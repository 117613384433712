@use "../../settings/third-party/mixins";
@use "../../settings/third-party/variables";

/*--ng-contact--*/
.edit-contact{
  .text-success{
    .success-icon{
      display: inline-block;
      height: 17px;
      width: 17px;
    }
    .fail-icon{
      display: none;
    }
  }
  .text-danger{
    .success-icon{
      display: none;
    }
    .fail-icon{
      display: inline-block;
      line-height: 16px;
      width: 17px;
      height: 17px;
      min-height: 16px;
      cursor: none !important;
      padding: 0;
      background-color: #f45151;
      #close-icon{
        height: 7px;
        width: 7px;
      }
    }
  }
}
#content {
  .md-subhead {
    // padding-left: 15px;
    // padding-top: 18px;
    margin: 0;
  }

  .contact-desc {
    padding-left: 4px;

    md-icon {
      outline: 0;

      &.verfication-icon {
        height: 18px;
        margin-left: 8px;
        position: relative;
        top: -3px;
        width: 18px;
      }
    }
  }

  .btn-min-space {
    min-width: 35px;
  }
}

.default-card-box {
  padding: 15px;
}

// .contact-user-list {
//   li:last-child {
//     margin-bottom: 0;
//   }
//
//   .desc {
//     h3 {
//       margin: 0;
//     }
//
//     p {
//       display: inline-block;
//       margin-top: 8px;
//       position: relative;
//       padding-left: 20px;
//     }
//
//     .icon-a {
//       position: absolute;
//       left: 0;
//       top: -5px;
//     }
//
//     .icon-b {
//       position: absolute;
//       left: 0;
//       top: -3px;
//     }
//
//     .icon {
//       color: $gray-light;
//       vertical-align: bottom;
//       font-size: $font-size-large;
//     }
//
//     .icon-ico-phone {
//       width: 15px;
//       height: 20px;
//     }
//   }
//
//   .social-info {
//     md-icon {
//       margin-right: 8px;
//     }
//
//     span {
//       display: inline-block;
//       vertical-align: middle;
//     }
//   }
//
//   .social-icon-wrapper {
//     md-icon {
//       margin: 0 4px;
//       vertical-align: top;
//     }
//
//     .md-button {
//       margin: 0;
//       height: auto;
//       min-width: 0;
//       padding: 0;
//       width: auto;
//       vertical-align: top;
//       min-height: 0;
//     }
//   }
//
//   .user-contact {
//     display: inline-block;
//     margin-right: 28px;
//     margin-top: 4px;
//     max-width: 55px;
//     color: $gray;
//
//     md-icon {
//       width: 55px;
//       height: 55px;
//     }
//   }
// }

.contact-desc .col-2-list {
  width: 100%;
}

/*Contact End*/

/*Contact-Edit Start*/

.form-base {
  textarea {
    height: 100px;
  }

  .avtar {
    width: 110px;
    max-width: 110px;
    text-align: center;

    .avtar-img {
      @include mixins.border-radius(50%);
      // border: 1px solid $gray-light;
      height: 98px;
      margin-bottom: 15px;
      width: 98px;
      // padding: 5px 3px 0;
      // overflow: hidden;
    }

    .avtar-change {
      margin-top: 10px;
      color: variables.$brand-primary;
      cursor: pointer;
      outline: 0;
    }

    label.avtar-change {
      display: block;
      margin: 0;
      padding: 0;
    }
  }

  .add-email {
    padding: 10px;
    margin-bottom: 18px;

    .icon-round {
      margin-left: -20px;
    }
  }

  .add-ext {
    position: absolute;
    top: 1px;
    right: -50px;
  }

  .add-ext,
  .add-field {
    @media screen and (max-width: 599px) {
      position: relative;
      right: 0;
    }
  }

  .add-field {
    position: absolute;
    top: 12px;
    right: -32px;
  }

  .v-address {
    @extend .add-field;
    margin: 13px 0 25px;
    right: -102px;
    @media screen and (max-width: 599px) {
      position: static;
      margin: -5px 0 10px;
      text-align: right;
    }
  }

  .ico-plus,
  .icon-ico-add {
    margin-right: 8px;
  }

  .add-contact {
    margin: 10px 0 25px;
  }

  .email-section {
    @media screen and (min-width: 600px) {
      flex: 1 1 66.7%;
      max-width: 66.7%;
    }
  }
}
@media screen and (max-width: 599px) {
  .close-btn .icon-cross {
    margin: 0;
  }

  // .contact-user-list .social-info {
  //   margin-top: 15px;
  //   margin-bottom: 15px;
  // }
  //
  // .contact-user-list .subscribe-link {
  //   top: 120px;
  // }
}

/*Contact-Edit End*/

/*FormBase End*/
@media only screen and (max-width: 767px) {
  // .contact-user-list .desc p {
  //   margin: 4px 0;
  // }

  .default-box {
    padding: 8px;
  }

  // .contact-user-list .user-contact {
  //   display: inline-block;
  //   margin-right: 25px;
  // }
}
@media only screen and (max-width: 439px) {
  // .contact-user-list .desc p {
  //   margin: 6px 0;
  // }

  // .contact-user-list {
  //   .icon-b {
  //     top: 0;
  //   }
  // }
}

// .social-icons:after {
//   border-width: 9px;
// }
//
// .social-icons md-menu-item .input-box input {
//   width: 69%;
// }

.auto-input::-webkit-input-placeholder {
  color: transparent !important;
}

.auto-input:-moz-placeholder {
  /* Firefox 18- */
  color: transparent !important;
}

.auto-input::-moz-placeholder {
  /* Firefox 19+ */
  color: transparent !important;
}

.auto-input:-ms-input-placeholder {
  color: transparent !important;
}

.error-text-email {
  position: relative;
  bottom: 18px;
}
