@use "../../settings/third-party/mixins";
@use "../../settings/third-party/variables";
@use "../../settings/style-guide";
@use "../../settings/custom";

.promotion-section {
  padding: 20px;
  // .top-section {
  // 	margin-bottom: 18px;
  // }
  .small-lbl {
    color: variables.$gray;
    font-size: 10px;
  }

  .big-lbl {
    color: variables.$gray-dark;
    font-size: 16px;
  }
  // .form-base {
  // 	md-input-container {
  // 		margin-bottom: 0;
  // 		md-select {
  // 			width: 145px;
  // 		}
  // 	}
  // }
  .coupon-list {
    @extend .no-style;

    li {
      @include mixins.border-radius(5px);
      background: variables.$white-dark;
      display: inline-block;
      margin-bottom: 20px;
      margin-right: 20px;
      padding: 20px 12px;
      position: relative;
      width: 22.4%;
      max-width: 100%;
    }

    .bottom-gap-sm {
      margin-bottom: 10px;
    }

    .bottom-gap-md {
      margin-bottom: 20px;
    }

    .bottom-gap-lg {
      margin-bottom: 25px;
    }

    .coupon-status,
    .menu-btn {
      position: absolute;
    }

    .menu-btn {
      top: 9px;
      right: 12px;
    }

    .coupon-status {
      top: 12px;
      left: 12px;

      &.active {
        @extend .text-success;
      }

      &.expired {
        @extend .text-danger;
      }
    }

    .icon-coupon {
      height: 34px;
      margin-bottom: 10px;
      width: 34px;
    }

    .title {
      @extend .f3;
      @extend .fm;
      margin-top: 0;
      margin-bottom: 10px;
      @extend .ellipsis;
      width: 100%;
    }

    .coupon-descp {
      line-height: 18px;
    }
  }
}

.promotion-view-section {
  .dark-color {
    color: variables.$gray-darker;
  }

  .active {
    color: variables.$status-green;
  }

  .new {}

  .disabled {
    color: variables.$status-gray;
  }

  .archived {
    color: variables.$status-yellow;
  }

  .deleted {}

  .draft {
    color: variables.$status-gray;
  }

  .expired {
    color: variables.$status-red;
  }

  .last-update-msg {
    right: 8px;
    top: 14px;

    .f3,
    .f4 {
      font-size: 10px;
    }
  }

  .icon-tick {
    height: 21px;
    margin-left: 18px;
    margin-right: 10px;
    width: 21px;
  }

  .custom-tabs {
    md-content {
      padding-top: 23px;
      padding-bottom: 10px;
    }
  }

  .rpp-list {
    li {
      margin-bottom: 18px;
    }
  }

  .bottom-gap-xs {
    margin-bottom: 8px;
  }

  .bottom-gap-md {
    margin-bottom: 18px;
  }

  .bottom-gap-lg {
    margin-bottom: 28px;
  }

  .item-chips li {
    margin-right: 10px;
    margin-bottom: 15px;
  }

  .list-section {
    margin-top: 18px;

    .search-list {
      li {
        padding-bottom: 0;
      }

      md-divider {
        margin-top: 10px;
      }

      .img-container {
        margin-left: 10px;
      }
    }
  }
}

.policy-grid-section {
  .coupon-list {
    .icon-coupon {
      margin-top: 25px;
    }
  }
}
@media screen and (max-width: 768px) {
  .promotion-section {
    .coupon-list li {
      width: 30.5%;
    }
  }
}
@media screen and (min-width: 600px) {
  .promotion-view-section {
    .list-section {
      max-width: 265px;
    }
  }
}
@media screen and (max-width: 600px) {
  .promotion-section {
    .coupon-list li {
      width: 100%;
      margin-right: 0;
    }
  }
}
