/*Sidebar Settings Start*/
@use "../../customers/opportunity/opportunity-sidebars";
@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.ar-sidepanel.g-edit .task-wrapper {
  top: 60px;
}

/*Sidebar Settings END*/

.f10 {
  font-size: 10px;
  font-weight: 400;
  color: variables.$gray;
}

.support-contact {
  .contact-info {
    p {
      margin-top: 6px;
    }
  }

  .second-contact {
    @extend .f10;

    margin-top: 7px;
  }
}

.repair-info {
  font-size: 16px;
  font-weight: 400;

  .status {
    margin-left: 9px;
  }

  .modified {
    margin-top: 7px;
    display: inline-block;
  }
}

.support-repair-details {
  .repair-heading {
    @extend .f1;

    font-weight: 400;

    .status {
      margin-left: 9px;
      font-size: variables.$font-size-small;
      @extend .text-danger;
    }
  }

  .info-link {
    @extend .f10;

    color: variables.$brand-primary;
    display: inline-block;
    margin-top: 5px;
  }

  .attached-doc {
    margin: 0;
    padding: 3px 8px;
    line-height: 24px;
    min-height: 24px;
    min-width: 113px;
    text-align: left;
    font-size: variables.$font-size-small;
    font-weight: 400;
    background: variables.$gray-lighter;

    md-icon {
      width: 13px;
      margin-right: 5px;
    }
  }

  .assign-repair {
    padding: 15px 0;

    a {
      display: inline-block;
      margin-bottom: 20px;

      md-icon {
        margin-right: 5px;
        height: 18px;
        width: 18px;
      }
    }
  }

  .default-card-box {
    box-shadow: none;
  }

  md-tabs md-ink-bar:after {
    border-top-color: #f8f8f8;
  }

  md-tab-content {
    md-content {
      background: transparent;
    }
  }

  .form-base {
    background: transparent;

    textarea {
      min-height: 92px;
      height: 90px;
    }

    .fix-version {
      margin-top: 1px;
      margin-right: 15px;
    }
  }

  .attachment-title {
    margin-bottom: 0;
  }

  .proposal-container.support-repair-conversation {
    padding: 0 10px;
  }

  .table-responsive {
    padding: 10px 0;

    .data-table {
      min-width: 650px;

      th {
        font-weight: 500;
        font-size: variables.$font-size-small-plus;
        color: variables.$gray-base;
      }

      td {
        background: #fff;
        padding: 14px;
      }
    }
  }
}

.support-repair-sidenav {
  &.proposal-sidepanel {
    overflow: visible;
  }

  .ar-sidepanel {
    .task-wrapper {
      top: 45px;

      md-input-container {
        margin-top: 10px;
        margin-left: 17px;
      }
    }

    md-icon.radio-icon {
      margin-right: 5px;
      width: 20px;
      height: 18px;
    }

    md-radio-button {
      .md-container {
        .md-off {
          background: variables.$white;
        }
      }
    }
  }

  // &.s2 {
  //     .ar-sidepanel {
  //         .task-wrapper {
  //             .md-block {
  //                 margin-bottom: 15px;
  //             }
  //         }

  //         .task-list {
  //             md-list {
  //                 padding: 0;
  //             }

  //             md-list-item.md-no-proxy,
  //             md-list-item.md-no-style {
  //                 padding: 0;
  //             }
  //         }

  //         .support-sidebar-radios {
  //             padding: 22px 18px;

  //             &.active {
  //                 background: $brand-info-light;
  //             }
  //         }
  //     }

  //     md-radio-group {
  //         md-radio-button {
  //             margin-bottom: 0;
  //         }

  //         .margin-rt-10 {
  //             margin-right: 10px;
  //         }
  //     }
  // }

  &.s3 {
    .ar-sidepanel {
      .task-wrapper .task-list {
        height: 100%;
      }

      .sidenav-title {
        margin-top: 4px;

        h2 {
          font-size: 16px;
          font-weight: 400;
          margin-left: 18px;
        }
      }

      .suggested-list {
        border-bottom: 1px solid variables.$border-color;
        padding: 18px;

        .case-status {
          margin-bottom: 5px;
        }

        .resolution {
          margin-bottom: 15px;
        }
      }
    }
  }
}

.margin-top-sm {
  margin-top: 15px;
}

.support-accordion.accordionN {
  a.active + .accordionN-section-content {
    display: block;
  }
}

.support-accordion.accordionN {
  a {
    padding: 9px 0;
    font-weight: normal;
    font-size: variables.$font-size-small;
    color: variables.$gray-dark;

    &.active {
      background: variables.$brand-info-light;
    }

    &:after {
      border-right: 2px solid variables.$gray-lighter;
      border-bottom: 2px solid variables.$gray-lighter;
      top: 21px;
    }

    .assign-list-icon {
      vertical-align: middle;
      margin-right: 12px;
    }
  }

  .accordionN-section-content {
    padding: 0;

    md-list {
      padding: 0;

      md-list-item {
        cursor: pointer;
        padding-left: 38px;
      }

      p {
        font-size: variables.$font-size-small;
        color: variables.$gray-dark;
      }
    }

    .icon-side-tick {
      left: 36px;
    }
  }
}

.machine-detail-edit {
  margin-top: 10px;

  .attach-edit {
    padding-top: 10px;

    label {
      @extend .f4;
    }
  }

  .attachment-list {
    li {
      padding-right: 25px;
      position: relative;
      display: inline-block;
      vertical-align: top;

      md-icon {
        height: 10px;
        width: 10px;
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }
  }

  .input-textarea {
    margin-top: 10px;

    md-input-container {
      .md-input {
        min-height: 62px;
      }
    }
  }

  .edit-inputs {
    @extend .no-style;

    margin-top: 10px;

    li {
      margin-bottom: 15px;

      md-input-container {
        margin: 0;
        width: 88%;

        &.with-label-box {
          width: 100%;

          input {
            width: 88%;
          }
        }
      }

      .datepicker-custom {
        width: 88%;

        .md-datepicker-button {
          top: -5px;
        }
      }

      .rating {
        display: inline-block;
        margin-left: 10px;
        vertical-align: bottom;

        md-icon {
          height: 16px;
          width: 16px;
        }
      }

      .attach-title {
        a {
          display: inline-block;
          margin-bottom: 15px;
        }
      }
    }
  }

  .payment-distribution {
    @extend .no-style;

    li {
      margin-bottom: 18px;

      md-input-container {
        margin: 0 10px 0 0;
      }

      h3 {
        margin: 10px 0;
        font-size: variables.$font-size-small;
        font-weight: 500;
      }

      md-checkbox {
        span {
          color: variables.$gray-light;
        }
      }
    }
  }

  md-input-container {
    label,
    md-select span {
      color: variables.$gray;
      padding-bottom: 4px;
    }
  }

  .datepicker-custom {
    .md-datepicker-input-container {
      height: 31px;

      input {
        font-size: variables.$font-size-small;
        padding-left: 4px;
        padding-top: 10px;
      }
    }

    .md-datepicker-button {
      top: -5px;
    }
  }
}
