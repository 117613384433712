@use "../../settings/third-party/variables";

.email-thread-main {
  .email-threads {
    .icon_img {
      width: 35px;
      margin: 10px 25px 0 0;
      height: auto;

    }

    .first_cell {
      max-width: 600px;
      width: 100%;
    }

    .inner-cell.second_cell {
      margin-left: 57px;
      // justify-content: flex-end;
      &.side_bar_perform_section{
        @media (max-width:600px) {
          margin-left: 0px;
        }
      }
      @media (max-width:1265px) {
        justify-content: flex-start;
        margin-left: 52px;
      }
    }

    .bg-bluegrey {
      background-color: #d3e1e54a;
    }

    .text-oceanblue {
      color: #5cc5c3;
    }

    .desc {
      width: 100%;
      margin-bottom: 10px;
      display: flex;
      padding: 0;
    }

    .accepting_cards_content {
      padding: 0;
    }

    .usr-date {
      color: #6a6a6a!important;
    }

    .icon-next-arrow {
      svg {
        path {
          fill: #b7b7b7;
        }
      }
    }

    .table-content {
      background-color: rgba(211,225,229,.2901960784);
      // margin-top: 50px;
      padding: 24px 60px;

      @media (max-width:600px) {
        padding: 16px;
      }
    }

    .detail-overview.customer-dashboard {
      padding: 16px;
      // background-color: #20394cff;
      background-color: #20394cff;

      .right-nav-back {
        .circle-back-icon {
          rotate: none;
          margin-top: 0;

          svg {
            path {
              fill: #ffffff;
            }
          }

          &:hover {
            svg {
              path {
                fill: variables.$brand-primary;
              }
            }
          }
        }
      }

      .icon-next-arrow {
        width: 15px;
        height: 15px;
        rotate: -180deg;
        width: 35px;

        svg {
          path {
            fill: variables.$brand-primary;
          }
        }
      }

      .back {
        color: variables.$brand-primary;
      }

      .from-desc {
        line-height: 1.5;
      }
    }
    .customer-communicate{
      width: 100%;
      gap: 35px;
      vertical-align: middle;
      display: flex;
      align-items: center;
      @media (max-width:600px) {
        gap: 10px;
        align-items: start;
      }
    }
    .legend_section{
      width: 100%;
      gap: 27px;
      display: flex;
    }
    .email-thread-content {
      display: flex;
      // padding: 16px;
      padding: 0;
      // padding: 25px 0px 0px;
      background-color: variables.$white;

      .icon-section {
        margin-right: 25px;

        .icon-next-arrow {
          rotate: -180deg;
          width: 35px;
          // font-weight: bold;
          // position: relative;
          // bottom: 565px;
          svg {
            path {
              fill: #64c43aff;
            }
          }
        }
        @media (max-width:750px) {
          margin-right: 0;
        }
      }
      @media (max-width:750px) {
        margin-right: 0;

        // .parent-card-grid {
        //   max-width: 500px;
        //   width: 100%;
        // }
      }

      .main-card-container-section {
        flex-wrap: wrap;
        width: 100%;
        min-height: auto;

        .icon-next-arrow {
          width: 15px;
          height: 10px;
          position: relative;
          bottom: 1px;
        }

        .icon-down-head {
          svg {
            polygon.st0 {
              fill: #959494;
            }
          }
        }

        .clickable-section:hover {
          cursor: pointer;

          label {
            color: variables.$brand-primary;
            cursor: pointer;
          }

          .icon-next-arrow {
            svg {
              path {
                fill: variables.$brand-primary;
              }
            }
          }

          .icon_img_download {
            svg {
              path {
                fill: variables.$brand-primary!important;
              }
            }
          }

        }

      }

      md-input-container {
        .md-input {
          position: relative;
          bottom: 18px;
        }
      }

      .card-container-section:hover {
        box-shadow: 0 3px 6px -1px #949494;
        border: 1px solid #cccccc;
      }

      .recipient-section {
        margin-bottom: 80px;
      }

      .card-container-section {
        background-color: variables.$white;
        border: 1px solid #cccccc;
        // padding: 20px;
        .detail-overview,
        .reply-desc.desc {
          background-color: #20394cff;
          color: #ffffff;
        }

        .detail-overview {
          padding: 20px 20px 10px;

          .desc {
            padding: 0;
          }
        }

        .reply-desc.desc {
          margin-bottom: 0;
        }

        .desc {
          width: 100%;
          margin-bottom: 10px;
          padding: 20px;

          .icon_img {
            width: 13px;
            height: auto;
            position: relative;
            top: 3px;
            margin-right: 3px;
          }

          .icon_img.icon_img_download {
            height: 13px;
            top: 0;
            margin-top: 0;

            svg {
              path {
                fill: #b7b7b7;
              }
            }
          }
        }

        hr {
          margin: 0 20px;
        }

        label {
          color: #b7b7b7;
        }

        .recipients-label {
          margin-right: 20px;
          font-size: 16px;
        }

        .email-threads-desc-section {
          border-left: 4px solid #abd037ff;
          padding-right: 15px;
        }

        .reply.email-threads-desc-section {
          border-left: 4px solid #6b9affff;
        }

        .email-threads-desc {
          display: block;
          padding: 15px 20px;

          p {
            line-height: 1.5;
            font-size: 12px;
            font-family: Roboto, "Helvetica Neue", sans-serif;
            color: #666666;
          }
        }

        .icons-grid {
          padding: 0 20px;

          .icon_img {
            width: 100px;
            margin-right: 10px;
          }
        }

        .bottom-icons-grid {
          .icon-grid-list {
            margin: 0 0 0 3px;
          }

          div {
            margin-right: 10px;
          }

          .icon_png {
            width: 25px;
            height: 12px;
            position: relative;
            top: 2px;
            margin-right: 0;
          }

          .icon-flag.icon_png {
            width: 25px;
            height: 35px;
            position: relative;
            top: -11px;

            svg {
              g {
                path {
                  fill: #b7b7b7!important;
                }
              }
            }
          }

          .icon-flag.icon_png:hover {
            svg {
              g {
                path {
                  fill: #cc0000!important;
                }

                .cls-2 {
                  stroke: #cc0000!important;
                }
              }
            }

          }
        }
      }
    }

    .icon_png_recipients {
      margin-top: 5px;
    }

    .email-thread-summary-content {
      .icon-section {
        .icon-next-arrow {
          rotate: -180deg;
          width: 35px;
          // font-weight: bold;
          // position: relative;
          // bottom: 148px;
        }

      }

      .business_entity {
        flex-wrap: nowrap;
        @media (max-width:1200px) {
          flex-wrap: wrap;
        }
      }

      .table-responsive {
        padding: 0;

        width: 100%;
        overflow-x: auto;
      }

      thead {
        background: #21374cff;

        th {
          color: variables.$white;
        }
      }
    }

    .second_cell {
      .md-button.btn-primary:hover {
        background-color: variables.$white;
        color: variables.$brand-primary;
        border: 1px solid variables.$brand-primary;

        md-icon {
          svg {
            path {
              fill: variables.$brand-primary;
            }
          }
        }
      }

      .md-button.btn-primary.active {
        background-color: variables.$white;
        color: variables.$brand-primary;
        border: 1px solid variables.$brand-primary;

        md-icon {
          svg {
            path {
              fill: variables.$brand-primary;
            }
          }
        }
      }

      .md-button.btn-primary {
        md-icon {
          svg {
            path {
              fill: #ffffff;
            }
          }
        }
      }
    }

    .flex {
      display: flex;
    }

    .save-contacts {
      background-color: variables.$white;
      color: variables.$brand-primary;
      border: 1px solid variables.$brand-primary;
      margin-top: 7px;
      max-width: 175px;
      min-width: 175px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-left: 0;

      .ico-touch-arrow {
        margin: 0;
      }
    }

    .save-contacts:hover {
      background-color: variables.$brand-primary!important;
      color: #fff;

      md-icon {
        svg {
          path {
            fill: #ffffff;
          }
        }
      }
    }

    .table-description {
      max-width: 300px;
      width: 100%;
    }

    .td-title-heading {
      font-size: 16px;
      color: #21374c;
      font-weight: 600;
      margin: 0;
    }

    .td-description-text {
      color: #b7b7b7;
      font-size: 12px;
      margin-top: 0;
      min-width: 240px;
    }

    @media (max-width:1200px) {
      max-width: 100%;
    }
    @media (max-width:750px) {
      max-width: 100%;
    }
  }

  .associated-text {
    margin-top: 0 !important;
  }

  // .switch-text {
  //   margin-top: 42px !important;
  // }

  .icon-section.layout-column {
    margin-right: 32px;
  }

  .text-white {
    color: variables.$white;
  }

  .text-navy-blue {
    color: variables.$text-navy-blue;
  }

  .email_title {
    font-size: 20px;
  }

  .thread-btn-primary {
    line-height: 1.3;
    text-align: left;
    min-width: 130px;
    padding: 5px;
    margin: 6px 0;
    border: 1px solid #F05E33;
    @media (max-width:600px) {
      min-width: 105px;
      span{
        font-size: 16px;
      }
    }

    span {
      font-size: 18px;
    }

    md-icon {
      margin-right: 7px !important;
    }
  }

  .function-content {
    .dashboard.bisection {
      margin: 40px 12px 0 0;
    }

    .first_default-box {
      padding: 0;
      margin: 0;

      .default-box-section {
        .profile-des-contact {

          margin-left: 0;

          .section-header {
            background-color: black;
            padding: 20px 0 20px 40px;

            span {
              font-weight: bold;
              font-size: 16px;
            }
          }
        }

        .content_div {
          // padding-right: 90px;
          padding-right: 25px;

          .img-container {
            .img-replacer {
              bottom: 40px;
              font-size: 20px;
              line-height: 55px;
              height: 55px;
              width: 55px;
            }
          }

          .pointer-section {
            gap: 10px;

            .img-container {
              position: initial;

              .img-replacer:not(md-icon) {
                background: #20394c;
                color: #ffffff;
              }

              .img-replacer.green:not(md-icon) {
                background: #63c53a;
              }

              .img-replacer.red:not(md-icon) {
                background: #f45250;
              }

              .img-replacer {
                position: initial;
                height: 35px;
                width: 35px;
                line-height: 35px;
                margin-right: 10px;
                font-size: 19px;
              }
            }
            @media (max-width:600px) {
              padding-left: 25px;
              
            }
          }
        }
      }

    }

    .second-card-box {
      margin-top: 0;
    }

    .flex {
      display: flex;
      width: 100%;
    }

    .section-heading {
      p {
        width: 100%;
        padding-left: 10px;
      }
    }

    .heading-card {
      width: 100%;
      background: black;
      padding: 20px 0;

      .profile-des {
        width: 100%;

        .section-header {
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding-right: 25px;

          span {
            font-weight: bold;
            font-size: 16px;
          }
        }
      }
    }

    .content_div {
      background-color: transparent;

      .action-menu {
        display: flex;
        align-items: flex-start;
      }
    }

    .md-button.active-cta,
    .md-button.archive-cta {
      text-transform: capitalize;
      max-width: 250px;
      min-width: 145px;
      text-align: left;
      border-radius: 25px;

      md-icon {
        margin-right: 10px;
      }
    }

    .md-button.active-cta {
      background-color: #20394cff;
      color: #ffffff;
    }

    .md-button.archive-cta {
      background-color: variables.$white;
      color: variables.$brand-primary;
      box-shadow: 0 3px 6px -4px #949494;

      &.active-cta {
        background-color: #20394cff;
        color: #ffffff;

        md-icon {
          svg {
            path {
              fill: #ffffff;
            }
          }
        }
      }

      md-icon {
        svg {
          path {
            fill: variables.$brand-primary;
          }
        }
      }
    }

    .table-responsive {
      .data-table {
        background-color: variables.$white;

        thead {
          background: #20394cff;

          th {
            color: variables.$white;
          }
        }

        .hexa-icon {
          position: absolute;

          .ico-table {
            position: relative;
            right: 30px;
            width: 30px;
            height: 30px;
            bottom: 10px;
          }
        }
      }

      .table-description {
        margin-left: 10px;
        bottom: 10px;
        position: initial;
      }

      .owner-detail {
        .profile-pic {
          width: 40px;
          height: 40px;
          line-height: 40px;
          margin-right: 10px;
        }

        .status-capsule {
          background-color: transparent;
          border: 1px solid #b7b7b7;
          max-width: 115px!important;
          width: 100%;
          font-weight: 500;
          font-size: 12px;
          text-align: center;
          line-height: 1.4;
          border-radius: 20px;
          padding: 3px 0 1px;
          height: 18px;
          box-sizing: content-box;
          color: #980000 !important;
          display: inline-block;
          @media (max-width:600px) {
            max-width: 100px!important;
          }
        }
        // p{
        //   margin: 10px auto;
        // }
      }

      .location-symbol {
        padding: 0 12px !important;
        min-width: 35px;
        text-align: center;
      }
      // .save-contacts{
      //   background-color: $brand-primary;
      //   color: $white;
      //   border: 1px solid $brand-primary;
      //   md-icon{
      //     svg{
      //       path{
      //         fill: $white;
      //       }
      //     }
      //   }
      // }
      .save-contacts {
        // background-color: $brand-primary;
        // color: $white;
        // border: 1px solid $brand-primary;
        md-icon {
          svg {
            path {
              fill: variables.$brand-primary;
            }
          }
        }
      }

      .save-contacts {
        // background-color: $brand-primary;
        // color: $white;
        // border: 1px solid $brand-primary;
        md-icon {
          svg {
            path {
              fill: variables.$brand-primary;
            }
          }
        }
      }

      .save-contacts:hover {
        md-icon {
          svg {
            path {
              fill: #ffffff;
            }
          }
        }
      }

      .td-description-text {
        margin: 5px auto;
      }
    }
  }

  .data-content {
    .dashboard.bisection {
      margin: 10px 12px 0 0;
      @media (max-width:600px) {
        margin: 30px 12px 0 0;
      }
    }

    .second-card-box {
      margin-top: 20px;
    }

    .heading-card {
      padding: 0;
    }

    .switch-here {
      justify-content: flex-end;

      p {
        // color: #64c43aff;
        color: variables.$brand-primary;
        margin-top: 0;
        text-align: right;
      }

      .icon-next-arrow {
        height: 15px;
        rotate: 90deg;
        width: 35px;

        svg {
          path {
            // fill: #64c43aff;
            fill: variables.$brand-primary;
          }
        }
      }
     
    }

    .second-card-box {
      margin-top: 0;
    }

    .heading-card {
      width: 100%;
      background: black;
      padding: 20px 0;

      .profile-des {
        width: 100%;

        .section-header {
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding-right: 25px;

          span {
            font-weight: bold;
            font-size: 16px;
          }
        }
      }
    }

    .pointer-section {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      // padding: 25px;
      .img-container {
        position: initial;

        .img-replacer {
          position: initial;
          height: 35px;
          width: 35px;
          line-height: 38px;
          margin-right: 10px;
        }

        .img-replacer:not(md-icon) {
          background: #20394c;
          color: #ffffff;
        }
      }

      p {
        font-size: 16px;
        margin-top: 7px;
      }
      // .pointer-exits , .pointer-donot-exits{
      //   justify-content: center;
      // }
      
    }
  }

  // .email-thread-content {
  //   .default-box {
  //     margin-bottom: 20px;
  //   }
  // }

  .thread-profile {
    padding: 24px;

    .default-box {
      border-radius: 5px !important;
      margin: 0;
    }

    .default-box-section {
      width: 100%;
    }

    .primary_email {
      display: flex;

      a {
        margin: 0 0 10px;
      }

      .good-email {
        margin-left: 7px;

        span.icon {
          margin-right: 2px;
        }
      }

      .edit {
        width: 15px;
      }
    }

    .icon-next-arrow {
      margin-right: 15px;
    }

    .img-container {
      position: absolute;

      .img-replacer {
        position: relative;
        right: 40px;
        bottom: 5px;
      }

      .img-replacer:not(md-icon) {
        background: #5CC5C3;
        color: #ffffff;
      }
    }

    .switch-here {
      justify-content: flex-end;

      p {
        // color: #64c43aff;
        color: variables.$brand-primary;
        margin-top: 0;
        text-align: right;
      }

      .icon-next-arrow {
        height: 15px;
        rotate: 90deg;
        width: 35px;

        svg {
          path {
            // fill: #64c43aff;
            fill: variables.$brand-primary;
          }
        }
      }
      @media (max-width:600px) {
        padding-left: 25px;
        justify-content: flex-start;
        margin-bottom: 10px;
      }
    }

    .profile-des {
      margin-left: 20px;

      .icon-grid-list-newtab {
        margin-top: 8px;
      }
      @media (max-width:600px) {
        width: 100%;
        .icon-grid-list-newtab {
          margin-right: 15px;
        }
      }
    }

    .section-header {
      h1 {
        font-size: 30px;
        margin-right: 15px;
        color: #20394c;
        font-weight: 600!important;
      }
    }

    .default-box.second-card-box {
      // max-width: 445px;
      width: 100%;

      .default-box-section {
        background-color: #f2f6f9;
      }

      .heading-card {
        width: 100%;
        background: black;

        .percent_counter {
          position: absolute;

          .icon_img {
            width: 50px;
            position: relative;
            right: 40px;
          }
        }

        h1 {
          margin: 10px 0 10px 30px;
          color: variables.$white;
          font-size: 17px;
        }
      }

      .pointer-section {
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        padding: 25px;

        .img-container {
          position: initial;

          .img-replacer {
            position: initial;
            height: 35px;
            width: 35px;
            line-height: 38px;
            margin-right: 10px;
          }

          .img-replacer:not(md-icon) {
            background: #20394c;
            color: #ffffff;
          }
        }

        p {
          font-size: 16px;
          margin-top: 7px;
        }

        .pointer-donot-exits,
        .pointer-exits {
          justify-content: center;
        }
      }

      .emailname-cta {
        margin-bottom: 20px;
      }

      .btn-primary {
        border: 1px solid #5CC5C3;
        background-color: #ffffff;
        border-radius: 25px;
        line-height: 1.4;
        color: #5CC5C3;
        min-width: 250px;
        width: 100%;
        padding: 6px;
        max-width: 300px;
        box-shadow: 0 3px 6px -1px #949494;

        span {
          font-size: 16px;
          font-weight: 600;
        }
      }

      .btn-primary:hover {
        background-color: #5CC5C3;
        color: #ffffff;
      }
    }
  }

  .aliases-heading {
    h2 {
      font-size: 20px;
    }
  }

  .text-green {
    color: variables.$text-green !important;
  }

  .text-orange {
    color: variables.$text-orange !important;
  }

  // .full-width {
  //   width: 100%!important;
  // }
  @media (max-width:860px) {
    .thread-profile {
      .default-box.second-card-box {
        // max-width: 95%;
        width: 100%;
      }
    }
  }
  @media (max-width:600px) {
    .convert-btn-primary {
      margin: 0;
    }

    .pointer-section {
      align-items: flex-start;
    }

    .card-container-section {
      .icons-grid {
        .icon_img {
          width: 55px !important;
        }
      }

      .desc {
        .first_row {
          margin-bottom: 8px;
        }
      }
    }

    .bottom-icons-grid {
      margin: 10px 0 0 15px;

      .clickable-section {
        margin-top: 5px;
        @media (max-width:600px) {
          margin-top: 0px;
        }
      }

      .icon-grid-list-flag {
        margin-top: 20px !important;
      }
    }
  }

  // .md-sidenav-right {
  //   .leftnav-wrap {
  //     box-shadow: none!important;

  //     .default-box.second-card-box {
  //       margin: 0;
  //       height: 100%;
  //       border-radius: 0;
  //       box-shadow: none;

  //       .profile-des {
  //         margin-left: 20px;
  //       }

  //       .content_div {
  //         height: 100%;
  //       }

  //       .heading-card {
  //         background-color: #20394cff;
  //         padding: 20px 0;
  //       }
  //     }
  //   }

  //   .bottom-section {
  //     background-color: #f2f6f9!important;
  //     border: transparent;
  //     border-left: 1px solid #cccccc !important;
  //     border-right: 1px solid #cccccc !important;
  //     border-bottom: 1px solid #cccccc !important;
  //   }
  // }
}
