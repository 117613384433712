@use "../../settings/third-party/mixins";
@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.package-section {
  .image-tabs-wrapper {
    .image-tabs {
      max-width: 866px;
      margin: 0 20px;
    }

    /** Image tabs wrapper starts **/
    .left-arrow,
    .right-arrow {
      position: absolute;
      top: 50%;
      margin-top: -19px;

      svg {
        path {
          fill: #a0a0a0;
        }
      }
    }

    .left-arrow {
      right: 0;
    }

    .right-arrow {
      left: 0;
      transform: rotate(180deg);
    }
  }

  .custom-tabs {
    md-content {
      padding-top: 20px;
    }
  }

  .detail-right-section {
    line-height: 24px;

    md-checkbox {
      margin-bottom: 14px;
    }

    label {
      padding-right: 4px;
    }
  }

  .datepicker-custom {
    margin-bottom: 18px;
  }

  .image-section {
    margin-top: 20px;
  }

  .product-img-list {
    // .image-type {
    // 	margin-top: 0;
    // 	margin-bottom: 20px;
    // 	text-align: left;
    // }
    .dimension-guide {
      @extend .f3;
      margin-top: 20px;
    }
  }

  .attached-files-section {
    // .md-icon-button.md-button.add-btn {
    //   margin: 0 8px 18px 0;
    // }

    .item-chips {
      li {
        margin-right: 10px;
        margin-bottom: 10px;

        &:before {
          content: '';
          display: inline-block;
          background: url("#{variables.$image-path}/icons/set-b/ico-pdf.svg") no-repeat 0 0;
          background-position: 0 0;
          background-size: 15px;
          height: 17px;
          margin-right: 10px;
          width: 15px;
          vertical-align: middle;
        }
      }
    }

    .icon-close {
      margin-left: 4px;

      md-icon {
        height: 13px;
        width: 13px;
      }
    }
  }
  /* Set policy tab css */
  .policy-rb-btns {
    display: block;
    margin-top: 15px;
    margin-bottom: 8px;
  }

  .policy-titles {
    font-weight: 500;
  }

  .sub-note {
    margin-top: -6px;
    padding-left: 30px;
  }

  md-checkbox.policy-cb {
    margin-bottom: 22px;
  }

  .day-tags-section {
    margin-bottom: 10px;
  }
  /* Set policy tab css */
  .default-card-box {
    @include mixins.border-radius(3px);
    box-shadow: none;
    margin-bottom: 6px;

    md-input-container {
      margin-bottom: 4px;
    }

    md-checkbox {
      margin-bottom: 0;

      .md-icon {
        background-color: variables.$white;
      }
    }
  }

  .image-tabs-wrapper {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .image-tabs {
    // .active {
    //   .img-tab-container {
    //     .counter {
    //       background: $brand-primary;
    //     }
    //   }
    // }

    .img-tab-container {
      height: 70px;
      position: relative;
      width: 100px;

      .counter {
        &.active {
          background: variables.$brand-primary;
          right: 4px;
          left: unset;
        }
        @include mixins.border-radius(50%);
        background: variables.$gray-light;
        color: variables.$white;
        display: inline-block;
        font-size: 10px;
        padding: 3px 4px;
        position: absolute;
        bottom: 3px;
        left: 4px;
      }

      md-icon {
        min-height: 25px;
        padding: 0;

        &.avatar {
          width: 46px;
        }
      }
    }

    .tab-caption {
      padding-right: 0;
    }
  }

  .product-selection-list.active {
    md-checkbox.product-radio {
      margin-left: 1px;

      md-content {
        padding-top: 0;
      }
    }

    .product-price {
      padding-right: 0;
    }

    ul li {
      margin-right: 10px;
    }
  }
}

.package-view-section {
  .section-gap {
    margin-bottom: 30px;
  }

  .field-view-list {
    li.extra-gap {
      margin-top: 30px;
    }

    .days {
      margin-top: -10px;

      li {
        margin-bottom: 4px;
      }
    }
  }

  .choice-selected {
    color: variables.$gray-dark;
    margin-top: 20px;
    margin-bottom: 20px;

    .f4 {
      padding-right: 18px;
    }
  }

  .product-selection-list.active {
    // margin-top: -10px;
    padding: 0;

    md-checkbox.product-radio .md-container {
      background: transparent;

      .md-icon {
        display: none;
      }
    }
  }
}

.child-catalog {
  li {
    &.active {
      border-color: variables.$brand-primary;
    }
    color: #000;
    border: 1px solid variables.$gray-light;

    padding: 10px;
    margin: 0 10px 10px 0;
    border-radius: 3px;
    width: 135px;
    min-height: 65px;
    position: relative;
    display: inline-block;

    .top-gap {
      padding-top: 15px;
    }

    .active-icon {
      width: 13px;
      position: absolute;
      right: 8px;
      top: 4px;
    }
  }
}

.image-tabs {
  li {
    padding-bottom: 20px;
  }

  li.active {
    position: relative;

    &:after {
      border-color: variables.$white-dark transparent transparent;
      bottom: -12px;
      z-index: 2;
    }

    &:before {
      border-color: variables.$gray-lighter transparent transparent;
      bottom: -13px;
      z-index: 1;
    }

    &:after,
    &:before {
      content: "";
      display: inline-block;
      border-width: 7px;
      border-style: solid;
      position: absolute;
      width: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    .img-tab-container {
      border-color: variables.$brand-primary;
    }
  }
}
/** Image tabs wrapper ends **/
@media only screen and (min-width: 1025px) {
  .package-section {
    .policy-left-division {
      width: 65%;
    }

    .policy-right-division {
      width: 35%;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .package-section {
    .policy-left-division {
      width: 60%;
    }

    .policy-right-division {
      width: 40%;
    }
  }
}
@media only screen and (min-width: 600px) {
  .package-section {
    .detail-left-section {
      float: left;
      width: 68%;
    }

    .gap-right {
      margin-right: 25px;
    }

    .detail-right-section {
      float: right;
      width: 30%;
    }
  }
}
@media only screen and (max-width: 600px) {
  .package-section {
    .detail-right-section {
      margin-bottom: 20px;
    }

    .policy-left-division,
    .policy-right-division {
      margin-bottom: 30px;
    }
    // .product-img-list {
    // 	.product-img-box {
    // 		width: 100%;
    // 	}
    //
    // 	.img-options {
    // 	    right: 10px;
    // 	}
    // }
  }
}
