@use "../settings/third-party/variables";

.my-workboard {
  padding: 20px;

  .img-container {
    margin-right: 6px;
  }

  .table-section {
    @media screen and (min-width: 960px) {
      margin-right: 15px;
    }
  }

  table {
    border-collapse: collapse;
    border: 1px solid variables.$gray-light;
    font-size: variables.$font-size-small;
    font-weight: 500;

    @media screen and (max-width: 959px) {
      width: 100%;
    }

    thead tr {
      background: #52758d;
      color: white;

      .min-width {
        min-width: 192px;
      }
    }

    td,
    th {
      &:not(.text-center) {
        text-align: left;
      }
      padding: 15px;
    }

    tbody tr:hover {
      background: #f3f3f3;
      color: orange;
    }

    .due-date-column {
      .tour-progress {
        margin: 5px 0 0;
      }
    }
  }

  .filter-section {
    margin-top: 50px;

    .filters {
      border: 1px solid variables.$gray-light;
      overflow-x: scroll;
      padding: 15px 10px;
    }

    .img-container {
      margin-right: 4px;
      border: 2px solid transparent;
      border-radius: 50%;

      &.selected {
        border-color: variables.$brand-primary;
      }
    }

    .media-filter {
      // @media screen and (max-width: 599px) {
      margin-top: 25px;
      // }

      @media screen and (min-width: 600px) {
        &:first-of-type {
          margin-right: 15px;
        }
      }
    }
  }

  .quick-nav {
    .nav-item {
      border: 1px solid variables.$gray-light;
      padding: 0 10px;
      background: white;
      margin-bottom: 20px;
      &:hover{
        background-color: #f3f3f3;
      }
      img {
        height: 40px;
        width: 40px;
        margin-right: 10px;
      }

      h2 {
        color: #606060;
      }

      .function-icon {
        margin-bottom: 10px;
      }

      .navigation-icon {
        height: 20px;
        width: 20px;
      }
    }
  }

  .notification-section {
    border: 1px solid variables.$gray-light;

    md-select {
      margin: 0;

      md-select-value {
        // min-height: 38px;

        span {
          padding-left: 10px;
        }
      }
    }

    .notification-list {
      max-height: 2500px;
      overflow-y: scroll;
    }
  }

  @media screen and (max-width: 599px) {
    .resend-cta {
      margin-bottom: 15px !important;
    }
  }

}

.status-dropmenu.resources-dropmenu {
  &:after,
  &:before {
    left: 31px;
  }

  .img-container {
    margin-right: 4px;
    border: 2px solid transparent;
    border-radius: 50%;

    &.selected {
      border-color: variables.$brand-primary;
    }
  }

  .resource-info {
    margin: 5px 10px;
  }
}
