@use "../../settings/third-party/variables";

.order-import-result{
    .result-banner{
        background-color: #f2f6f8;
        padding: 20px;
        .capsule-btn{
            background-color: #ffffff;
            padding: 7px 10px;
            border: 1px solid #c2bfbf;
            border-radius: 50px;
            max-width: 155px;
            text-align: center;
        }
    }
    .total-stats {
        border: 4px solid variables.$white;
    }
    .button-tab{
        padding: 20px;
        position: absolute;
        top: 155px;
    }
    .alertList-header{
        background-color: #f2f6f8;
    }
    .btn-secondary{
        min-width: 175px;
        justify-content: center;
        border: 1px solid variables.$brand-primary;
        color: variables.$brand-primary;
        background-color: #ffffff;
        &.active{
            background-color: variables.$brand-primary;
            color: #fff;
        }
        &:hover{
            background-color: variables.$brand-primary;
            color: #fff;
        }
    }
    .catalog-header{
        .total-stats{
            background-color: #e9f0f3;
        }
    }
    .desc-text{
        font-size: 30px
    }
    .icon-tick-block{
        height: 20px;
        width: 20px;
    }
    .tab-circles {
        display: flex;
        gap: 10px;
        margin-left: 20px;
    }
    
    .circle {
        width: 10px;
        height: 10px;
        background-color: transparent;
        border: 1px solid rgb(9, 9, 35);
        border-radius: 50%;
        cursor: pointer;
    }
    &.email-thread-main {
        .function-content{
            .content_div{
                background-color: #e9f0f3;
            }
        }
    } 
    // .order-import-icon{
    //     .hexa-icon{
    //         position: absolute;
    //         .ico-table{
    //             position: relative;
    //             right: 30px;
    //             width: 30px;
    //             height: 30px;
    //             bottom: 10px;
    //         }
    //     }
    // }
    
    .circle.active {
        background-color: navy;
        border: 2px solid navy; /* Make the border navy as well for the active state */
    }
    
    .circle.disabled {
        cursor: not-allowed;
        opacity: 0.5; /* Optional: to visually indicate the circle is disabled */
    }   
    
    .tab-content {
        margin-top: 15px;
    }
    .summery-widget{
        .content_div{
            color: black;
            background-color: #e9f0f3;
        }    
        width: 280px;
       
        .order-btn{
            width: 250px;
        }
        .next-step-box{
            padding: 12px;
            background: #fff;
            margin-bottom: 15px;
            gap: 10px;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12);
        }
    }
    .order-import-table{
        padding: 20px;
        margin-top: 50px;
    }
    .set-services-section{
        display: block;
        .section-heading{
            float: left;
            .sub-heading-title {
                font-size: 22px;
                max-width: 550px;
                width: 100%;
            }
        }
        .status-btn{
            border: 1px solid #d9d9d9;
            padding: 10px;
            min-width: 125px;
            justify-content: center;
            .error-btn{
                color: #f55e61;
            }
        }
    }
    .status.success{
        color: #6aa84f;
    }
    .status.warning{
        color: #e69138;
    }
    .status.expired{
        color: #e06666;
    }
    .status.error, .status.waiting{
        color: #cc0000;
    }
    .status.existing, .status.duplicate{
        color: #3c78d8;
    }
    .data-table{
        .update-text{
            font-size: 13px;
            color:#000000 ;
        }
    }
    .tab-open{
        height: 20px;
        width: 20px;
    }
}
.order-import-update{
    .notes-templates-main {
        margin-bottom: 30px;
        .cost-card-section {
            margin-bottom: 30px;
        }
    } 
}
.order-import-alert-list-sidebar{
    .order-update{
        padding: 5px 20px 10px;
    }
    .updated-info{
        background-color: #2b3e50;
        padding: 10px 20px;
    }
}
.order-import-popup{
    h1{
        margin: 5px 0px 20px;
    }
    p{
        font-size: 15px;
    }
    .result-banner{
        background-color: transparent;
        h2{
            margin-top: 0px;
        }
    }
    .bottom-section{
        .md-button{
            min-width: 145px;
            line-height: 1.3;
            padding: 8px;
            font-size: 16px;
        }
        .btn-secondary{
            background-color: #b7b7b7ff;
            border: 1px solid #b7b7b7ff;
            color: variables.$white;
            &:hover{
                background-color: variables.$brand-primary;;
                border: 1px solid variables.$brand-primary;
                color: variables.$white;
            }
        }
    }
}
@media screen and (max-width: 1200px) {
    .order-import-update{
        .notes-templates-main {
            margin-bottom: 20px;
        } 
    }
}
@media screen and (max-width: 765px) {
    .order-import-result{
        .set-services-section{
            display: flex;
        }
        .result-banner {
            gap: 15px;
            display: grid;
        }
        .btn-secondary{
            margin: 0px 0px 15px 0px;
        }
        .button-tab {
            position: relative;
            top: 0px;
            margin-bottom: 0px;
        }
        .order-import-table {
            margin-top: 0px;
        }

    }
}

