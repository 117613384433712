@use "sass:color";
@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

$con-spacer-lg: 80px !default;
$con-spacer-xs: 35px !default;
$post-color: #f9f9f9 !default;
$reply-color: #ececec !default;

// #content {
//   .md-subhead {
//     margin: 0;
//   }
//
//   .contact-desc {
//     padding-left: 4px;
//   }
// }

.status-dropmenu {
  &.conv-dropmenu {
    min-width: 70px;

    .edit-button {
      font-size: variables.$font-size-small-plus;
    }
  }
}

.status-dropmenu.convers-editmenu {
  width: 100px;

  button {
    font-size: variables.$font-size-small;
    font-weight: 400;
  }

  &:before {
    margin-right: 27px;
  }

  &:after {
    margin-right: 28.5px;
  }

  md-menu-item {
    height: 36px;
    min-height: 36px;
  }
}

.conversations {
  .save-conversation {
    //background-color: rgb(201, 232, 241, 71%);
    background-color: rgb(201, 232, 241);
    padding: 8px;
  }

  .default-box {
    margin: 6px 8px 20px;
  }

  .members-count {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: rgb(162, 243, 179);
    margin-left: -12px;
    position: relative;
  }

  .reply-count {
    @extend .members-count;
    height: 18px;
    width: 18px;
  }

  .select-tab-option {
    border: 1px solid rgb(205, 202, 202);
    min-width: 140px;
    padding: 3px 5px;
    min-height: 60px;
  }
  
  .md-virtual-repeat-container.md-autocomplete-suggestions-container {
    width: 282px;
  }

  md-list-item::before,
  md-list-item .md-list-item-inner::before {
    min-height: 0;
  }

  .conversation-list .content-wrapper .post-content .attach md-list-item md-icon.delete-attachment-icon {
    margin-left: 5px;
  }

  .delete-attachment-icon path {
    fill: #8E8E93;
  }

  .new-conversation h2 {
    display: inline-flex;
  }

  .new-conversation .item-chips li {
    margin-bottom: 10px;
  }

  .calendar-sidebar .bottom-section {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    height: 50px;
  }

  .calendar-sidebar .top-section {
    top: 0;
    bottom: 50px;
    overflow-y: scroll;
    position: absolute;
    right: 0;
    left: 0;
  }

  .md-chips-messages {
    color: #dd2c00;
    position: relative;
    top: -20px;
    overflow: hidden;
  }

  .md-chips-messages [ng-message] {
    line-height: 14px;
    overflow: hidden;
    padding-top: 5px;
  }

  .custom-chip {
    /* padding: 2px; */
    color: white;
    border-radius: 15px;
    height: 24px;
    font-size: 10px;
  }

  .custom-chip .notes-modelId {
    color: #fff;
  }

  .custom-chip md-icon {
    height: 15px;
    width: 15px;
  }

  /*@media (max-width: 500px) {
      .custom-chip {
        max-width: 150px;
      }
    }
  */
  .custom-chip span.active {
    color: #fab963;
    cursor: pointer;
  }

  .md-icon-button .notes-md-icon {
    width: 30px;
    height: 30px;
  }

  .status-dropmenu.time-lapse-menu {
    width: 200px;
    border-radius: 0;
  }

  .menu-empty-state.empty-state .empty-action-icon {
    height: 150px;
    width: 150px;
  }

  .menu-empty-state.empty-state {
    transform: translateY(20px);
  }

  .templates-all-btn {
    min-width: 55px;
  }

  .proposal-sidenav.notes-templates-main {
    padding-top: 5px;
  }

  .notes-empty-state.empty-state {
    transform: translateY(0%);
  }

  .archive-img-container {
    height: 30px;
    width: 30px;
    line-height: 30px;
  }

  .img-replacer {
    height: 24px;
    width: 24px;
    line-height: 24px;
    font-size: 10px;
  }

  .user-contact .img-replacer {
    height: 52px;
    width: 52px;
    line-height: 52px;
    font-size: 16px;
  }
  .members-count:first-child {
    margin-left: 0;
  }

  .custom-icon{
      height: 12px;
      width: 12px;
  }

  .members-count:nth-child(n) {
    z-index: calc(100 - var(--index));
  }

  .new-conversation {
    margin-bottom: 11px;
  }

  &.notes {
    &.status-dropmenu:before {
      left: 8px;
    }

    &.status-dropmenu:after {
      left: 8px;
    }
  }

  .assigned-labels {
    min-width: 140px;

    md-icon {
      width: 10px;

      svg {
        path {
          fill: #fff;
        }
      }
    }
  }

  .follow-up {
    border: 1px solid variables.$brand-primary;
    border-radius: 15px;
    padding: 2px;
    font-size: variables.$font-size-small-plus;
    background-color: variables.$white;
    min-width: 100px;
    display: flex;
    justify-content: center;

    &.post-follow-up,
    &.post-follow-up:hover {
      background-color: #d2a3d3;
      border-color: #d2a3d3;
      color: variables.$white;
      // position: absolute;
      // right: -20px;
    }
  }

  .follow-up:hover {
    background-color: variables.$brand-primary;
    color: variables.$white;
  }

  .slider-container {
    position: relative;
  }

}

.conversation-add {
  h2 {
    margin: 0 0 20px;

    md-icon {
      margin-right: 10px;
    }
  }

  .conversation-form {
    .type-of-conversations {
      background: variables.$header-bgcolor;

      li {
        display: inline-block;

        .md-button {
          color: variables.$white;

          &.active {
            color: variables.$brand-primary;

            svg path {
              fill: variables.$brand-primary;
            }
          }
        }

        md-icon {
          height: 20px;

          svg path {
            fill: #ffffff;
          }
        }
      }
    }

    md-input-container {
      margin: 0;
    }

    .ql-container.ql-snow,
    .ql-toolbar.ql-snow {
      border-color: variables.$header-bgcolor;
    }

    .share-btn {
      margin-right: 0;
    }
  }
}
.reply-options {
  transition: opacity 0.3s ease, visibility 0.3s ease;
  background-color: white;
  border-radius: 10px;
  max-width: 360px;
  position: absolute;
  top: 35px;
  z-index: 1000;
  top: 0;
  right: 210px;
  border: 1px solid rgb(203, 202, 202);
}
.content:hover {
  background-color: #e7f3f8;
}

.conversations {

  .conversation-type,
  .conversation-type-notes {
    li {
      padding: 1px 6px;
      border-left: 4px solid variables.$border-color;
      margin-bottom: 8px;
      font-size: variables.$font-size-base;
      font-weight: 500;

      &.active {
        border-color: variables.$brand-primary;

        * {
          color: variables.$brand-primary;
        }
      }
    }

    li:hover {
      font-size: 16px;
    }
  }

  .load-more-replies {
    padding-bottom: 20px;
    margin-left: 35px;
    border-left: 4px solid variables.$border-color;
  }
  .clock-icon {
    height: 16px;
    width: 16px;
    svg {
      path{
        fill: variables.$brand-accent;
      }
    }
  }
}
.menu-disabled {
 pointer-events: none;
 opacity: 0.5; 
 filter: grayscale(100%); 
}
.expand-on-hover:hover {
  div{
    color: variables.$brand-primary;
  }
}
.angle-down {
  height: 20px;
  width: 20px;
  svg{
    path {
      fill: black;
    }
  }
}
.expand-icon {
  height: 16px;
  width: 16px;
}
.angle-right {
  // height: 12px;
  // width: 12px;
  transform: rotate(-90deg);
}
.conversation-type,
.conversation-type-notes {
  // li {
  //   padding: 1px 6px;
  //   border-left: 4px solid variables.$border-color;
  //   margin-bottom: 8px;
  //   font-size: variables.$font-size-base;
  //   font-weight: 500;
  // }

  // li:nth-of-type(1) {
  //   // border-color: #D3B188;

  //   * {

  //     // color: #D3B188;
  //   }
  // }

  // li:nth-of-type(2) {
  //   border-color: #CCB6A3;

  //   * {
  //     color: #CCB6A3;
  //   }
  // }

  // li:nth-of-type(3) {
  //   border-color: #C9BEB5;

  //   * {
  //     color: #C9BEB5;
  //   }
  // }

  // li:nth-of-type(4) {
  //   border-color: variables.$gray-light;

  //   * {
  //     color: variables.$gray-light;
  //   }
  // }

  // li:nth-of-type(5) {
  //   border-color: variables.$gray-lighter;

  //   * {
  //     color: variables.$gray-lighter;
  //   }
  // }

  // li:nth-of-type(6) {
  //   border-color: variables.$gray-lighter;

  //   * {
  //     color: #D5D5D5;
  //   }
  // }

  // li:nth-of-type(7) {
  //   border-color: variables.$gray-lighter;

  //   * {
  //     color: #D5D5D5;
  //   }
  // }
  li:nth-of-type(1) {
    border-color: #2f6d6b; // Even darker teal as the starting point
  
    * {
      color: #2f6d6b;
    }
  }
  
  li:nth-of-type(2) {
    border-color: #3f817f; // Slightly lighter than the first
  
    * {
      color: #3f817f;
    }
  }
  
  li:nth-of-type(3) {
    border-color: #519494; // Gradual lightening
  
    * {
      color: #519494;
    }
  }
  
  li:nth-of-type(4) {
    border-color: #68a5a5; // Balanced tone
  
    * {
      color: #68a5a5;
    }
  }
  
  li:nth-of-type(5) {
    border-color: #84b7b6; // Softer teal
  
    * {
      color: #84b7b6;
    }
  }
  
  li:nth-of-type(6) {
    border-color: #9cc7c6; // Faint but visible
  
    * {
      color: #9cc7c6;
    }
  }
  
  li:nth-of-type(7) {
    border-color: #a7c7c7; // Slightly darker grey for the last item
  
    * {
      color: #a7c7c7;
    }
  }
  
}

.md-subheader-wrapper.md-sticky-clone {
  .conversation-title {
    background: hsl(0, 0%, 21%);
  }
}

.conversation-list {
  position: relative;

  .content {
    padding: 8px;
    position: relative;

    border-bottom: 1px solid #dde0e4;
    border-top: 1px solid #dde0e4;

    // border-top: 1px solid #ecf3f9;
    // background-color:  #f7fbffff;
    .comment-option-btn {
      min-width: 10px;
      margin: 2px 0;
      border-radius: 10px;
    }

    .avtar-img {
      height: 20px;
      width: 20px;
    }

    
  }
  .content:hover {
    background-color: rgb(235, 243, 250);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .attach-function-opportunities,
  .attach-function-default {
    // background-color: #f4faf4ff;
    border-right: 5px solid #63bf60ff;
  }

  .attach-function-jobs {
    // background-color: #fff6f5ff;
    border-right: 5px solid #ef4135ff;
  }

  .attach-function-quotes {
    // background-color: #fcf7ffff;
    border-right: 5px solid #a07cbcff;
  }

  .attach-function-multiple {
    background-color: #f5ffffff;
  }

  // .comment-content {
  //   min-width: 40%
  // }

  // .comment-opts {
  //   padding: 2px 5px;
  //   min-width: 35%;
  // }

  // .on-active-comment {
  //   background-color: white;
  //   border: 1px solid #e2ecf1;
  //   // padding: 2px 10px;
  // }
  .comment-content {
    display: flex;
    flex-direction: row;
    gap: 20px;
    min-width: 30%;
    padding: 5px 10px 5px 4px;
    justify-content: flex-start;
    flex-shrink: 0; 
    height: 30px;
    .angle-right {
      display: none;
      height: 20px;
      width: 20px;
    }
    .last-update {
      display: block;
    }
    .view-thread {
      display: none;
    }
  }
  
  .comment-opts-container {
    display: none;
    padding: 5px 0;
    // background-color: white;
    min-width: 35%;
    border: 1px solid #e2ecf1;
    position: absolute;
    right: 0;
    z-index: 1;
  }
  
  .comment-content:hover {
    background-color: white;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    // transform: translateY(-5px);
    border-radius: 6px;
    border: 1px solid rgb(201, 198, 198);
    .angle-right, .view-thread {
      display: block;
    }
    .last-update {
      display: none;
    }
  } 
  
  .on-active-comment {
    background-color: white;
    border: 1px solid #e2ecf1;
  }
  
  .comment-opts {
    padding: 2px 5px;
    min-width: 42%;
    background-color: white;
  }
  
  .comment-content {
    min-width: 30%;
    &.open-sidenav {
      width: 80%;
    }
  }
  

  .reply-comments {
    /* padding:16px 20px 8px 30px !important; */
    // background-color: #20394c;

    .cust-details {
      font-size: variables.$font-size-small - 1;
    }

    .down-arrow {
      height: 12px;
      width: 12px;
    }

    .notify-chip {
      display: inline-block;
      // background: variables.$brand-primary;
      color:  variables.$brand-primary !important;
      font-weight: 700;
      font-size: 12px;
      text-align: center;
      line-height: 2;
      // padding: 0 2px 0 5px !important;
      display: flex;
      border-radius: 2px;
      min-width: 38px;
      height: 25px;
      box-sizing: content-box;
    }
    .notify-chip-add {
      @extend .notify-chip;
      background: variables.$brand-primary;
    }
  }

  p {
    margin: 0;
  }

  .conversation-block {
    .main-container {
      width: 100%;
      // height: 100%;
      overflow: hidden;
    }

    .main-container .main-section {
      width: 100%;
      transition: width 0.3s ease-in-out;

      &.half-width {
        width: 52%; // Shrink to half when the sidebar is open
      }
    }
    .full-width {
      width: 100%;
    }
    .on-reply-hover:hover {
      background-color: #f0f6f8;
    }
    .msg-count {
      display: inline-flex; 
      align-items: center;  
      border-radius: 30px;
      border: 1px solid rgb(115, 115, 115);
      padding: 0 8px; 
      white-space: nowrap; 
      background-color: #e7f3f8;
    }
    .thumbs-up-icon {
      height: 14px;
      width: 14px;
      svg {
        path {
          fill: rgb(60, 60, 60)
        }
      }
    }
    .active {
      .content-wrapper {
        .content-inner-wrap {
          &:before {
            background: variables.$brand-primary;
          }
        }

        .inner-wrapper {
          border: 1px solid variables.$brand-primary;
          box-shadow: 0 0 11px -3px variables.$brand-primary;

          &:after,
          &:before {
            border-color: transparent variables.$brand-primary transparent transparent;
          }
        }

        .update-info md-icon svg path {
          fill: variables.$brand-primary;
        }
      }
    }

    .remove {
      position: absolute;
      height: 10px !important;
      width: 10px !important;
    }

    .shared-img-list {
      li {
        display: inline-block;
        margin: 8px 8px 0 0;
        position: relative;
      }

      .remove {
        top: -5px;
        right: -5px;
      }
    }

    .attach .file-holder {
      position: relative;

      .remove {
        right: -25px;
        top: 2px;
      }
    }
  }

  .conversation-title {
    background: hsl(0, 0%, 28%);
    color: variables.$white;
    width: 158px;
    text-align: center;
    padding: 6px 0;
    border-radius: 3px;
    font-size: variables.$font-size-base;
    font-weight: 500;

    .md-subheader-inner {
      padding: 1px 0 2px;
    }

    .channels-navigate-icon {
      top: -10px;
      right: -7px;
      position: absolute;
      display: none;
    }
  }

  .conversation-title:hover {
    .channels-navigate-icon {
      display: block;
    }
  }

  .title-wrapper {
    height: 70px;
  }

  .content-type-icon {
    // width: variables.$con-spacer-lg;
    position: absolute;
    left: -15px;
    text-align: right;
    float: left;
    padding-right: 15px;
    margin-top: 10px;

    md-icon {
      width: 20px;
      height: 20px;

      svg path {
        fill: #000000;
      }
    }
  }

  .content-inner-wrap {
    position: relative;

    &:before {
      content: "";
      height: 10px;
      width: 10px;
      background: #000000;
      border: 3px solid #fff;
      position: absolute;
      left: -10px;
      border-radius: 50%;
      top: 12px;
    }
  }

  .conversation-block>li:first-child section {
    padding-top: 30px;
  }
  
  .content-wrapper {
    padding-bottom: 15px;
    float: left;
    // width: calc(100% - #{variables.$con-spacer-lg});
    width: 98%;
    border-left: 4px solid variables.$border-color;
    margin-left: 11px;
    .calendar-title {
      color: variables.$brand-accent;
    }
    .post-content {
      cursor: text;
    }
    .post-title {
      font-size: 16px;
      margin-bottom: 8px;
      // padding-right: 50px;
      color: color.adjust(variables.$gray, $lightness: 15%);

      .post-title-notes {
        font-size: 15px;
      }

      .font-small {
        font-size: 14px;
      }
    }

    .post-content {
      @extend .f1;

      .shared-img-list {
        margin-top: 15px;
      }

      md-list {
        padding: 0;

        md-icon {
          filter: grayscale(50%);
          fill: variables.$gray-light;
        }
      }

      .attach {
        md-list-item {
          padding: 0;
          min-height: auto;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          md-icon {
            margin: 0 5px 0 0;
            width: 14px;
            height: 14px;
          }

          a {
            font-size: variables.$font-size-small;
          }

          .md-button {
            margin: 0;
            padding: 0;
            line-height: 14px;
          }
        }
      }

      .img-container {
        display: inline-block;
        padding: 10px;
        border: 1px solid variables.$border-color;
        border-radius: 3px;
        box-shadow: 1px 1px 0 variables.$border-color;
        overflow: hidden;

        img {
          max-height: 200px;
          max-width: 200px;
        }
      }
    }

    .reply-icons {
      height: 18px;
      width: 18px;
    }

    md-menu {
      .menu-btn {
        right: 0;
        transform: rotate(90deg);
        color: color.adjust(variables.$gray, $lightness: 15%);
        &:before {
          font-size: 20px;
          padding: 7px;
        }
      }

      .md-icon-button {
        margin: 0;
        padding: 0;
        min-height: 20px;
        width: 25px;
        line-height: 6px;
        height: 25px;

        md-icon {
          height: 10px;
          width: 10px;
        }
      }
    }

    .inner-wrapper {
      padding: 10px;
      margin-left: 15px;
      border-radius: 3px;
      position: relative;
      // background-color: variables.$post-color;
      border: 1px solid variables.$gray-lighter;

      &:after,
      &:before {
        width: 0;
        height: 0;
        content: "";
        position: absolute;
      }

      &:focus {
        outline: none;
      }

      &:before {
        top: 11px;
        left: -16px;
        border: 8px solid;
        border-color: transparent variables.$gray-lighter transparent transparent;
      }

      &:after {
        top: 12px;
        left: -14px;
        border: 7px solid;
        border-color: transparent $post-color transparent transparent;
      }
    }
  
    .note-wrapper {
      padding: 0 10px;
      margin-bottom:10px;
      
      // background-color: variables.$white;
    }

    .creator-info {
      margin-left: 15px;

      .small-btn {
        line-height: 20px;
        min-height: 20px;
      }

      .small-btn:last-of-type {
        margin-right: 0;
      }
    }

    .update-info {
      margin: 3px 0 5px 15px;

      .time-at {
        font-size: 10px;
        margin-right: 6px;
        color: color.adjust(variables.$gray, $lightness: 15%);
      }

      .custom-chips li {
        margin: 4px 0;
      }

      .updated-by {
        margin-left: 10px;
      }
    }

    .comments-cta md-icon {
      width: 20px;
      height: 15px;
      vertical-align: sub;

    }

    #fetchComments {
      transform: rotate(90deg);
      transition: transform linear 0.2s;

      &.opened {
        transform: rotate(-90deg);
      }
    }

    .reply-cta {
      margin-right: 15px;

    }

    .functions {
      font-size: variables.$font-size-large - 4;
    }

    .reply {
      color: variables.$white;
    }
  }

  &.segmented {
    .content-inner-wrap:before {
      top: 44px;
    }

    .content-type-icon {
      position: relative;
      top: 32px;
    }
  }

  .comments-block {
    margin-left: 35px;

    .content-wrapper {
      width: calc(100% - 45px);
      padding-top: 0 !important;

      .inner-wrapper {
        margin-left: 30px;
        background-color: variables.$white;
        border-color: $reply-color;

        &:after {
          border-right-color: variables.$white;
        }

        &:before {
          border-right-color: $reply-color;
        }
      }

      .update-info {
        margin-left: 30px;
      }
    }

    .inner-wrapper {
      .content {
        background-color: rgb(169, 113, 169);
      }

      .reply-comments {
        background-color: rgba(7, 7, 30, 0.171);
      }
    }

    .content-inner-wrap {
      &:before {
        background: #fff;
        border: 1px solid #ccc;
        left: -8px;
        top: 13px;
      }
    }
  }
}

.module-content {
  margin: 25px 0;
  max-width: 965px;

  .detail-cards-div {
    padding: 10px;
    max-width: 115px;
    min-width: 115px;
    border: 1px solid #cccccc;
    width: 100%;
    display: flex;
    position: relative;
    cursor: pointer;

    .detail-card-content {
      h3 {
        margin: 0;
      }

      p {
        color: #666666;
        margin: 5px 0 0;
        font-size: 10px;
      }
    }

    .hexa-icon {
      position: absolute;
      right: 0;
      bottom: 28px;
      left: 88px;

      .ico-table {
        min-width: 35px;
        height: 49px;
      }
    }
  }

  .detail-cards-div.orange-bg {
    background-color: #ffab40ff;
    border: 1px solid #ffab40ff;
  }

  .detail-cards-div:hover {
    border: 1px solid #ffab40ff;
  }
}


.model-icons {
  height: 20px;
  width: 20px;
}
// @media screen and (max-width: 1279px) {
//   .update-info {
//     .comments-cta {
//       margin-top: 10px;
//     }
//   }
// }

@media screen and (max-width: 1020px) {
  .conversation-list {
  .comment-content {
    min-width: 40%;
  }
  .comment-opts {
    min-width: 100%;
  }
}
}
@media screen and (max-width: 599px) {
  .sidenav-wrapper {
    .edit-sidenav {
      width: 100%; 
      max-width: 80%;
    }
  }
  .conversation-list {
    // .content-wrapper {
    //   width: calc(100% - #{$con-spacer-xs});
    // }
   
    .top-sec-align {
      gap: 2px;
    }
    .convo-wrap-container {
      margin-left: 0;
    }
    .comments-block {
      width: 100%;
      margin-left: $con-spacer-xs;
    }
    .comment-content {
      min-width: 90%;
    }
    .comment-opts {
      min-width: 90%;
    }
  }
 
  .conversations {
    .main-container {
      display: flex;
      width: 100%;
      transition: all 0.3s ease-in-out;
      /* Smooth transitions */
    }

    .conversation-type {
      margin-bottom: 15px;
      padding: 0 15px;
      background: variables.$white;
      overflow-x: auto;
      left: 0;
      right: 0;
      z-index: 1;
      box-shadow: variables.$gray-light 1px 1px 6px;

      li {
        border: 1px solid variables.$border-color;
        margin: 12px 1px;
        padding: 5px;
      }
    }

    .load-more-replies {
      margin-left: $con-spacer-xs;
    }
  }

  .conversation-list .content-type-icon {
    width: auto;
  }

  // .conversation-list {
  //   .conversation-title {
  //     left: -50;
  //   }
  // }
}

@media screen and (max-width: 670px) {
  .sidenav-wrapper {
    .edit-sidenav {
      width: 100%; 
      max-width: 70%;
    }
  }
  .conversation-list {
    .top-sec-align {
      gap: 0;
    }
    .convo-wrap-container {
      margin-left: 0;
    }
    .comment-content {
      min-width: 75%;
    }
    .comment-opts {
      min-width: 100%;
    }
  }
  .hide-content {
    display: none;
  }
}

@media screen and (max-width: 650px) {
  .sidenav-wrapper {
    .edit-sidenav {
      width: 100%; 
      max-width: 70%;
    }
  }
  .conversation-list {
    .conversation-block{
      
    }
  }
}

@media screen and (max-width: 750px) {
  .conversation-list {
    .conversation-block{
    }
    .top-sec-align {
      gap: 10px;
    }
    .comment-content {
      min-width: 75%;
    }
    .comment-opts {
      min-width: 100%;
    }
  }
  .hide-labels {
    display: none;
  }
}

@media screen and (max-width: 399px) {
  .sidenav-wrapper {
    .edit-sidenav {
      width: 100%; 
      max-width: 90%;
    }
  }
  .conversation-list {
    .top-sec-align {
      gap: 0;
    }
    .convo-wrap-container {
      margin-left: 0;
    }
    .content-wrapper {
      .update-info {
        .user-name {
          max-width: 100px;
        }

        .updated-by {
          margin-left: 0;
        }
      }
    }
    .comment-content {
      min-width: 100%;
    }
    .comment-opts {
      min-width: 100%;
    }
    .comments-block {
      .update-info .user-name {
        max-width: 85px;
      }
    }
  }
}

#all-converstaions {
  .form-base {
    .md-block {
      top: 0;
    }
  }
}

.form-base {
  .filter-btn {
    border: 2px solid #a8b6c2;
    border-radius: 5px;

    md-icon {
      width: 18px;
      height: 18px;
    }
  }
}

.cta-row {
  .title-blue {
    color: #5cc8fc;
  }

  .icon_png {
    height: 30px;
  }

  .side-nav-icon {
    height: 20px;
  }
}

.conversations {
  .label-filter {
    width: 30px;
    height: 10px;
    border-radius: 20px;
  }

  .selected-label {
    width: 95px;
    position: absolute;
    left: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .md-icon-button-notes {
    width: 86px;
    height: 62px;
  }

  .label-button {
    padding: 0px 3px;
  }

  .label-capsule {
    min-width: 25px;
    padding: 6px;
    border-radius: 20px;
  }

  .text-blue {
    color: #839eef
  }

  .time-lapse-header {
    color: #fff;
    background-color: #000000;

    h3 {
      font-size: 14px;
    }

    md-icon {
      height: 20px;
      width: 15px;
    }
  }

  .time-lapse-padding {
    padding: 15px 15px 15px 5px;
  }

  .function-attached-padding {
    padding: 15px 10px;

    &.function-attached-channels {
      background-color: #20394c;
    }

    &.function-attached-row {
      padding-right: 2px;
      line-height: 1;
      font-size: 13px;
    }
  }

  .no-function-attached {
    .bold-text {
      font-size: 13px;
    }
  }

  .no-function-attached-button {
    .md-button {
      min-width: 0;
      min-height: 0;
      line-height: 0;
    }

    md-icon {
      width: 22px;
      height: 17px;
    }

    &.notify-chip {
      padding-left: 2px !important;
    }
  }

  .function-attached-icon {
    width: 30px;
    height: 30px;
  }

  .time-lapse-menu-btn {
    transform: rotate(90deg);
  }

  .notify-chip-icon {
    height: 12px !important;
    width: 12px !important;
    svg {
      path {
        fill: variables.$brand-primary;
      }
    }
  }

  &.function-attached-menu {
    width: 320px;

    .status-dropmenu:after,
    .status-dropmenu:before {
      left: 0;
    }

    .custom-chip {
      color: #000;
    }
  }

  @media(max-width: 350px) {
    &.function-attached-menu {
      width: 250px;
    }
  }

  .action-item-channels {
    background-color: rgba(211, 225, 229, 71%);
    padding: 10px 10px 10px 15px;

    .action-item-label:hover {
      font-weight: bold;
    }

    md-icon {
      width: 20px;
      height: 20px;
    }
  }
}

.clockin-screen {

  .rotate-address-full-icon {
    transform: rotate(90deg);
  }

  .rotate-address-less-icon {
    transform: rotate(0deg);

  }

  .left-arrow {
    transform: rotate(180deg);
  }

  .mr-bottom-4 {
    margin-bottom: 10px !important;
  }

  .text-white {
    color: #ffffff;
  }

  .customer-dashboard {
    .task_number_status {
      color: #999999;
      font-size: 16px;
      margin-top: 5px;
      margin-left: 90px;
    }

    .grid {
      display: grid;
    }

    .icon_png {
      width: 50px;
      margin-right: 20px;
    }

    h1 {
      font-size: 20px;
    }

    .description {
      margin-left: 70px;
    }
  }

  .custom-default-box {

    .first_cell {
      max-width: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;

      //background-color: #14354cff;
      padding: 15px;

      .profile-pic {
        width: 50px;
      }

      .page-tag {
        //color: #20394c;
        color: #ffffff;
        font-size: 18px;
      }

      .icon_png {
        margin-right: 0px;
        width: 40px;
        height: 40px;
      }

      &.top-header {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        background-color: #14354cff;
      }
    }

    .description {
      margin-left: 0px;

      h3 {
        color: #999999;
        margin-bottom: 5px;
        font-size: 18px;
      }

      p {
        color: #999999;
        font-size: 16px;
        margin-top: 5px;
      }

    }
  }

  .text-white {
    color: white !important;
  }

  .count-chip {
    background-color: #14446b;
    width: 55px;
    padding: 4px;
    text-align: center;
    // margin-top: 14px;
  }

  .nav-icon {
    height: 30px !important;
    border-radius: 5px;
    background: #ee673d;
    min-width: 65px !important;
    padding: 3px;

    .icon-height {
      height: 28px;
      filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.2));
    }
  }

  .form-complete {
    border-radius: 10px;
    border: 1px solid white;
    background-color: rgb(1, 2, 21);
  }

  .complete-btn {
    background-color: #ea8538;
    border-radius: 2px;
    min-width: 250px;
    padding: 5px;
    min-height: 50px;
  }

  .pending-btn {
    @extend.complete-btn;
    background-color: white;
    border: 1px solid #ea8538;
    color: #ea8538;
  }

  .header-wrapper {
    width: 100%;
  }

  .list-view {
    .image-conatiner {
      position: relative !important;
    }

    .media-pic {
      position: absolute;
      right: 5px;
      margin-top: -10px;
    }

    .document {
      min-width: 320px;
      width: 100%;
      height: 200px;
      border: 4px solid rgb(54, 90, 235);
      background: white;
      padding: 5px;
    }
  }

  .grid-view {
    .grid-pic {
      position: relative !important;
    }

    .profile-pic {
      position: absolute;
      top: -10px;
      right: 5px;
      width: 35px;
      height: 35px;
    }

    .document-pic {
      min-width: 150px;
      // width: 100%;
      height: 100px;
      border: 4px solid rgb(54, 90, 235);
      background: white;
      padding: 5px;
    }
  }

  .delete-icon {
    height: 25px;
    width: 25px;
  }

  .business-tab-section {
    .form-accordion {
      min-height: 220px;
      overflow: hidden;
    }



    .first-row {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
    }

    .second-row {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      width: 100%; // Ensure second row takes full width
      flex-wrap: wrap;
    }

    .accordion-metrics {
      display: flex;
      gap: 10px;
      width: 100%;
    }

    .form-acc-content {
      text-align: center;
      display: flex;
      //   flex-grow: 1; 
      min-width: 105px;
      width: 100%;
      height: 70px;
      background-color: white;

      .total-border {
        width: 5px;
        min-height: 70px;
        background-color: #40f075;
      }

      .filled-border {
        @extend .total-border;
        background-color: #dbf040;
      }

      .mand-border {
        @extend .total-border;
        background-color: #f03a2a;
      }
    }


    .status-section {
      margin-right: 15px;
    }

    .accordion-content {
      width: 100%;
      background: linear-gradient(#dde8ee, #f3f7fa);
      display: block;
    }
  }

  .sidenav-content-bg {
    background: linear-gradient(#133146, #091721);

  }

  .custom-default-box.body-container {
    padding: 10px;

    // background: linear-gradient(#15354c, #517fa0);
    .first_cell {
      //background-color: #52748d;
      padding: 10px;
      border-radius: 10px;
    }

    .profile-detail {
      width: 100%;
    }

    .service-card {
      width: 100%;
      margin-bottom: 5px;

      md-icon {
        margin: 0px 10px 0px 0px;
        width: 20px;
      }

      .service-card-tab {
        margin-bottom: 10px;

        .customer-contact-info p {
          margin: 0px;
        }
      }

      .profile-pic {
        width: 25px;
        height: 25px;
        margin-right: 5px;
      }

      .border {
        padding: 4px;
        background-color: #00ff00ff;
        border-radius: 10px;
        position: relative;
        top: -2px;
        width: 100%;
      }

      .first_cell {
        background-color: #ffffff;
        padding: 0px 0px;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        margin-bottom: 25px;
      }

      .first_cell .clockedin-stats {
        background-color: #ecf3f9;
        padding: 0px 10px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      .first_cell .clockedin-stats p.jobs-count {
        color: #284d66;
        font-size: 16px;
        margin-bottom: 10px;
      }

      .first_cell .clockedin-stats p.assignments-stats {
        color: #284d66;
        font-size: 14px;
        text-align: center;
      }

      .clockin-assignment-status {
        padding: 10px 5px;
        gap: 10px;

        span {
          min-width: 100px;
          width: 100%;
          text-align: center;
          color: #ffffff;
          border-radius: 20px;
          font-size: 14px;
          font-weight: 600;
          padding: 5px;
        }

        .payment-status {
          background-color: #351d75;
        }

        .arrival-status {
          background-color: #36771d;
        }

        .situation-status {
          background-color: #0c5395;
        }
      }

    }

    .contact-line {
      .icon_png {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }

      .installation-service-section {
        font-size: 16px;
        color: #000000 !important;
        margin: 0px 0px 0px;
      }

      .service-status {
        font-size: 14px;
        margin-top: 5px;
        margin-bottom: 5px;

        &.accepted {
          color: #6aa84f;
        }
      }

      .location {
        background-color: #56c0beff;
        color: #ffffff;
        min-width: 40px;
        padding: 5px 10px;
        font-size: 12px;
        text-align: center;
        border-radius: 18px;
      }

    }

    .archive-img-container .flat-icons {
      .icon_png {
        margin-right: 0px;
        width: 35px;
        height: 35px;
      }
    }

    .service-status {
      .status {
        color: #949494;
      }
    }

    .customer-card {
      color: #acd5f0;
      padding: 15px 10px 0px;
      width: 100%;

      .clockedin-stats {
        .divider {
          width: 3px;
          height: 65px;
          vertical-align: middle;
          align-items: center;
          background-color: #ffffff;
          border: 1px solid #ffffff;
          display: flex;
          margin-top: 10;
        }
      }

      .field-value-container {
        background-color: transparent;
        color: #acd5f0;
        width: 100%;
        padding: 13px 0px;
        border-radius: 5px;

        p {
          margin: 0px;
          font-size: 13px;

          &.jobs-count {
            font-weight: 600;
            color: #ffffff;
            font-size: 20px;
            margin-bottom: 15px;

          }

          &.assignments-stats {
            color: #acd5f0;
          }
        }

        &.clockedin-stats-timing {
          text-align: left;
          display: grid;
          min-width: 70px;
          max-width: 85px;
          width: 100%;
        }

        &.clockedin-stats-duration {
          text-align: right;
          display: grid;
          margin-right: 0px;
          justify-content: end;
          min-width: 70px;
          max-width: 85px;
          width: 100%;
        }

        &.clockedin-stats-status {
          p.jobs-count {
            color: #acd5f0;
          }

          .clockedin-stats-status-circle {
            height: 15px;
            width: 15px;
            padding: 15px;
            border-radius: 50px;
          }
        }

        .clockedin-stats-status-circle.inactive {
          background-color: #cc0000ff;
        }

        .clockedin-stats-status-circle.active {
          background-color: #00ff00ff;
        }

        .clockedin-stats-status-circle.on_break {
          background-color: rgb(240, 218, 52);
        }
      }
    }

    .btn.gradient-btn {

      background-image: linear-gradient(#fcbf6f, #f49310);
      box-shadow: rgba(131, 50, 234, 0.01) 0 41px 17px, rgba(131, 50, 234, 0.05) 0 23px 14px, rgba(131, 50, 234, 0.09) 0 10px 10px, rgba(131, 50, 234, 0.1) 0 3px 6px;
      transition: all 0.3s ease-in-out;
      transition-behavior: normal;
      color: #ffffff;

    }

    .btn {
      color: #ff9501;
      border: 1px solid #ff9501;
      background-color: #ffffff;
      border-radius: 25px;
      min-width: 120px;
      max-width: 200px;
      width: 100%;
      //   padding: 25px;
    }

    .btn-secondary {
      color: #ff9501;
      border: 1px solid #ff9501;
      background-color: #ffffff;
    }

    .filter-section {
      .flat-icons-bg {
        background-color: #ffffff;
        border-radius: 50px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon_png {
          width: 20px;
          height: 20px;
        }
      }

      .flat-icons {
        .icon_png {
          width: 30px;
          height: 30px;
        }

        .customer-name {
          font-size: 15px;
        }
      }
    }

    .customer-name {
      font-weight: 500;
      font-size: 22px;
      margin: 8px 0;
      color: #ffffff;

      &.assign-date {
        font-size: 20px;
        text-align: end;
        margin-bottom: 0px;
      }

      &.assign-day {
        font-size: 14px;
        text-align: end;
        justify-content: end;

        a {
          margin-right: 0px;
          color: #84adc7;
        }
      }

      &.clockin {
        text-align: start;
        justify-content: start;
      }
    }

    .task_number_status {
      color: #999999;
      font-size: 16px;
      margin-top: 15px;
      margin-bottom: 0px;
      margin-left: 0px;
    }

    md-list-item .md-list-item-inner:before,
    md-list-item:before {
      min-height: 0px;
      visibility: hidden;
    }

    .label-key {
      margin-right: 10px;
    }

    .quote-alias {
      width: 100%;
    }

  }

  .comment-content {
    background-color: white;
    min-height: 100px;
    display: flex;
  }

  .comment-pic {
    .img-replacer {
      background-color: rgb(240, 227, 217);
      height: 35px;
      width: 35px;
      line-height: 35px;
      border-radius: 50%;
    }
  }

  .cmt-pic {
    width: 35px;
    height: 35px;
  }

  .fixed-bottom {
    position: absolute;
    bottom: 5px;
  }

  .document-upload {
    position: fixed;
    bottom: 180px;
    // left: 0;
    // right: 0;
    // height: 85vh;
    background-color: rgb(224 235 237);

    .upload-img {
      background-color: white;
      border: 1px solid #EF4135;
      border-radius: 20px;
      min-width: 160px;
      padding: 10px;
      color: #EF4135;
    }

    .camera-img {
      @extend .upload-img;
      background-color: #EF4135;
      color: white;
    }

    .grid-pic {
      position: relative !important;
    }

    .profile-pic {
      position: absolute;
      top: -15px;
      left: 20px;
      width: 55px;
      height: 55px;
    }


    .widget-visibily {
      opacity: 1;
      transition: opacity 0.3s ease;

      &.hide {
        opacity: 0;
        display: none !important;
      }
    }

    .format-bar {
      background-color: black;
    }

  }

  .post-add {
    left: 0;
    right: 0;
  }

  .reply-post {
    right: 0;
    max-width: 360px;
    min-width: 320px !important;
    bottom: 0;
  }

  .comment-accord {
    min-width: 280px;
    width: 100%;

    .expand-date {
      background-color: rgb(235, 241, 241);
      box-shadow: none;
      border-radius: 50px;
      min-width: 280px;
    }
  }

  .thread-accordion {
    .mat-expansion-panel-body {
      padding: 0 !important;
    }

    .thread-content {
      background-color: #46627b;
      box-shadow: none;
      position: relative;

      .accordion-line {
        background: orange;
        height: 6px;
        padding: 0 15px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 88%;
      }

      // padding: 5px 10px;
    }

    .comment-msg {
      white-space: normal;
      word-wrap: break-word;
      word-break: break-word;
    }

    .capsule {
      width: 28px;
      height: 16px;
      border-radius: 30px;

      &.red {
        background-color: red;
      }

      &.green {
        background-color: rgb(0, 255, 38);
      }

      &.yellow {
        background-color: rgb(231, 225, 57);
      }
    }

    .text-blue {
      color: rgb(54, 139, 235);
      font-weight: 500;
    }

    .text-size {
      font-weight: 500;
    }

    .line {
      align-self: stretch;
      width: 5px;

      &.success {
        background-color: rgb(0, 255, 38);
      }

      &.emergency {
        background-color: rgb(255, 77, 0);
      }
    }

    .reply-opt-container {
      position: relative;

      .reply-options {
        transition: opacity 0.3s ease, visibility 0.3s ease;
        background-color: #14446b;
        border-radius: 10px;
        max-width: 320px;
        position: absolute;
        top: 40px;
        &.hidden {
          display: none
        }
      }
    }


    .reply-pic {
      height: 28px;
      width: 28px;
      font-size: 10px;
      line-height: 28px;
    }

    .emergency-capsule {
      width: 100px;
      border-radius: 30px;
      padding: 2px 10px;
      background-color: rgb(222, 74, 40);
    }

    .comment-option-btn {
      min-width: 0;
      position: relative;
      overflow: hidden;
      vertical-align: middle;
      transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
    }

    .comment-option-btn:active {
      background-color: #888a8a !important;
      transform: scale(1.05);
    }
  }

  .reply-section {
    background-color: #d6e5eb;
  }

  // code for tab section ends here
  .wo-status {
    border-radius: 15px;
    padding: 5px;
    width: 120px;
    text-align: center;

    &.incomplete {
      color: rgb(223, 73, 105);
      border: 1px solid rgb(223, 73, 105);
    }
  }

  .inside-manage-wo {
    box-shadow: rgb(122 119 119 / 24%) 0px 3px 8px;
  }

  .font-bold {
    font-weight: 600;
  }

  .width-full {
    width: 100%;
  }

  .text-black {
    color: #000000;
  }

  @media (hover: none) {
    .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover {
      background: none !important;
    }
  }

}

// .clockin-assignment {
//   height: 100%;
//   overflow-y: scroll;
//   padding-bottom: 120px;
// }

.notes-templates-section {
  .sidebar-header {
    background-color: black;
    padding: 15px 10px;
  }

  .template-select-categories {
    top: -53px;

    .category-select {
      min-width: 304px;
      max-width: 750px;
      width: 100%;
    }

    .select-btn {
      background-color: white;
      border: 1px solid rgb(180, 177, 177);
      border-radius: 0;
      min-height: 50px;
      min-width: 70px;
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }

    .select-btn:hover {
      border: 1px solid #ef4135;
    }

    .onSelectBtnClick {
      border-radius: 0;
      min-height: 50px;
      min-width: 70px;
      border: 1px solid #ef4135;
      background-color: #ef4135;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    }

    .onSelectBtnClickNotes {
      color: #ef4135;
      border: 1px solid #ef4135;
      background-color: #fff;
    }
  }
}

.scroll-hide {
  overflow: hidden;
}

.detail-cards-div {
  max-width: 285px;
  min-height: 220px;
  border: 1px solid #fff;
  border-bottom: 0;
  width: 100%;
  display: flex;
  // justify-content: end;
  position: relative;
  cursor: pointer;
  background-color: #f3ffff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, .2), 0 0 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12);

  .notes-icon-tick {
    position: absolute;
    top: -13px;
    z-index: 1;
    left: -9px;
  }

  &.notes-tick-border {
    border: 1px solid #6aa84f !important;
    border-bottom: 0 !important;
  }

  .sales-card-cta {
    background-color: #2c6909;
    color: white;
    border-radius: 15px;
    text-align: left;
    padding: 5px 16px;
    font-size: 12px;
    max-width: 90px;
  }

  h3 {
    color: #21374c;
    font-size: 18px;
    font-weight: bold;
  }

  p {
    color: #666666;
  }

  md-card-actions {
    .list-icons {
      margin-right: 0;
      text-align: end;

      svg {
        path {
          fill: #ff9900;
        }
      }
    }
  }

  .bottom-label-section {
    margin: 0;
    padding: 5px 5px 5px 8px;
    color: white;
    background-color: #20394cff;
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0;
    border: 2px solid #20394cff;

    p {
      margin: 0;
      color: white;
      font-size: 10px;

      span {
        color: #ff9900;
      }
    }
  }

  .temp-widget {
    height: 100%;
    // background: variables.$white;
    // border: 1px solid variables.$gray-lighter;

    &.temp-active {
      border-color: #ef4135;
    }

    .template-heading {
      position: relative;
      background: rgb(163, 161, 161);
      border-bottom: 1px solid rgb(163, 161, 161);
      padding: 1px 0;
      overflow: hidden;

      h2 {
        padding: 0 15px;
      }

      .ico-tick {
        position: absolute;
        top: 0;
        right: 0;
        height: 18px;
        width: 18px;
      }

      .location-symbol {
        position: absolute;
        top: -11px;
        right: -18px;
        transform: rotate(45deg);
        z-index: 1;
        padding: 12px 10px 0 10px !important;
        border-radius: 0;
      }
    }

    .temp-image {
      // padding: 20px;
      overflow: hidden;
      // position: absolute;
      width: 100%;
      height: 100%;

      &:hover {
        .template-overlay {
          display: flex;
        }
      }
    }

    .template-preview {
      margin: 0;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 100%;

      md-card-title {
        display: grid;
        padding: 0 16px 15px 8px;
      }

      //   height: 250px;
    }

    .preview-container {
      transform: scale(.41) translate(-72%, -72%);
    }

    .template-overlay {
      background: rgba(0, 0, 0, .6);
      color: #ef4135;
      display: none;
      padding: 20px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      .overlay-btn {
        width: 100%;
        display: flex;
        justify-content: space-around;
      }

      .md-button {
        border: 1px solid white;
        margin-left: 0;
      }
    }

    .template-options {
      width: 100%;
      bottom: 45px;
      position: absolute;

      .cursor-point {
        text-align: center;
      }

      md-icon {
        height: 20px;
        width: 20px;
        margin-bottom: 10px;

        path {
          fill: white;
        }
      }
    }
  }
  .min-weight {
    font-weight: 300;
  }
  .normal-weight {
    font-weight: 500px;
  }
 

}

.detail-cards-div.selected {
  background-color: white;
  border: 1px solid #64c43a;
}
.module-name {
  font-size: 10px;
  color: rgb(7, 7, 7) !important;
}
.sidenav-wrapper {
  .edit-sidenav {
    width: 100%; 
    max-width: 49%;
    min-width: 300px;
  }
}

.ql-editor div {
  color: rgb(33, 32, 32);
}

.comment-body-text div {
  font-weight: 400;
}

.ql-editor * {
  color: #333333;
  font-weight: 400;
}

.ql-editor strong, 
.ql-editor b {
  font-weight: 700; 
}

.faz-quill-emoji-dropdown.show {
  left: 0 !important;
}
.md-sidenav-right.inside-container {
  top: 0 !important;
  bottom: 65px;
}
.model-data-color {
  font-size: 12px;
  color: #000;
}
.model-color {
  font-size: 12px;
  color: variables.$brand-primary;
}
.location-height{
  height: 16px;
  font-size: 8px;
  line-height: 2;
}