@use "../settings/third-party/variables";
@use "../settings/custom";

/* Head section style*/
.app-title {
  text-align: center;
  font-size: variables.$font-size-base;

  .subtitle,
  span {
    display: inline-block;
    font-size: variables.$font-size-medium;
    font-weight: 400;
    @extend .ellipsis-300;
    @media only screen and (max-width: 600px) {
      max-width: 200px;
    }
  }

  // span {
  //   font-size: $font-size-small;
  //   font-weight: 400;
  // }
}

// display after before menu under triple dots

.md-open-menu-container {
  overflow: inherit;
}

.status-dropmenu {
  &.scheduling-detail-menu {
    &:before {
      border-width: 7px;
      margin-right: 15.8px;
    }

    &:after {
      border-width: 6.9px;
      margin-right: 16.6px;
    }
    max-height: none;

    md-radio-group {
      &,
      md-radio-button {
        margin-right: 0;
        width: 100%;
      }

      md-radio-button {
        margin-bottom: 0;
      }

      .md-label {
        font-size: 16px;
        margin-left: 34px;
      }
    }
  }

  md-menu-item {
    &:not(.no-hover) {
      &:hover {
        background-color: rgba(158, 158, 158, 0.2);
      }
    }

    &.no-hover {
      .md-button:hover {
        background-color: inherit;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid variables.$gray-lighter;
    }

    &.thick-border {
      border-width: 5px;
    }

    .md-button md-icon {
      margin-right: 10px;
    }
  }
}

.head-menu {
  width: 100%;

  .dot {
    height: 10px;
    width: 10px;
    background-color: variables.$btn-default-color;
    border-radius: 50%;
    margin-right: 5px;
  }

  .menu-title {
    &,
    &:hover {
      background: variables.$btn-default-color !important;
    }

    .md-button {
      cursor: default;
      color: variables.$btn-default-bg;
      font-weight: bold;
    }
  }
}

md-menu-content {
  md-menu-item {
    &.active-menu {
      &,
      &:hover {
        background-color: variables.$brand-accent;
        color: variables.$white;
      }

      .dot {
        background: variables.$white;
      }
    }

    &.disabled {
      background-color: rgba(158, 158, 158, 0.2);

      .md-button {
        cursor: not-allowed;
        opacity: 0.75;
      }
    }
  }
}

/***ARchive Header by Selectiing the table***/
md-toolbar.archive-header {
  background-color: variables.$gray-base;
  color: variables.$white;

  .md-toolbar-tools .back-btn:not(.color) {
    background-color: transparent;
  }
}
/***ARchive Header by Selectiing the table End***/

md-toolbar.main-header .md-toolbar-tools .md-icon-button.close-popup {
  height: 30px;
  width: 30px;

  md-icon {
    height: 18px;
    width: 18px;

    svg {
      path {
        fill: variables.$white;
      }
    }
  }
}

/*--Header start--*/
md-toolbar {
  .md-ico-search {
    margin: auto auto auto 28px;
  }

  &:not(.md-menu-toolbar) .md-button:not(.md-raised) {
    color: variables.$white;
  }

  &.main-header {
    background-color: variables.$gray-base;
    color: variables.$header-color;
    min-height: 64px;

    .md-toolbar-tools {
      padding-right: 20px;
      @media screen and (max-width: 600px) {
        font-size: 16px;
      }

      md-icon {
        &.active {
          path {
            fill: variables.$brand-accent !important;
          }
        }
      }

      .md-icon-button {
        margin-left: 5px;
        margin-right: 0;
        padding: 0;

        md-icon {
          height: 30px;
          width: 30px;
        }

        &.no-marg-top {
          md-icon {
            margin-top: 0;
          }
        }
      }

      .round-back-btn {
        padding: 0;

        md-icon {
          height: 23px;
          width: 23px;
        }
      }

      .back-btn:not(.color) {
        padding: 0;

        md-icon {
          height: 15px;
          width: 15px;
        }
      }

      @media screen and (max-width: 599px) {
        padding: 0 10px;

        .md-icon-button {
          margin-left: 5px;
          margin-right: 0;
        }

        .head-menu {
          margin-left: 0;
          margin-right: 2px;
        }
      }
    }

    .md-button {
      color: variables.$white;
      line-height: 30px;
      min-height: 30px;
      font-weight: normal;

      &.btn-primary {
        min-width: 75px;
      }

      &:last-child {
        margin-right: 0;
      }

      &.md-icon-button {
        margin: 0;
      }
    }

    .icon-menu {
      height: 21px;
      width: 21px;
      vertical-align: middle;
    }

    .btn-default .icon-ico-back-arrow {
      height: 22px;
      width: 16px;
      vertical-align: middle;
    }

    .btn-default .ng-scope {
      vertical-align: middle;
      text-transform: initial;
      font-weight: bold;
    }

    .btn-default .icon-ico-small-right-arrow {
      height: 16px;
      width: 14px;
      vertical-align: middle;
      font-weight: bold;
    }
  }
}

@media only screen and (max-width: 959px) and (min-width: 0) {
  .md-toolbar-tools {
    height: 64px;
    max-height: 64px;
  }
}
/*--Header End--*/
