@use "../../settings/third-party/variables";

.inventory-catalog{
  .catalog-list-section {
    padding: 20px;
  
    .actions {
      &.catalog {
        min-width: 115px;
      }
  
      .manage-icon {
        height: 12px;
        width: 12px;
        margin-right: 20px;
      }
  
      md-icon {
        height: 15px;
        width: 15px;
  
        &.right-space {
          margin-right: 20px;
        }
      }
    }
  
    .update-status {
      position: absolute;
      top: 6px;
      right: 0;
  
      .sm-text {
        font-size: 10px;
      }
    }
  
    md-tabs {
      .table-responsive {
        // margin-top: 18px;
  
        .data-table {
          min-width: 780px;
  
          td {
            color: variables.$gray-dark;
            // font-size: $font-size-small;
            vertical-align: middle;
            // md-checkbox {
            //   margin-top: 4px;
            // }
            .small-grey {
              color: variables.$gray;
              font-size: 10px;
            }
          }
          // md-checkbox .md-icon {
          //   background: $white;
          // }
        }
      }
    }
  }
  
  .status-dropmenu {
    &.child-list-dropmenu {
      md-menu-item {
        line-height: 33px;
        min-height: 35px;
        height: 35px;
        padding: 0 10px;
        /* for product-detail-list.html */
        & > * {
          padding: 0;
          width: auto;
        }
  
        .count {
          min-width: 25px;
        }
  
        .location-symbol {
          margin-left: 2px;
          position: relative;
          top: -2px;
        }
        /* for product-detail-list.html */
      }
  
      &:after,
      &:before {
        right: 53px;
      }
  
      &:before {
        border-width: 8px;
      }
  
      &:after {
        border-width: 6px;
      }
    }
    
  }
  .variation-sidenav{
    .availability{
      background-color: #e9f0f3;
      .col{
        min-width: 85px;
        max-width: 85px;
      }
      @media screen and (min-width: 320px) and (max-width: 370px) {
        .col{
          min-width: 60px;
          max-width: 85px;
        }
      }
    }
    
  }  

}
