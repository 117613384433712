@use "../settings/third-party/variables";

/*--Footer start--*/
.md-toolbar-tools h1 {
  font-size: inherit;
  font-weight: inherit;
  margin: inherit;
}

md-toolbar.footer {
  border-top: 1px solid variables.$gray-lighter;
  background-color: variables.$footer-bgcolor;
  z-index: 60;
  //Fixed it bottom
  // position: fixed;
  // bottom: 0;
}

.footer .md-toolbar-tools,
.footer-menu {
  max-height: 65px;
  height: 65px;
}

.footer-bg {
  background-color: variables.$footer-bgcolor;
  color: variables.$footer-color;
}

.user-settings {
  position: absolute;
  cursor: pointer;
  top: 14px;
  right: 25px;
  height: 35px;
  width: 35px;
  background-size: 35px;

  .img-replacer,
  img,
  md-icon.avatar {
    height: 35px;
    width: 35px;
    line-height: 35px;
    border-radius: 50%;
  }
}

.footer-menu {
  // padding: 0;
  // margin: 0;
  font-size: 0;
  overflow: hidden;

  li {
    display: inline-block;
    text-align: center;

    &:not(:last-child) {
      min-width: 70px;
      padding: 6px 5px;
    }

    // :not(.no-bg)
    &:not(.mobile):hover {
      background-color: variables.$white-darker;
      cursor: pointer;
    }

    // :not(.no-bg)
    &.active {
      background-color: variables.$white-darker;
      cursor: pointer;
    }

    md-icon {
      height: 35px;
      width: 35px;

      &.icon-upgrade {
        position: absolute;
        top: 5px;
        right: 5px;
        height: 15px;
        width: 15px;
      }
    }

    label {
      display: block;
      font-size: variables.$font-size-small;
      padding-top: 4px;
      cursor: pointer;
    }
  }

  .more-menu-trigger {
    display: none;
  }
}

.footer-dropdwn-menu {
  &[width="3"] {
    min-width: 155px;
  }
  padding: 0;

  md-menu-item {
    border-bottom: 1px solid variables.$gray-lighter;
    min-height: 0;
    height: 36px;

    &:nth-child {
      border-bottom: 0;
    }
  }

  &:after,
  &:before {
    top: 99.7%;
    right: 0;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-top-color: #FFFFFF;
    border-width: 10px;
    margin-right: 24px;
  }

  &:before {
    border-color: rgba(194, 225, 245, 0);
    border-top-color: #CCCCCC;
    border-width: 12px;
    margin-right: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .user-settings {
    position: relative;
    top: -14px;
    right: 0;

    &.one-row-profile {
      top: -24px;
      margin-left: 15px;
    }
  }
}

@media only screen and (max-width: 657px) {
  .footer-menu {
    text-align: center;
    border-top: 15px;

    .menu-title {
      font-size: variables.$font-size-small;
    }

    .more-menu-trigger {
      display: inline-block;

      md-icon {
        transition: all linear 0.2s;

        &.expanded {
          transform: rotate(180deg);
        }
      }
    }

    .more-menu-item:not(.one-row-profile) {
      display: none;
    }

    // &:before {
    //   content: "\2022 \00a0 \2022 \00a0 \2022";
    //   cursor: pointer;
    //   display: block;
    //   font-size: $font-size-base;
    //   padding: 15px 5px;
    //   border-bottom: 1px solid $gray-lighter;
    //   color: $gray-light;
    //   margin-bottom: 10px;
    // }
  }

  .footer {
    min-height: 46px;

    .md-toolbar-tools {
      padding: 0;
      // max-height: 34px;
      //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
      transition: all 0.45s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      webkit-transition: all 0.45s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }

    .footer-menu {
      height: 100%;
    }
  }

  .open.footer {
    .md-toolbar-tools {
      height: 100%;
      max-height: 200px;
    }

    .footer-menu {
      max-height: 100%;
    }

    .more-menu-item {
      display: inline-block;
    }
  }

  // .footer-menu li.no-bg {
  //   padding: 0;
  //   width: 0;
  // }
}

@media only screen and (max-width: 439px) {
  .open.footer .md-toolbar-tools {
    max-height: 260px;
  }
}
/* Footer End*/
