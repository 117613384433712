@use "../../settings/third-party/variables";

/*Quotes List Start*/
.layout-column {
  // height: 100%;
}
.quotes-map {
    .map-section {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        padding: 0;
        width: 100%;
        border-top:1px solid #ddd;
    }
    .back-icon {
      svg {
        path{
          fill: #fe9603;
        }
      }
    }
    .go-to-icon {
      svg {
        path {
          fill: variables.$white;
        }
      }
      width: 28px;
    }
    .list-section {
        border-top:1px solid #ddd;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        background: variables.$white;
        width: 100%;
        min-width: 480px;

        .ico-quote-location{
                height: 40px;
               width: 40px;
               margin-left:-10px;
               margin-top: -3px;
            svg{
                path:first-child{
                    fill:#2ecece;
                }
                path:last-child{
                   display:none;
                }

            }
        }

        .md-subheader {
            background: variables.$white-dark;
            font-weight: 500;
            .md-subheader-inner{
            padding: 12px;
            }
        }
        .type-capsule {
          .review-capsule {
            color: #fe9603;
            border: 1px solid #fe9603;
            font-weight: 600;
            line-height: 10px;
            min-width: 90px;
            min-height: 24px;
            background-color: variables.$white;
            border-radius: 80px;
            &.active {
              background-color:#fe9603;
              color: variables.$white;
            }
            &.inactive {
              color: variables.$gray-lighter;
              border: 2px solid variables.$gray-lighter;
              pointer-events: none;
            }
          }
          .review-capsule:hover {
            background-color: #fe9603;
            color: variables.$white;
          }
        }
        .email-icon {
          width: 20px;
          height: 20px;
          margin-left: 3px;
        }
        .list-bg-color {
          background-color: #29445e;
        }
        .no-service-meet {
          .md-no-style {
            padding: 0 !important;
          }
          .map-list-section, .list-bg-color {
            padding: 0 20px;
          }
        }
        .map-list {
            position: absolute;
            top: 58px;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 0;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            background: variables.$white;

            .incomplete {
              color: variables.$status-red;
            }
            .draft {
              color: variables.$status-gray;
            }
            .submitted {
              color: variables.$status-green;
            }
          .modified {
            color: variables.$status-yellow;
          }
          .resubmitted {
            color : variables.$status-yellow;
          }
          .expired {
            color: variables.$status-red;

          }
          .void {
            color: variables.$status-gray;
          }

          .rejected {

          }
          .accepted {
           color: variables.$status-green;
          }
          .partial-complete {
            color: variables.$status-yellow;
          }

          .completed {
            color: variables.$status-green;

          }
          .closed {

          }
          
          .service-visit-cta {
            font-size: 13px;
          }
            h2{
                font-weight: normal;
                margin-top: 0;
                margin-bottom: 3px;
            }

           md-list-item.active{
              // background: $brand-primary-light;
              background-color: #d2f4c3;
              md-icon.active{
                svg{
                    path{
                        fill:variables.$brand-primary;
                }
              }
            }
        }

            .service-icon {

                width: 14px;
                height: 14px;
                margin: 0;
            }
            .ico-location-info{
                width:10px;
                position:absolute;
                top:35px;
                right:20px;
            }
            md-list-item.md-no-proxy,
            md-list-item .md-no-style {
                    padding: 20px;
                    // border-bottom: 1px solid $gray-lighter;
            }
            .list-top {
                display: inline-block;
                vertical-align: top;
                margin: 0;
            }
            .sales-notify{
                 margin-top: 5px;
            }

        }
    }
  .select-border{
    border-bottom: 1px solid white;
  }
  .select-border:focus {
    outline: none;
    border-bottom: 1px solid transparent; 
  }
}
@media screen and (min-width: 600px) {
  .quotes-map {
    .sub-header-bg {
      background-color: #29445e !important;
      height: 60px  !important;
    }
  }
}

@media screen and (max-width: 600px) {
    .quotes-map {
        .map-section {
            position: relative;
            top: auto;
            left: auto;
            bottom: auto;
            padding: 0;
            width: 100%;
        }
        .sub-header-bg {
          background-color: #29445e !important;
          height: auto !important;
        }
        .list-section {
            position: relative;
            top: auto;
            right: auto;
            bottom: auto;
            background: variables.$white;
            width: 100%;
            min-width: auto;
            .map-list {
                position: relative;
                top: 0;
                left: auto;
                right: auto;
                bottom: auto;
                padding: 0;
                overflow-y: auto;
            }
        }
    }
}
