@use "../settings/third-party/variables";

.report-dashborad {
  .add-block {
    margin: 20px;
    padding: 20px;
    border-radius: 5px;

    .margin-left {
      margin-left: 20px;
    }

    .block-icon {
      height: 250px;
      width: 250px;
      width: 100%;
    }

    .names-camp {
      width: 100%;
    }

    .welcome-blocks {
      font-size: variables.$font-size-medium;
      color: variables.$gray;
    }
  }

  .resources-list {
    .img-replacer {
      width: 24px;
      min-width: 24px;
      height: 24px;
      font-size: 10px;
      line-height: 25px;
    }
  }

  .dashboard-icon {
    max-width: 30px;
  }

  .dashboard-icons {
    background: #fff;
  }

  .dashboard-text {
    color: #999999;
    padding: 7px 20px;
    background: #fff;
    max-width: 150px;
    min-width: 150px;
    border-radius: 30px;
    // position: absolute;
    // right: 2px;
  }

  .dashboard-block {
    position: relative;
    min-height: 100px;
    min-width: 130px;
    border: 1px solid #d6e1e5;
    border-radius: 1px;
    background: #fff;
    box-shadow: 0px 0px 7px 0px #b7b0b7;
    overflow: hidden;

    &.active {
      .dashboard-index {
        background: #52758d !important;
      }
    }
  }

  .dashboard-block .dashboard-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 1;
    padding-bottom: 10px;
  }

  .dashboard-block:hover .dashboard-overlay {
    opacity: 1;
  }

  .view-dashboard-btn {
    z-index: 2;
    padding: 10px 20px;
    background-color: variables.$brand-primary;
    color: white;
    border: none;
    border-radius: 2px;
    font-size: 14px;
    cursor: pointer;
    min-width: 150px;
    transition: background-color 0.3s ease;
  }

  .view-dashboard-btn:hover {
    background-color: #ce5409;
  }
  .current-view {
    background-color: white;
  }
  .current-view:hover {
    background-color: #e0dfdf;
  }
  .action-description:hover {
    color: variables.$brand-primary;
  }
  .dashboard-block.active,
  .dashboard-block:hover {
    border: 1px solid variables.$brand-primary;
  }

  .dashboard-block.active {
    color: variables.$brand-primary;
  }

  .header-text-seablue {
    color: #48c5c3;
  }

  .filter-reports {
    padding: 7px 10px;
  }

  .dashboard-index {
    padding: 3px;
    border-radius: 40px;
    max-width: 20px;
    min-width: 20px;
    background: #ccc;
    color: #fff;
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 12px !important;
  }

  &.dashboard-sidenav {
    .sidebar-header {
      background: #000;
    }
  }

  .dasboard-icon {
    max-width: 30px;
    min-width: 30px;
  }
}
.description-container {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limits to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 4.5em; /* Adjust based on font size */
}

.description-container.expanded {
  -webkit-line-clamp: unset;
  max-height: none;
}

.chart-type {
  background: black;
  color: white;
  padding: 3px 5px;
  border-radius: 5px;
}

.recent-opportunities {
  padding: 15px 0 0;
  overflow-x: auto;
}

.recent-opportunities {
  table {
    border-bottom: 0;
    text-align: left;
    width: 100%;
    margin-top: 8px;
    border-radius: 5px;
    border-collapse: inherit;
    overflow: hidden;
    min-width: 800px;

    td {
      padding-top: 8px;
    }
  }
}

// .archive-img-container {
//   display: inline-block;
//   height: 23px !important;
//   line-height: 35px;
//   text-align: center;
//   width: 46px;
// }

.customer-name {
  font-size: 16px;
}

.dashboard-filter {
  margin-right: 10px;
}

.section-header {
  position: relative;

  .rm-top {
    margin-top: 0;
    color: variables.$status-gray;
  }

  .md-menu {
    position: absolute;
    top: -12px;
    right: -20px;
  }
}

.campaign-names {
  margin: 0;
  // border: 1px solid #cccccc;
  // max-width: 323px;
  // height: 70px;
  // box-shadow: 2px 2px 20px #ccc;
  background: #fff;
  padding: 10px;
  // min-width: 323px;
  position: relative;

  .statistics-icons {
    border-radius: 50%;
    border: 1px solid variables.$gray-light;
    position: absolute;
    right: 0;
    right: 10px;
    top: 7px;
    height: 30px;
    width: 30px;
    text-align: center;

    .cat-icon {
      width: 100%;
      max-width: 15px;
    }
  }
}

.statistics-value {
  font-size: 16px;
}

.statistics-data {
  font-size: 12px;
  color: variables.$gray;
}

.campaign-names:hover {
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, .2), 0 3px 4px 0 rgba(0, 0, 0, .14), 0 3px 3px -2px rgba(0, 0, 0, .12);
}

.default-padding {
  margin: 20px;
}

.default-box {
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 20px;
  padding: 20px;
}

.stats-row {
  .stats-container {
    padding: 10px;
  }
}

.dashboard-sidenav {
  width: 100%;
  max-width:380px;
}

@media only screen and (min-width: 960px) {
  .stats-row .stats-container {
    &:nth-child(4n+1) {
      padding-left: 0;
    }

    &:nth-child(4n) {
      padding-right: 0;
    }
  }
}

@media only screen and (max-width: 959px) {
  .stats-row .stats-container {
    &:nth-child(even) {
      padding-right: 0;
    }

    &:nth-child(odd) {
      padding-left: 0;
    }
  }
}

@media screen and (max-width: 600px) {
  .stats-row .stats-container {
    padding: 10px 0;
  }

  .report-dashborad {
    .dashboard-filter {
      margin-right: 0;
    }
  }

  .default-card-box {
    md-select {
      width: 100%;
    }
  }
}