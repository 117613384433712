@use "../../settings/third-party/mixins";
@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.business-account-section {
  .dark-variable {
    color: variables.$gray-darker;
  }

  &.business-section {
    .titles {
      font-weight: normal;
      font-size: variables.$font-size-base;
    }
  }

  .list-sections {
    padding-bottom: 20px;

    .titles {
      @extend .dark-variable;

      margin-bottom: 30px;
    }
  }

  .section-gap {
    margin-bottom: 40px;
  }

  .record-list {
    @extend .no-style;

    li {
      &:not(:last-child) {
        margin-bottom: 30px;
      }

      .top-gap {
        margin-top: 10px;
      }

      md-icon {
        height: 21px;
        margin-left: 10px;
        width: 21px;
      }
    }

    &.payment-history {
      max-height: 195px;
      overflow-y: scroll;

      .text-right {
        padding-right: 20px;
      }
    }
  }

  .cards-section {
    margin-top: 30px;

    .titles {
      margin-bottom: 8px;
    }

    .card-holder {
      @include mixins.border-radius(4px);

      border: 1px solid variables.$border-color;
      box-sizing: border-box;
      min-height: 130px;
      padding: 14px 20px;

      label {
        line-height: 20px;
        padding-right: 0;
      }
    }

    .card-detail {
      margin-bottom: 25px;
    }
  }
}

.legal-tab-section {
  .addadditional-item {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 600px) {
  .entity-business {
    max-width: 478px;
  }

  .business-account-section {
    .vertical-divider {
      position: relative;

      &:after {
        content: '';
        display: inline-block;
        border-left: 1px solid variables.$border-color;
        position: absolute;
        top: 0;
        bottom: 20px;
        left: 50%;
        transform: translate(0, 0);
      }
    }

    .right-box {
      padding-left: 25px;
    }

    .cards-section {
      .gap-right {
        margin-right: 15px;
      }
    }

    .record-list.payment-history {
      max-width: 220px;
    }
  }
}

@media screen and (max-width: 600px) {
  .business-account-section {
    .cards-section .card-holder {
      margin-bottom: 25px;
    }

    .right-box {
      margin-top: 25px;
    }
  }
}
