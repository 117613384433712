@use "../../settings/style-guide";
@use "../../settings/custom";

.certificate-completion-section {
  .data-table {
    td {
      line-height: 24px;
      padding: 10px;

      label {
        @extend .f4;
        display: inline-block;
        margin-right: 15px;
        min-width: 120px;
      }
    }

    .long-descp {
      width: 100%;
      @extend .ellipsis;
    }

    .dark-color {
      @extend .long-descp;
    }

    .no-width {
      min-width: auto;
    }

    .signer-list li:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
@media screen and (min-width: 1024px) {
  .certificate-completion-section {
    .table-responsive {
      width: 90%;
    }
  }
}
