/*Quotes List Start*/
@use '../../customers/support/support-repairing';
@use '../../customers/support/support-repairing-webpack';
@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.scheduling-task-detail .md-datepicker-calendar-pane.md-pane-open {
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
  transform-origin: right top;
}

.assign-out-of {
  h2 {
    font-weight: normal;
    margin: 0 0 5px;
  }

  md-menu-item {
    min-height: 75px;
    height: 75px;
  }

  .menu-header {
    md-icon {
      margin: 2px 7px 5px 1px;
    }
  }
}

.schedule-task-detail {
  padding: 0;

  // md-datepicker {
  //   margin: 0;
  // }

  .date-picker-custom md-datepicker .md-datepicker-input-container input {
    font-size: variables.$font-size-small;
    min-width: 95px;
    max-width: 95px;
  }

  .default-card-box {
    margin: 0;

    .highlight-text {
      font-size: variables.$font-size-large;
    }

    ul li {
      &:not(:last-child) {
        margin-right: 25px;
      }
    }

    label.f4 {
      display: inline-block;
      margin-right: 5px;
    }

    md-input-container {
      margin: 0;
      height: 22px;
      border-bottom: 0;
      padding-bottom: 6px;
      margin-left: 6px;

      md-select {
        height: 22px;

        md-select-value {
          height: 22px;
          min-width: 0;
          min-height: 0;
          padding-bottom: 0;
        }
      }

      label,
      span {
        font-size: variables.$font-size-small;
        color: variables.$gray-dark;
      }
    }

    md-icon {
      width: 15px;
      height: 15px;
      margin-left: 4px;
    }
  }

  .round-number {
    background: variables.$brand-table;
    font-size: variables.$font-size-small;
    padding: 1px;
    margin-left: 5px;
    max-height: 23px;
    width: 23px;
    line-height: 23px;
    text-align: center;
    border-radius: 100%;
    display: inline-block;
    vertical-align: middle;
    outline: 0;
    font-weight: 500;
  }

  .scheduling-detail-bar {
    margin-top: 10px;
    margin-bottom: 18px;

    .date-picker-custom {
      margin-top: 5px;
    }

    .resorce-menu-btn {
      margin-left: 20px;

      svg path {
        fill: variables.$gray-light;
      }
    }

    .right {
      min-width: 320px;
      text-align: right;

      .date-picker {
        display: inline-block;
        margin-left: 10px;
      }

      md-input-container {
        margin: 0;
        width: 100%;
        max-width: 130px;

        md-select {
          height: 30px;
          margin-left: 15px;

          span {
            @extend .f3;
          }
        }
      }

      .ico-truck {
        width: 24px;
        height: 14px;
        margin-right: 5px;
      }

      .small-text {
        @extend .f4;

        font-size: 11px;
      }
    }

    .date-picker-custom md-datepicker .md-datepicker-input-container,
    .date-picker-custom md-datepicker.md-default-theme {
      input {
        height: 20px;
        margin: 0;
      }

      input::-webkit-input-placeholder {
        font-size: variables.$font-size-small;
      }

      input:-moz-placeholder {
        /* Firefox 18- */
        font-size: variables.$font-size-small;
      }

      input::-moz-placeholder {
        /* Firefox 19+ */
        font-size: variables.$font-size-small;
      }

      input:-ms-input-placeholder {
        font-size: variables.$font-size-small;
      }
    }
  }

  .c-master-ui {
    position: relative;
    border-top: 1px solid #dbdbdb;

    .time-frame {
      ul {
        margin-top: 66px;
        padding-top: 25px;
        border-top: solid 1px variables.$gray-lighter;

        li {
          position: relative;
          border-bottom: solid 1px variables.$gray-lighter;
          margin: 20px 0 20px 42px;
        }

        .h-time {
          position: absolute;
          text-align: right;
          width: 42px;
          left: -50px;
          top: -8px;
        }
      }

      .current-time {
        position: absolute;
        border-bottom: 2px solid variables.$gray-light;
        top: 80px;
        left: 50px;
        right: 8px;

        &:after {
          left: -8px;
          top: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(136, 183, 213, 0);
          border-left-color: variables.$brand-accent;
          border-width: 8px;
          margin-top: -7px;
        }
      }

      .off-time {
        background: rgba(variables.$white-dark, 0.4);
        position: absolute;
        left: 50px;
        right: 0;
        top: 222px;
        z-index: 50;
        height: 83px;
      }
    }

    .user-frame {
      position: absolute;
      left: 50px;
      right: 50px;
      top: 0;
      bottom: 0;
      z-index: 50;
      max-width: 100%;
      overflow-x: auto;

      li {
        border-right: solid 1px variables.$gray-lighter;
        position: relative;
        min-width: 330px;
        width: 25%;

        .md-button.md-icon-button {
          margin-right: -5px;

          &:before {
            transform: rotate(90deg);
            height: 15px;
            width: 21px;
          }
        }

        .event-edit {
          text-align: center;

          md-icon {
            width: 11px;
            height: 11px;

            svg path {
              fill: variables.$gray-base;
            }
          }
        }

        &:first-child {
          border-left: solid 1px variables.$gray-lighter;
        }
      }

      .item-th {
        padding: 10px;
        position: relative;

        .assign-tick {
          height: 18px;
          width: 18px;
          position: absolute;
          // left: 4px;
          left: 50px;
          top: 10px;
        }

        md-icon {
          vertical-align: middle;
          margin-right: 6px;

          &.ico-add {
            height: 18px;
            margin-right: 0;
            margin-left: 12px;
            // position: relative;
            width: 18px;

            // &:before {
            //     content: '';
            //     display: inline-block;
            //     position: absolute;
            //     top: 0;
            //     left: -8px;
            //     bottom: 0;
            //     border-left: 1px solid $brand-primary;
            // }
          }
        }

        a {
          display: inline-block;
          margin-top: 5px;
        }
      }

      .create-event {
        background: variables.$white-darker;
        border-top: 2px dashed variables.$gray-light;
        border-bottom: 2px dashed variables.$gray-light;
        cursor: pointer;
      }

      .item-td {
        // padding: 10px;
        min-height: 23px;
        position: absolute;
        left: 0;
        right: 0;
        top: 50px;
        line-height: 2.2;
        border-bottom: 1px solid rgba(0,0,0,0.7);

        .time-remain {
          position: absolute;
          right: 13px;
          // top: 11px;
          font-size: 10px;

          md-icon {
            width: 20px;
            height: 20px;
            margin-right: 4px;
          }
        }

        .ico-truck {
          svg {
            path {
              fill: variables.$gray-base;
            }
          }
        }

        &.off-day {
          background: rgba(variables.$white-dark, 0.4);
          top: 43px;
          bottom: 0;
          text-align: center;
          font-size: 16px;
          color: variables.$gray-light;

          label {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 100px;
            height: 25px;
            color: variables.$gray;
          }
        }
      }

      .palette1,
      .palette2,
      .palette3,
      .palette4,
      .palette5 {
        color: variables.$white;
      }

      .palette1 {
        background: rgba(variables.$brand-info, 0.7);
      }

      .palette2 {
        background: rgba(variables.$proposalOrange, 0.7);
      }

      .palette3 {
        background: rgba(variables.$brand-danger-dark, 0.7);
      }

      .palette4 {
        background: rgba(variables.$gray, 0.7);
      }
    }
  }
}

.assign-nav {
  .top-section {
    padding: 20px 20px 5px;
  }

  h2 {
    font-weight: 500;
  }

  .assign-type,
  h2 {
    margin-left: 0;
    margin-right: 0;
  }

  .assign-type {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.scheduling-detail-menu {
  padding: 0;

  &:before {
    border-width: 12px;
    margin-right: 19px;
  }

  &:after {
    border-bottom-color: variables.$white-darker;
    border-width: 11px;
    margin-right: 20.5px;
  }

  .md-subheader {
    background: variables.$white-darker;

    .md-subheader-inner {
      padding: 8px;
      @extend .f1;

      font-weight: normal;
    }
  }

  md-radio-group md-radio-button {
    margin-right: 0;
  }

  md-divider {
    margin: 0;
  }

  md-menu-item {
    border-bottom: 1px solid variables.$gray-lighter;

    &:last-child {
      border-bottom: 0;
    }

    // &:nth-child(3) {
    //   border-width: 5px;
    // }
  }

  .selected-resource {
    position: relative;

    .usr-avatar {
      border: 1px solid variables.$white-darker;
      border-radius: 100%;
      background: variables.$white-dark;
      margin-right: 10px;

      svg {
        border-radius: 100%;
      }
    }

    .ico-tick {
      height: 9px;
      width: 9px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.date-picker-custom {
  display: inline-block;

  md-datepicker,
  md-datepicker.md-default-theme {
    background: transparent;

    .md-datepicker-input-container {
      border: 0;
      margin: 0;
      padding-left: 3px;
      padding-bottom: 0;

      input {
        @extend .f1;

        min-width: 110px;
        max-width: 110px;
      }

      input::-webkit-input-placeholder {
        color: variables.$gray-dark !important;
        font-weight: 500;
      }

      input:-moz-placeholder {
        /* Firefox 18- */
        color: variables.$gray-dark !important;
        font-weight: 500;
      }

      input::-moz-placeholder {
        /* Firefox 19+ */
        color: variables.$gray-dark !important;
        font-weight: 500;
      }

      input:-ms-input-placeholder {
        color: variables.$gray-dark !important;
        font-weight: 500;
      }
    }

    // .md-datepicker-calendar-icon {
    //   svg {
    //     path {
    //       fill: #828282;
    //     }
    //   }
    // }

    .md-datepicker-triangle-button {
      display: none;
    }

    .md-datepicker-button {
      margin: 0;
      position: absolute;
      right: -8px;
      top: -11px;
      z-index: 20;
      padding-right: 0;
      width: 30px;

      md-icon {
        height: 22px;
        width: 22px;
      }
    }
  }
}

.ar-sidepanel {
  &.custom-ar-sidepanel {
    .custom-task-list {
      .search-list .quote-select {
        line-height: 2;
      }

      md-chips .md-chips {
        padding-left: 0;
      }
    }
  }

  .top-section {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 75px;

    &.custom-top-section {
      .top-heading {
        margin: 0;
        padding: 8px!important !important;
        background: #000;

        h2 {
          color: variables.$white;
        }

        md-icon {
          width: 25px;
          height: 25px;

          svg path {
            fill: variables.$white!important;
          }
        }
      }

      .custom-form-base {
        padding-bottom: 20px;
      }

      .custom-task-wrapper {
        top: 165px!important;

        md-list-item._md-button-wrap {
          padding: 5px 0;
        }
      }
    }
  }

  .task-wrapper {
    position: absolute;
    top: 155px;
    left: 0;
    right: 0;
    overflow-y: auto;

    &.custom-task-wrapper {
      form {
        padding-top: 10px;
      }
    }

    &.custom-associate-task-wrapper {
      top: 70px!important;
    }

    &.custom-notes-task-wrapper {
      top: 165px!important;

      md-list-item._md-button-wrap {
        padding: 5px 0 0;
        // div.md-button{
        //   background-color: #eaf0f6 !important;
        // }
      }
    }
  }

  .bottom-section {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
  }

  .icon-calendar {
    height: 16px;
  }

  .support-accordion.accordionN {
    a.active + .accordionN-section-content {
      display: block;
    }
  }
}
@media screen and (max-width: 600px) {
  .create-event-popup .create-event-field li label {
    display: block;
    padding-bottom: 10px;
    min-width: 0;
    width: 100%;
  }

  .create-event-popup .create-event-field li {
    padding: 15px 0;
    height: auto;
  }

  .schedule-task-detail .default-card-box ul li {
    padding-bottom: 10px;
  }
}
// .task-list md-list-item div .md-button{
//   position: absolute;
//   height: 100%;
//   left: 0;
//   width:80%;
// }
