@use "../../settings/third-party/variables";
@use "../../settings/style-guide";
@use "../../settings/custom";

.btn-media {
  color: variables.$white;
  margin: 0;
  min-width: 15px;
  // height: 15px;
  md-icon {
    svg path {
      fill: variables.$white;
    }
  }
}

.cross-icon {
  md-icon {
    width: 15px;
    height: 15px;
  }
}

.top-gap {
  margin-top: 18px;
}

.right-gap-hard {
  margin-right: 20px;
}

// .filter-inside-container {
//   top: 64px;
//   bottom: 0;
// }

.img-title {
  @extend .f1;
  @extend .text-center;
  margin: 10px;
}

.media-option {
  @extend .status-dropmenu;
  width: 170px;
}

.media-list {
  width: 100%;
  border: 1px solid variables.$gray-light-media;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 10px;

  .left-option {
    .media-img {
      // border: 1px solid $gray-light-media;
      border-radius: 4px;
      width: 60px;
      height: 60px;
      overflow: hidden;

      img {
        width: 60px;
        height: auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    md-checkbox {
      margin: 0 10px 0 0;
    }
  }

  .right-option {
    .item-chipset .chipbox {
      margin-right: 5px;
    }

    .menu-btn {
      position: static;
      margin-right: 10px;
    }

    .bin-icon {
      margin: 0;

      md-icon {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.list-grid-filter {
  .media-filter {
    margin: 0;
    padding: 0;
    display: inline-block;

    .md-select-value {
      border: none;
    }
  }

  md-select:not([disabled]):focus .md-select-value {
    border: none;
  }

  .icon {
    @extend .f8;
    @extend .cursor-point;
    color: variables.$brand-primary;
    display: inline-block;
    margin-right: 10px;

    md-icon {
      width: 12px;
      height: 12px;
      margin-right: 5px;

      svg path {
        fill: variables.$brand-primary;
      }
    }

    &.active {
      @extend .no-cursor;
      color: variables.$gray-base;

      md-icon svg path {
        fill: variables.$gray-base;
      }
    }
  }
}

.check-active {
  box-shadow: -1px 2px 5px -1px rgba(0,0,0,0.57);
}

.image {
  max-width: 220px;
  width: 220px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: inline-block;

  .image-bg {
    width: 100%;
    height: 220px;
    background-size: cover;
    background-position: center;
    background-color: #eee;
    border: 1px solid #dbdbdb;
    border-bottom-width: 0;
  }

  .media-opt {
    padding: 10px;
    border: 1px solid variables.$gray-light-media;
    width: 220px;

    .media-header {
      md-checkbox {
        margin-bottom: 2px;
        min-height: auto;
      }

      .menu-btn {
        top: 8px;
      }
    }
  }
}

// .media-grid {
//   width: 100%;
//   max-width: 150px;
//   min-width: 150px;
//   margin-right: 10px;
//   display: inline-block;
//
//   .media-gallary-grid {
//     border-radius: 5px;
//     background-clip: padding-box;
//     border: 1px solid $gray-light-media;
//     margin-top: 10px;
//
//     .media-header {
//       margin: 5px 5px 5px 10px;
//
//       .media-img {
//         display: block;
//         height: 60px;
//         width: 60px;
//         margin: 10px auto 22px;
//
//         img {
//           width: 60px;
//           height: auto;
//           position: relative;
//           top: 50%;
//           transform: translateY(-50%);
//         }
//       }
//     }
//   }
// }

.add-tag-sidenav {
  @extend .ar-sidepanel;

  .item-chipset .chipbox {
    max-width: 225px;
  }

  .leftnav-wrap {
    .top-section {
      .sidenav-title {
        margin-top: 0;

        h2 {
          margin-left: 0;
          margin-bottom: 0;
        }

        .cross-icon {
          margin-top: 10px;
        }
      }
    }

    .task-wrapper {
      top: 50px;
      bottom: 80px;
      overflow: auto;

      .form-base md-radio-group {
        margin-top: 10px;

        md-radio-button {
          margin-right: 10px;
        }
      }

      .top-title {
        margin-left: 0;
      }

      .task-wrapper-inner {
        // overflow: hidden;
        // height: auto;
        .search-list {
          li {
            padding: 10px 18px;
          }

          li.child-product {
            padding: 4px 40px;
          }
        }
      }
    }
  }

  .leftnav-wrap .bottom-section {
    height: 85px;

    .bottom-option {
      margin: 15px 15px 0;
    }
  }

  .item-chipset {
    margin: 0 8px;

    .chipbox {
      border: 1px solid variables.$brand-table;
    }
  }
}

.image-view {
  background-color: variables.$header-bgcolor;
  height: 100%;
  display: block;

  .slide-play-btn {
    position: absolute;
    bottom: 10px;
    left: 0;
  }
  // .center-img {
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  // }
  .pre-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .pre-btn {
    @extend .pre-next;
    left: 0;
  }

  .next-btn {
    @extend .pre-next;
    right: 0;
  }

  .center-image {
    // height: calc(100% - 80px);
    // top: 0;

    height: calc(100% - 100px);
    top: 20px;
    width: 100%;
    position: absolute;

    img {
      max-width: 100%;
      height: auto;
      max-height: 100%;

      &::selection {
        background: transparent;
      }
    }
  }

  .img-desc {
    @extend .text-center;
    color: variables.$white;
    margin: 0 auto;
    bottom: 0;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);

    a {
      color: variables.$white;
      margin-right: 10px;
    }
  }

  .slide-show-control {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }

  .esc-exit {
    position: absolute;
    bottom: 8px;
    left: 0;

    md-icon {
      width: 15px;
      height: 15px;
      margin: -3px 5px 0 0;
    }
  }
}

.image-filter {
  .custom-tabs md-tabs-wrapper {
    margin: 15px 15px 0;
  }

  .custom-tabs md-tabs-content-wrapper md-tab-content {
    height: 100%;
  }
  // .custom-tabs md-tabs-content-wrapper md-tab-content[id="tab-content-1"] > [md-tabs-template] {
  //   position: absolute;
  // }
  .custom-tabs md-tabs-content-wrapper md-tab-content > [md-tabs-template] {
    top: 0;
    padding: 0 15px 15px;
    bottom: 70px;
    overflow: auto;
  }

  .custom-tabs md-tabs-wrapper md-tabs-canvas {
    height: 50px;
  }

  .custom-tabs md-tabs-wrapper md-tabs-canvas md-pagination-wrapper md-tab-item::after,
  .custom-tabs md-tabs-wrapper md-tabs-canvas md-pagination-wrapper md-tab-item::before {
    border-width: 0 !important;
  }

  .custom-tabs md-tabs-wrapper md-tabs-canvas md-pagination-wrapper md-tab-item {
    padding: 0 10px;
  }

  .custom-tabs md-tabs-wrapper md-tabs-canvas md-pagination-wrapper md-tab-item span {
    margin-top: 0;
  }

  .custom-tabs md-tabs-wrapper md-tabs-canvas md-pagination-wrapper md-tab-item.md-active {
    span {
      color: rgb(255,152,0);
      border-bottom: 2px solid;
    }

    md-icon svg g g {
      fill: rgb(255,152,0);
    }

    md-icon svg path {
      fill: rgb(255,152,0);
    }
  }

  .tab-header {
    border-bottom: 1px solid variables.$gray;

    h2 {
      color: variables.$brand-primary;

      md-icon {
        cursor: auto;

        svg g g,
        svg path {
          fill: variables.$brand-primary;
        }

      }
    }
  }

  .image-filter-type {
    .filter-type {
      // display: inline-block;
      margin-right: 15px;
      width: 115px;
      height: 115px;
      overflow: hidden;
      float: left;
      margin-bottom: 15px;

      h2 {
        margin: 5px 0;
      }

      img {
        width: 110px;
        border: 1px solid variables.$gray;
        padding: 5px;
      }
    }
  }

  .crop-filter {
    margin-top: 20px;
    padding: 20px 10px;
    border: 1px solid variables.$gray;

    .rotate {
      transform: rotate(180deg);
    }

    .rotate-gap {
      margin: 20px 0;
    }

    .rotate-border {
      &.active {
        border-color: variables.$brand-accent;

      }
      padding: 10px;
      border: 1px solid variables.$gray;
      @extend .cursor-point;
    }

    .checkbox {
      margin-bottom: 30px;
    }
  }
}
//7 Nov 2017

.import-area {
  width: 100%;
  position: absolute;
  top: 50%;
  left: calc(50% - calc(variables.$upload-width / 2));
  transform: translate(-50%, -50%);

  &.dragover {
    border: 2px dashed variables.$brand-primary;
  }
}

.sidebar-upload {
  // position: absolute;
  // width: $upload-width;
  // right: 0;
  // height: 100%;
  padding: 20px 10px;
  // background-color: $white-dark;
  .side-wrap {
    overflow: auto;
    height: calc(100% - 170px);
  }

  .side-full-wrap {
    height: 70%;
    overflow: auto;
  }

  .bottom-section {
    border: 1px solid variables.$gray;
    background: white;
    border-radius: 5px;
    position: absolute;
    bottom: 5px;
    width: 300px;
    // width: 100%;
    .padding {
      padding: 18px;

      h1 {
        font-size: 28px;

        span {
          color: variables.$gray;
        }
      }
    }

    .st-av {
      background-color: variables.$gray;
      padding: 12px;

      h3 {
        font-size: 20px;
        margin: 0;
      }
    }
  }
}

.upload-error {
  color: variables.$brand-danger-dark;
}

// .dragover{
//   padding: 100px;
//     border: 1px solid #f2f2f2;
//     border-radius: 50%;
//     background: #f2f2f2;
// }
.media-upload {
  padding: 12px;

  h3 {
    margin: 0 0 10px;
    font-size: variables.$font-size-h3;
  }

  .f2 {
    font-size: variables.$font-size-large;
    margin-bottom: 10px;
  }

  .file-selector {
    position: relative;
    color: variables.$brand-primary;

    .file-icon {
      position: absolute;
      left: -99999px;
    }
  }
}

.upload-table {
  border-collapse: collapse;
  width: 100%;

  td:first-child,
  th:first-child {
    width: 85%;
  }

  td,
  th {
    border: 1px solid variables.$table-border-color;
    padding: 12px;
    text-align: left;
  }

  .first-div {
    // width: 9%;
    width: 80px;
    // height: 80px;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    margin-right: 10px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .second-div {
    position: relative;
    display: inline-block;
    width: calc(100% - 95px);
    // max-width: 89%;
    margin: 21px 0;
    vertical-align: middle;

    md-progress-linear {
      margin-top: 8px !important;
      margin-bottom: 8px !important;
    }

    // .descri {
    // position: absolute;
    // right: 8px;
    // top: 0;
    // }
  }
}

.md-primary {
  .md-bar {
    background-color: variables.$brand-accent;
  }

  .md-container {
    // background-color: $upload-prog-back-color;
  }

  .p-bar {
    margin-top: 10px;
  }
}

.upload-media {
  position: absolute;
  width: calc(100% - variables.$upload-width);
  padding: 50px 10px;
  height: 100%;
  overflow: auto;
}

.refresh-action {
  padding: 10px 30px;

  .reload {
    display: inline-block;

    md-icon {
      width: 15px;
    }
  }
}

.mtop {
  margin: 10px 0;
}

.media-pro {
  margin: 15px 0;

  .mtop {
    label span {
      color: variables.$brand-danger-dark;
    }
  }
}

.show-media-small-device {
  margin-left: 20px;
}

.tag-by-name {
  position: relative;
  border-top: 1px solid variables.$gray-lighter;
  padding: 5px 0;
  margin-top: 15px;

  li:first-of-type {
    margin-left: 30px;
  }

  .tag-separation {
    position: absolute;
    top: -12px;
    width: 25px;
    height: 25px;
    background: variables.$brand-primary;
    border-radius: 50%;

    .tag-by-name-filter {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
}

.tag-chip {
  margin-left: 20px;

  li.active {
    background: variables.$brand-primary;
    color: variables.$gray-base;
  }

  md-chips .md-chips {
    &,
    &.md-focused {
      box-shadow: none !important;
    }

    .md-chip-input-container {
      display: none;
    }
  }
}
@media (max-width:599px) {
  .upload-table .second-div {
    width: 100%;
  }

  .media-list .right-option {
    .option-menu {
      position: absolute;
      right: 25px;
      top: 5px;
    }

    .bin-icon {
      position: absolute;
      right: 5px;
      top: 5px;
    }
  }

  // .second-div .descri {
  //   position: static;
  // }

  .upload-table .first-div {
    display: block;
    // margin: 0 auto;
    height: auto;
  }
}
@media (max-width:959px) {
  .upload-media {
    width: 100%;
  }

  .import-area {
    left: 50%;
  }

  .media-list {
    .left-option {
      margin-bottom: 10px;
    }
  }
}
