@use "../../settings/third-party/variables";

.welcome-import {
  padding: 20px;
  // .welcome-headline {
  //       margin-left: 27px;
  // }

  ul {

    list-style-type: none;

    li {
      position: relative;
      margin-bottom: 10px;
    }

  }

  .done {
    .icon-tick {
      display: inline-block;
      height: 25px;
      width: 25px;
      background-size: 24px;
      background-repeat: no-repeat;
      margin-right: 10px;
      margin-top: 4PX;
      position: absolute;
      top: 11px;
      left: -13px;

    }
  }

  .warning {
    .icon-tick {
      display: inline-block;
      height: 25px;
      width: 25px;
      background-size: 24px;
      background-repeat: no-repeat;
      margin-right: 10px;
      margin-top: 4PX;
      position: absolute;
      top: 11px;
      left: -13px;

    }
  }

  .done:before {
    content: '';
    display: inline-block;
    height: 25px;
    width: 25px;
    // background-image: url(#{$image-path}/media-icons/ico-done.svg);
    background-size: 24px;
    background-repeat: no-repeat;
    margin-right: 5px;
    margin-top: 4PX;
    position: absolute;
    top: 10px;

  }

  .warning:before {
    content: '';
    display: inline-block;
    height: 25px;
    width: 25px;
    // background-image: url('#{$image-path}/media-icons/ico-waring.svg');
    background-size: 24px;
    background-repeat: no-repeat;
    margin-right: 5px;
    margin-top: 4PX;
    position: absolute;
    top: 10px;

  }

  .import-heading {
    font-weight: 400;
    line-height: 2;
    display: inline-block;
    margin-left: 20px;
  }

  .begin-with {
    font-size: 16px;
    font-weight: 400;
  }

  .donwload-template {
    font-size: 16px;
    margin-top: 30px;
  }

  .import-utilities {
    font-size: variables.$font-size-medium;
    font-weight: variables.$headings-font-weight;
  }
}

.inventory-summary {
  .welcome-headline {
    font-size: 16px;
  }
}
