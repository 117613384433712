@use "../../settings/third-party/variables";
@use "../../settings/custom";

.quick-navigation {
  background: variables.$white;

  &.custom-quick-navigation {
    .top-section {
      div {
        margin: 0;
        background: #000;

        h2 {
          color: variables.$white;
        }
      }
    }

    .nav-title {
      background: #000;
      color: variables.$white;
    }

    li {
      padding: 15px 8px;
      box-shadow: #949494 0 1px 3px -1px;
    }

    .sidebar-sub-header {
      background: #a8b6c2;

      h3 {
        margin-top: 3px !important;
      }
    }
  }

  .nav-title {
    background: #dde9f9;
    margin: 0;
    padding: 16px;
  }

  li {
    padding: 8px;

    &.disabled {
      background: #eee;
      cursor: not-allowed;
    }

    .function-icon {
      width: 33px;
      height: 33px;
      min-width: 33px;
      margin: 8px;
      cursor: default;
    }

    h2 {
      margin-top: 0;
    }

    p {
      margin-bottom: 0;
    }

    .md-button.md-icon-button {
      margin: 0;
    }

    .add-icon path {
      fill: variables.$brand-accent;
    }
  }
}

.customer-user-list {
  li {
    margin-bottom: 25px;
    position: relative;
  }

  li:last-child {
    margin-bottom: 0;
  }

  .desc {
    margin-left: 55px;

    h3 {
      margin: 0;
    }

    p {
      display: inline-block;
      margin: 8px 50px 0 0;
      position: relative;
      padding-left: 20px;
    }

    .icon-a {
      position: absolute;
      left: 0;
      top: -5px;
    }

    .icon-b {
      position: absolute;
      left: 0;
      top: -3px;
    }

    .icon {
      color: variables.$gray-light;
      vertical-align: bottom;
      font-size: variables.$font-size-large;
    }

    .icon-ico-phone {
      width: 15px;
      height: 20px;
    }

    .desc-info {
      margin-top: 20px;

      li {
        display: inline-block;
        padding-left: 20px;
      }
    }

    .desc-social {
      li {
        display: inline-block;
        padding-right: 10px;
      }
    }
  }

  .user-contact {
    float: left;
    margin-right: 28px;
    margin-top: 4px;
    max-width: 55px;
    color: variables.$gray;

    md-icon {
      width: 55px;
      height: 55px;
    }
  }
}

.location-add {
  width: 60px;
  height: 60px;
}

.address-desc {
  max-width: 60%;
  width: 100%;
}

.add-address-title {
  font-size: 18px;
  font-weight: 500;
}

.add-address-new {
  font-size: 18px;
  font-weight: bold;
}

.add-address-icon {
  height: 48px;
  width: 48px;
  margin: 0 0 15px;
}

.add-hover:hover {
  border: 1.5px dashed variables.$brand-primary;
}

.credit-card-details {
  background-color: #e6f0ef;
}

.credit-region {
  border-radius: 2px;
  font-weight: 600;
}

.delete-credit {
  background-color: white;
}

.card-icon-card {
  margin-top: -15px;
  height: 30%;
  width: 50px;
}

.social-info .subscribe-link {
  md-icon {
    margin-right: 10px;
    vertical-align: top;
    margin-top: -2px;
    height: 18px !important;
    width: 22px !important;
  }

  span {
    display: inline-block;
  }
}

.contact-desc {
  .col-2-list li {
    margin-bottom: 20px;

    label {
      // max-width: 160px;
      padding-right: 20px;
    }
  }

  a {
    font-size: variables.$font-size-small-plus;
    color: variables.$brand-accent;
    font-weight: 500;
  }
}

.social-icons:after {
  border-width: 9px;
}

.social-icons md-menu-item .input-box input {
  width: 69%;
}

.overflow-text {
  max-width: 130px;
  @extend .ellipsis;
}
/*Customer Detail End*/
/*Customer Tags*/
.user-tags li {
  margin: 0 10px 10px 0;

}

.item-chips {
  &.custom-user-tags {
    li {
      background-color: #596773;
      color: #fff;
      margin-bottom: 15px;
      font-size: 14px;
    }
  }
}

/*Customer Tags End*/
// icon-cross-fix

.icon-cross-fix {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  right: 0;
}
//Textarea

.form-base .edit-textarea {
  margin: -3px;
  height: 50px;
  resize: none;
  border-width: 0;
  font-size: variables.$font-size-small;
}

.address-edit {
  position: absolute;
  margin-left: 5px;
}

.full-height {
  height: 100%;
}
@media screen and (max-width:767px) {
  .address-edit {
    position: absolute;
    top: 0;
    right: -140px;
  }
}
@media screen and (max-width:600px) {
  .address-edit {
    right: -10px;
  }
}

.credit-card-details {
  max-width: 300px !important;
  width: 100%;
}
// @media screen and (max-width:650px) {
//   .credit-card-details {
//     max-width: 70% !important;
//     width: 100%;
//   }
// }
// @media screen and (max-width:450px) {
//   .credit-card-details {
//     max-width: 95% !important;
//     width: 100%;
//   }
// }
.inner-sublist {
  li {
    .PosR md-icon {
      display: none;
    }

    &:hover .PosR md-icon {
      display: inline-block;
    }
  }
}

.iPhone .inner-sublist li .PosR md-icon {
  display: inline-block;
}
/*Customer  Details*/
.customer-details {
  .spacer-left-5 {
    margin-left: 5px;
  }

  .data-table.business-detail-table.active-cards {
    th {
      font-weight: bold;
    }
  }

  .active-cards {
    border-bottom: 1px solid variables.$border-color;

    thead {
      background: none;
    }

    td {
      border: none;
      font-size: variables.$font-size-small;
    }

    .card-details {
      color: variables.$brand-primary;
    }
  }

  .continue-further-section {
    // background-color: #f2fffe;
    // padding: 20px;

    .section1 {
      padding: 40px 20px;
      background-color: #f2fffe;

      .next-action-txt {
        color: #a6a6a6;
        font-weight: 600;
      }

      .image-section {
        width: 40%;
        height: 250px;
        background: white;
      }

      .address-details {
        // width: 300px;
        background-color: white;
        padding: 20px 20px 20px 35px;
        box-shadow: -1px 1px 6px rgba(0, 0, 0, 0.3);
        // min-width: 200px;
        min-height: 190px;

        .title {
          font-size: 18px;
          font-weight: bold;
        }

        .address {
          font-size: 15px;
          margin: 5px 0;
        }

        .view-map-cta {
          border: 1px solid variables.$brand-primary;
          width: 100%;
          // min-width:80px;
          max-width: 220px;
          text-align: center;
          background-color: white;
          border-radius: 20px;
          font-weight: 600;
          cursor: pointer;
          color: variables.$brand-primary;
          transition: box-shadow 0.5s, color 0.5s, background-color 0.5s;
          padding: 8px;
          margin-top: 10px;
          // margin-top: auto;

          &:hover {
            box-shadow: -1px 1px 6px rgba(0, 0, 0, 0.3);
            color: white;
            background-color: variables.$brand-primary;
          }

          &.disabled {
            pointer-events: none;
            //cursor: not-allowed;
            border: 1px solid;
            border-color: #dedede;
            background-color: #f2f2f2;
            color: #8e8e93;
          }
        }

        .map-icon {
          height: 40px;
          width: 40px;
          position: absolute;
          top: -15px;
          left: 0;
        }
      }
    }

    .section2 {
      padding: 20px;
      background-color: #e2f5f4;

      .title-section {
        .subtitle,
        .title {
          font-size: 28px;
          font-weight: 600;
        }

        .title {
          color: #5cc5c3;
          margin-bottom: 0;
        }

        .subtitle {
          color: #000;
          margin-top: 2px;
        }
      }

      .quick-navigation {
        // background-color: #f2fffe;
        background-color: #e2f5f4;
        margin: 30px 35px;

        .nav-section {
          background-color: white;
          // height: auto;
          // border: 1px solid black;
          box-shadow: -1px 1px 6px rgba(0, 0, 0, 0.3);
        }

        .nav-title {
          background-color: #21374c;
          color: white;
        }
      }
    }
  }

}

.common-tabs.account-tab md-tabs-wrapper {
  border: 0;
}
/*Customer Associates*/
.customer-associates {
  .common-tabs.account-tab md-tab-item::after,
  .common-tabs.account-tab md-tab-item::before {
    border: none !important;
  }

  .revenue-tab {
    font-size: 18px;
  }

  .business-crop {
    margin-right: 20px;
  }
}
//customer communication

.customer-communicate {
  .recipient-name {
    word-break: break-all;
  }

  .message-time {
    margin-top: 30px;

    &.hidden {
      visibility: hidden;
    }
  }

  .submit-select {
    button {
      border: 1px solid variables.$gray-light-media;
      background: variables.$white;
      color: variables.$gray;
      line-height: 42px;
      min-height: 42px;
      min-width: 80px;
      margin: 0 10px 12px 0;
    }

    .md-button.active {
      border-color: variables.$brand-primary;
      box-shadow: 0 0 1px 0 variables.$brand-primary;
    }
  }

  .communication-details {
    border-left: 2px solid variables.$brand-accent;
    font-size: variables.$font-size-base;
    color: #696969;

    .abstract-detail {
      padding: 10px;
      cursor: pointer;
    }

    .full-detail {
      padding: 0 10px 15px;
    }

    &.unread {
      font-weight: 500;
    }
    // .full-detail {
    //   display: none;
    // }
    .email-body {
      margin-bottom: 20px;
      border-bottom: 1px solid variables.$gray-lighter;

      .email-text {
        margin: 0 0 25px;
      }
    }
  }

  .comment-attachment {
    .deliver-status {
      margin-right: 25px;
    }
  }

  .email-options {
    md-icon {
      margin: 0 0 0 30px;
    }
  }

  .filter-value {
    margin: 0;
    padding: 0;

    md-input-container {
      margin: 0;
      padding: 0;
    }
  }

  .ico-attachment {
    // width: 40px;
    // height: 35px;
    margin: 8px 0 0 15px;
  }

  .communication-status {
    margin: 20px 0;

    md-divider {
      margin-top: 20px;
    }
  }

  .engage-list {
    padding: 7px 0;
    margin: 5px 0;

    h3 {
      margin: 0;
    }

    label {
      color: variables.$gray;
    }

    hr {
      border: 5px solid variables.$brand-primary-dark;
    }
  }

  .opened {
    border-color: variables.$brand-success;
  }

  .clicked {
    border-color: variables.$proposalBlue;
  }

  .delivered {
    border-color: variables.$gray-light;
  }

  .bounced {
    border-color: variables.$brand-danger-dark;
  }

  hr {
    border: 6px solid variables.$brand-primary-dark;
    width: 30px;
    display: inline-block;
    margin: 0 10px 0 0;
    vertical-align: middle;
  }

  md-input-container {
    display: inline-block;
  }

  .status-info {
    margin: 15px 0 0;

    .email-staus p {
      display: inline-block;
    }

    h3 {
      margin: 0 10px 0 0;
      vertical-align: middle;
    }
  }

  .tag-btns .md-button:hover:enabled {
    background: variables.$white;
    border-color: variables.$brand-primary;
    box-shadow: 0 0 1px 0 variables.$brand-primary;
  }

  .email-filter {
    margin: 0;
  }

  .email-status p {
    display: inline-block;
    vertical-align: middle;
  }

  .content-spacing {
    margin: 0 10px;
  }

  .personal-detail h3 {
    margin-left: 10px;
  }
  // CAMPAIGNS tab
  .campaign-tab {
    .campaign-stat {
      padding: 15px 10px;
      text-align: left;
      margin-right: 15px;
    }
  }
}
@media(min-width:600px) {
  .customer-communicate {
    .recipient-name {
      width: 120px;
    }

    .message-time {
      width: 115px;
    }

    .sent-time {
      width: 105px;
    }
  }
}
@media(max-width:600px) {
  .customer-communicate {
    .email-options {
      margin-top: 20px;
    }

    .communication-details {
      margin: 8px 2px 15px 0;
    }

    .message-time.hidden {
      display: none;
    }
  }
}

.view-champaign {
  .active-status md-icon {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
}

.change-list {
  min-height: 420px;
  width: 660px;

  .gap-20 {
    gap: 20px;
  }

  md-toolbar {
    &.main-header {
      h2 {
        font-size: 16px;

        font-weight: normal;
      }
    }
  }

  .main-section {
    padding: 20px;

    .active-cta,
    .inactive-cta {
      width: 130px;
      border: 1px solid #6a6a6a;
      padding: 6px 15px;
      /* text-align: center; */
      border-radius: 20px;
      font-size: 15px;
      font-weight: 600;
      background-color: #d3e1e5;

      &.active {
        border-color: #a8b6c2;
      }
    }

    .active-cta {
      &.active {
        color: #6aa84f;
      }
    }

    .inactive-cta {
      &.active {
        color: #e06666;
      }
    }

    .description {
      max-width: 380px;
      margin: 2px;
    }

    .no-cancel-cta,
    .yes-move-cta {
      padding: 10px 15px;
      cursor: pointer;
      font-size: 14px;
      transition: 0.5sec box-shadow;

      &:hover {
        box-shadow: -1px 1px 6px rgba(0, 0, 0, 0.3);
      }

    }

    .no-cancel-cta {
      background-color: transparent;
      border: 1px solid #a6a6a6;

    }

    .yes-move-cta {
      background-color: #f39c26;
      color: white;

    }
  }
}

.customer-details-campaigns-segments-list {
  div {
    &.md-button {
      padding: 0 !important;
    }
  }
}

.customer-nurturing {
  .nurturing-title {
    color: #5cc5c3;
  }

  .nurturing-title-image {
    width: 40px;
    height: 40px;
  }

  .nurturing-btn {
    color: variables.$brand-primary;
    border: 1px solid variables.$brand-primary;
    min-width: 157px;

    &.btn-primary {
      color: #fff;
    }
  }
}

.sidenav-btn-cta {
  .md-button {
    min-width: 180px;
    border-radius: 45px;
    border: 1px solid variables.$brand-primary;

    &:hover {
      background: #F3F3F3;
      color: variables.$brand-primary;
    }
  }
}
