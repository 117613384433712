@use "../settings/third-party/variables";

.tagging-machine {
  // border: 2px dashed $gray-lighter;
  box-shadow: 0 5px 5px -5px variables.$gray-base;
  padding: 18px;
  // bottom: auto;
  z-index: 2;

  .inner-inputs li {
    height: auto;
    max-height: none;
  }

  .tagging-header {
    margin-top: 8px;
    margin-bottom: 8px;

    md-icon {
      height: 21px;
      margin-right: 8px;
      width: 21px;
    }

    h1 {
      font-weight: normal;
    }
  }

  md-chips {
    margin-bottom: 10px;
  }

  .md-chips .md-chip-input-container {
    float: none;
  }

  .md-chip-remove-container {
    z-index: 10;

    md-icon {
      color: rgba(0, 0, 0, 0.54);
      fill: rgba(0, 0, 0, 0.54);
    }
  }

  md-autocomplete > md-autocomplete-wrap {
    > input {
      margin-top: 8px;
    }

    > md-progress-linear {
      display: none;
    }
  }

  .tag-buttons {
    .md-button {
      margin: 0 8px 0 0;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .tagging-machine {
    .tag-buttons {
      text-align: right;
      margin-top: 15px;
    }
  }
}
