@use "../../settings/third-party/mixins";
@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.business-popups-section {
  width: 800px;
  min-height: 556px;
  overflow: hidden;

  md-toolbar.main-header .md-toolbar-tools {
    .md-icon-button md-icon {
      height: 18px;
      width: 18px;
    }

    h2 {
      font-size: 16px;
      line-height: 32px;
    }

    md-icon {
      height: 18px;
      width: 18px;

      svg path {
        fill: variables.$white;
      }
    }
  }

  .logo-edit-container {
    @include mixins.border-radius(3px);
    border: 1px solid variables.$border-color;
    margin: 114px auto 0;
    max-width: 100%;
    width: 515px;
    padding: 18px 0;

    .icon-trash {
      height: 18px;
      width: 15px;

      svg path {
        fill: variables.$gray;
      }
    }

    .icon-upload {
      color: variables.$gray-darker;

      md-icon {
        margin-right: 10px;
      }
    }
    // .logo-holder img {
    //   max-width: 100%;
    // }
  }

  .divider {
    border-right: 1px solid variables.$white-darker;
  }

  .region-custom-padding {
    padding: 40px;

    .add-region {
      margin-bottom: 15px;

      label {
        font-size: 14px;
      }
    }
  }
  // .add-region md-autocomplete {
  //   max-width: 500px;
  //   margin   : 50px auto;
  // }
}

.loader-popup {
  min-height: 245px;
  width: 480px;

  md-dialog-content {
    padding: 20px;
  }

  .close-btn {
    min-width: auto;
    position: absolute;
    top: 12px;
    left: 18px;

    md-icon {
      height: 17px;
      width: 17px;

      svg path {
        fill: #424853;
      }
    }
  }

  .loading-img {
    height: 45px;
    margin-bottom: 20px;
  }

  .loader-msg {
    @extend .f2;
    line-height: 21px;
  }
}
// @media screen and (min-width: 660px) {
//   .business-popups-section {
//     .logo-holder img {
//       margin-left: 20px;
//     }
//   }
// }
@media screen and (max-width: 600px) {
  .business-popups-section {
    max-width: 100%;

    md-toolbar.main-header .md-toolbar-tools {
      .btn-primary {
        min-width: 45px;
      }
    }

    .logo-holder {
      margin-bottom: 30px;
    }

    .divider.hide-divider-xs {
      border-right: none;
    }
  }
}
