@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.view-by {
  md-input-container {
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 15px;

    .md-static {
      display: none;
    }
  }

  label.f4 {
    padding-top: 11px;
    display: inline-block;
  }
}

.right-down-arrow {
  height: 14px;
  margin-left: 5px;
  position: relative;
  width: 14px;

  svg path {
    fill: variables.$brand-accent;
  }

  &:before {
    content: '';
    display: inline-block;
    border-left: 1px solid variables.$brand-accent;
    position: absolute;
    top: -5px;
    left: -1px;
    bottom: 7px;
    opacity: 0.7;
    cursor: pointer;
  }
}

.service-list {
  // overflow-x: auto;

  .data-table {
    td {
      &.width-xs {
        min-width: 150px;
      }

      &.width-sm {
        min-width: 200px;
      }

      &.width-price {
        min-width: 170px;
      }

      &.width-s {
        min-width: 120px;
      }
    }

    .address-icon {
      height: 14px;
      width: 14px;
      flex: none;
      margin: 3px 0 0;
    }

    .draft {
      color: variables.$status-gray;
    }

    .submitted {
      color: variables.$status-green;
    }

    .modified {
      color: variables.$status-yellow;
    }

    .tax-value {
      color: variables.$gray;
      margin-left: 4px;
    }

    &.service-table {
      min-width: 700px;

      // @media screen and (min-width: 1025px) {
      //     td {
      //         &:last-child {
      //             width: 30px;
      //         }

      //         &:nth-child(7) {
      //             width: 120px;
      //         }

      //         &:nth-child(6) {
      //             width: 120px;
      //         }
      //     }
      // }
    }
  }

  .swipe-wrapper {
    // overflow: hidden;
    min-width: 720px;
    margin-top: 10px;
  }

  .disable-clear {
    cursor: default;
    // position: fixed;
    // position: absolute;
  }

  .enable-clear {
    cursor: pointer;
    // position: fixed;
  }
}

.distanc-marg {
  margin-top: 0;
  padding-bottom: 0;
  display: inline-block;
}

.service-recurring {
  overflow-x: auto;

  .data-table {
    min-width: 700px;

    .item-sublist td {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .tax-value {
      color: variables.$gray;
      margin-left: 4px;
    }
  }

  .vertical-bottom {
    vertical-align: bottom;
  }

  .vertical-middle {
    vertical-align: middle;
  }

  .price-min-col {
    min-width: 60px;
  }
}

.service-edit {
  .switch-btns {
    margin-bottom: 20px;

    md-switch {
      margin-left: 10px;
      top: 2px;
    }
  }

  .rb-buttons {
    md-radio-button {
      @extend .f3;

      margin-bottom: 18px;
    }
  }

  .small-txt {
    font-size: 10px;
  }

  .days {
    .day-text {
      margin-bottom: 15px;
    }

    .timings {
      font-size: 10px;
    }
  }
}

@media screen and (min-width: 960px) {
  .service-edit .switch-btns {
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
}
