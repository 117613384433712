@use "../../settings/third-party/mixins";
@use "../../settings/third-party/variables";
@use "../../settings/style-guide";
@use "../../settings/custom";

.product-joiners {
  height: 90%;
  width: 90%;
  max-width: none;
  max-height: none;

  .joiners-header {
    background: transparent;
    border-bottom: 1px solid variables.$gray-light;

    .heading {
      max-height: 64px;
      height: 64px;
      padding: 7px 0 3px;

      .head-img {
        display: inline-block;
        vertical-align: top;
      }

      .head-txt {
        display: inline-block;
        vertical-align: top;

        h2 {
          display: inline-block;
          margin-top: 5px;
          @extend .f1;
        }

        p {
          font-size: 11px;
          margin-top: 5px;
        }
      }
    }
  }

  .ico-cross {
    transform: rotate(45deg);
    margin-left: 10px;
    height: 20px;
    width: 20px;

    svg {
      path {
        fill: variables.$gray-light;
      }
    }

    &:hover {
      svg {
        path {
          fill: variables.$gray;
        }
      }
    }
  }

  md-dialog-content {
    background: variables.$white-dark;
    overflow-x: hidden;

    .custom-tabs md-tabs-wrapper {
      margin-top: -3px;
      margin-bottom: 15px;
    }

    .md-choice-list,
    .upper-section {
      background: variables.$white;
      border-right: 1px solid variables.$gray-light;
    }

    .md-choice-list {
      border-top: 1px solid variables.$gray-light;
      padding: 16px;

      .list-col {
        margin-right: 30px;

        li {
          display: inline-block;
          vertical-align: top;
          margin: 5px 5px 5px 0;
          height: 65px;
          width: 80px;
          text-align: center;
          border: 1px solid variables.$gray-lighter;
          border-radius: variables.$border-radius-small;
          position: relative;
          @extend .f4;

          span {
            position: absolute;
            bottom: 10px;
            left: 0;
            right: 0;
          }

          &.active {
            border-color: variables.$brand-accent;
          }
        }

        &.one {
          width: 92px;
        }

        &.two {
          width: 175px;
          height: 310px;
          column-count: 2;
          -moz-column-count: 2;
          -webkit-column-count: 2;
        }

        &.addition-list {
          min-height: 310px;

          li {
            box-sizing: border-box;
            width: 110px;
            margin-right: 10px;
            margin-bottom: 10px;
            padding: 10px 8px;
            text-align: left;

            span {
              left: 8px;
            }
          }

          h3 {
            @extend .f3;
            @extend .ellipsis;
            width: 100%;
          }
        }
      }
    }

    .add-list-info {
      ul {
        li {
          border-bottom: 1px solid variables.$gray-light;
          padding: 10px 35px 10px 20px;
          position: relative;

          &:last-child {
            border-bottom: 0;
          }

          textarea {
            background: variables.$white;
            margin-top: 12px;
            height: 80px;
          }

          .success {
            display: inline-block;
            color: variables.$brand-success;
            margin: 0 5px;
          }

          .ico-cross {
            position: absolute;
            right: 10px;
            top: 7px;
          }
        }
      }
    }

    h3 {
      @extend .f1;
      margin: 0 0 10px;
      font-weight: 400;
    }

    .joiner-list {
      @extend .no-style;
      @include mixins.border-radius(3px);
      @extend .custom-scroll;
      border: 1px solid #d6e1e5;
      height: 160px;
      max-height: 160px;
      max-width: 190px;
      overflow-y: auto;
      padding: 0;

      li {
        height: 39px;
        line-height: 39px;

        &.selected {
          background-color: variables.$white-dark;
        }
      }

      md-radio-button {
        margin-bottom: 0;
        padding: 0 10px;

        .md-container {
          left: 10px;
        }

        &.md-checked .md-off,
        .md-off {
          border-color: #d6e1e5;
        }

        .md-label {
          @extend .ellipsis;
          max-width: 120px;
          margin-left: 40px;
        }

        .md-off {
          border-width: 1px;
        }

        .md-on {
          background-color: variables.$brand-accent;
        }
      }

      md-checkbox {
        margin-bottom: 0;
        height: 39px;
        line-height: 33px;
        padding: 0 10px;
        width: 100%;

        // .md-icon {
        //   border: none;
        // }

        .md-container {
          left: 10px;
        }
      }
    }

    .joiner-picker {
      margin: 4px 0 0;

      .gap {
        margin-left: 25px;
      }
    }

    .add-btn {
      margin-bottom: 0;
      min-width: 60px;
      min-height: 30px;
      line-height: 30px;
    }

    .order-msg {
      margin: 10px 0;
      border-left: 1px solid variables.$gray-light;
      padding: 0 20px;

      label.md-static {
        display: none;
      }

      md-select,
      md-select span {
        @extend .f4;
      }
    }
  }
}
@media only screen and (max-width: 960px) {
  .product-joiners md-dialog-content .md-choice-list .list-col.one {
    width: auto;
  }

  .product-joiners md-dialog-content .md-choice-list .list-col {
    margin-right: 0;
    margin-bottom: 25px;
  }

  .product-joiners md-dialog-content .md-choice-list .list-col.two {
    width: auto;
    height: auto;
    column-count: auto;
    -moz-column-count: auto;
    -webkit-column-count: auto;
  }

  .product-joiners md-dialog-content .order-msg {
    border: 0;
    padding: 0 8px;
  }
}
@media only screen and (max-width: 600px) {
  .product-joiners md-dialog-content .joiner-picker {
    padding-right: 0;

    .gap {
      margin: 10px 0;
    }
  }

  .product-joiners .joiners-header .heading {
    p {
      display: none;
    }

    .head-img {
      display: none;
    }

    .head-txt {
      padding-top: 10px;
    }
  }

  .product-joiners md-dialog-content .md-choice-list .list-col li {
    height: 60px;
    width: 70px;
  }
}
