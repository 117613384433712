@use "../settings/third-party/variables";
@use "../settings/style-guide";
@use "../settings/custom";

/*Calander style*/
.calendar-base {
  .btn-toolbar {
    text-align: center;
    margin-bottom: -25px;

    .btn-group {
      display: inline-block;
      overflow: hidden;
      border-radius: 5px;
      border: 1px solid variables.$brand-primary;
      padding: 0;
      margin: 0;
    }

    .btn {
      border-style: solid;
      border-width: 0 1px 0 0;
      border-color: variables.$white;
      background-color: variables.$brand-primary;
      float: left;
      color: variables.$white;
      outline: none;
      padding: 7px 28px;
      font-size: variables.$font-size-small;
      font-weight: 400;
      transition: cubic-bezier(.74, 0, .56, 1) all 0.2s;

      &.active {
        background-color: variables.$white;
        color: variables.$gray-dark;
        transition: cubic-bezier(.74, 0, .56, 1) all 0.2s;
      }

      &:last-child {
        border: none;
      }
    }
  }

  .fc-toolbar {
    .fc-left {
      h2 {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
  /* month view */
  /* month default */
  .fc-unthemed {
    .fc-month-view {
      .fc-row.fc-week.fc-widget-content {
        height: 96px;
        overflow: hidden;
      }

      .fc-today {
        background-color: transparent;
        position: relative;
        font-size: variables.$font-size-base;
        color: #fff;

        .date-holder {
          @extend .location-symbol;
          font-size: variables.$font-size-base;
          line-height: 1.4;
          font-weight: 400;
        }
      }
    }

    .fc-agendaDay-view .fc-today {
      background-color: transparent;
      position: relative;
    }
  }

  .event-style {
    background-color: variables.$brand-primary;
    border: 2px solid variables.$brand-primary;
    border-radius: 15px;
    padding-left: 15px;
  }

  .fc-event {
    background-color: #fff !important;
    border-width: 0 0 0 4px;
    border-radius: 5px;
    padding: 5px;
    box-shadow: rgba(17, 17, 26, 0.05) 0 1px 0px, rgba(17, 17, 26, 0.1) 0 0 8px;

    .fc-content {
      color: #000;
    }

    .fc-time {
      margin-bottom: 10px;
    }

    .fc-title {
      font-weight: bold;
    }
  }

  .fc-event:hover {
    background-color: rgb(209, 209, 249) !important;

    .fc-title {
      color: variables.$brand-primary;
    }
  }

  .fc-more-cell {
    .fc-more {
      @extend .event-style;
      background-color: variables.$white;
      display: block;
      margin-left: 2px;
      margin-right: 2px;
      border-width: 1px;
      font-size: 11px;
      text-decoration: none;
    }
  }

  /* month day */
  .fc-basic-view td.fc-day-number,
  .fc-basic-view td.fc-week-number span {
    padding: 8px;
  }
  /* month view End */
  .fc-time-grid .fc-slats {
    .fc-minor td {
      border-top-style: none;
    }

    td:first-child {
      border: none;
    }
  }

  .fc-more-popover {
    border-radius: 5px;
    overflow: hidden;
    border-color: transparent;
    box-shadow: 0 2px 9px -2px rgba(variables.$gray-base, 0.5);

    .fc-header.fc-widget-header {
      background: #fff;
      border-bottom: 1px solid #eee;
      height: 20px;
      padding-top: 9px;
      position: relative;

      .fc-icon.fc-icon-x {
        font-size: variables.$font-size-small;
        position: absolute;
        right: 7px;
        transition: all ease 0.5s;
        padding: 2px;
        border-radius: 50%;
        top: 6px;
        color: variables.$gray;

        &:hover {
          background-color: variables.$gray;
          color: #fff;
          transition: all ease 0.5s;
        }
      }
    }

    .fc-body.fc-widget-content {
      max-height: 200px;
      overflow: auto;
      @extend .custom-scroll;

      .fc-event-container {
        padding: 0;

        .fc-event.fc-day-grid-event {
          // background: $white;
          // color: $gray-dark;
          border-width: 0 0 2px 2px;
          border-color: variables.$gray-lighter variables.$brand-primary;
          border-radius: 0;
          padding: 8px;
          font-size: 11px;
          margin-left: 0;
          margin-right: 0;
          margin-top: -1px;
        }
      }
    }
  }

  .fc-right-inner md-input-container {
    margin-top: -7px;
    margin-right: 5px;

    label.select-mark {
      position: absolute;
      top: -15px;
      right: 5px;
      padding: 0;
      left: inherit;
    }
  }

  .cal-r-side {
    display: inline-block;
    vertical-align: top;
  }
}
/*Create event Calendar popup starts here */
.create-event-popup {
  width: 395px;
  height: 330px;
  padding: 5px 15px;

  md-toolbar.main-header {
    background-color: #FFFFFF;
    border-bottom: 1px solid variables.$white-darker;

    .md-icon-button.close-popup {
      svg path {
        fill: variables.$gray-lighter;
      }
    }
  }

  &.form-base h3 {
    font-size: variables.$font-size-base;
  }

  md-toolbar.main-header .md-toolbar-tools .md-icon-button.close-popup md-icon svg path {
    fill: variables.$gray-dark;
  }

  .create-event-field {
    @extend .no-style;
    padding: 8px 20px;

    li {
      position: relative;
      padding: 10px 0;
      height: 26px;

      label {
        @extend .f4;
        display: inline-block;
        min-width: 95px;
        margin-right: 5px;
      }

      &.date-picker-custom {
        padding: 0;

        md-datepicker .md-datepicker-input-container input {
          min-width: 104px;
          max-width: 104px;
          margin: 0;
          height: 22px;
        }
      }

      .date-picker {
        display: inline-block;

        .md-datepicker-input-container {
          border-bottom: 1px solid rgb(224, 224, 224);
          height: 22px;
        }

        input::-webkit-input-placeholder {
          font-size: variables.$font-size-small;
        }

        input:-moz-placeholder {
          /* Firefox 18- */
          font-size: variables.$font-size-small;
        }

        input::-moz-placeholder {
          /* Firefox 19+ */
          font-size: variables.$font-size-small;
        }

        input:-ms-input-placeholder {
          font-size: variables.$font-size-small;
        }
      }

      md-input-container {
        margin: 0;
        height: 28px;

        md-select {
          height: 31px;
          width: 80px;
          margin-right: 5px;

          md-select-value {
            height: 28px;
            min-width: 0;
            min-height: 28px;
          }
        }

        label {
          height: 28px;
          line-height: 28px;
        }

        label,
        span {
          font-size: variables.$font-size-small;
          color: variables.$gray-light;
        }

        input {
          height: 28px;
        }
      }
    }
  }
}
/*Create event Calendar popup ends here */

/* Calendar Right sidebar style */
.sidenav-wrapper {
  // .ar-sidepanel {
  //   border-left: 2px solid $gray-light;
  // }
}

.back-white {
  .ar-sidepanel {
    background: variables.$white;

    .head-section {
      height: auto;
      background: variables.$white-dark;
    }
  }
}

.event-container {
  margin-top: 8px;

  &:last-child {
    margin-bottom: 20px;
  }

  .date-events {
    margin-top: 8px;
    margin-bottom: 16px;

    h2 {
      font-weight: 500;
    }

    .badge-caption {
      span {
        @extend .location-symbol;
        background-color: #E1EEF3; //static color
        color: variables.$gray-dark;
      }
    }
  }

  .event-info {
    padding-left: 10%;
    margin-bottom: 12px;
    position: relative;

    &:before {
      content: "";
      display: inline-block;
      background: variables.$brand-primary;
      border-radius: 50%;
      height: 5px;
      width: 5px;
      position: absolute;
      left: 7%;
      top: 4px;
    }

    &.green:before {
      background-color: variables.$brand-success;
    }

    .event-title {
      @extend .f3;
      font-weight: 400;
      margin: 0 0 2px;
    }

    label {
      font-size: 10px;
      color: variables.$gray;
      margin-bottom: 3px;
    }
  }
}

.map-r-sidebar {
  .leftnav-wrap {
    padding: 0;
  }

  .head-section {
    position: relative;
    padding: 4px 10px;
  }

  .sidenav-title {
    margin-top: 5px;

    .search-box {
      top: 16px;
      right: 20px;
    }

    .search-button {
      top: 11px;
    }

    h2 {
      margin-left: 0;
    }

    .icon-map-pin {
      margin-right: 10px;
      width: 12px;
      cursor: auto;

      svg path {
        fill: variables.$brand-success-dark;
      }

      &.orange {
        svg path {
          fill: variables.$brand-primary;
        }
      }
    }
  }

  .map-event {
    li {
      padding: 20px 0;
      color: variables.$gray-dark;
      border-bottom: 1px solid variables.$white-dark;
    }

    .date {
      margin-top: 5px;
    }

    .map-user,
    .month {
      color: variables.$gray;
    }

    .day {
      font-size: variables.$font-size-base;
      font-weight: 700;
      margin-bottom: 2px;
    }

    .map-title {
      h3 {
        margin: 0;
        color: variables.$gray-dark;
        font-weight: 400;
      }

      .icon-location {
        margin: 0 20px;
        width: 14px;
      }
    }
  }
}

.fc .fc-toolbar {
  margin-bottom: 10px;

  .fc-right > .fc-today-button {
    margin-left: 0;
    border-radius: 0;
    height: 28px;
    background: variables.$brand-primary;
    color: variables.$white;
    border-left-color: variables.$white;
    border-right-color: variables.$white;
  }

  .fc-right-inner {
    margin-top: 3px;
  }
}

.fc-left {
  width: 155px;
}

.fc-right {
  min-height: 30px;

  .fc-prev-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0;
  }

  .fc-next-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 0;
    margin-left: 0;
  }

  .fc-next-button,
  .fc-prev-button {
    background: variables.$brand-primary;
    color: variables.$white;
    height: 28px;
    width: 40px;
  }

  .fc-today-button {
    padding: 0;
  }

  .fc-next-button.fc-state-default,
  .fc-prev-button.fc-state-default {
    box-shadow: none;
  }

  h2 {
    margin-top: 5px;
  }
}

.calendar-base .fc-unthemed .fc-month-view .fc-today {
  color: variables.$proposalBlue;
}

.calendar-r-sidebar {
  .sidenav-title {
    font-size: 16px;
    font-weight: 400;
    margin: 0 0 10px;
    vertical-align: middle;
    height: 35px;

    .edit-calendar-icon {
      width: 30px;
      height: 30px;
    }
  }

  .show-date-time {
    margin: 5px 0;
  }
}

.top-gap {
  margin-top: 15px;
}
