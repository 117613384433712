@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.repository-section {
  .coupon-list {
    li {
      &.selected {
        border-color: variables.$brand-accent;
      }
    }

    &.layout2 {
      li {
        overflow: hidden;
      }

      .img-box {
        margin: -8px -10px 10px;

        img {
          width: 100%;
          max-height: 146px;
        }
      }

      .menu-btn {
        top: 1px;
        right: -7px;
      }
    }

  }

  .thumbnail-holder {
    @extend .no-style;

    margin: 0 60px 10px;
    overflow: hidden;
    white-space: nowrap;

    li {
      box-sizing: border-box;
      display: inline-block;
      margin-right: 10px;
      padding: 5px;
      width: 115px;

      &.selected {
        border: 1px solid variables.$gray-light;
      }

      img {
        max-height: 85px;
        width: 100%;
      }
    }
  }

  .arrow-holder {
    width: 60px;

    .arrow-btn {
      height: auto;
      margin: 0;
      min-height: auto;
      width: auto;

      md-icon {
        height: 35px;
        width: 35px;

        &.right-arrow {
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
    }
  }

  .slider-container {
    padding-right: 10px;
    padding-left: 10px;

    .img-container {
      padding: 25px;

      img {
        max-height: 380px;
        width: 100%;
      }
    }
  }
}

.image-upload-sidebar {
  .apply-al-cb {
    margin-top: 20px;
    margin-left: 0;

    .md-container {
      background: variables.$white;
    }
  }
}
