@use "../settings/third-party/variables";

.activity-setup {
  .subsection-title {
    margin: 25px 0;
  }

  md-radio-group {
    margin-bottom: 20px;
  }

  .sub-categories {
    li {
      border: 1px solid variables.$gray-lighter;
      min-width: 140px;
      margin: 0 20px 20px 0;
      padding: 15px;

      &.selected {
        border-color: variables.$brand-primary;
      }

      &.disabled {
        // cursor: not-allowed;
        pointer-events: none;
        background: variables.$gray-lighter;
      }
    }

    md-icon {
      height: 45px;
      width: 45px;
    }
  }

  .add-resources-btn {
    md-icon {
      margin: 0 10px 0 0;
    }
  }
}
