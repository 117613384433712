@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.update-profile {
  padding: 20px;

  .status-icon {
    margin-right: 7px;
  }
}

.communication-section {
  .active-status {
    margin-right: 7px;
  }

  .email-setup {
    margin-bottom: 10px;
  }
}

.autocomplete-currency {
  li {
    border-bottom: 1px solid variables.$gray-lighter;
  }
}

.pwd-strength-checker {
  .type {
    font-size: variables.$font-size-small;
    margin-bottom: 10px;
  }

  md-progress-linear {
    &,
    .md-container {
      &,
      .md-bar {
        height: 2px;
      }
    }

    .md-container {
      background-color: variables.$border-color;
    }
  }

  &.pwd-strong {
    .type {
      color: variables.$brand-success-dark;
    }

    md-progress-linear .md-bar {
      background-color: variables.$brand-success-dark;
    }
  }

  &.pwd-weak {
    .type {
      color: variables.$brand-danger-dark;
    }

    md-progress-linear .md-bar {
      background-color: variables.$brand-danger-dark;
    }
  }
}

.business-section {
  // padding: 25px 20px;
  // padding: 8px;

  .ui-accordion {
    section {
      padding: 16px;

      .holiday {
        .date-picker {
          height: 15px;
          padding-top: 0;
          margin-bottom: 28px;

          md-datepicker {
            width: 240px;

            .md-datepicker-input-container {
              width: 240px;
            }

            .md-datepicker-button {
              position: absolute;
              top: -9px;
            }
          }
        }

        .bottom-gap {
          margin-bottom: 28px;

          &.right-gap {
            margin-right: 25px;
          }
        }
      }
    }

    .left-section {
      width: 64%;
    }

    .right-section {
      width: 34%;
    }

    .actions {
      flex: 1;
      max-width: 30px;
    }
  }

  .custom-tabs {
    md-content {
      padding-top: 20px;
    }
  }

  .form-base {
    md-input-container {
      margin-bottom: 28px;

      &.smaller-input {
        input {
          border: none;
        }
      }

      &.day-input {
        margin-bottom: 15px;
      }
    }

    md-autocomplete {
      width: 100%;
      margin-right: 0;
    }

    // md-autocomplete-wrap {
    //   md-input-container {
    //     margin-bottom: 0;
    //   }
    // }
  }

  .update-status {
    color: variables.$gray;
    font-size: 10px;
    position: absolute;
    top: 6px;
    right: 0;
    z-index: 1;
  }

  .titles {
    margin-top: 0;
  }

  .md-button {
    &.add-field-btn {
      margin: 0 8px 0 0;
      padding: 0;
      height: 27px;
      min-height: 27px;
      width: 27px;

      md-icon {
        svg path {
          fill: variables.$brand-primary;
        }
      }

      &.side-add-btn {
        top: -6px;
      }
    }
  }

  .switch-bar {
    label {
      margin-right: 15px;
      padding-right: 0;
    }
  }

  .verification-section {
    display: inline-block;

    md-icon {
      height: 21px;
      margin-right: 10px;
      width: 21px;
    }

    .status {
      color: variables.$gray-darker;
      font-size: variables.$font-size-small;
    }
  }

  .ownership-tab-section {
    .logo-edit-section {
      .titles {
        color: variables.$gray;
        margin-bottom: 10px;
      }

      .logo-holder {
        border: 1px solid variables.$border-color;
        height: 95px;
        margin-bottom: 28px;
      }

      .ico-default {
        margin: -6px 22px 0 30px;

        &,
        &:after,
        &:before {
          background: variables.$brand-accent;
        }
      }

      h1 {
        margin: 0;
        font-size: 24px;
        font-weight: 300;
      }
    }
  }

  .business-tab-section .verification-section,
  .ownership-tab-section .md-button.add-field-btn.side-add-btn {
    margin-bottom: 20px;
  }

  .login-input-section {
    @extend .no-style;

    li {
      margin-bottom: 25px;

      label {
        padding-right: 12px;
      }
    }

    md-checkbox,
    md-input-container {
      margin-bottom: 0;
    }
  }
  /* Business tab code starts here */
  .business-tab-section {
    .titles {
      margin-bottom: 30px;
    }

    .switch-bar {
      margin-bottom: 20px;
    }

    .working-hour-section {
      h2.op-hours {
        margin-bottom: 20px;
      }

      // h3 {
      //   color: $gray;
      //   font-size: 10px;
      //   position: absolute;
      //   top: -22px;
      //   left: 0;
      //   right: 0;
      // }

      .location-above {
        .apply-schedule {
          @extend .f8;
        }
      }
    }

    .menu-btn {
      left: 0;
    }

    .location-actions {
      .titles {
        display: inline-block;
      }

      .menu-btn {
        position: relative;
      }
    }
  }
  /* Business tab code ends here */
  /* Legal tab code starts here */
  .legal-tab-section {
    // md-checkbox {
    //   margin-bottom: 20px;
    // }

    .logo-edit-section {
      .titles {
        color: variables.$gray;
        margin-bottom: 10px;
      }

      .logo-holder {
        border: 1px solid variables.$border-color;
        height: 95px;
        margin-bottom: 28px;
      }

      .ico-default {
        margin: -6px 22px 0 30px;

        &,
        &:after,
        &:before {
          background: variables.$brand-accent;
        }
      }

      h1 {
        margin: 0;
        font-size: 24px;
        font-weight: 300;
      }
    }

    .titles {
      color: variables.$gray-darker;
      font-weight: normal;
      margin-bottom: 21px;
    }

    .sub-section {
      .switch-bar {
        margin-bottom: 21px;
      }
    }

    .item-chips {
      li {
        &:before {
          content: '';
          display: inline-block;
          background: url("#{variables.$image-path}/icons/set-b/ico-pdf.svg") no-repeat 0 0;
          background-position: 0 0;
          background-size: 15px;
          height: 17px;
          margin-right: 10px;
          width: 15px;
          vertical-align: middle;
        }
        margin: 0 15px 15px 0;
      }
      margin-bottom: 21px;
    }

    md-checkbox {
      margin-right: 15px;

      .md-label {
        color: variables.$gray;
      }
    }

    md-input-container {
      &.invoice-editor {
        margin-top: 4px;
        margin-right: 0;
        margin-bottom: 10px;

        textarea {
          @extend .f3;
          height: 125px !important;
          line-height: 16px;
        }
      }
    }

    .upload-doc-container {
      color: variables.$gray-darker;
      font-size: variables.$font-size-small;
      margin-bottom: 25px;

      md-icon {
        height: 21px;
        margin-right: 10px;

        path {
          fill: variables.$brand-primary;
        }
      }
    }

    .custom-terms {
      .icon-trash {
        margin-top: 15px;
        height: 20px;
        width: 20px;
      }
    }
  }
  /* Legal tab code ends here */
  &.profile-view {
    line-height: 1.5;

    h2 {
      font-size: variables.$font-size-large;
      margin-top: 0;
      margin-bottom: 5px;
      font-weight: 500;
    }

    h4 {
      margin-bottom: 7px;
    }

    p {
      margin-top: 5px;
    }

    ul {
      margin-top: 5px;
    }

    .item-chips {
      li {
        padding: 8px 10px;
      }
    }

    li {
      padding: 4px 0;
    }

    .custom-tabs md-content {
      padding-top: 25px;
    }

    .co-custom-icon {
      height: 13px;
      width: 13px;
      margin-right: 2px;

      path {
        fill: variables.$gray-light;
      }
    }

    .col-list-2 label {
      min-width: 100px;
      display: inline-block;
    }

    .phn-num {
      padding-top: 10px;
    }
  }
}

@media only screen and (min-width: 600px) {
  .business-section {
    .form-base {
      .locations {
        md-input-container {
          margin-right: 20px;
          width: 240px;

          // input,
          // md-select {
          //   width: 240px;
          // }

          &.address-input {
            width: 500px;
            // input {
            // }
          }

          &.smaller-input {
            width: 110px;

            input,
            md-select {
              width: 110px;
            }
          }
        }

        // md-checkbox {
        //   margin-top: 12px;
        // }

        md-autocomplete-wrap {
          // margin-bottom: 25px;
          width: 500px;

          md-input-container {
            margin-right: 0;

            input {
              width: 100%;
            }
          }
        }
      }

      // .time-selector-inputs {
      //   md-input-container {
      //     &.smaller-input {
      //       md-select {
      //         width: 160px;
      //       }
      //     }
      //   }
      // }
    }

    .md-button.add-field-btn.side-add-btn {
      margin-left: -12px;
    }

    .business-tab-section {
      .verification-section {
        position: relative;
        top: -4px;
      }

      .working-hour-section {
        .add-field-btn {
          margin-top: 8px;
        }
      }
    }

    // .logo-edit-section {
    //   max-width: 500px;
    // }
  }
}
@media only screen and (max-width: 600px) {
  .business-section {
    .business-tab-section {
      .working-hour-section {
        .add-field-btn {
          margin-bottom: 20px;
        }

        .apply-week {
          margin-bottom: 20px;
        }

        .location-above {
          .apply-schedule {
            margin-bottom: 15px;
          }
        }
      }
    }

    .currency-addloc {
      md-input-container {
        width: 100%;
      }
    }
  }
}
@media only screen and (min-width: 600px) {
  .working-hour-section {
    // md-checkbox {
    //   label {
    //     position: relative;
    //     top: 14px;
    //   }
    // }

    .apply-week {
      position: relative;
      top: 17px;
    }

    .location-above {
      .apply-schedule {
        margin-top: 15px;
      }

      .smaller-input {
        margin-right: 30px;
      }
    }
  }
}
@media only screen and (max-width: 400px) {
  .business-section {
    .update-status {
      top: 40px;
    }
  }
}

//view-invoice
.order-invoice {
  .order-set {
    &.policy-header {
      background-color: #52758d;
    }
    background-color: #d3e1e5;

    .notes-icon {
      height: 60px;
      width: 60px;
    }

    .customize {
      // color: $brand-accent;
      font-size: 30px;
    }
  }
  .order-invoice-section{
    width: 100%;
    display: block;
  }
  .legal-tab-section {
    .toggle-icon {
      display: none;
    }

    .toggle-tab {
      width: 35%;
      &.pickcat-section{
        width: 100%;
      }
    }

    .pickcat {
      &.cat-section {
        min-height: 680px;
      }

      &.cat-size {
        min-height: 580px;
      }
      background-color: #f2f6f8;
      opacity: 1;

      .category {
        color: variables.$brand-accent;
        font-weight: bold;
        font-size: variables.$font-size-medium;
      }

      .notes-icon {
        position: relative;
        top: 4px;
      }

      .tablinks {
        background-color: variables.$white;
        border: 1px solid variables.$brand-primary;
        padding: 12px;

        .tab {
          font-size: variables.$font-size-small + 2;
        }

        &.active {
          background-color: variables.$brand-primary;

          .tab {
            color: variables.$white;
          }
        }
        // &.active{
        //   background-color: $brand-primary;
        //   .tab{
        //     color: $white;
        //   }
        // }
      }

      .active {
        @extend .tablinks;
        background-color: variables.$brand-primary;

        .tab {
          color: #f2f6f8;
        }
      }

      .tablink {
        @extend .tablinks;
        border: 1px solid variables.$brand-accent;
      }
    }
  }
  @media only screen and (max-width: 960px) {
    .legal-tab-section {
      section {
        min-height: 680px;
        &.service-sow{
          margin-left: 40px;
          .toggle-tab.open {
            width: 45%;
            top: 79px;
          }
        }
        .invoice {
          margin: -13px 30px;
        }

        .invoice-det {
          margin: -35px 30px;
        }
      }

      .toggle-icon {
        display: block;
        z-index: 4;
        position: absolute;
        top: 65px;
        left: -4px;

        &.icon {
          top: 250px;
          left: -3px;
        }

        &.toggle {
          top: 145px;
          left: -3px;
        }
        &.service-icon{
          top: 107px;
          @media (max-width:600px) {
           top: 145px;
            
          }
        }
      }

      .pickcat {
        z-index: 2;

        .category {
          margin: 13px 0 10px 30px;
        }
      }

      .toggle-tab {
        position: absolute;
        left: -100%;
        top: 37px;

        &.update-work {
          top: 150px;
        }
        box-shadow: 0 0 4px 4px #7b94a1;
        transition: 0.5s all linear;
        padding: 0;
        z-index: 3;
        // transition: .5s all linear;
        &.pickcat-section{
          left: -103%;
          top: auto;
          transition: 0.4s all linear;
        }
      }

      .toggle-tab.open {
        left: -8px;
        top: 45px;
        width: 40%;
        &.pickcat-section{
          left: 0px;
          top: auto;
          .pickcat.cat-size{
            padding: 0px 5px !important;
            .catalog-sidepanel-header {
              margin-left: 30px !important;
            }
         }
        }
        
      }

      .toggle-tab.update-work.open {
        left: -8px;
        top: 125px;
        width: 40%;
      }

      .static {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  @media only screen and (max-width: 750px) {
    .legal-tab-section {
      .toggle-tab.open {
        left: -8px;
        top: 45px;
        width: 60%;
      }

      .toggle-tab.update-work.open {
        left: -8px;
        top: 125px;
        width: 60%;
      }
    }
  }
  @media only screen and (max-width: 450px) {
    .order-set {
      padding: 19px;
    }

    .legal-tab-section {
      .toggle-tab.open {
        left: -10px;
        top: 45px;
        width: 90%;
      }

      .toggle-tab.update-work.open {
        left: -8px;
        top: 125px;
        width: 90%;
      }

      .static {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  @media only screen and (max-width: 390px) {
    .order-set {
      padding: 15px;
    }

    .static {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.service-detail-section, .products-landing{
  .toggle-tab {
    width: auto !important;
    margin: 0 10px 0 0;
    padding-top: 0px;
    &.pickcat-section{
      width: 100%;
    }
  }
}
@media only screen and (max-width: 900px) {
  .products-landing{
    .order-invoice{
      .legal-tab-section{
        position: relative;
        section.service-sow .toggle-tab.open {
          top: 47px!important;
          width: 45% !important;
          left: -20px;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .products-landing{
    .order-invoice{
      .legal-tab-section{
        position: relative;
        section.service-sow .toggle-tab.open {
          width: 75% !important;
        }
      }
    }
  }
}
