@use "../../settings/third-party/variables";

.new-drip {
  .initiate-hour md-input-container {
    margin-right: 15px;
  }

  .toggle-switch md-switch {
    margin-right: 15px;
  }

  .add-email-sidenav {
    background: variables.$white;

    .knowledge-drip {
      &:not(:last-of-type) {
        margin-bottom: 20px;
      }

      &,
      img {
        width: 100%;
      }
    }
  }

  .add-resource-header {
    md-switch {
      top: 3px;
      left: 25px;
    }
  }

  .add-designed-email {
    li {
      margin-right: 20px;
    }
  }

  .template-heading {
    padding: 2px 10px;
    border-bottom: 1px solid variables.$gray-light-media;

    md-icon {
      margin: 0 10px 0 0;
    }
  }

  .template-content {
    border: 1px solid variables.$gray-light-media;
  }

  .email-information {
    padding: 10px 20px;

    md-icon {
      margin-right: 10px;
    }
  }

  .campaign-email-content {
    color: variables.$white;

    > div {
      padding: 10px;
    }

    .first-section {
      background: #AA79DB;
    }

    .second-section {
      background: #3f51b5;
    }

    .third-section {
      background: #6fbd6a;
    }
  }

  .select-winner md-input-container {
    margin: 8px;
  }

  .initiate-hour md-input-container {
    width: 150px;
  }

  .icon-ico-add {
    margin-right: 5px;
  }

  .active-email-campaign,
  .define-equal {
    margin-right: 10px;
  }

  .vr-btns {
    md-radio-button {
      margin: 10px 0;
    }
  }
}

@media(max-width:600px) {
  .new-drip {
    .add-resource-header md-switch {
      left: 0;
    }

    .initiate-hour md-input-container {
      width: 100%;
    }
  }
}
