@use "../settings/third-party/variables";

.notification-card {
  background: variables.$color-blue;
  border-radius: 10px;
  color: variables.$white;

  &.error-warning {
    background: variables.$brand-primary;
  }

  .md-headline {
    font-size: 18px;
    font-weight: 500;
  }

  .condition-text {
    margin-top: 8px;
  }

  .app-links {
    @media screen and (max-width: 599px) {
      margin-bottom: 10px;
    }

    img {
      width: 120px;
    }

    a:not(:last-of-type) {
      margin-right: 11px;
    }
  }

  md-card-actions {
    margin: 0 16px 16px;

    .notification-cta .md-button {
      background: variables.$color-blue;
      color: variables.$white;
      border-radius: 15px;
      border: 1px solid variables.$white;
      min-height: 25px;
      line-height: 25px;
      min-width: 120px;

      &:not([disabled]):hover {
        color: variables.$color-blue;
      }

      &.error-warning {
        background: variables.$brand-primary;
      }

      &.nav-btn {
        min-width: 27px;

        &[disabled] {
          opacity: 0.5;
          transition: all linear 0.2s;
        }
      }
    }
  }
}

.cust-filter-btns {
  margin-bottom: 10px;

  .md-button {
    margin: 0 20px 20px 0;
  }
}

.disabled-input {
  position: relative;

  .click-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }
}

.resource-chips {
  md-chips md-chip {
    height: 24px;
    line-height: 24px;
    padding: 0 12px 0 0;
    border-radius: 16px !important;

    .avtar-img,
    .img-replacer,
    img {
      height: 24px;
      width: 24px;
      float: left;
      margin-right: 5px;
    }

    img {
      border-radius: 50%;
      vertical-align: middle;
    }

    span {
      float: left;
      font-size: variables.$font-size-small;
    }
  }

  md-chips .md-chips {
    box-shadow: 0 0 rgba(0, 0, 0, 0.12);
  }

  .img-replacer {
    line-height: 24px;
  }

  &.larger {
    md-chips md-chip {
      border-radius: 18px;
      line-height: 35px;
      height: 35px;

      .avtar-img,
      .img-replacer,
      img {
        height: 35px;
        width: 35px;
      }

      .img-replacer {
        line-height: 35px;
      }
    }

    &.view-only {
      md-chips md-chip {
        float: none;
      }
    }
  }
}

md-chips {
  &.custom-md-tags {
    md-chip {
      background-color: #596773;
      margin-bottom: 15px;
    }

    .md-chip-content {
      span {
        color: #fff;
        font-size: 14px;
      }
    }

    button.md-chip-remove md-icon {
      fill: variables.$white;
    }
  }

}

// Visuals used in right sidebars
.icon-grid {
  margin-top: 20px;

  .icon-container {
    height: 80px;
    width: 88px;
    position: relative;
    border: 2px solid transparent;

    &.selected {
      border-color: variables.$brand-primary;
    }

    md-icon {
      height: 40px;
      width: 40px;
    }

    .visual {
      transform: scale(.625); // 40px
    }
  }

  &.emoji-grid {
    margin: 10px;

    .emoji-symbol {
      font-size: 35px;
    }

    .icon-container {
      height: 48px;
      width: 48px;
    }
  }
}

.visual-sm-icon {
  height: 24px;
  width: 24px;
  vertical-align: middle;

  .visual {
    transform: translate(-50%, -50%) scale(0.375); // 24px
  }
}

// empty state starts here

.empty-state {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;

  .empty-action-icon {
    height: 250px;
    width: 250px;
  }

  .error-statement {
    color: variables.$brand-accent;
    font-size: variables.$font-size-medium;
    margin-bottom: 15px;
  }
}

.empty-state.static,
md-sidenav .empty-state,
md-tabs .empty-state {
  position: static;
  transform: none;
}

.empty-starter {
  max-width: 350px;
  margin: 0 auto;
  line-height: 1.5;
}

// section heading

.section-heading {
  md-icon {
    margin: 0;
    flex: none;
  }

  h1,
  h2 {
    margin: auto 10px;
  }

  .section-info {
    margin: 10px 0 0 34px;
  }
}

.info-icon {
  width: 15px;
  z-index: 1;
}

md-input-container.md-icon-right .md-select-icon {
  margin: 0 20px;
}

/* Tooltip Styling */

.tooltip {
  position: absolute;
  z-index: 1020;
  display: block;
  visibility: visible;
  padding: 5px;
  font-size: 11px;
  opacity: 0;

  &.in {
    opacity: 1;
  }

  &.top {
    margin-top: -2px;

    .tooltip-arrow {
      bottom: 0;
      left: 50%;
      margin-left: -5px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #000;
    }
  }

  &.right {
    margin-left: 2px;

    .tooltip-arrow {
      top: 50%;
      left: 0;
      margin-top: -5px;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-right: 5px solid #000;
    }
  }

  &.bottom {
    margin-top: 2px;

    .tooltip-arrow {
      top: 0;
      left: 50%;
      margin-left: -5px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #000;
    }
  }

  &.left {
    margin-left: -2px;

    .tooltip-arrow {
      top: 50%;
      right: 0;
      margin-top: -5px;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid #000;
    }
  }
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  background-color: #000;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
}

.progress-indicator {
  .quotes-header {
    padding: 15px 25px;
    background: black;
    color: white;
    font-size: 14px;
    font-weight: bold;

    md-icon.guide-small {
      margin-right: 5px;

      svg path {
        fill: white;
      }
    }
  }

  .progress-indicator-status {
    margin: 20px;
  }

  .stepprogress {
    padding-left: 45px;
    list-style: none;
  }

  .stepprogress-item {
    position: relative;
    border: 1px dashed variables.$brand-accent;
    padding: 0 15px;
    min-height: 56px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-left: 5px solid variables.$brand-accent;

    span {
      position: absolute;
      left: -40px;
      top: 15px;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      text-align: center;
      line-height: 25px;
      background: variables.$brand-accent;
      color: variables.$white;
    }
  }

  .progress-status {
    padding: 0 20px 0 10px;
  }

  .panel-container {
    border: 1px solid variables.$white-dark;

    &.fix-width {
      width: 320px;
      box-shadow: -1px -2px 8px 0 #dedede;
    }
  }

  .progress-header {
    padding: 5px 15px;
    background: variables.$btn-default-color;
    color: variables.$white;
  }

  .stepprogress-item:not(:last-child):before {
    content: "";
    border-left: 1px dashed variables.$brand-accent;
    height: 100%;
    position: absolute;
    left: -28px;
    top: 39px;
  }
}

.popover {
  background: variables.$brand-accent;
  border-radius: 2px;
  color: variables.$white;
  font-weight: 500;

  &.right > .arrow:after {
    border-right-color: variables.$brand-accent;
  }

  .learn-more-icon {
    height: 15px;
    width: 15px;
    margin: 0 5px 0 0;
  }
}

.signature-pad {
  border: 1px solid variables.$gray-light;
  border-radius: 10px;
  width: 100%;
  max-width: 660px;
}

.table-responsive .data-table.signature-table {
  min-width: auto;
  width: auto;
}

.sign-img {
  width: 100%;
  max-width: 100%;
  min-height: 191px;
  max-height: 191px;
  border: 1px solid variables.$gray-lighter;
}

.signature-table .signature-img {
  height: 200px;
  width: 400px;
  border: 1px solid variables.$gray-lighter;
}

.color-picker {
  .color-box {
    position: absolute;
    right: 0;
    top: 10px;
    box-shadow: #a2a1a1 1px 1px 5px;
  }

  .color-holder {
    width: 20px;
    height: 20px;
    opacity: 0;
  }
}

.disabled-cta {
  &,
  a,
  button,
  md-icon {
    color: grey !important;
    cursor: default !important;
  }

  svg path {
    fill: grey !important;
  }
}

.package-trigger {
  .bg-color {
    color: white;
    background-color: rgb(82, 117, 141);
    padding-bottom: 55px;
  }

  .upper-header {
    h1 {
      text-align: center;
      padding: 35px 0 0;
      font-size: 35px;
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 0;

      &.tag-line {
        font-size: 20px;
        margin-top: 0;
      }
    }

  }

  .pack-feature {
    padding: 0 40px;

    &.top {
      transform: translateY(-40px);

    }

    &.bottom {
      transform: translateY(40px);
    }

    &.fix-width {
      width: 100%;
      max-width: 800px;
      margin: 0 auto;
    }

    .container {
      background-color: rgb(72, 197, 195);
      padding: 10px 20px 20px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

      &:not(:last-child) {
        margin-right: 20px;

        @media screen and (max-width: 959px) {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }

      h3 {
        font-size: 20px;
        color: white;
        margin: 0;

      }

      md-icon {
        width: 35px;
        height: 35px;
      }
    }

  }

  .bottom-action {
    padding-bottom: 30px;

    .top-padding {
      padding-top: 40px;
    }

    .cancel-btn {
      background-color: white;
      color: black;
    }

    h1 {
      font-size: 30px;

      &.no-mar-top {
        margin-top: 0;
      }
    }

    p {
      margin-bottom: 40px;
      font-size: 16px;
    }

    .md-button {
      width: 155px;
      line-height: 50px;
      font-size: 16px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
  }
}

.rating-star {
  .star1 {
    svg path {
      fill: #ff4545;
    }
  }

  .star2 {
    svg path {
      fill: #ffa534;
    }
  }

  .star3 {
    svg path {
      fill: #ffe234;
    }
  }

  .star4 {
    svg path {
      fill: #b7dd29;
    }
  }

  .star5 {
    svg path {
      fill: #57e32c;
    }
  }
}

.color-palatte {
  &.pink {
    background-color: variables.$color-palatte-pink;
  }

  &.blue {
    background-color: variables.$color-palatte-blue;
  }

  &.red {
    background-color: variables.$color-palatte-red;
  }

  &.orange {
    background-color: variables.$color-palatte-orange;
  }

  &.sky-blue {
    background-color: variables.$color-palatte-sky-blue;
  }

  &.green {
    background-color: variables.$color-palatte-green;
  }

  &.light-green {
    background-color: variables.$color-palatte-light-green;
  }

  &.skin {
    background-color: variables.$color-palatte-skin;
  }

  &.default {
    background-color: variables.$gray;
  }

  &.no-color {
    background-color: transparent;
  }
}

.starter-section {
  background: #f2fffe;

  .starter-content {
    box-shadow: rgba(0, 0, 0, 0.15) 0 2px 2px -1px, rgba(0, 0, 0, 0.15) 0 2px 2px -1px;
    display: none;
    padding: 15px 50px;

    .header {
      // background-color: red;
      padding: 5px 10px 20px 0;
      border-bottom: 1px solid #a2a1a1;
      margin: 15px;

      .header-box {
        width: 200px;
        padding: 10px;
        background-color: #fff;
        box-shadow: -1px 1px 8px rgba(0, 0, 0, 0.3);
      }

      .margin-left-auto {
        margin-left: auto;
      }

      .margin-left-20 {
        margin-left: 20px;
      }
    }

    .main {
      .img-section {
        width: 45%;
        height: 300px;
        background-color: white;
      }

      .title {
        font-size: 30px;
        margin: 20px 0 0;
        letter-spacing: 1px;
      }

      .subtitle {
        font-size: 28px;
        margin: 0;
        letter-spacing: 1px;
      }

      .para-list {
        font-size: 16px;
        color: #a2a1a1;
        list-style-type: decimal;
        padding-left: 0;
      }

      .para-list li {
        margin-left: 40px;
      }

      .watch-video-btn {
        width: 170px;
        padding: 15px 8px;
        margin-top: 50px;
        color: white;
        background-color: #63d1dd;
        box-shadow: -1px 1px 8px rgba(0, 0, 0, 0.3);
      }
    }

    .footer {
      padding: 25px 0;
      border-top: 1px solid #a2a1a1;
    }

  }

  .toggle-icon {
    position: absolute;
    top: -1px;
    right: 28px;
    padding: 2px 6px;
    border: 1px solid #52748d;
    border-radius: 0 0 3px 3px;
    background: rgba(82, 116, 141, 0.3);

    md-icon {
      margin: 0;
      width: 15px;
      height: 15px;
      transform: rotate(-90deg);
      transition: 0.5s all;

      &.open {
        transform: rotate(90deg);
      }

      svg path {
        fill: #52748d;
      }
    }
  }
}

.cl-easy-access {
  background: #f2f6f7;
  padding: 30px;

  .cl-easy-cta {
    padding: 15px 10px;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #fe9603;
    color: #fe9603;
    cursor: pointer;
  }

  .clear-ctas {
    margin-top: 30px;

    .cl-cta:not(.cursor-point) md-icon {
      cursor: auto;

      path {
        fill: #949494;
      }
    }
  }
}

.cs-filter {
  padding: 8px 10px;
  width: 159px;
  white-space: nowrap;
  height: auto;
  border: 1px solid #a8b6c2;
  cursor: pointer;
  margin-bottom: 15px;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  position: relative;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  transition: 0.3s linear box-shadow;

  &.active,
  &:hover {
    border-color: #FFAB19;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2);
  }

  &.active {
    background-color: #e2f5f4;
  }

  .customer-icon {
    width: 40px;
    height: 40px;
    position: absolute;
    top: -10px;
    left: 15px;

    &.right-align {
      top: -12px;
      left: unset;
      right: -20px;
    }
  }

  h2 {
    margin: 0;
    line-height: 22px;
  }

}

md-sidenav md-input-container.sidenav-select {
  padding: 0 !important;
  margin: 0 !important;
  // width : 95%;
  width: 100%;

  label:not(.right) {
    display: none;
  }

  md-select {
    background: #fff;
    // color: rgb(204, 204, 204);

    md-select-value {
      span {
        display: inline-block;
        padding-left: 10px;
      }

      .md-select-icon {
        transform: translate(-9px);
      }
    }
  }
}

.emails-container {
  position: relative;
  background-color: #f2f6f7;
  padding: 30px 15px 40px;

  &.darker {
    background-color: rgba(211, 225, 229, 0.71);
  }

  img {
    height: 25px;
    width: 25px;
  }
}

.capsule-cta {
  border: 1px solid variables.$brand-primary;
  border-radius: 26px;
  padding: 2px 15px;
  cursor: pointer;
}

// for search toolbar backdrop
.search-backdrop {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, .48);
  display: none;
  z-index: 65;
}

md-autocomplete .md-show-clear-button button md-icon svg g path {
  fill: #FAB963;
}

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  position: relative;
  pointer-events: none;

  &.header {
    height: 40px;
    margin-bottom: 6px;
  }

  &.body {
    height: 100px;

    &:not(:last-of-type) {
      margin-bottom: 6px;
    }
  }
}

.panel-toggle-icon {
  position: absolute;
  left: 181px;
  top: 70px;

  &.opened {
    left: 194px;
  }

  @media screen and (max-width: 959px) {
    left: -19px;
  }

  &.md-primary.md-fab {
    height: 30px;
    width: 30px;
    min-height: 30px;
    transition-property: all;
    background-color: rgba(255, 255, 255, .6);

    &.opened {
      background-color: rgba(240, 94, 51, 1);
      transform: rotate(180deg);
    }
  }

  md-icon {
    height: 18px;
    width: 18px;
  }

  svg path {
    fill: #000;
  }
}

.quick-panel {
  position: relative;
  // right: 0;
  // bottom: 0;
  // top: 64px;
  z-index: 60;
  background: variables.$footer-bgcolor;
  width: 0;
  overflow: visible;
  transition: all 0.3s linear;
  border-left: 1px solid rgba(0, 0, 0, .12);
  // box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;

  &.opened {
    width: 62px;
  }

  .panel-toggle-icon {
    left: auto;
    top: auto;
    bottom: 11px;
    right: -19px;

    &.opened {
      right: 8px;
    }

    &.md-primary.md-fab {
      transform: rotate(180deg);

      &.opened {
        transform: rotate(0);
      }
    }
  }

  // md-divider {
  //   width: 30px;
  // }

  .quick-add {
    a {
      margin: 15px;
    }

    md-icon {
      height: 32px;
      width: 32px;

      &:hover {
        transform: scale(1.2);
        transition: all 0.2s linear;
      }
    }
  }
}
