@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.expense-section {
  letter-spacing: 0.24px;
  line-height: 18px;
  padding: 30px 20px 20px;

  .filters-bar {
    margin: 0 0 16px;
  }

  .expense-lbl {
    @extend .f4;
  }

  .f9 {
    color: variables.$gray-darker;
    font-size: variables.$font-size-small;
  }

  ul {
    li {
      margin-bottom: 25px;
    }

    md-input-container {
      margin-bottom: 0;
    }
  }

  .data-table {
    min-width: 780px;

    td {
      @extend .f3-light;
    }

    .date-text {
      color: variables.$gray-darker;
      font-size: variables.$font-size-large;
      font-weight: bold;
    }

    .option-icons {
      padding: 0;
      height: auto;
      width: auto;

      md-icon {
        height: 27px;
        width: 27px;

        &.active {
          svg path {
            &.inner-tick,
            &.outer-circle {
              fill: variables.$brand-success-dark;
            }
          }
        }
      }
    }
  }
}

.expense-add-section {
  .custom-tabs {
    md-content {
      padding-top: 18px;
    }
  }

  .datepicker-custom {
    margin-bottom: 20px;
  }

  .paid-title {
    color: variables.$gray-dark;
    font-weight: 500;
    margin-top: 14px;
    margin-bottom: 10px;
  }

  md-radio-group md-radio-button {
    font-size: variables.$font-size-small;
    margin-bottom: 14px;
  }

  .add-btn {
    margin-left: -8px;
    margin-right: 0;
  }

  .item-chipset {
    margin-top: 2px;

    .icon-close {
      margin-left: 8px;
    }
  }

  .add-expense-table {
    margin-top: 0;
    min-width: 780px;

    td {
      @extend .f3;
    }

    .edit-button {
      height: auto;
      width: auto;
      min-height: auto;

      md-icon {
        height: 14px;
        width: 14px;
      }
    }

    md-input-container {
      input,
      md-select {
        font-size: variables.$font-size-small;
      }
    }

    .input-xs {
      width: 75px;
    }

    .input-sm {
      width: 100px;
    }

    .input-md {
      width: 115px;
    }

    .option-icons {
      padding: 0;
      height: auto;
      width: auto;

      md-icon {
        height: 27px;
        width: 27px;

        &.active {
          svg path {
            &.inner-tick,
            &.outer-circle {
              fill: variables.$brand-success-dark;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 600px) {
  .expense-add-section {
    .gap-right-xs {
      margin-right: 12px;
    }
  }
}
@media screen and (max-width: 600px) {
  .expense-section {
    .expense-lbl {
      margin-bottom: 12px;
    }
  }
}

.sm-lbl {
  color: #949494;
  font-size: 10px;
}

.exp-mapping-active {
  color: variables.$brand-success-dark !important;
}

.mapping-customer {
  .img-container {
    margin-top: 5px;
  }
}

.padding-top-bottom {
  padding: 5px 0 !important;
}

.customer-table-list {
  ul {
    li:first-child {
      padding: 4px 20px 4px 0;
    }
  }
}

.gap-between-img-det {
  margin-left: 15px;
}

.mapping-with-label {
  max-width: 40px;
  width: 100%;
}

.gap-between-line {
  margin-bottom: 7px;
  font-size: 10px;
}

.map-with-select-width {
  width: 285px;
}

.label-settings {
  margin-top: 0;
  width: 285px;
  text-align: right;
  margin-bottom: 20px;
  margin-left: 20px;
  padding-right: 5px;
}

.label-size {
  font-size: 12px;
}

.gap {
  padding-left: 18px;
  padding-right: 18px;
}

.search-box-width {
  padding-right: 18px;
}

// .task-wrapper .search-list li {
//   cursor     : pointer;
//   padding-top: 0;
//   padding-top: 10px;
// }
