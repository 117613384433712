@use "../../settings/style-guide";

.tax-policy-section {
  padding: 30px 18px;

  .filters-bar {
    margin: 0 0 16px;
  }

  .small-txt {
    &.f3,
    &.f4 {
      font-size: 10px;
    }
  }

  .data-table {
    td {
      @extend .f3;
    }
  }

  .zone-list {
    @extend .no-style;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    li {
      @extend .clearfix;
    }
  }

  // .tab-switcher {
  // 	margin-bottom: 8px;

  // 	md-radio-button:first-child {
  // 		margin-right: 75px;
  // 	}
  // }

  // .input-container {
  // 	md-input-container {
  // 		margin-bottom: 0;
  // 	}
  // }

  // .input-description {
  // 	color: $gray;
  // 	font-size: 10px;
  // 	margin-top: 6px;
  // }

  // .tax-btn-section {
  // 	@extend .f3;

  // 	margin-bottom: 10px;

  // 	.add-btn {
  // 		margin-left: -6px;
  // 		margin-right: 10px;

  // 		md-icon svg path {
  // 			fill: $brand-primary;
  // 		}
  // 	}
  // }

  // .input-list-section li {
  // 	margin-bottom: 18px;
  // }

  // .summery-widget {
  // 	h1 {
  // 		margin-top: 0;
  // 		margin-bottom: 20px;
  // 	}

  // 	.input-list-section li:last-child {
  // 		margin-bottom: 0;
  // 	}
  // }

  // .data-table {
  // 	margin-top: 0;
  // 	min-width: 841px;

  // 	td {
  // 		@extend .f3;

  // 		&.code-cell {
  // 			@extend .fm;

  // 			background: $brand-table;
  // 			position: relative;
  // 			width: 95px;

  // 			&:after {
  // 				content: '';
  // 				display: inline-block;
  // 				position: absolute;
  // 				top: 0;
  // 				left: 0;
  // 				right: 0;
  // 				bottom: 0px;
  // 				border: 1px solid $white;
  // 			}
  // 		}
  // 	}
  // }
}

.tax-zone-section {
  padding-top: 18px;

  .custom-tabs {
    md-content {
      padding-top: 10px;
    }
  }

  .data-table {
    min-width: 810px;
  }

  .set-tax-list {
    margin-top: 2px;

    li {
      &:first-child {
        margin-bottom: 20px;
      }
    }

    md-checkbox {
      margin-bottom: 20px;
    }
  }
}

.tax-zone-section2 {
  .data-table {
    min-width: 780px;

    .descp-cell {
      width: 50%;
    }
  }

  .action-button {
    height: auto;
    min-height: auto;
    width: auto;

    md-icon {
      height: 14px;
      width: 14px;
    }
  }
}
