@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.communication-section {
  .last-update-msg {
    top: 14px;
    right: 8px;
  }

  md-input-container input[type="text"] {
    text-transform: none;
  }

  .switch-bar {
    label {
      padding-right: 16px;
    }
  }

  .custom-tabs {
    md-content {
      padding-top: 30px;
      padding-bottom: 10px;
    }
  }

  .check-icon-tick {
    height: 21px;
    margin-left: 8px;
    width: 21px;
  }

  .emailTestWidget {
    margin-top: 8px;
    margin-bottom: 15px;
    max-width: 280px;

    .title {
      color: #666666;
      font-size: variables.$font-size-base;
      margin-top: 0;
      margin-bottom: 21px;
    }

    .btn-primary {
      margin: 0;
    }
  }

  .bcc-mail-section {
    md-input-container {
      margin-bottom: 0;
      margin-left: 18px;
    }
  }

  .default-business-logo {
    min-height: 80px;
  }

  .email-composer-input {
    textarea {
      font-size: variables.$font-size-small;
      height: 220px !important;
    }
  }

  .composer-option {
    margin-top: -15px;

    md-checkbox {
      margin-bottom: 2px;
    }

    .delete-btn {
      margin-right: -8px;
    }
  }

  .calendar-section {
    margin: 20px 0 40px;

    .setup-calender {
      margin-left: 28px;
    }
  }

  .calendar-subsection {
    margin: 20px 0 25px 28px;
  }

  .image-tabs li {
    text-align: left;
  }

  .email-status md-icon {
    margin-right: 5px;
  }
}

.logo-edit-section {
  .titles {
    color: variables.$gray;
    margin-bottom: 10px;
  }

  .logo-holder {
    border: 1px solid variables.$border-color;
    height: 95px;
  }

  .ico-default {
    margin: -6px 22px 0 30px;

    &,
    &:after,
    &:before {
      background: variables.$brand-accent;
    }
  }

  h1 {
    margin: 0;
    font-size: 24px;
    font-weight: 300;
  }
}

.form-base md-input-container.gap-right {
  margin-right: 18px;
}

.emailTemplate {
  .image-tabs-wrapper {
    background: none;
    border: none;
    margin-bottom: 15px;

    .image-tabs {
      max-width: 866px;
      margin: 0 20px;

      .border-between {
        border: none;
      }

      &.border-between li {
        border-left: none;
        @extend .cursor-point;
        border: 1px solid #d6d7d8;
        padding: 10px;
        width: 100%;
        max-width: 165px;
        display: inline-block;
        transition: box-shadow 1s;

        &:not(:last-child) {
          margin-right: 15px;
          // margin-bottom: 15px;
        }
        transition: border-color 1s background-color 1s box-shadow 1s;

        &.active,
        &:hover {
          border-color: variables.$brand-primary;
          box-shadow: 0 1px 2px 2px rgba(30, 30, 30, 0.1);
        }

        &.active {
          background-color: variables.$brand-primary;
          color: white;

          &:after,
          &:before {
            border-color: variables.$brand-primary transparent transparent;
          }
        }

        .data {
          margin-bottom: 10px;
        }

        .lbl-gray {
          // @extend .f4;
          font-size: 14px;
        }

        .usage {
          color: variables.$gray;
        }

        .count {
          // @extend .f1;
          font-size: 20px;
        }
      }
    }
    /** Image tabs wrapper starts **/
    .left-arrow,
    .right-arrow {
      position: absolute;
      top: 50%;
      margin-top: -19px;

      svg {
        path {
          fill: variables.$brand-primary;
        }
      }
    }

    .left-arrow {
      right: 0;
    }

    .right-arrow {
      left: 0;
      transform: rotate(180deg);
    }
  }
}

ul.emailTemplate li {
  @extend .cursor-point;
  border: 1px solid #D6E1E5;
  padding: 10px;
  width: 100%;
  max-width: 165px;
  display: inline-block;

  &:not(:last-child) {
    margin-right: 15px;
    margin-bottom: 15px;
  }

  &.active {
    box-shadow: 0 1px 2px 2px rgba(30, 30, 30, 0.1);
  }

  .data {
    margin-bottom: 10px;
  }

  .lbl-gray {
    @extend .f4;
    font-size: 14px;
  }

  .usage {
    color: variables.$gray;
  }

  .count {
    @extend .f1;
    font-size: 20px;
  }
}
@media screen and (min-width: 600px) {
  .communication-section {
    .tabLeftSection {
      float: left;
      width: 55%;
    }

    .tabRightSection {
      float: right;

      .bottom-gap {
        margin-bottom: 28px;
      }
    }

    .target-widget {
      min-width: 220px;
    }
  }
}
@media screen and (min-width: 600px) {
  .communication-section {
    .composer-option {
      .delete-btn {
        margin-right: 0;
      }
    }
  }
}

.zoomhangout {
  .collapse {
    display: none;
  }

  .collapse.in {
    display: block;
  }

  .accordion-space .switch-bar {
    .md-block {
      margin-right: 25px;
    }

    .md-bar {
      background: #FAB963;
      color: variables.$white;
    }

    .md-thumb-container {
      margin-left: 2px;
    }

    .md-thumb {
      background: variables.$white;
    }

    .md-block .md-label {
      color: variables.$white;
      margin-left: 4px;
    }

    label {
      margin-right: 15px;
    }
  }
}
