@use "../../settings/third-party/variables";

.guided-action {
  .action-item {
    md-checkbox {
      // margin-top: 15px;
      margin-right: 10px;
    }
  }

  .section-head {
    margin-top: 35px;
  }

  .display-value {
    padding-right: 15px;
  }

  .assign-section {
    md-chip {
      height: 20px;
      border-radius: 50px !important;
      padding-left: 0;
      line-height: 20px;
      box-shadow: none;
    }

    md-chips {
      md-chips-wrap {
        box-shadow: none;
      }
    }

    .view-chips {
      md-chips-wrap {
        padding: 0;
      }
    }

    .img-replacer {
      margin: 0;
      height: 20px;
      width: 20px;
      line-height: 20px;
      padding: 0;
      font-size: 11px;
      color: variables.$brand-primary;
      vertical-align: top;
    }
  }

  md-list-item label {
    color: #949494;
  }

  .view-chips {
    padding: 0;
  }

  .opp-detail-list {
    md-list-item:before {
      min-height: 35px;
    }

    md-list-item {
      padding-left: 0;
    }
  }

  .due-date {
    md-radio-button {
      margin-bottom: 0;
    }
  }
}

@media only screen and (max-width: 600px) {
  .guided-action {
    .opp-detail-list {
      md-list-item:before {
        min-height: 25px;
      }
    }
  }
}

@media only screen and (max-width: 959px) {
  .guided-action {
    .due-date {
      .date-row {
        max-width: 400px;
        margin-bottom: 25px;
      }

      .gap-right {
        margin-right: 0;
      }

      .determinant,
      .display-value {
        margin-bottom: 20px;
      }
    }
  }
}
