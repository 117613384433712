@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.estimate-section {
  h2 {
    padding: 0;
    font-weight: 500;
    margin: 0 0 0 7px;
    @extend .f1;
  }

  .estimate-title {
    margin: 0 20px;
    padding: 25px 0 10px;

    .icon-text {
      color: variables.$gray;
      display: inline-block;
      @extend .f3;
    }

    .icon-setting {
      svg {
        width: 20px;
        height: 20px;

        .st0 {
          fill: variables.$brand-primary;
        }
      }
    }
  }

  .default-box {
    padding: 0 19px;
    margin: 0 20px;
    border-radius: 5px;

    p {
      color: variables.$gray;
      font-size: 10px;
    }

    .estimate-listing {
      li {
        padding: 10px 0;
        border-bottom: 1px solid variables.$gray-lighter;
        line-height: 20px;
        @extend .f3;

        .f9 {
          font-size: 10px;
        }

        p {
          margin: 0;
          line-height: 1.2;
        }

        &.list-section {
          padding: 15px 0 10px 45px;

          &.list-section-resource {
            padding: 15px 0 10px 42px;
          }

          &.temp-list-section {
            padding: 15px 0 10px 50px;
          }

          h2 {
            margin-left: 0;
          }

          // span{
          //   font-size: 14px;
          // }
        }
      }

      label {
        line-height: 1.5;
      }

      &.total-area {
        padding: 10px 0 0;

        li {
          border-bottom: none;
          padding: 0 0 10px;
        }

        &.total-area-dark {
          background-color: #000;
          border-left: 7px solid #63d1dd;
          padding: 10px 19px 0 57px;

          span,
          strong {
            color: #ffffff;
          }

          label {
            color: #b7b7b7;
          }

          &.total-area-dark-resource {
            padding: 10px 19px 0 53px;
          }

          &.temp-total-area-dark {
            padding: 10px 19px 0 63px;
          }
        }
      }

      .r-total {
        font-weight: bold;
        font-size: variables.$font-size-small-plus;

        span {
          font-weight: 500;
        }
      }
    }
  }

  &.scope-work {
    margin-bottom: 35px;
  }

  .sidebar-toggle-btn {
    margin: 20px 40px 0;
    @extend .text-right;

    a {
      display: inline-block;
    }
  }

  .estimate-sidebar {
    .sidebar-title {
      // padding: 30px 16px 5px 19px;
      margin: 13px;

      h3.md-subhead {
        @extend .f1;
        padding: 0;
      }

      .menu-icon {
        svg {
          path {
            // fill: $brand-primary;
            fill: variables.$brand-primary;
          }
        }
      }
    }

    md-list {
      padding: 0;

      md-list-item {
        min-height: auto;
        // padding: 11px 19px;
        color: variables.$gray-darker;

        &.f1 {
          padding-top: 10px;
        }
      }
    }

    md-divider {
      margin: 10px 0;
    }
  }

  .md-sidenav-right {
    width: 310px;
    min-width: 0;
    max-width: 310px;
  }

  .estimate-detail-content {
    background-color: #f2f6f9;
  }

  &.edit-page {
    .md-button.md-icon-button {
      margin: 0;
      height: 40px;
      min-width: 30px;
      line-height: 24px;
      padding: 0;
    }

    &.custom-edit-page {
      .custom-edit-content {
        background-color: #f2f6f9;
      }

      .view-existing-link {
        position: relative;
        top: 18px;
      }

      .md-button.md-icon-button {
        min-width: 80px;
      }

      .page-edit-options .svg-base {
        margin-right: 10px;
      }
    }
  }
}

.total-box {
  padding: 5px 0;

  &.custom-total-box {
    padding: 0;
  }
}

.simple-estimate {
  padding: 20px;

  .add-more {
    margin-bottom: 20px;
    color: orange;

    md-icon {
      margin-right: 10px;

      svg path {
        fill: variables.$brand-primary;
      }
    }
  }

  .des-enable {
    margin-bottom: 20px;

    md-switch.md-block {
      margin-bottom: 0;
      margin-left: 10px;
    }
  }
}

.customize-time {
  margin: 20px;

  .custom {
    margin-bottom: 25px;

    .right-g {
      margin-right: 15px;
    }

    md-checkbox {
      margin-bottom: 0;
    }

    md-select {
      margin: 0;
    }
  }

}

.estimate-popup {
  .error-text {
    color: maroon;
  }

  &.scope-work {
    .switch-label {
      margin-left: 20px;
    }

    textarea {
      max-height: 100px;
      height: auto;
      overflow: auto;
    }
  }

  &.dialog-popover {
    .simple-estimate {
      md-switch {
        top: -5px;
      }

      #cat-info,
      #tot-price-tax {
        margin-top: 10px;

        a:hover {
          cursor: default;
        }
      }
    }
  }

  .txtarea-label {
    margin-top: 12px;
  }
}

.estimate-detail-top-heading {
  background-color: #e8f0f3;
  padding: 40px 40px 20px;

  &.estimate-service-section {
    background-color: transparent;
    border-radius: 0;
    //margin: 18px;
    .poly-icon {
      margin: 0;

      svg path {
        fill: #63d1ddff;
      }
    }
  }

  .manage-service-section {
    max-width: 160px;
    width: 100%;
  }

  .heading-tag {
    font-size: 10px;
    color: #a8b6c2;
    letter-spacing: 1px;
  }

  .capsule-btn {
    background-color: #ffffff;
    padding: 7px 10px;
    border: 1px solid #63d1dd;
    color: #20394c;
    border-radius: 50px;
    max-width: 155px;
    text-align: center;
  }
}

.estimate-detail-service {
  .btn-secondary {
    min-width: 175px;
    border: 1px solid variables.$brand-primary;
    color: variables.$brand-primary;
    background-color: #ffffff;
    padding: 5px;
    float: right;

    .service-icon {
      margin: 0 10px 0 0;
    }

    .inner-tag {
      font-size: 14px;
      margin-top: 3px;
    }
  }

  .btn-secondary:hover {
    background-color: variables.$brand-primary;
    color: #ffffff;

    .service-icon svg path {
      fill: #ffffff;
    }
  }

  .default-box {
    padding: 0;
    border: none;
    margin-bottom: 40px;
  }

  .estimate-title-head {
    padding-bottom: 65px;

    .capsule-pill {
      border: 1px solid #d9d9d9;
      background-color: #fff;
      border-radius: 20px;
      padding: 8px 30px;
      font-weight: 600;
      margin-top: 20px;
    }
  }

  .icon-section {
    position: absolute;

    &.custom-icon-section {
      max-width: 460px;
      width: 100%;

      h2 {
        position: relative;
        top: 12px;
      }
    }

    .service-icon {
      width: 65px;
      height: 80px;
      position: relative;
      top: 20px;
      margin-right: 8px;
      margin-left: 8px;

      &.temp-service-icon {
        width: 65px;
        height: 80px;
      }
    }
  }

  .estimate-listing {
    padding: 0 19px;

  }
}
@media only screen and (max-width: 1200px) {
  .estimate-section.custom-edit-page {
    .md-button.md-icon-button {
      min-width: 50px!important;

      span {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .estimate-section.custom-edit-page {
    .estimate-detail-service {
      .estimate-title.scopeofwork-estimate-title {
        .icon-section.custom-icon-section {
          position: relative;
        }
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .estimate-section.custom-edit-page {
    .estimate-detail-service {
      .estimate-title {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
      }

      .icon-section.custom-icon-section {
        position: relative;

        .service-icon {
          width: 60px;
        }
        // .service-icon-resource{
        //   width: 50px;
        // }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .estimate-section {
    md-sidenav {
      width: 75%;
      min-width: 60%;
    }

    .list-section,
    .list-section-resource,
    .temp-list-section {
      padding-left: 0 !important;
    }

    .temp-total-area-dark,
    .total-area-dark,
    .total-area-dark-resource {
      padding-left: 12px !important;
    }
  }
}

@media only screen and (max-width: 465px) {
  .estimate-section {
    .estimate-detail-service {
      .estimate-title {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 15px;
      }

      .icon-section.custom-icon-section {
        position: relative;
      }
    }
  }
}

@media only screen and (max-width: 410px) {
  .estimate-section.custom-edit-page {
    .estimate-detail-service {
      .icon-section.custom-icon-section {
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
  }
}

.description-section {
  transform: rotate(90deg);
  width: 16px;
  height: 16px;
}

.description-section.opened {
  transform: rotate(-90deg);
}
