@use "../../settings/third-party/variables";
@use "../../settings/style-guide";
/*site visit edit css start here*/

.service-edit {
  md-input-container.md-input-focused:not(.md-input-invalid) md-icon {
    color: variables.$brand-danger;
  }

  md-list-item {
    display: block;
    margin: 0 1px;

    h3 {
      font-weight: normal;
    }

    p {
      color: variables.$gray;
      line-height: 20px;
    }

    a {
      display: block;
      margin: 10px 0;

      svg {
        width: 14px;

        .st0 {
          fill: variables.$brand-primary;
        }
      }
    }
  }

  .service-note {
    margin: 10px 8px 0 13px;
  }

  md-divider {
    margin: 0 18px;
  }

  .icon-date-time {
    height: 40px;
    width: 40px;
  }

  .edit-service-icon {
    height: 40px;
    width: 40px;
    border: 4px solid variables.$brand-accent;
    border-radius: 50%;
  }

  .address-select {
    padding: 0;
    position: relative;
    top: 0;
    left: 0;

    .icon-down {
      transform: rotate(-90deg);
      width: 10px;
      height: 10px;
      margin-left: 5px;

      svg {
        path {
          fill: variables.$gray;
        }
      }
    }
  }

  .v-address {
    right: -110px;
  }

  .property-detail {
    margin: 18px 0;
  }

  .form-base {
    .enter-service {
      color: variables.$brand-accent;
    }

    h3 {
      @extend .f2;

      font-weight: normal;
      min-width: 140px;
      margin: 0 0 0 15px;

    }

    h4 {
      @extend .f3;

      font-weight: normal;
      margin: 0 0 20px;
    }

    .address-type {
      margin-bottom: 10px;

      h4 {
        padding-right: 20px;
        margin: 0;
      }
    }
  }

  .address-details {
    // margin: 0 10px 0 19px;
    background-color: #f2f6f8;
    padding: 8px;

    .address-card {
      background-color: variables.$white;
      min-width: 160px;
      text-align: left;
      line-height: 1.4;
      font-size: variables.$font-size-base;

      &:hover {
        a {
          color: variables.$white;
        }

        background-color: variables.$brand-primary;
      }
    }

    .address-button md-icon {
      position: absolute;
      right: -4px;
      top: -6px;
    }

    .steps {
      background-color: variables.$white;
      padding: 2px 4px;
      text-align: center;
      line-height: 1.9;
      min-width: 100px;
      border-radius: 20px;
    }

    .address-heading {
      font-size: variables.$font-size-large;
    }

    .origin-address {
      background-color: variables.$white;
      max-width: 1200px;
      padding: 2px 12px;
    }

    .address {
      .insert-address {
        margin-bottom: 48px;
      }
    }

    .circle {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: variables.$brand-accent;
    }

    .contact-chip {
      @extend .steps;
      border-radius: 20px;
    }
  }

  .service-addr {
    background-color: #e9f0f3;
  }

  @media screen and (max-width: 768px) {
    .align-address,
    .customer-heading,
    .origin-addr {
      margin-left: 6px;
      margin-right: 6px;
    }

    .origin-address {
      margin-left: 6px;
      margin-right: 6px;
    }
  }

  @media screen and (max-width: 560px) {
    .get-address {
      align-items: flex-start;
    }

    .service-edit .address-details .address .address-header .insert-address {
      margin-bottom: 15px;
    }
  }
}
