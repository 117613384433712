@use "../../settings/third-party/variables";

.rel-purch-order {
  .section-header {
    font-size: variables.$font-size-h4;
    margin: 5px 0;
  }

  .active {
    font-weight: 600;
  }



    .stat-panel {
      // background-color: $gray-lighter;

      .stat-desc {
        padding: 5px 40px;
        // border-right: 1px solid $gray;
        margin: 20px 0;
        text-align: center;

      }
    }
  }

@media only screen and (min-width: 1280px) {
  .rel-purch-order .stat-box:first-child {
    margin-right: 12px;
  }
}
