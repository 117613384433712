@use '../customer-details/_popups';
@use "../../settings/third-party/variables";

.opportunity-popup {
  height: 540px;
  width: 800px;

  &.board-categories {
    md-dialog-content {
      padding: 20px;
    }

    .category-img {
      height: 40px;
      width: 40px;

      .visual {
        transform: translate(-50%, -50%) scale(.625);
      }
    }

    .data-table {
      border: none;
      margin-bottom: 20px;
      padding-top: 0;

      thead {
        background: transparent;

        th {
          font-weight: bold;
          border-bottom: 1px solid variables.$brand-table;
        }
      }

      md-input-container {
        margin-bottom: 0;
      }
    }

    .add-btn {
      margin: 0;
    }
    // .note {
    //   margin-top: 30px;
    // }
  }

  &.priority-setup {
    md-dialog-content {
      padding: 20px;
    }

    .priority-icon {
      margin: 8px 10px 0 0;
    }

    md-icon {
      &.badge-icon {
        height: 30px;
        width: 30px;
        margin: 5px 3px 0 0;
        // position: relative;
        // left: -5px;
      }

      &.remove-icon {
        height: 20px;
        width: 20px;
        margin: 12px 0 0 10px;
      }
    }

    .add-priority {
      // margin-bottom: 20px;
      md-icon {
        margin-right: 5px;
      }
    }

    .productivity h2.main-header {
      margin-bottom: 30px;
    }

    .measurement h2 {
      margin-top: 25px;
    }
  }
}

.confirm-dialog.card-priority {
  width: 300px;

  .dialog-content {
    padding: 20px;
  }

  .question {
    margin-bottom: 25px;
  }

  .field-row {
    margin-bottom: 15px;

    &.priority {
      margin-bottom: 25px;
    }
  }

  .field-label {
    padding-right: 15px;
  }

  md-icon.rating-star {
    height: 16px;
    width: 16px;
  }
}
// Bulk action popup

.bulk-update-opportunity {
  padding: 20px;

  .field-row {
    margin: 15px 0;

    md-checkbox {
      margin-bottom: 0;
    }
  }

  .add-contact {
    margin: 20px 0;

    md-icon {
      margin-right: 10px;
    }
  }

  .rating span {
    vertical-align: middle;
    margin-left: 8px;
  }
}

.bulk-update {
  padding: 40px 20px;

  h3 {
    margin-bottom: 20px !important;
  }

  ul li {
    margin-bottom: 10px;
  }
}

.create-bulk-item {
  .bulk-add-resource {
    margin: 20px 0;
  }

  .bulk-add-resource md-icon {
    margin-right: 15px;
  }
}

.post-conversion {
  height: 500px;

  .type-of-conversations {
    margin: 0;
  }
}

.opportunity-bulk-action {
  width: 800px;

  md-chip {
    border-radius: 50px !important;
  }
}
// End  Bulk action popup

// Add To Workboard popup

.workboard-inner-content {
  md-icon {
    margin: 10px !important;
  }

  md-input-container {
    margin: 10px 0 20px 0 !important;
  }

  .inner-content {
    margin: 10px 10px 10px 20px;
  }

  .text-content {
    margin: 20px 0 0 45px;
  }

  .tracking-popups {
    width: 1030px !important;
  }
}

//End Add To Workboard popup

// Clone Workboard popup

.clone-workboard {
  md-icon {
    margin: 10px !important;
  }

  md-input-container {
    margin-left: 40px;
  }
}

//End Clone Workboard popup

// Completing Workboard popup

.completing-workboard {
  .tracking-popups {
    width: 1170px !important;
  }

  .f2-small {
    margin-top: 20px;
  }

  md-input-container {
    margin-top: 0 !important;
  }

  .workboard-text {
    font-size: 14px;
  }
}
