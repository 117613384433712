@use "../settings/third-party/variables";

/* SideNav Start */
.md-sidenav-left {
  background: variables.$brand-accent-darker;

  /* extend custom scroll via duplicate code*/
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 5px;
  }

  &::-webkit-scrollbar:horizontal {
    width: 6px;
  }

  &::-webkit-scrollbar-track:horizontal {
    background: rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb:horizontal {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 5px;
  }

  &.md-locked-open {
    // transition: all ease-in-out 1s;
    width: 200px;
    min-width: 200px;
  }

  img.logo {
    padding: 10px 12px 9px;
    border-bottom: 1px solid variables.$brand-accent-sidenav;
  }

  .side-menu-list {
    > li {
      // &:not(:last-of-type) {
      border-bottom: solid 1px variables.$brand-accent-sidenav;
      // }
    }

    .sidebar-add-service {
      margin-bottom: 10px;
      margin-left: 7px;
    }

    li {
      cursor: pointer;
      color: variables.$white;
      font-size: variables.$font-size-small;
      font-weight: 500;
      position: relative;

      .title {
        padding: 10px 12px;
      }

      a {
        color: variables.$gray-dark;
      }

      span {
        cursor: pointer;
      }

      .sublist-option {
        font-size: variables.$font-size-small;
        color: #527b9a;

        md-icon {
          width: 15px;
          height: 15px;
          // margin-right: 4px;
        }
      }

      &.active,
      &:hover {
        color: variables.$brand-accent;
        position: relative;

        md-icon {
          svg {
            path {
              fill: variables.$brand-accent;
            }
          }
        }
      }

      .menu-icon {
        width: 15px;
        height: 15px;
        vertical-align: top;
        margin-right: 5px;

        svg {
          path {
            fill: variables.$white;
          }
        }
      }

      ul {
        li {
          text-indent: 25px;

          &.selected {
            background: variables.$white;
            color: variables.$brand-accent;
            position: relative;
            // border-right: $brand-accent solid 4px;
          }

          span {
            cursor: pointer;
          }
        }
      }
    }
  }

  .quick-add {
    height: 66px;
    flex: none;
    border-top: 1px solid variables.$brand-accent-sidenav;

    md-icon {
      height: 32px;
      width: 32px;
      margin: 0 7px;
    }
  }

  .quick-cta {
    margin: 7px;
    color: variables.$white;
    cursor: pointer;

    .notify-badge {
      background: variables.$brand-primary;
      color: variables.$gray-base;
      top: -4px;
      right: 3px;
    }
  }

  &.sub-panel {
    background: #fff;
    width: 300px;
    max-width: 300px;
    z-index: 1;
    border-right: 1px solid rgba(0,0,0,0.12);

    .clear-text {
      font-size: 10px;
      text-wrap: nowrap;
    }

    .panel-header {
      min-height: 54px;
      background: variables.$navbar-default-bg;
      padding: 0 5px;
      border-bottom: 1px solid rgba(0, 0, 0, .12);
    }

    .panel-fc-btns {
      transition: all linear 0.3s;
    }

    @media screen and (max-width:1279px) {
      z-index: 80;
    }
  }
}

@media screen and (max-width: 959px) {
  .md-sidenav-left {
    z-index: 80;
  }
}

@media screen and (min-width: 600px) {
  .md-sidenav-left {
    max-width: 245px;
  }
}
/*SideNav End*/

/*Right Navbar Header and Footer */

.right-nav {
  max-width: 400px;
  width: 100%;
  box-shadow: -2px 0 10px rgb(32 31 31 / 30%);

  &.custom-quick-navigation {
    .sidebar-sub-header {
      background: #a8b6c2;
    }
  }

  .sidebar-header,
  .sidebar-sub-header {
    color: variables.$white;
    background-color: variables.$gray-base;

    h3 {
      font-size: variables.$font-size-large;
    }

    md-icon.fill-white {
      svg {
        path {
          fill: variables.$white;
        }
      }
    }
  }

  .sidebar-header {
    padding: 16px;

    .md-icon {
      height: 30px;
      width: 30px;
    }
  }

  .sidebar-sub-header {
    padding: 8px 16px;

    .service-icon {
      height: 40px;
      width: 40px;
    }
  }

  .sidebar-footer {
    background-color: variables.$white-darker;
    padding: 5px 0;

    .btn-default {
      border: 1px solid variables.$brand-primary;
      color: variables.$brand-primary;
    }
  }
}
