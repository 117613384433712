@use "../../settings/third-party/variables";

.guided-action-grid {
  .active {
    color: variables.$brand-success-dark;
    font-weight: 600;
    font-size: 14px;
  }

  .boards {
    .edit-action {
      width: 135px;
      min-width: 135px;
    }

    .icon-coupon {
      height: 34px;
      margin-bottom: 15px;
      width: 34px;
    }

    .stat-info {
      .stat {
        font-size: 18px;
        padding: 5px;
      }
    }
  }
}

// .stat-info {
//   .stat {
//     color: $brand-primary;
//   }
// }

.gap-right-hard {
  margin-right: 10px;
}
