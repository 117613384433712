@use "../third-party/variables";

.status {
  font-weight: bold;

  &.accepted,
  &.active,
  &.assigned,
  &.authorized,
  &.available,
  &.bundled,
  &.completed,
  &.contracted,
  &.fully,
  &.fully-paid,
  &.fully.paid,
  &.fully.published,
  &.fully.received,
  &.fully.return,
  &.good,
  &.paid,
  &.re-submitted,
  &.resumed,
  &.returned,
  &.running,
  &.scheduled,
  &.started,
  &.submitted,
  &.success,
  &.won {
    color: variables.$status-green !important;
  }

  &.canceled,
  &.cancelled,
  &.closed,
  &.disabled,
  &.finished,
  &.inactive,
  &.no-roles,
  &.void,
  &.voided {
    color: variables.$status-gray !important;
  }

  &.archived,
  &.buying,
  &.negotiation,
  &.new,
  &.on_hold,
  &.partial,
  &.partially-paid,
  &.partially.completed,
  &.partially.fulfilled,
  &.partially.paid,
  &.partially.return,
  &.paused,
  &.prospect,
  &.reopen,
  &.stopped,
  &.tentative,
  &.undetermined {
    color: variables.$status-yellow !important;
  }

  &.mixed_status {
    color: #464646 !important;
  }

  &.not.assigned {
    color: variables.$gray-base !important;
  }

  &.bounced,
  &.committed,
  &.domain_blocked,
  &.email_blocked,
  &.expired,
  &.lost,
  &.not-running,
  &.over-assigned,
  &.over.due,
  &.overbooked,
  &.overdue,
  &.pending,
  &.refurbished,
  &.rejected,
  &.returned,
  &.unpaid {
    color: variables.$status-red !important;
  }

  &.auto.submitted,
  &.fulfilled,
  &.modified,
  &.partial-complete,
  &.rescheduled,
  &.resubmitted,
  &.warrantied {
    color: variables.$status-yellow !important;
  }

  &.partially.received {
    color: variables.$status-info !important;
  }

  &.buyer,
  &.draft,
  &.incomplete,
  &.internal {
    color: variables.$status-mehrun !important;
  }

  &.unassembled {
    color: variables.$color-palatte-blue !important;
  }
}

.email-throttle {
  padding: 8px;
  font-size: 12px;
  margin-bottom: 10px;

  &.success {
    background-color: #B6D7A8;
  }

  &.warning {
    background-color: #F9CB9C;
  }

  &.danger {
    background-color: #EA9999;
  }

  &.hard-danger {
    background-color: #FF0000;
    color: white;
  }
}

.status-capsule {
  display: inline-block;
  text-align: center;
  // min-width: 120px !important;
  color: white !important;
  max-width: none !important;
  padding: 10px;
  border-radius: 25px;
  font-size: 12px;
  font-weight: bold;

  &,
  &.fully,
  &.success {
    background: variables.$status-green;
  }

  &.partial,
  &.paused,
  &.stopped,
  &.warning {
    background: variables.$status-yellow;
  }

  &.danger,
  &.expired,
  &.not-running,
  &.pending {
    background: variables.$status-red;
  }

  &.finished {
    background: variables.$status-gray;
  }

}

.status-bg {
  &.active,
  &.success {
    background: variables.$status-green;
  }

  &.archived {
    background: variables.$status-yellow;
  }

  &.danger,
  &.expired,
  &.pending {
    background: variables.$status-red;
  }

  //added
  &.disabled,
  &.inactive {
    background: variables.$status-gray;
  }

  &.draft,
  &.incomplete {
    background: variables.$status-mehrun;
  }
}
