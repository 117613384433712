@use "../settings/third-party/variables";

.business-highlights {
  padding: 20px;

  .tab-btn {
    padding: 5px 20px;
    font-weight: 500;
    font-size: 14px;
    border: 2px solid lightgray;

    &.active {
      border-color: #f0cb99;
      background: #ffe8c6;
      color: #bb5f06;
    }

    &:first-of-type {
      border-radius: 5px 0 0 5px;
      border-right-width: 0;
    }

    &:last-of-type {
      border-radius: 0 5px 5px 0;
    }
  }

  .pie-chart {
    width: 290px;
  }

  .top-space {
    margin-top: 40px;
  }

  .right-space {
    margin-right: 30px;
  }

  .space-betn {
    margin-right: 10px;
  }

  .chart-stats {
    color: variables.$gray-base;
    font-size: variables.$font-size-base;

    .stat-row {
      margin-bottom: 15px;

      &.total {
        margin-top: 25px;
      }
    }

    .key-text {
      min-width: 100px;
    }
  }

  .card-section {
    margin-top: 20px;

    &:not(.highlight-lane) {
      @media screen and (min-width: 600px) {
        padding-right: 20px;
      }

      @media screen and (min-width: 960px) {
        &:nth-child(3n) {
          padding: 0;
        }
      }

      @media screen and (max-width: 960px) {
        &:nth-child(even) {
          padding: 0;
        }
      }
    }

    &.recent-customers {
      padding: 0;
    }

    .header-bg {
      background: #5f8f5c;
      padding: 6px;
      color: variables.$white;

      &.quotes {
        background: #7f6b8d;
      }

      &.jobs {
        background: #ac4744;
      }

      &.teams {
        background: #64a0bb;
      }

      .icon-color {
        color: variables.$white;
      }
    }

    .subheading-section {
      padding: 10px;
      background: #6fbd6a;
      color: variables.$white;

      h1 {
        margin-bottom: 0;
      }

      &.quotes {
        background: #a282b9;
      }

      &.jobs {
        background: #ec4843;
      }

      &.teams {
        background: rgb(101, 198, 241);
      }
    }

    .recent-section {
      background: #52758d;
      padding: 8px;

      .recent-text {
        color: variables.$white;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .profile-section {
      padding: 10px;
      border: 1px solid lightgray;
      border-top: none;
      border-top-width: 0;
      word-break: break-all;

      &:hover {
        background: #f3f3f3;
      }

      .img-container {
        margin-right: 10px;
      }

      .space-bottom {
        margin-bottom: 6px;
      }

      .customer-info {
        margin-right: 15px;
      }
    }
  }

  .funnel-img {
    width: 200px;
    text-align: right;
    top: -3px;
    margin-right: 10px;

    // .stat {
    //   color: $white;
    //   font-weight: 500;
    //   position: absolute;
    //   left: 37%;
    //   top: 13px;
    //   transform: translateX(-50%);
    // }
  }

  table {
    tr {
      pointer-events: none;
    }

    td {
      padding: 4px 15px;
    }

    .opportunities {
      color: rgb(107, 191, 102);

      td {
        padding-top: 12px;
      }
    }

    .quotes {
      color: rgb(157, 123, 183);
    }

    .jobs {
      color: rgb(239, 65, 55);
    }

    .retension {
      color: rgb(255, 153, 0);
    }
  }
}
