@use "../../settings/third-party/mixins";
@use "../../settings/third-party/variables";

.business-section {
  .account-msg {
    @include mixins.border-radius(3px);
    color: variables.$gray-darker;
    background: variables.$white-dark;
    font-size: 10px;
    line-height: 10px;
    margin-bottom: 23px;
    padding: 10px;
  }

  .account-status {
    color: variables.$gray-darker;
    font-size: variables.$font-size-small;
    margin-bottom: 20px;

    @media screen and (min-width: 600px) {
      padding-top: 7px;
    }

    md-icon {
      height: 21px;
      width: 21px;
      margin-right: 10px;
    }
  }

  md-checkbox.info-cb {
    .md-label {
      color: variables.$gray-darker;
    }
  }
}

.add-gateway {
  .business-list-header {
    margin-top: 25px;
  }

  .sync-bottom-spaciing {
    margin-bottom: 20px;
  }

  .sync-left-info {
    margin-right: 15px;
  }

  .accounting-header.top-heading {
    margin: 50px 0 20px;

    h2 {
      margin: 0;
      line-height: 25px;
    }
  }

  .add-email {
    md-icon {
      margin: 10px;
    }

    span {
      vertical-align: sub;
    }
  }

  .info-widget {
    .title {
      margin-top: 4px;
    }
  }

  .sync-inform {
    margin-bottom: 5px;

    md-icon {
      width: 16px;
      height: 16px;
      // transform: scale(1.4);
    }
  }

  .enable-sync {
    margin-right: 30px;
  }

  .accounting-header {
    margin: 40px 0 20px;
  }

  .icon-spacing {
    md-icon {
      margin-right: 4px;
    }
  }

  .margin-seperator {
    margin: 20px 0;
  }

  .business-location-list {
    padding-left: 0;

    li {
      width: 300px;
      margin: 0 20px 20px 0;
      float: left;
      list-style: none;
    }

    .select-status {
      border: 1px solid variables.$gray-light-media;
      border-left: none;
      border-right: none;
      // margin-top: 20px;
      // text-align: center;
      background-color: #eaf0f6;
      padding: 15px 20px;

      .left-margin {
        margin-left: 10px;
      }
    }
  }

  .entity-holder {
    border: 1px solid variables.$gray-light-media;
    // padding: 20px;
    border-bottom: none;

    .inactive {
      opacity: 0.7;
      .location-symbol {
        background-color: variables.$gray;
      }
    }

    .top-section {
      padding: 20px;
    }

    .ico-tick {
      margin-right: 14px;
    }

    h2 {
      margin-top: 0;
      &.grey{
        color: #808080;
      }
    }

    p {
      margin: 0 0 0 40px;
    }
  }

  .entity-desc {
    line-height: 18px;
  }

  .entity-holder.active {
    border-color: variables.$brand-primary;

    .select-status {
      border-color: variables.$brand-primary;
    }
  }

  .secondary-options md-icon {
    margin-right: 6px;
  }

  // TODO: try to reuse
  .bottom-section {
    position: absolute;
    bottom: 1px;
    left: 0;
    right: 0;
  }

  .md-sidenav-right {
    bottom: 66px;
  }

  .task-wrapper .chart-header {
    margin: 0;
    background: variables.$btn-default-color;
    color: variables.$btn-default-bg;
  }
}

.quickbook-transactions {
  padding: 20px;

  .status-indicator {
    width: 10px;
    height: 10px;
    background: red;
    display: inline-block;
    border-radius: 50%;
  }

  .sync-status-option {
    margin-left: 5px;
  }

  .quick-section {
    md-select {
      padding: 5px 15px;
      border: 1px solid #333;
      border-radius: 50px;
      width: 150px;
    }

    md-input-container {
      margin: 0;
    }
  }

  .viewby-select {
    width: 200px;
  }

  .sync-status {
    .md-select-value span {
      color: variables.$btn-default-color;
    }
  }

  .filters-bar {
    md-select {
      width: 100%;
    }

    md-input-container {
      margin: 8px;
    }
  }
}

.quickbook-sync-popup {
  md-dialog-content {
    padding: 20px;
  }

  .correct-option {
    margin-bottom: 0;
  }

  .login-successful md-icon {
    margin-right: 10px;
  }
}

@media(max-width:500px) {
  .add-gateway .add-email md-icon {
    margin-left: 0;
  }
}

@media(max-width:600px) {
  .view-gateway {
    .segment-desc-sec {
      margin-top: 30px;
    }

    .segment-desc-sec label {
      margin-bottom: 10px;
    }
  }
}
