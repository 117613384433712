@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.product-section.product-general {
  h2 {
    padding: 0;
    font-weight: 500;
    margin: 0;
    @extend .f1;
  }

  .section-title {
    margin: 0 20px;
    padding: 25px 0 10px;

    .icon-setting {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .data-table {
    min-width: 0;
  }

  .item-section:last-child {
    margin-bottom: 25px;
  }

  .default-box {
    padding: 5px 19px;
    margin: 0 20px;
    border-radius: 5px;

    .table-responsive {
      margin: 0;
    }

    h3 {
      padding: 10px 0;
      font-weight: 500;
      margin: 0;
      @extend .f3;
      color: variables.$gray-darker;
    }

    p {
      color: variables.$gray;
      font-size: 10px;
    }

    .product-listing {
      .feature-icons {
        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;

          li {
            display: inline-block;
            padding: 0 5px;

            .ico-ps-dual-book {
              height: 11px;
              width: 10px;
            }

            .ico-ps-umbrella {
              height: 11px;
              width: 14px;
            }

            .ico-ps-hot-shape {
              height: 15px;
              width: 12px;
            }

            .ico-ps-calender {
              height: 12px;
              width: 13px;
            }
          }

          li:first-child {
            padding-left: 0;
          }
        }
      }

      table {
        border: none;
        margin: 0;
      }

      thead {
        background: transparent;
      }

      td,
      th {
        @extend .f4;
        padding: 10px 0;
        line-height: 1;
        height: auto;
      }

      td {
        border-bottom: none;
        color: variables.$gray-darker;
      }
    }
  }

  .layout-margin md-divider {
    margin: 10px 0;
  }

  .sidebar-toggle-btn {
    margin-top: 20px;
    margin-right: 20px;
    @extend .text-right;

    a {
      display: inline-block;
    }
  }

  .product-sidebar {
    .top-section {
      padding: 10px 0;

      .sidebar-title {
        padding: 20px 16px 18px;
        border-bottom: 1px solid variables.$gray-lighter;
        margin-bottom: 10px;
      }

      md-list {
        padding: 0 16px;

        md-list-item {
          min-height: auto;
          padding: 10px 0;
          color: variables.$gray-darker;

          &.f1 {
            padding-top: 10px;
          }

          .tax-amount {
            @extend .f4;
            padding: 10px 0 0;
          }
        }
      }
    }
  }

  .md-sidenav-right {
    width: 300px;
    min-width: 0;
    max-width: 300px;
  }

  .tablesaw-bar {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .product-section.product-general {
    .md-sidenav-right {
      width: 32%;
      min-width: 32%;
    }

    .tablesaw-bar {
      display: block;
    }
  }
}
@media only screen and (max-width: 600px) {
  .product-section.product-general {
    .md-sidenav-right {
      width: 200px;
    }
  }
}
