@use "../../settings/third-party/mixins";
@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.history-section {
  md-toolbar.md-default-theme:not(.md-menu-toolbar),
  md-toolbar:not(.md-menu-toolbar) {
    background: none;
  }

  .show {
    display: block;
  }
  /***** Style for history navigation section *****/
  .history-nav-section {
    padding-top: 6px;

    li {
      text-align: center;
    }

    .ico-navs {
      width: 60px;
      height: 60px;
      @include mixins.border-radius(100%);
    }

    h4 {
      margin: 7px 0 0;
      font-weight: 500;
      @extend .f4;
    }

    .active {
      .ico-navs {
        border: 1px solid variables.$brand-primary;
      }

      h4 {
        @extend .f3;
      }
    }
  }
  /***** Style for history navigation section *****/
  .history-list {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      bottom: -50px;
      border: 1px solid variables.$brand-accent;
      @include mixins.border-radius(2px);
    }

    li {
      @extend .clearfix;
      margin: 0 0 18px;
      position: relative;

      &:before {
        @include mixins.border-radius(100%);
        background-color: variables.$brand-accent;
        border: 3px solid variables.$white;
        content: '';
        display: inline-block;
        height: 8px;
        position: absolute;
        left: 50%;
        top: 22px;
        transform: translate(-45%, -50%);
        visibility: visible;
        width: 8px;
      }
      /****** active history list li start ***********/
      &.active {
        &:before {
          background-color: variables.$brand-primary;
        }

        .data-msg {
          &:before {
            border-right: 7px solid variables.$brand-primary;
          }

          .doc-list {
            border: 1px solid variables.$brand-primary;
          }
        }
      }

      &:nth-child(even).active {
        .data-msg {
          &:before {
            border-left: 7px solid variables.$brand-primary;
          }
        }
      }
      /****** active history list li end ***********/
      .data-date,
      .data-msg {
        width: 48%;

        p {
          margin: 0 0 7px;
          line-height: 18px;
        }

        span {
          font-size: 10px;

          &.f4 {
            font-size: variables.$font-size-small;
          }
        }
      }

      .data-date {
        @extend .text-right;
        color: variables.$gray-darker;
        font-size: 10px;
        float: left;
        margin-left: -7px;
        padding: 7px 0 0;
      }

      .data-msg {
        position: relative;
        float: right;
        margin-right: -7px;
        /********** arrow generator start **************/
        &:after,
        &:before {
          position: absolute;
          right: 100%;
          width: 0;
          height: 0;
          content: "";
        }

        &:before {
          top: 15px;
          left: -13px;
          border-top: 7px solid transparent;
          border-right: 7px solid variables.$brand-accent;
          border-bottom: 7px solid transparent;
          border-left: 7px solid transparent;
        }

        &:after {
          top: 17px;
          left: -10px;
          border-top: 5px solid transparent;
          border-right: 6px solid variables.$white;
          border-bottom: 5px solid transparent;
          border-left: 5px solid transparent;
          z-index: 10;
        }
        /********** arrow generator start **************/
        .doc-list {
          background-color: #FFF;
          z-index: 10;
          outline: 0;
          border: 1px solid variables.$brand-accent;
          @include mixins.border-radius(4px);
          padding: 10px;
          box-sizing: border-box;
          left: 0;
          right: 0;

          li {
            position: relative;
            margin: 0;

            &:before {
              visibility: hidden;
            }

            .doc-holder {
              float: left;

              md-icon {
                &.doc-icon {
                  width: 65px;
                  height: 65px;
                }

                svg {
                  @extend .PosR;
                  border: 1px solid variables.$gray-light;
                  @include mixins.border-radius(4px);
                  padding: 8px 0;
                  box-sizing: border-box;
                  background-color: variables.$white;
                  z-index: 3;
                  width: 65px;
                  height: 65px;
                }
              }
            }

            .doc-descp {
              padding-left: 15px;
              overflow: hidden;

              .doc-name {
                margin: 0 0 10px;
              }
            }

            md-icon.ico-refresh {
              position: absolute;
              bottom: 0;
              right: 5px;
              width: 20px;
              height: 20px;
            }
          }

          &.mulDoc-list {
            padding: 0;

            &:after {
              content: "";
              position: absolute;
              top: 30px;
              left: -11px;
              bottom: -2px;
              background: #fff;
              width: 10px;
              z-index: 10;
            }

            &.open {
              z-index: 12;
              position: absolute;
              max-height: none;

              &:first-child {
                .doc-icon {
                  &:before {
                    display: none;
                  }
                }
              }

              ul {
                max-height: none;
              }
            }

            ul {
              max-height: 85px;
              overflow: hidden;
              padding: 0;

              li {
                margin: 0 0 10px;
                padding: 10px;

                &:first-child {
                  .doc-icon {
                    @extend .PosR;

                    &:before {
                      content: "";
                      border: 1px solid variables.$gray-light;
                      @include mixins.border-radius(4px);
                      position: absolute;
                      top: 3px;
                      right: -3px;
                      bottom: -3px;
                      left: 3px;
                      z-index: -2px;
                    }
                  }
                }

                &:nth-child(even) {
                  background-color: variables.$white-dark;
                }

                &:last-child {
                  margin: 0;
                }
              }
            }
          }
        }
      }

      &:nth-child(even) {
        .mulDoc-list {
          &:after {
            right: -11px;
            left: initial !important;
          }
        }

        .data-date {
          @extend .text-left;
          float: right;
          margin-right: -7px;
        }

        .data-msg {
          position: relative;
          float: left;
          margin-left: -7px;

          &:before {
            left: auto;
            right: -13px;
            border-left: 7px solid variables.$brand-accent;
            border-right: 7px solid transparent;
          }

          &:after {
            left: auto;
            right: -11px;
            border-left: 6px solid variables.$white;
            border-right: 6px solid transparent;
          }

          &.selected-msg {
            &:before {
              border-left: 7px solid variables.$brand-primary;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .history-section {
    .history-list {
      li {
        .data-msg {
          .doc-list li {
            .doc-descp p {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              height: 30px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .history-section {
    .containers.active-container {
      padding: 0 5px 0 0;
    }

    .history-nav-section {
      .ico-navs {
        width: 40px;
        height: 40px;
      }

      .md-subhead {
        font-size: variables.$font-size-small-plus;
      }
    }

    .history-list {
      &:before {
        left: calc(25% + 4px);
        transform: translate(-50%, 0);
      }

      li {
        &:before {
          left: 25%;
          top: 19px;
          transform: translate(-50%, -25%);
        }

        .data-date {
          text-align: left;
          width: 25%;
          padding: 8px 0;
          box-sizing: border-box;
          margin-left: 0;
        }

        .data-msg {
          float: left;
          margin-left: 20px;
          width: 70%;
          max-width: 100%;

          .doc-list li {
            .doc-descp p {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              height: 30px;
            }
          }
        }

        &:nth-child(even) {
          .data-date {
            float: left;
            margin-right: 0;
          }

          .data-msg {
            float: left;
            margin-left: 20px;

            &:after,
            &:before {
              content: "";
              position: absolute;
              right: 100%;
              width: 0;
              height: 0;
            }

            &:before {
              top: 15px;
              left: -13px;
              border-top: 7px solid transparent;
              border-right: 7px solid variables.$brand-accent;
              border-bottom: 7px solid transparent;
              border-left: 7px solid transparent;
            }

            &:after {
              top: 17px;
              left: -10px;
              border-top: 5px solid transparent;
              border-right: 6px solid variables.$white;
              border-bottom: 5px solid transparent;
              border-left: 5px solid transparent;
            }
          }
        }
      }
    }
  }
}

.openpdf-popup {
  min-width: 100%;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  background: transparent;

  .ico-btn {
    padding: 0;
    min-width: 0;
    width: auto;
    vertical-align: middle;
  }

  .close-btn {
    position: absolute;
    right: 18px;
    top: 4px;
    margin-right: 0 !important;

    md-icon {
      height: 16px;
      width: 14px;

      svg {
        path {
          fill: variables.$white;
        }
      }
    }
  }

  md-dialog-content {
    height: 100%;

    .pdf-section {
      width: 100%;
      height: 100%;
    }
  }
}
@-moz-document url-prefix() {
  .openpdf-popup .close-btn {
    left: 25px;
    right: inherit;
    top: -4px;
  }
}

.history-section .history-list li .arrow-depth:after {
  z-index: 15;
}

@media screen and (max-width: 390px) {
  .history-section .history-list {
    padding: 0;

    &:before {
      left: calc(25%);
    }

    li .data-msg {
      margin-left: 14px !important;
    }
  }
}

@media screen and (max-width: 370px) {
  .history-section .history-list li .data-msg {
    width: 70%;
  }
}
