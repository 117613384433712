@use "../../settings/third-party/variables";

.notes-templates-main {
  .like-history-icon {
    width: 18px;
  }

  .category-dropdown {
    width: 50%;
  }

  .add-note-template-sidenav {
    position: absolute;
    right: 10px;
  }

  .color-picker {
    border-radius: 50px;
    width: 65px;
    margin: auto;

    .color-holder {
      width: 100px;
      height: 20px;
      opacity: 0;
    }
  }

  h2 {
    font-size: 25px;
  }

  .note-templates {
    top: -50px;
  }

  .detail-cards-div {
    max-width: 260px;
    min-height: 220px;
    border: 1px solid #fff;
    border-bottom: 0;
    width: 100%;
    display: flex;
    // justify-content: end;
    position: relative;
    cursor: pointer;
    background-color: #f3ffff;
    box-shadow: 0 0 1px 0 rgba(0,0,0,.2), 0 0 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12);

    .notes-icon-tick {
      position: absolute;
      top: -13px;
      z-index: 1;
      left: -9px;
    }

    &.notes-tick-border {
      border: 1px solid #6aa84f !important;
      border-bottom: 0 !important;
    }

    .sales-card-cta {
      background-color: #20394cff;
      color: white;
      border-radius: 15px;
      text-align: left;
      padding: 5px 16px;
      font-size: 12px;
      max-width: 90px;
    }

    h3 {
      color: #21374c;
      font-size: 18px;
      font-weight: bold;
    }

    p {
      color: #666666;
    }

    md-card-actions {
      .list-icons {
        margin-right: 0;
        text-align: end;

        svg {
          path {
            fill: #ff9900;
          }
        }
      }
    }

    md-card-actions.bottom-label-section {
      margin: 0;
      padding: 5px 5px 5px 8px;
      color: variables.$white;
      background-color: #20394cff;

      p {
        margin: 0;
        color: variables.$white;
        font-size: 10px;

        span {
          color: #ff9900;
        }
      }
    }

    .temp-widget {
      height: 100%;
      // background: $white;
      // border: 1px solid $gray-lighter;

      &.temp-active {
        border-color: variables.$brand-primary;
      }

      .template-heading {
        position: relative;
        background: variables.$gray-light-media;
        border-bottom: 1px solid variables.$gray-lighter;
        padding: 1px 0;
        overflow: hidden;

        h2 {
          padding: 0 15px;
        }

        .ico-tick {
          position: absolute;
          top: 0;
          right: 0;
          height: 18px;
          width: 18px;
        }

        .location-symbol {
          position: absolute;
          top: -11px;
          right: -18px;
          transform: rotate(45deg);
          z-index: 1;
          padding: 12px 10px 0 10px !important;
          border-radius: 0;
        }
      }

      .temp-image {
        // padding: 20px;
        overflow: hidden;
        position: absolute;
        width: 100%;
        height: 100%;

        &:hover {
          .template-overlay {
            display: flex;
          }
        }
      }

      .template-preview {
        margin: 0;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;

        md-card-title {
          display: grid;
          padding: 0 16px 15px 8px;
        }
        //   height: 250px;
      }

      .preview-container {
        transform: scale(.41) translate(-72%, -72%);
      }

      .template-overlay {
        background: rgba(0, 0, 0, .6);
        color: variables.$white;
        display: none;
        padding: 20px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .overlay-btn {
          width: 100%;
          display: flex;
          justify-content: space-around;
        }

        .md-button {
          border: 1px solid variables.$white;
          margin-left: 0;
        }
      }

      .template-options {
        width: 100%;
        bottom: 45px;
        position: absolute;

        .cursor-point {
          text-align: center;
        }

        md-icon {
          height: 20px;
          width: 20px;
          margin-bottom: 10px;

          path {
            fill: variables.$white;
          }
        }
      }
    }

  }

  .detail-cards-div.selected {
    background-color: variables.$white;
    border: 1px solid #64c43a;
  }

  .notes-header {
    padding: 30px 25px;
    background-color: rgb(211, 225, 229);

    .categories-header {
      font-weight: bold !important;
    }

    .header-icon {
      min-height: 70px;
      min-width: 84px;
    }

    .manage-categories {
      background-color: white;
      border-radius: 5px;

      md-icon {
        width: 17px;
        height: 15px;
      }
    }
  }

  .notes-template-selection {
    font-size: 10px;
    color: #000;
  }

  @media(min-width: 600px) {
    .template-select-categories {
      margin-top: 25px;
    }
  }

  .template-select-categories {
    top: -53px;

    .category-select {
      min-width: 304px;
      max-width: 750px;
      width: 100%;
    }

    .select-btn {
      background-color: white;
      border: 1px solid variables.$gray-lighter;
    }

    .select-btn:hover {
      border: 1px solid variables.$brand-primary;
    }

    .onSelectBtnClick {
      border: 1px solid variables.$brand-primary;
      background-color: variables.$brand-primary;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    }

    .onSelectBtnClickNotes {
      color: variables.$brand-primary;
      border: 1px solid variables.$brand-primary;
      background-color: #fff;
    }
  }
}

.delete-notes {
  padding: 18px 35px;

  .heading {
    color: #000000;
    max-width: 445px;
    font-size: 16px;
    width: 100%;
    line-height: 1.5;
  }

  .bottom-section {
    display: flex;
    justify-content: center;
  }
}

.add-note-popup {
  // max-width: 800px;
  // width: 100%;
  .add-notes {
    width: 100%;

    md-list-item.md-no-proxy {
      padding: 0;
    }

    .lead-creation-div {
      // width: 100%;
      // margin-bottom: 15px;
      .export-criteria-div {
        display: flex;
        width: 100%;
        justify-content: space-between;

        md-switch {
          margin-top: 15px;
          margin-right: 20px;
        }
      }

      .bz-preferred {
        padding: 5px 15px;
        background-color: #d6e1e5;
        border-radius: 13px;
      }

      .icon_img {
        width: 30px;
        margin-right: 15px;
      }
    }

    .lead-card {
      display: flex;
      width: 100%;
      //justify-content: space-between;
      md-input-container {
        margin-right: 15px;
      }
    }

    .section-heading {
      width: 100%;

      md-icon {
        margin: 0 5px 0 4px;
      }
    }

    .lead-card-content {
      margin-left: 40px;
      margin-bottom: 30px;
      width: 100%;

      .sales-agent-btns {
        margin: 0 0 20px;
      }
    }

    .view-lead-card-content {
      md-radio-button {
        pointer-events: none;
      }

      md-switch.md-block {
        pointer-events: none;
      }

      md-checkbox {
        pointer-events: none;
      }

      md-input-container {
        pointer-events: none;
      }
    }

    .setup-content-div {
      //padding-left: 50px;
      .setup-content-heading {
        margin-right: 45px;
      }

      .setup-content-desc {
        margin-top: 10px;
        width: 100%;
      }

      md-input-container {
        margin-left: 0 !important;
      }

      .rb-btns {
        margin-top: 10px;
      }
    }

    .add-contact {
      margin: 0 30px 20px 0;
      align-items: center;
      display: flex;

      .icon-ico-add {
        margin-right: 5px;
      }

      .profile_icon_png {
        width: 30px !important;
        margin-right: 5px;
      }
    }
  }

}

.notes-template-sidenav {
  bottom: 0!important;

  .leftnav-wrap {
    width: 100%;
    min-width: 100%;
    position: absolute;
    right: 0;

    .site-visit-sidebar {
      padding: 0;

      .close-div {
        background-color: #000000;
        padding: 15px 10px !important;

        .topbar-heading {
          margin: 0 auto;
          color: variables.$white;
          font-size: 18px;
        }
      }

      .profile-detail-section {
        ul {
          padding-left: 0;
          width: 100%;

          li {
            margin-bottom: 10px;
            padding: 25px 10px;

            .img-content {
              display: flex;
              align-items: flex-start;

              .capsule-section {
                justify-content: space-between;
                padding-top: 10px;

                .capsule-div {
                  background: white;
                  border: 1px solid rgba(0,0,0,0.12);
                  border-radius: 50px;
                  padding: 5px 10px;
                }

                .capsule-name {
                  padding: 0;
                  border: 1px solid #eeeeee;
                  border-radius: 50px;
                  background-color: #eeeeee;
                  max-width: 155px;
                  width: 100%;
                  align-items: center;
                  display: flex;
                  text-align: left;
                  font-weight: 600;
                  font-size: 11px;
                  margin-right: 20px;

                  .icon_img {
                    margin: 0 7px 0 0;
                    width: 25px !important;
                    height: auto;
                  }
                }
              }

            }

            .icon_img {
              width: 40px !important;
              height: auto;
              margin-right: 15px;
            }

            p {
              color: #20394c!important;
              margin-top: 0 !important;
              font-size: 15px;
              font-weight: 500;
            }
          }

          li:nth-child(even) {
            background-color: #d3e1e54a;
          }
        }

      }
    }

  }

}

.text-seagreen {
  color: #5cc5c3!important;
}

.text-seablue {
  color: #2196f3!important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.notes-labels-sidenav {
  background-color: #fff;

  .notes-labels {
    border-radius: 4px;
    color: #fff;
  }
}

.notes-sidenav {
  .likes-history {
    background-color: #fff;
  }

  .user-contact {
    .contact-img.notes {
      width: 30px !important;
      height: 30px !important;
      min-width: 24px !important;
      max-width: 34px !important;
      min-height: 24px !important;
      max-height: 34px !important;
      border-radius: 50%;
      overflow: hidden;
    }
  }

  .likes-thumbs-up {
    height: 20px;
    width: 20px;
  }

  .location-symbol-notes {
    background-color: #ef4135;
  }
}
