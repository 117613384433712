@use "../../settings/third-party/mixins";
@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.opp-detail {
  margin-bottom: 12px;

  label {
    @extend .f4;

    font-weight: 400;
    margin-right: 10px;
  }
  //
  // .opp-id {
  //   font-size: 16px;
  //   font-weight: bold;
  // }
  //
  // .opp-date,
  // .opp-id {
  //   color: $gray-darker;
  // }
}

.default-card-box,
.opp-stats {
  margin-bottom: 18px;
}

.opp-stats {
  font-size: variables.$font-size-base;
  color: variables.$gray-darker;

  .cur-status {
    label {
      padding-right: 0;
    }

    .separator {
      display: inline-block;
      margin: 5px 20px 5px 9px;
      position: relative;
      top: 2px;
      border: 1px solid variables.$white-dark;
      width: 46px;

      md-icon {
        height: 8px;
        position: absolute;
        top: -4px;
        right: -16px;
        width: 15px;
      }
    }

    // span {
    //   color: $brand-primary;
    // }
  }

  .label {
    color: variables.$gray;
    font-size: 10px;
    margin-bottom: 8px;
  }

  .agent-detail {
    font-size: variables.$font-size-small;
  }

  .resource-chips md-chips {
    .md-chips {
      padding: 0;
    }

    md-chip {
      margin: 0;
    }
  }
}

.md-open-menu-container,
.receipient-menu {
  @include mixins.border-radius(4px);
}

.receipient-menu {
  border: 1px solid #CFDADE;

  &:after {
    border-width: 7px;
  }

  &:before {
    border-width: 9px;
  }

  &:after,
  &:before {
    bottom: 99%;
  }

  md-menu-item {
    height: 40px;
    min-height: 40px;

    & > * {
      padding: 0 10px;
    }

    .md-button {
      color: variables.$gray-darker;
      font-size: variables.$font-size-small;
      background: none;
    }
  }

  &:after,
  &:before {
    right: 15px;
  }
}

.opportunity-proposal {
  /***optimized code starts here***/
  .contact-user-list .user-contact {
    margin-top: 0;
  }

  .opportunity-agreements {
    background: variables.$navbar-default-bg;
    box-shadow: 1px 1px 1px variables.$gray;
    padding: 10px 10px 12px;

    .agr-name {
      margin: 0 0 12px;

      h2 {
        color: variables.$gray-dark;
        font-size: 16px;
        font-weight: 400;
        margin: 0;
      }

      .agr-status {
        color: variables.$brand-accent;
        font-size: variables.$font-size-small-plus;
      }

      .menu-btn {
        right: 0;
        top: -6px;
      }
    }

    .agr-amt {
      @extend .f1;

      @extend .fm;

      display: inline-block;
      margin: 0 0 16px;
    }

    .business-tag {
      @extend .f3;

      margin: 0 0 10px;

      md-icon {
        height: 16px;
        margin: 0 10px 0 0;
        width: 16px;
      }

      .rec-msg {
        color: variables.$gray;
        font-size: 10px;
        margin-left: 8px;
      }
    }
  }

  .receipient-lists {
    @extend .clearfix;

    li {
      background-color: variables.$white;
      box-shadow: 2px 2px 1px variables.$gray-lighter;
      box-sizing: border-box;
      float: left;
      margin: 0 10px 0 0;
      min-width: 201px;
      height: 65px;
      max-height: 65px;
      padding: 10px 9px;

      &:last-child {
        background: none;
        box-shadow: none;
        height: auto;
        min-width: auto;
        margin: 0;
        padding: 0;
      }
    }

    .recep-no {
      color: variables.$gray;
      font-size: 36px;
      font-weight: 300;
    }

    .recep-detail {
      position: relative;
      line-height: 16px;
      color: variables.$gray;
      font-size: 10px;
    }

    .recep-name {
      @extend .f3;

      @extend .fm;

      margin-top: 3px;
    }

    .circle {
      border: 1px solid variables.$gray;

      @include mixins.border-radius(100%);

      display: inline-block;
      height: 11px;
      width: 11px;
      position: absolute;
      top: 0;
      right: 0;

      &.ind-suc {
        border: 1px solid variables.$brand-success-dark;
      }

      &.ind-warn {
        border: 1px solid variables.$brand-primary;
      }
    }

    .add-recp-btn {
      height: 36px;
      margin: 16px 0 0 8px;
      width: 36px;
    }
  }

  .agreement-detail {
    color: variables.$gray;
    font-size: 10px;
    margin: 10px 0 30px;
  }

  .opportunity-tabs {
    md-content {
      padding-top: 15px;
      min-height: 365px;
    }

    .summary-head {
      margin: 0 0 32px;
      color: variables.$gray-darker;
      font-weight: normal;
    }

    md-ink-bar:after,
    md-ink-bar:before {
      top: 0;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    md-ink-bar:after {
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #FFFFFF;
      border-width: 7px;
      margin-left: -15px;
    }

    md-ink-bar:before {
      border-color: rgba(204, 204, 204, 0);
      border-top-color: #CCCCCC;
      border-width: 8px;
      margin-left: -16px;
    }

    md-ink-bar {
      background: none;
      height: 12px;
      bottom: -12px;
    }

    .md-tab {
      color: variables.$brand-primary;
    }

    .md-tab.md-active {
      color: variables.$gray-base;
    }

    &[md-border-bottom] md-tabs-wrapper {
      z-index: 11;
    }

    md-tab-item {
      padding-top: 0;
      padding-bottom: 0;
      text-transform: capitalize;

      span {
        font-weight: normal;
        font-size: variables.$font-size-small;
      }
    }

    md-pagination-wrapper,
    md-tabs-canvas {
      height: 27px;

      md-tab-item {
        padding-left: 10px;
      }
    }

    md-tabs-canvas {
      overflow: visible;
    }

    .proposal-summary {
      li {
        color: variables.$gray-darker;
        margin: 0 0 30px;
      }

      label {
        @extend .f4;
      }

      .status {
        color: variables.$brand-success;
      }
    }

    .data-table {
      min-width: 700px;

      thead th {
        @extend .fm;

        color: variables.$gray-darker;
      }

      td {
        color: variables.$gray-darker;
        font-size: variables.$font-size-small;

        &:first-child {
          color: #606060;
        }
      }

      .sort-icon {
        margin-left: 10px;
      }
    }

    .notes-list {
      margin: 0;
      padding-left: 10px;
      min-height: 200px;

      &.edit-note-list {
        margin: 20px 0;
        border: 1px solid variables.$gray-light;

        @include mixins.border-radius(4px);
      }

      li {
        color: variables.$gray-darker;
        font-size: variables.$font-size-small;
        position: relative;
        padding: 0 150px 0 0;
        margin: 0 0 20px;
        line-height: 21px;

        .notes-edit-icon {
          position: absolute;
          top: 5px;
          right: 0;
          height: 14px;
          width: 14px;
        }

        md-input-container {
          margin: -10px 0 0;

          textarea {
            height: 30px;
          }
        }
      }
    }

    .formatting-wrapper {
      display: none;

      md-input-container {
        margin: 12px 0;

        textarea.md-input {
          font-size: variables.$font-size-small;
          width: 640px;
          max-width: 100%;
          line-height: 18px;
          padding: 15px 10px 20px;
          box-sizing: border-box;
          height: 68px;
          max-height: 68px;
        }
      }

      .footer-icons {
        md-icon {
          @include mixins.border-radius(100%);

          border: 1px solid variables.$gray;
          height: 17px;
          margin: 0 7px 0 0;
          padding: 6.5px;
          width: 17px;

          &.active-btn {
            border: 1px solid variables.$brand-primary;

            svg path {
              fill: variables.$brand-primary;
            }
          }
        }
      }
    }
  }

  /***optimized code ends here***/
}

.md-sidenav-right {
  md-content {
    padding-top: 25px;
  }

  .sidenav-header {
    padding-left: 16px;

    h2 {
      @extend .f1;

      margin: 0 0 18px;
      font-weight: normal;
    }

    md-checkbox {
      &.md-checked .md-icon:after {
        border-color: variables.$brand-primary;
        top: 1px;
        left: 5.5px;
        height: 12.33px;
        width: 5px;
      }
    }

    .md-icon {
      background-color: variables.$white;
      height: 18px;
      width: 18px;
    }

    .md-label {
      color: variables.$gray-dark;
      margin-top: 2px;
      margin-left: 34px;
    }
  }

  .receipient-list {
    padding: 0;

    .right-space {
      margin-right: 20px;
    }

    md-list-item {
      min-height: 70px;
      padding: 15px 16px;
      position: relative;

      &:last-child {
        @extend .f3;

        min-height: auto;
      }
    }

    .recp-add-btn {
      margin: 0 14px 0 0;
      min-height: auto;
      min-width: auto;
      padding: 0;

      &.md-button:not([disabled]):hover {
        background: none;
      }
    }

    .list-detail {
      margin-right: 10px;
      max-width: 23px;
      min-height: 55px;
      background: url("#{variables.$image-path}/icons/set-d/ico-bricks.svg") center top 32px / 10px 14px no-repeat;
    }

    .list-no {
      border: 1px solid #c0e0f6;
      display: inline-block;
      height: 22px;
      line-height: 22px;
      width: 22px;
      background-color: #EBF2F7;
      font-size: variables.$font-size-base;

      @extend .fm;

      @extend .text-center;
    }

    .recp-detail {
      position: relative;

      span {
        margin-bottom: 7px;
      }
    }

    .edit-recp-box md-input-container {
      @extend .right-space;

      background-color: #fff;
      margin-bottom: 12px;

      input {
        padding: 0 9px;
      }

      input,
      label {
        font-size: variables.$font-size-small;
      }

      label {
        padding-bottom: 0 !important;
        font-weight: 300;
      }

      &:last-child {
        margin-bottom: 0 !important;
      }
    }

    .recp-name {
      @extend .f3;
    }

    .recp-cont,
    .recp-email {
      @extend .f4;
    }
  }

  .right-sidenav-footer {
    @extend .text-center;

    background-color: #e1e2e0;
    max-height: 40px;
    padding: 7px 0;

    .md-button {
      @extend .fm;

      font-size: variables.$font-size-base;
      margin: 0 8px;
      line-height: 27px;
      min-height: 27px;
      max-height: 27px;

      &:first-child {
        background-color: variables.$white-dark;
        color: #8e8e93;
      }

      &:last-child {
        background-color: variables.$brand-primary;
        color: variables.$white;
      }
    }
  }

  // md-input-container .md-placeholder,
  // md-input-container label:not(.md-no-float):not(.md-container-ignore) {
  //     padding-bottom: 5px;
  //     padding-left: 9px;
  // }
}

@media only screen and (max-width: 714px) {
  .opportunity-proposal .opportunity-agreements .receipient-lists li {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .opp-detail {
    span:first-child {
      margin-bottom: 7px;
    }
  }

  .opp-stats {
    div {
      &:not(:last-child) {
        margin-bottom: 7px;
      }
    }

    .agent-detail {
      margin-top: 20px;
    }
  }

  .opportunity-proposal {
    .opportunity-tabs {
      .formatting-wrapper {
        md-input-container {
          textarea.md-input {
            max-width: 100%;
          }
        }
      }

      .notes-list li {
        padding: 0 40px 0 0;
        text-align: justify;

        .notes-edit-icon {
          right: -8px;
        }
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  .opportunity-proposal .opportunity-agreements .receipient-lists {
    li {
      float: none;
      margin: 0 auto 10px;

      &:last-child {
        text-align: center;

        md-icon[aria-label="icon-add"] {
          margin: 0;
        }
      }
    }
  }
}

//*****************************Send Proposal popups**************************************//
.send-proposal {
  width: 800px;
  min-height: 610px;
  overflow: hidden;

  .main-header {
    .md-toolbar-tools .md-icon-button {
      md-icon {
        height: 18px;
        width: 18px;

        svg {
          path {
            fill: variables.$white;
          }
        }
      }
    }
  }

  md-dialog-content {
    width: 100%;
    height: 100%;
    padding: 12px 20px 0;

    .mail-section {
      width: 100%;

      .mail-tool-list {
        li {
          border-bottom: 1px solid variables.$white-dark;
          padding: 32px 0 10px;

          label {
            @extend .f4;

            font-weight: 500;
            display: inline-block;
            width: 7%;
            min-width: 40px;
            margin-right: 5px;
          }

          input {
            display: inline-block;
            width: 85%;
            height: auto;
            border: 0;
            background: variables.$white;

            @extend .f3;
          }

          &:last-child {
            margin-bottom: 10px;
          }
        }
      }

      .mail-tools {
        .ico-add {
          height: 20px;
          width: 20px;

          svg {
            path {
              fill: variables.$brand-accent;
            }
          }
        }

        .ico-attach {
          width: 15px;
          height: 15px;
          margin-right: 4px;
        }

        .edit-tool {
          margin: 15px 0;

          img {
            max-width: 100%;
          }
        }

        .muted-btns {
          margin: 5px 0 0;

          .md-button {
            padding: 0 20px 0 8px;
            font-size: 10px;
            height: 20px;
            min-height: 20px;
            line-height: 20px;

            .chip-close {
              height: 8px;
              width: 8px;
              position: absolute;
              top: 5px;
              right: 6px;
            }
          }
        }

        .permisson-settings {
          position: relative;
          padding-left: 20px;
          margin-top: 14px;

          md-icon {
            width: 14px;
            height: 14px;
            position: absolute;
            left: 0;
            top: 0;
          }

          span {
            display: block;
            font-size: 10px;
            color: variables.$gray-dark;
            margin-bottom: 4px;
            padding-top: 2px;
          }

          a {
            font-size: 10px;
          }
        }
      }

      .mail-box {
        margin: 0;
        display: block;
        text-align: justify;
        height: 200px;
        max-height: 200px;
        overflow-y: auto;
        line-height: 1.42857143;
        background-color: #fff;
        background-image: none;
        border: 0;
        resize: none;

        @extend .f3-light;
      }
    }
  }

  .md-sidenav-right.inside-container {
    bottom: 0;
    width: 232px;

    @media only screen and (max-width: 767px) {
      bottom: 0 !important;
    }

    #setPermissions {
      padding: 20px;

      h2 {
        margin-top: 0;

        @extend .f1;

        font-weight: normal;
        margin-bottom: 6px;
      }

      label {
        @extend .f4;

        display: inline-block;
        margin-bottom: 20px;
        margin-top: 6px;
      }

      md-radio-group {
        margin-bottom: 20px;

        md-radio-button {
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }

          .md-label {
            @extend .f3;
          }

          .md-off {
            background: #fff;
          }

          .md-on {
            top: 4px;
            left: 4px;
            width: 14px;
            height: 14px;
          }
        }
      }

      .datepicker-custom {
        margin-bottom: 16px;

        .md-datepicker-input-container input {
          @extend .f4;

          height: 38px;
          width: 100%;
        }
      }
    }
  }
}

//*****************************End popups**************************************//
