@use "sass:color";
@use "../settings/third-party/variables";

.upload-options {
  padding: 8px 12px;
  .sidebar-header{
    color: variables.$white;
    background-color: variables.$gray-base;

    h3 {
      font-size: variables.$font-size-large;
    }

    md-icon.fill-white {
      height:30px;
      width:30px;
      svg {
        path {
          fill: variables.$white;
        }
      }
    }
  }
  &.form-base {
    md-input-container {
      margin-bottom: 0;
    }

    h3 {
      font-size: 13px;
    }
  }

  .attach-icon {
    margin-right: 4px;
    margin-bottom: 2px;
    transform: rotate(-36deg);

    svg path {
      fill: #989086;
    }
  }

  .title {
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    margin: 0 0 0 -4px;
    vertical-align: middle;
  }

  md-divider {
    border-top-color: rgba(0,0,0,0.12) !important;
    margin-top: 5px;
  }

  .upload-buttons {
    width: 100%;

    li {
      margin-bottom: 12px;

      &:first-child {
        padding-left: 5px;
        min-height: 36px;
        height: 36px;
        line-height: 36px;
        margin-bottom: 12px;
      }

      md-icon {
        // width: 24px;
        // height: 24px;
        margin-right: 12px;
      }

      .md-button {
        min-width: 100px;
        width: 100%;
        margin: 0;
        padding: 0 5px;
        text-align: center;
        color: black;
      }
    }
  }

  // .link-add {
  //   width: 100%;
  //   margin-top: 15px;
  //
  //   md-divider {
  //     margin-bottom: 20px;
  //   }
  //
  //   .label-txt {
  //     display: inline-block;
  //     margin-bottom: 15px;
  //     vertical-align: top;
  //
  //     @extend .f1;
  //   }
  //
  //   input {
  //     background: #fff;
  //   }
  // }

  .sidebar-sub-header {
    background-color: black;

    .attach-file {
      color: white;
    }
  }

  .md-card-image {
    max-width: 50px;
  }

  .md-headline {
    font-size: variables.$font-size-large;
  }

  .type-box {
    border: 2px dashed transparent;

    &.dragover,
    &:hover {
      background-color: color.adjust(variables.$brand-primary, $lightness: 36%);
      border-color: variables.$brand-primary;

      .btn-hover {
        background-color: variables.$gray-light;
        color: variables.$white;
      }
    }
  }

  .upload-icon {
    height: 50px;
    width: 50px;
  }
}
