@use "../../settings/third-party/mixins";
@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.receive-po-section {
  padding: 20px;

  .custom-tabs {
    md-content {
      padding-top: 20px;
    }
  }

  .bottom-gap-md {
    margin-bottom: 30px;
  }

  .summery-widget {
    line-height: 1.5;

    .widget-space {
      margin-top: 15px;
    }
  }

  .data-table {
    td {
      @extend .f3;
    }

    .sm-text {
      color: variables.$gray;
      font-size: 10px;
    }

    .img-cb-box {
      width: 60px;
      min-height: 40px;

      md-checkbox {
        .md-label {
          margin-left: 0;
        }
      }
    }

    &.editable-records {
      .img-cb-box {
        md-checkbox {
          display: none;
        }
      }

      tr:hover {
        .img-cb-box {
          .product-img,
          .product-no-img {
            display: none;
          }

          md-checkbox {
            display: inline-block;
          }
        }
      }
    }
  }

  .input-sm {
    width: 75px;
  }

  .order-status {
    display: inline-block;
    width: 70px;

    .status-viewer {
      @include mixins.border-radius(5px);

      background: variables.$gray-lighter;
      border: 1px solid variables.$gray-light;
      height: 18px;
      margin-top: 2px;
      margin-bottom: 2px;
      overflow: hidden;

      .progress-bar {
        display: inline-block;
        height: 100%;

        &.open {
          background: variables.$proposalBlue;
        }

        &.closed {
          background: variables.$brand-info;
        }
      }
    }
  }
}
