@use "../../settings/third-party/mixins";
@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.service-detail-section {
  padding: 18px 20px;

  .organization {
    .f2 {
      margin-bottom: 30px;
    }

    md-checkbox {
      margin-top: 10px;
    }
  }

  .dark-variable {
    color: variables.$gray-darker;
    font-size: variables.$font-size-small;
  }

  .multi-select-input {
    &.md-input-has-value md-select md-select-value span {
      max-width: 80%;
      text-overflow: ellipsis;
    }
  }

  .update-status {
    color: variables.$gray;
    font-size: 10px;
    position: absolute;
    top: -10px;
    right: 0;
    z-index: 1;
  }

  .custom-tabs {
    md-content {
      padding-top: 25px;
    }
  }

  .ui-accordion {
    .panel-group {
      border: none;

      .panel {
        &,
        .panel-heading {
          @include mixins.border-radius(5px);
        }
        // border: 1px solid $border-color;
        // margin-bottom: 10px;

        .panel-heading {
          // background-color: $white;
        }
      }

      .panel-title > a {
        border-bottom: none;
        box-sizing: border-box;
      }
    }

    section {
      padding: 16px;
    }

    .left-section {
      // width: 64%;

      .new-type {
        margin-bottom: 28px;

        md-input-container {
          margin-right: 20px;
        }
      }
    }

    .right-section {
      width: 34%;
    }
  }

  // .rate-input-list {
  //   md-input-container {
  //     margin-bottom: 10px;
  //   }
  // }

  .inital-hour-limit {
    .section-lbl {
      margin-top: -12px;
      padding-right: 20px;
    }
  }

  md-checkbox.cb-checkDisabled {
    &[disabled] {
      .md-label span {
        color: variables.$gray;
      }

      &.md-checked {
        .md-label span {
          color: variables.$gray-darker;
        }
      }
    }
  }
}
/** Service type section starts here **/
.service-typeTab-section {
  .form-base {
    .input-md {
      &.add-task-input {
        margin-bottom: 25px;
      }

      input {
        width: 180px;
      }
    }
  }

  .add-task-btn {
    md-icon {
      height: 28px;
      width: 28px;

      svg path {
        fill: variables.$brand-primary;
      }
    }
  }
}

.service-item-list {
  &.item-list {
    .md-icon-button {
      float: right;
      top: 0 !important;
    }

  }

  &.list-services {
    .md-icon-button {
      float: right;
      top: 0 !important;
    }
  }
}

.service-item-list {
  @extend .no-style;

  &.block-list {
    li {
      display: block;
    }
  }

  &.sub-list {
    margin-top: 20px;
    margin-bottom: -20px;
    margin-left: 30px;

    li {
      display: inline-block;
    }
  }

  .md-icon-button {
    float: right;

  }

  li {
    // display: inline-block;
    // width: 313px;
    // margin-right: 139px;
    // margin-bottom: 20px;

    // md-checkbox.cb-checkDisabled {
    //   margin-bottom: 0;
    //   padding: 11px;
    // }

    md-checkbox {
      margin-bottom: 0;
      // padding: 11px;
    }

    .md-button.md-icon-button {
      margin: 6px 8px;
    }
  }
}

.service-widget {
  width: 225px;

  .widget-title {
    @extend .dark-variable;
    @extend .fm;
    margin-top: 0;
    margin-bottom: 18px;
  }

  .cb-checkDisabled {
    margin-bottom: 20px;
  }

  .status {
    margin-top: 4px;

    &.enable {
      @extend .text-success;
    }

    &.disable {
      color: variables.$gray;
    }
  }
}

.work-scope-list {
  margin-top: 10px;

  li {
    color: variables.$gray-darker;
    line-height: 20px;

    &.section-labels {
      font-size: variables.$font-size-small;
      font-weight: 500;
      margin-bottom: 12px;
    }
  }

  md-divider {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  md-checkbox {
    margin-top: 2px;
  }

  .scope-edit-btn md-icon {
    height: 12px;
    width: 12px;
  }
}

.work-scope-list-edit {
  li {
    margin-bottom: 30px;

    .work-scope-lbl {
      @extend .f4;
      padding-right: 20px;
    }

    .switch-bar {
      label {
        padding-right: 20px;
      }
    }

    .description-editor {
      textarea {
        min-height: 70px !important;
      }
    }
  }
}

.service-type-table {
  margin-top: 0;

  .data-table {
    min-width: 780px;

    td {
      @extend .f3;
      vertical-align: middle;
    }

    md-checkbox .md-icon {
      background: variables.$white;
    }
  }

  .edit-button {
    height: 32px;
    min-height: 32px;
    width: 32px;

    md-icon {
      height: 14px;
      width: 14px;
    }
  }

  .small-text {
    font-size: 10px;
  }
}

.rate-input-list {
  @extend .no-style;

  li {
    @extend .dark-variable;
    margin-bottom: 4px;

    &.list-heading {
      &.dark-bold label {
        color: variables.$gray-darker;
      }

      label {
        @extend .f4;
        padding-right: 10px;
      }
    }

    .resType-cb {
      top: -4px;
    }
  }
}
/** Service type section ends here **/
/** Service rate section starts here **/
.service-rateTab-section {
  .form-base {
    md-radio-button {
      @extend .dark-variable;
    }

    md-select .md-text {
      color: variables.$gray;
    }

    md-input-container label:not(.md-no-float):not(.md-container-ignore) {
      font-size: variables.$font-size-small;
    }
  }

  .inner-section {
    margin-left: 20px;

    .inital-hour-limit {
      height: 32px;
    }
  }

  .title {
    margin-top: 0;
  }

  .tab-switcher {
    md-radio-group md-radio-button {
      margin-right: 30px;
      margin-bottom: 15px;
    }
  }

  .switch-bar {
    margin: 18px 25px 0 0;
    float: left;

    label {
      padding-right: 20px;
    }
  }

  .rate-structure-section {
    margin-bottom: 18px;

    .title {
      margin-bottom: 20px;
    }
  }

  .rate-input-section {
    .title {
      margin-bottom: 25px;
    }
  }
}
/** Service rate section end here **/
@media screen and (max-width: 768px) {
  .rate-input-list li {
    .resType-sectionGap {
      margin-right: 25px;
    }
  }
}
@media screen and (min-width: 600px) {
  .service-detail-section {
    // &.label-hide .rate-input-list {
    //   md-input-container label:not(.md-no-float):not(.md-container-ignore) {
    //     display: none;
    //   }
    // }

    &.form-base {
      .input-sm {
        input,
        md-select {
          width: 100px;
        }
      }

      .input-180 {
        input,
        md-select {
          width: 180px;
        }
      }

      .input-md {
        input,
        md-select {
          width: 300px;
        }
      }
    }
  }

  .service-rateTab-section {
    .peak-season-section {
      .input-sm {
        margin-right: 20px;
      }
    }
  }

  // .rate-input-list {
  //   li {
  //     .res-name {
  //       margin-top: 10px;
  //     }
  //   }
  //
  //   .ellipsis {
  //     max-width: 200px;
  //   }
  // }
}
@media screen and (max-width: 600px) {
  .service-rateTab-section {
    .switch-bar {
      float: none;

      label {
        margin-bottom: 15px;
      }
    }
  }

  .rate-input-list {
    .gap-right-hard {
      margin-right: 0;
    }

    li {
      &.list-heading {
        display: none;
      }

      &:not(:last-of-type) {
        margin-bottom: 42px;
      }

      .res-name {
        margin-bottom: 15px;
      }
    }
  }
}

.organization-popup {
  min-height: 200px;
  max-width: 450px;

  md-dialog-content {
    padding: 15px;
  }
}

@media screen and (min-width: 600px) {
  // .service-detail-section .ui-accordion .left-section {
  //   width: 64%;
  //   float: left;
  // }

  .rate-input-list li .resType-cb {
    top: 12px;
  }
}

@media screen and (min-width: 375px) {
  .service-typeTab-section {
    .service-item-list.sub-list li {
      min-width: 315px;
    }
  }
}

@media screen and (max-width: 375px) {
  .service-typeTab-section {
    .service-item-list.sub-list li {
      max-width: 60%;
    }
  }
}
