// TODO: correct it later

.lead-collector {
  .detailstab {
    .name-bg {
      line-height: 35px;
      padding: 0 10px;
      display: inline-block;
      background-color: #EFEFEF;
    }
  }

  .qr-img {
    width: 150px;
    border: 1px solid rgb(223, 223, 223);

    .width-100 {
      width: 100%;
    }
  }

  .light-grey-box {
    width: 200px;
    height: 60px;
    background-color: rgb(251, 249, 249);
    border: 1px solid black;
  }

  .gradient-bg {
    width: 200px;
    height: 60px;
    border: 1px solid black;
  }

  .black-color-text {
    color: black;
    font-weight: 300;
  }

  .img-box {
    width: 150px;
    height: 80px;
    border: 1px solid black;
  }

  .li-landing-box {
    .land-box {
      max-height: 210px;
      margin-top: 10px;
    }
  }

  .padding-tb {
    padding: 15px 0;
  }

  .padding-bt-40 {
    padding-bottom: 40px;
  }

  .marginright {
    margin-right: 15px;
  }

  .border-box {
    border: 1px solid black;
  }

  .top-size-checkbtn {
    top: 8px;
  }

}
