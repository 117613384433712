@use "../settings/third-party/variables";

md-datepicker.hide-input-date-picker {
  position: relative;
}

.hide-input-date-picker {
  padding-right: 0;
  background: transparent;
}

.hide-input-date-picker .md-datepicker-input-container {
  margin: 0;
  padding: 0;

  .md-datepicker-input,
  .md-datepicker-triangle-button {
    display: none;
  }
}

.scroll-top {
  path {
    fill: variables.$brand-primary;
  }
}
// centered tabs

.dash-tabs.common-tabs md-tabs-canvas {
  height: auto;
}

.default-card-box md-select {
  width: 150px;
}

.dash-tabs.common-tabs:not(.no-style) {
  margin-top: 8px;
}

.dashboard.bar-chart {
  margin: 15px 12px 0 0;

  .function {
    margin-bottom: 30px;
  }

  .header {
    font-size: 24px;

    span {
      font-weight: bold;
    }
  }

  .info {
    color: variables.$gray;
    font-size: 16px;
    margin: 15px 0;
  }
}

.arrow-down,
.arrow-up {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  margin-right: 9px;
  display: inline-block;
}

.arrow-up {
  border-bottom: 10px solid variables.$brand-success-dark;
}

.arrow-down {
  border-top: 10px solid variables.$brand-danger-dark;
}

.progress-circular-holder {
  position: relative;
  margin-right: 9px;
  display: inline-block;
  vertical-align: middle;

  md-progress-circular {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border: 2px solid variables.$border-color;
      background: variables.$white;
      z-index: -1;
      -moz-border-radius: 100%;
      -webkit-border-radius: 100%;
      border-radius: 100%;
      background-clip: padding-box;
    }

    .md-inner {
      .md-right {
        .md-half-circle {
          border-top-color: variables.$brand-accent;
          border-right-color: variables.$brand-accent;
        }
      }

      .md-left {
        .md-half-circle {
          border-top-color: variables.$brand-accent;
          border-left-color: variables.$brand-accent;
        }
      }
    }
  }

  .progress-number {
    color: variables.$gray-darker;
    font-size: 7px;
    font-weight: 500;
    transform: translate(-50%, -50%);
    z-index: 10;
    font-weight: 500;
    position: absolute;
    top: 12px;
  }
}

.img-responsive {
  max-width: 100%;
}

.line-chart {
  margin-right: 12px;
}

.section-header {

  h2{
    font-size: 20px;
    // font-weight: 400;
  }
}

.dashboard.bisection {
  margin: 10px 12px 0 0;

  .border-purple {
    border: 1px solid variables.$mediumPurple;
    color: variables.$mediumPurple;
  }

  .border-danger {
    border: 1px solid variables.$brand-danger;
    color: variables.$brand-danger;
  }

  .default-box {
    .info-progress-circle {
      text-align: center;
      margin: 35px 0;
      padding: 35px 0;

      .circle {
        border-radius: 50%;
        display: inline-block;
        font-size: 30px;
        font-weight: bold;
        height: 100px;
        width: 100px;

        span {
          line-height: 100px;
        }
      }

      .circle-bottom-number {
        margin-top: 15px;
        font-size: 25px;
        font-weight: 500;
      }
    }

    .right-border {
      border-right: 1px solid variables.$gray-light;
    }
  }
}

.dashboard.statistics {
  margin: 16px 12px !important;
  list-style: none;

  li {
    display: inline-block;
    padding: 15px;
    text-align: center;
    margin: auto;
    min-width: 143px;
    min-height: 115px;

    .stat {
      font-size: 19px;
      font-weight: 300;
      margin: 10px 0;

      &.active {
        font-weight: 600;
      }
    }

    .header {
      font-size: 12px;
      font-weight: 100;
    }

    .header-lg {
      font-size: 14px;
      font-weight: 200;
    }

    .info-lg {
      font-size: 14px;
      font-weight: 400px;
    }

    .info-sm {
      font-size: 10px;
      font-weight: 200;
    }

    .info-md {
      font-size: 12px;
      font-weight: 400;
    }

    .info-warning {
      color: variables.$brand-danger;
    }

    .info-normal {
      color: variables.$gray;
    }
  }
}
