@use "../../settings/third-party/mixins";
@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.status-dropmenu.uploadDropmenu {
  padding-top: 12px;
  padding-bottom: 12px;

  &:before {
    border-width: 9px;
    margin-right: 46px;
  }

  &:after {
    border-width: 8px;
    margin-right: 47.5px;
  }

  md-menu-item {
    height: 30px;
    min-height: 30px;

    &:hover {
      background: variables.$brand-accent;
      color: variables.$white;
    }
  }

  .md-button {
    line-height: 30px;
    min-height: 30px;

    p {
      font-size: variables.$font-size-small;
    }
  }
}

.image-upload-section {
  padding: 20px;

  .status-displayer {
    margin-top: 0;
    margin-bottom: 18px;

    .main-holder {
      &:after,
      &:before {
        top: 30%;
      }

      &.active {
        .progress-circular-holder {
          &:after {
            border-color: #dbdbdb;
          }
        }
      }

      .displayer-title {
        margin-top: 10px;
      }
    }

    .progress-circular-holder {
      height: 50px;
      width: 50px;

      md-progress-circular {
        height: 43px !important;
        width: 43px !important;

        .md-inner {
          height: 88px;
          width: 88px;

          .md-gap {
            left: 44px;
            right: 44px;
          }

          .md-left,
          .md-right {
            height: 88px;
            width: 43px;

            .md-half-circle {
              width: 88px;
              height: 88px;
            }
          }
        }
      }

      .progress-number {
        font-size: 16px;
      }
    }
  }

  .f9 {
    color: variables.$gray-darker;
    font-size: variables.$font-size-small;
  }

  .coupon-list {
    @extend .no-style;

    li {
      @include mixins.border-radius(5px);

      border: 1px solid variables.$gray-lighter;
      display: inline-block;
      margin-bottom: 10px;
      margin-right: 10px;
      padding: 8px 10px 20px;
      position: relative;
      width: 23.7%;
      max-width: 100%;
    }

    a {
      margin-left: 8px;
    }

    .product-img {
      display: block;
      height: 110px;
      width: 110px;
      margin: 10px auto 22px;
    }

    .bottom-gap-sm {
      margin-bottom: 8px;
    }

    .bottom-gap-md {
      margin-bottom: 20px;
    }

    .menu-btn {
      position: absolute;
      top: 8px;
      right: 2px;
      z-index: 2;

      &:before {
        color: variables.$gray-lighter;
        -webkit-transform: rotate(90deg) scale(1.7, 1.1);
        -moz-transform: rotate(90deg) scale(1.7, 1.1);
        transform: rotate(90deg) scale(1.7, 1.1);
      }
    }
  }

  .custom-tabs {
    md-content {
      padding-top: 25px;
    }
  }

  .col-2-table {
    padding-top: 0;
    min-width: 825px;

    md-radio-button {
      margin-bottom: 0;
    }

    .table-header {
      margin-top: 4px;

      md-radio-button {
        @extend .f4;

        margin-left: 15px;
      }
    }

    .data-table {
      @include mixins.border-radius(4px);

      border: 1px solid #c1cbcd;
      min-width: auto;

      tr:last-child td {
        border-bottom: none;
      }

      td {
        border-bottom: 1px solid #c1cbcd;
        font-size: 10px;
        line-height: 16px;
        vertical-align: middle;
        width: 50%;

        &.highlight-cell {
          background: variables.$brand-table;
        }

        &:last-child {
          border-left: 1px solid #c1cbcd;
        }
      }

      .product-img-holder {
        margin-left: -18px;

        .inner-cell {
          @include mixins.border-radius(2px);

          border: 1px solid variables.$gray-lighter;
          background: variables.$white;
          padding: 5px 10px;

          &:first-child {
            margin-right: 18px;
          }

          img {
            height: 28px;
            margin-right: 10px;
            width: 28px;
          }
        }

        .icon-button {
          height: auto;
          min-height: auto;
          margin-right: -8px;
          width: auto;

          md-icon {
            height: 14px;
            width: 14px;
          }
        }
      }

      // .product-img,
      // .product-no-img {
      // 	margin-right: 20px;
      // }

      // md-radio-button {
      // 	margin-bottom: 0;

      // 	.md-label {
      // 		margin-left: 20px;
      // 	}
      // }

      // .product-img {
      // 	width: 62px;
      // 	height: 40px;
      // }

      // .product-no-img {
      // 	background: $white;
      // 	height: 55px;
      // 	width: 65px;

      // 	&.small-pic {
      // 		height: 38px;
      // 	}

      // 	md-icon {
      // 		height: 34px;
      // 		margin-top: 8px;
      // 		width: 34px;
      // 	}
      // }

      // .product-id {
      // 	font-size: $font-size-base;
      // 	font-weight: 500;
      // 	margin: 0;
      // 	margin-bottom: 10px;
      // }

      // .icon-edit {
      //     height: 12px;
      //     margin-top: 0;
      //     margin-left: 8px;
      //     width: 12px;
      // }

      // .common-gap {
      // 	min-width: 55px;
      // 	margin-right: 10px;
      // }

      // .spec-lbl {
      // 	color: $gray;
      // 	margin-right: 8px;
      // 	padding-right: 0;
      // }
    }
  }
}

.image-upload-sidebar {
  .leftnav-wrap {
    position: relative;

    .bottom-section,
    .task-wrapper {
      position: absolute;
      right: 0;
      left: 0;
    }
  }

  .task-wrapper {
    top: 0;
    bottom: 50px;
    overflow-y: auto;
    padding-top: 20px;
  }

  .bottom-section {
    bottom: 1px;
  }

  .cb-options,
  .cb-options-note,
  .upper-section {
    margin-right: 20px;
    margin-left: 20px;
  }

  .upper-section {
    margin-bottom: 30px;

    .sidebar-title {
      color: variables.$gray-darker;
      font-weight: 500;
      font-size: variables.$font-size-base;
      margin-top: 0;
      margin-bottom: 4px;
    }

    md-input-container {
      margin-bottom: 10px;

      label {
        font-size: variables.$font-size-small;
      }
    }
  }

  .cb-options {
    @extend .no-style;
    margin-top: 20px;

    li {
      margin-bottom: 4px;
    }

    md-checkbox .md-container {
      background: variables.$white;
    }
  }

  .cb-options-note {
    color: variables.$gray;
    font-size: 10px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .search-list {
    li {
      padding-right: 20px;
      padding-left: 20px;
      padding-bottom: 0;
    }

    md-divider {
      margin-top: 10px;
      margin-right: -20px;
      margin-left: -20px;
    }
  }
}

@media screen and (max-width: 768px) {
  .image-upload-section {
    .coupon-list li {
      width: 30.5%;
    }
  }
}

@media screen and (max-width: 600px) {
  .image-upload-section {
    .coupon-list li {
      width: 100%;
      margin-right: 0;
    }
  }
}
