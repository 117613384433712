.client-tech-tracking {
  .custom-default-box.body-container {
    background-color: #e3f5f5;
    border-bottom: 3px solid #56c0beff;
    padding: 10px 20px;
  }

  .profile-detail {
    width: 100%;
  }

  .top-banner {
    .top-description {
      max-width: 300px;
      width: 100%;
      color: #212121;
      font-size: 16px;
    }

    .customer-name {
      margin-left: 0;
      margin-bottom: 10px;
      color: #197472;
      font-size: 24px;
    }

    .assign-date {
      margin: 0 0 10px;
      color: #212121;
      font-size: 16px;
      text-align: end;
    }

    .logo-img-holder img {
      width: 135px;
      height: auto;
    }

    a {
      color: #212121;
      font-size: 14px;

    }

    a.email {
      margin-top: 5px;
    }
    // .icon_png{
    //     width: 20px;
    // }
  }

  .cta-button {
    border: 2px solid #f46b25;
    max-width: 250px;
    width: 100%;
    padding: 12px 10px;
    background-color: #ffffff;

    span {
      color: #21374c;
      font-size: 16px;
      font-weight: 600;
    }

    &:hover {
      background-color: #f46b25;

      span {
        color: #ffffff;
      }
    }
  }

  .custom-body-box {
    padding: 40px 20px;

    .customer-card-heading {
      .heading {

        margin-bottom: 10px;
        color: #197472;
        font-size: 22px;
        font-weight: 600;
      }
    }

    .customer-card-heading .right-heading {
      span {
        font-size: 16px;
      }

      .new_icon_png.icon_png {
        width: 40px;
        height: 40px;
      }

      .icon_png {
        width: 20px;
        height: 20px;
      }
    }

    .customer-card-body {
      box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
      padding: 40px 20px;

      .avatar_icon_png {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        margin-right: 20px;
      }

      .top-banner-section {
        line-height: 1.6;
      }

      .client-name {
        font-weight: 600;
        color: #21374c;
        font-size: 17px;
      }

      .position-field {
        font-size: 14px;
        color: #56c0be;
      }

      .client-details {
        font-size: 18px;
        color: #21374c;
      }

      .cta-button {
        max-width: 180px;
      }

      .flat_icon_border {
        border: 1px solid #56c0be;
        background-color: #56c0be29;
        border-radius: 10px;
        padding: 10px;
        max-width: 55px;
        width: 100%;
        margin-right: 10px;
        margin-top: 20px;

        .avatar_icon_png {
          width: 22px;
          height: 22px;
          margin: 0 auto;
          text-align: center;
          border-radius: 0;
        }
      }
    }
  }

  .icon_png {
    width: 20px;
  }

  .tracking-section {
    .customer-card-body .client-details {
      font-size: 16px;
    }

  }
}

.first_cell {
  width: 100%;
}

.divider {
  border: 1px solid #dce7ee;
  width: 75%;
  margin: 12px auto 20px;
}

.progress-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 720px;
  position: relative;
}

.points {
  width: 18px;
  height: 18px;
  padding: 7px;
  background-color: transparent;
  border-radius: 50px;
  margin: 5px;
}

.start-point {
  border: 4px solid #56c0beff;

}

.end-point {
  border: 4px solid #b0bfc7ff;
}

.slider-wrapper {
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
}

.progress-bar {
  width: 100%;
  //-webkit-appearance: none;
  height: 8px;
  background: #d3d3d3;
  border-radius: 5px;
  position: relative;
}

/* Styling for Slider Thumb */
.progress-bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background: transparent;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid transparent;
}

/* Play Button Styling */
.play-button {
  position: absolute;
  top: -6px;
  transform: translateX(-11px);
  width: 24px;
  height: 24px;
  background: #41b6e6;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  border: none;
  transition: left 0.1s linear;
  /* Smooth movement */
}

.width-full {
  width: 100%!important;
}

.mr-10 {
  margin-right: 10px!important;
}
