@use "../../settings/style-guide";

.main-header {
  .data-request-btns {
    width: 150px;
  }
}

.data-section {
  .last-update-msg {
    top: 14px;
    right: 8px;
  }

  .bottom-gap-xxs {
    margin-bottom: 5px;
  }

  .bottom-gap-gt-xxs {
    margin-bottom: 10px;
  }

  .bottom-gap-xs {
    margin-bottom: 20px;
  }

  .bottom-gap-sm {
    margin-bottom: 30px;
  }

  .bottom-gap-md {
    margin-bottom: 45px;
  }

  .custom-tabs {
    md-content {
      padding-top: 30px;
    }
  }

  .datepicker-custom {
    margin-bottom: 20px;
  }

  .big-btn {
    margin-top: 2px;
    margin-left: 0;
    min-width: 155px;
    margin-bottom: 25px;
  }

  .icon-tick {
    height: 21px;
    margin-left: 10px;
  }

  .icon-clock {
    height: 21px;
    margin-right: 10px;

    .icon-bg {
      fill: transparent;
    }
  }

  .data-table {
    margin-top: 20px;
    min-width: 780px;
    margin: 1px 2% 1px 0;

    td {
      @extend .f3;
    }
  }
}

@media screen and (min-width: 600px) {
  .data-section {
    .gap-right {
      margin-right: 20px;
    }
  }
}
