@use "../../settings/third-party/variables";
@use "../../settings/style-guide";
@use "../../settings/custom";

.inventory-products {
  .inventory-list-section {
    padding: 20px;

    .menu-options {
      md-input-container {
        margin-right: 20px;
        margin-bottom: 10px;
      }
    }

    .inventory-table {
      .data-table {
        min-width: 780px;

        td {
          font-size: variables.$font-size-small;
          // a {
          //   display     : inline-block;
          //   margin-right: 2px;
          // }
          .small-gray {
            font-size: 10px;
            color: variables.$gray;
            line-height: 1.5;
          }

          .set-list {
            position: relative;
            padding-left: 25px;

            md-icon {
              position: absolute;
              top: 5px;
              left: -3px;
              margin: 0;
              // height: 12px;
              // width: 12px;
            }
          }

          .location-symbol {
            // padding: 0 6px;
            // min-width    : 22px;
            margin-bottom: 4px;
          }

          &.last-update-col {
            font-size: 10px;
            line-height: 1.5;
          }

          .stock-location {
            // @extend .text-right;
            display: inline-block;

            .more-link {
              text-align: left;
              margin-left: 8px;

              a {
                font-size: 10px;
                margin: 0;
              }
            }
          }
        }

        .active {
          color: variables.$status-green;
        }

        .new {}

        .disabled {
          color: variables.$status-gray;

        }

        .archived {
          color: variables.$status-yellow;
        }

        .deleted {}

        .draft {
          color: variables.$status-gray;
        }

        .expired {
          color: variables.$status-red;
        }
      }
    }
  }

  .product-dropdown {
    @extend .table-menu;

    md-menu-item {
      height: auto;
      min-height: 0;

      &.menu-header {
        // height: 35px;
        font-size: variables.$font-size-small;
        font-weight: 500;
        color: variables.$gray-dark;
      }

      a {
        display: inline-block;
        margin-right: 5px;
      }

      .stock-location {
        @extend .text-right;
        display: inline-block;
        padding: 10px 0;
      }

      .location-symbol {
        padding: 0 6px;
        min-width: 22px;
        margin-bottom: 4px;
        margin-left: 4px;
        margin-bottom: 4px;
      }
    }

    .product-table-list {
      padding: 0 10px;

      .arrow-icon {
        height: 15px;
      }

      .img-wrap {
        margin-top: 15px;
      }

      .product-img {
        max-width: 50px;
        max-height: 35px;
        margin-right: 10px;
      }

      ul {
        margin-top: 10px;

        li {
          margin-bottom: 7px;
          font-size: 10px;

          span {
            font-size: 10px;

            &.f4 {
              margin-right: 8px;
            }
          }
        }
      }
    }

    &.small-menu {
      min-width: 90px;
      width: 90px;

      &:before {
        margin-right: 30px;
      }

      &:after {
        margin-right: 31px;
      }
    }
  }

  .variation-sidenav {
    .availability {
      background-color: #e9f0f3;

      .available-heading {
        margin: 0 8px;
      }

      .col {
        min-width: 85px;
        max-width: 85px;
      }
      @media screen and (min-width: 320px) and (max-width: 370px) {
        .col {
          min-width: 60px;
          max-width: 85px;
        }
      }
    }

  }

  .policy-stats {
    .img-width {
      height: 40px;
      width: 40px;
      font-size: variables.$font-size-small;
      line-height: 40px;
    }

    .committed-border,
    .internal-border,
    .success-border,
    .warranty-border {
      background-color: variables.$white;
    }

    .success-border {
      border: 1px solid #6aa84f;

      .text-color {
        color: #6aa84f;
      }
    }

    .success-border:hover {
      background-color: #6aa84f;
    }

    .warranty-border {
      border: 1px solid #e69138;

      .text-color {
        color: #e69138;
      }
    }

    .warranty-border:hover {
      background-color: #e69138;
    }

    .committed-border {
      border: 1px solid #e06666;

      .text-color {
        color: #e06666;
      }
    }

    .committed-border:hover,
    .internal-border:hover,
    .success-border:hover,
    .warranty-border:hover {
      border: 1px solid #e2dede;

      .text-color {
        color: variables.$white;
      }
    }

    .committed-border:hover {
      background-color: #e06666;
    }

    .internal-border {
      border: 1px solid #c55c76;

      .text-color {
        color: #c55c76;
      }
    }

    .internal-border:hover {
      background-color: #c55c76;
    }

  }

}
