@use "../../settings/third-party/mixins";
@use "../../settings/third-party/variables";
@use "../../settings/style-guide";
@use "../../settings/custom";

.status-dropmenu {
  &.select-location-dropmenu {
    &:before {
      border-width: 8px;
    }

    &:after {
      border-width: 7px;
    }

    &:after,
    &:before {
      right: 0;
      left: 50%;
      transform: translate(-50%);
    }

    md-menu-item {
      &:not(:last-child) {
        border-bottom: 1px solid variables.$border-color;
      }

      .md-button {
        font-size: variables.$font-size-small;
      }
    }
  }
}
/* The Modal (background) */
.map-modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 50px;
  /* Location of the box */
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0,0,0);
  /* Fallback color */
  background-color: rgba(0,0,0,0.4);
  /* Black w/ opacity */
  /* Modal Content */
  .map-modal-content {
    color: #fefefe;
    margin: auto;
    padding: 20px;
    font-size: 20px;
    text-align: center;
    width: 80%;
  }
}

.gray-location {
  md-icon {
    svg {
      path {
        fill: variables.$gray-light;
      }
    }
  }
}

.orange-location {
  md-icon {
    svg {
      path {
        fill: variables.$brand-primary;
      }
    }
  }
}

.resource-detail-section {

  // Google Sync Page
  .accordion-space {
    padding: 20px;

    .top-space {
      margin-top: 20px;
    }

    .switch-bar {
      margin-bottom: 20px;
    }

    .switch-text-space {
      margin-left: 20px;
    }

    ul li {
      display: inline-block;
      margin-right: 10px;
    }
  }
  // Google Sync Page

  > md-content {
    padding: 20px;
  }

  .password-generate {
    margin-bottom: 15px;

    .left-space {
      margin-left: 20px;
    }
  }

  .datapicker-gap {
    margin-top: 10px;
  }

  .status-displayer {
    margin-bottom: 0;
  }

  // .form-base md-input-container {
  //   input,
  //   label,
  //   md-select {
  //     font-size: $font-size-small;
  //   }
  // }

  .status-displayer .main-holder:after,
  .status-displayer .main-holder:before {
    border-color: variables.$brand-accent;
    border-width: 1px;
  }
  // .status-displayer .progress-circular-holder md-progress-circular {
  //   transform: rotate(-97deg);
  // }
  .status-displayer .progress-circular-holder md-progress-circular .md-inner .md-left,
  .status-displayer .progress-circular-holder md-progress-circular .md-inner .md-right {
    width: 64px;
  }

  .secondary-link {
    display: inline-block;
    margin-top: 14px;
  }

  .email-status {
    height: 20px;
    width: 20px;
  }

  .send-btn {
    @extend .btn-primary;
    display: inline-block;
    min-width: 50px;
    min-height: 30px;
    line-height: 30px;
    margin: 0 10px 0 0;
  }

  .last-login-status {
    display: inline-block;
    margin-top: 5px;
  }

  .type-of-list {
    position: absolute;
    top: 25px;
    right: 0;
    z-index: 32;

    md-icon {
      height: 15px;
      width: 15px;
    }

    label {
      padding-right: 12px;
      padding-left: 5px;
    }
  }

  .year-input-container {
    width: 50px;
    margin: 0;
    padding-left: 10px;
  }

  .task-wrapper {
    .task-list {
      position: relative;

      md-list-item {
        p {
          padding-right: 60px;
        }
      }
    }
  }

  .distance-lbl {
    position: absolute;
    right: 60px;
    top: 5px;
    @extend .f4;
    font-size: 9px;
  }

  .distance-data {
    position: absolute;
    right: 60px;
    top: 20px;
    @extend .f3;
  }

  .ar-sidepanel {
    .sidenav-title h2 {
      margin-left: 15px;
    }

    .distant-txt {
      font-size: 10px;
    }

    .schedule-list {
      width: 100%;
      min-width: 0;

      li a {
        color: variables.$brand-accent;
        display: inline-block;
        min-width: 100px;
      }
    }
  }

  .resource-detail-tab {
    @extend .custom-tabs;

    .total-tasks-title {
      font-size: variables.$font-size-large;
    }

    md-tabs-content-wrapper {
      .common-tabs {
        &:not(.no-style) {
          margin-top: -25px;
        }

        md-tabs-wrapper {
          border: 0;

          md-tab-item {
            color: variables.$white;
            padding: 1px 20px;
            // extra CSS
            background-color: variables.$brand-primary;
            display: inline-block;
            overflow: hidden;
            border: 1px solid variables.$brand-primary;

            &:first-of-type {
              border-radius: 5px 0 0 5px;
            }

            &:last-of-type {
              border-radius: 0 5px 5px 0;
            }

            &.md-active {
              background-color: variables.$white;
            }
          }
        }
      }
    }
    margin-top: 20px;

    .table-filter {
      height: 25px;
      // z-index: 30;

      md-icon {
        height: 15px;
        width: 15px;
      }

      label {
        padding-right: 12px;
        padding-left: 5px;
      }

      md-datepicker,
      md-datepicker.md-default-theme {
        background: transparent;

        .md-datepicker-input-container {
          border: 0;
          margin: 0;
          padding-left: 3px;
          padding-bottom: 0;

          input {
            @extend .f1;
            min-width: 120px;
            max-width: 120px;
          }

          input::-webkit-input-placeholder {
            color: variables.$gray-dark !important;
            font-weight: 500;
          }

          input:-moz-placeholder {
            /* Firefox 18- */
            color: variables.$gray-dark !important;
            font-weight: 500;
          }

          input::-moz-placeholder {
            /* Firefox 19+ */
            color: variables.$gray-dark !important;
            font-weight: 500;
          }

          input:-ms-input-placeholder {
            color: variables.$gray-dark !important;
            font-weight: 500;
          }
        }

        // .md-datepicker-calendar-icon {
        //   svg {
        //     path {
        //       fill: #828282;
        //     }
        //   }
        // }

        .md-datepicker-triangle-button {
          display: none;
        }

        .md-datepicker-button {
          margin: 0;
          position: absolute;
          right: 0;
          top: -11px;
          z-index: 1;
          padding-right: 0;

          md-icon {
            height: 22px;
            width: 22px;
          }
        }
      }
    }

    md-tab-content {
      padding-top: 20px;

      md-content label {
        padding: initial;
      }
    }

    md-tab-item {
      md-icon {
        width: 17px;
        height: 17px;
      }
    }

  }

  .assign-table {
    overflow: auto;

    td:first-child,
    th:first-child {
      padding-left: 30px;
    }

    td {
      padding-bottom: 10px;

      .overlap-time {
        margin-top: 12px;
        position: relative;

        label {
          position: absolute;
          top: 9px;
          left: -2px;
          text-align: center;
        }
      }

      a {
        font-size: variables.$font-size-small;
      }
    }

    .location-selector {
      @extend .f8;
      position: absolute;
      top: 133px;
      left: 228px;

      &:after {
        content: '>';
        cursor: pointer;
        color: variables.$border-color;
        display: inline-block;
        font-size: 22px;
        transform: scale(1.4, 0.8) rotate(90deg);
        position: absolute;
        right: -20px;
      }
    }

    .active-scale {
      td {
        border-bottom: 0;
      }
    }

    .d-timeline {
      display: inline-block;
      @include mixins.border-radius(5px);
      border: 1px solid variables.$border-color;
      margin-left: -15px;
      overflow: hidden;
      position: relative;

      table td {
        border-bottom: none;
        padding: 10px;
      }

      .assigned-slot {
        font-size: 10px;

        label {
          display: inline-block;
          position: absolute;
          text-align: center;

          &.trackline-u {
            top: 40px;
          }

          &.trackline-a {
            top: 10px;
          }

          &.seal {
            &:after {
              background: #25D8E5;
            }
          }

          &.purple {
            &:after {
              background: #AE7FE5;
            }
          }

          &:after,
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            left: 0;
            bottom: -1px;
          }

          &:before {
            background: url("#{variables.$image-path}/icons/set-h/ico-car-point.svg") no-repeat left bottom;
            background-size: 16px;
            height: 30px;
            width: 30px;
            z-index: 1;
            left: -2px;
          }

          &:after {
            background: red;
            right: 0;
            height: 5px;
            border-radius: 5px;
          }
        }
      }

      td {
        vertical-align: bottom;
        font-size: 10px;
      }

      .h-time-list {
        font-size: 10px;
        border-top: solid 1px variables.$border-color;
        margin-top: 70px;
        white-space: nowrap;

        li {
          position: relative;
          text-align: center;
          display: inline-block;
          vertical-align: top;
          width: 20px;

          &:after,
          &:before {
            content: '';
            position: absolute;
            width: 1px;
            background: variables.$border-color;
          }

          &:before {
            left: 9px;
            top: -12px;
            height: 12px;
          }

          &:after {
            right: -1px;
            top: -7px;
            height: 7px;
          }

          &:last-child {
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }

  .profile-section {
    .accordion {
      margin: 0;
    }

    .profile-payouts {
      padding: 20px;
    }

    .title-section {
      margin-bottom: 23px;
      font-size: variables.$font-size-base;
      color: variables.$gray;
    }
  }

  .wage-setup-section {
    padding-right: 20px;

    h4 {
      margin: 0;
      @extend .f3;
      font-weight: normal;

      &.space-top {
        margin-top: 12px;
      }
    }

    .table-responsive {
      margin-bottom: 20px;
    }

    .data-table {
      min-width: 500px;

      td {
        position: relative;
        padding-bottom: 30px;

        .floating-text {
          @extend .f4;
          font-size: 10px;
          position: absolute;
          bottom: 10px;
          left: 14px;
          display: inline-block;
          width: 300px;
          @extend .ellipsis;
        }
      }
    }

    .wage-inputs {
      h4 {
        margin-bottom: 8px;
      }

      .datepicker-custom {
        margin-bottom: 10px;
        max-width: 300px;

        .md-datepicker-input-container {
          height: 29px;

          input {
            border: none;
            padding-top: 7px;
            height: 30px;
            font-size: variables.$font-size-small;
            width: 100%;
            padding-left: 4px;
          }
        }

        .md-datepicker-button {
          top: -5px;
        }
      }

      md-input-container {
        margin-top: 0;
        margin-bottom: 10px;
        max-width: 300px;

        label {
          color: variables.$gray;
        }
      }
    }
  }

  .label-checkbox,
  .label-radio {
    position: relative;
    margin: 0.5rem;
    line-height: 135%;
    cursor: pointer;
  }

  .custom-checkbox {
    position: relative;
    top: 0.025rem;
    margin: 0 1rem 0 0;
    cursor: pointer;
  }

  .custom-checkbox:before {
    -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    -moz-transition: -moz-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    -webkit-transform: rotate(-45deg) scale(0, 0);
    -moz-transform: rotate(-45deg) scale(0, 0);
    -ms-transform: rotate(-45deg) scale(0, 0);
    -o-transform: rotate(-45deg) scale(0, 0);
    transform: rotate(-45deg) scale(0, 0);
    content: "";
    position: absolute;
    left: 0.2875rem;
    top: 0.225rem;
    z-index: 1;
    width: 11px;
    height: 5px;
    border: 2px solid variables.$brand-accent;
    border-top-style: none;
    border-right-style: none;
  }

  .custom-checkbox:checked:before {
    -webkit-transform: rotate(-45deg) scale(1, 1);
    -moz-transform: rotate(-45deg) scale(1, 1);
    -ms-transform: rotate(-45deg) scale(1, 1);
    -o-transform: rotate(-45deg) scale(1, 1);
    transform: rotate(-45deg) scale(1, 1);
  }

  .custom-checkbox:after {
    content: "";
    position: absolute;
    top: -0.125rem;
    left: 0;
    width: 19px;
    height: 19px;
    border-radius: 3px;
    background: #fff;
    border: 1px solid variables.$gray-light;
    cursor: pointer;
  }

  .custom-radio {
    position: relative;
    margin: 0 1rem 0 0;
    cursor: pointer;
    top: 1px;
  }

  .custom-radio:before {
    -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    -moz-transition: -moz-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
    content: "";
    position: absolute;
    top: 2.65px;
    left: 4.65px;
    z-index: 1;
    width: 10px;
    height: 10px;
    background: variables.$brand-accent;
    border-radius: 50%;
  }

  .custom-radio:checked:before {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .custom-radio:after {
    content: "";
    position: absolute;
    top: -0.25rem;
    left: -0.125rem;
    width: 20px;
    height: 20px;
    background: #fff;
    border: 2px solid variables.$white-dark;
    border-radius: 50%;
  }
}

.toggle-list {
  margin-left: 16px;
  margin-right: 17px;

  md-list-item {
    align-items: flex-start;
    line-height: 1.7;
  }

  .gap-left {
    display: inline-block;
    margin-left: 10px;
  }

  .gap-right {
    display: inline-block;
    margin-right: 10px;
  }

  .mul-items {
    @extend .no-style;

    li {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      label {
        min-width: 45px;
        margin-right: 2px;

        &.space-lbl {
          margin-left: 10px;
          min-width: 30px;
        }
      }

      .pass-field {
        margin-top: 12px;
      }
    }

    .secnd-line-txt {
      padding-left: 49px;
    }

    &.large-label {
      li {
        label {
          min-width: 120px;
          margin-right: 5px;
        }
      }
    }
  }
}

.resource-detail-section .resource-detail-tab md-tab-content md-input-container label.select-mark {
  position: absolute;
  top: -15px;
  right: 5px;
  padding: 0;
  left: inherit;
  color: variables.$gray-darker;
}

.resource-detail-section .resource-detail-tab md-tab-content .summery-widget md-input-container label.select-mark {
  top: -23px;
  right: 7px;
}

.activiti-tab {
  .reporting-structure-table {
    .user-status .status-light {
      right: 5px;
    }
  }

  .data-table {
    min-width: 580px;

    td,
    th {
      @extend .f3;
      padding: 15px;

      md-icon {
        height: 16px;
        width: 16px;
        margin-right: 5px;

        &.sort-icon {
          height: 12px;
          margin-right: 0;
          width: 12px;
        }

        &.timer {
          height: 18px;
          width: 18px;

          svg {
            path {
              fill: variables.$gray-light;
            }
          }
        }
      }
    }
  }
}

.resource-detail-section .resource-detail-tab md-tab-content .wage-inputs md-input-container label {
  padding-left: 5px;
}
/** Organization-edit-section start **/
.resource-detail-section {
  .organization-edit-section {
    .bottom-gap {
      margin-bottom: 20px;
    }

    .md-button.md-icon-button {
      &.add-btn {
        // margin: 5px 4px 15px 0;
        // height: auto;
        min-height: auto;
        padding: 0;
        width: auto;

        md-icon {
          height: 21px;
          width: 21px;
        }
      }
    }

    .add-btn-label {
      color: variables.$gray-dark;
    }

    .form-base {
      margin-top: -8px;
      margin-bottom: 0;
    }

    .organization-edit-inputs {
      //margin-bottom: 10px;
      &.extra-gap {
        margin-bottom: 30px;
        margin-top: 15px;
      }

      md-input-container {
        margin-bottom: 20px;
        //max-width: 250px;
      }
    }

    .radio-btn-tabs {
      margin-top: 3px;
      margin-bottom: 25px;
    }

    .datepicker-custom {
      margin-bottom: 20px;
      border-bottom: 1px solid variables.$brand-table;
      //        .md-datepicker-input-container {
      //            input {
      //                border: none;
      //                padding-top: 7px;
      //                height: 38px;
      //                font-size: $font-size-small;
      //                width: 100%;
      //                padding-left: 4px;
      // max-width: none;
      //            }
      //        }
      // .md-datepicker-button {
      //   top: -5px;
      // }
    }

    .section-label {
      color: variables.$gray-darker;
      margin-top: 0;
      margin-bottom: 10px;
    }

    .work-hour-list {
      margin-top: 10px;

      li {
        margin-bottom: 8px;

        label.day-name {
          @extend .f3;
          min-width: 85px;
        }

        .apply-lbl {
          cursor: pointer;
        }

        .time-selector {
          @extend .PosR;
          margin-right: 16px;

          h3 {
            color: variables.$gray;
            font-size: 10px;
            position: absolute;
            top: -22px;
            left: 0;
            right: 0;
          }
        }

        md-input-container {
          margin-right: 10px;
          margin-bottom: 4px;

          md-select {
            min-width: 100px;
          }

          &.selected {
            .custom-select {
              color: variables.$gray;
            }
          }

          .custom-select {
            color: variables.$gray-dark;
            width: 55px;
          }

          label.select-mark {
            top: -21px;
          }
        }
      }
    }
  }
}

.assign-nav {
  .top-section {
    .sidenav-select {
      min-width: 170px;
    }

    .assign-type {
      margin: 3px 15px;

      md-radio-button {
        font-size: variables.$font-size-small-plus;
      }
    }
  }

  .task-wrapper {
    top: 135px;
  }
}

.ico-add {
  width: 20px;
  height: 20px;
  margin: 13px 8px;

  &.add-emr-btn {
    margin: -2px 8px 0 0;
  }
}

.profile-edit {
  padding: 20px;

  label.select-mark {
    top: -18px !important;
    right: 8px !important;
    color: variables.$gray-light !important;
  }

  .gender-title {
    @extend .f4;
    padding-left: 3px;
  }

  .ico-add {
    &.add-emr-btn {
      margin-bottom: 20px;
    }

    &.add-ext-btn {
      margin: 13px 0 13px 8px;
      position: relative;
      top: -8px;
    }
  }

  .small-input {
    width: 100%;
    max-width: 60px;
    margin-left: 8px;
  }

  .med-input {
    width: 103px;
    margin-right: 7px;
  }

  md-input-container {
    margin: 0 0 20px;

    &.gap-right {
      margin-right: 15px;
    }

    &.width-limit {
      width: 100%;
      max-width: 300px;
    }
  }

  .radio-btn-tabs {
    margin-top: 15px;
    margin-left: 2px;

    .label-radio {
      margin-left: 0;
      margin-right: 20px;
    }
  }

  .datepicker-custom {
    // max-width: 230px;
    // margin-top: 20px;
    // margin-bottom: 15px;
    .md-datepicker-input-container {
      // height: 30px;
      margin-bottom: 20px;
      //         input {
      //             padding-top: 6px;
      //             font-size: $font-size-small;
      //             padding-left: 3px;
      //         }
      //     }
      //     .md-datepicker-button {
      //         top: -4px;
      //         padding: 0;
    }
  }
  // .input-textarea {
  //     margin-top: 10px;
  //     md-input-container {
  //         .md-input {
  //             min-height: 62px;
  //         }
  //     }
  // }
}

.login-edit {
  padding: 20px;

  .toggle-list {
    margin: 0 !important;
    padding: 0 !important;
  }

  .reset-password {
    background: variables.$white-dark;
    width: 95%;
    width: calc(100% - 30px);
    padding: 0 15px 15px;
    margin-bottom: 15px;

    h3 {
      font-size: variables.$font-size-small;
      margin: 0;
      font-weight: 500;
      margin-top: 15px;
      padding-left: 2px;
    }

    md-input-container {
      max-width: 330px;
      margin: 15px 0;

      label {
        padding-left: 2px !important;
      }
    }

    .md-button {
      margin: 0;
      min-width: 67px;
      min-height: 26px;
      line-height: 26px;
      margin-right: 12px;
      font-size: variables.$font-size-small;
    }

    .btn-secondary {
      background: variables.$gray-lighter;

      &:hover {
        background: variables.$gray-light;
      }
    }
  }
}

.status-edit {
  padding: 20px;

  .toggle-list {
    margin: 0 !important;
    padding: 0 !important;
  }

  .edit-section {
    h4 {
      font-size: variables.$font-size-small;
      font-weight: 500;
      margin-top: 0;
      margin-bottom: 8px;
      padding-left: 1px;
    }

    .custom-select {
      padding-bottom: 4px;
      margin-right: 15px;
      padding-left: 0;
    }

    md-input-container {
      margin-right: 15px;
      margin-top: 0;
      margin-bottom: 10px;
      max-width: 135px;

      &.no-marg {
        margin: 0;
      }
    }

    label.select-mark {
      top: -18px !important;
      right: 8px !important;
      color: variables.$gray-light !important;
    }

    .gap-bottom {
      margin-bottom: 25px;
    }
  }
}
/** Organization-edit-section end **/
@media screen and (max-width: 768px) {
  .resource-detail-section .resource-detail-tab md-tabs-content-wrapper .common-tabs:not(.no-style) {
    margin-top: 16px;
  }

  .resource-detail-section md-tabs.md-dynamic-height md-tabs-content-wrapper .tabs-container {
    margin-top: 20px;
  }

  .resource-detail-section .type-of-list {
    top: 60px;
  }

  .resource-detail-section .send-btn {
    margin-top: 5px;
  }
}
@media screen and (min-width: 600px) {
  .organization-edit-inputs,
  .profile-edit {
    max-width: 45%;
  }
}
@media screen and (max-width: 600px) {
  .status-edit .edit-section md-input-container {
    max-width: none;
  }
  // .resource-detail-section {
  //     .organization-edit-section {
  //         .radio-btn-tabs {
  //             .label-radio {
  //                 display: block;
  //                 margin-bottom: 15px;
  //                 margin-left: 0;
  //             }
  //         }
  //         .label-checkbox {
  //             margin-left: 0;
  //         }
  //         .work-hour-list {
  //             li {
  //                 margin-bottom: 20px;
  //                 .apply-lbl,
  //                 .custom-checkbox-holder {
  //                     margin-top: 10px;
  //                 }
  //                 md-input-container {
  //                     .custom-select.time-select {
  //                         width: 120px;
  //                     }
  //                 }
  //             }
  //         }
  //     }
  // }
}
@media screen and (max-width: 461px) {
  .resource-detail-section .status-displayer .progress-circular-holder md-progress-circular {
    transform: rotate(-90deg);
  }
}

.ui-accordion.summery-list .panel-group .panel-title > a {
  // min-height: 0;
}

.datepicker-custom .md-datepicker-input-container input {
  max-width: 100%;
  width: 100%;
}

.summary-status {
  width: 40%;
  max-width: 200px;
  float: right;
  margin-top: 20px;
}

// .contact-user-list {
//   .desc {
//     h3 {
//       margin: 0;
//       display: inline-block;
//     }
//
//     p {
//       display: block;
//       position: relative;
//       padding-left: 20px;
//       margin: 10px 50px 10px 0;
//       text-align: left;
//     }
//
//     .icon-a {
//       position: absolute;
//       left: 0;
//       top: -5px;
//     }
//
//     .icon-b {
//       position: absolute;
//       left: 0;
//       top: -3px;
//     }
//
//     .icon {
//       color: $gray-light;
//       vertical-align: bottom;
//       font-size: $font-size-large;
//     }
//
//     .icon-ico-phone {
//       width: 15px;
//       height: 20px;
//     }
//
//     .status {
//       margin-left: 5px;
//       color: $gray-light;
//
//       &.active {
//         color: $brand-success;
//       }
//     }
//
//     .place {
//       display: block;
//       color: $gray;
//       margin-bottom: 5px;
//     }
//
//     .social-info,
//     .time-info {
//       text-align: right;
//       color: $gray;
//       font-size: 10px;
//       margin-left: 10px;
//     }
//
//     .social-info {
//       margin-left: 0;
//
//       md-icon {
//         margin-right: 8px;
//       }
//
//       span {
//         display: inline-block;
//         vertical-align: middle;
//       }
//     }
//
//     .social-icon-wrapper {
//       md-icon {
//         margin: 0 4px;
//         vertical-align: top;
//       }
//
//       .md-button {
//         margin: 0;
//         height: auto;
//         min-width: 0;
//         padding: 0;
//         width: auto;
//         vertical-align: top;
//         min-height: 0;
//       }
//     }
//
//     .contact-info {
//       min-width: 200px;
//       display: inline-block;
//
//       &.num {
//         min-width: 0;
//       }
//
//       .ico-landline {
//         width: 18px;
//         height: 20px;
//         position: relative;
//         right: 2px;
//       }
//     }
//   }
//
//   .user-contact {
//     display: inline-block;
//     margin-right: 28px;
//     margin-top: 4px;
//     max-width: 52px;
//     color: $gray;
//
//     md-icon {
//       width: 55px;
//       height: 55px;
//     }
//   }
// }

.mTop {
  margin-top: -35px;
}

.email-verfied {
  position: absolute;
  top: 0;
  right: -60px;
  font-size: 14px;
  color: variables.$gray;
}

.change-mgmt-popups {
  min-width: 250px;
  width: 750px;

  .form-base {
    .function-data {
      .md-block {
        padding-top: 18px;
      }
    }
  }

  .msg-section {
    margin-bottom: 15px;
  }

  .function-data {
    padding-left: 35px;
  }

  .warning {
    margin: 0;

    md-icon {
      svg {
        path {
          fill: variables.$btn-warning-border;
        }
      }
    }
  }

  .reason {
    height: 150px;
    width: 150px;
  }

  .rb-btns {
    padding-top: 10px;
  }
}

@media (max-width:600px) {
  .email-verfied {
    top: -23px;
    right: 0;
  }
}

.connection-status {
  position: relative;
  padding: 18px 0 0 35px;

  .circle {
    position: absolute;
    width: 15px;
    height: 15px;
    top: 17px;
    left: 12px;
    border-radius: 50%;
  }
}

.user-clock-management {
  .custom-button,
  .custom-button:hover {
    background-color: rgb(167, 207, 157) !important;
    color: black;

  }

  .btn-secondary,
  .custom-button {
    font-weight: bold;
    padding: 0 10px;
  }

  .travel-summary {
    padding-left: 30px;
    position: relative;

    .address-link {
      margin-top: 5px;
    }

    &:not(.stop) {
      border-left: 2px dashed;
    }

    &:not(:last-child) {
      padding-bottom: 10px;
    }

    &:last-child {
      &.current,
      &.destination,
      &.origin {
        border-color: white;
      }
    }

    .circle {
      width: 10px;
      height: 10px;
      position: absolute;
      top: 0;
      left: -6px;
      border-radius: 50%;
    }

    &.clocked-out {
      border-color: transparent;

      .circle {
        background-color: rgb(82, 117, 141);
      }
    }

    &.destination {
      &.clocked-out {
        border-color: rgb(82, 117, 141);
      }

      .circle {
        background-color: rgb(88, 202, 255);
      }
    }

    &.current {
      &.destination {
        border-color: rgb(88, 202, 255);
      }

      .circle {
        background-color: variables.$brand-primary;
      }
    }

    &.origin {
      &.current {
        border-left-style: solid;
        border-color: variables.$brand-primary;
      }

      .circle {
        background-color: rgb(147, 196, 125);
      }
    }
  }

  .clock-calculation {
    background-color: rgb(236, 243, 249);
    padding: 20px 30px;
    margin-left: 10px;
    @media (max-width: 600px) {
      margin-left: 0;
    }

    .calculation-card {
      margin-top: 5px;
      background-color: white;
      padding: 5px 20px;
    }
  }

  .labor-breakdown {
    .label-active,
    .label-danger,
    .label-grey {
      padding: 5px 20px;
      color: white;
      margin: 5px 5px 0 0;

    }

    .label-active {
      background-color: rgb(147, 196, 125);
    }

    .label-danger {
      background-color: rgb(224, 102, 102);
    }

    .label-grey {
      background-color: rgb(153, 153, 153);
    }
  }

  .my-calendar {
    .wrappper {
      border: 1px solid #dedede;

      .header,
      .time-row {
        height: 46px;
        border-bottom: 1px solid #dedede;
      }

      .time-row {
        &.last {
          border: none;
        }

        .time-head {
          border-right: 1px solid #dedede;
          line-height: 46px;
          text-align: center;
        }

        .item {
          padding-left: 5px;
        }
      }
    }
  }
}

.added-custom-tag {
  margin-bottom: 30px;

  .blue_heading_title {
    background-color: #ddecf3ff!important;
    padding: 30px 10px 60px !important;

    h2 {
      font-size: 25px;
      color: #052a4d;
    }

    p {
      font-size: 18px;
      color: #666666;
    }

    .cards-section {
      margin: 20px auto;

      .type-box {
        min-width: 200px;
        width: 100%;
        padding: 65px 16px;
        color: #52758d;
        font-weight: 600;
        cursor: pointer;
        background: variables.$white;
        margin: 15px;
        gap: 10px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
        @media screen and (max-width: 1200px) {
          min-width: auto;
          margin: 20px auto;
        }

        .second_content {
          font-size: 14px;
        }
      }
    }

    .cta-section {
      margin: 0 auto;
      display: flex;
      max-width: 500px;
      width: 100%;

      .md-button {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
        max-width: 180px;
        width: 100%;
      }

      .btn-secondary {
        margin-right: 15px;
      }
      @media screen and (max-width: 600px) {
        display: grid;

        .btn-secondary {
          margin: 20px auto 0;
        }
      }
    }
  }

  .add-custom-content {
    h2 {
      font-size: 20px !important;
      color: #052a4d;
    }

    p {
      font-size: 18px !important;
      color: #666666;
    }
  }
}
