@use "../../settings/third-party/mixins";
@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.invoice {
  .business-logo {
    font-size: 18px;
    // border: 1px solid $brand-table;
    padding: 25px 0;
  }

  .invoice-header {
    background-color: #0b5394;

    th {
      color: variables.$white;
    }
  }

  .subheader {
    background-color: #cfe2f3;
    font-size: 12px;

    td {
      padding: 10px 15px;
    }
  }

  .single-border-table {
    border: 1px solid variables.$brand-table;

    tr {
      border: none;

      td {
        border: none;

        .upper-text {
          margin-top: 20px;
        }
      }

      &.section-last {
        border-bottom: 1px solid variables.$brand-table;

        td {
          border-bottom: 1px solid variables.$brand-table;
        }
      }
    }
  }
}

.suppliersCatalogs-details {
  .default-card-box {
    height: 150px;
    background-color: #fff;
    box-shadow: 2px 2px 0 #fff;
    -webkit-box-shadow: 2px 2px 0 #fff;
    border-bottom: 2px solid #D2D2D2;
  }
}

.submitted {
  color: variables.$status-green;
}

.sku-catalog {
  .add-catalog {
    margin-left: 20px;
  }

  .sku-icon {
    width: 34px;
    height: 34px;
    margin: 0;
  }
}

.auto.submitted {
  color: variables.$status-green;
}

.status-dropmenu.procure-tab {
  max-height: none;
}

.supplier-priority {
  margin-top: 10px;
}

.notes-icon {
  margin-left: 10px;
}

.title-center {
  margin: 0 auto;
  text-align: center;
}

.addcost-icon {
  md-icon {
    height: 18px;
    width: 23px;
  }

  .right-space {
    margin-left: 15px;
  }
}

.resource-divisonsection {
  margin: 10px 0;
}

.procurment-task {
  .schedule-select {
    .icon-side-tick {
      display: inline-block;
    }
  }

}

.procurment-task {
  .icon-side-tick {
    display: none;
    height: 15px;
    position: absolute;
    left: 46px;
    top: 22px;
    width: 15px;
  }

}

.procurements-accordionN {
  padding: 0 !important;

  .md-avatar {
    border-radius: 50%;
    border: 1px solid #ccc;

  }

  .icon-side-tick {
    display: none;
    height: 15px;
    position: absolute;
    left: 46px;
    top: 22px;
    width: 15px;
  }

}

.procurment-assign {
  width: 100%;

  .name-container {
    margin: 10px 0;
  }

  .resource-organisation {
    font-size: 11px;
    margin-top: 6px;
  }

  .accordion-names {
    font-size: 11px;
  }

  .emailand-phone {
    text-align: right;
    line-height: 1.2;
  }

}

.resource-divisoncontainer {
  margin-left: 3px;
}

.select-locationResource {
  margin: 0 7px;
}

.content-divison {
  margin-top: -7px;
}

.resourceAllocation {
  .resource-icon {
    width: 34px;
    height: 34px;
    margin: 0;
    vertical-align: middle;

  }

  .search-symbol {
    width: 20px;
    height: 20px;
    margin: 0;
  }

  .search-button {
    top: 0;
    z-index: 2;
  }

  .sidenav-searchicon {
    height: 16px;
    float: right;
    cursor: pointer;
    margin: 0;
    padding-right: 6px;
    outline: 0;
    z-index: 10;
    position: relative;
    z-index: 2;
  }

  .ar-sidepanel {}

  .sidenav-title {
    .search-box {
      top: 12px;
      right: 5px;
      height: 25px;
      font-size: 12px;
      position: absolute;
      margin: 0;
      top: 4px;
      right: 13px;
      padding-right: 22px;
    }

  }

  h2 {
    display: inline-block;
    font-weight: 500;
    margin-left: 9px;
  }

  .search-symbol {
    width: 20px;
    height: 20px;
    margin: 0;
  }
}

.ar-sidepanel {
  .bottom-section {

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;

  }

}

.resource-organisation-procurment {
  padding: 15px 10px 0;

  md-select {
    background: #fff;
  }

}

.divison-side-allocation {
  a {
    img {
      vertical-align: middle;
      margin-right: 12px;
    }
  }
}

.resource-organisationprocurment {}

.popup-auto-order {
  height: 100%;
  max-width: 70%;
}

.history-caps {
  text-transform: capitalize;
}

.adjust-calculations {
  margin: 20px;
}

.publish-header-right {
  md-icon {
    height: 40px;
    width: 40px;
  }
}

.po-savebtn-btn {
  background-color: #FAB963;
}

.po-savebtn {
  margin-right: 15px;
}

.adjsut-remove {
  display: inline;
}

.status-progrss {
  text-align: left;
}

.adjusted {
  color: red;
}

.purchaseord-box {
  min-height: 216px;
  position: relative;

  .cost-items {
    margin-top: 71px;
  }
}

.po-status {
  position: absolute;
  right: 24px;
  top: 13px;
}

.paymentPo-tab {
  cursor: pointer;
}

.payment-table {
  //   min-height: 48px;
  // 	max-height: 50px;
  // overflow: scroll;
  max-height: 93px;
  overflow: scroll;
  overflow-x: hidden;

  table {
    width: 100%;

    .header-left {
      text-align: left;

      tr {
        td {
          padding: 8px 1px;
        }
      }
    }

    .payment-terms {
      padding: 2px 1px;
    }
  }

}

.cost-payment {
  padding: 20px;
  margin: 20px 40px;
  background: #d6e1e5;

  div {
    margin-right: 20px;

  }
}

.single-view {
  padding: 20px;
}

.single-view-heade {
  margin-top: 14px;
  margin-left: 15px;
}

.disabled-icon {
  svg {
    path {
      color: gainsboro;
    }
  }
}

.saveBtn-onPO {
  text-align: center;
}

.paymentType-infomation {
  text-align: right;
}

.address-location {
  display: block;
  clear: both;
  margin-right: 5px;
}

.supplierdetail-widget {
  .summery-widget {
    margin-bottom: 0;
  }
}

.item-cost-info {
  margin-top: 15px;
}

.material-product {

  margin-bottom: 5px;
}

.additionalCost-pop {
  padding: 20px;

  table {
    border: none;

    tbody {
      border: none;

      td,
      tr {
        border: none;

      }
    }

    thead {
      border: none;

      th,
      tr {
        border: none;
      }
    }
  }

}

.next-step {
  display: none;
}

input.viewable {
  border: transparent;
}

input.editable {
  color: #000;
}

.po-table {
  min-width: 1000px !important;
}

.payment-made {
  margin-top: 10px;
}

.no-boedertb td {
  border: 0;
}

.recordpayment-form {
  padding: 30px !important;
}

.supplier-right {
  .switch-bar {
    md-switch {
      margin-top: 12px;
      margin-bottom: 8px;

    }
  }
}

.payment-count {
  .md-icon-button {
    md-icon {
      height: 15px;
    }
  }
}

.table-responsive {
  width: 100%;
}

.add-item-container {
  .add-btn-section {
    margin-bottom: 10px;

    button {
      padding-left: 0;
      margin-left: 0;
    }
  }
}

.procurment-status-dropmenu {
  width: 150px;

  .edit-dropdown-content {
    background: #fff;
    border: 1px solid #CFDADE;
    border-top: 0;
    border-radius: 5px;
    padding: 0;

    width: 300px;
  }

  .product-table-list {
    padding: 6px 5px 15px;
    cursor: pointer;
    // border-bottom: 1px solid $gray-light;
  }

  .product-table-list:hover {
    background-color: rgba(158, 158, 158, 0.2);
  }

  .lead-time {
    text-align: center;
  }
}

.address-type {
  margin-top: 15px;
}

.service-history-section {
  padding: 0;
}

.contact-number {
  margin-top: 7px;

  .md-input-container {
    .label {
      padding: 7px;
    }
  }
}

.doc-list {
  .chipbox:before {
    content: '';
    display: inline-block;
    background: url("#{variables.$image-path}/icons/set-b/ico-pdf.svg") no-repeat 0 0;
    background-position: 0 0;
    background-size: 15px;
    height: 17px;
    margin-right: 6px;
    width: 15px;
    vertical-align: middle;
  }

}

.supplier-catlog {

  padding: 20px;
}

.procure-supplier-list-section {
  padding: 20px;
}

.procurement {
  .filter-doc-input {
    width: 350px;
  }

  .input-list {
    .supplier-contact {
      margin-bottom: 20px;
    }

  }

  .notes {
    margin-top: 15px;
  }

  md-content {
    overflow: hidden;
  }

  .filter-notes-input {
    width: 350px;
  }

  .small-txt {
    color: variables.$gray;
    font-size: 10px;

    .f3 {
      font-size: 10px;
    }
  }

  .files-type-sec {
    li {
      margin-bottom: 25px;
    }
  }

  .stat {
    font-size: 19px;
    padding: 5px 0;
  }

  .stat-head {
    font-size: 14px;
    font-weight: 200;
  }

  .stat-box {
    border: 1px solid variables.$gray-light;
    border-radius: 10px;
    margin-top: 6px;
    padding: 10px 20px;
    margin-right: -10px;

    .current-box {
      position: absolute;
      top: -30px;
      left: 2px;
    }

    .stat-panel {
      // background-color: $gray-lighter;

      .stat-desc {
        padding: 5px 40px;
        // border-right: 1px solid $gray;
        margin: 20px 0;

      }
    }
  }

  .custom-tabs {
    md-content {
      padding-top: 20px;
    }
  }

  .btn-icon {
    height: auto;
    margin-right: 0;
    min-height: auto;
    width: auto;
  }

  .reduce-left {
    margin-left: -8px;
  }

  // .reduce-top {
  // 	margin-top: -8px;
  // }

  .increase-top {
    margin-top: 8px;
  }

  .icons-only {
    height: 21px;
    margin-right: 8px;
    width: 21px;
  }

  .purchase-submited {
    margin-bottom: 15px;
  }

  .data-table {
    td {
      @extend .f3;
    }

    .location-symbol {
      margin-right: 8px;
    }

    .forward-btn {
      height: auto;
      min-height: auto;
      width: auto;

      md-icon {
        height: 15px;
        width: 15px;

        svg path {
          fill: variables.$brand-accent;
        }
      }
    }
  }

  .file-type-img {
    margin-bottom: 20px;
    margin-right: 20px;
    width: 50px;
    height: 50px;
  }

  .div-container {
    margin: 18px 0;
  }
  // md-divider {
  // 	margin-bottom: 15px;
  // 	margin-top: 10px;
  // }

  .date-text {
    display: inline-block;
    font-size: 18px;
    color: variables.$gray-darker;
  }

  .status-type {
    max-width: 65px;

    &.draft {
      color: variables.$status-gray;
    }

    &.submitted {
      color: variables.$status-green;
    }

    &.modified {
      color: variables.$status-yellow;
    }

    &.partially-received {
      color: variables.$status-info;
    }

    &.fully-received {
      color: variables.$status-green;

    }

    &.over-due {
      color: variables.$status-red;
    }

    &.complete {
      color: variables.$status-green;
    }

    &.partially-paid {
      color: variables.$status-info;
    }

    &.auto-submitted {
      color: variables.$status-green;

    }

    &.fully-published {
      color: variables.$status-green;

    }

    &.open {
      color: variables.$status-green;

    }

    &.closed {
      color: variables.$status-gray;

    }

  }

  .rightside-widget {
    .summery-widget {
      line-height: 21px;
    }

    .switch-bar {
      margin-bottom: 15px;

      label {
        padding-right: 15px;
      }
    }

    .icon-tick .tick-bg {
      fill: transparent;
    }

    .supplier-no {
      margin-top: 3px;
      margin-right: 5px;
      margin-bottom: 3px;
    }
  }

  .rb-section {
    margin-top: 25px;
    margin-bottom: 20px;

    md-radio-button {
      @extend .f3;
      margin: 15px 10px;

    }
  }

  .input-list {
    @extend .no-style;

    li {
      margin-bottom: 20px;
    }

    .supplier-contact {
      margin-bottom: 20px;
    }
  }
}

.procure-supplier-list-section {
  .data-table {
    min-width: 1050px !important;

    td {
      line-height: 20px;
    }

    .small-txt {
      line-height: 14px;
    }
  }

  .sources-list {
    @extend .no-style;

    li:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.procure-supplier-tab-section {
  .img-container {
    background: variables.$white;
    margin-left: 25px;
    padding: 5px 10px;

    img {
      width: 60px;
    }
  }
}

.procure-supplier-tablist-section {
  .filters-bar {
    .input-lg input {
      width: 100%;
    }

    .search-btn {
      margin-left: 20px !important;
    }
  }

  .search-result-container {
    @include mixins.border-radius(4px);

    color: variables.$gray-darker;
    background: variables.$brand-info;
    font-size: 10px;
    padding: 10px;
    margin-left: 15px;
  }
}

.procurement-sidebar {
  .bottom-section,
  .task-wrapper {
    position: absolute;
    right: 0;
    left: 0;
  }

  .task-wrapper {
    top: 0;
    bottom: 50px;
    overflow-y: auto;

    .input-section {
      margin-bottom: 15px;
      padding-right: 20px;
      padding-left: 20px;

      .section-title {
        color: variables.$gray-darker;
        margin-top: 12px;
        font-size: variables.$font-size-small;
        font-weight: 500;
      }
    }
  }

  .bottom-section {
    bottom: 1px;
  }

  .search-list {
    li {
      padding-right: 20px;
      padding-bottom: 0;
      padding-left: 20px;
    }

    md-divider {
      margin-top: 10px;
      margin-right: -20px;
      margin-left: -20px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .supplier-catalog-table {
    max-width: 795px;
  }
}

@media screen and (min-width: 600px) {
  .procurement {
    .rightside-widget {
      position: absolute;
      top: 20px;
      right: 10px;

      .summery-widget {
        min-width: 180px;
      }
    }
  }

  .procurement {
    .rightside-widgetCatalog {
      position: relative !important;
      right: 0;

      .summery-widget {
        min-width: 180px;
      }
    }
  }

  .procure-supplier-tab-section {
    md-input-container {
      margin-right: 20px;
    }
  }

  .procure-supplier-tablist-section {
    .supplier-tablisttop-section {
      margin-bottom: 20px;

      md-input-container {
        margin-bottom: 0;
        margin-right: 18px;
      }

      .md-icon-button {
        margin-left: 18px;
      }
    }
  }

  .purchase-orderlist-section {
    .top-section {
      md-input-container {
        margin-right: 20px;
      }
    }
  }
}
