@use "../../settings/third-party/variables";
@use "../../settings/style-guide";
@use "../../settings/custom";

.form-base {
  .avtar {
    .i-holder {
      padding: 0;
      margin-bottom: 10px;
      height: 100px;
      width: 100px;
      border-radius: 50%;
      border: solid 1px variables.$gray-light;
      overflow: hidden;

      .quote-image-replacer-edit-contact {
        @extend .img-replacer;
        font-size: 42px;
        height: 98px;
        width: 98px;
        line-height: 98px;
      }
    }

    .avtar-change {
      margin-right: 10px;
    }
  }

}

.add.md-button {
  background: variables.$brand-primary;
}

.md-virtual-repeat-container.md-autocomplete-suggestions-container {
  height: auto;

  .md-virtual-repeat-offsetter {
    height: auto;
    // line-height: auto;

    ul li {
      height: auto;
    }
  }
}

.select-errorspace {
  margin-bottom: 45px !important;
}
// .md-errors-spacer { display: none; }

.form-right-section {
  .country-name {
    overflow: hidden;
  }

  .clear-sec-contact {
    float: right;
    margin: 13px 0 25px;
  }

}

.camel-case {
  text-transform: capitalize;
}

md-option.country-code-flag .md-text {
  width: 100%;
}

.edit-contact {
  input[type=number] {
    font-size: 14px;
  }
}

@media screen and (max-width: 600px) {
  .edit-contact md-input-container.gap-right-hard {
    margin-right: 0;
  }
}

.contact-user-list .desc .icon-ico-phone {
  width: 20px;
  height: 20px;
}

.contact-email-width {
  max-width: 159px;
}
