@use "../../settings/third-party/mixins";
@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.purchase-payment-section {
  padding: 20px;

  .bottom-gap-xs {
    margin-bottom: 10px;
  }

  .summery-widget {
    line-height: 1.5;

    .widget-space {
      margin-top: 15px;
    }

    .payment-confirm {
      height: 18px;
      margin-right: 8px;
      width: 18px;

      .tick-bg {
        fill: transparent;
      }
    }
  }

  .order-status {
    display: inline-block;
    width: 80px;
    text-align: center;

    .status {
      text-transform: uppercase;
    }

    .sm-text {
      color: variables.$gray;
      font-size: 10px;
    }

    .status-viewer {
      @include mixins.border-radius(5px);

      background: variables.$gray-lighter;
      border: 1px solid variables.$gray-light;
      height: 18px;
      margin-top: 2px;
      margin-bottom: 2px;
      overflow: hidden;

      .progress-bar {
        display: inline-block;
        height: 100%;

        &.open {
          background: variables.$proposalBlue;
        }

        &.closed {
          background: variables.$brand-info;
        }
      }
    }
  }

  .data-table {
    md-checkbox {
      margin-top: 8px;
    }

    .icon-paid {
      height: 21px;
      width: 21px;
    }

    .merging-row-top {
      td {
        border-bottom: none;
      }
    }

    .merging-row-bottom {
      td {
        padding-top: 0;
      }
    }

    td {
      @extend .f3;
    }

    md-input-container {
      margin-bottom: 0;

      &.input-sm {
        width: 115px;
      }

      &.input-xs {
        width: 80px;
      }
    }

  }
}
