@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.opportunity-proposal.proposal-template {
  .section-title {
    font-size: 16px;
    font-weight: 400;
    margin-top: 0;
  }

  .opportunity-tabs {
    &.inner {
      md-tabs-wrapper {
        border-bottom: none;
      }

      md-ink-bar:after,
      md-ink-bar:before {
        border: none;
      }
    }
  }

  .layouts {
    margin-left: -10px;
    @media screen and (max-width:900px) {
      margin: 0 auto;
      text-align: center;
    }

    li {
      width: 100%;
      max-width: 220px;
      max-height: 100%;
      min-height: 157px;
      padding: 10px;
      float: left;
      @media screen and (max-width:900px) {
        float: none;
        display: inline-block;
      }

      .layout-box {
        padding: 10px;
        margin: 0;
        border: 1px solid variables.$border-color;
        box-shadow: 1px 1px variables.$border-color;
        cursor: pointer;

        .img-container {
          width: 100%;
          max-height: 140px;
          min-height: 100px;
          height: 100%;
        }

        .image {
          width: 100%;
          max-height: 100%;
        }

        .menu-btn {
          position: absolute;
          top: auto;
          bottom: 5px;
          right: 5px;

          &:before {
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
          }
        }
      }

      &.built {
        max-width: 130px;

        .layout-box {
          padding: 20px;
          text-align: center;

          .image svg {
            height: 37px;
          }

          &.prop-tiles {
            font-size: variables.$font-size-base;
            font-weight: 500;
            border: 1px solid;
            border-radius: 3px;
            box-shadow: none;
            cursor: pointer;

            &.active {
              color: #fff;

              .image svg path {
                fill: #fff;
              }
            }
          }

          &.branding {
            border-color: variables.$proposalRed;
            color: variables.$proposalRed;

            &.active {
              background-color: variables.$proposalRed;
            }
          }

          &.catering {
            border-color: variables.$proposalPurpal;
            color: variables.$proposalPurpal;

            &.active {
              background-color: variables.$proposalPurpal;
            }
          }

          &.ecommerce {
            border-color: variables.$proposalGreen;
            color: variables.$proposalGreen;

            &.active {
              background-color: variables.$proposalGreen;
            }
          }

          &.eventmanage {
            border-color: variables.$proposalBlue;
            color: variables.$proposalBlue;

            &.active {
              background-color: variables.$proposalBlue;
            }
          }

          &.html5 {
            border-color: variables.$proposalOrange;
            color: variables.$proposalOrange;

            &.active {
              background-color: variables.$proposalOrange;
            }
          }

          &.inbound {
            border-color: variables.$proposalRed;
            color: variables.$proposalRed;

            &.active {
              background-color: variables.$proposalRed;
            }
          }

          &.ios {
            border-color: variables.$proposalPurpal;
            color: variables.$proposalPurpal;

            &.active {
              background-color: variables.$proposalPurpal;
            }
          }

          &.seo {
            border-color: variables.$proposalRed;
            color: variables.$proposalRed;

            &.active {
              background-color: variables.$proposalRed;
            }
          }

          &.social {
            border-color: variables.$proposalGreen;
            color: variables.$proposalGreen;

            &.active {
              background-color: variables.$proposalGreen;
            }
          }

          &.ux {
            border-color: variables.$proposalRed;
            color: variables.$proposalRed;

            &.active {
              background-color: variables.$proposalRed;
            }
          }
        }
      }

      .image-msg {
        position: absolute;
        bottom: 0;
        height: 40px;
        left: 0;
        width: 100%;
        background-color: rgba(variables.$gray-base, .75);
        color: variables.$white;
        font-size: 16px;
        cursor: pointer;
      }

      .update-status {
        margin-top: 5px;
        margin-right: 5px;
        @extend .text-right, .f4;
      }

      &.active {
        .layout-box {
          border-color: variables.$brand-primary;
          box-shadow: 1px 1px variables.$brand-primary;
        }

        .image-msg {
          background-color: rgba(variables.$brand-primary, .88);
        }
      }
    }
  }
}
