@use "../../settings/third-party/variables";
@use "../../settings/style-guide";
@use "../../settings/custom";

.products-landing {
  md-content {
    padding: 15px;
  }
}

.list-style {
  padding: 10px 0;

  ul {
    display: inline-block;

    li {
      @extend .f8;
      @extend .cursor-point;
      transition: all ease-in-out 0.5s;
      display: inline-block;
      padding: 0 15px;
      color: variables.$brand-primary;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      &.active {
        color: variables.$gray-base;
        @extend .no-cursor;
      }
    }
  }
}

.top-header {
  background: #e4ecee;
  transition: all ease-in-out 0.5s;
  // border-bottom: 1px solid $gray-light;
  box-shadow: 0 8px 10px -15px variables.$gray-base;
  z-index: 1;
  position: relative;
  @extend .list-style;
  padding: 10px 15px;

  ul li {
    padding: 0 10px;

    color: variables.$brand-primary!important;

    &.active {
      color: variables.$gray-base!important;
    }
  }

  h3 {
    font-weight: 400;
  }

  .parent-catalog {
    @extend .cursor-point;

    .catalog-img {
      display: inline-block;

      md-icon {
        width: 13px;
        position: absolute;
        right: 0;
        top: -5px;
      }

      img {
        @extend .img-circle;
        border: 1px solid variables.$gray-light;
      }
    }

    .catalog-name {
      display: inline-block;

      .name {
        display: inline-block;
        vertical-align: middle;
        margin: 10px;

        &.product-catalog-name {
          font-size: 18px;
          font-weight: bold;
        }

      }

      label {
        @extend .cursor-point;
      }

      md-icon {
        width: 12px;
        transition: transform linear 0.3s;
        transform: rotate(90deg);

        &.opened {
          transform: rotate(-90deg);
        }

        path {
          fill: variables.$brand-primary;
        }
      }
    }
  }

  .location {
    display: inline-block;

    md-icon {
      display: inline-block;
      margin-top: -3px;
      width: 12px;
    }

    md-select {
      display: inline-block;
      margin: 0 10px 0 0;
      // width: 200px;
    }
  }
}

.top-header:hover {
  .product-catalog-name {
    color: variables.$brand-primary;
  }
}

.list-grid-filter {
  .icon {
    @extend .cursor-point;
    display: inline-block;
    color: variables.$brand-primary;

    &.product-filter {
      color: variables.$brand-primary;
      font-size: 14px;
    }

    &.active {
      color: variables.$gray-base;

      md-icon path {
        fill: variables.$gray-base;
      }
    }

    &:first-child {
      margin-right: 10px;
    }

    md-icon {
      width: 15px;
      margin-right: 5px;

      path {
        fill: variables.$brand-primary;
      }
    }
  }
}

.list-product-regular {
  // padding: 15px;
  h3 {
    font-weight: 400;
  }

  ul {
    li {
      color: variables.$gray-base;
      border: 1px solid variables.$btn-default-border;
      padding: 10px;
      margin: 0 10px 10px 0;
      border-radius: 3px;
      width: 135px;
      min-height: 65px;
      position: relative;
      transition: border-color 1s;

      &.active,
      &:not(.disabled):hover {
        border-color: variables.$brand-primary;
      }

      md-icon {
        position: absolute;
        width: 13px;
        top: 0;
        right: 8px;
      }

      .badge {
        margin-bottom: 6px;
        padding: 0 5px;
        width: 40px;
      }
    }
  }
}

.tag-filter {
  position: absolute;
  right: 2px;
  font-size: 12px;
  bottom: 3px;

  md-icon {
    &.head-icon {
      width: 15px;
      margin-right: 5px;
    }

    &.tag {
      width: 18px;
      height: 18px;
    }
  }
}

.icon,
.prod-filter-item,
.tag-filter {
  &.product-filter {
    @extend .f1;
    @extend .font-bold;
    @extend .spacer-bot-10;
  }
}

.prod-filter-item {
  &.product-filter {
    margin-bottom: 0 !important;
  }
}

.tag-filter {
  .top-section {
    .header {
      margin: 10px;

      h2 {
        margin-left: 10px;
      }
    }
  }

  .content-section {
    margin: 0 10px;

    .item-chipset li {
      display: inline-block;
      margin-right: 10px;

      .chipbox {
        &.active {
          border-color: variables.$brand-primary;
        }
        border: 1px solid #949494;
      }
    }

  }

}

.catalog-header {
  .total-stats {
    padding: 25px;
    background: #e4ecee;
  }
}

.parent-catalog-list {
  display: none;

  .catalog-parent {
    margin: 10px 0 30px;

    &.active,
    &:hover {
      .catalog-container {
        border-color: variables.$brand-primary;
      }

      h2 {
        transition: color 0.5s;
        color: variables.$brand-primary;
      }
    }

    .ellipsis {
      display: inline-block;
      max-width: 182px;
    }
  }

  .catalog-container {
    background: #f2f6f7;
    width: 182px;
    min-height: 182px;
    border-radius: 3px;
    border: 1px solid variables.$btn-default-border;
    position: relative;
    padding: 15px;
    margin: 0 15px 0 0;
    transition: border-color 0.5s;

    .img-replacer {
      height: 120px;
      width: 120px;
      font-size: 40px;
      line-height: 120px;
    }
  }
}

.cart-quantity {
  display: inline-block;
  margin: 0 12px;
  white-space: nowrap;

  .md-button.md-icon-button {
    margin: 0;
    min-width: 40px;
  }

  .ico-round-btn {
    path {
      fill: variables.$gray-light;
    }
  }

  input {
    width: 55px;
    height: 36px;
    // margin: 0 7px;
    text-align: center;
    border: 1px solid variables.$gray-light;
    border-radius: 3px;
  }
}

md-menu-content.status-dropmenu.child-list-dropmenu.quantity-popup md-menu-item {
  padding: 0;
}

md-menu-content.quantity-popup md-menu-item {
  .cart-item {
    width: 100%;

    .md-button.md-icon-button {
      margin: 0;
      height: 20px;
    }

    .ico-round-btn {
      height: 20px;

      path {
        fill: variables.$gray-light;
      }
    }

    input {
      text-align: center;
      border: 1px solid variables.$gray-light;
      border-radius: 3px;
      min-width: 40px;
      max-width: 40px;
    }
  }
}

.location-center {
  margin: 0 auto;
}

.list-grid {
  // margin: 0 15px 15px;

  .count-badge {
    background: #e5ecf1;
    color: variables.$gray-darker;
    text-align: center;
    border-radius: 3px;
    padding: 3px;
    width: 60px;
    font-weight: bold;

    &.location {
      background: variables.$brand-accent;
      color: variables.$white;
    }

    &.dark {
      background: #2b3e50;
      color: variables.$white;
    }
  }

  .list-view {
    border: 1px solid variables.$gray-light;
    padding: 10px;
    border-radius: 3px;

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }

    &.product-list-view {
      background-color: #F2F6F7;
      box-shadow: #949494 0 1px 3px -1px;
      border: none;
    }

    .regular-price {
      margin: 10px 20px 10px 0;
      color: variables.$gray-base;
      text-align: right;
      max-width: 180px;
    }

    .left-option {
      .product-image {
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;

        img {
          @extend .img-circle;
          border: 1px solid variables.$gray-light;
        }
      }

      .product-detail {
        vertical-align: middle;
        display: inline-block;
        margin-right: 15px;
        max-width: 230px;

        &.product-detail-exp {
          max-width: 750px;
        }

        h4 {
          margin: 0 0 5px;
          color: variables.$gray-base;
          font-weight: 400;
          // label {
          //   color: $brand-danger-dark;
          // }
          &.product-tag {
            grid-gap: 5px;
            margin-bottom: 10px;
            color: variables.$gray-base;

            label {
              font-size: 12px;
              font-weight: 400;
            }
          }
        }

        .stats {
          display: inline-block;
          color: variables.$gray;

          &.product-tag-stats {
            color: variables.$gray-base;
          }
        }

      }

    }

  }

  .product-list-view:hover {
    //background-color:  #F2F6F7;
    background-color: #e4ecee;

    .left-option {
      .product-detail {
        h4 {
          &.product-tag {
            color: variables.$brand-primary;
          }
        }
      }
    }

  }

  .right-option {
    .action-btns {
      display: inline-block;

    }

    .price {
      display: inline-block;
    }

    .customize-quick-add {
      margin-left: 0;

      md-icon {
        height: 15px;
        width: 15px;
        vertical-align: text-bottom;

        svg path {
          fill: variables.$white;
        }
      }
    }

  }

  .grid-view {
    .grid-product.select-product {
      border-color: variables.$brand-primary;
    }

    .grid-product {
      max-width: 220px;
      width: 220px;
      margin-right: 10px;
      margin-bottom: 10px;
      display: inline-block;

      &:hover {
        .prod-img-alternate {
          border-color: variables.$brand-primary;
        }

        .media-opt {
          .media-header {
            md-icon {
              svg {
                path {
                  fill: variables.$brand-primary;
                }
              }
            }

            h2 {
              transition: color 0.5s;
              color: variables.$brand-primary;
            }
          }
        }
      }

      .prod-img-alternate {
        width: 100%;
        height: 220px;
        padding: 18px;
        border: 1px solid #dbdbdb;
        background: #f2f6f7;
        box-shadow: #949494 0 1px 3px -1px;
      }

      .image-bg {
        background-size: cover;
        background-position: center;
        //border: 1px solid #dbdbdb;
        //border-bottom-width: 0;
        width: 100%;
        height: 185px;
      }

      .img-alternate {
        //border: 1px solid #dbdbdb;
        background: #f2f6f7;
        //border-bottom-width: 0;
        width: 100%;
        height: 185px;

        .prod-grid-img {
          height: 120px;
          width: 120px;
          font-size: 40px;
          line-height: 120px;
        }
      }

      .eye-btn {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        height: 35px;
        padding: 0 0 8px 8px;

        md-icon {
          width: 20px;
          height: 20px;

          path {
            fill: variables.$gray;
          }
        }
      }

      .edit-btn {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;

        md-icon {
          width: 12px;
          height: 12px;

          path {
            fill: variables.$gray;
          }
        }
      }

      .overlay {
        background: rgba(0, 0, 0, 0.8);
        border-radius: 3px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;

        .status {
          color: variables.$brand-danger-dark;
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          margin: 0;
        }

      }

      .media-opt {
        padding: 10px 20px 10px 0;
        //border: 1px solid #dbdbdb;
        width: 220px;

        .media-header {
          font-size: 14px;

          md-icon {
            margin-right: 10px;

            svg {
              path {
                fill: variables.$gray-darker;
              }
            }
          }
        }
      }

      .grid-product-status {
        margin-bottom: 25px;
      }

      .bottom-gap {
        margin-bottom: 5px;
      }
    }

  }

  .product-tag {
    font-weight: bold!important;
    text-transform: capitalize;
    font-size: 16px;
    display: grid;
  }

  .product-tag-price {
    font-size: 16px;
  }

}

.blur-effect {
  opacity: 0.3;
  transition: all linear 0.2s;
}

.customize-btn {
  &:not(.no-border) {
    border: 1px solid variables.$gray-lighter;
  }

  &.customize-sec-btn {
    border: 1px solid variables.$brand-primary;
    color: variables.$brand-primary;
    background-color: transparent;

  }

  &.customize-sec-btn:hover {
    background-color: variables.$brand-primary;
    color: #ffffff;
  }
}

.span-desc {
  color: variables.$input-color-placeholder;
  line-height: 15px;
}

.add-product {
  padding: 15px;

  &.custom-add-product-main {
    padding: 15px 0;
  }

  &.custom-add-product {
    padding: 30px 0;

    .product-add {
      padding: 0 30px;
    }
  }

  .product-filter h3 {
    font-weight: 400;
  }

  .package-list {
    .product-cat-info {
      background: variables.$gray-dark;
      color: variables.$white;
      padding: 5px 15px 1px;

      md-icon,
      svg {
        height: 24px;
        width: 40px;
      }
    }

    .package-separator {
      border-left: 1px solid variables.$gray-light;
      margin: 20px 0 20px 35px;
      position: relative;

      .category-img {
        position: absolute;
        left: -23px;
        top: -10px;

        .cat-name {
          margin-left: 10px;
        }
      }

    }

    .product-direction-arrow {
      position: absolute;
      top: 75px;
      left: -12px;
      border-radius: 50%;
      background: variables.$white-dark;
      padding: 2px;

      md-icon {
        height: 20px;
        width: 20px;
        padding-top: 5px;

      }
    }

    .package-end {
      height: 15px;
      width: 15px;
      background-color: variables.$brand-accent-dark;
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: -7px;
    }

    .package-index {
      @extend .package-end;

      &.active {
        background: variables.$brand-info;
      }
      position: relative;
      background: variables.$white;
      margin-right: 10px;
      height: 20px;
      width: 20px;
      margin-left: 11px;
      left: 0;

      span {
        position: absolute;
        font-size: 12px;
        color: variables.$gray-base;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

    }

    .catlog-name {
      vertical-align: super;
    }
  }

  .catalog-information {
    overflow-x: scroll;
    font-size: 14px;

    &.back-head {
      background-color: variables.$brand-accent-dark;
      padding: 15px;
      color: #fff;
    }

    &.border-bottom {
      border-bottom: 1px solid #4c92a7;
    }

    strong {
      font-size: 16px;
      margin-right: 5px;
    }

    .opacity-light {
      opacity: 0.5;
    }

    md-icon {
      height: 40px;
      width: 40px;

      svg {
        fill: #fff;
        height: 50px;
        width: 40px;

      }
    }

    .pack-item-section {
      width: 100%;
      min-width: 130px;
    }

  }

  .product-card {
    width: 250px;
    border: 1px solid #fff;

    .ico-tick {
      position: absolute;
      top: 0;
      right: 4px;
      width: 14px;
    }

    &.select-product {
      border: 1px solid variables.$brand-primary;
    }

    // .product-index {
    //   background: #52758d;
    //   padding: 4px;
    //   border-radius: 10px;
    //   margin: 5px 0 5px 5px;

    //   &.product-index-active {
    //     background: #ecf4f4;
    //   }
    // }

    &.select-product-package,
    &:hover {
      border: 1px solid #4cb748;

      .ico-tick {
        position: absolute;
        right: -10px;
        top: -10px;
        width: 24px;
        height: 24px;
      }

      .package-product-price {
        color: #4cb748;
      }
    }

    &:hover {
      box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 5px 8px 0 rgba(0, 0, 0, .14), 0 1px 14px 0 rgba(0, 0, 0, .12);
    }

    .md-button {
      display: none;
    }
  }

  .product-selection-list {
    padding: 80px 0;

    ul {
      li {
        // margin: 5px 5px 20px 5px;
        padding-right: 15px;

        &:not(.disabled):hover {
          md-card {
            .ico-tick,
            .product-content,
            .product-image {
              // display: none;
            }

            .md-button {
              display: block;
            }
          }
        }
      }
    }
  }

  .cat-package-product {
    margin: 20px 0 0 38px;
  }

  .ui-accordion .panel-group {
    .panel {
      border-width: 0;

      &:not(.panel-open):last-of-type .panel-title > a {
        border-bottom-width: 0;
      }
    }
  }

  .product-desc {
    margin-right: 15px;

    .cancel-items {
      color: variables.$gray-base;
      margin-bottom: 12px;
    }

    span.cancel-item-product {
      color: variables.$gray-base;
      font-size: variables.$font-size-base;
      font-weight: bold;
    }

    span.description {
      color: variables.$gray-base;
      line-height: 20px;

      &.description-product {
        color: variables.$gray-base!important;
      }
    }

    a {
      span {
        color: variables.$brand-primary!important;
      }
    }

    span:not(.cancel-item-product),
    span:not(.cancel-item-product.description),
    span:not(.description) {
      color: variables.$input-color-placeholder;
      line-height: 20px;
    }

    h1 {
      margin-top: 0;
    }

    h3 label.outOfStock {
      color: variables.$brand-danger-dark;
      font-size: variables.$font-size-small;
    }

    .product-qt {
      margin: 15px 0 0;
      white-space: normal;
      display: block;

      label {
        display: inline-block;
      }

      .quantity {
        display: inline-block;
        // margin: 0 20px;
      }
    }
  }

  .editional-desc {
    .eprod-desc {
      font-size: 12px;
      color: variables.$input-color-placeholder;
      margin-bottom: 10px;

      label {
        color: variables.$gray-base;
      }
    }
  }
}

.product-add {
  &:not(.no-padding) {
    padding-bottom: 15px;
  }

  .product-detail {
    .product-image {
      margin: 0 15px 15px 0;

      .thumbnail-images {
        .left-arrow,
        .right-arrow {
          display: inline-block;
          margin-top: 0;
          vertical-align: middle;
          padding: 5px;

          md-icon {
            width: 8px;

            svg path {
              fill: variables.$btn-default-border;
            }
          }
        }
      }

      .image-tabs {
        display: inline-block;
        vertical-align: middle;
        max-width: 153px;

        ul {
          margin: 12px 0;

          li {
            width: 49px;
            min-width: 49px;
            display: inline-block;
            border: 1px solid variables.$btn-default-border;
            border-radius: 3px;
            margin-right: 5px;
            padding: 5px;
            transition: border-color 1s;

            &.activeIndex,
            &:hover {
              border-color: variables.$brand-primary;
            }

            img {
              width: 100%;
            }
          }
        }
      }
    }
  }

  // @media (min-width:= 1280px) {
  //   &.product-add-section-1 {
  //     .enter-item-count-cta {
  //       flex-wrap: nowrap;
  //       flex: 44%;
  //     }
  //   }
  // }
}

.package {
  &.slider-container {
    width: calc(100% - 120px);
    margin: 5px auto;
  }

  .left-arrow,
  .right-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    svg path {
      fill: variables.$btn-default-border;
    }

    md-icon {
      height: 18px;
      width: 18px;
    }
  }

  .left-arrow {
    left: -60px;
  }

  .right-arrow {
    right: -60px;
  }

  .badge {
    position: absolute;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin: 0 10px 10px 0;
      border: 1px solid variables.$btn-default-border;
      width: 180px;
      min-height: 108px;
      border-radius: 3px;
      padding: 10px;
      cursor: pointer;
      text-align: center;

      &.slider-item {
        float: none;
        margin: 0 10px 0 0;
      }

      &.smaller {
        width: 135px;
      }

      &.active {
        border: 2px solid variables.$brand-primary;
        color: variables.$brand-primary;
      }

      .select-check {
        position: absolute;
        left: 10px;
        top: 10px;
      }

      .package-name {
        margin: 6px auto;

        img {
          @extend .img-circle;
        }
      }

      .product-image {
        margin: 9px;

        img {
          @extend .img-circle;
          border: 1px solid variables.$gray-light;
        }
      }
    }
  }
}

.product-description-section {
  .form-base {
    width: 100%;
  }
}

.product-fulfillment-section {
  // .f1{
  //   font-size: 16px;
  // }
  // .product-detail{
  //   .product-image{
  //     margin-right: 80px;
  //   }
  // }
  .custom-img-cover {
    padding: 20px;
    background-color: #d3e1e54a;
    border: 1px solid variables.$brand-primary;
    position: relative;

    .arrow-img-thumbnail {
      position: absolute;
      left: 199px;
      top: 12px;
      overflow: visible;
      width: 30px;
      height: 30px;
    }
  }

  .custom-thumbnail-images {
    .image-tabs {
      ul {
        li {
          padding: 5px;
        }
      }
    }
  }

  .custom-product-desc {
    span.cancel-item-product {
      font-weight: 500;
      font-size: 16px;
    }

    span.description.description-product {
      text-transform: capitalize;
      font-size: 16px;
    }
  }

  .review-capsule {
    background-color: transparent;
    border: 1px solid #fe9603;
    padding: 8px 15px;
    border-radius: 50px;
    color: #fe9603;
    font-weight: 600;
    line-height: 10px;
    // text-align: left;
    max-width: 250px;
    min-width: 250px;
  }

  .fullfilment-custom-flag {
    background-color: #d3e1e54a;
    border: 1px solid #b7b7b7;
    padding: 8px 8px 30px;
    min-width: 300px;
    max-width: 350px;
    width: 100%;

    .inner-section {
      background-color: #ffffff;
      padding: 8px;

      .custom-product-desc {
        span.cancel-item-product.font-bold {
          font-weight: 600;
        }
      }
    }
  }

  .text-white {
    color: #ffffff;
  }
}

.fullfilment-item-setcion {
  margin-bottom: 7rem;

  .fullfilment-item-setcion-header-title .f1 {
    font-size: 16px;
  }

  .fullfilment-item-setcion-header {
    background-color: #000;
    padding: 10px 30px;

    .fullfilment-icon-img {
      width: 45px;
      height: 45px;
      margin-right: 15px;
      background: #fff;
      border-radius: 30px;
    }

    .type-box {
      padding: 8px 16px;
      color: #000000;
      cursor: pointer;
      background: #fff;
      //margin-bottom: 15px;
      gap: 10px;
      font-size: 18px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12);

      .second_content {
        font-size: 14px;
        color: #666666;
        margin-top: 5px;
      }

      .order-status-icon {
        width: 35px;
        height: 35px;
        margin-right: 15px;
      }
    }
  }

  .fullfilment-item-section-body {
    .form-base-header {
      background-color: #d3e1e54a;
      padding: 20px;
      border-bottom: 10px solid #2b3e50ff;

      .capsule-tab {
        background-color: #2a2a2aff;
        border: 1px solid #5e696c;
        color: #fff;
        font-size: 13px;
        padding: 7px 35px;
        border-radius: 50px;
        position: relative;
      }

      .icon-next-arrow {
        width: 45px;
        height: 45px;

        svg path {
          fill: #fe9603;
        }

        &.opened {
          rotate: -180deg;
        }
      }

      .icon-next-arrow.add-serial-icon {
        &.opened {
          rotate: -90deg;
        }
      }
    }

    .form-base-body {
      background-color: #ffffff;
      padding: 20px;
    }

    .item-location {
      color: #666666;
      position: relative;
      border: 1px solid #527b9a;
      background-color: #d3e1e54a;

      span {
        font-size: 14px;
        padding: 5px;

        &.stock-level-section {
          font-size: 18px;
        }
      }
      // .location-symbol{
      //   min-width: 75px;
      //   height: 23px;
      //   font-size: 14px;
      //   line-height: 1.8;
      // }
      .icon-tick {
        position: absolute;
        right: -12px;
        top: -11px;
        width: 28px;
        height: 28px;

        svg .path1,
        svg .path2 {
          fill: #00ba00;
        }

        svg .path3 {
          fill: #ffffff;
        }
      }

      .data-head-section {
        font-size: 14px;
        text-align: left;
        margin-bottom: 10px;
      }

      .data-head-section,
      .data-value-section {
        max-width: 100px;
        min-width: 100px;
      }

      .data-value-section {
        color: #20394c;
        font-size: 16px;
        text-align: left;
      }
    }

    .heading_title {
      font-size: 14px;
    }

    .form-base-body-content-title {
      font-size: 14px;

      span {
        font-size: 14px;
        color: #666666;
      }
    }

    .logistic-option-div {
      width: 100%;
    }

    .order-status-icon {
      width: 80px;
    }

    .total-amount {
      font-size: 20px;
      color: #20394c;
    }

    .quantity {
      position: relative;
      left: -15px;

      input {
        border: 2px solid #fe9603;
        height: 38px;
        font-size: 14px;
        width: 130px;
        text-align: center;
        color: #666666;
      }
    }

    .serial-dropdown.dropdown {
      position: relative;
      display: inline-block;
      float: inline-end;

      &.delivery-option {
        width: 100%;

        .item-location {
          border: 1px solid #fe9603;
          padding: 10px;
          background-color: #ffffff;

          &.selected {
            border-bottom: 10px solid #fe9603;
          }
        }
      }

      .dropdown-content {
        position: absolute;
        background-color: #f1f9fc;
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, .2);
        z-index: 999;
        padding: 15px;
        right: 10px;
        top: 40px;

        &.dropdown-content-custom {
          padding: 0;
        }

        @media (max-width: 600px) {
          &.serial-sidenav-dropdown {
            right: -106px;
          }
        }
      }

      .manage-serial-section {
        .serial-id,
        .serial-name,
        .serial-part-no {
          color: #666666;
        }

        .serial-no {
          background-color: #2a2a2a;
          font-size: 16px;
          text-align: center;
          padding: 10px;
          width: 35px;
          color: #ffffff;
          border-radius: 50px;
          height: 35px;
        }
      }

      .dropdown-content .type-box .type-box-cta {
        color: #000000;
        background-color: #ffffff;
        border: 1px solid #fe9603;
        padding: 10px;
        width: 100%;
        line-height: 16px;
        //display: block;
        .second_content {
          font-size: 14px;
        }

        md-icon {
          width: 20px;
          height: 18px;
        }

        &:hover {
          color: #fe9603;
        }

        &.selected {
          color: #fe9603;
        }
      }

      .btn-primary {
        // color: #ffffff;
        // background-color: #fe9603;
        // text-align: center;
        // text-decoration: none;
        // margin: 4px 2px;
        // cursor: pointer;
        // padding: 10px 10px;
        // min-width: 110px;
        // width: 100%;
        // font-size: 14px;
        // &.manage-serial-cta{
        //   min-width: 100px;
        //   max-width: 130px;
        // }
        .icon-next-arrow {
          width: 10px;
          height: 10px;
          margin-top: 0;

          svg path {
            fill: #ffffff;
          }
        }
      }
    }

    .dropdown-content {
      display: block;

      .item-location {
        &:hover {
          cursor: pointer;
        }

        &.selected,
        &:hover {
          border: 1px solid #fe9603;
          border-bottom: 10px solid #fe9603;
        }

        span {
          color: #000;
        }
      }

    }
  }

  &.fullfilment-transaction-section {
    .stock-full-status {
      width: 100%;
    }

    .table-responsive {
      padding: 15px 30px 0;

      .transaction-date-section {
        font-size: 14px;
        color: #666666;
      }
    }

    .index-number {
      background-color: #2a2a2a;
      color: #fff;
      width: 30px;
      height: 30px;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      text-align: center;
      vertical-align: middle;
    }

    .transaction-type-section {
      span {
        font-size: 14px;

        &.swapped {
          color: #0000ff;
        }

        &.delivered {
          color: #39a82d;
        }

        &.returned {
          color: #f45150;
        }
      }
    }

    .stock-quantity-section {
      span {
        background-color: #2b3e50;
        color: #fff;
        font-size: 14px;
        width: 70px;
        height: 30px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
      }
    }

    .fulfilled-serial-table {
      thead {
        background-color: #cfe2f3ff;
      }

      .serial-action-icon {
        width: 16px;
        margin-left: 0;
      }

      .light-grey {
        color: #666666;
      }

      .fulfilled-data {
        font-size: 14px;
      }
    }

    .serial-icon {
      width: 30px;
    }

    .stock-level-section {
      font-size: 18px;
    }

    .btn-secondary {
      border: 1px solid variables.$brand-primary;
      color: #666666;
      background-color: #ffffff;
    }

    .btn-primary,
    .btn-secondary {
      min-width: 150px;
      font-size: 14px;
      font-weight: bold;
      padding: 5px;
    }

    .table-section {
      padding: 10px 15px;

      .table-card-section {
        border: 1px solid #cccccc;
        background-color: #ffffffff;
        margin-bottom: 15px;

        .table-row {
          .icon-next-arrow.add-serial-icon {
            rotate: 90deg;
          }

          &.opened {
            .icon-next-arrow.add-serial-icon {
              rotate: -90deg;
            }
          }
        }
      }
    }

    .table-head {
      padding: 15px;
      background-color: #d6e1e5;
    }

    .data-table {
      width: 100%;
      min-width: 1180px;
    }

    .table-head,
    .table-row {
      display: flex;
      align-items: center;
      //border-bottom: 1px solid #e0e0e0;
      padding: 8px 0;
    }

    .table-cell {
      flex: 1;
      text-align: center;
      padding: 8px 0;
    }

    .table-head .table-cell {
      font-weight: bold;
      text-transform: uppercase;
    }

    .table-section {
      background-color: #fafafa;
    }

    .location-symbol {
      display: inline-block;
    }

    .sort-icon {
      vertical-align: middle;
      margin-right: 4px;
    }

    .icon-next-arrow {
      cursor: pointer;
    }

    .dropdown-content {
      padding: 16px;
      margin-top: -1px;
    }

    .dropdown-content.show {
      display: block;
    }

    .form-base-header {
      background-color: transparent!important;
      border: none;
      padding: 16px;
      //border: 1px solid #e0e0e0;
    }

    .item-location {
      padding: 8px;
      border: none!important;
      margin-bottom: 8px;
      cursor: pointer;
    }

    .item-location.selected {
      background-color: #e0f7fa;
    }

    .data-head-section,
    .data-value-section {
      flex: 25%;
      text-align: center;
    }
  }

  .fulfillment-transactions-display {
    display: none !important;

    &.opened {
      display: block !important;
    }
  }

  .btn-hover,
  .btn-hover:hover {
    background: transparent;
  }
}

.attachment-not-active {
  svg path {
    fill: #999 !important;
  }
}

.attachment-active {
  svg path {
    fill: variables.$brand-primary !important;
  }
  border: 2px solid variables.$brand-primary !important;
}

.icon-transaction-note {
  svg {
    height: 24px;
    width: 26px;
  }
}

.fulfillment-sidenav {
  .f4 {
    font-size: 15px;
  }

  .f3 {
    font-size: 14px;
  }

  md-input-container label {
    font-size: 16px;
  }

  .fulfillment-sidenav-select {
    .md-select-value span {
      padding: 18px;
    }
  }

  .serial-list-view {
    padding-left: 5px;
    padding-right: 5px;
  }

  .search-bar {
    md-autocomplete-wrap {
      border: 1px solid variables.$brand-primary;
    }
  }

  .sidenav-product-title {
    max-width: 265px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 15px;
  }

  .available-status {
    color: #00ba00;
  }

  .md-menu {
    .md-button.md-icon-button {
      margin: 0;
      padding: 0;
      width: 18px;
      line-height: 20px;
    }
  }

  .bottom-desc {
    background-color: #2b3e50ff;
    color: #ffffff;
    padding: 10px 25px 30px;
    text-align: left;
    font-size: 18px;
  }

  .bottom-button-div {
    padding: 5px 20px;

    .md-button {
      max-width: 150px;
      width: 100%;
    }
  }

  md-checkbox {
    min-height: 25px;
  }

  .filter-dropdown {
    rotate: -272deg;
    width: 20px;
    height: 20px;

    &.opened {
      rotate: -90deg;
    }
  }
}

.img-cover {
  border: 1px solid variables.$btn-default-border;
  width: 200px;
  height: 200px;
  border-radius: 3px;

  .add-to-img {
    position: absolute;
    bottom: 37px;
  }

  img {
    // border: 1px solid $gray-lighter;
    // border-radius: 50%;
    // height: 120px;
    width: 100%;
  }
}

.max-min-qt {
  margin-top: 15px;

  div {
    display: inline-block;

    &.stats {
      margin-top: 10px;
    }

    h3 {
      display: inline-block;
      margin-bottom: 0;
    }

    .badge {
      margin: 0 10px;
      background: variables.$brand-accent;
    }
  }
}

.package-variation {
  min-height: 500px;
  max-width: 100%;
  width: 650px;

  .desc {
    color: variables.$input-color-placeholder;
    line-height: 20px;
  }

  .attribute {
    margin-top: 20px;

    label {
      font-size: variables.$font-size-base;
    }

    .avail-stock {
      display: inline-block;
      margin-left: 15;

      .badge {
        display: inline-block;
        width: 35px;
      }
    }

    .atr-type {
      ul {
        list-style: none;
        padding: 0;

        li {
          display: inline-block;
          padding: 10px 14px;
          border: 1px solid variables.$brand-primary;
          color: variables.$brand-primary;
          margin-right: 5px;
          border-radius: 3px;

          &.active {
            background: variables.$brand-primary;
            color: variables.$white;
          }
        }
      }
    }
  }
}

.left-catalog,
.right-catalog {
  h3 {
    margin-top: 0;
  }
  border: 1px solid variables.$btn-default-border;
  padding: 15px;
  border-radius: 3px;
}

.left-catalog {
  .badge {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.product-detail-sidenav {
  .package-pro {
    ul {
      padding: 0 15px;
      list-style: none;

      li {
        &:not(:last-child) {
          .cat-list {
            border-left: 1px solid variables.$btn-default-border;
          }
        }

        .cat-list {
          position: relative;
          margin-left: 20px;
          padding-bottom: 20px;

          .product-detail {
            .product-image {
              @extend .cursor-point;
              position: absolute;
              left: -23px;
              top: 0;
              width: 46px;
              height: 46px;
              vertical-align: middle;

              .icon-tick {
                position: absolute;
                top: -5px;
                right: 0;
                width: 16px;
              }

              img {
                // width: 100%;
                // height: auto;
                // border-radius: 50%;
                @extend .img-circle;
                border: 1px solid variables.$btn-default-border;
              }
            }

            .prod-desc {
              margin-left: 35px;
              vertical-align: middle;

              .p-detail {
                margin-bottom: 10px;
              }

              .p-detail-half,
              .title-view {
                margin-bottom: 5px;
              }

              .title-view {
                color: grey;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 960px) {
  .product-add .editional-desc {
    // margin-top: 20px;
    margin-left: 45px;
  }

  .add-product .product-content {
    max-width: 80%;
  }
}

@media screen and (min-width: 767px) {
  .list-grid {
    br.hide-gt-small {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .list-grid {
    .list-view {
      .left-option .product-detail {
        margin-top: 10px;
      }

      .right-option {
        text-align: right;

        .cart-quantity {
          margin: 0 -11px 0 0;
        }

        .price {
          display: inline-block;
          margin-right: 10px;
          text-align: right;

        }
      }
    }
  }

  .product-selection-list.active ul li {
    margin: 20px;
  }
}

@media screen and (min-width: 531px) and (max-width: 768px) {
  .list-grid .list-view .right-option .action-btns {
    display: flex;
    flex-direction: row-reverse;
  }
}
@media screen and (max-width: 1200px) {
  .list-grid {
    .product-tag-price {
      font-size: 14px;
    }

    .list-view {
      .left-option {
        .product-detail.product-detail-exp {
          max-width: 350px;
        }
      }

      .regular-price {
        max-width: 120px;
      }
    }
  }
}
@media screen and (max-width: 960px) {
  .list-grid {
    .list-view {
      .left-option {
        .product-detail.product-detail-exp {
          max-width: 355px;
        }
      }

      .right-option {
        margin-top: 10px;
        margin-left: 65px;

        .regular-price {
          max-width: 400px;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .list-grid {
    .list-view {
      .left-option {
        .product-detail.product-detail-exp {
          max-width: 500px;
        }
      }

      .right-option {
        margin-top: 10px;
        margin-left: 65px;

        .regular-price {
          max-width: 400px;
        }

        .product-tag-price {
          font-size: 16px;
        }
      }
    }
  }
}
@media screen and (max-width: 610px) {
  .list-grid {
    .list-view {
      .left-option {
        .product-detail.product-detail-exp {
          max-width: 240px;
        }
      }

      .product-tag-price {
        font-size: 16px;
      }

      .regular-price {
        max-width: 400px;
        text-align: left;
        margin-top: 10px;
        margin-left: 65px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .product-add .editional-desc {
    margin-top: 15px;
  }

  .top-header {
    ul {
      padding: 0;

      li {
        padding: 0 8px;
      }
    }

    .location {
      position: static;
    }
  }

  .list-grid .list-view {
    .right-option {
      .price {
        text-align: right;
        display: block;

        &~.cart-quantity {
          margin-top: 10px;
        }
      }
    }
  }
}

.product-cart-detail {
  display: block;
  margin: 15px;
  min-width: 1600px;
  overflow-x: auto;

  &.cartdash-product-cart-detail {
    .cart-detail {
      .product-border-col.product-border-col-custom {
        padding-bottom: 65px;
      }

      .view-job-list-img {
        top: 50px;
      }
    }
  }

  .col-min {
    min-width: 95px;
    max-width: 95px;
    // padding-left: 10px;
  }

  .col-min-150,
  .col-min-200,
  .col-min-400 {
    display: inline-block;
    vertical-align: middle;
    // padding-bottom: 10px;
    // padding-left: 20px;
  }

  .col-min-150 {
    min-width: 150px;
    max-width: 150px;
  }

  .col-min-200 {
    min-width: 200px;
    max-width: 200px;
  }

  .col-min-400 {
    min-width: 400px;
    max-width: 400px;
  }

  .col {
    @extend .col-min;
    // @extend .text-center;
    display: inline-block;
    vertical-align: middle;
    padding-left: 10px;
  }

  .cart-header {
    border-bottom: 1px solid variables.$border-color;
    background: variables.$border-color;
    font-weight: bold;

    .head-col {
      @extend .col;
      padding: 10px;
    }
  }

  .cart-detail.cart-border-full {
    margin-top: 20px;
    // margin-bottom: 20px;
  }

  .cart-border-full {
    border-bottom: 1px solid variables.$border-color;
  }

  .cart-detail.cart-border {
    @extend .cart-border-full;
    margin-left: 200px;
    // margin-bottom: 10px;
  }

  .cart-detail.connect.line-connector {
    .product-border-col {
      border-left: 1px solid variables.$gray-light;
    }

  }

  .cart-detail {
    .cart-product-image.job-cart-product-image {
      width: 65px;
    }

    .view-job-list-img {
      position: relative;
      top: 38px;
      right: 30px;
      color: variables.$brand-primary;
      background-color: white;
      padding: 4px 0;
    }

    .product-name-rel {
      position: absolute;
      top: 45%;
      // left:10px;
      transform: translateY(-50%);
    }

    .grey {
      color: variables.$gray-child;
    }

    .location .location-symbol {
      margin-bottom: 2px;
    }

    .product-border-col {
      &.grey md-checkbox .md-label {
        color: variables.$gray-child;
      }

      &.instructions {
        padding-bottom: 10px;
      }

      &.static-border {
        border-left: 1px solid variables.$gray-light;
      }
      margin-left: 133px;
      padding-bottom: 35px;
      // border-left: 1px solid $gray-light;
      position: relative;
      top: 10px;

      &.product-border-col-custom {
        padding-bottom: 50px;
      }
    }

    .product-info {
      margin-left: 200px;

      li {
        display: inline;
        margin-right: 15px;
      }
    }

    md-checkbox {
      margin-bottom: 0;

      .md-label {
        color: variables.$gray-base;
      }
    }

    .md-label-delivery {
      color: variables.$gray-base;
    }

    .cart-product-image {
      vertical-align: middle;
      width: 60px;
      display: inline-block;

      .icon-tick {
        position: absolute;
        width: 15px;
        top: -5px;
        left: 5px;
        z-index: 1;
      }

      img {
        @extend .img-circle;
        border: 1px solid variables.$gray-light;
        position: absolute;
        top: 0;
        left: -25px;
        background: variables.$white;
      }

      .replacer {
        position: absolute;
        top: 0;
        left: -25px;
        // background: $white;
      }
    }

    .f12 {
      font-size: variables.$font-size-base;
    }

    .product-name {
      font-size: variables.$font-size-base;
      color: variables.$brand-primary;
    }

    .gap-hard {
      text-align: right;
      padding-right: 25px;
    }
  }
}

.product-cart-fulfillment {
  &:not(.no-padding) {
    padding: 20px;
  }

  li {
    display: inline-block;
  }

  .fulfill {
    width: 100%;
    max-width: 260px;
    border: 1px solid variables.$border-color;
    padding: 20px;
    margin-bottom: 20px;
    transition: box-shadow 1s;

    &.work-board-box {
      width: 130px;
      max-width: 100%;
      margin-right: 15px;
    }

    &:not(:last-child) {
      margin-right: 10px;
    }

    &.active,
    &:not(.no-hover):hover {
      box-shadow: 0 0 10px rgba(30, 30, 30, 0.2);
    }

    .fulfillment,
    .fulfillment-type,
    .job-fulfillment-type {
      padding-bottom: 10px;
    }

    .fulfillment-type {
      color: #666;
    }

    .fulfillment {
      .fullfill-qty {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  .stock-detail {
    @extend .fulfill;
    max-width: 230px;
    padding: 8px;
  }
}

.job-stockreview-tr {
  td {
    vertical-align: middle;
  }
}

.add-product {
  .product-info {
    margin-bottom: 15px;
    font-size: variables.$font-size-base;

    label {
      font-weight: bold;
    }
  }

  .selected-item {
    padding: 20px;

    .number-of-item {
      font-size: 20px;
      margin: 0 5px;
    }

    .item-selected {
      font-size: 12px;
      color: variables.$gray;
    }

    .select-head {
      margin-right: 40px;
    }

    .progress-circle {
      width: 45px;
      height: 45px;
      border: 4px solid variables.$gray;
      border-radius: 50%;
      position: relative;

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 10px;
      }
    }
  }

  .package-pro-list {
    width: 220px;
    margin-bottom: 15px;
  }

  .product-package-detail {
    span {
      color: variables.$gray;
      margin-right: 5px;
    }

    label {
      color: variables.$gray-dark;
    }

  }

  .btn-action {
    margin-top: 10px;
  }

  .quick-add {
    .customize-btn {
      margin: 0;
      font-size: 12px;
      height: 25px;
      min-height: 25px;
      line-height: normal;

      &.custom-btn {
        background-color: #fff;

        &:hover {
          border: 1px solid variables.$brand-primary;
          color: variables.$brand-primary;
        }
      }

      md-icon {
        height: 15px;
        width: 15px;
      }

      svg {
        stroke: variables.$white;
        // margin-top: -2px;
      }
    }

  }

  .item-location {
    border: 1px solid variables.$gray-base;
    padding: 11px;
    margin-bottom: 20px;

    .head {
      font-weight: bold;
      padding: 5px;
      font-size: 14px;
    }

    // .margin-right {
    // margin-right: 10px;
    // }
  }

  .order-fulfillment .prod-select {
    label {
      font-weight: bold;
      font-size: variables.$font-size-base;
    }

    .order-status {
      display: inline-block;

      .fix-gap {
        margin: 0;
        width: 170px;
      }
    }
  }

  .detail-view {
    .item-chipset li {
      display: inline-block;
      margin-right: 10px;
    }

    .doc-list {
      .chipbox {
        &:before {
          content: '';
          display: inline-block;
          background: url("#{variables.$image-path}/icons/set-b/ico-pdf.svg") no-repeat 0 0;
          background-position: 0 0;
          background-size: 15px;
          height: 17px;
          margin-right: 6px;
          width: 15px;
          vertical-align: middle;
        }
      }
    }
  }
}

.stock-status {
  &:not(.no-bottom) {
    margin-bottom: 40px;
  }

  &.table-responsive,
  .table-responsive {
    padding: 0;
  }

  .fulfil-custom-btn {
    padding: 5px 12px;
    background-color: variables.$brand-primary;
    border-radius: 13px;

    &,
    &:hover {
      color: variables.$white;
    }

    &[disabled] {
      background-color: variables.$gray;
      cursor: not-allowed;
    }
  }

  .stock-status-head {
    padding: 10px 10px 10px 30px;
    border: 1px solid variables.$border-color;
    background: variables.$border-color;
    font-weight: bold;
    font-size: variables.$font-size-base;
    // margin-bottom: 40px;

    .title {
      padding: 3px 0;
    }

    .attach-note {
      font-size: 8px;

      md-icon {
        width: 15px;
        height: 15px;
      }

      &:not(:last-child) {
        margin-right: 15px;
      }
    }

    .stock-status-indicator {
      width: 100%;
      max-width: 10px;
      padding: 6px;
      position: absolute;
      border-radius: 50%;
      top: 50%;
      transform: translateY(-50%);
      left: 12px;

      &.pending {
        background: variables.$brand-primary;
      }

      &.deliver {
        background: variables.$brand-success-dark;
      }

      &.return {
        background: variables.$status-yellow;
      }
    }
  }

  .stock-full-status {
    @extend .data-table;
    text-align: center;
    border: none;
    border-collapse: separate;
    border-spacing: 0 15px;

    td {
      border: none;
      padding: 0;

      &.collapse-color,
      .collapse-color {
        background-color: variables.$border-color;
      }

      .text-box {
        width: 100%;
        max-width: 70px;
      }
    }

    thead {
      background: none;
      // border-bottom: 1px solid $border-color;
    }

    tbody {
      font-size: variables.$font-size-base;
    }
  }
}

.order-fulfillment {
  margin-left: 30px;
}
@media screen and (max-width: 960px) {
  .order-fulfillment {
    margin-left: 0;
  }
}
@media screen and (max-width: 600px) {
  .product-cart-fulfillment .fulfill.work-board-box {
    width: 100%;
  }

  .product-cart-fulfillment .fulfill.work-board-box .product-cart-fulfillment .fulfill {
    max-width: 100%;
    margin-right: 0;
  }

  .product-cart-fulfillment li {
    display: block;
  }

  .opacity-light {
    margin-left: 38px;
  }
}

.stock-info {
  padding: 15px;

  h3 {
    margin-top: 0;
  }

  .info-title {
    margin-bottom: 5px;
    font-size: variables.$font-size-base;
  }
}

// add product

.quick-add-menu {
  width: 100%;
  padding: 15px;
  max-width: 400px;

  .product-image {
    margin-top: 10px;
  }

  .cart-quantity,
  md-input-container,
  md-select {
    margin: 10px 0;
  }

}

.md-select-menu-container {
  z-index: 999999;
}

.pro-details {
  margin-bottom: 15px;
}

// Product detail popup

.product-popup-details {
  h1 {
    font-size: 18px;
  }

  .list-product-regular ul li {
    width: 160px;
    height: 70px;
    display: inline-block;
  }

  md-dialog-content {
    width: 100%;
    max-width: 800px;
  }

  .main-header {
    background: none;

    .close-btn {
      md-icon {
        width: 20px !important;
        height: 20px !important;
        transform: rotate(45deg);

        svg path {
          fill: variables.$gray !important;
        }
      }
    }

    .product-name {
      color: variables.$gray-dark;
      font-size: 14px;
      margin-left: 10px;
    }

    // img {
    //   height: 40px;
    //   width: 40px;
    //   border-radius: 50%;
    //   border: 1px solid $gray;
    // }

    .md-toolbar-tools {
      border-bottom: 1px solid variables.$border-color;
    }
  }

}

@media(max-width:600px) {
  .product-popup-details .list-product-regular ul li {
    width: 100%;
  }
}

.add-package {
  .product-image {
    .img-cover {
      border: 10px solid #21374c;
      border-top: 12px solid #21374c;
      border-bottom: 12px solid #21374c;
    }
  }

  .product-image-border {
    padding: 5px;
    border: 2px solid #ffcc00;
    border-radius: 40px;
    text-align: center;
  }

  .product-image-rounded {
    width: 35px;
    border-radius: 17px;
    vertical-align: middle;
  }

  .media-button {
    border-radius: 25px;

    &.btn-primary {
      border: 1px solid variables.$brand-primary;
    }

    &.video-button {
      border: 1px solid #f2f2f2;
      background: #f2f2f2;
    }
  }

  .media-button:hover {
    border: 1px solid variables.$brand-primary;
  }

  .package-details {
    background: #d3e1e54a;
    padding: 30px 20px;

    .card-title {
      background: #20394c;
      color: #fff;
      font-size: 14px;
      padding: 0 10px;

      h3 {
        padding: 0 75px;
      }

      .product-image-border {
        background: #fff;
        position: absolute;
        top: -20px;

        md-icon {
          width: 30px;
          height: 30px;
        }
      }
    }

    .product-desc-no-margin {
      margin-right: 0;
    }

    .package-details-text-area {
      border: 1px solid #ccc;
    }

    .calendar-icon {
      width: 20px;
      height: 20px;
    }

    @media (max-width: 959px) {
      md-card {
        // display: inline-block;
      }
    }
  }
}

.choice-selection {
  .choice-selection-header {
    background: #20394c;
    padding: 5px 0 20px;

    md-divider {
      border-color: #ccc;
    }

    .header-section {
      padding: 0 20px 0 25px;
    }

    md-icon {
      width: 30px;
      height: 30px;
    }

    .blue-text {
      color: #63d1dd;
    }

    .cart-item-status {
      padding: 5px 20px;
      background: #fff;
      border-radius: 30px;
      font-size: 12px;
    }

    .package-status {
      padding: 10px 0 10px 38px;

      md-icon {
        width: 20px;
        height: 20px;
      }
    }
  }

  .items-selected {
    .selected-items-count {
      background: #fff;
      border: 3px solid #63d1dd;
      padding: 22px 29px;
      border-radius: 46px;
      display: inline-block;
    }

    .f6 {
      font-size: 18px;
    }
  }

  &.list-view-btn:hover,
  .list-view-btn {
    background: #000;
    padding: 2px;
    border-radius: 47px;
    min-width: 30px;
    min-height: 30px;
    max-height: 30px;
    line-height: 1px;

    md-icon {
      width: 10px;
      height: 10px;

      svg {
        path {
          fill: #fff;
        }
      }
    }

    .grid-view {
      width: 20px;
      height: 20px;
    }
  }

  .filter-btn {
    border: 1px solid variables.$brand-primary;
    border-radius: 40px;
    color: variables.$brand-primary;
    padding: 5px 17px;
    line-height: 1;
    min-height: 0;
  }

  .filter-btn:hover {
    background-color: variables.$brand-primary;
    color: #fff;
  }
}

.selected-items {
  margin: 0 0 70px 70px;

  .section-border {
    max-width: 250px;
    padding: 10px 50px 10px 10px;
    border-left: 2px solid #52758d;
    border-bottom: 2px solid #52758d;

    .selection-tick {
      position: absolute;
      left: -13px;
      margin-top: 10px;
    }
  }

  .selected-items-list {
    background: #ecf4f4;
    padding: 18px 30px 18px 20px;
    border-left: 2px solid #52758d;

    .catalog-selected-item,
    .minimum-selection {
      background: #fff;
      padding: 10px 5px;
      min-width: 150px;
      min-height: 46px;
      border: 2px solid #4cb748;
      border-radius: 4px;

      .package-product-image {
        position: absolute;

        .package-image {
          width: 24px;
          height: 24px;
          position: absolute;
          bottom: 12px;
        }

        .img-replacer {
          line-height: 26px;
          font-size: 10px;
        }
      }

      .ico-tick {
        position: absolute;
        top: -34px;
        right: -150px;
      }
    }

    .minimum-selection {
      background: #eee;
      border: 2px solid #ccc;
      border-radius: 6px;

      img {
        width: 24px;
        height: 24px;
      }

      .ico-tick {
        top: -12px;
        right: -8px;

        svg {
          .path2 {
            fill: #fff;
          }

          .path1 {
            fill: #8c8686;
          }
        }
      }
    }

    .product-card {
      padding: 8px 5px;
      max-width: 200px;
      min-width: 200px;
    }

    .product {
      &.product-img-replacer {
        width: 190px;
        height: 175px;
        border-radius: 5px;
        border: 1px solid #ccc;
      }
    }

    span.product-img-replacer {
      line-height: 160px;
    }

    .product-title {
      font-size: 13px;
      color: #52758d;
    }

    .btn-action {
      padding: 0 5px;
      margin-left: 8px;
      margin-right: 8px;
      max-width: 200px;
      min-width: 200px;
    }
  }
}

.package-accordion-list {
  border-bottom: 2px solid #ecf4f4;
}

// .package-accordion {
//   .ui-accordion .panel-group .panel-heading {
//     .accordion-toggle {
//       padding: 2px;
//       min-height: 0px;
//     }
//     background: #ecf4f4;
//   }

//   .panel-group {
//     width: 100%;
//     .panel-body {
//       border-top: 0 !important;
//       background: #ecf4f4;
//     }
//   }
// }

.catalog-package-list {
  .catalog-filter {
    margin-left: 122px;
  }

  .catalog-header {
    background: #000;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 10px 15px 5px 40px;

    .catalog-title {
      margin-left: 90px;
    }

    &.catalog-header-yellow {
      border-bottom: 3px solid #ffce3a;

      .img-container {
        border: 4px solid #ffce3a;
      }

      .catalog-name {
        color: #ffce3a;
      }

      .catalog-stats {
        border: 1px solid #ffce3a;
      }

      .items-selected {
        background: #ffce3a;
      }
    }

    &.catalog-header-red {
      border-bottom: 3px solid #e06666;

      .img-container {
        border: 4px solid #e06666;
      }

      .catalog-name {
        color: #e06666;
      }

      .catalog-stats {
        border: 1px solid #e06666;
      }

      .items-selected {
        background: #e06666;
      }
    }

    &.catalog-header-green {
      border-bottom: 3px solid #4cb748;

      .img-container {
        border: 4px solid #4cb748;
      }

      .catalog-name {
        color: #4cb748;
      }

      .catalog-stats {
        border: 1px solid #4cb748;
      }

      .items-selected {
        background: #4cb748;
      }
    }

    .img-container {
      position: absolute;
      min-width: 78px;
      max-width: 78px;
      height: 78px;
      border-radius: 50px;
      top: -50px;

      .img-replacer {
        height: 72px;
        width: 72px;
        line-height: 70px;
      }

      img {
        border-radius: 50px;
      }
    }

    .catalog-stats,
    .items-selected {
      border-radius: 5px;
      padding: 6px 20px;
    }
  }
}

.package-accordion {
  display: none;
}

.package-accordion-display {
  display: block;
}

.catalog-arrow-icon {
  position: absolute;
  right: 0;
  top: 40px;

  svg {
    background: #000;
    border-radius: 15px;

    path {
      fill: variables.$brand-primary;
    }
  }

  .open-catalog {
    transform: rotate(180deg);
    transition: 0.3s;
  }

  .view-all {
    border-radius: 40px;
    min-height: 0;
    line-height: 1;
    color: #fff;
    padding: 5px 15px;
    background: #52758d;
    border: 1px solid #52758d;

    svg {
      background: none;
      border-radius: 0;
    }

    md-icon {
      width: 20px;
      height: 18px;

      path {
        fill: #fff;
      }
    }
  }

  .view-all:hover {
    color: #52758d;
    background-color: #fff;

    md-icon {
      path {
        fill: #52758d;
      }
    }
  }

  .view-all.active {
    color: variables.$brand-primary;
    background-color: #fff;
    border-color: variables.$brand-primary;

    md-icon {
      path {
        fill: #000;
      }
    }
  }
}

@media(width < 547px) {
  .catalog-arrow-icon {
    top: 19px;
  }
}

.catalog-status-indicator {
  &.status-red {
    color: #e06666;
  }

  &.status-yellow {
    color: #ffce3a;
  }

  &.status-green {
    color: #4cb748;
  }
}

.catalog-status {
  &.status-red {
    background: #e06666;
  }

  &.status-yellow {
    background: #ffce3a;
  }

  &.status-green {
    background: #4cb748;
  }
}

.bundle-order-fulfillment {
  .transaction-expand-all-cta {
    line-height: normal;
    min-height: 30px;
    height: 30px;
    min-width: 110px !important;
    margin-top: 8px;
  }

  .custom-tabs .md-tab {
    font-weight: bold !important;
  }

  .dropdown-arrow {
    svg {
      fill: variables.$brand-primary;
    }
  }

  .img-cover {
    border: 1px solid variables.$brand-primary;
  }

  .product-desc {
    font-size: 16px;
    color: variables.$gray-base;
    line-height: 0;

    .product-value {
      color: #000 !important;
    }

    .product-label {
      color: #999;
    }
  }

  .additional-description {
    background-color: rgba(211, 225, 229, .2901960784);
    border: 1px solid #b7b7b7;
    padding: 8px 8px 30px;
    min-width: 300px;
    max-width: 350px;
    width: 100%;
    font-size: 16px !important;

    .additional-info {
      background: #fff;

      .delivery-method {
        color: #5cc5c3;
      }
    }
  }

  .navigation-btn {
    border: 1px solid variables.$brand-primary;
    color: variables.$brand-primary;
    border-radius: 20px;
    max-width: 250px;
    min-width: 250px;
  }

  .navigation-btn:hover {
    background: variables.$brand-primary;
    color: #fff;
  }

  .fulfillment-section-header {
    background: #000;
    padding: 10px 30px;

    .fullfilment-icon-img {
      width: 38px;
      background: white;
      border-radius: 38px;
    }

    .text-white {
      color: #fff;
    }

    .type-box {
      background-color: #fff;
      font-size: 18px;

      .order-status-icon {
        width: 35px;
      }

      .second_content {
        color: #666;
        margin-top: 8px;
        font-size: 14px !important;
      }
    }
  }

  .steps-indicator {
    background-color: #2a2a2a;
    border: 1px solid #5e696c;
    min-width: 115px;
    border-radius: 50px;
    padding: 7px;
  }

  .fulfillment-steps {
    padding: 30px 20px 20px;
    background-color: rgba(211,225,229,.2901960784);
    border-bottom: 10px solid #2b3e50;

    .fulfillment-step-cta {
      background: #fff;
    }

    .total-amount {
      font-size: 20px;
    }

    .delivery-option {
      font-size: 14px;
    }

    .fulfillment-location-selection {
      border: 1px solid #527b9a;
      background-color: rgba(211, 225, 229, .2901960784);
      color: #666;
    }

    .location-header {
      font-size: 18px;
    }

    .location-stock-info {
      font-size: 14px;

      .stock-info-value {
        font-size: 16px;
        color: #20394c;
      }
    }

    .location-dropdown {
      font-size: 16px;
    }

    .quantity-cta {
      input {
        border: 2px solid #fe9603;
        font-size: 14px;
        width: 130px;
        text-align: center;
        color: #666;
      }
    }

    .delivery-method-picker {
      border: 1px solid #fe9603;
      padding: 10px;
      background-color: #fff;
      border-bottom: 10px solid #fe9603;

      &.selected {
        md-icon {
          position: absolute;
          top: -10px;
          right: -10px;
        }
      }
    }

    .delivery-method-picker.selected,
    .delivery-method-picker:hover {
      background-color: rgba(211,225,229,0.2901960784);
    }
  }

  .arrow-btn-cta {
    width: 20px;
    height: 15px;
  }

  .kits-btn-primary {
    svg path {
      fill: #fff;
    }
  }

  .kit-index {
    background-color: #2a2a2a;
    text-align: center;
    width: 35px;
    border-radius: 50px;
    height: 35px;
    padding: 8px;
  }

  @media(width > 350px) {
    &.delivery-dropdown {
      width: 300px;
    }
  }

  @media(width > 600px) {
    &.delivery-dropdown {
      width: 480px;
    }
  }

  @media(width > 650px) {
    &.location-dropdown {
      width: 600px;
    }
  }

  .bundle-product-fulfillment {
    background: #527584;
  }

  .cancel-items {
    color: #999;
  }

  .selected-location-tick {
    position: absolute;
    top: -10px;
    right: -10px;
  }

  .auto-complete {
    md-input-container {
      margin: 0;
    }
  }

  .simple-fulfillment {
    color: variables.$brand-primary;

    svg path {
      fill: variables.$brand-primary;
    }
  }

  .desc-line-height {
    line-height: 1.5;
  }
}

.simple-fulfillment-section {
  .icon-tick {
    svg {
      .path1,
      .path2 {
        fill: variables.$gray;
      }

      .path3 {
        fill: #fff;
      }
    }

    &.selected {
      svg {
        .path1,
        .path2 {
          fill: rgb(76, 183, 72);
        }
      }
    }
  }

  .pending-qty-field {
    min-width: 0;
    border: 0;
    font-size: 16px;
    max-width: 30px;
  }

  .clear-input-cta-hidden {
    width: 12px;
    height: 12px;
    visibility: hidden;
  }

  .clear-input-cta {
    visibility: visible;
  }

  .bundle-product-detail {
    max-width: 150px;
    text-overflow: ellipsis;
  }

  .edit-icon {
    width: 15px;
    height: 15px;
  }

  @media(width > 400px) {
    .auto-complete-input-field {
      width: 350px;
    }
  }

}

.bundle-kits-dropdown {
  background-color: #f1f9fc;

  .dropdown-button {
    text-align: left;
    background: #fff;
    border: 1px solid variables.$brand-primary;
    line-height: 24px;
    min-width: 260px;

    md-icon {
      width: 20px;
      height: 20px;
    }
  }

  .dropdown-button:hover {
    color: variables.$brand-primary;
    background: #fff;
  }
}

.bundles-fulfillment-sidenav {
  .steps-cta {
    background: rgba(211, 225, 229, 71%);
  }
}

.fulfillment-product-content {
  .product-fulfilment-info {
    background-color: rgba(211, 225, 229, .2901960784);
  }

  .description-field {
    background: #fff;
    height: 255px;
  }
}

@media (max-width: 1030px) {
  .fulfillment-step-cta.location .quantity-cta {
    flex-direction: column;
  }
}

.product-dashboard-table {
  .head-col-100 {
    max-width: 107px !important;
    min-width: 107px !important;
  }

  .cart-prod-img {
    width: 75px !important;
  }

  .product-name-rel {
    top: 34% !important;
  }

  .product-name-rel-quote {
    top: 24% !important;
  }

  .col-min-100 {
    max-width: 100px !important;
    min-width: 100px !important;
  }

  .product-details-height {
    min-height: 133px;
  }

  .tag-alignment {
    vertical-align: inherit;
  }

  .ellipsis-65 {
    max-width: 65px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.sow {
  .sow-title {
    // min-height: 30px;
    height: auto;
    width: 140px;
  }

  .sow-title-textarea {
    height: 35.88px;
  }

  .edit-icon-parent {
    position: absolute;
    display: none;
    top: -15px;
    right: -15px;
    background: #fff;
    text-align: center;
  }

  .edit-icon-holder {
    // position: absolute;
    visibility: hidden;
    padding: 3px 5px 5px;
    right: -5px;
    bottom: -5px;

    md-icon {
      height: 15px;
      width: 18px;
    }
  }

  &.sow-list-view {
    .editing-title,
    .sow-title {
      border: 1px solid transparent;
      border-radius: 5px;
      padding: 5px;
    }
  }

  &.active-sow,
  &.sow-list-view:hover {
    border: 1px solid variables.$brand-primary;

    .editing-title,
    .sow-title {
      // color: $brand-primary;
      border: 1px solid variables.$brand-primary;
      background-color: #fff;

      .edit-icon-holder {
        visibility: visible;
      }
    }

    .edit-icon-parent {
      border: 1px solid variables.$brand-primary;
      border-radius: 25px;
      width: 30px;
      height: 30px;
      display: inline-block;

      md-icon {
        height: 15px;
        width: 15px;
        position: absolute;
        right: 7px;
        top: 5px;
      }
    }

    .footer-icons-container {
      display: flex;
      // visibility: visible;
    }

    .footer-icons-container-popup {
      // z-index: 111111;
      // right: -16px;
      top: 16px;
    }
  }

  &.sow-active,
  &.sow-list:hover {
    .editing-title,
    .sow-title {
      border: 1px solid transparent;
    }
  }

  &.bulk-popup.sow-list-view {
    background-color: #EEF3F6;
  }

  .list-grid .list-view.active {
    background: #d9ead3;
  }

  .location-container {
    width: 50px;
  }

  .form-base md-switch {
    font-size: 12px;
    line-height: 25px;

    &.md-block {
      .md-container .md-bar {
        height: 22px;
        width: 52px;
      }

      &.md-checked .md-thumb,
      .md-thumb-container .md-thumb {
        height: 18px;
        width: 18px;
      }
    }
  }

  .footer-icons-container {
    position: absolute;
    right: -16px;
    z-index: 111111;
    display: none;

    &.footer-icons,
    .footer-icons {
      width: 30px;
      height: 30px;
      background: #fff;
      border-radius: 30px;
      text-align: center;
      border: 1px solid variables.$brand-primary;

      md-icon {
        width: 18px;
        height: 18px;
        top: 5px;
        right: 5px;

        path {
          fill: #ccc !important;
        }
      }

      &:hover {
        background: variables.$brand-primary;

        path {
          fill: #fff !important;
        }
      }
    }
  }

  .ql-snow {
    background-color: #fff;
    border-color: transparent !important;

    &.ql-toolbar {
      background: #f1f0f0;
    }
  }

  .estimates .ql-snow {
    border-color: variables.$brand-primary !important;
  }

  .import-image {
    width: 20px;
    height: 20px;
  }

  .grid-height {
    min-height: 156px;
  }
}

.sow-tab-index {
  background: #21374C;
  border-radius: 5px;
  padding: 3px 10px;
  font-size: 15px;
}

.sow-empty-state {
  .md-button {
    line-height: 20px;
    min-width: 95px;
    border: 1px solid variables.$brand-primary;
  }
}

.sow-catalog-sidenav {
  min-width: 200px;
  max-width: 200px;
  // .loc-dropdown {
  //   padding: 5px 5px 0;
  //   border: 1px solid #d6e1e5;
  // }
  &.legal-tab-section{
    max-width: 600px;
    margin: 0;
    padding: 0;
  }
  .select-container {
    border: 1px solid #ccc;

    md-select {
      .md-select-value {
        border-bottom: 0;
      }

      .md-select-placeholder {
        color: #000 !important;
        font-weight: bold;
      }
    }
  }

  .catalog-sidepanel-header {
    background-color: #E9F0F3;
  }

  .catalog-btn {
    border: 1px solid #D9D9D9;
    padding: 5px;
    background-color: #E9F0F3;

    .count-badge {
      font-size: 12px;
      width: 40px;
      background-color: #DDECF3;
    }

    .service-count {
      font-size: 12px;
    }

    &.active {
      border-color: variables.$brand-primary;
      background-color: #fff;
    }

    .img-replacer {
      height: 30px;
      width: 30px;
      line-height: 30px;
      font-size: 12px;
    }
  }
}

.edit-sow-panel {
  border-radius: 5px;
}

.sow-ai-popup {
  left: auto !important;
  right: auto !important;
  transition: 0s !important;

  .arrow-filled {
    svg path {
      fill: #000;
    }
  }
}


@media only screen and (max-width: 960px) {
  .service-detail-section{
    .sow-catalog-sidenav{
      .catalog-sidepanel-header {
        padding-left: 58px;
      }
      .top-section{
        padding-left: 30px;
      }
    }
    .legal-tab-section {
      .toggle-icon {
        top: 100px!important;
      }
    }
  }
  .products-landing{
    .sow-catalog-sidenav{
      padding: 0px;
      max-width: 100%;
      h2{
        margin-left: 45px;
        margin-top: 25px;
      }
      .top-section{
        padding: 0 10px 0px 15px;
      }
    }
    .legal-tab-section {
      position: relative;
      .toggle-icon {
       margin-left: 0px!important;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .service-detail-section , .products-landing{
    .order-invoice{
      .legal-tab-section{
        .toggle-icon {
          top: 67px!important;
        }
        .toggle-tab.open {
          top: 45px!important;
          width: 90%!important;
        }

      }
    } 
  }
}