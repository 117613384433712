@use "../../settings/third-party/variables";

.product-attach-section {
  padding: 15px;

  .customkey-list {
    width: 120px;
  }

  .add-btn.right-side {
    position: absolute;
    right: -50px;
    top: 5px;
  }

  .product-list {
    margin-top: 10px;
  }

  .center-align {
    margin: 0 auto;
  }
  // .muted-btns{ position: relative;}
  // .delete-node{
  //    	position: absolute;
  //    	top: -1px;
  //    	right: 0;
  //    	z-index: 2;
  //  &:after {
  // 	    content: '';
  // 	    display: inline-block;
  // 	    position: absolute;
  // 	    top: -5px;
  // 	    right: 5px;
  // 	    height: 12px;
  // 	    width: 12px;
  // 	    background: url(#{$image-path}/icons/set-d/ico-cross.svg) no-repeat center;
  // 	    background-size: 12px;
  // 	    cursor: pointer;
  // 	}
  // }
  .form-base {
    md-input-container {
      input,
      md-select,
      textarea {
        width: 100%;
      }

      textarea {
        max-height: 160px;
        min-height: 60px;
        overflow: auto;
      }
    }

    md-checkbox {
      margin-bottom: 20px;
    }

    md-radio-button {
      margin-bottom: 18px;

      .md-label {
        @extend .f3;
      }
    }
  }

  .switch-bar {
    label {
      margin-right: 12px;
    }
  }

  .md-button.add-btn {
    margin: -5px 0 20px -8px;
  }

  .summery-widget {
    margin-top: 10px;

    .sm-text {
      font-size: 10px;
    }

    md-icon {
      height: 21px;
      margin-right: 8px;
      width: 21px;

      svg path:first-child {
        fill: transparent;
      }
    }
  }

  .item-chips {
    li {
      margin-right: 10px;
      margin-bottom: 10px;

    .icon-close {
      margin-left: 8px;
    }
  }
}

.assigned-products {
  padding: 20px;

  .badge {
    background-color: variables.$gray;
    padding: 0 5px;
    margin: 5px;
  }

  .ico-prohibit {
    svg path {
      fill: variables.$brand-danger;
    }
    margin-top: 7px;
  }

  .warning {
    svg path {
      fill: variables.$brand-danger;
    }
  }

  &.inventory-list-section {
    .inventory-table {
      .data-table {
        .active {
          color: variables.$brand-success; //green:
        }

        .draft {
          color: variables.$brand-primary; //brand-primary:
        }

        .danger {
          color: variables.$brand-danger;
        }
      }
    }
  }
}

.attached_services {
  margin-top: 10px;
}

.input-list-container {
  .list-item {
    margin: 18px 0;
  }

  md-checkbox[disabled] {
    .md-checked {
      .md-icon {
        background-color: none;
      }

      .md-icon:after {
        color: variables.$gray;
      }
    }
  }
}

.product-sidenav {
  .f3 {
    &.count {
      margin: 10px;
    }
  }

  .primary {
    font-weight: 600;
  }

  .active {
    color: variables.$brand-success; //green:
  }

  .draft {
    color: variables.$brand-primary; //brand-primary:
  }

  .danger {
    color: variables.$brand-danger;
  }
}
@media screen and (min-width: 600px) {
  .product-attach-section {
    .summery-widget {
      box-sizing: border-box;
      width: 300px;
    }
  }

  .inventory-addProduct-section {
    &.product-instruction-section {
      .summery-widget {
        box-sizing: border-box;
        width: 300px;
      }
    }
  }
}
@media screen and (min-width: 600px) {
  .attached_services {
    .update-info {
      top: 15px;
      position: relative;
    }
  }
}
}