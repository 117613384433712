@use "../../settings/third-party/variables";
@use "../../settings/custom";

.td-min-width-130 {
  min-width: 130px;
}

.td-min-width-220 {
  min-width: 220px;
}

.quotes-list {
  // .data-table .row-check md-checkbox {
  //   margin-top: 5px;
  // }
  // .c-filter {
  //   cursor: pointer;
  // }
  .quoteid-symbol {
    min-width: 80px;
  }

  .td-min-width-50 {
    min-width: 50px;
  }

  .td-min-width-30 {
    min-width: 30px;
  }

  .td-min-width-70 {
    min-width: 70px;
  }

  .td-min-width-100 {
    min-width: 100px;
  }

  .block-title {
    font-size: 16px;
    font-weight: 400;
    // margin: 8px;
  }

  .td-block {
    display: inline-block;
  }

  .m-header-option {
    text-align: right;
  }
  // .archive-img-container {
  //   height: 50px;
  //   width : 50px;
  //
  //   .user-img-holder {
  //     span {
  //       font-size: 20px;
  //     }
  //   }
  // }
  .quote-image-replacer-edit-contact {
    height: 100px;
    width: 100px;
    padding: 40px 0 0;
    font-size: 48px;
    border-radius: 50%;
    background: #FFE9CF;
    display: inline-block;
    vertical-align: middle;
    // color: $brand-primary;
    color: variables.$brand-primary;
    box-sizing: border-box;
    line-height: 15px;
    text-transform: uppercase;
    cursor: pointer;
  }
  // .loading-next-data {
  //   padding: 0;
  // }

  .overflow-text {
    max-width: 130px;
    @extend .ellipsis;
  }

  .c-filter {
    position: absolute;
    right: 20px;
    top: 18px;
  }
}

._md-datepicker-floating-label._md-datepicker-has-calendar-icon > label:not(.md-no-float):not(.md-container-ignore) {
  right: auto;
}

.filters-bar .date-picker md-input-container .md-input,
.filters-bar md-input-container md-select {
  margin-top: 0;
}

.quotes-list {
  .archive-img-table tbody tr:hover md-checkbox {
    margin-top: 0;
  }

  .filters-bar {
    .filter-label {
      margin-top: 20px;
    }

    md-input-container.filter-input {
      margin-top: 5px;

      .md-input,
      md-select {
        margin-top: 8px;

        md-select-value {
          span {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
