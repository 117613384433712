@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

/* SummeryPage Start */

.submit-section {
  .text-gray-2 {
    color: #717171;
  }

  .spacer-left-35 {
    margin-left: 35px;
  }

  .steps-cta {
    background: #eeeeee;
    padding: 5px 30px;
    border-radius: 20px;
    color: #717171;
  }

  .spacer-right-30 {
    margin-right: 30px;
  }

  .spacer-left-auto {
    margin-left: auto;
  }

  .flag-blue-icon {
    width: 15px;
    height: 15px;
    margin-right: 8px;
  }

  .next-action-txt {
    cursor: pointer;
    color: #06b7f8;
    font-size: 12px;
    letter-spacing: 1px;
  }

  .bottom-txt {
    font-size: 18px;
    font-weight: 600;
  }

  .checked-icon {
    position: absolute;
    right: -14px;
    top: -12px;
  }

  .font-size-16 {
    font-size: 16px;
  }

  .font-size-15 {
    font-size: 15px;
  }

  .pdf-review-icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .gap-40 {
    gap: 40px;
  }

  .spacer-left-50 {
    margin-left: 50px;
  }

  .font-size-11 {
    font-size: 11px;
  }

  .padding-5 {
    padding: 5px;
  }

  .summary-section-container {
    gap: 40px;
    padding: 20px 32px;
  }

  .cost-summary-section,
  .submission-status-section {
    background-color: #486480;
    color: white;
    border-radius: 8px;
    max-width: 500px;
  }

  .cost-summary-section {
    .total-estimate {
      padding: 5px;
      background-color: white;
      color: black;
      width: 50%;
      border-radius: 30px;
      text-align: center;
      margin-right: 30px;
      min-width: 100px;
    }

  }

  .status-box {
    width: 50%;
    padding: 5px;
    background-color: white;
    border: 1px solid;
    border-radius: 30px;
    text-align: center;
    min-width: 100px;
  }

  .customize-invoice-section {
    background-color: #f2f6f7;
    padding: 20px 20px 40px;

    .review-invoice {
      border: 1px solid variables.$brand-primary;
      width: 200px;
      text-align: center;
      background-color: white;
      border-radius: 20px;
      font-weight: 600;
      cursor: pointer;
      color: variables.$brand-primary;
      transition: box-shadow 0.5s, color 0.5s, background-color 0.5s;

      &:hover {
        box-shadow: -1px 1px 6px rgba(0, 0, 0, 0.3);
        color: white;
        background-color: variables.$brand-primary;
      }
    }
  }

  .make-decision-section {
    background-color: rgba(211, 225, 229, 0.71);

    .action-cta {
      background-color: white;
      height: 100%;
      // border: 1px solid $brand-primary;
      width: 200px;
      height: 55px;
      box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.3);
      // font-size: 14px;
      cursor: pointer;
      transition: 1s box-shadow;

      &:hover {
        background: white;
        border-color: variables.$brand-primary;
        box-shadow: 1px 0 6px variables.$brand-primary;
      }
    }

  }

  .select-invoice-section,
  .send-work-order-sec {
    background-color: #f2f6f7;
    padding: 40px 15px 50px;

    .table-head {
      background-color: #2b3e50;

      .th {
        color: white;
      }
    }
  }

  .send-work-order-sec {
    background-color: rgba(211, 225, 229, 0.71);
  }

  .space-gt-xs {
    .margin-right {
      margin-right: 30px;
    }
  }

  @media only screen and (max-width: 600px) {
    .space-gt-xs {
      .margin-btm {
        margin-bottom: 10px;
      }

      .margin-right {
        margin-right: 0;
      }
    }
  }

  .col-2-list {
    // margin-top: 10px;

    .col-list-item {
      padding: 7px 0;

      .summery-status {
        text-align: right;

        label {
          font-weight: 300;
          margin-left: 8px;
        }
      }
    }

    md-divider {
      margin: 10px 0;
    }

    &.status-list {
      label {
        display: inline-block;
        // margin-left: 20px;
      }

      h3 {
        margin-bottom: 3px;
        margin-top: -4px;
      }
    }
  }

  h3 {
    margin: 0;
    font-weight: normal;

    @extend .f1;
  }

  .submit-select {
    margin-top: 0;

    h3 {
      margin-top: 14px;
    }

    .first-title {
      margin-top: 0;
    }
  }

  .txt-space {
    display: inline-block;
    margin-bottom: 6px;
  }

  .tag-btns {
    // margin: 12px 0;

    .md-button {
      border: 1px solid variables.$gray-lighter;
      background: variables.$white;
      // color: $gray;
      line-height: 15px;
      min-height: 42px;
      min-width: 80px;
      margin: 0 10px 5px 0;
      // width: 200px;
      position: relative;
      -moz-box-shadow: 0 0 1px 0 variables.$gray-light;

      /*FF 3.5+*/
      -webkit-box-shadow: -1px 1px 8px 0 variables.$gray-light;

      /*Saf3-4, Chrome, iOS 4.0.2-4.2, Android 2.3+*/
      box-shadow: -1px 1px 8px 0 variables.$gray-light;

      /* FF3.5+, Opera 9+, Saf1+, Chrome, IE10 */

      &:hover:enabled {
        background: white;
        border-color: variables.$brand-primary;
        box-shadow: 1px 0 6px variables.$brand-primary;
      }

      &.active {
        border-color: variables.$brand-primary;
        box-shadow: 0 0 1px 0 variables.$brand-primary;
      }

      &[disabled] {
        background: #eee;
        font-style: italic;
      }
    }

    .email-templates {
      background-color: #374151;
      color: white;

      &:hover {
        color: black;
      }

      &.active {
        background-color: white;
        color: black;
      }

    }
  }

  md-divider {
    margin: 3px 0;
    border-top-color: variables.$white-dark;
  }

  .invoice-selection {
    &.active {
      .invoice-btn .md-button {
        border: 1px solid variables.$brand-primary;

      }

      .invoice-circle {
        border: 1px solid variables.$brand-primary;
      }

    }

    .invoice-circle {
      border: 1px solid variables.$gray-light;
      border-radius: 50%;
      position: relative;
      width: 20px;
      height: 20px;
      background-color: #2b3e50;
      color: white;
      margin-right: 5px;

      .count {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .invoice-btn {
      .md-button {
        font-size: 12px;
        background: white;
        box-shadow: -1px 1px 8px rgba(0, 0, 0, 0.3);
        color: variables.$brand-primary;
        line-height: 15px;
        padding: 5px 25px 5px 5px;

        &:hover:enabled {
          box-shadow: 1px 0 6px variables.$brand-primary;
          background-color: white;
          border: 1px solid variables.$brand-primary;
        }

        &[disabled] {
          background: #eee;
          font-style: italic;
          pointer-events: none;
        }
      }

      .convert-to-job {
        width: 200px;
        padding: 14px 5px;
      }

      .icon {
        width: 30px;
        height: 30px;
        margin-right: 20px;
        margin-left: 8px;
      }

      .bottom-txt {
        font-size: 18px;
        font-weight: 600;
      }

    }
  }

  .invoice-email-email {
    .status-color {
      width: 30px;
      height: 15px;
      display: inline-block;
      margin-right: 5px;

      &.clicked,
      &.opened {
        background: variables.$brand-success;
      }

      &.delivered,
      &.varies {
        background: variables.$mediumPurple;
      }

      &.bounce,
      &.hard_bounce,
      &.soft_bounce {
        background: variables.$brand-danger;
      }

      &.sent {
        background: variables.$brand-primary;
      }

      &.none {
        background: variables.$gray;
      }
    }

    .status-name {
      display: inline-block;
      vertical-align: text-bottom;
    }
  }
}

.submit-md-menu:after,
.submit-md-menu:before {
  right: 28px;
}

.ico-add {
  width: 25px;
  height: 25px;
  margin-left: 10px;

  svg {
    path {
      fill: variables.$brand-primary;
    }
  }
}

.ico-head {
  width: 30px;
  height: 30px;
  margin-left: 10px;

  svg {
    path {
      fill: variables.$gray;
    }
  }
}

@media only screen and (max-width: 600px) {
  .summery-section .col-2-list .col-list-item .summery-status {
    text-align: left;
  }

  .submit-section .col-2-list.status-list label {
    margin-left: 0;
  }

  .ico-head {
    margin-right: 0;
  }
}

/*---Quote Estimate Poppup---*/

.submit-popup {
  min-height: 520px;
  width: 660px;

  // md-toolbar.md-default-theme:not(.md-menu-toolbar), md-toolbar:not(.md-menu-toolbar) {
  //     background-color: transparent;
  //     color: #000;
  // }

  md-toolbar {
    &.main-header {
      h2 {
        font-size: 16px;

        font-weight: normal;
      }
    }
  }

  .mail-section {
    width: 100%;

    .mail-tool-list {
      li {
        border-bottom: 1px solid variables.$white-dark;
        padding: 22px 20px 8px;

        label {
          @extend .f2;

          display: inline-block;
          width: 7%;
          min-width: 50px;
          margin-right: 5px;
        }

        input {
          display: inline-block;
          width: 85%;
          height: auto;
          border: 0;
          background: variables.$white;

          @extend .f1;
        }

        &:last-child {
          margin-bottom: 10px;
        }
      }
    }

    .mail-tools {
      padding: 0 20px;

      .ico-add {
        margin: 5px 5px 5px 0;
      }

      .ico-attach {
        width: 20px;
        height: 20px;
        margin-top: 10px;
        margin-right: 15px;
      }
    }

    .edit-tool {
      margin: 10px 10px 12px;

      img {
        max-width: 100%;
      }
    }

    .mail-box {
      display: block;
      text-align: justify;
      height: 120px;
      max-height: 120px;
      margin-top: 10px;
      overflow-y: auto;
      padding: 0 10px;
      line-height: 1.42857143;
      background-color: variables.$white;
      background-image: none;
      border: 0;
      resize: none;
      width: 100%;

      @extend .f3-light;
    }
  }

  .md-sidenav-right.inside-container {
    bottom: 0;
  }
}

.attached-section {
  margin-left: 5px;

  .muted-btns {
    display: inline-block;
    margin: 5px 0;
    position: relative;

    .delete-node {
      position: absolute;
      top: -1px;
      right: 0;
      z-index: 2;

      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        top: -5px;
        right: 5px;
        height: 12px;
        width: 12px;
        background: url("#{variables.$image-path}/icons/set-d/ico-cross.svg") no-repeat center;
        background-size: 12px;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .submit-popup .ico-add {
    margin-left: 0;
  }

  .submit-popup .header h2 {
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .submit-popup {
    .header .btn-cancel {
      font-size: 0;
      width: 25px;
      min-width: 0;
      height: 25px;
      min-height: 0;
      border: 1px solid #ccc;
      border-radius: 100%;

      &:after {
        content: "x";
        color: #606060;
        line-height: 1.2;
        font-size: 18px;
      }
    }

  }

}

.service-selection {
  .service-button {
    min-width: 200px;
    line-height: 16px;
    border: 1px solid #a6a6a6;
    text-align: left;
    padding: 8px 8px 8px 15px;
    box-shadow: 0 0 1px 0 #a6a6a6;

    &.service-selected {
      border: 1px solid variables.$brand-primary;
      color: variables.$brand-primary;
      box-shadow: 0 0 1px 0 variables.$brand-primary;
    }
  }

  .set-cycle {
    .cycle-dropdown {
      min-width: 200px;
    }
  }
}
