@use "../../settings/third-party/mixins";
@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.search-wrapper .cust-upload-search {
  right: 8px;
}

.customer-upload-section {
  padding: 20px;
  position: relative;

  // .text-red {
  //   color: $brand-danger-dark;
  // }

  .save-msg {
    @include mixins.border-radius(4px);
    color: #0C6614;
    background: variables.$brand-info;
    font-size: variables.$font-size-base;
    padding: 4px 0 4px 10px;
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 11;

    .icon-button {
      margin-right: 2px;
    }

    md-icon {
      height: 16px;
      width: 16px;

      svg path {
        fill: #0C6614;
      }
    }
  }

  .status-displayer {
    margin-top: 0;
    margin-bottom: 10px;

    .main-holder {
      &:after,
      &:before {
        top: 30%;
      }

      &.active {
        .progress-circular-holder {
          &:after {
            border-color: #dbdbdb;
          }
        }
      }

      .displayer-title {
        margin-top: 10px;
      }
    }

    .progress-circular-holder {
      height: 50px;
      width: 50px;

      md-progress-circular {
        height: 43px !important;
        width: 43px !important;

        svg {
          width: 51px !important;
          height: 51px !important;
        }

        .md-inner {
          height: 88px;
          width: 88px;

          .md-gap {
            left: 44px;
            right: 44px;
          }

          .md-left,
          .md-right {
            height: 88px;
            width: 43px;

            .md-half-circle {
              width: 88px;
              height: 88px;
            }
          }
        }
      }

      .progress-number {
        font-size: 16px;
      }
    }
  }

  .table-responsive {
    padding-top: 0;
  }

  .data-table {
    min-width: 780px;

    td {
      @extend .f3;
      //vertical-align: middle;
      &.name-initial-cell {
        padding-right: 0;
        width: 30px;
      }
    }

    .editable-row {
      background: #fafbfc;

      &.no-border {
        td {
          border-bottom: none;
          padding-bottom: 0;
        }
      }

      md-input-container {
        margin-top: 0;
        //margin-bottom: 0;
      }

      .tag-holder {
        margin-top: -5px;
        margin-left: 45px;

        .md-chips {
          margin-bottom: 10px;

          .md-chip {
            font-size: variables.$font-size-small;
          }
        }
      }
    }

    .edit-icon {
      margin-top: -8px;
    }

    .archive-img-container {
      margin-right: 0;
      position: relative;
      top: -4px;
      // .name-initials {
      // 	background: $proposalGreen;
      // 	color: $white;
      // }
    }
  }

  .editable-action {
    text-align: center;
    position: absolute;
    right: 10px;
    bottom: -8px;

    .option-icons {
      padding: 0;
      height: auto;
      width: auto;

      md-icon {
        height: 27px;
        width: 27px;

        &.active {
          svg path {
            &.inner-tick,
            &.outer-circle {
              fill: variables.$brand-success-dark;
            }
          }
        }

        .tick-bg {
          fill: transparent;
        }
      }
    }

    a {
      display: inline-block;
      margin-top: 16px;
    }
  }

  .icon-button {
    height: auto;
    min-height: auto;
    width: auto;
  }

  .edit-icon {
    md-icon {
      height: 28px;
      width: 28px;
    }
  }

  .duplicate-list {
    margin-top: 30px;

    &,
    .inner-sublist {
      @extend .no-style;
    }

    li {
      @include mixins.border-radius(2px);
      border: 1px solid variables.$border-color;
      margin-bottom: 20px;
      padding: 20px;
      -webkit-box-shadow: 2px 2px 2px 0 rgba(214,225,229,0.75);
      -moz-box-shadow: 2px 2px 2px 0 rgba(214,225,229,0.75);
      box-shadow: 2px 2px 2px 0 rgba(214,225,229,0.75);
    }

    .header {
      padding-bottom: 10px;

      md-checkbox {
        margin-bottom: 0;
        margin-right: 40px;
      }

      .btn-primary {
        margin-left: 30px;
        margin-right: 0;
      }
    }

    .inner-sublist {
      li {
        @include mixins.border-radius(0);
        border-width: 0 0 1px 0;
        border-style: solid;
        border-color: variables.$border-color;
        margin-bottom: 0;
        padding-right: 0;
        padding-left: 0;
        box-shadow: none;
      }

      .archive-img-container {
        margin-right: 15px;
      }

      md-radio-button {
        margin-right: 5px;
        margin-bottom: 0;
      }

      .input-container {
        margin-top: 4px;

        &:not(:last-child) {
          margin-bottom: 16px;
        }

        .contact-type-cb1 {
          margin-bottom: 0;
          margin-left: 60px;
          max-width: 20px;

          .md-label {
            left: -88px;
            text-align: right;
            width: 45px;
          }
        }

        .contact-type-cb2 {
          margin-left: 125px;
          @extend .contact-type-cb1;

        }

        .contact-type-value {
          margin-top: 10px;
          margin-left: 15px;
          text-align: left;
          word-break: break-all;
        }
      }
    }
  }
}
