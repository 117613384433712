@use "../../settings/third-party/variables";

.generate_email {
  position: absolute;
  left: -100%;
  top: 64px;
  bottom: 0;
  right: 100%;
  transition: 0.5s all linear;
  border-right: 3px solid;

  &.open {
    right: 0;
    left: 245px;

    @media screen and (max-width: 959px) {
      left: 0;
    }
  }

  .copy-icon {
    position: absolute;
    right: 5px;
    bottom: 5px;
  }
}

.gpt-input {
  .md-input {
    border: 1px solid #20394c !important;
    border-radius: 6px;
    padding: 10px 15px;
    box-shadow: rgba(0, 0, 0, 0.15) 2.5px 2.5px 2.5px;
  }

  .send-icon {
    position: absolute;
    right: 25px;
    bottom: 25px;
  }
}

md-dialog.send_email {
  max-width: 100%;
  width: 100%;
  height: 100%;
  max-height: 100%;

  md-card {
    width: 100%;
    margin: 0;

    md-card-content {
      padding: 0;
    }
  }

  .md-sidenav-left.md-locked-open {
    min-width: 245px;
    padding: 0 5px;
    overflow-y: scroll;
    min-height: calc(100vh - 64px);
  }

  .md-sidenav-left {
    .detail-overview {
      margin: 20px 0 0 5px;

      .desc {
        .first_row {
          margin-right: 0;
        }

        .percent_counter.border_plate.icon_border_plate {
          border: none;
          box-shadow: none;
          margin-right: 10px;

          .icon_img {
            width: 20px;
            margin-top: 5px;
          }
        }

        h2 {
          font-size: 12px;
        }
      }
    }

    .profile_icon_png {
      width: 100px;
      margin: 0 auto 20px;
    }

    .proflie_section {
      border-bottom: 1px solid #595959;
      // padding-bottom: 15px;

      h1 {
        font-weight: normal;
      }
    }

    .card_cta {
      .md-button {
        font-size: 16px;
        margin-bottom: 10px;
      }

      .btn-secondary {
        background-color: transparent;
        border: 1px solid #486480;
        color: #5cc8fc;

        .icon-ico-add {
          svg {
            path {
              fill: #486480;
            }
          }
        }
      }

      .icon-ico-add {
        width: 18px;
        margin: 0 10px;
        text-align: left;

        svg {
          path {
            fill: #fff;
            font-size: 10px;
          }
        }
      }
    }

    .template-list {
      display: none;
    }

    .prefered_template {
      margin: 15px 0 8px;
      background-color: #006496ff;
      border: 1px solid #209ad7;
      padding: 5px;
      border-radius: 2px;
      position: relative;

      .icon_png {
        width: 20px;
        margin-right: 10px;
      }

      .title {
        margin-top: 3px;
      }

      .icon-down {
        transform: rotate(-90deg);
        width: 10px;
        height: 10px;
        right: 10px;
        top: 10px;
        position: absolute;
        transition: 0.3s all linear;

        &.open {
          transform: rotate(90deg);
        }

        svg {
          path {
            fill: variables.$white;
          }
        }
      }
    }

    .prefered_template_dropdown {
      background-color: transparent;
      //border: none;
      border: 1px solid #20394c;
      margin: 0 0 8px;
      padding: 0 0 0 5px;

      .icon_png {
        width: 20px;
        height: 20px;
        margin: 7px 10px 7px 0;
        opacity: 0.5;
      }

      .sub_title {
        padding: 8px 10px 8px 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border: 1px solid transparent;
      }

    }

    .prefered_template_dropdown.is-active {
      background-color: #496481;
      border: 1px solid #496481;
      border-radius: 2px;
      padding: 0 0 0 5px;

      .icon_png {
        opacity: 1;
        margin: 4px 10px 0 0;
      }

    }

    .prefered_template_dropdown:hover {
      border: 1px solid #496481;
      padding: 0 0 0 5px;

      .icon_png {
        //opacity: 1;
        //margin: 4px 10px 0px 0px;
      }
      // .sub_title{
      //     border: 1px solid #496481;
      // }

    }

  }

  .type_box_card_section {
    .type-box {
      border: 1px solid #ffab40;
      background-color: #fff;
      width: 100%;
      margin-right: 25px;
      display: flex;
      padding: 12px 16px 2px;

      .type-label {
        align-items: flex-start;

        .custom_type-value_title {
          font-size: 13px;
          color: #ffab40;
        }

        .custom_type-value {
          margin-left: 0;
          font-weight: normal;
          font-size: 17px;
          color: #000000;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 190px;
          width: 100%;
        }
      }

      .icon_png_img {
        width: 30px;
        height: 30px;
        right: -15px;
        top: -15px;
        position: absolute;
      }
    }
  }

  .btn_icon_img {
    width: 20px;
    margin-right: 10px;
  }

  .email_cta {
    background-color: transparent;
    padding: 0;
  }

  .add-email {
    .insert_content {
      margin-top: 7px;
    }

    .ico-plus {
      svg {
        path {
          fill: #5cc8fc;
        }
      }
    }
  }

  .email_form_section {
    padding: 0;
    overflow-x: hidden;

    .customer-dashboard {
      display: block;
    }

    .email_title {
      font-size: 18px;
    }

    .custom-default-box {
      padding: 20px 30px 5px;
      margin: 0 0 20px;
      background-color: #e8f0f3;

      .business_entity {
        margin-bottom: 20px !important;
      }

      .second_cell {
        .percent_des_label {
          background: #fff;
          color: #999;
          font-weight: 500;
          padding: 5px 15px;
          border-radius: 15px;
          height: fit-content;
        }
      }

      // .slaes_agent_checkbox {
      //   margin-left: 58px;
      //   min-height: 20px !important;
      //
      //   .md-label {
      //     display: flex;
      //     margin-top: 0;
      //   }
      //
      //   .info_img {
      //     width: 25px;
      //     height: 25px;
      //   }
      //
      //   .checkbox_text {
      //     margin: 5px 10px 0 0;
      //   }
      // }

    }

    .customer-dashboard-content {
      padding: 0 30px;

      .popup-notes {
        color: #52758d;
        font-size: 14px;
        margin-bottom: 15px;
      }
    }

    .input_section {
      h2 {
        margin-right: 10px;
      }
    }
  }

  .email_form_section.custom-default-boxx {
    overflow-y: auto;
    padding: 0;
    margin: 0;

    &.email-popup-template {
      background-color: #d3e1e54a;
      min-height: calc(100vh - 64px);
    }
  }

  .email_form_section.custom-default-boxx::-webkit-scrollbar {
    width: 8px;
  }

  .email_form_section.custom-default-boxx::-webkit-scrollbar-track {
    background: transparent;
  }

  .email_form_section.custom-default-boxx::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 6px;
    border: 3px solid transparent;
  }

  .generate_email {
    .custom-default-box {
      background-color: transparent;
      overflow-y: auto;

      .first_cell {
        max-width: 880px;
        width: 100%;
        line-height: 1.5;
      }

      .email_instruction {
        background-color: #f2f6f9;
        padding-bottom: 40px;

        .instruction_label {
          font-size: 16px;
          margin-top: 12px;
        }
      }

      .email_point_edit {
        background-color: transparent;
      }

      .word_count_section {
        .word_count_desc {
          margin-right: 20px;
          font-size: 15px;
        }

        .left_section {
          display: flex;
        }

        .right-space {
          margin-right: 10px;
        }
      }
    }

    .custom-default-box::-webkit-scrollbar {
      width: 8px;
    }

    .custom-default-box::-webkit-scrollbar-track {
      background: transparent;
    }

    .custom-default-box::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 6px;
      border: 3px solid transparent;
    }

    .chat_label {
      font-size: 18px;
      color: #000000;
      margin-top: 10px;
    }

    .email_divider {
      border: 1px solid #d8d6d6;
      width: 65%;
      margin-bottom: 20px;

    }

    .right_section {
      display: flex;

      .second_cell {
        .percent_counter {
          margin: 0 auto 10px;
          background-color: #496481;
          color: variables.$white;
          line-height: 3;
          min-width: 75px;
          height: 75px;
          font-size: 25px;
          font-weight: 500;
        }

        .label_text {
          text-align: center;
          color: #000000;
          font-size: 14px;
        }
      }
    }

    .custom_svg_icon {
      margin: 5px 20px 0 0;
      height: 45px;
      width: 45px;

      svg {
        path {
          fill: #000000;
        }
      }
    }

  }

  .ico-plus {
    margin-right: 10px;
  }

  .word_count_section {
    .add-email {
      margin-bottom: 0;
      padding-top: 10px;

      &.add-token {
        margin-bottom: 15px;
      }
    }

    .close {
      width: 10px;
    }

    .md-button.md-icon-button {
      min-width: 30px;
      margin: 0;
      padding: 0;
    }

    .attachments-label {
      padding-left: 5px;
      margin-bottom: 10px;
    }

    .icon_png {
      width: 19px;
    }

    .icon_img {
      width: 65px;
      height: 65px;
      margin: 10px 10px 0 0;

    }

    .word_count_desc {
      margin-right: 20px;

      span {
        font-weight: bold;
        margin-left: 8px;
      }
    }

    .card_cta {
      margin-left: 0;

      .btn-primary {
        padding-left: 0;
        margin-right: 10px;
        background-color: transparent;
        color: #000000;

        .icon-ico-add {
          margin-right: 10px;
        }
      }

      .btn-secondary {
        background-color: transparent;
        color: #000000;
        padding-left: 0;

        .icon-ico-add {
          margin-right: 10px;
        }
      }

      .cta_text {
        line-height: 18px;
        text-align: left;
      }

      .icon_img {
        width: 30px;
        margin-right: 5px;
      }
    }

    .business-desc-sec {
      display: flex;

      &.business-desc-sec-right {
        margin-right: 25px;
      }

      .first_row {
        margin-right: 0;
      }

      p {
        font-size: 12px;
        line-height: 1.4;

        span {
          font-size: 14px;
        }
      }

      .star-img-icon {
        width: 25px;
        height: 25px;
        margin-top: 18px;
      }
    }
  }

  .detail-overview {
    display: flex;
    padding: 0;

    .desc {
      display: inline-flex;
      padding: 0;
    }

    .percent_counter.border_plate {
      border: none;
      box-shadow: none;
      border-radius: 50px;
      text-align: center;
      margin-right: 15px;

      .icon_img {
        width: 35px;
        margin: 7px auto 0;
        height: auto;
      }
    }
  }

  .text_white {
    color: variables.$white;
  }

  .text_blue {
    color: #5cc8fc;
  }

  .attached-files-section {
    position: relative;
    right: 37px;
    margin-bottom: 20px;

    .attach_label {
      margin-right: 38px;
    }

    .add-btn {
      padding: 0;
      margin: 0;
    }

    h2 {
      margin-right: 25px;
    }
  }
}

@media (max-width:1250px) {
  .attached-files-section {
    right: 0 !important;
  }
}

@media (max-width:600px) {
  md-dialog.send_email {
    .word_count_section {
      .card_cta {
        .btn-primary {
          margin-bottom: 10px;

          .icon-ico-add {
            margin: 0 10px 0 0;
          }
        }

        .btn-secondary {

          margin-bottom: 10px;

          .icon-ico-add {
            margin: 0 10px 0 0;
          }
        }

      }

    }
  }
}
