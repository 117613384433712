.job-list-section {
  .data-table {
    min-width: 1110px;
  }

  .sort-icon {
    &.default-color svg path {
      fill: #abadb1;
    }

    &.hand-icon {
      width: 16px;
    }
  }

  .service-width {
    width: 140px;
  }

  .payment-stats {
    background: #F1F1F1;
  }

  .payment-summary {
    border: 1px solid #5e7a85;
    padding-left: 20px;
  }

  .separator {
    padding: 15px;
  }

  .payment-summary h1 {
    margin-left: 15px;
  }

  .payment-summary .separator {
    border-right: 1px solid #D6E1E5;
  }

  .top-gap {
    margin: 12px 0;
  }

  .payment-stats .md-datepicker-input {
    max-width: none;
  }

  .clear-filter-button {
    background-color: #f9f9f9;
    color: rgb(160, 134, 134);
    height: 20px;
  }

  .filter-btns {
    margin: 10px 0 0 20px;
  }

  .btn-primary {
    height: 20px;
  }

  .dropdown-section {
    margin: 8px 0 0 30px;
  }

  .payment-status-section {
    margin-left: 30px;
  }

  .calendar-section {
    margin-left: 20px;
  }

  .data-table {
    margin-top: 30px !important;
  }

  .gap-right {
    margin-right: 10px;
  }
}
