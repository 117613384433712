@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.structure-promotions {
  padding: 18px;

  .update-status {
    font-size: 10px;
    color: variables.$gray;
  }

  .data-table {
    margin-top: 20px;
    min-width: 780px;

    td {
      font-size: variables.$font-size-small;
      vertical-align: middle;

      .arrow-icon {
        height: 15px;
        width: 15px;

        svg path {
          fill: variables.$brand-primary;
        }
      }
    }

    .frwd-btn {
      height: auto;
      min-height: auto;
      width: auto;

      md-icon svg path {
        fill: variables.$brand-accent;
      }
    }

    &.notification-list {
      margin-top: 10px;
    }
  }
  // .select-by-view {
  //     md-input-container {
  //         width: 100%;
  //         max-width: 112px;
  //         margin: 0;
  //         md-select md-select-value span {
  //             padding-left: 10px !important;
  //         }
  //     }
  // }
}

.cate-lis li {
  position: relative;
}

.side-icons {
  position: absolute;
  right: -95px;
  top: 15px;

  .list-icons {
    height: 14px;
    margin-right: 18px;
    width: 14px;

    svg path {
      fill: #949494;
    }
  }
}
// .update-status {
//     color: $gray;
//     font-size: 10px;
//     position: absolute;
//     top: 27px;
//     right: 20px;
//     z-index: 15;
//     @media screen and (max-width: 400px) {
//        top: 15px;
//     }
// }

.structure-promotions-edit {
  padding: 18px;

  .custom-tabs {
    md-content {
      padding-top: 18px;
    }
  }

  .f9 {
    color: variables.$gray-darker;
    font-size: variables.$font-size-small;
  }

  .switch-bar {
    margin-bottom: 12px;

    label {
      padding-right: 0;
    }
  }

  .datepicker-custom {
    margin-bottom: 20px;
  }

  .summery-widget {
    line-height: 20px;

    .icon-tick {
      margin-right: 8px;
      height: 21px;
      width: 21px;

      svg path.tick-bg {
        fill: transparent;
      }
    }

    .md-button {
      margin-left: 0;
      margin-bottom: 18px;
    }

    .datepicker-custom {
      width: 100%;
    }

    .section-title {
      margin-top: 0;
      font-weight: 500;
    }
  }

  md-switch.md-block {
    margin-left: 12px;
    top: 2px;
    display: inline-block;
    vertical-align: middle;

    &.enable-switch {
      left: -10px;
      margin-left: 22px;
    }
  }

  h4 {
    margin: 0;
    font-size: variables.$font-size-base;
    font-weight: 500;
  }

  md-radio-button {
    .md-label {
      @extend .f3;
    }
  }

  .radioBtn-section {
    display: block;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .info-note {
    line-height: 16px;
    margin-bottom: 25px;
  }

  .customerLimitSection {
    margin-bottom: 20px;

    h4 {
      margin-bottom: 5px;
    }

    md-input-container {
      margin-right: 18px;
      margin-bottom: 15px;
    }
  }

  .add-btn {
    margin: 0 0 1px -10px;

    md-icon {
      height: 21px;
      width: 21px;
    }
  }

  .radio-tabs {
    margin-top: 10px;
  }

  md-input-container.recurringServiceInputs {
    margin-bottom: 8px;
  }

  .product-list-section {
    margin-top: 20px;

    .title {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }

  .search-list {
    &.product-list {
      width: 295px;

      li {
        padding-right: 20px;
        padding-left: 20px;
      }
    }
  }

  .promotion-edit-inputs {
    @extend .no-style;

    li {
      &:first-child {
        margin-top: 12px;
      }

      &.top-space {
        margin-top: 20px;
      }

      md-switch.md-block {
        margin-right: 15px;
        margin-left: 0;
      }

      md-input-container {
        width: 100%;
        //max-width: 240px;
        margin: 15px 0 0;

        input {
          height: 38px;
        }
      }

      md-radio-group md-radio-button {
        margin-bottom: 3px;
        margin-top: 5px;
      }

      md-checkbox {
        margin-bottom: 5px;
      }

      .ico-tick {
        margin-right: 5px;
      }
    }
  }
  @media screen and (max-width: 400px) {
    md-tabs {
      margin-top: 20px;
    }
  }
}

.promotionSidebar {
  .leftnav-wrap {
    position: relative;

    .task-wrapper {
      padding-top: 18px;
    }
  }

  .gap {
    padding-left: 18px;
    padding-right: 18px;
  }

  .item-chipset {
    li:last-child {
      margin-bottom: 20px;
    }

    .chipbox {
      border: 1px solid variables.$border-color;
    }
  }

  .task-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 49px;
    overflow-y: auto;
  }

  .bottom-section {
    position: absolute;
    bottom: 1px;
    left: 0;
    right: 0;
  }

  h1 {
    color: variables.$gray-darker;
    font-size: variables.$font-size-base;
    font-weight: 500;
    margin-top: 0;
  }

  .add-btn {
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 20px;
  }

  md-divider {
    margin-right: -16px;
    margin-left: -16px;
  }

  md-checkbox {
    margin-top: 20px;

    .md-icon {
      background: variables.$white;
    }
  }

  .search-link {
    margin-bottom: 20px;
  }
}

.disable-all-service {
  cursor: default;

  path {
    fill: #999;

  }
}

.enable-all-service path {
  fill: rgb(248, 149, 29);
}

@media only screen and (min-width: 601px) {
  .structure-promotions-edit {
    .datepicker-custom {
      width: 140px;
    }
  }

  .promotion-left-section {
    float: left;
  }

  .promotion-right-section {
    float: right;

    .summery-widget {
      width: 220px;
    }
  }

  .gap-right {
    margin-right: 18px;
  }

  md-input-container.promotional-inputs {
    input,
    md-select {
      width: 240px;
    }
  }
}
