@use "../../settings/third-party/mixins";
@use "../../settings/third-party/variables";
@use "../../settings/style-guide";
@use "../../settings/custom";

.opportunity-tracking {
  .opp-stats {
    margin: 25px 0;
  }

  .guider-checklist .md-list-item-inner {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }

  .user-options-wrapper {
    .md-button {
      &.md-icon-button {
        @include mixins.border-radius(0);

        color: variables.$gray;
        font-size: 10px;
        font-weight: 400;
        height: auto;
        line-height: 14px;
        margin: 0 8px;
        min-width: 30px;
        overflow: visible;
        padding: 0;
        width: auto;

        &:last-child {
          margin: 0 0 0 8px;
        }
      }

      md-icon {
        display: block;
        height: 16px;
        margin-bottom: 4px;
        width: 16px;
      }
    }
  }

  .table-responsive {
    margin-bottom: 20px;
    padding-top: 0;

    .data-table {
      td {
        @extend .f3-light;
      }

      .ico-table {
        height: 16px;
        width: 16px;
      }
    }
  }

  .panel-container {
    max-height: 400px;

    .panel-content {
      min-height: 350px;
      max-height: 350px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    md-list {
      md-list-item {
        &.form-base:first-child {
          min-height: 80px;
        }

        .radio-section {
          width: 100%;
        }
      }

      .star {
        margin: 5px 10px 5px 5px;
        cursor: pointer;

        md-icon {
          color: variables.$white-darker;
        }
      }

      .timer {
        margin: 5px 10px 5px 5px;
        cursor: pointer;

        md-icon {
          color: variables.$white-darker;

          &.active {
            color: variables.$brand-accent;
          }
        }
      }

      .radio-list-btn {
        margin-top: 14px;
        margin-bottom: 16px;
        margin-left: 16px;
        line-height: 15px;

        @extend .f3;

        .md-icon {
          height: 18px;
          width: 18px;

          &:after {
            height: 11px;
            left: 5.5px;
            top: 1.45px;
            width: 6px;
          }
        }
      }
    }

    .panel-btn {
      width: 100%;
      height: 40px;
      margin: 15px 0;
      background: variables.$white-darker;
      padding-top: 2px;
      border-radius: 3px;
      font-size: variables.$font-size-base;
      text-transform: none;

      .badge {
        @extend .location-symbol;

        background-color: variables.$gray-light;
        margin-left: 5px;
      }
    }

    .archived-items {
      margin: 0;

      md-list {
        padding-top: 0;

        md-list-item:first-child .md-list-item-inner {
          margin-top: 0;
        }
      }
    }

    md-input-container {
      padding-right: 20px;
      margin-bottom: 0;

      &.btn-section {
        background-color: variables.$gray-lighter;
      }

      .btn-accent {
        background-color: variables.$brand-accent;
        color: variables.$white;
      }

      .guider-cancel {
        background: variables.$gray-lighter;
      }
    }

    md-checkbox {
      .md-label {
        width: 97%;
        margin-left: 0;

        span {
          width: 80%;
        }
      }

      md-icon {
        margin: 0 7px 0 25px;
        vertical-align: top;
        height: 18px;
        width: 20px;
        float: left;
      }
    }

    .calander {
      .full-cal,
      .time {
        padding: 10px;
      }
    }
  }

  //    .date-time-detail {
  //  background: $white-dark;

  //      .custom-calender {
  //      min-height: 190px;

  //          table {
  //          width: 100%;
  //      }

  //          .uib-datepicker {
  //          transform: scale(0.6);
  //          transform-origin: left top 0;

  //              .btn {
  //              min-width: 40px !important;
  //              min-height:40px;
  //          }
  //      }
  //  }
  // }
  .date-time-detail {
    background: variables.$white-dark;

    .custom-calender {
      min-height: 300px;
      max-height: 310px;

      table {
        width: 100%;
      }

      .uib-datepicker {
        transform: scale(1);
        transform-origin: left top 0;

        @media only screen and (max-width: 1270px) and (min-width: 960px) {
          transform: scale(1);
        }

        @media only screen and (max-width: 524px) and (min-width: 320px) {
          transform: scale(0.8);
        }

        .btn {
          min-width: 40px !important;
          min-height: 40px;
        }
      }
    }

    .panel-date-time {
      margin-top: 0;
    }

    md-input-container {
      padding-right: 20px;
      margin-bottom: 15px;
      margin-right: 20px;
      margin-left: 20px;

      input,
      label,
      span {
        font-size: variables.$font-size-base;
      }

      &.btn-section {
        margin: 0;
        background: transparent;
      }
    }
  }
}

.right-gap {
  margin-right: 27px;

  @media screen and (max-width: 960px) {
    margin-right: 0;
    margin-bottom: 65px;
  }
}

.tracking-popups {
  width: 800px;
  min-height: 610px;
  overflow: hidden;

  md-dialog-content {
    box-sizing: border-box;
    max-width: 100%;
    padding: 12px 20px 0;

    .datepicker-custom .md-datepicker-input-container input {
      padding-left: 4px;
      padding-top: 14px;

      @extend .f4;

      font-weight: 500;
    }

    .inner-content {
      max-width: 70%;

      .message-head {
        color: variables.$brand-primary;
        margin: 24px 0;
        outline: 0;
        cursor: pointer;
      }

      md-input-container label {
        @extend .f4;

        font-weight: 500;
      }

      .message-list {
        @include mixins.border-radius(2px);

        border: 1px solid variables.$white-dark;
        max-height: 200px;
        overflow-y: auto;

        li {
          box-sizing: border-box;
          min-height: 60px;
          padding: 15px;
          border-bottom: 1px solid variables.$gray-light;
        }

        md-divider {
          border-top-color: variables.$white-dark;
        }

        md-checkbox {
          margin-bottom: 0;

          .md-icon {
            top: -5px;

            &:after {
              border-color: variables.$brand-primary;
            }
          }

          .md-label {
            @extend .f3-light;

            line-height: 18px;
            margin-top: 0;
          }
        }
      }

      .input-width {
        max-width: 130px;
      }
    }

    .attachment-wrapper {
      margin-bottom: 10px;

      .ico-attach {
        width: 15px;
        height: 15px;
        margin-right: 4px;
      }

      .muted-btns {
        margin: 5px 0 0;

        .md-button {
          padding: 0 20px 0 8px;
          font-size: 10px;
          height: 20px;
          min-height: 20px;
          line-height: 20px;

          .chip-close {
            height: 8px;
            width: 8px;
            position: absolute;
            top: 5px;
            right: 6px;
          }
        }
      }

      .ico-add {
        height: 20px;
        width: 20px;

        svg path {
          fill: variables.$brand-accent;
        }
      }
    }
  }

  .tracking-popup-sidenav {
    padding-top: 20px;

    #attachments {
      margin-top: -18px;
    }

    .popup-title {
      color: #606060;
      font-weight: normal;
      margin: 0 10px 15px;
    }

    .message-list {
      li {
        box-sizing: border-box;
        min-height: 60px;
        padding: 10px;
      }

      md-divider {
        border-top-color: variables.$white-dark;
      }

      md-checkbox .md-container {
        top: 34% !important;
      }

      md-checkbox {
        margin-bottom: 0;

        .md-icon {
          top: -5px;

          &:after {
            border-color: variables.$brand-primary;
          }
        }

        .md-label {
          @extend .f3-light;

          line-height: 18px;
          margin-top: 0;
        }
      }
    }

    .schedule-date {
      @extend .f4;

      margin-left: 10px;
    }

    .popup-schedule-list {
      li {
        padding: 0 10px;
        height: 48px;

        .schedule-location,
        .schedule-time {
          color: variables.$gray-dark;
        }

        .schedule-type {
          color: variables.$brand-primary;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .tracking-popups md-dialog-content .inner-content {
    max-width: 100%;

    .input-width {
      margin-bottom: 18px;
      max-width: 100%;
      width: 100%;
    }
  }
}
