@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.main-header {
  .md-toolbar-tools .btn-primary.btn-lg {
    min-width: 105px;
  }
}

.inventory-error-section {
  .custom-tabs {
    md-content {
      padding-top: 18px;
    }
  }

  .form-base {
    input[type="text"] {
      font-size: variables.$font-size-small;
    }
  }

  .step-tree {
    margin-bottom: 5px;

    li {
      color: variables.$gray;
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 15px;
      position: relative;

      md-icon {
        display: none;
        height: 13px;
        margin-right: 7px;
        width: 13px;

        svg path {
          fill: variables.$brand-success-dark;
        }
      }

      &.active {
        color: variables.$gray-darker;
      }

      &.completed md-icon {
        display: inline-block;
      }

      &:after {
        content: '>';
        color: variables.$brand-table;
        display: inline-block;
        position: absolute;
        top: 0;
        right: -20px;
        -webkit-transform: scale(1.5, 2);
        transform: scale(1.5, 2);
      }

      &:not(:first-child) {
        margin-left: 10px;
      }

      &:last-child {
        &:after {
          content: '';
        }
      }
    }
  }

  .data-table {
    border: 1px solid #c1cbcd;
    border-radius: 4px;
    margin-top: 0;
    min-width: 800px;

    tr:last-child td {
      border-bottom: none;
    }

    &.duplicate-sku-table {
      td {
        font-size: 10px;

        &:last-child {
          border-left: 1px solid #c1cbcd;
        }
      }

      .product-img,
      .product-no-img {
        margin-right: 20px;
      }
    }

    td {
      @extend .f3;

      border-bottom: 1px solid #c1cbcd;
      line-height: 16px;
      vertical-align: middle;
      width: 400px;

      &.highlight-cell {
        background: variables.$brand-table;
      }
    }

    md-radio-button {
      margin-bottom: 0;

      .md-label {
        margin-left: 20px;
      }
    }

    md-input-container {
      margin-bottom: 0;
    }

    .product-lbl {
      color: variables.$brand-primary;
      cursor: pointer;
      font-size: 10px;
    }

    .product-img {
      width: 62px;
      height: 40px;
    }

    .product-no-img {
      background: variables.$white;
      height: 55px;
      width: 65px;

      &.small-pic {
        height: 38px;
      }

      md-icon {
        height: 34px;
        margin-top: 8px;
        width: 34px;
      }
    }

    h3 {
      font-size: variables.$font-size-base;
      font-weight: 500;
      margin: 0 0 10px;
    }

    .icon-edit {
      height: 12px;
      margin-top: 0;
      margin-left: 8px;
      width: 12px;
    }

    .common-gap {
      min-width: 55px;
      margin-right: 10px;
    }

    .spec-lbl {
      color: variables.$gray;
      margin-right: 8px;
      padding-right: 0;
    }
  }
}

@media screen and (min-width: 600px) {
  .inventory-error-section {
    .duplication-list {
      .inner-section:first-child {
        border-right: 1px solid #c1cbcd;
      }
    }
  }
}
