@use "../settings/third-party/variables";

$filter-be: #f2fffe;

md-option.resource-dropdown-filter {
  // code modified by nephtalem
  // height changed from 40px to 70px and border bottom added
  height: 70px;
  border-bottom: 1px solid variables.$gray-light;

  .avatar,
  .img-replacer {
    width: 30px !important;
    height: 30px !important;
    min-width: 30px !important;
    line-height: 30px;
  }
}

.filter-space {
  padding: 18px;
}

.filters-bar {
  background: #f1f1f1 !important;
  margin: 0;
  // width: auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 20;

  &.static {
    position: static;
    padding: 18px;
  }

  h1 {
    font-size: variables.$font-size-medium;
  }

  h1,
  h2,
  h3 {
    color: variables.$gray-darker;
  }

  .filter-header {
    border-bottom: variables.$gray-lighter solid 1px;
    padding: 4px 18px;
    background-color: $filter-be;
  }

  .filter-btns,
  .scroll-y {
    padding: 0 18px;
  }

  .scroll-y {
    background-color: $filter-be;
  }

  .scroll-y:not(.static) {
    flex: 1;
    // position: absolute;
    // top: 55px;
    // right: 0;
    // bottom: 49px;
    // left: 0;
  }

  .filter-btns {
    text-align: right;
    border-top: variables.$gray-lighter solid 1px;
    background-color: $filter-be;
    .clear-filter-button.btn-primary{
      border-color: #FD4547;
      background-color: transparent!important;
      color: #FD4547!important;
      &:disabled{
        pointer-events: none;
        background-color: #d8e2e3!important;
        color: #8e8e93!important;
        //border: 1px solid;
       
      }
    }
    .filter-cta.btn-primary{
      background-color: #ee9c38;
      color: #fff;
      &:hover {
        background-color: #C47618;
      }
    }
    // &:not(.static) {
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   right: 0;
    //   z-index: 1;
    // }

    .md-button:last-of-type {
      margin-right: 0;
    }
  }

  .close-filter.md-fab {
    margin: 0;
    opacity: 0.75;
    line-height: 35px;
    width: 35px;
    height: 35px;
    background-color: variables.$white;

    md-icon {
      height: 15px;
      width: 15px;

      svg path {
        fill: variables.$gray-light;
        transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
      }
    }

    &:hover {
      svg path {
        fill: variables.$white;
      }
    }
  }

  .filter-label {
    color: #333;
    font-size: variables.$font-size-small;
    margin-right: 20px;
    margin-top: 20px;
    display: inline-block;
    vertical-align: top;
  }

  .inner-inputs {
    margin-top: 15px;

    li {
      margin-bottom: 7px;
      margin-left: 0;

      &.date-picker {
        margin-bottom: 27px;
      }

      &.date-picker200 {
        width: 100%;
        max-width: 200px;
      }
    }
  }

  .clear-date-top {
    bottom: -17px;
    position: absolute;
    right: 0;
  }

  md-input-container {
    md-select {
      height: 38px;
      margin-top: 0;
      // width: 155px;
    }
  }

  md-datepicker,
  md-datepicker.md-default-theme {
    background: transparent;

    .md-datepicker-input-container {
      margin: 0;
      padding-left: 3px;
      height: 38px;
    }

    .md-datepicker-calendar-icon {
      svg {
        path {
          fill: variables.$brand-primary;
        }
      }
    }

    .md-datepicker-button {
      margin: 0;
      position: absolute;
      right: -5px;
      top: -5px;
    }
  }

  .date-picker {
    margin-top: 0;

    md-datepicker {
      margin-right: 0;
      padding-right: 0;

      .md-datepicker-button {
        height: 40px;

        &,
        &:hover {
          background: #fff;
          z-index: 1;
        }
      }

      &:not(.md-datepicker-open) .md-datepicker-button {
        &,
        &:hover {
          background: $filter-be;
        }
      }
    }
  }

  .close-icon {
    right: 15px;
    top: 10px;
  }
}

.filter-res-select {
  .md-select-value > span:not(.md-select-icon) .md-text {
    display: inline-block;
  }

  md-select {
    .avatar,
    .img-replacer {
      width: 30px !important;
      height: 30px !important;
      min-width: 30px !important;
      line-height: 30px;
    }
  }
}

// @media screen and (min-width: 960px) {
//   .filters-bar {
//     &:not(.no-sidenav) {
//       left: 200px;
//     }
//   }
// }

@media screen and (min-width: 600px) {
  .filters-bar {
    .inner-inputs {
      li {
        margin-right: 15px;
        display: inline-block;
        vertical-align: top;

        &.date-picker {
          max-width: 155px;
        }
      }
    }

    .date-picker md-datepicker .md-datepicker-input-container {
      width: 155px;
    }

    md-input-container {
      .md-input,
      md-select {
        width: 155px;
      }
    }
  }
}

// @media screen and (max-width: 767px) {
//   .filters-bar {
//     bottom: 46px;
//   }
// }

@media screen and (max-width: 600px) {
  .filters-bar {
    .inner-inputs {
      li.date-picker,
      li.date-picker .md-datepicker-input-container {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
