@use "../../settings/third-party/variables";

.opportunity-grid {
  padding: 20px;

  .opportunity-grid-header-section {
    background: #d3e1e54a;
  }

  .title-blue {
    color: #2196F3;
  }

  .title-image-background {
    background: #fff;
    border-radius: 50px;
    padding: 8px;

    .title-image {
      width: 40px;
      height: 40px;
    }
  }

  .filter-header {
    background: #52758D;
    font-size: 20px;
    color: #fff;
    margin: 2px 0 0 10px;
  }

  .category-filter-image {
    border-radius: 30px;
    padding: 4px;
    background: #fff;
    position: absolute;
    top: -22px;
    right: 6px;
    border: 1px solid #000;
  }

  .opportunity-category-header {
    background-color: #000;
    padding: 5px 15px;
  }

  .opportunity-new-count {
    &.location-symbol {
      background: #EF4135;
    }
  }

  .opportunity-font-size {
    font-size: 10px;
  }

  .card-btns {
    line-height: 1.5;
    border-radius: 7px;
    border: 1px solid #d0e4f2;
    padding: 5px;

    .right-arrow {
      display: none;
    }
  }

  .card-btns:hover {
    background-color: variables.$brand-primary;

    .opportunity-font-size {
      color: #fff;
    }

    .right-arrow {
      display: inline;
    }

    svg path {
      fill: #fff;
    }
  }

  .boards {
    li {
      // border: 1px solid $gray-lighter;
      border-radius: 5px;
      margin-right: 20px;
      max-width: 300px;
      width: 100%;
    }

    .new-label {
      position: absolute;
      top: -24px;
      right: 0;
      width: 30px;
      height: 30px;
    }

    .summery-widget {
      padding: 10px 15px;
      overflow: hidden;

      &.white-board {
        background: variables.$white;
        min-height: 306px;
        padding: 0;
        transition: box-shadow 1s, border-color 1s;
        border: 1px solid #d0e4f2;

        // box-shadow: 0 0 2px $gray-lighter;
        &:hover {
          border-color: #52758d;
          box-shadow: 0 0 8.37px 0.63px rgba(82, 117, 141, 0.35);
        }
      }

      .opportunity-header {
        padding: 4px 15px 2px;
        border-bottom: 1px solid #ccc;
        background-color: #eff6fb;
      }

      &.new-board {
        padding: 15px;
      }

      .side-space {
        padding: 0 15px;
      }

      .color-indicator {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-bottom: 50px solid transparent;
        border-left: 50px solid transparent;
      }

      .category-img {
        height: 30px;
        width: 30px;

        .visual {
          transform: translate(-50%, -50%) scale(0.47); // ~30px
        }
      }

      .menu-btn {
        position: static;
      }

      // &.edit-mode {
      //   background: $white;
      // }
    }

    md-icon.edit {
      height: 15px;
      width: 15px;

      path {
        //fill: $gray;
      }
    }

    .stat {
      margin: 0;
      display: inline-block;
    }

    .stat-info {
      text-transform: uppercase;
    }

    .location-symbol {
      margin: 3px 0;
    }

    .md-button {
      margin: 0;
    }

    .btn-primary {
      padding: 0 15px;
      // margin-right: 10px;
    }

    .space-bottom {
      margin-bottom: 24px;
    }

    .new-board {
      color: variables.$gray;

      md-icon:not(.icon-ico-add) {
        height: 60px;
        width: 60px;

        path {
          fill: variables.$gray-light;
        }
      }
    }

    .opportunity-card:hover {
      background: rgba(211,225,229,.21);
    }

    .color-indicator {
      .category-filter-image {
        border-radius: 63px;
        top: 21px;
        right: 2px;
      }

      .visual-sm-icon {
        height: 16px;
        width: 16px;
      }

      .category-img .visual {
        transform: translate(-50%, -50%) scale(0.3);
      }
    }
  }
}

.opportunity-right-sidebar {
  .sidebar-header {
    h2 {
      font-size: 20px !important;
    }
  }

  .header-section {
    h2 {
      font-size: 18px;
    }
  }

  .cards-section:hover {
    background-color: #F2F6F7;
  }

  .card-stat {
    border-radius: 10px;
    min-width: 170px;

    md-icon {
      width: 15px;
      height: 15px;
    }

    .location-symbol {
      padding: 0 10px !important;
    }

    &.won {
      background: #EAF7E4;

      .location-symbol {
        background-color: variables.$status-green;
      }
    }

    &.lost {
      background: #FBE2E2;

      .location-symbol {
        background-color: variables.$status-red;
      }
    }

    &.pending {
      background: #F5F0FF;

      .location-symbol {
        background-color: #B4A7D6;
      }
    }

    &.postponed {
      background: #FFF2CC;

      .location-symbol {
        background-color: #FAB963;
      }
    }

    &.canceled,
    &.no-outcome,
    &.void {
      background: #F3F3F3;

      .location-symbol {
        background-color: #B7B7B7;
      }
    }
  }

  .contact-stat {
    background-color: #63BF60;

    md-icon {
      svg path {
        fill: #fff;
      }
    }
  }
}
