@use "../settings/third-party/variables";

.mainboard {
  padding: 20px;

  md-card {
    margin: 0;

    md-card-title {
      padding: 16px;
    }
  }

  .tour-progress {
    height: 10px;
    width: 95px;
    margin-right: 10px;
    background: linear-gradient(to right, #93c47d 50%, lightgray 50%);
  }

  .tours-section {
    margin-top: 40px;

    md-icon {
      height: 35px;
      width: 35px;
    }

    .tour-done-icon {
      height: 16px;
      width: 16px;
    }

    md-card {
      padding-left: 10px;

      &:not(:last-of-type) {
        margin-right: 8px;
      }

      .heading {
        font-size: variables.$font-size-base;
        font-weight: 500;
      }

      .sub-heading {
        padding-top: 10px;
      }
    }
  }

  .tours-tabs {
    margin: 50px 0 0 6px;

    .tour-number {
      color: variables.$brand-primary;

      md-icon {
        position: absolute;
        left: -9px;
        height: 30px;
        top: -3px;
        width: 30px;

        path {
          fill: lightgray;
        }
      }
    }

    .video-category {
      border: 1px solid variables.$gray-lighter;

      background: variables.$white;
      border-radius: 8px;
      margin: 0 50px 10px 20px;
      min-width: 200px;
      padding: 0 10px;
      text-align: center;
      &:hover{
        border-color: variables.$brand-primary;

      }
      h1 {
        color: variables.$gray-base;
      }

      &.selected {
        background: #ffe8c6;
        border-color: variables.$brand-primary;

        h1 {
          color: #b45f06;
        }
      }
    }

    .tour-progress {
      margin-left: 22px;
    }

    .complete-status {
      color: variables.$gray;
      font-size: variables.$font-size-base;
    }

    .bottom-space {
      margin-bottom: 30px;
    }
  }

  .services-section {
    width: 300px;
    margin: 30px 20px 0 0;
    box-shadow: 0 0 3px variables.$brand-accent-darker;

    .video-info {
      padding: 0 10px;
    }

    .right-arrow-icon {
      margin: 0 !important;
    }

    .right-arrow-icon:hover {
      color: variables.$brand-accent;
    }

    .action-section {
      padding: 10px 0;

      .done-text {
        margin: 4px 5px 0 0;
      }

      .done-cta {
        color: variables.$gray;
        padding: 3px;

        &.done,
        &:hover {
          .done-text,
          md-icon {
            color: variables.$brand-accent;
          }
        }
      }
    }
  }

  @media screen and (max-width: 599px) {
    .tours-section md-card:not(:last-of-type) {
      margin: 0 0 8px;
    }
  }
}
