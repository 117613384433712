@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.tax-policy-popups {
  width: 800px;
  min-height: 500px;
  overflow: hidden;

  md-toolbar.main-header .md-toolbar-tools {
    .md-icon-button md-icon {
      height: 18px;
      width: 18px;
    }

    h2 {
      font-size: 16px;
      line-height: 32px;
    }

    md-icon {
      height: 18px;
      width: 18px;

      svg path {
        fill: variables.$white;
      }
    }
  }

  md-dialog-content {
    padding: 30px;
  }

  md-checkbox {
    color: variables.$gray-dark;
    margin-bottom: 20px;
  }

  .display-setting-section {
    margin-top: 20px;

    .input-container {
      margin-top: -6px;
      width: 210px;

      md-input-container {
        margin-bottom: 0;
      }
    }

    .input-description {
      color: variables.$gray;
      font-size: 10px;
      margin-top: 6px;
    }
  }

  .input-container {
    md-input-container {
      input,
      label {
        @extend .f4;
      }
    }
  }

  .product-package-section {
    .note {
      @extend .f4;
      margin-top: -5px;
      margin-left: 30px;
    }

    .package-input-list {
      .input-xlg {
        md-input-container {
          margin-bottom: 16px;
        }

        label:not(.md-no-float):not(.md-container-ignore) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }
      }
    }
  }

  .item-chips {
    margin-top: 10px;
    margin-bottom: 10px;

    li {
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 5px 9px;

      .icon-close {
        height: 11px;
        margin-left: 7px;
        width: 11px;
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .tax-policy-popups {
    .product-package-section {
      .check-list {
        position: absolute;
        top: 20px;
        right: 0;
      }

      .input-xlg {
        display: inline-block;
        margin-right: 20px;
        width: 260px;
      }
    }

    .item-chips {
      display: inline-block;
      vertical-align: top;
    }
  }
}
@media screen and (max-width: 767px) {
  .tax-policy-popups {
    .product-package-section {
      .check-list {
        margin-top: 30px;
        max-width: 100%;
      }
    }
  }
}
@media screen and (min-width: 600px) {
  .tax-policy-popups {
    .display-setting-section {
      margin-left: 90px;
    }
  }
}
@media screen and (max-width: 600px) {
  .tax-policy-popups {
    md-toolbar.main-header .md-toolbar-tools {
      .md-button.btn-primary.tick-btn {
        min-width: 45px;
      }

      .long-titles {
        font-size: 15px;
        line-height: 20px !important;
        text-align: center;
      }
    }
  }
}
