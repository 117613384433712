@use "../../settings/third-party/variables";

.add-campaign {
  .footer img {
    width: 300px;
    margin-top: 15px;
  }

  .add-email md-icon {
    margin-right: 10px;
  }

  .hr-btns {
    margin: 20px 0;

    md-radio-button {
      margin-right: 15px;
    }
  }
}

.add-view-champaign {
  md-list-item:before {
    min-height: 20px;
  }
}

.campaign-grid {
  .campaign-header {
    .review-segments {
      display: none;
    }

    .segments-header-icon {
      height: 36px;
    }
  }

  .campaign-header:hover {
    .review-segments {
      display: inline-block;

      md-icon {
        width: 15px;
        height: 15px;
      }

      svg path {
        fill: #fff;
      }
    }

    .segments-header-icon {
      display: none;
    }
  }

  .segmentations-list {
    max-width: 360px !important;
  }

  .open-new-tab-size {
    width: 18px;
    height: 18px;
  }

  .campaign-detail-border {
    border-radius: 10px;
  }

  .campaign-grid-background {
    background-color: rgba(211, 225, 229, 0.21);
    border-right: 1px solid #d0e4f2;
  }

  .campaign-counter {
    min-width: 50px;
    border-radius: 15px;
    background-color: #20394c;
    text-align: center;
    padding: 2px;

    &.campaign-counter-cron-job {
      background-color: #fff;
    }
  }

  .campaign-drip-counter {
    border-radius: 15px;
    padding: 2px;
    font-size: 13px;
    background-color: #fff;
    min-width: 90px;
    text-align: center;

    &.dynamic {
      border: 1px solid #6aa84f;
      color: #6aa84f;
    }

    &.dynamic:hover {
      background-color: #6aa84f;
      color: #fff;
    }

    &.restricted {
      border: 1px solid #e06666;
      color: #e06666;
    }

    &.restricted:hover {
      background-color: #e06666;
      color: #fff;
    }

    &.manual {
      border: 1px solid #52758d;
      color: #52758d;
    }

    &.manual:hover {
      background-color: #52758d;
      color: #fff;
    }
  }

  .segmentation-counters-list {
    padding: 12px 10px;
  }

  @media (max-width: 360px) {
    .segmentation-counters-list {
      flex-direction: column;
    }
  }

  .segmentation-counter-list {
    list-style: none;

    md-icon {
      display: none;
    }

    li:hover {
      background-color: rgba(211, 225, 229, 0.21);

      md-icon {
        display: inline;
      }
    }
  }

  .contacts-cron-detail {
    background-color: #20394c;
  }

  .campaign-footer {
    background-color: rgba(211, 225, 229, 0.21);
    padding: 15px 8px;

    md-icon {
      width: 18px;
      height: 18px;
    }
  }

  .campaign-side-nav {
    .sidebar-list-header {
      background-color: #20394c;
      padding: 15px 10px 15px 20px;

      &.promotions {
        background-color: #E2F5F4;
      }

      .view-promotions-button {
        display: none;
      }
    }

    .channels-list:hover {
      background: aliceblue;
    }

    .campaign-list-header {
      color: variables.$brand-primary;
      font-size: 16px;
    }

    // .segmentation-stats {
    //   min-width: 45px;
    //   background: #fff;
    //   border-radius: 3px;
    //   padding: 3px;
    // }

    .channels-icon {
      height: 15px;
      width: 15px;
      margin: 0 5px 0 0;
    }

    .update-info {
      color: #999;
    }

    .update-info-font {
      font-size: 14px;
    }

    .cta-row {
      padding: 15px 20px;
    }

    .segment-status {
      background-color: rgba(211, 225, 229, .21);
      padding: 15px 20px;

      &.promotions {
        background-color: #20394c;
      }
    }

    .status-border {
      padding: 2px 4px;
      background: #fff;
      border-radius: 50px;
      min-width: 80px;

      &.active,
      &.running {
        border: 1px solid variables.$status-green;
      }

      &.expired,
      &.not-running {
        border: 1px solid variables.$status-red;
      }

      &.archived,
      &.new,
      &.paused {
        border: 1px solid variables.$status-yellow;
      }

      &.disabled {
        border: 1px solid variables.$status-gray;
      }

      &.draft {
        border: 1px solid variables.$status-mehrun;
      }
    }

    .promotions {
      .remaining-promotions {
        padding: 4px 12px;
        background: #fff;
        border-radius: 2px;
        min-width: 45px;
        max-width: 45px;
        display: inline-block;
      }
    }

    .coupon-type {
      border: 1px dashed #949494;
      border-radius: 5px;
      background-color: #fff;
      padding: 15px 25px;
      min-width: 137px;
      max-width: 137px;
    }

    .promotions-font {
      font-size: 15px;
    }

    .promotions-icon {
      width: 45px;
      height: 40px;
    }
  }
}

.sidebar-list-header.promotions:hover {
  .open-promotions-icon {
    display: none;
  }

  .view-promotions-button {
    display: inline-block;
    border-radius: 50px;
    line-height: 20px;
    min-height: 0;
    min-width: 150px;

    md-icon {
      width: 15px;
      height: 15px;
      margin: 2px 0 0;
    }

    svg path {
      fill: #fff;
    }
  }
}

.freepik-template {
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  z-index: 10008;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;

  md-sidenav md-input-container .md-input {
    border-color: #fff;
  }

  .f6 {
    color: #fff !important;
  }

  .md-button {
    text-transform: capitalize;
  }

  .section-heading {
    background-color: #e9f0f2;

    .section-info {
      color: #666666;
    }

    .image-tokens {
      background: #486480;
      border: 2px solid #06b7f8;
      border-radius: 30px;
      min-width: 60px;
      min-height: 58px;
      color: #fff;
      line-height: 4;
      text-align: center;
    }
  }

  .secondary-section-heading {
    background: #f2f6f7;
    padding: 10px 10px 10px 40px;
  }

  .btn-primary {
    color: #fff;
    border: 1px solid variables.$brand-primary;
  }

  .btn-primary:hover,
  .selected {
    color: variables.$brand-primary;
    background: #fff;
  }

  .images-md-sidenav-left {
    background-color: #2b3e50;

    .close-arrow {
      transform: rotate(90deg);
      width: 5px;
      height: 20px;
    }

    .open-arrow {
      transform: rotate(-90deg);
    }

    .sidenav-arrow {
      svg path {
        fill: #209ad7;
      }
    }

    .image-styling-options {
      padding: 5px;
      cursor: pointer;
      border: 1px solid #209ad7;
      background: #006496;
    }

    .style-option-button {
      background: variables.$brand-primary;
      color: #000;
      border: 1px solid variables.$brand-primary;
    }

    .selected,
    .style-option-button:hover {
      color: variables.$brand-primary;
      background: #fff;
    }
  }

  .generated-image {
    // width: 150px;
    height: 150px;
    border: 3px solid #fff;
    cursor: pointer;
    box-shadow: 0 0 8.37px 0.63px rgba(82, 117, 141, .35);
    transition: box-shadow 1s, border-color 1s;

    &:hover {
      box-shadow: 0 0 8.37px 0.63px rgba(82, 117, 141, 1.35);
    }
  }

  .spacer-left {
    margin-left: 35px;
  }

  .padding-10 {
    padding: 10px;
  }

  .no-margin {
    margin: 0;
  }

  .gap-10 {
    gap: 10px;
  }

  .font-bold {
    font-weight: bold;
  }

  .ai-icon {
    width: 30px;
    height: 30px;
  }

  md-switch.md-block {

    // flex: none;
    // margin: 0;
    // position: relative;
    // width: 50px;
    // display: block;
    // top: -5px;
    // left: 0;
    .md-bar {
      background: #ffe9cf;
    }

    &:not([disabled]) {
      .md-bar {
        background: #ff9501;
      }
    }
  }

  // md-switch.md-block.md-checked {
  //   .md-label {
  //     left: 0;
  //     color: $white;
  //     padding-left: 9px;
  //   }

  //   &:not([disabled]) {
  //     .md-bar {
  //       background: $brand-primary;
  //     }
  //   }

  //   .md-thumb {
  //     background: $white;
  //   }
  // }

  .image-container {
    position: relative;

    .img-footer {
      position: absolute;
      right: 0;
      bottom: -7px;
      gap: 5px;
      display: none;
    }

    &:hover {
      .img-footer {
        display: flex;
      }
    }

    .icon-container {
      padding: 5px;
      background-color: #fff;
      border: 1px solid variables.$brand-primary;
      border-radius: 30px;

      md-icon {
        width: 20px;
        height: 20px;

        path {
          fill: variables.$brand-primary;
        }
      }
    }
  }

}

.prompt-screen-todo {
  .text-white {
    color: #ffffff;
  }

  .font-bold {
    font-weight: 600 !important;
  }

  h3 {
    font-size: 18px !important;
  }

  h2 {
    font-size: 16px !important;
  }

  .text-seagreen {
    color: #5cc5c3 !important;
  }

  .text-dark-gray-3 {
    color: #6a6a6a;
  }

  .text-black {
    color: black !important;
  }

  .text-seablue {
    color: #2196f3 !important;
  }

  .tab-icons-png {
    background-color: #000;
    border-radius: 4px;
    padding: 5px 0 0 5px;

    svg {
      width: 70%;
      height: 70%;

      path {
        fill: #fff !important;
      }
    }
  }

  .lock-icon-png {
    width: 24px;
    height: 24px;
  }

  .title {
    border-bottom: none !important;
  }

  .font-bold {
    font-weight: 600;
  }

  .width-full {
    width: 100%;
  }

  .summery-widget {
    padding: 20px;
    margin-bottom: 10px;
    background: #d3e1e54a;
    border: 1px solid #b0bfc7;
    max-width: 375px;
    width: 100%;
    right: 20px;
    position: absolute;
  }

  @media (max-width: 900px) {
    .summery-widget {
      position: relative;
      right: 0;
    }
  }

  .customer-segment .automation-div .summery-widget .title {
    padding: 0 20px !important;
  }

  .escalation-rules-list li {
    display: flex;
  }

  .check-icon {
    width: 30px;
    height: 30px;
  }

  .escalation-rules-list li .escalation-box {
    border: 1px solid #b0bfc7;
    padding: 0 20px;
    justify-content: flex-start;
    background-color: #d3e1e54a;
    width: 100%;

  }

  // .escalation-rules-list li .escalation-box .edit-icon {
  //   height: 25px;
  // }

  .escalation-rules-list li .escalation-box .edit-icon md-icon {
    rotate: -90deg;
  }

  .add-contact {
    margin-bottom: 15px;
    padding: 0 10px;
    border: 1px solid variables.$brand-primary;
    max-width: 250px;
    width: 100%;
    border-radius: 40px;
    display: flex;
    align-items: center;
    color: variables.$brand-primary;
    font-size: 19px;
    font-weight: 600;

    .add-icon {
      width: 30px;
      height: 30px;
    }
  }

  // .highlight-text {
  //   background-color: #ffab40;
  //   padding: 2px;
  // }

  md-input-container.prompt-todo-input .md-input {
    -ms-flex-preferred-size: 26px;
    border: 1px solid #a8b6c2 !important;
    background-color: #d3e1e54a !important;
  }

  .escalation-rules-list li .escalation-box .edit-icon md-icon svg path {
    fill: #59595970;
  }

  md-switch.md-checked:not([disabled]) .md-bar {
    background-color: #fe9603;
  }

  md-switch.md-checked:not([disabled]) .md-thumb {
    background-color: #ffffff;
  }

  md-switch.md-checked:not([disabled]) .md-ink-ripple {
    color: #ffffff;
  }

  md-tabs.agents-tabs-section .md-tab {
    max-width: 320px !important;
    display: flex;
    margin-right: 30px;
  }

  md-tabs.agents-tabs-section md-ink-bar {
    background: transparent;
    color: transparent;
  }

  md-tabs.agents-tabs-section md-content {
    background-color: #d3e1e54a;
    padding-top: 50px;
  }

  .agents-tabs-section {
    h3 {
      text-transform: capitalize;
      color: #fe9603;
    }

    .add-title {
      color: #666666;
      font-size: 18px;
      margin-bottom: 5px;
    }

    .desc-tab {
      color: #000000;
      font-size: 24px;
    }

    .customer-card {
      color: #acd5f0;
      padding: 5px 0 15px !important;
      width: 100%;
    }

    .first_cell {
      position: relative;
      margin-right: 35px;
      max-width: 300px !important;
      width: 100%;
    }

    .floating-check-icon {
      text-align: right;
      top: -16px;
      position: absolute;
      right: -10;
    }

    .clockin-assignment-status .icon_png {
      text-align: right;
      right: 0;
    }

    .clockin-assignment-status {
      padding: 10px 0 0 !important;
      display: flex;
      justify-content: flex-end;
    }

    .service-card {
      width: 100%;
      min-width: 355px !important;
      max-width: 370px !important;
    }

  }

  md-tabs.agents-tabs-section .md-tab.md-active h3 {
    color: #000000;
  }

  .custom-default-box {
    .review-activity-section {
      color: #434343;
      font-size: 16px;
      margin-top: 10px;
    }

    .first_cell {
      max-width: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;

      //background-color: #14354cff;
      padding: 15px;

      .profile-pic {
        width: 50px;
      }

      .page-tag {
        //color: #20394c;
        color: #14354cff;
        font-size: 18px;
      }

      .icon_png {
        margin-right: 0;
        width: 40px;
        height: 40px;
      }

      &.top-header {
        box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
        background-color: #14354cff;
      }
    }

    .description {
      margin-left: 0;

      h3 {
        color: #999999;
        margin-bottom: 5px;
        font-size: 18px;
      }

      p {
        color: #999999;
        font-size: 16px;
        margin-top: 5px;
      }

    }
  }

  .custom-default-box.body-container {
    padding: 0;
    // background: linear-gradient(#15354c, #517fa0);
    background: #ffffff;
    height: 100%;

    .first_cell {
      //background-color: #52748d;
      padding: 10px;
      border-radius: 10px;
    }

    .profile-detail {
      width: 100%;
      padding: 40px;
      background-color: #21374cff;
    }

    .service-card {
      width: 100%;
      margin-bottom: 5px;

      md-icon {
        margin: 0 10px 0 0;
        width: 20px;
      }

      .service-card-tab {
        margin-bottom: 10px;

        .customer-contact-info p {
          margin: 0;
        }
      }

      .profile-pic {
        width: 25px;
        height: 25px;
        margin-right: 5px;
      }

      .border {
        padding: 1px;
        background-color: #64c43aff;
        border-radius: 10px;
        position: relative;
        top: -2px;
        width: 100%;
      }

      .first_cell {
        background-color: #ffffff;
        padding: 0 10px;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
        margin-bottom: 25px;
      }

      .clockin-assignment-status {
        padding: 10px 5px;
        gap: 10px;

        span {
          min-width: 100px;
          width: 100%;
          text-align: center;
          color: #ffffff;
          border-radius: 20px;
          font-size: 14px;
          font-weight: 600;
          padding: 5px;
        }

      }

    }

    .contact-line {
      .icon_png {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }

      .installation-service-section {
        font-size: 16px;
        color: #000000;
        margin: 0;
      }
    }

    .archive-img-container .flat-icons {
      .icon_png {
        margin-right: 0;
        width: 25px;
      }
    }

    .customer-card {
      color: #acd5f0;
      padding: 15px 0 0;
      width: 100%;
    }

    .filter-section {
      .flat-icons-bg {
        background-color: #ffffff;
        box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon_png {
          width: 45px;
          height: 45px;
        }
      }

      .filter-icon {
        margin-right: 0;
        width: 25px;
        height: 25px;
      }

      .flat-icons {
        .icon_png {
          width: 30px;
          height: 30px;
        }

        .customer-name {
          font-size: 15px;

        }
      }
    }
  }

  .md-select-menu-container.md-active {
    z-index: 99999 !important;
    top: 100px !important;
    min-width: 330.4px !important;
  }

  .md-select-value .md-select-icon:after {
    color: #000000;
    font-size: 35px;
    right: 10px;
  }

  .role-type-section .md-select-menu-container.md-active {
    z-index: 99999 !important;
    top: 100px !important;
    min-width: 330.4px !important;
  }
}

.prompt-screen {
  .text-white {
    color: #ffffff;
  }

  .font-bold {
    font-weight: 600 !important;
  }

  h3 {
    font-size: 18px !important;
  }

  h2 {
    font-size: 28px !important;
  }

  .text-seagreen {
    color: #5cc5c3 !important;
  }

  .text-dark-gray-3 {
    color: #6a6a6a;
  }

  .text-black {
    color: black;
  }

  .text-seablue {
    color: #2196f3 !important;
  }

  .tab-icons-png {
    margin-right: 15px;
    width: 25px;
    height: 25px;
  }

  md-tabs.agents-tabs-section .md-tab {
    max-width: 320px !important;
    display: flex;
    margin-right: 30px;
  }

  md-tabs.agents-tabs-section md-ink-bar {
    background: transparent;
    color: transparent;
  }

  md-tabs.agents-tabs-section md-content {
    background-color: #d3e1e54a;
    padding-top: 50px;
  }

  .agents-tabs-section {
    h3 {
      text-transform: capitalize;
      color: #fe9603;
    }

    .add-title {
      color: #666666;
      font-size: 18px;
      margin-bottom: 5px;
    }

    .desc-tab {
      color: #000000;
      font-size: 24px;
    }

    .customer-card {
      color: #acd5f0;
      padding: 5px 0 15px !important;
      width: 100%;
    }

    .first_cell {
      position: relative;
      margin-right: 35px;
      max-width: 300px !important;
      width: 100%;
    }

    .floating-check-icon {
      text-align: right;
      top: -16px;
      position: absolute;
      right: -10;
    }

    .clockin-assignment-status .icon_png {
      text-align: right;
      right: 0;
    }

    .clockin-assignment-status {
      padding: 10px 0 0 !important;
      display: flex;
      justify-content: flex-end;
    }

    .service-card {
      width: 100%;
      // min-width: 355px!important;
      // max-width: 370px!important;
    }

  }

  md-tabs.agents-tabs-section .md-tab.md-active h3 {
    color: #000000;
  }

  .filter-options-tab md-option .md-text {
    display: flex !important;
    align-items: center !important;
  }

  .custom-default-box {
    .review-activity-section {
      color: #434343;
      font-size: 16px;
      margin-top: 10px;
    }

    .first_cell {
      max-width: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;

      //background-color: #14354cff;
      padding: 15px;

      .profile-pic {
        width: 50px;
      }

      .page-tag {
        //color: #20394c;
        color: #14354cff;
        font-size: 18px;
      }

      .icon_png {
        margin-right: 0;
        width: 40px;
        height: 40px;
      }

      &.top-header {
        box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
        background-color: #14354cff;
      }
    }

    .description {
      margin-left: 0;

      h3 {
        color: #999999;
        margin-bottom: 5px;
        font-size: 18px;
      }

      p {
        color: #999999;
        font-size: 16px;
        margin-top: 5px;
      }

    }
  }

  .custom-default-box.body-container {
    padding: 10px;
    // background: linear-gradient(#15354c, #517fa0);
    background: #ffffff;
    height: 100%;

    .first_cell {
      //background-color: #52748d;
      padding: 10px;
      border-radius: 10px;
    }

    .profile-detail {
      width: 100%;
      padding: 20px 20px 20px 0 !important;
      background-color: transparent !important;

    }

    .service-card {
      width: 100%;
      margin-bottom: 5px;

      md-icon {
        margin: 0 10px 0 0;
        width: 20px;
      }

      .service-card-tab {
        margin-bottom: 10px;

        .customer-contact-info p {
          margin: 0;
        }
      }

      .profile-pic {
        width: 25px;
        height: 25px;
        margin-right: 5px;
      }

      .border {
        padding: 1px;
        background-color: #64c43aff;
        border-radius: 10px;
        position: relative;
        top: -2px;
        width: 100%;
      }

      .first_cell {
        background-color: #ffffff;
        padding: 0 10px;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
        margin-bottom: 25px;
      }

      .clockin-assignment-status {
        padding: 10px 5px;
        gap: 10px;

        span {
          min-width: 100px;
          width: 100%;
          text-align: center;
          color: #ffffff;
          border-radius: 20px;
          font-size: 14px;
          font-weight: 600;
          padding: 5px;
        }

      }

    }

    .contact-line {
      .icon_png {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }

      .installation-service-section {
        font-size: 16px;
        color: #000000;
        margin: 0;
      }

    }

    .archive-img-container .flat-icons {
      .icon_png {
        margin-right: 0;
        width: 25px;
      }
    }

    .customer-card {
      color: #acd5f0;
      padding: 15px 0 0;
      width: 100%;
    }

    .filter-section {
      .flat-icons-bg {
        background-color: #ffffff;
        box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon_png {
          width: 45px;
          height: 45px;
        }
      }

      .filter-icon {
        margin-right: 0;
        width: 25px;
        height: 25px;
      }

      .flat-icons {
        .icon_png {
          width: 30px;
          height: 30px;
        }

        .customer-name {
          font-size: 15px;

        }
      }
    }

    .customer-name {
      font-weight: 500;
      font-size: 22px;
      margin: 8px 0;
      color: #000000;

    }

  }

  // code for tab section starts here

  // code for tab section ends here

  .font-bold {
    font-weight: 600;
  }

  .width-full {
    width: 100%;
  }

  .md-select-menu-container.md-active {
    z-index: 99999 !important;
    top: 100px !important;
    min-width: 330.4px !important;
  }

  .md-select-value .md-select-icon:after {
    color: #000000;
    font-size: 35px;
    right: 10px;
  }

  .role-type-section .md-select-menu-container.md-active {
    z-index: 99999 !important;
    top: 100px !important;
    min-width: 330.4px !important;
  }

  @media (max-width: 900px) {
    .prompt-screen .filter-options-tab {
      width: 70%;
    }
  }

  @media (max-width: 600px) {
    .prompt-screen .custom-default-box.body-container .filter-section .flat-icons-bg {
      background-color: #ffffff;
      box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
      border-radius: 50%;
      width: 65px;
      height: 65px;
    }

    .prompt-screen .custom-default-box.body-container .filter-section .flat-icons-bg .icon_png {
      width: 30px;
      height: 30px;
    }
  }

  md-checkbox .md-label {
    margin: 0;
  }
}
