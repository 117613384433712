@use "../../settings/third-party/variables";

.customer_campaign_report {
  .section1 {
    background-color: #f2f6f7;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12);
    margin: 8px 8px 40px;
  }

  .first_row {
    margin-right: 10px;
  }

  .description {
    margin-left: 0;
  }

  h1 {
    margin-top: 0;
  }

  .task_number_status {
    margin-left: 0;
    margin-bottom: 0;
  }

  .type_box_card_section {
    margin-left: 25px;

    .type_box_card_div {
      margin-top: 0;
      display: flex;
      width: 100%;
      margin-left: 31px;
    }
  }

  .type-box {
    margin-right: 10px;
    color: #6a6a6a;
    width: 225px;
    cursor: pointer;

    .type-label {
      label {
        color: #6a6a6a;
      }
    }

    .value_percent {
      display: flex;
      justify-content: space-between;
    }

    .custom_svg_icon {
      svg {
        g {
          color: #595959;
          fill: #595959;
        }
      }
    }
  }

  .type-box.sent-box {
    border: 1px solid #c37618;

    .custom_type-value {
      color: #c37618;
    }
  }

  .type-box.orange-box {
    border: 1px solid #fab99a;
    background: variables.$white;

    .custom_type-value {
      color: #fab99a;
    }
  }

  .type-box.opened-box {
    border: 1px solid #b6d66b;
    background: transparent;

    .custom_type-value {
      color: #b6d66b;
    }
  }

  .type-box.clicked-box {
    border: 1px solid #6b9dff;
    background: transparent;

    .custom_type-value {
      color: #6b9dff;
    }
  }

  .type-box.bounced-box {
    border: 1px solid #f45150;
    background: transparent;

    .custom_type-value {
      color: #f45150;
    }
  }

  .business-profile {
    padding-left: 45px;

    .first_row {
      margin-right: 10px;
    }

    h2 {
      margin-top: 5px;
    }

    h1 {
      color: #000000;
    }
  }

  .section2 {
    padding: 10px 0;

    .type_box_card_section {
      .type_box_card_div {
        margin-left: 0;
      }
    }

    md-card {
      padding: 0;

      .inner_content {
        padding: 15px;
        background-color: #e9edee;

        .header_title {
          padding-left: 23px;
        }
      }

      .footer_section {
        margin: 0;
        background-color: #21374c;
        padding: 20px 15px;
        color: variables.$white;

        .custom_type-value {
          font-size: 25px;
          font-weight: bold;
          margin-left: 25px;
        }

        .custom_svg_icon {
          svg {
            g {
              color: #ffffff;
              fill: #ffffff;
            }
          }
        }
      }

      .description {
        h2 {
          margin-top: 5px;
        }
      }
    }
  }

  .section3 {
    background-color: #e9edee;
    margin-bottom: 30px;

    .business-profile {
      padding-left: 0;
    }

    .type_box_card_section {
      margin-left: 0;

      .type_box_card_div {
        margin-left: 0;
      }
    }
  }
}

@media (max-width:1200px) {
  .customer_campaign_report {
    .type_box_card_section {
      height: auto;

      .type_box_card_div {
        margin-left: auto;
      }
    }

    .section2 {
      .type_box_card_section {
        margin-left: auto;
      }
    }
  }
}

@media (max-width:600px) {
  .customer_campaign_report {
    .type_box_card_section {
      margin-left: 15px;

      .type_box_card_div {
        display: contents;
      }
    }

    .type-box {
      width: 100%;
      max-width: none;
    }

    .icon_png {
      margin-bottom: 20px;
    }

    .custom-default-box {
      margin: auto;
    }

    .business-profile {
      padding-left: 35px;
    }

    .section2 {
      padding-left: 0;

      md-card {
        .inner_content {
          .header_title {
            padding-left: 10px;
          }
        }
      }
    }
  }
}
