@use "../../settings/third-party/variables";

// .notification-add {
//     padding: 20px;

//     .col-2-list {
//         li {
//             margin: 10px 0px 20px;

//             md-input-container, .datepicker-custom {
//                 max-width: 245px;
//             }

//             md-chips {
//                 max-width: 245px;
//             }

//             md-input-container {
//                 &.small-input {
//                     max-width: 60px;
//                     display: inline-block;
//                     vertical-align: top;

//                     label {
//                         top: -15px;
//                         left: 5px;
//                     }

//                     input {
//                         border: 1px solid $gray-light;
//                         border-radius: 3px;
//                         padding-left: 7px;
//                     }
//                 }
//             }

//             .text-desc {
//                 max-width: 460px;

//                 textarea {
//                     min-height: 70px;
//                     border: 1px solid #ccc;
//                     border-radius: 3px;
//                 }
//             }

//             .lbl-txt {
//                 @extend .f4;

//                 display: inline-block;
//                 vertical-align: middle;
//                 margin-top: 12px;
//                 margin-left: 5px;
//             }

//             &.chip-wrap {
//                 md-switch.md-block {
//                     top: 0px;
//                     display: inline-block;
//                     vertical-align: middle;

//                     .md-container .md-bar {
//                         background: #73D6F5;
//                     }

//                     .md-label {
//                         color: $white;
//                     }

//                     &.md-checked .md-bar {
//                         background: $brand-accent;
//                     }
//                 }
//             }
//         }

//         label {
//             @extend .f4;

//             display: inline-block;
//             max-width: 122px;
//             margin-right: 30px;
//             margin-bottom: 5px;
//         }
//     }
// }

.notification-view-section {
    .viewTitles {
        font-weight: 500;
        margin-top: 0;
        margin-bottom: 0;
    }

    .view-titleGap {
        margin-bottom: 10px;
    }

    .view-sectionGap {
        margin-bottom: 25px;
    }

    .target-view {
        .icon-tick {
            margin-left: 30px;
            margin-right: 10px;
        }
    }

    .email-composed {
        color: #555555;

        .email-subject {
            font-size: variables.$font-size-base;
        }
    }

    .logic-detail-list {
        li {
            margin-bottom: 10px;
        }
    }
}

.notification-view-section,
.notification-edit-section {
    .custom-icon {
        height: 21px;
        width: 21px;
    }
}

.notification-edit-section {
    .sm-text {
        font-size: 10px;
    }

    md-checkbox {
        margin-bottom: 20px;
    }

    md-radio-group md-radio-button {
        font-size: variables.$font-size-small;
        margin-bottom: 20px;
    }

    .targetAddBtn {
        margin-left: -8px;
        margin-right: 0;
    }

    .email-composer-input {
        textarea {
            font-size: variables.$font-size-small;
            height: 200px !important;
        }
    }

    .composer-option {
        margin-top: -10px;

        .delete-btn {
            margin-right: -8px;
        }
    }

    .switch-bar {
        margin-top: 5px;
        margin-bottom: 10px;

        label {
            padding-right: 20px;
        }
    }

    .summery-widget {
        box-sizing: border-box;

        &.logic-widget {
            min-width: 250px;
        }

        .heading {
            margin-top: 0;
            font-weight: 500;
            margin-bottom: 15px;
        }

        .line-gap {
            margin-bottom: 12px;
        }

        .icon-tick {
            margin-right: 8px;

            .tick-bg {
                fill: transparent;
            }
        }
    }

    .widget-mainBtn {
        margin-top: 0;
        margin-left: 0;
    }

    .ui-accordion {
        .icon-tick {
            margin-left: 8px;
        }
    }

    .widget-result {
        margin-top: 15px;
    }

    .logic-innerSection:not(:last-child) {
        margin-bottom: 30px;
    }
}

.notification-sidebar {
    .leftnav-wrap {
        position: relative;

        .task-wrapper,
        .bottom-section {
            position: absolute;
            right: 0;
            left: 0;
        }
    }

    .task-wrapper {
        top: 0;
        bottom: 50px;
        overflow-y: auto;
    }

    .bottom-section {
        bottom: 1px;
    }

    .sidebar-title {
        font-weight: 500;
        margin-top: 0;
    }

    .mainBtn {
        margin-top: 0;
        margin-left: 0;
    }
}

@media screen and (min-width: 1025px) {
    .notification-edit-section {
        .summery-widget {
            width: 200px;
        }
    }
}

@media screen and (min-width: 600px) {
    .notification-edit-section {
        .notification-leftSection {
            float: left;
            width: 53%;
        }

        .notification-rightSection {
            float: right;
        }

        .target-widget {
            min-width: 220px;
        }
    }

    .default-business-logo {
        min-width: 255px;
    }
}

@media screen and (max-width: 600px) {
    .notification-add .col-2-list li md-radio-group {
        margin-top: 10px;

        md-radio-button {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .notification-add .col-2-list li.chip-wrap {
        margin-bottom: 0;
        max-height: 30px;

        md-switch.md-block {
            top: -25px;
            left: 50px;
        }
    }

    .notification-view-section {
        .default-business-logo {
            margin-top: 20px;
        }
    }
}