@use "../../settings/third-party/variables";

.smart-cards-list {
  .hamburger-dot:before {
    font-size: 9px;
    margin-left: 0;
  }
}

@media(width > 600px) {
  .welcome-page {
    padding: 20px;
  }
}

.welcome-page {
  background-image: url("#{variables.$image-path}/Black-bg-3.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  overflow-y: scroll;
  gap: 20px;
  // border: 1px solid white;

  .section-1 {
    justify-content: space-between;

    .exit-desktop-logo {
      cursor: pointer;
      width: 35px;
      height: 35px;
      position: absolute;
      right: 10px;
      top: 0;
    }
  }

  .section-2 {
    background-color: white;
    // width: 40%;
  }

  .welcome-cta {
    width: 55px;
    height: 55px;
    line-height: 55px;
    background-color: orange;
    border-radius: 50%;

    path {
      fill: variables.$white;
    }
  }

  .scan-center-cta {
    min-width: 100px;
  }

  .welcome-to {
    color: variables.$brand-accent;
  }

  .line-height-1 {
    line-height: 1.3 !important;
  }

  .scan-out-txt {
    color: variables.$brand-accent;
    font-weight: 600;
  }

  .cta-txt {
    font-size: 12px;
    color: black;
    font-weight: 600;
  }

  .btn-sec {
    // width: 80%;
    min-width: 225px;
  }

  .main-screen-btn {
    background-color: transparent;
    border: 1px solid orange;
  }

  .logo-div {
    display: none;
  }

  .black-text {
    color: black;
  }

  .font-size-30 {
    font-size: 25px;
  }

  .width-100 {
    width: 100%;
  }

  .font-size-20 {
    font-size: 20px;
  }

  .font-normal {
    font-weight: 400;
  }

  .font-size-35 {
    font-size: 35px;
  }
  .margin-right-10{
    margin-right: 10px;
  }

  .font-semi-bold {
    font-weight: 600;
  }

  .white-text {
    color: white;
  }

  .qr-sec {
    border: 1px solid gray;
    width: 80%;
    padding: 5px;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
  }

  .arrow-icon {
    width: 55px;
    height: 55px;
    position: absolute;
    top: -15px;
    left: -30px;
    z-index: 1;
    transform: rotate(30deg);
  }

  .qr-small-img {
    width: 20px;
    height: 20px;
  }

  .video-container {
    height: 100%;
  }

  .single-img-display {
    width: 100%;
    height: auto;
  }

  .carousel-div {
    width: 100%;
    height: 75%;
    border-radius: 8px;

    &.full-screen {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      z-index: 100;
    }

    .fullscreen-icon {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    #slider {
      position: relative;
      width: 100%;
      overflow: hidden;
      height: 100%;
    }

    #slider #line {
      height: 5px;
      background: rgba(0,0,0,0.5);
      z-index: 1;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    #slider #dots {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 16px;
      display: flex;
      justify-content: center;
    }

    #slider #dots li {
      transition: 0.3s;
      list-style-type: none;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background: rgba(236, 236, 236, 0.5);
      margin: 0 0.25em;
      cursor: pointer;
    }

    #slider #dots li.active,
    #slider #dots li:hover {
      background: orange;
    }

    @keyframes line {
      0% {
        width: 0;
      }

      100% {
        width: 100%;
      }

    }

    #slider #back,
    #slider #forword {
      width: 6%;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: 0.3s;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      color: white;
      font-weight: 700;
      font-size: 2rem;
      background: -moz-linear-gradient(left, rgba(255,255,255,0.75) 0%, rgba(255,255,255,0) 100%);
      background: -webkit-linear-gradient(left, rgba(255,255,255,0.75) 0%,rgba(255,255,255,0) 100%);
      background: linear-gradient(to right, rgba(255,255,255,0.75) 0%,rgba(255,255,255,0) 100%);
      // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bfffffff', endColorstr='#00ffffff',GradientType=1 );
    }

    #slider #forword {
      left: auto;
      right: 0;
      background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.75) 100%);
      background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.75) 100%);
      background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.75) 100%);
      // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#bfffffff',GradientType=1 );
    }

    #slider:hover #back,
    #slider:hover #forword {
      opacity: 0.7;
    }

    ul#move {
      margin: 0;
      padding: 0;
      display: flex;
      width: 100%;
      height: 100%;
      background: white;
      margin-right: 100%;
    }

    ul#move li {
      transition: 0.6s;
      min-width: 100%;
      color: white;
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    ul#move li img {
      width: 100%;
    }

    ul#move li:nth-child(1) {
      background: #657765;
    }

    ul#move li:nth-child(2) {
      background: #456174;
    }

    ul#move li:nth-child(3) {
      background: #984;
    }

    ul#move li:nth-child(4) {
      background: #445566;
    }

    ul#move li:nth-child(5) {
      background: #744674;
    }
  }

  .big-logo {
    width: 12rem;
    height: auto;
  }

  .carousel-text-div,
  .slider-image,
  .video-section {
    width: 100%;
    height: 100%;
  }

  .video-section {
    background-color: white;
    position: relative;

    .video-bar {
      position: absolute;
      left: 0;
      right: 0;
      // height: 70px;
      width: 100%;
      height: 40px;
      bottom: 0;
    }
  }

  .carousel-text-div {
    opacity: 0.9;
    background-color: aquamarine;
    opacity: 0.5;
    border-radius: 5px;
    padding: 15px;

    .rand-carousel-txt {
      text-align: justify;
      max-width: 900px;
      font-size: 40px;
      font-weight: 400;
      color: #52758D;
    }
  }

}

.contact-info {
  .section-2 {
    .section2-logo {
      width: 8rem;
      height: auto;
    }
  }

  .contact-form {
    md-input-container {
      .md-input,
      .md-select-value .md-select-icon,
      .md-select-value .md-text,
      .md-select-value.md-select-placeholder,
      label {
        color: variables.$white;
      }
    }

    md-select[disabled] .md-select-value span div {
      color: variables.$white;
    }

    md-select .md-select-value {
      border-bottom-color: variables.$white;
    }

    .contact-form-icon {
      width: 20px;
      height: 20px;
      margin-top: 10px;
    }
  }

}

.thank-you {
  .height-80 {
    height: 80%;
  }

  .view-contact-btn {
    padding: 10px;
    background-color: transparent;
    border: 1px solid orange;
    width: 200px;
  }

  .width-100 {
    width: 100%;
  }

  .contact-added-btn {
    /* min-width: 140px; */
    padding: 10px;
    width: 100%;
    max-width: 140px;
  }

  .thank-you-sect {
    height: 100%;

    .thank-you-txt {
      font-size: 40px;
    }

    .contact-success-ctas {
      margin-bottom: 175px;
    }

    .new-contact-btn {
      width: 20%;
      padding: 5px 0;
      margin-right: 20px;
      font-size: 20px;
      min-width: 150px;
      max-width: 250px;
    }

    .confetti-sect {
      display: none;
    }

    .transition-txt {
      text-align: center;
    }

  }

}

.enter-pin {
  .enter-pin-txt {
    font-size: 35px;
    font-weight: 600;
  }

  .enter-pin-form {
    // border: 1px solid red;
    height: 100%;
    margin-top: 40px;
    margin-left: 20px;
    position: relative;
    // background-color: red;

    .enter-pin-btns {
      //    display: flex;
      //    border: 1px solid white;
      width: 100%;
      margin-top: 150px;
      gap: 15px;

      .exit-btn,
      .main-screen-btn {
        width: 20%;
        padding: 5px 0;
        min-width: 150px;
        max-width: 250px;
      }
    }

    .transition-txt {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .pin-div-container {
    display: flex;
    width: 100%;
    gap: 50px;
    margin-top: 50px;
    min-width: 320px;
  }

  .pin-div {
    width: 70px;
    border-radius: 5px;
    background-color: white;
    padding: 5px;
    display: flex;
    max-width: 70px;
  }
}

.scanned-data {

  .retake-message-sec{
    background-color: red;
    padding: 8px;
    max-width: 350px;
    // float: right;
  }
  .next-btn,
  .retake-btn {
    padding: 5px 0;
    margin-right: 20px;
    font-size: large;
    min-width: 140px;
  }

  .retake-btn {
    background-color: transparent;
    border: 1px solid orange;
    margin-right: 25px;
  }

  .img-holder {
    width: 150px;
    height: 150px;
    border: 1px solid white;
    background-color: rgb(195, 195, 195);
  }

}

/* Styles for tablet view */
@media (min-width: 601px) and (max-width: 1024px) {
  .welcome-page {
    .section-1 {
      width: 70%;
      order: 2;
      padding: 25px 0;
    }

    .section-2 {
      background-color: white;
      width: 30%;
      order: 1;
      justify-content: space-around;
      padding: 15px;
      border-radius: 5px;

      .big-logo {
        width: 10rem;
        height: auto;
      }
    }

    .section2-logo {
      width: 7.5rem;
      height: auto;
    }

    .qr-scan-txt {
      font-size: 1.5625rem;
    }

    .btn-sec {
      width: 80%;
      // min-width:225px;
    }

    .carousel-div {
      width: 100%;
      background-color: aquamarine;
      height: 70%;
      border-radius: 8px;
    }

    .carousel-text-div {
      opacity: 0.5;

      .rand-carousel-txt {
        font-size: 30px;
      }
    }

    .arrow-icon {
      width: 30px;
      height: 30px;
      top: -15px;
      left: 25px;
    }

    .contact-btn {
      font-size: 11px;
      font-weight: 600;
    }

    .jacktrade-powered-mobile {
      display: none;
    }

  }

  .contact-info {
    display: flex;
    flex-direction: row;

    .section-2 {
      order: 1;
    }

    .section-1 {
      order: 2;
    }

    .btns-div {

      margin-bottom: 20px;

      .back-btn,
      .submit-btn {
        width: 40%;
        padding: 10px 0;
        margin-right: 20px;
        font-size: large;
      }

      .back-btn {
        background-color: transparent;
        border: 1px solid darkorange;
      }
    }

    .sell-info-txt {
      text-align: start;
      font-size: 14px;
    }
  }
}

/* Styles for desktop view */
@media (min-width: 1025px) {
  .welcome-page {
    .section-1 {
      width: 70%;
      order: 2;
      padding: 30px 0;
    }

    .section-2 {
      // background-color: white;
      width: 30%;
      order: 1;
      justify-content: space-around;
      padding: 15px;
      border-radius: 5px;
    }

    .section2-logo {
      width: 7.5rem;
      height: auto;
    }

    .qr-scan-txt {
      font-size: 1.5625rem;
    }

    .btn-sec {
      width: 80%;
      min-width: 270px;
      // border: 4px solid red;
    }

    .arrow-icon {
      top: -30px;
      // left:80px;
    }

    .contact-btn {
      width: 100%;
      font-size: 20px;
      padding: 15px 0;
      font-weight: 600;
    }

    .jacktrade-powered-mobile {
      display: none;
    }

  }

  .contact-info {
    .input-cont-div {
      margin-top: 25px;
    }

    .btns-div {

      margin: 20px 0;
      // border: 1px solid white;

      .back-btn,
      .submit-btn {
        width: 40%;
        padding: 10px 0;
        margin-right: 20px;
      }

      .back-btn {
        background-color: transparent;
        border: 1px solid darkorange;
      }
    }

    .sell-info-txt {
      text-align: start;
      font-size: 14px;
    }
  }
}

/* Styles for mobile view */
@media (max-width: 600px) {
  .mobile-view-margin {
    margin-bottom: 140px;
  }
  .welcome-page {
    justify-content: space-between;

    .white-text {
      color: white;
    }

    .cta-txt,
    .scan-out-txt,
    .scan-qr-code {
      color: white;
    }

    .scan-qr-code {
      font-size: 20px;
      margin-bottom: 5px;
    }

    .scan-out-txt {
      font-size: 15px;
      color: white;
      font-weight: 400;
    }

    .cta-txt {
      font-size: 12px;
      // word-break: break-all;

    }

    .welcome-cta {
      width: 50px;
      height: 50px;
    }

    .section-1 {
      padding: 0 15px;
      justify-content: start;

      .welcome-to {
        font-size: 16px;
        margin-bottom: 2px;
        text-align: center;
        color: white;
        font-weight: 600;
      }

      .suburban-home {
        font-size: 18px;
        width: 100%;
        text-align: center;
        color: #566874;
      }

    }

    .section-2 {
      background-color: transparent;
      padding: 15px 0;
      margin-top: 20px;
    }

    .logo-div {
      display: flex;
      // padding:0 15px;
      margin-bottom: 30px;

      .logo-img {
        width: 40px;
        height: auto;
      }

      .exit-icon {
        width: 30px;
        height: 30px;
      }
    }

    .carousel-div {
      width: 100%;
      background-color: aquamarine;
      height: 180px;
      // height: 60%;
      margin-top: 15px;
      border-radius: 8px;
    }

    .qr-sec {
      border: 1px solid black;
      margin-top: 10px;
      width: 55%;
      padding: 5px;
    }

    .qr-big-img {
      width: 100%;
    }

    .qr-small-img {
      width: 20px;
      height: 20px;
    }

    .arrow-icon {
      width: 40px;
      height: 40px;
      top: -20px;
      left: -20px;
    }

    .btn-sec {
      width: 80%;
      min-width: 250px;
    }

    .section2-logo {
      display: none;
    }

    .jacktrade-powered-dektop {
      display: none;
    }

    .cpm-ide {
      display: block;
      // margin-top: 30px;
    }

    .qr-scan-txt {
      font-size: 1rem;
    }

    .contact-btn {
      padding: 5px 0;
      width: 100%;
      font-size: 1rem;
      font-weight: 600;
    }

    .carousel-text-div {
      opacity: 0.5;

      .rand-carousel-txt {
        font-size: 20px;
      }
    }

  }

  .contact-info {
    display: flex;
    flex-direction: column;
    justify-content: start;

    .section-2 {
      display: none;
    }

    .section-1 {
      .bottom-text {
        margin-top: auto;
      }
    }

    .contact-form {
      .title {
        font-size: 25px;
        margin-bottom: 30px;
      }

      .btns-div {
        margin-top: 15px;
        margin-bottom: 20px;

        .back-btn,
        .submit-btn {
          width: 50%;
          padding: 5px 0;
          // margin-bottom: 15px;
        }

        .back-btn {
          margin-top: 20px;
          background-color: transparent;
          border: 2px solid darkorange;
        }
      }

      .sell-info-txt {
        font-size: 16px;
        margin-bottom: 10px;
      }

    }
  }

  .thankyou {
    .contact-added-txt {
      font-size: 25px;
    }

    .home-btn,
    .scan-btn,
    .view-contact-btn {
      border-radius: 10px;
    }

    .thank-you-sect {
      height: 85%;

      .confetti-sect {
        display: block;
        width: 100%;
        height: 150px;

      }

      .txt-sect {
        // margin-top: 100px;
        text-align: center;
        height: 50%;
      }

      .thank-you-txt {
        font-size: 25px;
      }

      .get-in-touch-txt {
        font-size: 20px;
      }

      .new-contact-btn {
        width: 70%;
        margin: 10px auto;
      }

      .transition-txt {
        text-align: center;
        margin: 10px auto 0;
      }
    }

  }

  .enter-pin {
    .enter-pin-form {
      margin-top: 50px;
      margin-left: 0;

      .enter-pin-txt {
        font-size: 25px;
        // font-weight: 400;
        text-align: center;
      }

      .transition-txt {
        display: none;
      }
    }

    .pin-form {
      margin-top: 80px;

      .pin-div-container {
        // justify-content: space-around;
        // border: 1px solid white;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-width: 200px;

        .pin-div {
          width: 70px;
          // max-width: 80px;
          height: 60px;
          border-radius: 0;
          background-color: white;
          padding: 5px;
          display: flex;
          min-width: 40px;
          // margin-bottom: 10px;
          justify-content: center;
          align-items: center;
          // padding-bottom: 10px;
          // max-width: 70px;
        }
      }

      .enter-pin-btns {
        // border: 1px solid white;
        margin-top: 100px;
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        // justify-content: center;
        width: 100%;
        padding: 5px;
      }

      .exit-btn,
      .main-screen-btn {
        width: 200px;
        max-width: 200px;
        padding: 10px 0;
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  .scanned-data {
    .title1 {
      font-size: 20px;
      text-align: center;
    }

    .title2 {
      font-size: 12px;
      text-align: center;
    }

    .btns-div {
      margin-bottom: 20px;
    }

    .next-btn,
    .retake-btn {
      margin: 0;
      min-width: 123px;
      border-radius: 5px;
    }
  }

  .business-card-info{
    .section-1{
      
      .info-title{
        margin-top: 20px;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
      }

      .btn-section{
        margin-top: 20px;
      }

      .title , .subtitle{
        text-align: center;
      }
    }
  }

}


.business-card-info{
  // background-color: red;
  height: 100%;
  width: 100%;

  .section-1{
    justify-content: start;
    height: 100%;

    .info-sec{
      max-width: 720px;
    }

    .margin-right-20{
      margin-right: 20px;
    }

    .padding-vertical-5{
      padding:5px 0;
    }

    .continue-btn{
      min-width: 150px;
    }

    .title{
      font-size: 18px;
    }
    .subtitle{
      font-size: 20px;
    }
    .btn-section{
      margin-top: auto;
    }
  }
}