.attr-policy {
  h3 {
    margin: 0 0 0 25px;
  }
}

.bottom-gap {
  margin-bottom: 20px;
}

.right-gap {
  margin-right: 20px !important;
}

.add-attribute {
  .add-new-att {
    padding-left: 10px;
    padding-top: 10px;
  }
}
