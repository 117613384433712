@use "../../settings/third-party/variables";

.workboard-search {
  font-size: 14px;
  margin: 12px 0 40px;
  padding: 6px 4px 6px 0;
  border: 2px solid variables.$brand-accent;
  border-left: none;
  border-right: none;
  color: variables.$brand-accent;

  md-icon {
    svg path {
      fill: variables.$brand-accent;
    }
  }
}

.custom-chip.folder-name{
  border-radius: unset;
  md-icon{
    width:15px;
    height:15px;
  }
}

.archive-folder {
  .archived-folder p {
    border: 1px solid variables.$gray-light-media;
    padding: 10px;
    border-left: none;
    border-right: none;
    color: variables.$brand-accent;
    font-size: 15px;
  }
}
// sort action item

.sort-action-item {
  .action-items {
    span {
      font-size: 14px;
      font-weight: bold;
    }

    .radio-list-btn {
      margin: 0 10px 0 0;
    }
  }

  .edit-desc {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .contact-desc {
    padding: 10px;

    md-icon {
      width: 18px;
      height: 18px;
    }
  }

  .star md-icon {
    margin-top: -5px; // margin-right: 10px;
  }

  .quotes-status {
    .qoute-seperator {
      position: relative;

      hr {
        border: 1px solid variables.$gray-light-media;
      }
    }

    .number-ccounter {
      position: absolute;
      background-color: variables.$brand-accent;
      width: 25px;
      height: 25px;
      min-height: 25px;
      line-height: 6px;
      right: 15px;
      margin-top: 2px;
    }

    .counter-square {
      border-radius: 10%;
    }
  }

  .action-item-list {
    border: 1px solid variables.$white-dark;

    .contact-desc:not(:last-child) {
      border-bottom: 1px solid variables.$white-dark;
    }
  }
}
