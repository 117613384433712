.new-email-setup {
  padding: 20px;

  .custom-tabs md-content {
    padding-top: 25px;
  }

  .top-heading {
    margin-top: 0;
  }

  .add-resources {
    md-icon {
      margin-right: 3px;
    }
  }

  .template-info {
    max-width: 280px;

    .summery-widget md-icon {
      margin-right: 5px;
    }
  }

  @media screen and (min-width: 600px) {
    .template-info {
      padding: 20px 0 40px 20px;
    }
  }
}

.sel-templ-sidebar {
  .top-section {
    top: 0;
    bottom: 50px;
    overflow-y: scroll;
    position: absolute;
    right: 0;
    left: 0;
  }

  .gap {
    padding: 0 15px;
  }

  .bottom-section {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    height: 50px;
  }
}
