@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.tax-policyadd-section {
  .filters-bar {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
  }

  .top-gap-xs {
    margin-top: 10px;
  }

  .top-gap-sm {
    margin-top: 15px;
  }

  .instruction-msg {
    color: variables.$gray;
    font-size: 10px;
  }

  .custom-tabs {
    md-content {
      padding-top: 28px;
    }
  }

  .add-tab {
    position: absolute;
    top: -7px;
    right: 0;
    z-index: 12;

    .md-button {
      margin-right: 0;
      position: relative;
      top: -2px;

      md-icon {
        height: 21px;
        width: 21px;
      }
    }
  }

  .remove-tab-link {
    position: absolute;
    top: 28px;
    right: 0;
  }

  .switch-bar {
    label {
      display: inline-block;
      margin-bottom: 16px;
      padding-right: 12px;
    }
  }

  .input-list-section {
    .instruction-msg {
      margin-bottom: 20px;
    }
  }

  .input-cb-list {
    @extend .no-style;

    md-radio-group md-radio-button {
      @extend .f3;

      width: 88px;
    }

    li {
      margin-bottom: 14px;
    }
  }

  .zone-list-btn {
    margin-left: -8px;
    margin-right: 0;
  }

  .tax-zone-list {
    @extend .no-style;
    @extend .f3;

    .action-button {
      height: auto;
      min-height: auto;
      width: auto;

      md-icon {
        height: 14px;
        width: 14px;
      }
    }
  }
}

.tax-policyView-section {
  .custom-tabs {
    md-content {
      padding-top: 20px;
      padding-bottom: 10px;
    }
  }

  .main-title {
    @extend .f1;

    margin-bottom: 16px;
  }

  .sub-title {
    @extend .f3;

    margin-bottom: 18px;
  }

  .main-title,
  .sub-title {
    font-weight: normal;
    margin-top: 0;
  }

  md-divider {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .rpp-list {
    li {
      margin-bottom: 16px;
    }
  }
}

@media screen and (min-width: 600px) {
  .tax-policyadd-section {
    .input-list-section {
      .instruction-msg {
        margin-left: 30px;
      }
    }

    .input-cb-list {
      .instruction-msg {
        margin-top: 1px;
        margin-left: -2px;
      }
    }

    .tax-zone-list {
      width: 200px;
    }
  }
}
