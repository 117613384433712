@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.reset-password-popup {
  min-height: 450px;
  max-height: 500px;
  width: 100%;
  max-width: 650px;

  .main-header .icon-right-arrow {
    height: 16px;
    width: 14px;
    vertical-align: middle;
  }

  h3 {
    font-size: variables.$font-size-base;
  }

  md-dialog-content {
    padding: 20px;

    md-input-container {
      width: 100%;
      max-width: 290px;
    }
  }

  .step-marker {
    color: variables.$gray;
    position: absolute;
    bottom: 25px;
    right: 50px;
  }
}

.popupleft-nav {
  overflow: visible;
  top: 63px;

  .sidenav-close {
    background: #f2f2f2;
    border-radius: 3px;
    position: absolute;
    right: -25px;
    top: 30px;
    z-index: 0;
    margin: 0;
    padding: 7px;
    min-width: 0;
    width: auto;
    min-height: 0;
    height: 30px;
    cursor: pointer;

    md-icon {
      height: 12px;
      width: 12px;
      transform: rotate(180deg);
    }
  }

  .sidenav-close:not([disabled]).md-icon-button:hover,
  .sidenav-close:not([disabled]):hover {
    background: #f2f2f2;
  }

  .assign-number {
    @extend .f2;

    position: absolute;
    bottom: 12px;
    left: 27px;
  }

  .assignmenu-list {
    padding: 0;
    background: variables.$white;

    .md-subheader .md-subheader-inner {
      background: variables.$white-darker;
      color: variables.$gray-dark;
      padding-left: 52px;
      font-size: variables.$font-size-small;
    }

    md-list-item {
      &:not(:last-child) {
        border-bottom: 1px solid variables.$border-color;
      }

      .md-list-item-inner {
        padding: 18px;

        > md-checkbox {
          margin-right: 9px;
        }
      }

      p {
        line-height: 1.5;

        > strong {
          font-size: 18px;
        }

        .assign-date {
          @extend .f3;
        }

        .assign-time {
          font-size: 10px;
          padding-left: 8px;
        }
      }
    }
  }
}

.reporting-popups {
  width: 800px;
  height: 500px;

  h3 {
    font-size: 16px;
  }

  md-dialog-content {
    padding: 20px;

    md-input-container {
      position: relative;
      width: 100%;
      margin-bottom: 14px;

      label {
        @extend .f4;

        line-height: 1.8;
      }

      input[type="text"],
      md-select {
        height: 32px;

        span {
          font-size: variables.$font-size-small;
        }
      }

      &.secondary-input {
        label {
          font-size: variables.$font-size-small;
        }
      }

      &.select-input {
        margin-top: 8px;
        margin-bottom: 10px;
      }

      .edit-pencil {
        position: absolute;
        height: 8px;
        width: 8px;
        right: 4px;
        top: 13px;
        left: inherit;

        svg {
          path {
            fill: variables.$gray;
          }
        }
      }

      &.md-has-icon > label {
        left: inherit;
        right: inherit;
      }

      md-checkbox {
        margin-right: 25px;
      }
    }

    .add-department {
      margin-top: 15px;
      margin-bottom: 20px;

      span {
        padding-top: 1px;
        display: inline-block;
        vertical-align: middle;
      }

      md-icon {
        height: 18px;
        width: 18px;
        margin-right: 10px;
      }
    }

    .section-title {
      @extend .f3;

      font-weight: normal;
      margin-top: 0;
      margin-bottom: 18px;
    }

    // .loc-checkbox {
    //     margin-top: 10px;

    //     md-checkbox .md-label {
    //         font-size: $font-size-base;
    //     }
    // }
  }
}

.resource-popups {
  min-height: 500px;
  width: 800px;

  h2 {
    font-size: 18px;
  }

  &.add-address-popup {
    md-dialog-content {
      padding: 35px 55px;
    }
  }

  &.print-schedule-popup {
    md-dialog-content {
      padding: 20px;
    }
  }
}

.print-schedule-popup {
  .form-base {
    md-radio-group md-radio-button {
      display: block;
      margin-bottom: 12px;
    }
  }

  md-radio-button {
    .md-label {
      margin-left: 34px;
    }
  }

  .dp-section {
    margin-top: 22px;
  }

  .date-picker {
    margin-bottom: 20px;
    width: 118px;

    input {
      &[disabled] {
        border-bottom: 1px solid variables.$white-dark;
        color: variables.$gray;
        height: 28px;
      }
    }

    .md-datepicker-input-container {
      margin-left: 0;
      width: 100%;
    }

    .md-datepicker-button {
      background: variables.$white;
      position: absolute;
      padding: 0;
      height: 32px;
      width: 32px;
      right: -10px;
      top: -5px;
      z-index: 1;
      min-height: 32px;
    }
  }
}

// Google Login

.google-login {
  md-icon {
    margin: 10px !important;
  }

  .fm {
    margin-top: 15px;
  }

  md-input-container {
    margin-left: 40px;
  }

  .btn-google-login {
    margin-left: 254px;
  }

  .cancel-button {
    background-color: lightgrey;
    color: white;
  }
  @media screen and (max-width: 600px) {
    .btn-google-login {
      margin-left: 0 !important;
    }
  }
}

//Grant-Access-popup
.grant-access {
  .resource-popups {
    width: 430px !important;
    padding: 30px 20px 0 30px;
  }

  .success-text {
    margin-top: 70px;
    color: green;
  }

  .sync-calendar-text {
    margin: 20px 0;
  }
}

@media screen and (min-width: 601px) {
  .add-address-popup .gap-right {
    margin-right: 15px;
  }

  .print-schedule-popup {
    .gap-left {
      margin-left: 170px;
    }

    .date-picker.gap-right {
      margin-right: 20px;
    }
  }

  .reporting-popups {
    .section-right-gap {
      margin-right: 20px;
    }
  }
}

@media screen and (max-width: 601px) {
  .resource-popups {
    md-toolbar {
      &.main-header {
        h2 {
          font-size: 15px;
          text-align: center;
        }

        .md-button.btn-save {
          min-width: 45px;
        }
      }
    }

    &.add-address-popup,
    &.print-schedule-popup {
      md-dialog-content {
        padding: 25px;
      }
    }
  }
}
