@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

md-input-container {
  &.pl-btn.header-select-input {
    background: variables.$brand-primary;
    border-radius: 4px;
    margin-top: 2px;
    margin-left: 8px;
    min-width: 88px;

    // md-select {
    //   max-height: 30px;
    // }

    md-select-value {
      position: relative;
      top: -2px;
      padding: 0 9px;

      .md-text {
        color: variables.$white;
      }

      .md-select-icon:after {
        color: variables.$white;
        top: -1px;
        -webkit-transform: scaleY(0.8) scaleX(1.5) rotate(90deg);
        transform: scaleY(0.8) scaleX(1.5) rotate(90deg);
      }
    }
  }
}

.status-dropmenu {
  &.cat-prodList-menu {
    // max-height: none;

    md-menu-item {
      & > .md-button.small-btn {
        font-size: 13px;
      }

      &.title {
        font-size: 15px;
        line-height: 13px;
      }
    }
  }
}

.product-list-section {
  padding: 18px;

  .top-section {
    margin-top: -10px;

    .big-text .search-result {
      color: variables.$gray-dark;
    }

    .big-text {
      @extend .fm;
      font-size: 16px;
    }

    .search-result {
      font-weight: 400;
    }

    .search-title {
      font-size: 16px;
    }

    .search-result-container {
      border-radius: 4px;
      color: variables.$gray-darker;
      background: variables.$brand-info;
      font-size: 10px;
      padding: 10px;
      margin-left: 15px;

      .underline {
        font-style: italic;
        font-weight: 500;
        text-decoration: underline;
      }
    }

    .md-button.md-icon-button {
      margin-right: 0;

      md-icon {
        height: 21px;
        width: 21px;
      }
    }
  }

  .advance-search-result {
    @extend .f1;
    margin-bottom: 12px;
  }

  .filters-bar {
    .inner-inputs {
      li {
        &,
        &.date-picker {
          max-width: 100%;
          width: 190px;
        }
      }
    }

    md-input-container {
      &,
      .md-input,
      md-select {
        width: 100%;
      }
    }

    .date-picker md-datepicker .md-datepicker-input-container {
      width: 100%;

      .md-datepicker-input {
        width: 80%;
      }
    }
  }

  .data-table {
    min-width: 800px;
    // md-checkbox {
    //   .md-container {
    //     background: $white;
    //   }
    // }
    .location-symbol {
      margin-top: 2px;
      margin-right: 8px;
    }

    td {
      @extend .f3;
      // md-checkbox {
      // 	margin-top: 5px;
      // }
    }
  }

  .manage-item {
    .active {
      color: variables.$status-green;
    }

    .new {}

    .disabled {
      color: variables.$status-gray;

    }

    .archived {
      color: variables.$status-yellow;
    }

    .deleted {}

    .draft {
      color: variables.$status-gray;
    }

    .expired {
      color: variables.$status-red;
    }

  }

}

.product-list-sidebar {
  .gap {
    padding-left: 18px;
    padding-right: 18px;
  }

  .bottom-section,
  .top-section {
    position: absolute;
    right: 0;
    left: 0;
  }

  .bottom-section {
    bottom: 0;
  }

  .form-base {
    md-input-container {
      label {
        font-size: variables.$font-size-small;
      }
    }
  }

  // md-divider {
  //   margin-left : -18px;
  //   margin-right: -18px;
  // }

  .top-section {
    top: 0;
    bottom: 50px;
    overflow-y: auto;

    h2 {
      // margin-top: 18px;
      // margin-bottom: 3px;
      font-weight: 400;
    }

    // md-input-container {
    //   margin-bottom: 10px;
    // }

    // .item-chips {
    //   li {
    //     border: 1px solid $border-color;
    //     margin-bottom: 10px;
    //
    //     &:not(:last-child) {
    //       margin-right: 10px;
    //     }
    //   }
    //
    //   .icon-close {
    //     position: relative;
    //     top: -1px;
    //     margin-left: 4px;
    //   }
    // }
    //
    // .search {
    //   margin-top: 8px;
    // }
  }
}
