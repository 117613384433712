@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.opportunity-detail {
  padding: 20px;

  .item-chips {
    li {
      margin: 0 15px 15px 0;
    }
    margin-bottom: 21px;
  }

  .card-info-box {
    &:not(:last-of-type) {
      margin-bottom: 15px;
    }

    .visual-sm-icon {
      margin-right: 5px;
    }
  }

  .label-container {
    vertical-align: top;

    li {
      margin-bottom: 5px;
    }
  }

  .opp-detail-list {
    &,
    md-list-item {
      @extend .no-padding;
    }

    .attachment-list li {
      max-width: 260px;
      word-break: break-all;
    }

    md-list-item {
      min-height: auto;
      margin-bottom: 20px;

      &:before {
        // min-height: 40px;
        min-height: 0;
      }

      label {
        color: variables.$gray;
      }

      span {
        color: variables.$gray-darker;
        word-break: break-word;
      }

      .icon-star {
        // height: 15px;
        margin-right: 4px;
        // width: 15px;
      }

      // .list-rating {
      //   font-size: 10px;
      // }

      .attach-link {
        color: variables.$brand-primary;
      }
    }

    .range-slider {
      // display: inline-block;
      // width: 127px;
      margin: 0 10px 0 2px;
      // height: auto;
      // top: -27px;
      .md-thumb-text {
        color: variables.$white;
      }
    }
  }

  .btn-primary.comment-btn {
    margin-left: 0;
  }

  .opportunity-detail-tab {
    md-content {
      padding-top: 15px;
    }
  }

  .switch-bar {
    &:first-of-type {
      margin-right: 50px;
    }

    label {
      // min-width: 90px;
      margin-left: 15px;
    }

    .md-block {
      top: 1px;
    }
  }

  .icon-ico-add {
    height: 21px;
    margin-right: 8px;
    width: 21px;
  }

  .deal-status {
    padding: 6px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 5px 0;

    md-icon {
      margin: 0 10px 0 0;
    }

    &.won {
      background-color: #eaf0d6;
    }

    &.lost {
      background-color: #fae1dc;
    }

    &.postponed {
      background-color: #fff2cd;
    }

    &.pending {
      background-color: #d9d2e9;
    }
  }

  // .paym-dist-detail {
  //   @include border-radius(6px);
  //   margin-bottom: 20px;
  //   max-width: 268px !important;
  //   padding: 12px 10px;
  //   background-color: $footer-bgcolor;
  //
  //   h2,
  //   p {
  //     margin-top: 0;
  //     margin-bottom: 10px;
  //   }
  //
  //   h2 {
  //     @extend .f4;
  //     font-weight: normal;
  //   }
  //
  //   p {
  //     @extend .f3;
  //   }
  //
  //   md-checkbox {
  //     margin-bottom: 0;
  //
  //     &.md-checked {
  //       .md-container,
  //       .md-icon {
  //         height: 16px;
  //         width: 16px;
  //       }
  //
  //       .md-icon {
  //         background-color: #D7EEF4;
  //         border: 1px solid #B2C6CD;
  //
  //         &:after {
  //           border-color: #b2c6cd;
  //           left: 5px;
  //           top: 1px;
  //           height: 10px;
  //           width: 5px;
  //         }
  //       }
  //
  //       .md-label {
  //         color: $gray;
  //         font-size: 10px;
  //         font-weight: 300;
  //         margin-top: 3px;
  //         margin-left: 21px;
  //       }
  //     }
  //   }
  // }

  .table-responsive.activity-table-section {
    padding-top: 0;
  }

  .contact-user-list .user-contact {
    margin-top: 0;
  }

  .default-card-box,
  .opp-stats {
    margin-bottom: 27px;
  }

  .comment-section {
    li {
      margin-top: 15px;
      position: relative;

      &.default-card-box {
        padding: 15px 10px;
        box-shadow: 1px 1px 0 #D2D2D2;
        border-radius: 3px;
      }

      .profile-pic {
        max-width: 40px;
        max-height: 40px;
      }

      .comment-txt {
        display: inline-block;
        vertical-align: top;
        // width: 100%;
        padding-bottom: 5px;
        margin-left: 10px;
        word-break: break-word;

        p {
          margin-top: 5px;
          margin-bottom: 5px;
        }

        img {
          vertical-align: middle;
        }

        .usr-name {
          font-size: 10px;
          font-weight: normal;
          color: variables.$gray;
        }
      }
    }
  }
}

.label-box {
  display: inline-block;
  width: 150px;
  // height: 30px;
  padding: 3px;
  border-radius: 3px;
  outline: 0;
  vertical-align: middle;
  margin-right: 15px;
  color: white !important;
  font-size: 14px;
  text-align: center;
}

.opp-detail-edit {
  .format-option {
    position: relative;

    md-input-container {
      textarea {
        min-height: 140px;
        padding-bottom: 32px;
      }
    }
  }

  .comment-options {
    position: absolute;
    right: 0;
    bottom: 50px;

    md-icon {
      width: 25px;
      height: 20px;
      vertical-align: top;
      margin-right: 22px;

      svg {
        path {
          fill: variables.$gray-light;
        }
      }

      &.attach {
        width: 25px;
        height: 25px;
        margin-top: -3px;
      }

      &.active {
        svg {
          path {
            fill: variables.$brand-primary;
          }
        }
      }
    }
  }

  .edit-inputs {
    @extend .no-style;

    li {
      margin-bottom: 15px;

      &.rating-field {
        margin: 25px 0;

        md-icon {
          margin-right: 4px;
        }
      }

      md-input-container {
        &.with-label-box {
          width: 100%;

          input {
            width: 88%;
          }
        }
      }

      .datepicker-custom {
        .md-datepicker-button {
          top: -5px;
        }
      }

      .rating {
        // display: inline-block;
        margin-left: 10px;
        // vertical-align: bottom;

        // md-icon {
        //   height: 16px;
        //   width: 16px;
        // }
      }

      .attach-title {
        a {
          display: inline-block;
          margin-bottom: 15px;
        }
      }

      .slider-wrapp {
        label {
          display: inline-block;
          min-width: 110px;
          margin-right: 5px;
        }

        md-slider {
          max-width: 230px;
        }
      }

      h3 {
        margin: 40px 0 10px;
        font-size: variables.$font-size-small;
        font-weight: 500;
      }
    }
  }

  .payment-distribution {
    @extend .no-style;
    margin-top: 20px;

    md-input-container .md-placeholder,
    md-input-container label:not(.md-no-float):not(.md-container-ignore),
    md-input-container md-select md-select-value,
    md-input-container md-select:not([disabled]):focus md-select-value {
      padding-left: 0;
    }

    li {
      margin-bottom: 18px;

      md-input-container {
        margin: 0;
      }

      md-checkbox {
        span {
          color: variables.$gray-light;
        }
      }
    }
  }

  md-input-container {
    label,
    md-select span {
      color: variables.$gray;
      padding-bottom: 4px;
    }
  }

  .datepicker-custom {
    .md-datepicker-input-container {
      height: 31px;

      input {
        font-size: variables.$font-size-small;
        padding-left: 4px;
        padding-top: 10px;
      }
    }

    .md-datepicker-button {
      top: -5px;
    }
  }
}
@media only screen and (max-width: 600px) {
  .opportunity-detail .opp-detail-list {
    md-list-item {
      padding-left: 8px;

      // &:before {
      //   min-height: 30px;
      // }

      label {
        margin-bottom: 8px;
      }
    }

    // .range-slider {
    //   margin-left: 8px;
    // }
  }
}

.ar-sidepanel {
  .accordionN-section-content {
    padding: 0 !important;

  }

  .resourcedivision-allocation {
    margin-right: 30px;
  }

  .resource-assign {
    width: 100%;

    .resource-organisation {
      margin-top: 4px;
      line-height: 1;
    }

    .resource-name {}

    .resource-allocation {
      float: right;
    }
  }
}

.divison-side-allocation {
  a {
    padding: 9px 0;
    font-weight: normal;
    font-size: 12px;
    color: #333333;

    img {
      vertical-align: middle;
      margin-right: 12px;
    }
  }
}

.search-list {
  .quote-select {

    padding: 12px;

    .quote-name {
      font-size: 14px;
    }

    .quoteselect-date {
      margin-top: 3px;
    }
  }
}

.opportunity-bulk-action {
  .table-responsive table {
    min-width: 1200px;
  }
}

.opportunity-sidebar {
  padding: 0;

  .close-div {
    background-color: #595959ff;
    padding: 18px;

    md-icon {
      margin-right: 10px !important;

      svg {
        max-width: 50px;
        height: 14px;
        width: 100%;
      }
    }

    p {
      margin: 0 0 5px;
      color: variables.$white;
    }

    h2 {
      margin: 0;
      color: variables.$white;
      font-size: 18px;
    }
  }

  .profile-detail-section {
    ul {
      padding-left: 15px;
      width: 100%;

      .owner-detail {
        h3 {
          margin: 0;
          font-size: 18px;
          font-weight: 500;
        }

        p {
          color: #999999;
        }
      }

      .mb-30 {
        margin-bottom: 30px !important;
      }
    }
  }

  .status-deal-section {
    padding: 0;

    .status-deal-div {
      background-color: #000000;
      padding: 0 10px;

      md-icon {
        margin-right: 15px;

        svg {
          path {
            fill: variables.$white;
          }
        }
      }

      h2 {
        color: variables.$white;
        font-size: 18px;
      }
    }

    ul {
      width: 100%;
      padding: 0;

      li {
        margin-bottom: 10px;
        padding: 15px 10px;
        cursor: pointer;
      }

      .won {
        background-color: #eaf0d6!important;
      }

      .postponed {
        background-color: #fff2cd!important;
      }

      .lost {
        background-color: #fae1dc!important;
      }

      .pending {
        background-color: #d9d2e9!important;
      }

      .no-outcome {
        background-color: #f6f6f6!important;
      }

      .bg-dark-grey {
        background-color: #d9d9d9!important;
      }

      .background-circle {
        background-color: #fff;
        border-radius: 50px;
        height: 46px;
        width: 46px;
        margin-right: 15px;
        position: relative;

        .icon_png_img {
          width: 30px;
          height: 30px;
          position: relative;
          top: 8px;
          left: 8px;
          display: none;
        }
      }

      .active {
        border: 1px solid #fe9603!important;

        .icon_png_img {
          display: inline-block;
        }
      }

      .owner-detail {
        h3 {
          margin: 0;
          font-size: 18px;
          font-weight: 500;

          // span {
          //   font-size: 11px;
          // }
        }

        p {
          color: #999999;
        }

        .arrow-icon {
          margin: 0;
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}
