@use "../../settings/third-party/mixins";
@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.inventory-popup-section {
  height: 540px;
  width: 800px;

  md-dialog-content {
    padding: 18px;
    position: relative;
  }

  .main-header {
    h2 {
      font-size: 16px;
    }
  }

  .md-sidenav-right.inside-container {
    bottom: 0;
  }

  .md-button.md-icon-button {
    &.add-btn {
      margin: 0 8px 0 0;
      height: auto;
      min-height: auto;
      padding: 0;
      width: auto;

      md-icon {
        height: 21px;
        width: 21px;

        svg path {
          fill: variables.$brand-primary;
        }
      }
    }
  }

  .data-table {
    min-width: 745px;

    td {
      @extend .f3;

      .cursor-lbl {
        @extend .f8;
        cursor: pointer;
      }
    }
  }

  .price-check-section {
    .section-title {
      @extend .fm;
      color: variables.$gray-dark;
      margin-top: 0;
      margin-bottom: 15px;
    }

    md-input-container {
      margin-bottom: 3px;
    }

    .detail-section {
      margin-top: 15px;

      .bottom-gap {
        margin-bottom: 10px;
      }
    }

    .btn-check {
      height: 30px;
      line-height: 30px;
      margin-top: 0;
      margin-left: 0;
      min-width: 55px;
      min-height: 30px;
    }
  }

  .default-img {
    @include mixins.border-radius(3px);
    border: 1px solid variables.$border-color;
    margin-bottom: 10px;
    width: 150px;

    md-icon {
      height: 36px;
      margin-top: 30px;
      margin-bottom: 35px;
      width: 36px;
    }
  }

  .item-detail-section {
    position: relative;

    .bottom-gap {
      margin-bottom: 12px;
    }

    .item-detail {
      * {
        margin-bottom: 8px;
      }
    }
  }

  .filter-section {
    padding-top: 12px;
    padding-right: 0;
    padding-left: 0;
  }

  .summery-widget {
    .top-section {
      margin-bottom: 8px;

      .icon-info {
        height: 20px;
        margin-right: 3px;
        width: 20px;
      }
    }
  }

  .item-globalSetting-section {
    .md-button.add-btn {
      margin-bottom: 15px;
    }
  }
}

.attached-services-section {
  .form-base {
    md-input-container {
      margin-bottom: 3px;

      textarea {
        height: 60px !important;
      }
    }
  }

  md-checkbox {
    margin-top: 17px;
    margin-bottom: 6px;
  }

  .switch-bar {
    margin-top: 24px;
    margin-bottom: 10px;

    label {
      color: variables.$gray-darker;
      margin-right: 15px;
    }
  }

  .item-chips {
    margin-top: 6px;

    li {
      margin-right: 20px;
      margin-bottom: 10px;

      span {
        margin-right: 10px;
      }
    }
  }

  .note-text {
    font-style: italic;
  }
}

.sell-target-popup {
  .sell-setting-list {
    @extend .no-style;
    margin-bottom: 25px;

    .heading-section {
      margin-bottom: 10px;
    }

    .sell-target-name {
      @extend .f3;
      margin-top: 12px;
      margin-bottom: 20px;
    }
  }
}

.upload-image-section {
  .summery-widget {
    h1 {
      margin-top: 0;
      margin-bottom: 12px;
    }

    .guidelines {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  .item-image-list {
    @include mixins.border-radius(3px);
    border: 1px solid variables.$border-color;
    margin-top: 10px;

    li {
      min-height: 85px;
      padding: 10px 15px;

      &:not(:last-child) {
        border-bottom: 1px solid variables.$white-darker;
      }
    }

    .product-img-box {
      margin-right: 30px;
    }

    .img-descp,
    md-checkbox {
      margin-bottom: 10px;
    }

    .img-descp {
      @extend .f4;

      span {
        color: variables.$gray-darker;
      }
    }
  }
}

.tiered-priceSetup-section {
  .form-base {
    .data-table {
      input[type="text"] {
        color: variables.$gray;
      }
    }
  }

  .rb-attribute {
    md-radio-button {
      margin-top: 8px;
      margin-right: 6px;
    }
  }

  .ico-tick {
    margin-right: 8px;

    svg path:first-child {
      fill: transparent;
    }
  }

  .switch-bar {
    margin-top: 5px;
    margin-bottom: 10px;

    label {
      margin-right: 12px;
    }
  }

  .data-table {
    margin-bottom: 10px;

    td {
      vertical-align: middle;

      .icon-edit {
        height: 11px;
        margin-top: 4px;
        width: 11px;
      }

      &.row-remove-cell {
        width: 220px;
      }

      md-input-container {
        margin-bottom: 0;
      }
    }
  }

  .price-check-section .section-title {
    margin-bottom: 0;
  }
}

.child-category-section {
  .data-table {
    td {
      verticaalign: middle;

      &.img-cell {
        width: 70px;
      }
    }
  }
}

.remove-catalog-section {
  .form-base {
    md-radio-group {
      margin-top: 25px;

      md-radio-button {
        font-size: variables.$font-size-small;
        margin-bottom: 22px;
      }
    }
  }

  .summery-widget {
    line-height: 21px;

    p {
      margin-top: 8px;
      margin-bottom: 0;

      &:last-child {
        margin-top: 20px;
      }
    }

    .information {
      margin-left: 15px;

      li {
        list-style-type: disc;
      }
    }
  }

  .inner-section {
    margin-top: -7px;
    padding-left: 28px;
  }
}

.bulk-upload-section,
.tiered-priceImage-section {
  // .product-img-list {
  //     @extend .no-style;
  //     li {
  //         margin-bottom: 25px;
  //         text-align: center;
  //         vertical-align: top;
  //     }
  //     .product-img-box {
  //         @include border-radius(4px);
  //         border: 1px solid $border-color;
  //         box-sizing: border-box;
  //         max-width: 100%;
  //         height: 150px;
  //         position: relative;
  //         width: 95%;
  //     }
  //     .holder {
  //         position: absolute;
  //         top: 50%;
  //         left: 50%;
  //         -webkit-transform: translate(-50%, -50%);
  //         transform: translate(-50%, -50%);
  //     }
  //     .product-no-img {
  //         height: 45px;
  //     }
  //     .product-img {
  //         max-width: 85px;
  //         min-width: 75px;
  //     }
  //     .product-lbl {
  //         color: $gray-dark;
  //         cursor: pointer;
  //         font-size: 10px;
  //         margin-top: 8px;
  //         &.add-lbl {
  //             @extend .f8;
  //         }
  //     }
  // }
  // .img-options {
  //     position: absolute;
  //     top: -15px;
  //     right: -14px;
  //     .md-icon-button {
  //         &,
  //         &:hover {
  //             background: $white;
  //         }
  //         border: 1px solid $gray-light;
  //         padding: 0;
  //         height: 28px;
  //         margin-right: 0;
  //         min-height: 28px;
  //         width: 28px;
  //     }
  //     md-icon {
  //         height: 12px;
  //         width: 12px;
  //     }
  // }
}

.bulk-upload-section {
  .form-base md-radio-button {
    display: block;
    margin-bottom: 22px;
  }

  .product-img-box {
    cursor: pointer;

    &.retry-bg {
      background: variables.$white-dark;
      border: none;
    }

    .icon-refresh {
      height: 28px;
      -webkit-transform: rotate(50deg);
      transform: rotate(50deg);

      svg path {
        fill: variables.$gray-light;
      }
    }
  }

  .upload-bar-section {
    margin-bottom: 20px;

    .upload-track {
      @include mixins.border-radius(10px);
      background: variables.$white-dark;
      height: 10px;
      overflow: hidden;

      .uploader {
        background: variables.$brand-accent;
        height: 100%;
      }
    }

    .upload-lbl {
      color: variables.$gray-dark;
      font-size: 10px;
      margin-top: 6px;
    }
  }

  .upload-options {
    padding-top: 65px;

    .descp {
      @extend .f1;
      margin-bottom: 25px;
    }
  }

  .multiple-image-section {
    margin: 14px auto 0;

    .title {
      @extend .f1;
      margin-bottom: 15px;
    }

    .single-img-box {
      @include mixins.border-radius(4px);
      border: 1px solid variables.$border-color;
      margin-bottom: 20px;
      position: relative;
    }

    .img-options {
      top: 10px;
      right: 10px;
    }
  }

  .image-thumbnails {
    .left-arrow {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }

    .arrow {
      @include mixins.border-radius(50%);
      border: 1px solid variables.$gray-light;
      height: 14px;
      width: 14px;
      padding: 6px;

      svg path {
        fill: variables.$gray-light;
      }
    }

    ul {
      margin-right: 15px;
      margin-left: 15px;
      overflow: hidden;
      white-space: nowrap;

      &.flex-80 {
        flex: 1 1 85%;
        max-width: 85%;
      }

      li {
        display: inline-block;
        height: 60px;
        width: 80px;

        &.active {
          border: 1px solid variables.$gray-light;
        }

        img {
          max-height: 60px;
          width: 100%;
        }

        &:not(:last-child) {
          margin-right: 15px;
        }
      }
    }
  }
}

.filter-section {
  .form-base md-input-container.md-input-has-value md-select md-select-value span {
    color: variables.$gray;
  }

  .gap {
    padding: 20px 18px 0;
  }

  md-switch {
    &.md-block {
      margin-left: 25px;
      margin-right: 20px;
      top: 8px;
    }
  }

  .filter-msg {
    margin-top: -12px;
  }

  .filters-bar {
    background: variables.$white !important;
    padding: 0;

    .close-icon,
    .filter-btns {
      display: none;
    }

    md-datepicker {
      .md-datepicker-input {
        font-size: 12px;
      }

      .md-datepicker-button {
        &,
        &:hover {
          background: variables.$white;
        }
      }
    }
  }

  .md-button.add-btn {
    margin-left: -6px;
    margin-right: 0;
  }
}

.instruction-category-section {
  .form-base {
    md-input-container {
      input,
      label {
        @extend .f4;
      }
    }
  }
}

.item-globalSetting-section {
  .form-base md-input-container {
    width: 100%;
  }

  .add-btn {
    margin-bottom: 15px;
  }
}

.setting-list {
  @extend .no-style;

  li {
    &:first-child {
      margin-bottom: 12px;
    }
  }
}
@media only screen and (min-width: 600px) {
  .inventory-popup-section {
    .intro-cb {
      margin-top: 12px;
    }

    .img-upload-section {
      margin-right: 40px;
    }

    .input-right-gap {
      margin-right: 20px;
    }

    .instruction-category-section {
      padding: 50px 55px;
    }
  }

  .sell-target-popup {
    md-dialog-content {
      padding-left: 60px;
      padding-top: 30px;
    }

    .sell-setting-list {
      md-input-container {
        & label:not(.md-no-float):not(.md-container-ignore) {
          display: none;
        }
      }
    }
  }

  .setting-list li:not(:first-child) {
    label {
      margin-top: -14px;
    }
  }

  .attached-services-section {
    .form-base {
      input,
      md-select {
        width: 220px;
      }

      textarea {
        width: 370px;
      }
    }

    .md-button.md-icon-button.add-btn {
      margin-left: 15px;
    }
  }

  .upload-image-section {
    .summery-widget {
      margin-top: -4px;
    }
  }

  .remove-catalog-section {
    .summery-widget {
      box-sizing: border-box;
      width: 258px;
    }
  }

  .tiered-priceImage-section {
    .product-img-list {
      margin-top: 40px;
      margin-left: 50px;
    }
  }

  .bulk-upload-section,
  .tiered-priceImage-section {
    .product-img-list {
      li {
        // display: inline-block;
        // margin-right: 28px;
        // .product-img-box {
        //     width: 150px;
        // }
      }
    }
  }

  .bulk-upload-section {
    .multiple-image-section {
      width: 80%;
    }
  }
}
@media only screen and (max-width: 600px) {
  .inventory-popup-section {
    md-toolbar.main-header .md-button.btn-primary {
      min-width: 45px;
    }

    .img-upload-section {
      margin-bottom: 20px;
    }

    .price-check-section {
      .btn-check {
        margin-bottom: 15px;
      }
    }
  }

  .attached-services-section {
    .md-button.md-icon-button.add-btn {
      margin-top: 15px;
      margin-bottom: 5px;
    }
  }

  .setting-list {
    li:first-child {
      display: none;
    }

    label {
      margin-bottom: 10px;
    }
  }

  .upload-image-section {
    .item-image-list {
      .product-lbl {
        margin-bottom: 10px;
      }
    }
  }
  // .addPackage-section {
  //     .table-responsive {
  //         margin-top: 20px;
  //     }
  // }
}
// .addPackage-section {
//     .package-search-section {
//         md-input-container {
//             margin-right: 20px;
//         }
//     }
// }
// .bundledProduct-setup-section {
//     .update-input-section {
//         md-input-container {
//             input {
//                 width: 250px;
//             }
//         }
//     }
//     .price-check-section {
//         margin-top: -8px;
//         .right-gap {
//             margin-right: 20px;
//         }
//         .detail-section {
//             .price-detail {
//                 text-align: right;
//             }
//         }
//     }
// }
// .addPackage-section {
//     .package-search-section {
//         margin-top: 5px;
//         .search-package-btn {
//             height: 30px;
//             margin-top: 8px;
//             margin-left: 0;
//             min-height: 30px;
//             min-width: 80px;
//             line-height: 32px;
//         }
//     }
//     .data-table {
//         td {
//             vertical-align: middle;
//             &.add-btn-section {
//                 width: 32px;
//                 .md-button.md-icon-button {
//                     padding: 0;
//                     margin: 0;
//                     height: 30px;
//                     min-height: 30px;
//                     width: 30px;
//                 }
//             }
//             &.row-view-section {
//                 width: 80px;
//             }
//             &.item-active {
//                 color: $brand-success-dark;
//             }
//             &.item-draft {
//                 color: $brand-accent;
//             }
//             &.item-invisible {
//                 color: $gray;
//             }
//         }
//     }
// }
// .bundledProduct-setup-section {
//     .form-base {
//        md-radio-group {
//             md-radio-button {
//                 margin-right: 15px;
//                 margin-bottom: 0;
//             }
//             .md-off {
//                 background: $white;
//             }
//             .md-label {
//                 color: $gray-darker;
//                 font-size: $font-size-small;
//             }
//        }
//     }
//     .update-input-section {
//         .info-lbl {
//             color: $gray;
//             font-size: 11px;
//             position: relative;
//             top: -14px;
//         }
//     }
//     .data-table {
//         .product-lbl {
//             color: $gray-dark;
//         }
//         td {
//             &.other-options {
//                 width: 330px;
//             }
//         }
//     }
// }
