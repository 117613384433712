// .product-list-section {
//   .product-img-box {
//     min-width: 160px;
//   }
// }

.product-list-sidebar {
  md-icon {
    &.set {
      height: 12px;
      width : 12px;
    }
    z-index: 1;
  }
}
