@use "../../settings/third-party/variables";

.add-customer-ns {
  .summery-widget {
    padding: 0;

    @media(max-width: 959px) {
      min-width: auto;
    }
  }

  .heading_title {
    padding: 20px;
    font-weight: 600;
    background-color: #20394cff;
  }

  .icon-next-arrow {
    width: 15px;
    height: 15px;

    svg {
      path {
        fill: variables.$brand-primary;
      }
    }
  }

  .content_div {
    padding: 20px;
    background: #f2f6f9;
  }

  h3 {
    margin-bottom: 15px;
    font-weight: 500;
    color: #6a6a6a;
  }

  .icon_png {
    width: 30px;
  }

  .title {
    color: #5cc8fc;
  }

  .type-box {
    padding: 12px 16px;
    color: variables.$brand-primary;
    cursor: pointer;
    background: variables.$white;
    margin-bottom: 15px;
    gap: 10px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);

    .second_content {
      font-size: 16px;
    }

    &.disabled {
      color: variables.$gray;
      pointer-events: none;

      md-icon svg path {
        fill: variables.$gray;
      }
    }
  }
}
