@use "../../settings/third-party/variables";

.export-data {
  .table-responsive {
    padding: 15px;
    // width: 100%;

    .td-description-text {
      color: #6a6a6a;
      font-size: 14px;
    }

    .data-table {
      // background-color: $white;
      // position: absolute;
      // margin-left: 20px;
      // width: 80%;
      // z-index: 0;
      overflow: auto;

      thead {
        background: #20394cff;

        th {
          color: variables.$white;
        }
      }

      .hexa-icon {
        position: absolute;
        right: 30px;
        width: 30px;
        height: 30px;
        top: -5px;
        left: -40px;
        margin: 0;
      }
    }

    .table-description {
      margin-left: 10px;
      bottom: 0;
      position: relative;
    }

    .owner-detail {
      // .profile-pic {
      //   width: 40px;
      //   height: 40px;
      //   margin-right: 10px;
      // }

      // .status-capsule {
      //   background-color: transparent;
      //   border: 1px solid #b7b7b7;
      //   max-width: 75px;
      //   width: 100%;
      //   font-weight: 500;
      //   font-size: 12px;
      //   text-align: center;
      //   line-height: 1.4;
      //   border-radius: 20px;
      //   padding: 3px 0 1px;
      //   height: 18px;
      //   box-sizing: content-box;
      //   color: #980000;
      //   display: inline-block;
      // }
    }

    // .location-symbol {
    //   padding: 0 12px !important;
    //   min-width: 35px;
    //   text-align: center;
    // }

    .save-contacts {
      background-color: variables.$brand-primary;
      color: variables.$white;
      border: 1px solid variables.$brand-primary;

      md-icon {
        svg {
          path {
            fill: variables.$white;
          }
        }
      }
    }

    .td-description-text {
      margin: 5px auto;
    }
  }

  .md-sidenav-right {
    max-width: 345px;
    width: 100%;

    .heading {
      padding: 21px 15px;
      background-color: black;
      color: variables.$white;
      text-align: center;
      font-size: 19px;
    }

    .list-data {
      padding: 15px;
      border-bottom: 1px solid #d6e1e5;

      .td-description-text {
        color: #6a6a6a;
      }

      // .profile-pic {
      //   width: 30px;
      //   height: 30px;
      //   margin-right: 15px;
      // }

      .export-cta-div {
        // margin-left: 35px;

        .data-setup-cta {
          background-color: variables.$white;
          color: variables.$brand-primary;
          border: 1px solid variables.$brand-primary;
          border-radius: 50px;
          min-width: 114px;
        }

        .na-cta,
        .remove-cta {
          color: variables.$white;
          margin-right: 0;
        }

        .active-cta {
          margin-left: 0;
        }

        .remove-cta {
          background-color: #f45e60;
          border: 1px solid #f45e60;
        }

        .download-cta {
          background-color: variables.$brand-primary;
          margin-right: 0;
          border: 1px solid variables.$brand-primary;

          a {
            color: variables.$white;
          }
        }

        .na-cta {
          background-color: #cccccc;
          border: 1px solid #cccccc;
        }
      }

      .download-div {
        // padding-left: 45px;
        color: #6a6a6a;
      }

    }

    .list-data:hover {
      background-color: rgba(222, 222, 222, 0.2);
    }
    // .bottom-section {
    //     box-shadow: $whitefff 0px 2px 0px 2px;
    //     background-color: #f2f2f2;
    // }
    .monthly {
      color: #f55e61!important;
    }

    .weekly {
      color: #6aa84f!important;
    }
  }
}
