@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.assign-list-section {
  padding: 20px;

  .common-tabs:not(.no-style) {
    margin-top: 30px;
  }

  .popupleft-nav {
    background: variables.$white;
    top: 0;
  }

  .tabs-container {
    position: relative;

    .date-filter {
      top: 3px;

      md-icon {
        margin: 3px 10px;
      }
    }

    .total-tasks {
      @extend .f1;

      position: absolute;
      left: 0;
      top: -1px;
      z-index: 20;

      .task-count {
        padding-bottom: 5px;
      }

      md-select {
        margin: 0 0 0 15px;
        min-width: 120px;
      }
    }
  }
}

.table-responsive {
  .assign-table {
    min-width: 0;

    .overlap-time {
      label {
        display: inline-block;
        border-radius: 20px;
        padding: 4px 6px;
        line-height: 1;
        background: variables.$white-darker;
        color: variables.$gray-dark;
        border: 1px solid variables.$gray-light;
        font-size: 10px;
        min-width: 36px;
      }

      .text-danger {
        font-size: 10px;
      }
    }

    .sub-th {
      font-size: 9px;
    }

    .resorce-type {
      font-size: 11px;
      color: variables.$gray;
    }

    .more-res {
      font-size: 11px;
    }

    .services-no {
      position: relative;
      display: inline-block;
      padding-left: 20px;

      a {
        font-size: variables.$font-size-small;
      }

      .ico-table {
        position: absolute;
        height: 14px;
        width: 14px;
        left: 0;
        top: 3px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .assign-list-section .tabs-container .date-filter {
    top: 38px;
  }

  .assign-list-section md-tabs.md-dynamic-height md-tabs-content-wrapper {
    margin-top: 25px;
  }

  .assign-list-section .tabs-container .total-tasks {
    top: 35px;
  }
}

@media screen and (max-width: 600px) {
  .assign-card {
    width: auto;

    li {
      padding: 10px 0;
    }

    .card-actions {
      text-align: left;
    }
  }

  .assign-list-section md-tabs.md-dynamic-height md-tabs-content-wrapper {
    margin-top: 60px;
  }

  .assign-list-section .tabs-container .total-tasks {
    top: 70px;
  }
}
