.bundle-productDetail-section {
	.custom-tabs {
		margin-top: 15px;

		.icon-tick {
			height: 16px;
			margin-right: 5px;
			width: 16px;
		}

		md-content {
			padding-top: 20px;
		}
	}

	.specification-list {
		label {
			padding-right: 0;
		}
	}
}