.category-container {
  padding: 20px;
  .icon-category {
    text-align: right;
    padding-right: 37px;
    md-icon {
      height: 16px;
    width: 16px;
    }
  }

}
