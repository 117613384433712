@use "../../settings/third-party/variables";

.closing-po {

  padding: 20px;

  .icon-tick {
    margin: 0;
  }

  .congrats-po {
    font-size: variables.$font-size-medium;
    font-weight: 700;
  }

  .completed {
    color: variables.$brand-success;
    // font-size: $font-size-small;
  }

  .itemnew-list {
    margin-top: 2px;
    margin-left: 9px;
  }

  .unclosed-po {
    color: variables.$status-info;
    margin: 20px 0 10px;
    font-size: variables.$font-size-base;
    font-weight: 700;
  }

  .note-cancel {
    margin: 20px 0;
    font-size: variables.$font-size-base;
  }

  .continue-close {
    margin: 20px 0;
    font-size: variables.$font-size-medium;
    font-weight: 800;
  }

  .cancle-close {
    text-align: center;
    margin-top: 15px;
  }

  .cancel-btn {
    background: variables.$gray-lighter;
  }
}

.archiving-supplier {
  padding: 20px;

  .info {
    font-size: variables.$font-size-base;
    line-height: 22px;
  }

  .items-count {
    margin: 20px 0;
  }

  .icon-tick {
    margin: 0;
  }

  .items {
    display: inline-block;
    width: 200px;
    font-size: variables.$font-size-base;
  }

  .item-list {
    margin-top: 20px;
  }

  .item-name {
    // width: 150px;
    font-size: variables.$font-size-small;
    font-weight: 800;
    margin-top: 3px;
    // max-width: 150px;
    // display: inline-block;
  }

  .dependency-list {
    margin-left: 15px;
  }

  .itemcount {
    font-size: variables.$font-size-base;
    margin: 1px 16px;
    width: 30px;
  }
}

.disallow-po {
  .overdue {
    color: variables.$brand-danger;
  }

  .partially-received {
    color: variables.$status-info;
  }

  .count-po {
    border: 1px solid variables.$gray-light;
    padding: 7px;
    border-radius: 6px;
  }
}
