@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

/*Popups Css Start*/
.customer-popup {
  md-toolbar.main-header {
    height: 72px;

    h2 {
      font-size: 16px;
      font-weight: normal;
    }

    .md-button {
      width: 70px;
      min-width: 70px;
    }

    .close-popup {
      min-width: 0;
      // width: 30px;

      md-icon {
        // height: 18px;
        // width: 18px;

        svg {
          path {
            fill: variables.$white-dark;
          }
        }
      }
    }

    .md-toolbar-tools {
      height: 72px;
      min-height: 72px;
    }
  }

  &.disable-popup {
    min-height: 100px;
    width: 700px;
    height: 300px;

    md-dialog-content {
      padding: 30px 50px;

      span {
        line-height: 50px;
        padding: 10px 20px;
      }
    }
  }

  &.conversation-popup {
    min-height: 500px;
    width: 800px;

    md-dialog-content {
      padding: 15px;
    }

    md-icon {
      height: 18px;
      width: 15px;
      margin: 0 5px 0 0;
    }

    .block-title {
      font-weight: 500;
      margin: 0 0 21px;

      md-icon {
        width: 25px;
        height: 28px;
      }
    }

    .links {
      label {
        font-size: 10px;
        color: variables.$gray;
      }
    }

    md-input-container {
      margin-bottom: 18px;
      max-width: 100%;

      label {
        padding-left: 0;
      }

      textarea {
        height: 90px;
        min-height: 92px;
      }
    }

    .chip {
      display: inline-block;
      position: relative;
      width: 109px;
      height: 86px;
      text-align: center;
      background: variables.$white;
      border: 1px solid variables.$white-darker;
      border-radius: 3px;
      box-shadow: 2px 2px 0 variables.$white-darker;
      margin: 0 10px 10px 0;

      .md-button {
        position: absolute;
        right: -16px;
        top: -9px;

        md-icon {
          width: 10px;
          height: 10px;
        }
      }

      md-icon {
        width: 31px;
        height: auto;
      }
    }
  }
}

.image-slider-popup {
  min-height: 500px;
  width: 800px;

  md-dialog-content {
    padding: 25px 20px;
  }

  .arrow-holder {
    width: 60px;

    .arrow-btn {
      height: auto;
      margin: 0;
      min-height: auto;
      width: auto;

      md-icon {
        height: 35px;
        width: 35px;

        &.right-arrow {
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
    }
  }

  .slider-container {
    padding-right: 10px;
    padding-left: 10px;

    .img-container {
      padding: 16px;

      img {
        max-height: 380px;
        width: 100%;
      }
    }
  }

  .thumbnail-holder {
    @extend .no-style;
    margin: 10px 60px 0;
    overflow: hidden;
    white-space: nowrap;

    li {
      box-sizing: border-box;
      display: inline-block;
      margin-right: 10px;
      padding: 5px;
      width: 115px;

      &.selected {
        border: 1px solid variables.$gray-light;
      }

      img {
        max-height: 85px;
        width: 100%;
      }
    }
  }
}
/* Smart phone ----------- */
@media only screen and (min-width: 600px) {
  .conversation-popup md-input-container {
    width: 75%;
  }
}
@media only screen and (max-width: 600px) {
  .customer-popup .manage-board {
    padding: 18px;
  }
}
