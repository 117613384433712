@use "../settings/third-party/variables";

.search-toolbar {
  position: fixed;
  right: 0;
  top: 0;
  display: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  z-index: 100;

  md-autocomplete {
    height: 64px;

    md-autocomplete-wrap {
      height: 64px;

      input {
        height: 64px !important;
        padding-left: 40px !important;
      }
    }
  }

  // styles for search toolbar in sidenav
  &.sidenav {
    md-content {
      padding: 0;
    }
  }

  .close-search {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 40px;
    z-index: 100;
    cursor: pointer;
  }

  .icon-cross {
    width: 15px;
  }

  .icon-cross path {
    fill: #aaacb0;
  }

}

.autocomplete-search {
  li {
    border-left: 3px solid variables.$white;
    padding: 0 15px 0 12px;

    &:hover {
      border-color: variables.$brand-accent;
    }
  }

  .search-history-icon {
    margin-right: 20px;
  }
}
