@use 'data';
@use "../../settings/third-party/variables";

.maintenance-section {
	.custom-tabs {
		md-content {
			padding-top: 25px;
		}
	}

	.datepicker-custom-new {
		.md-datepicker-input-container {
			input {
				max-width: 100%;
			}
		}
	}

	.danger {
		color: variables.$brand-danger;
	}

	.equipment-title {
		color: variables.$gray-dark;
		font-size: 16px;
		font-weight: normal;
		margin-top: 0;
		margin-bottom: 30px;
	}

	.equp-test-input {
		margin-right: 18px;
		margin-bottom: 30px;
	}

	.test-btn {
		margin-top: 0;
		margin-left: 0;
	}

	.upgrade-btn {
		min-width: 125px;
	}

	.btn-danger {
		background-color: variables.$brand-danger;
		color: variables.$white;
		min-height: 36px;
		min-width: 88px;
		line-height: 36px;
		vertical-align: middle;
		border-radius: 2px;
	}

	.btn-info {
		background-color: variables.$gray-light;
		color: variables.$gray-dark;
		min-height: 36px;
		min-width: 88px;
		line-height: 36px;
		vertical-align: middle;
		border-radius: 2px;
	}

	.login-info {
		span {
			padding: -5px 0;
		}
	}

	.email-info {
		md-icon {
			margin: 2px 0;
		}
		margin-top: 18px;
	}

	.icon-clock,
	.icon-info {
		margin-right: 8px;
	}

	.icon-clock {
		height: 24px;
	}
}
