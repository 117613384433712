@use "../settings/third-party/variables";

.visual {
  background: url("#{variables.$image-path}/sprites/visuals.png") no-repeat top left;
  display: block;
  height: 64px;
  width: 64px;

  &.airplane {
    background-position: 0 0;
  }

  &.alarm-clock {
    background-position: -64px 0;
  }

  &.alerts {
    background-position: -128px 0;
  }

  &.analytics-1 {
    background-position: -192px 0;
  }

  &.analytics-2 {
    background-position: -256px 0;
  }

  &.angry {
    background-position: -320px 0;
  }

  &.angry-1 {
    background-position: -384px 0;
  }

  &.architect {
    background-position: -448px 0;
  }

  &.award {
    background-position: -512px 0;
  }

  &.award-01 {
    background-position: -576px 0;
  }

  &.balance {
    background-position: -640px 0;
  }

  &.banner {
    background-position: -704px 0;
  }

  &.bar-chart {
    background-position: 0 -64px;
  }

  &.bars {
    background-position: -64px -64px;
  }

  &.bolt {
    background-position: -128px -64px;
  }

  &.bored-1 {
    background-position: -192px -64px;
  }

  &.boss {
    background-position: -256px -64px;
  }

  &.bow-tie {
    background-position: -320px -64px;
  }

  &.box {
    background-position: -384px -64px;
  }

  &.briefcase-1 {
    background-position: -448px -64px;
  }

  &.briefcase-6 {
    background-position: -512px -64px;
  }

  &.browser {
    background-position: -576px -64px;
  }

  &.bubble {
    background-position: -640px -64px;
  }

  &.bulb {
    background-position: -704px -64px;
  }

  &.businessman {
    background-position: 0 -128px;
  }

  &.businessman-1 {
    background-position: -64px -128px;
  }

  &.businessman-4 {
    background-position: -128px -128px;
  }

  &.calculator {
    background-position: -192px -128px;
  }

  &.calendar {
    background-position: -256px -128px;
  }

  &.chat {
    background-position: -320px -128px;
  }

  &.chess {
    background-position: -384px -128px;
  }

  &.chevron-13 {
    background-position: -448px -128px;
  }

  &.chevron-14 {
    background-position: -512px -128px;
  }

  &.chevron-15 {
    background-position: -576px -128px;
  }

  &.chevron-16 {
    background-position: -640px -128px;
  }

  &.chevron-17 {
    background-position: -704px -128px;
  }

  &.chevron-18 {
    background-position: 0 -192px;
  }

  &.chevron-3 {
    background-position: -64px -192px;
  }

  &.chevron-4 {
    background-position: -128px -192px;
  }

  &.chevron-5 {
    background-position: -192px -192px;
  }

  &.chevron-6 {
    background-position: -256px -192px;
  }

  &.chevron-7 {
    background-position: -320px -192px;
  }

  &.chevron-8 {
    background-position: -384px -192px;
  }

  &.chevron-9 {
    background-position: -448px -192px;
  }

  &.circle-dark-grey {
    background-position: -512px -192px;
  }

  &.circle-green {
    background-position: -576px -192px;
  }

  &.circle-light-grey {
    background-position: -640px -192px;
  }

  &.circle-light-red {
    background-position: -704px -192px;
  }

  &.circle-pink {
    background-position: 0 -256px;
  }

  &.circle-purple {
    background-position: -64px -256px;
  }

  &.circle-red {
    background-position: -128px -256px;
  }

  &.circle-yellow {
    background-position: -192px -256px;
  }

  &.clipboard-1 {
    background-position: -256px -256px;
  }

  &.clipboard-2 {
    background-position: -320px -256px;
  }

  &.clipboard-6 {
    background-position: -384px -256px;
  }

  &.conference-1 {
    background-position: -448px -256px;
  }

  &.conference-2 {
    background-position: -512px -256px;
  }

  &.confetti {
    background-position: -576px -256px;
  }

  &.confused {
    background-position: -640px -256px;
  }

  &.crying {
    background-position: -704px -256px;
  }

  &.crying-1 {
    background-position: 0 -320px;
  }

  &.dialog {
    background-position: -64px -320px;
  }

  &.diamond {
    background-position: -128px -320px;
  }

  &.diamond-1 {
    background-position: -192px -320px;
  }

  &.diploma {
    background-position: -256px -320px;
  }

  &.document-2 {
    background-position: -320px -320px;
  }

  &.e-learning {
    background-position: -384px -320px;
  }

  &.embarrassed {
    background-position: -448px -320px;
  }

  &.emoticons {
    background-position: -512px -320px;
  }

  &.exchange-3 {
    background-position: -576px -320px;
  }

  &.filing-cabinet {
    background-position: -640px -320px;
  }

  &.flag-02 {
    background-position: -704px -320px;
  }

  &.flag-03 {
    background-position: 0 -384px;
  }

  &.flame {
    background-position: -64px -384px;
  }

  &.folder {
    background-position: -128px -384px;
  }

  &.forbidden {
    background-position: -192px -384px;
  }

  &.handshake {
    background-position: -256px -384px;
  }

  &.happy {
    background-position: -320px -384px;
  }

  &.happy-1 {
    background-position: -384px -384px;
  }

  &.happy-2 {
    background-position: -448px -384px;
  }

  &.happy-4 {
    background-position: -512px -384px;
  }

  &.heart-dark-grey {
    background-position: -576px -384px;
  }

  &.heart-green {
    background-position: -640px -384px;
  }

  &.heart-light-grey {
    background-position: -704px -384px;
  }

  &.heart-light-red {
    background-position: 0 -448px;
  }

  &.heart-pink {
    background-position: -64px -448px;
  }

  &.heart-purple {
    background-position: -128px -448px;
  }

  &.heart-red {
    background-position: -192px -448px;
  }

  &.heart-yellow {
    background-position: -256px -448px;
  }

  &.hierarchical-structure {
    background-position: -320px -448px;
  }

  &.hold {
    background-position: -384px -448px;
  }

  &.hourglass {
    background-position: -448px -448px;
  }

  &.ill {
    background-position: -512px -448px;
  }

  &.in-love {
    background-position: -576px -448px;
  }

  &.infographic {
    background-position: -640px -448px;
  }

  &.kissing {
    background-position: -704px -448px;
  }

  &.knight {
    background-position: 0 -512px;
  }

  &.line-chart-1 {
    background-position: -64px -512px;
  }

  &.loss-4 {
    background-position: -128px -512px;
  }

  &.loupe {
    background-position: -192px -512px;
  }

  &.mad {
    background-position: -256px -512px;
  }

  &.medal-01 {
    background-position: -320px -512px;
  }

  &.medal-05 {
    background-position: -384px -512px;
  }

  &.money-bag {
    background-position: -448px -512px;
  }

  &.nerd {
    background-position: -512px -512px;
  }

  &.network {
    background-position: -576px -512px;
  }

  &.networking {
    background-position: -640px -512px;
  }

  &.networking-1 {
    background-position: -704px -512px;
  }

  &.payment-1 {
    background-position: 0 -576px;
  }

  &.pie-chart-1 {
    background-position: -64px -576px;
  }

  &.podium-02 {
    background-position: -128px -576px;
  }

  &.podium-03 {
    background-position: -192px -576px;
  }

  &.profits-3 {
    background-position: -256px -576px;
  }

  &.quiet {
    background-position: -320px -576px;
  }

  &.sad {
    background-position: -384px -576px;
  }

  &.sales {
    background-position: -448px -576px;
  }

  &.sand-clock {
    background-position: -512px -576px;
  }

  &.settings {
    background-position: -576px -576px;
  }

  &.shopping-bag {
    background-position: -640px -576px;
  }

  &.smart {
    background-position: -704px -576px;
  }

  &.smartphone-2 {
    background-position: 0 -640px;
  }

  &.smartphone-3 {
    background-position: -64px -640px;
  }

  &.smile {
    background-position: -128px -640px;
  }

  &.smiling {
    background-position: -192px -640px;
  }

  &.star-dark-grey {
    background-position: -256px -640px;
  }

  &.star-green {
    background-position: -320px -640px;
  }

  &.star-light-grey {
    background-position: -384px -640px;
  }

  &.star-light-red {
    background-position: -448px -640px;
  }

  &.star-pink {
    background-position: -512px -640px;
  }

  &.star-purple {
    background-position: -576px -640px;
  }

  &.star-red {
    background-position: -640px -640px;
  }

  &.star-yellow {
    background-position: -704px -640px;
  }

  &.startup {
    background-position: 0 -704px;
  }

  &.strategy {
    background-position: -64px -704px;
  }

  &.surprised {
    background-position: -128px -704px;
  }

  &.suspicious {
    background-position: -192px -704px;
  }

  &.switch {
    background-position: -256px -704px;
  }

  &.tablet {
    background-position: -320px -704px;
  }

  &.tablet-1 {
    background-position: -384px -704px;
  }

  &.target {
    background-position: -448px -704px;
  }

  &.team-1 {
    background-position: -512px -704px;
  }

  &.telemarketer-1 {
    background-position: -576px -704px;
  }

  &.toast {
    background-position: -640px -704px;
  }

  &.tongue-out-1 {
    background-position: -704px -704px;
  }

  &.traffic-sign {
    background-position: -768px 0;
  }

  &.trophy-02 {
    background-position: -768px -64px;
  }

  &.user-3 {
    background-position: -768px -128px;
  }

  &.user-4 {
    background-position: -768px -192px;
  }

  &.user-7 {
    background-position: -768px -256px;
  }

  &.users {
    background-position: -768px -320px;
  }

  &.warning {
    background-position: -768px -384px;
  }

  &.warning-1 {
    background-position: -768px -448px;
  }

  &.wink {
    background-position: -768px -512px;
  }

  &.winner-01 {
    background-position: -768px -576px;
  }

  &.winner-03 {
    background-position: -768px -640px;
  }

  &.winner-04 {
    background-position: -768px -704px;
  }

  &.worldwide-1 {
    background-position: 0 -768px;
  }
}
