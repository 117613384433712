@use "../../settings/third-party/variables";

.rcm {
  &.change-mgmt-popups {
    height: 520px;

    &.tag-delete {
      height: 305px;
      width: 550px;
    }
    height: 520px;

    &.confirm {
      height: 220px;
      width: 550px;
    }

    md-toolbar {
      .back-arrow-btn {
        min-width: 25px;
      }

      .arrow-icon {
        min-width: 15px;

        svg path {
          fill: variables.$white;
        }
      }

      .nxt {
        width: 70px;
      }
    }

    .warning {
      cursor: default;
    }

    .msg-section {
      line-height: 1.7em;
    }

    .selection {
      margin-top: 8px;
    }

    .location-symbol {
      padding-top: 3px;
    }

    .segment-container .data-table {
      font-size: 12px;

      td {
        // div {
        //   vertical-align: middle;
        // }

        &.status {
          white-space: nowrap;
          position: relative;

          // div {
          //   display: inline-block;
          //   width: 11px;
          //   height: 11px;
          //   border-radius: 50%;
          //   margin-right: 4px;
          // }

          .success {
            color: #3cea21;

            div {
              background: #3cea21;
              position: relative;
              // top: 3px;
              display: inline-block;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              margin-right: 4px;
              vertical-align: middle;
            }
          }

          .failed {
            color: #f05858;

            div {
              background: #f05858;
              position: relative;
              // top: 3px;
            }
          }
        }
      }
    }

    .location-data {
      .location-symbol {
        margin-right: 8px;
      }

      .md-select-value {
        .location-symbol {
          line-height: 0.9;
          height: 10px;
        }
      }

      .selection {
        margin-top: 15px;
      }

      .f3-light {
        font-size: 9px;
      }

      .f3 {
        font-size: 11px;
        font-weight: 600;
        padding-top: 3px;
      }
    }
  }

  &.rcm20 {
    .md-text {
      div {
        margin-bottom: 5px;
      }
    }
  }

  .ico-engineer {
    height: 200px;
    width: 200px;

    svg {
      path {
        fill: black;
      }
    }
  }

  .form-base md-input-container md-select md-select-value .location-symbol {
    display: inline-block;
    background: #5cc5c3;
    color: #fff !important;
    font-weight: 500;
    font-size: 10px;
    text-align: center;
    line-height: 1.9;
    border-radius: 20px;
    padding: 3px 3px !important;
    min-width: 30px;
    height: 18px;
    box-sizing: content-box;
  }

  .arrow-icon {
    height: 11px;
    width: 11px;
    margin-bottom: 3px;

    svg path {
      fill: variables.$gray;
    }
  }
}

.md-select-menu-container {
  .md-text {
    width: 100%;
  }
}

md-option {
  &.customize-dropdown {
    height: 70px;

    // .location-symbol {
    //   position: absolute;
    //   top: 15px;
    //   right: 15px;
    // }
  }
}

.form-base md-select.res-select {
  height: 70px;
}

.res-option {
  // span{
  //   color:$brand-primary;
  // }
  // .md-avatar,
  // img,
  // span.img-replacer {
  //   height: 32px;
  //   width: 32px;
  //   line-height: 32px;
  //   border-radius: 50px;
  // }

  .f3-light {
    font-size: 9px;
  }

  .f3 {
    font-size: 11px;
    font-weight: 600;
    padding-top: 3px;
  }

  div {
    margin-right: 8px;
  }
}

.sku-popup {
  height: 350px;
  width: 650px;
}

.msg-icon {
  height: 15px;
  width: 15px;
}

.msg-section {
  margin: 5px 0;
}

.change-stock-popup {
  width: 650px;

  .data-table {
    font-size: 12px;
  }
}

@media only screen and (max-width: 600px) {
  .selection {
    md-input-container {
      margin-top: 8px;
    }
  }
}
