@use "../settings/third-party/variables";

/*--Login-Page--*/
.auth-login {
  md-content {
    flex: 1 0 auto;
    background: variables.$brand-primary url("#{variables.$image-path}/login-bg.png") center center/cover fixed no-repeat;
    padding: 20px;
  }

  .welcome-text {
    font-size: 60px;
    font-weight: 500;
    color: variables.$white;
  }

  .logo-icon {
    width: 300px;
    height: auto;
  }

  .status-icon {
    margin: 0 10px 0 0;
  }

  .login-secure {
    margin-bottom: 30px;

    md-icon {
      height: 30px;
      width: 30px;
    }

    h2 {
      margin: 0;
      font-size: 28px;
      font-weight: 300;
    }
  }

  .form-base {
    padding: 55px 80px 30px;
    width: 100%;
    max-width: 430px;
    background: variables.$white;

    hr {
      background: rgb(221, 221, 221) none repeat scroll 0 0;
      border: 0 none;
      height: 1px;
      position: relative;
      top: 3px;
    }

    input[type="password"] {
      padding-right: 30px;
    }
  }

  .remember-me {
    a {
      text-decoration: underline;
    }

    md-checkbox {
      margin: 0;
    }
  }

  .btn-primary,
  .btn-secondary {
    margin: 25px 0;
  }
}

@media screen and (max-width: 960px) {
  .auth-login {
    .welcome-container {
      margin-bottom: 25px;
    }
  }
}

@media screen and (max-width: 600px) {
  .auth-login {
    .welcome-container {
      margin-bottom: 25px;
    }

    .welcome-text {
      font-size: 35px;
    }

    .logo-icon {
      width: 200px;
    }

    .login-secure {
      md-icon {
        left: -24px;
        height: 24px;
        width: 24px;
      }

      h2 {
        font-size: 24px;
      }
    }

    .form-base {
      padding: 30px 30px 5px;
      max-width: 360px;
    }
  }
}

@media screen and (min-width: 600px) {
  .auth-login {
    .login-secure {
      left: -39px;
      width: 300px;
    }
  }
}
