@use "../../settings/third-party/variables";

.order-import {
    .section-padding {
        padding: 30px 50px;
    }

    .import-button-border {
        height: 30px;
        border-right: 5px solid variables.$brand-primary;
    }

    a.usr-name,
    a.user-name {
        font-size: 14px;
        font-weight: 400;
    }

    .table-responsive .recommendation-td {
        bottom: 0px;
    }

    .table-responsive .recommendation-td .td-title-heading {
        margin: 0 0 0 15px;
    }

    .table-responsive .data-table .hexa-icon .ico-table {
        bottom: 5px;
        z-index: 1;
    }

    .data-table .button-td {
        line-height: 5px;
    }

    .td-description-text {
        color: #6a6a6a;
    }

    .data-table td {
        min-width: 200px;
    }

    .data-table{
        .check-width{
            min-width: 100px;
        }
    }

    .data-table .ico-table {
        margin-left: 0px;

        &.icon-import-setup {
            height: 18px !important;

            svg path {
                fill: #b7b7b7;
            }
        }

    }

    // .order-import-cta {
    //     text-align: center;
    //     font-weight: bold;
    //     max-width: 175px;
    //     margin: 0px auto 10px;
    //     width: 100%;
    //     background-color: #fff;
    //     color: $brand-primary;
    //     border: 1px solid $brand-primary;

    //     &:hover {
    //         background-color: $brand-primary;
    //         color: #fff;
    //         border: 1px solid $brand-primary;
    //     }
    // }

    // .process-cta {
    //     background-color: #3c78d8ff;
    //     color: $white;
    //     border: 1px solid #3c78d8ff;

    // }
    .process-cta {
        text-align: center;
        font-weight: bold;
        max-width: 175px;
        margin: 0px auto 10px;
        width: 100%;

        .right-arrw-ico {
            width: 16px;
            height: 16px;

            path {
                fill: #fff;
            }
        }
    }
    .progress-cta{
        background-color: #3c78d8;
        color: variables.$white;
        border: 1px solid #3c78d8;
    }
    .progress-cta:hover{
        background-color: #3c78d8;
        color: variables.$white;
        border: 1px solid #3c78d8;
    }
    .run-cta{
        background-color: #a8b6c2;
        color: variables.$white;
        border: 1px solid #a8b6c2;
    }
    .run-cta:hover{
        background-color: #a8b6c2;
        color: variables.$white;
        border: 1px solid #7c41b0;
    }
    .view-cta {
        background-color: variables.$brand-primary;
        color: variables.$white;
        border: 1px solid variables.$brand-primary;
    }
    .view-cta:hover{
        background-color: variables.$brand-primary;
        color: variables.$white;
        border: 1px solid variables.$brand-primary;
    }
    .bulk-cta {
        background-color: variables.$white;
        border: 2px solid #d8e0e6;
        max-width: 200px;
    }
    .video-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: rgba(0, 0, 0, .3);
    }
    .bulk-cta-focus{
        @extend .bulk-cta;
        border: 2px solid variables.$brand-primary;
    }
    .import-run-bulk-cta{
        background-color: rgb(221, 233, 238);
        &.border {
            border: 2px solid #D8E0E6;
            border-radius: 3px;
        }
    }
    .start-import {
        height: 30px;
    }
    .play-icon{
        height: 60px;
        width: 60px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        path {
            fill: black;
        }
    }
    .create-add {
        path {
            fill: variables.$white;
        }
    }

    .template-container {
        height: 150px;
        padding:10px;
        background-color: rgb(197, 239, 235);
    }
    .md-card-custom {
        min-height: 250px;
        width: 300px;
        border-radius: 5px;
        border: 1px solid #DFDFDF;
    }
}
.welcome-order-import-section{
    .back-arrow{
        transform: rotate(180deg);
        top: -3px;
        position: relative;
    }
    .switch-container {
        display: flex;
      }
    .hex-icon-start-import{
        margin: 8px 8px 0 0;
    }

    .start-import-heading{
        margin-left: 0;
    }
    .no-click{
        pointer-events: none;
        background-color: #8e8e93;
        color: variables.$gray-dark;
    }
    .coming-soon, .bulk-op {
        width: 100%;
    }
    .app-title {
        max-width: 200px;
        margin: 0px auto;
    }
    .order-import-tab{
        width: 100%;
    }
    .export-criteria-div{
        h2{
            &.sub-heading-title{
                font-size: 21px!important;
                max-width: 550px;
                width: 100%;
            }
        }
    }
    .setup-name-input{
        &.md-input-invalid{
            margin-top: 10px;
        }
        &.md-input-focused{
            margin-top: 15px;
        }
    }
    
        .user-input, .start-import-heading{
            margin-left: 10px;
        }
    
    .setup-name-input.md-input-has-value{
        margin-top: 15px;
    }
    .setup-content-div{
        .capsule-name .icon_img {
            width: 35px;
        }

        .product-tag li {
            background-color: #52748d;
            color: #ffffff;
        }
    }

    .chat-list {
        border-left: 6px solid #ffab40ff;
    }

    .proflie_section h1 {
        font-size: 24px !important;
    }
    .proflie_section p {
        color: #999999!important;
    }
    .detail-overview{
        
        .heading-title{
            font-size: 24px;
        }
        .desc-text,.download-link{
            font-size: 14px;
            line-height: 1.6;
        }
        .sub-heading-title{
            font-size: 20px;
            // max-width: 550px;
            width: 100%;
        }

        .thanking-note {
            color: #000000;
            margin: 0px 0px 5px 0px;
            font-size: 14px;
        }
    }

    .order-note {
        max-width: 650px;
        width: 100%;
        font-size: 12px;
        color: #2a2a2a;
        line-height: 1.5;
        padding: 10px;
        margin-bottom: 40px;
        border: 1px dashed #5CC5C3;
        border-left: 5px solid #5CC5C3;

        .note-label {
            margin-right: 10px;
            color: #5CC5C3;
        }
    }

    .grey-text {
        color: #595959;
    }

    .black-text {
        color: #000000;
    }

    .customer-detail-tabs {
        width: 95%;
    }

    .pick-setup-section {
        top: -10px;
        position: relative;

        .pick-set-section {
            background-color: #d3e1e54a;
            padding-top: 25px !important;
        }
    }

    .set-service-section {
        span {
            text-align: left;
        }

        .md-button {
            line-height: 1.6;
            padding: 10px 8px;
            position: relative;
            overflow: visible;
            min-width: 120px;

            .icon-tick {
                position: absolute;
                right: -7px;
                top: -12px;

                svg path {
                    &.tick-bg {
                        fill: rgb(76, 183, 72);
                    }

                    &.outer-circle {
                        fill: rgb(76, 183, 72);
                    }

                    &.inner-tick {
                        fill: #ffffff;
                    }
                }
            }
        }

        .btn-primary {
            color: #ffffff;
            background-color: variables.$brand-primary;
            border: 1px solid variables.$brand-primary;
            margin: 0px 30px 0px 0px;

            .icon-tick {
                svg path {
                    &.tick-bg {
                        fill: rgb(76, 183, 72);
                    }

                    &.outer-circle {
                        fill: rgb(76, 183, 72);
                    }

                    &.inner-tick {
                        fill: #ffffff;
                    }
                }
            }
        }

        .btn-secondary {
            color: variables.$brand-primary;
            background-color: variables.$white;
            border: 1px solid variables.$brand-primary;
            margin: 0px 30px 0px 0px;

            .icon-tick {
                svg path {
                    &.tick-bg {
                        fill: #999999;
                    }

                    &.outer-circle {
                        fill: #999999;
                    }

                    &.inner-tick {
                        fill: #ffffff;
                    }
                }
            }
        }
    }

    .order-setup-section {
        padding: 15px;

        .heading-services {
            width: 100%;
        }

        .capsule-tab {
            background-color: #52748d;
            color: #ffffff;
            font-size: 13px;
            font-weight: 600;
            padding: 7px 35px;
            border-radius: 50px;
            position: relative;

            .icon-tick {
                position: absolute;
                top: -8px;
                right: -6px;

                svg path {
                    &.tick-bg {
                        fill: rgb(76, 183, 72);
                    }

                    &.outer-circle {
                        fill: rgb(76, 183, 72);
                    }

                    &.inner-tick {
                        fill: #ffffff;
                    }
                }
            }
        }

        .datepicker-custom {
            width: 60%;
        }

        .time-zone-section {
            background-color: #d9e7f2;
            border: 1px solid #c3d0d8;
            padding: 10px;
            border-radius: 5px;
            max-width: 570px;

            .icon-next-arrow {
                width: 40px;
                height: 15px;
                position: relative;
                left: 18px;
            }

            .icon-next-arrow svg path {
                fill: variables.$brand-primary;
                transform: rotate(90deg);
            }
        }

    }

    .upload-inventoryfile {
        max-width: 525px;
        width: 100%;
        padding-bottom: 15px;
    }

    .order-import-template {
        .ar-sidepanel .task-wrapper {
            position: absolute;
            top: 0px;
        }

        .chart-header {
            background-color: #000000;
            color: #fff;
            font-weight: 600;
            margin-top: 0px;
        }

        .chat-unreads-div {
            padding: 10px;
        }

        .logo_icon_img {
            width: 50px;
            height: 50px;
        }

        // .inner-container{
        //     overflow: auto;
        //     height: 100%;
        // }
        .notes-templates-main {
            margin: 0px auto 30px;

            .detail-cards-div {
                height: 100px;
                margin: 0px 0px 20px;

                .temp-widget {
                    &.active {
                        border: 1px solid #64c43a;
                    }
                }
            }

            .template-preview {
                overflow: visible !important;
            }

            .cost-card-section {
                margin-right: 0px;

                .icon-tick {
                    position: relative;
                    top: -20px;
                    z-index: 999;
                    right: -68px;
                    width: 21px;
                    height: 21px;
                }

                svg path.tick-bg {
                    fill: #4cb748;
                }

                svg path.outer-circle {
                    fill: #4cb748;
                }

                svg path.inner-tick {
                    fill: #ffffff;
                }
            }

            .card-capsule {
                max-width: 85px;

                &.inactive {
                    color: #ff0000;
                    background-color: #f4cccc;
                }
            }

        }

        .create-template-section {
            margin-bottom: 100px;
        }
    }

    .notes-templates-main {
        margin-bottom: 80px;

        .template-image {
            height: 100%;
        }

        .detail-cards-div {
            max-width: 250px;
            min-height: 260px;
            margin-left: 0px;

            h3 {
                font-size: 17px;
            }
        }

        .btn-primary {
            line-height: 1.6;
            padding: 5px 10px;
            min-width: 120px;
            margin: 0px auto;

            .caption {
                text-align: left;
            }

            .description {
                text-align: left;
                font-size: 18px;
            }
        }

        .cost-card-section {
            max-width: 250px;
            margin: 0px 30px 20px 0px;
        }

        .card-bottom-section {
            padding: 0px 5px;

            .icon-ico-add {
                margin: 0px 10px 0px 0px;
            }

            .list-icons {
                margin-left: 0px;
            }
        }
        .welcome-order-import{
            .heading{
                color: black;
            }
        }
        .card-heading {
            padding: 10px;
            background-color: black;
            color: white;
            font-size: 16px;
            font-weight: 600;
            width: 100%;
        }

        md-card-actions {
            p {
                margin: 0px 0px 5px;
            }

            .price-amount {
                font-weight: 500;
                margin: 0px;
                font-size: 18px;
            }
        }

        .card-capsule {
            background-color: #d8ead2;
            color: #38761d;
            padding: 5px;
            border-radius: 10px;
            max-width: 75px;
            width: 100%;
            text-align: center;
        }

        .detail-cards-div .temp-widget .template-preview md-card-title {
            padding: 10px 16px 15px 8px;
        }
    }

    .invoice_right_side {
        background-color: #000000;
        padding: 8px;
        h3{
            color: #ffffff;
            font-size: 16px;
        }
        md-icon.search-symbol path,md-icon.fill-white svg path {
            fill: #ffffff;
        }
    }
    .enter-date-section{
        md-icon.list-icons{
            margin-left: 0px;
            margin-top: 10px;
        }
    }
}
@media (max-width: 720px) {
    .landing-page {
      flex-direction: column;
      justify-content: start;
    }
  }
  
@media (max-width: 620px) {
    .welcome-order-import-section{
        .user-input, .start-import-heading{
            margin-left: 20px;
        }
        .coming-soon, .bulk-op{
            width: 80%;
        }
    }
}


