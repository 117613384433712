@mixin border-radius($radius) {
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

@mixin side-border-radius($side1, $side2, $radius) {
  -moz-border-#{$side1}-#{$side2}-radius: $radius;
  -webkit-border-#{$side1}-#{$side2}-radius: $radius;
  border-#{$side1}-#{$side2}-radius : $radius;
  background-clip: padding-box;
}
@mixin background-opacity($color, $opacity: 0.3) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}
@mixin buttonIcons($btnDimension) {
  height: $btnDimension;
  min-height: $btnDimension;
  width: $btnDimension;

  md-icon {
    height: $btnDimension - 15px;
    width: $btnDimension - 15px;
  }
}