@use "../../settings/third-party/mixins";
@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.job-charge-section {
  .last-update-msg {
    top: 14px;
    right: 8px;
  }

  .custom-tabs {
    md-content {
      padding-top: 20px;
    }
  }

  .top-section {
    margin-bottom: 35px;
  }

  .section-titles {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .note-section {
    line-height: 20px;
    margin-top: 25px;
  }

  md-divider {
    margin-top: 15px;
    margin-bottom: 20px;
  }

  .switch-bar {
    label {
      padding-right: 15px;
    }
  }

  .email-editor {
    textarea {
      height: 85px !important;
    }
  }

  .other-inputs {
    margin-top: 28px;
    margin-bottom: 14px;

    md-checkbox {
      margin-bottom: 0;
      margin-top: -2px;
    }
  }

  .add-btn {
    height: auto;
    min-height: auto;
    min-width: auto;
    margin: -8px 0 0 -8px;
  }

  .custom-cb {
    @extend .f3;

    .md-label {
      margin-left: 37px;
    }
  }

  .rules-list {
    .rule-type-section {
      min-width: 100px;
    }
  }

  &.recurring-cpolicy-section {
    .top-section {
      margin-bottom: 30px;
    }

    md-radio-group md-radio-button {
      @extend .f3;

      margin-bottom: 20px;
    }

    .switch-bar {
      &.simple-switch-bar {
        margin-top: 10px;
      }

      &.advance-switch-bar {
        margin-bottom: 20px;

        label {
          min-width: 80px;
        }
      }

    }
  }
}

.job-charge-viewSection,
.po-payment-viewSection,
.recurring-charge-viewSection {
  .main-title {
    @extend .f1;

    font-weight: normal;
    margin-top: 0;
    margin-bottom: 16px;
  }

  .charges-title {
    @extend .f3;

    font-weight: bold;
    margin-bottom: 14px;
  }

  .custom-tabs {
    md-content {
      padding-bottom: 15px;
    }
  }
}

.job-charge-viewSection {
  .charge-rule-viewlist {
    margin-bottom: 30px;
  }
}

.recurring-charge-viewSection {
  .rpp-list {
    margin-bottom: 38px;

    li {
      margin-bottom: 18px;
    }
  }
}

.charge-rule-viewlist {
  @extend .no-style;

  margin-left: 30px;

  li {
    margin-bottom: 18px;

    md-checkbox {
      margin-bottom: 0;
      position: relative;
      left: -30px;
    }
  }
}

.notification-viewlist {
  @extend .no-style;

  li {
    @include mixins.border-radius(5px);

    border: 1px solid variables.$gray-lighter;
    line-height: 19px;
    padding: 16px 20px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    .main-title {
      font-weight: 500;
      margin-bottom: 8px;
    }

    .email-subject {
      @extend .f3;
      @extend .fm;

      margin-bottom: 10px;
    }

    .day-title {
      @extend .f1;

      font-weight: normal;
      margin-top: 10px;
      margin-bottom: 3px;
    }
  }

  md-checkbox {
    margin-bottom: 0;
    margin-left: 30px;
  }
}

@media screen and (min-width: 600px) {
  .job-charge-section {
    .gap-right {
      margin-right: 20px;
    }

    .other-inputs {
      md-checkbox {
        margin-left: 65px;
      }
    }
  }

  .charge-rule-viewlist {
    max-width: 400px;
  }
}

@media screen and (max-width: 600px) {
  .job-charge-section {
    .other-inputs {
      margin-bottom: 20px;
    }

    .switch-bar {
      margin-bottom: 20px;
    }
  }
}
