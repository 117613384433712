.itemPerOrder {
  padding: 20px;

  .stock-overview {
    padding: 0;
    text-align: center;

    .inStock {
      margin-left: 10px;
    }

    .stocks {
      margin-left: -2px;
      padding: 4px !important;

    }

    .stock-bg {
      background: #efefefff;
      padding: 6px 4px;
    }

    .stock-backorder {
      margin-right: 14px;
    }
  }

  .stock-item-info{
    max-width: 270px;
    width: 100%;
    min-width: 160px;
  }
  .locationsymbo-bar {
    margin-bottom: 22px;
  }

  .data-table {

    min-width: 1200px !important;
  }

  .order-status {
    max-width: 80px;

    .status-viewer {
      -webkit-border-radius: 5px;
      border-radius: 5px;
      background-clip: padding-box;
      background: #dbdbdb;
      border: 1px solid #cccccc;
      height: 16px;
      margin-top: 2px;
      margin-bottom: 2px;
      overflow: hidden;

      .progress-bar {
        display: inline-block;
        height: 100%;
      }

      // .progress-bar.open {
      //   background: #6C9AFF;
      // }
      // 
      // .progress-bar.closed {
      //   background-color: #b9eaa7;
      // }
    }

  }
}

.fulfilled-items {
  .order-status {
    .status-viewer {
      width: 84px;
      display: inline-flex;
    }
  }

  .delivered-order {
    padding-right: 11px;
  }

  .cancel-qty {
    max-width: 50%;
    text-align: center;
  }

  .cancel-item-fulfillment {
    margin-top: 10px;

    table {
      tbody {
        tr {
          td {
            padding-top: 10px;
          }
        }
      }
    }
  }
  padding: 20px;

  .product-icon {
    md-icon {
      svg {
        height: 75px;
        width: 75px;
      }
    }
  }

  .cancel-items {
    margin-bottom: 12px;
  }

  .cancel-item-product {
    font-size: 14px;
    font-weight: bold;
  }

  .item-supplier {
    font-weight: bold;
    color: #000;
    padding: 15px 32px;
    background: #efefefff;
    min-width: 481px;
  }

  table {
    min-width: 500px;

    .stock-overview {
      text-align: center;

      .stock-bg {
        padding: 15px;
      }
    }
  }
}

.procured-items {
  .stock-bg {
    padding: 15px;
  }

  .stock-overview {
    text-align: center;

  }

  .item-remaining {
    margin-top: 10px;
  }

  .item-receive {
    padding: 15px;

    .item-supplier {
      padding: 15px 32px;
      background: #efefefff;
      min-width: 481px;

    }
  }

  table {
    td {
      padding: 15px;
    }
  }

}

.ordering-cart {
  padding: 20px;

  .warrenty-oderded {
    margin-right: 15px;
  }
}
