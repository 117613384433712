@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.add-catalog-section {
  .summery-widget {
    md-icon {
      height: 21px;
      margin-right: 8px;
      width: 21px;

      svg path:first-child {
        fill: transparent;
      }
    }

    .summery-widget-footer {
      background-color: white;
    }
  }

  md-radio-group {
    md-radio-button .md-label {
      @extend .fm;
      @extend .f3;
    }
  }

  .switch-bar {
    margin-top: 5px;
    margin-bottom: 13px;

    label {
      margin-right: 15px;
    }
  }

  h3 {
    margin-top: 10px;
    margin-bottom: 15px;
    font-weight: 500;
  }

  .child-list {
    li {
      margin-bottom: 20px;
    }

    .list-icons {
      height: 14px;
      margin-left: 12px;
      width: 14px;

      svg path {
        fill: variables.$gray;
      }
    }
  }
}
