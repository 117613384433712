@use "../settings/third-party/variables";

.img-avatar-replacer {
  margin: 0;
  height: 32px;
  width: 32px;
  line-height: 32px;
  padding: 0;
  color: variables.$brand-primary !important;
}

.contact-chips-custom-template {
  .img-replacer {
    @extend .img-avatar-replacer;
  }

  img,
  md-icon.avatar {
    height: 32px !important;
    width: 32px !important;
    min-width: 32px !important;
    max-width: 32px !important;
    min-height: 32px !important;
    max-height: 32px !important;
    border-radius: 50%;
    overflow: hidden;
    vertical-align: middle;
  }
}

.calendar-fixed {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
}

.calendar-sidebar {
  .block-title {
    font-size: 16px;
    // font-weight: 400;
    margin: 8px;
  }

  .leftnav-wrap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: auto;
  }

  .select-wrapper {
    padding: 10px 16px 16px;

    md-input-container {
      input,
      md-select,
      textarea {
        background-color: variables.$white;
      }
    }
  }

  .actions {
    .delete-icon {
      height: 15px;
      width: 15px;
    }
  }

  div.f3 {
    margin: 8px 0;
  }

  // .status {
  //   color: $brand-success;
  // }

  .draft-status {
    color: variables.$brand-accent;
  }

  .quote-title-info {
    margin: 0 auto;
  }

  .quote-margin {
    margin: 15px 8px;
  }

  .quote-padding {
    padding-bottom: 10px;
  }

  .margin-remove {
    margin: 0;
  }

  .width-remove {
    width: auto;
  }

  .md-margin-remove md-chips,
  .md-margin-remove md-chips-wrap {
    margin-bottom: 0;
  }

  .quote-time {
    color: variables.$gray;
    font-size: variables.$font-size-small - 2;
  }

  .quote-badge {
    width: 20px;
    background: variables.$gray-light;
    height: 20px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    font-size: variables.$font-size-small - 2;
    color: variables.$component-active-color;
  }

  .chip-set-for md-chips-wrap {
    background: none;
    box-shadow: none;
  }

  .text-bold {
    font-weight: bold;
  }

  .date-picker {
    &.gap-right {
      margin-right: 20px;
    }

    md-datepicker {
      background: none;
    }

    .md-datepicker-button {
      position: absolute;
      z-index: 1;
      right: -7px;
    }

    .md-datepicker-expand-triangle {
      display: none;
    }

    .md-datepicker-input-container {
      background: variables.$white;
      margin-left: 0;
      width: 100%;

      .md-datepicker-input {
        height: 38px;
        position: relative;
        top: 2px;
        padding-left: 3px;
      }
    }
  }

  .bottom-gap {
    margin-bottom: 18px;
  }

  md-input-container {
    // background: $white;
    input,
    textarea {
      background-color: variables.$white;
    }

    textarea {
      min-height: 96px;
    }

    &.input-md {
      textarea {
        height: 50px !important;
      }
    }
  }

  md-checkbox {
    margin-left: auto;
  }

  .day-text {
    height: 32px;
    line-height: 32px;
    width: 32px;
    cursor: pointer;
  }

  .days {
    background: variables.$white;
    padding-left: 2px;

    li {
      margin-top: 10px;
    }
  }

  .day-tags-section {
    margin-bottom: 20px;
  }

  // .md-button {
  //   margin: 0;
  // }

  md-chips-wrap {
    background-color: variables.$white;
  }

  // md-chips[readonly] md-chips-wrap {
  //   background-color: $gray-light;
  //   cursor: not-allowed;
  // }

  .md-chips {
    img,
    md-icon.avatar {
      height: 32px !important;
      width: 32px !important;
      min-width: 32px !important;
      max-width: 32px !important;
      min-height: 32px !important;
      max-height: 32px !important;
      border-radius: 50%;
      overflow: hidden;
      vertical-align: middle;
    }

    .md-chip,
    md-chip {
      border-radius: 50px;
      padding-left: 0;
      line-height: 28px;

      .md-chip-content span {
        font-size: 12px;
      }
    }

    .img-replacer {
      @extend .img-avatar-replacer;
    }
  }
}

.tips-sidebar {
  .page-section {
    position: absolute;
    top: 51px;
    bottom: 50px;
    left: 0;
    right: 0;
    overflow-y: auto;
  }

  &.ar-sidepanel {
    width: 400px;
  }

  .data {
    line-height: 20px;

    ul {
      list-style: inherit;
    }

    img {
      max-width: 100%;
    }
  }

  .bottom-section {
    .md-button md-icon {
      height: 15px;
      width: 15px;

      &.nav path {
        fill: #00000061;
      }
    }
  }
}
