@use "../../settings/third-party/variables";

.product-details-custom {
  .detail-overview {
    display: flex;

    .gallery {
      margin-right: 45px;
      width: auto;

      .i-holder {
        border: 1px solid #21374cff;
        border-radius: 5px;
        overflow: hidden;
        width: 250px;
        height: 250px;
        text-align: center;
        position: relative;
        background-color: #21374cff;

        img {
          width: 225px;
        }
      }

      .thumb-items {
        margin-top: 10px;

        ul {
          li {
            width: 78px;
            height: 78px;

            img {
              max-width: fit-content;
              max-height: fit-content;
              margin: auto;
              text-align: center;
              vertical-align: middle;
              display: flex;
              align-items: center;
              margin-top: 10px;
            }
          }
        }

        li.active {
          border: 2px solid #f45150;
        }
      }
    }

    .desc {
      display: inline-flex;

      .first_row {
        .ico_gap {
          margin-right: 10px;
        }
      }

      ul {
        li {
          margin-bottom: 12px;
        }

        .title {
          margin-top: 16px;
          font-weight: 600;
        }

        .deleted_amount {
          margin-right: 20px;
          font-size: 13px;
        }

        del {
          color: #ff0000;
          font-size: 13px;
        }

        .amount {
          color: variables.$gray-base;
        }

        .title_label {
          color: #999999;
          font-weight: normal;

        }

        .product_id {
          margin-bottom: 8px;
        }

        .max_order_count {
          margin-bottom: 18px;
        }

        .item-quantity-box {
          margin-bottom: 16px;

          md-icon {
            width: 30px;
            height: 30px;
          }
        }

        .cost_cta {
          margin-bottom: 30px;
        }
      }

      .md-button {
        border-radius: 50px;
        padding: 0 15px;
        font-size: 18px;
      }

      .view_media {
        margin-right: 20px;
        font-size: 18px;
      }

      .btn-secondary {
        background-color: #b0bfc7ff;
        color: #ffffff;
      }

    }

    .item-quantity-box {
      .title_label {
        margin-right: 30px;
        display: inline-grid;
        vertical-align: middle;
      }

      .ico-round-btn {
        svg {
          path {
            fill: #ff9900;
          }
        }
      }

      input[type="text"] {
        border-color: #ff9900;
        height: 40px;
        font-size: 20px;
        width: 60px;
        color: variables.$gray-base;
      }
    }
  }

  .section2 {
    background-color: #f2fffeff;
    padding: 30px 18px;

    .first_card {
      max-width: 500px;
      width: 100%;
      padding: 0;
    }

    .heading {
      background-color: #2b3e50ff;
      position: relative;
      margin: 0 auto;

      .ico_gap {
        position: absolute;
        top: -30px;
        left: 5px;
        width: 55px;
      }

      h2 {
        color: variables.$white;
        margin: 0 auto;
        font-size: 20px;
      }
    }

    .card_div {
      padding: 20px;

      .first_row {
        margin-right: 10px;
        position: relative;
        bottom: 30px;

        .ico_gap {
          svg {
            path {
              fill: #999999;
            }
          }
        }
      }

      .content_row {
        display: inline-block;
        width: 100%;
        margin-bottom: 10px;
      }

      .location-symbol {
        margin-right: 10px;
        padding: 0 10px !important;
      }

      .margin-btm {
        margin-bottom: 10px;
      }

      .second_row {
        .allowed_order {
          max-width: 150px;
          width: 100%;
          margin-bottom: 25px;
        }

        .order_quantiy_div {
          display: grid;
        }

        .label_first {
          margin-right: 25px;
        }

        .ico_gap {
          svg {
            path {
              fill: #999999;
            }
          }
        }
      }

    }

    .second_card {
      max-width: 750px;
      width: 100%;

      md-input-container {
        textarea.md-input {
          height: 45px !important;
          overflow: auto;
        }
      }
    }
  }

  .section3 {
    background-color: #20394cff;
    padding: 15px 0;
    margin-top: 0;
    margin-left: 0;

    .package_offer_section {
      md-icon {
        margin-right: 10px;

        svg {
          path {
            fill: variables.$white;
          }
        }
      }
    }

    h2 {
      color: #63d1dd;
      font-size: 20px;
    }

    .top-right-section {
      label {
        font-weight: normal;

        .btn-primary {
          background-color: #ffffff;
          color: variables.$brand-primary;
          padding: 7px 15px;
          border-radius: 50px;
          margin-left: 10px;
          font-weight: bold;
        }
      }
    }

    .add-opportunity.package_offer_section.add-items {
      margin-left: 45px;
    }

    .custom-default-box {
      margin-left: 65px;

      .inner-cell {
        margin-left: 30px;
      }

      .catalog_container_div {
        margin-bottom: 20px;
        justify-content: end;
        gap: 105px;
      }

      .catalog_container2_div {
        justify-content: end;
        gap: 105px;
      }

      .catalog_container {
        display: flex;
        // margin-right: 65px;
      }

      .first_row {
        margin-right: 15px;
        position: relative;
        top: 8px;
      }
      

      .order_quantiy_div {
        // display: grid;
        display: flex;
        justify-content: flex-start;

        h1 {
          margin-top: 0;
          font-size: 30px;
          margin-right: 10px;
        }

        label {
          color: variables.$white;
          font-size: 16px;
          font-weight: normal;
        }
      }

      .second_cell {
        text-align: center;
        color: variables.$white;

        .percent_des_label {
          font-size: 16px;
        }
      }

      .percent_counter {
        display: inline-block;
        background: #ffffff;
        border: 3px solid #06b7f8;
        color: #20394c !important;
        font-weight: 600;
        font-size: 28px;
        text-align: center;
        line-height: 3;
        border-radius: 50px;
        padding: 0 3px !important;
        min-width: 80px;
        height: 80px;
        margin-bottom: 20px;
        // margin-left: 40px;
        box-sizing: content-box;
      }
    }
    // .package_offer_section{
    //     display: flex;
    //     justify-content: space-between;
    //     width: 100%;
    // }
  }

  .navigate_catalog {
    .second_cell {
      gap: 60px;
    }

    .icon_img {
      width: 85px !important;
      // margin-bottom: 55px;
    }

    .section-heading {
      h2 {

        margin-top: 30px !important;
        margin-left: 17px;
      }
    }

    .right-div {
      label {
        font-size: 14px;
        color: #666666;
        font-weight: normal;
      }
    }

  }

  .section4 {
    height: 200px;

    .icon_img {
      width: 100px;
    }

    .icon_first {
      margin-right: 25px;
    }

    .percent_counter {
      display: inline-block;
      background: #ccccccff;
      border: 4px solid #ccccccff;
      text-align: center;
      line-height: 3.2;
      border-radius: 50px;
      width: 10px;
      height: 10px;
      margin-bottom: 5px;
      box-sizing: content-box;
    }

    .catalog_menu_cell {
      span {
        font-size: 21px;
        text-transform: uppercase;
        color: #ffce3aff;
      }
    }

    .catalog_menu_pink {
      span {
        color: #e06666;
      }

    }

    .catalog_menu_green {
      span {
        color: #c2e74d;
      }

    }

    .percent_counter.green_percent_counter {
      background: #c2e74dff;
      border: 4px solid #c2e74dff;
    }

    .percent_counter.pink_percent_counter {
      background: #ea9999ff;
      border: 4px solid #ea9999ff;
    }

    .percent_counter.yellow_percent_counter {
      background: #ffce3aff;
      border: 4px solid #ffce3aff;
    }

    .second_cell {
      display: flex;
      margin-left: 22px;
      margin-top: 25px;
      // gap: 15px;
      // .inline-block {
      //     margin-right: 65px;
      // }
    }

    .menu_second_cell.second_cell {
      margin-left: 113px;
      margin-top: 0;
      .catalog_menu{
        display: flex;
        .percent_counter {
          margin-right: 10px;
        }
      }
    }

    md-divider {
      margin-left: 20px;
    }

    .icon-down {
      transform: rotate(-90deg);
      width: 15px;
      height: 15px;
      margin-right: 5px;

      svg {
        path {
          fill: #ff9900ff!important;
        }
      }
    }

    .percent_des_label {
      color: #ff9900ff;
      font-size: 14px;
    }

    .right-div {
      margin-top: 25px;
    }

    .section-heading {
      min-height: auto;
      // margin-bottom: 20px;
      h2 {
        font-size: 20px;
        margin-top: 45px;
      }
    }
  }

  .section5 {
    height: 215px;

    .main_div {
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .sub-heading-title {
      margin-bottom: 0;

      h2 {
        margin-left: 122px;
        font-size: 25px;
      }
    }
    // .icon_img{
    //     width: 100px;
    //     position: relative;
    //     bottom: 84px;
    // }
    .catalog_cell {
      background-color: black;
      margin-left: 0;
      padding-top: 20px;
      margin-top: 15px;
      display: flex;

      .inline-block {
        margin-right: 50px;
        min-height: 20px;
        height: 45px;
        margin-bottom: 10px;
      }

      .inner_catalog_cell {
        display: flex;
        justify-content: space-between;
        padding-bottom: 15px;
        float: left;
        width: calc(100% - 80px);
        border-left: 4px solid rgb(214, 225, 229);
        margin-left: -107px;
        padding-left: 73px;
      }

      .outer_catalog_cell {
        .percent_counter {
          height: 100px;
          width: 100px;
          position: relative;
          bottom: 84px;
          border: 1px solid #ffce3aff;
          background: #ffce3aff;
        }

        .percent_counter.green_counter {
          background: #c2e74dff;
          border: 1px solid #c2e74dff;
        }

        .percent_counter.pink_counter {

          border: 1px solid #ea9999ff;
          background: #ea9999ff;
        }

      }
    }

    .percent_counter {
      display: inline-block;
      background: transparent;
      border: 2px solid #ffce3aff;
      color: variables.$white;
      line-height: 1.7;
      font-weight: 600;
      font-size: 22px;
      text-align: center;
      border-radius: 50px;
      width: 35px;
      height: 35px;
      margin-bottom: 5px;
      box-sizing: content-box;
    }

    .percent_counter.yellow_percent_counter {
      background: #ffce3aff;
      border: 4px solid #ffce3aff;
      color: #374151;
    }

    .percent_counter.pink_percent_counter {
      background: #ea9999ff;
      border: 4px solid #ea9999ff;
      color: #374151;
    }

    .percent_counter.pink_percent {
      border: 2px solid #ea9999ff;
    }

    .percent_counter.green_percent_counter {
      background: #c2e74dff;
      border: 4px solid #c2e74dff;
      color: #374151;
    }

    .percent_counter.green_percent {
      border: 2px solid #c2e74dff;
    }
  }

  .desc {
    .percent_counter {
      display: inline-block;
      background: transparent;
      border: 2px solid #ffce3aff;
      color: variables.$white;
      line-height: 1.1;
      font-weight: 600;
      font-size: 22px;
      text-align: center;
      border-radius: 50px;
      width: 45px;
      height: 45px;
      margin-bottom: 5px;
      margin-right: 10px;
      box-sizing: content-box;

      .icon_img {
        width: 35px;
        margin-top: 5px;
      }
    }
  }

  .section6 {
    height: 145px;
    padding-top: 0;
    margin-top: 0;
    float: left;
    width: calc(100% - 80px);
    border-left: 4px solid #d6e1e5;
    margin-left: 77px;
    padding-left: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    width: 100%;

    .main_div {
      padding-bottom: 0;
      margin-bottom: 0;
      width: 100%;
    }

    .type_box_card_section {
      width: 100%;
      // .type_box_card_div.flex {
      //     display: flex;
      //     justify-content: flex-end;
      // }
    }

    .type-box {
      padding: 7px 16px;
      border: 2px solid #a8b6c2;
      background: #f3f3f3;
      -moz-border-radius: 7px;
      -webkit-border-radius: 7px;
      border-radius: 7px;
      background-clip: padding-box;
      padding: 12px 16px;
      min-width: 140px;
      max-width: 225px;
      width: 100%;
      line-height: 21px;
      box-sizing: border-box;
      display: inline-block;
      margin-right: 25px;
      margin-bottom: 20px;
      position: relative;
      cursor: pointer;

      .type-label {
        color: #6a6a6a;
        font-size: 12px;
        // margin-bottom: 5px;
        display: flex;
        justify-content: space-between;

        .icon_png_img {
          right: 0;
          left: 93%;
          bottom: 75%;
        }

        .info_img {
          width: 40px;
        }
      }

    }
    // .type-box:hover {
    //     border: 2px solid #008000;
    // }
    .menu_selected {
      label {
        font-size: 15px;
        font-weight: normal;
        margin-top: 5px;
      }
    }

    .selection_cell {
      .first_content {
        label {
          font-size: 15px;

        }
      }
    }

    .type-box-green {
      border: 2px solid #008000;
    }

    .watch-tooltip {
      position: absolute;
      right: 96%;
      top: 10%;
      height: auto;
      min-width: 45px;
      line-height: 30px;
      width: 45px;
      background-color: transparent;
    }
    // #label-in-front{
    //     .md-icon {
    //         border-radius: 50px;
    //         top: -21px;
    //         left: 30px;
    //         background-color: #a8b6c2;
    //         border: 1px solid #a8b6c2;
    //     }
    // }
    // #label-in-front.md-checked{
    //     .md-icon {
    //         background-color: #008000;
    //         border: 1px solid #008000;
    //     }
    // }
    // .inactive_checkbox{
    //     .md-icon {
    //         background-color: #a8b6c2;
    //         border: 1px solid #a8b6c2;
    //     }
    // }

    md-checkbox .md-icon:after,
    md-checkbox.md-checked .md-icon:after {
      border-color: white;
      position: absolute;
      left: 6.67px;
      top: 2.22px;
    }

    .selection_cell {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .inner_catalog_cell {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      .first_content {
        display: flex;
        margin-right: 0;
        max-width: 300px;
        width: 100%;
        margin-bottom: 15px;
      }
    }

    md-divider {
      margin-left: 0;
      padding-left: 0;
    }
  }

  .section7 {
    height: 600px;
    padding-bottom: 50px;

    .selection_cell {
      .inner_catalog_cell {
        display: grid;
      }
    }

    .type-box {
      background-color: transparent;

      .md-headline {
        font-size: 16px;
        font-weight: bold;
        line-height: 32px;
        color: #52758d;
      }
    }
    // .type-box:hover{
    //     border: 2px solid #008000;
    // }
    .type-box-green {
      border: 2px solid #008000;
      background-color: #ebf8e6;
    }

    .type-box {
      height: 365px;
      min-width: 250px;
      width: 100%;
      text-align: left;

      .md-card-image {
        height: 134px;
      }

      md-card-content {
        padding-bottom: 0;

        .icon_png_img {
          position: initial;
        }

      }

      img.icon_png_img {
        left: 95%;
        top: -5%;
        width: 35px !important;
        height: 35px;
      }

    }

    .card_cta {
      padding-left: 5px;
      padding-right: 20px;

      .md-button{
        max-width: 110px;
        width: 100%;
        min-width: 110px;
      }

      .icon-ico-add {
        margin-right: 10px;

        svg {
          path {
            fill: variables.$white;
          }
        }
      }

      .btn-secondary {
        background-color: transparent;
        color: #535355;
        border: 1px solid #a8b6c2;
      }

      .btn-secondary:hover {
        background-color: transparent;
        color: variables.$brand-primary;
        border: 1px solid variables.$brand-primary;
      }
    }
  }

  .menu_grid {
    margin-top: 20px;
  }

  .collapse_section {
    display: flex;
    flex-direction: row;

    .divider {
      display: inline-block;
      margin-right: 10px;
      // max-width: 900px;
      // width: 100%;
    }

    .show_less {
      display: inline-block;
      position: relative;
      bottom: 20px;
      max-width: 35px;
      width: 100%;
      background-color: black!important;
      border: 4px solid black!important;
    }
  }

  .product-tab-content2 {
    .section6 {
      height: auto;
    }
  }

  .blank_catalog {
    height: auto;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .show_less {
    width: 35px !important;
    height: 35px !important;

    .icon-down {
      margin-top: 10px;
      margin-left: 5px;
    }
  }

  .border-radius-50 {
    border-radius: 50px;
  }

  .mr-10 {
    margin-right: 10px;
  }

  img.icon_png_img {
    width: 25px !important;
    height: 25px;
    position: absolute;
    left: 65px;
  }

  .custom_icon_img {
    width: 30px;
  }

  .text_grey {
    color: #999999;
  }

  .text_white {
    color: variables.$white;
  }

  .text_blue {
    color: #20394c;
  }

  .font_normal {
    font-weight: normal;
  }

  .text_green {
    color: #6aa84f;
  }

  .font-normal {
    font-weight: normal;
  }

  .text_oceangreen {
    color: #63d1dd;
  }
}
.bordered{
  &.cart-cta{
    border: 1px solid variables.$brand-primary!important;
    color: variables.$brand-primary!important;
    md-icon {
      svg {
        path {
          fill: variables.$brand-primary!important;;
        }
      }
    }
  }
  &.cart-active {
    background: variables.$brand-primary!important;
    color:#ffffff!important;
    md-icon {
      svg {
        path {
          fill: #ffffff!important;;
        }
      }
    }
  }
 
}
@media (max-width:600px) {
  .product-details-custom {
    .detail-overview {
      display: grid;
    }

    .desc {
      .view_media {
        margin-bottom: 15px;
      }

      ul {
        .item-quantity-box {
          md-icon {
            width: 25px !important;
            height: 25px !important;
          }
        }
      }
    }

    .menu_grid {

      width: 245px;
    }

    .section2 {
      .heading {
        .ico_gap {
          position: absolute;
          top: -27px;
          left: 0;
          width: 40px;
        }

        h2 {
          font-size: 16px;
        }
      }

      .first_card {

        min-width: 290px;
        width: 100%;
        padding: 0;
        margin-bottom: 40px;
      }

      .second_card {
        .delivery_method {
          width: 100%;
        }

        .expected_delivery {
          width: 100%;
        }
      }

      .card_div {
        .first_row {
          md-icon {
            width: 20px;
          }
        }

        .content_row {
          display: inline-block;
          width: 80%;
          margin-bottom: 10px;
        }
      }
    }

    .section3 {
      .custom-default-box {
        .second_cell {
          margin-bottom: 45px;
        }
      }

      .add-opportunity.package_offer_section.add-items {
        margin-left: 0;
        display: grid;
      }

      h2 {
        color: #63d1dd;
        font-size: 14px;
      }

      .top-right-section {
        margin-top: 11px;

        label {
          .btn-primary {
            font-size: 11px;
          }
        }
      }

      .custom-default-box {
        .inner-cell.first_cell {
          min-width: 300px;
          width: 100%;
        }
      }
    }

    .section4 {
      padding-left: 0;
      height: 295px !important;

      .right-div {
        margin-top: 35px;
      }

      .catalog_menu_cell {
        span {
          font-size: 16px;
        }
      }

      .section-heading {
        padding-left: 0;
        margin-left: 0;

        h2 {
          font-size: 14px;
        }
      }

      .percent_des_label {
        font-size: 12px;
      }

      .percent_counter {
        width: 5px;
        height: 5px;
      }

      .menu_second_cell.second_cell {
        margin-left: 60px;
        gap: 20px;
        display: flex;
        justify-content: space-between;
      }

      .icon_img {
        width: 60px;
      }
    }

    .navigate_catalog {
      .icon_img {
        width: 50px !important;
        margin-top: 45px;
      }

      .right-div {
        label {
          font-size: 11px;
        }
      }

      .right_filter_list {
        .icon {
          md-icon {
            width: 20px;
          }
        }
      }

      .section-heading {
        h2 {
          margin-top: 60px !important;

        }
      }
    }

    .section5 {
      .main_div {
        padding-right: 0;
        margin-right: 0;
        padding-left: 0;
        margin-left: 0;
      }

      .catalog_cell {
        .inline-block {
          margin-right: 15px !important;
        }

        .inner_catalog_cell {
          margin-left: -60px !important;
          padding-left: 11px;
        }

        .outer_catalog_cell {
          .percent_counter {
            height: 60px;
            width: 60px;
          }
        }
      }

      .percent_counter {
        width: 19px;
        height: 19px;
        line-height: 1.7;
        font-weight: 600;
        font-size: 12px;
      }

      .sub-heading-title {
        h2 {
          margin-left: 90px;
        }
      }
    }

    .section6 {
      margin-left: 31px;
      height: auto!important;
      margin-bottom: 20px;
    }

    img.icon_png_img {
      width: 25px !important;
      height: 25px;
      position: absolute;
      left: 20px;
    }

    .section7 {
      .main_div {
        padding-left: 0;
        margin-left: 0;
      }
    }

    .main_meal_catalog {
      .section-heading {
        h2 {
          font-size: 20px;
        }
      }
    }

    .blank_catalog {
      height: 230px !important;

      .catalog_cell {
        .outer_catalog_cell {
          .percent_counter {
            bottom: 70px;
          }
        }
      }
    }
  }

}
@media (max-width:960px) {
  .product-details-custom {
    .section3 {
      .custom-default-box {
        margin-left: 0;

        .first_cell {

          min-width: 475px;
          width: 100%;
        }

        .second_cell {
          text-align: center;
          margin-left: 0;
          margin-right: 0;
          min-width: 300px;
        }

        .catalog_container_div {
          gap: 0;
        }

        .catalog_container2_div {
          gap: 0;
        }
      }
    }

    .section4 {
      height: 235px;
    }

    .main_meal_catalog {
      height: 250px !important;

      .sub-heading-title {
        h2 {
          margin-top: 0;
        }
      }
    }

    .section5 {
      height: 315px;

      .catalog_cell {
        .inner_catalog_cell {
          display: grid;
          margin-left: -95px;
        }

        .inline-block.first_content {
          margin-right: 50px;
        }

        .inline-block {
          margin-right: 38px;
        }
      }
    }

    .blank_catalog {
      height: 230px;
    }

    .section6 {
      .selection_cell {
        display: grid;
      }
    }

    .navigate_catalog {
      .second_cell {
        gap: 25px;
      }
    }
    // .product-tab-content2{
    //     .section6 {
    //         margin-bottom: 245px;
    //     }
    // }
  }
}
