@use "../../settings/third-party/mixins";
@use "../../settings/third-party/variables";
@use "../../settings/style-guide";
@use "../../settings/custom";

.product-details {
  md-content {
    label {
      @extend .f2;
      display: inline-block;
      // min-width: 80px;
    }

    md-divider {
      margin-bottom: 20px;
      margin-top: 20px;
    }

    .ico-heart {
      width: 10px;
      height: 10px;
      margin-left: 7px;
      margin-right: 7px;
    }

    .ico-pdf {
      width: 15px;
      height: 20px;
      margin-right: 15px;
      margin-top: -4px;
    }

  }

  .product-filter {
    .size {
      padding-left: 90px;

      label {
        position: absolute;
        left: 0;
        top: 4px;
      }

      md-checkbox {
        .md-label {
          margin-right: 5px;
          margin-top: 2px;
        }
      }
    }

    ul {
      li {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .product-color {
        label {
          vertical-align: top;
        }

        md-checkbox .md-container .md-ripple-container {
          top: -22px;
        }

        md-checkbox {
          .md-icon {
            margin-top: -5px;

            &:after {
              border-color: #fff;
            }
          }

          &.dark-brown {
            .md-icon {
              background: #443438;
              border-color: #443438;
            }
          }

          &.brown-light {
            .md-icon {
              background: #D1C6C9;
              border-color: #D1C6C9;
            }
          }

          &.brown {
            .md-icon {
              background: #844A1F;
              border-color: #844A1F;
            }
          }
        }
      }
    }

    .desc-info {
      .success {
        display: inline-block;
        color: variables.$brand-success;
        margin-bottom: 10px;
      }
    }
  }

  .icon-tick {
    height: 21px;
    margin-right: 10px;
    width: 21px;

    .tick-bg {
      fill: transparent;
    }
  }

  h3 {
    margin-top: 0;
    font-weight: normal;
  }

  .inventory-title {
    h3 {
      margin: 0 0 0 8px;
      font-size: 16px;

      md-icon {
        width: 25px;
        height: 22px;
        vertical-align: bottom;
      }
    }

    .ico-infi {
      width: 22px;
      height: 20px;

      svg path {
        fill: variables.$gray-light;
      }
    }

    .ico-bin {
      width: 20px;
      margin-left: 20px;
      height: 20px;

      svg path {
        fill: variables.$gray-light;
      }
    }
  }

  .service-info {
    ul {
      li {
        margin-bottom: 15px;
      }
    }
  }

  .cost-list {
    md-checkbox {
      margin-left: 30px;
      vertical-align: top;
    }

    .amt {
      vertical-align: top;
      display: inline-block;
      padding-top: 3px;
      line-height: 1;
      @extend .f1;
    }

    label {
      padding-top: 3px;
      vertical-align: top;
      line-height: 1;
    }
  }

  .detail-overview {
    .gallery {
      float: left;
      width: 142px;
      margin-right: 20px;

      .i-holder {
        border: 1px solid variables.$gray-light;
        border-radius: variables.$border-radius-small;
        overflow: hidden;
        width: 140px;
        height: 140px;
        text-align: center;
        position: relative;

        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          max-width: 100%;
          max-height: 100%;
          margin: auto;
        }
      }

      .thumb-items {
        position: relative;

        ul {
          overflow: hidden;
          white-space: nowrap;

          li {
            display: inline-block;
            border: 1px solid #cccccc;
            border-radius: variables.$border-radius-small;
            margin: 10px 2px 0 1px;
            overflow: hidden;
            position: relative;
            height: 42px;
            width: 42px;

            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
        }

        .img-movers {
          margin-top: 10px;
          text-align: center;

          .md-button {
            height: auto;
            min-height: auto;
            width: auto;
            padding: 0;
          }
        }

        .arrow {
          height: 16px;
          width: 16px;

          svg path {
            fill: variables.$brand-primary;
          }

          &.rarrow {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            transform: rotate(180deg);
          }
        }

        .add-img {
          position: absolute;
          right: -75px;
          top: 22px;

          a {
            display: inline-block;
            cursor: pointer;
          }
        }
      }
    }

    .desc {
      overflow: hidden;

      ul {
        h3,
        p {
          margin: 0;
          font-weight: normal;
        }

        li {
          margin-bottom: 15px;
        }

        del {
          @extend .f4;
        }
      }
    }
  }

  .ico-round-btn {
    height: 21px;
    width: 21px;

    svg {
      path {
        fill: variables.$gray-lighter;
      }
    }
  }
  /*--Md-side nav of Product Detail Page--*/
  .md-sidenav-right {
    .ico-cross {
      height: 22px;
      width: 22px;
      vertical-align: inherit;
    }

    .accordion-arrow {
      transform: rotate(180deg);
      height: 15px;
      width: 15px;

      &.down {
        transform: rotate(-90deg);
        transition: all 0.8s;
      }

      svg {
        path {
          fill: variables.$gray-light;
          stroke: variables.$gray-light;
        }
      }
    }

    .sub-items {
      .item-table {
        width: 96%;

        th {
          @extend .f4;
          padding: 10px 0;
          text-align: left;
          font-weight: normal;

          &:nth-child(3) {
            @extend .text-center;
          }
        }

        td {
          text-align: left;
          padding: 10px 0;
          @extend .f3;

          &:nth-child(3) {
            @extend .text-center;
            @extend .f4;
          }

          .fix-string {
            max-width: 90px;
          }
        }
      }
    }

    .status-dropmenu {
      md-checkbox {
        &:after {
          border: 0;
        }

        &:before {
          border: 0;
        }
      }
    }

    .qty-total {
      margin-left: 9px;
    }
  }

  md-checkbox {
    margin: 0 0 5px;

    &:last-child {
      margin-bottom: 0;
    }
    @extend .f1;

    .md-container {
      height: 21px;
      width: 21px;
    }
  }

  .qty-btns {
    height: auto;
    min-height: auto;
    padding: 0;
    width: auto;
  }
}

.product-detail-sidenav {
  .bottom-section,
  .top-section {
    position: absolute;
    right: 0;
    left: 0;
  }

  .top-section {
    top: 0;
    bottom: 50px;
    overflow-y: auto;
    padding-right: 18px;
    padding-left: 18px;

    &.prod-top-section {
      padding: 0 !important;

      .head-section {
        background-color: variables.$gray-base;
        padding: 12px 18px;

        .icon-button {
          &.edit-icon-button {
            height: 15px;
          }
        }

        h1 {
          color: variables.$white-darker;
          font-weight: bold;
          font-size: 18px;
        }
      }

      .product-sidebar-accordion {
        .panel-group {
          margin: 0;

        }

        .panel,
        .panel.panel-open {
          .panel-heading {
            background: #a8b6c2ff;

          }

          .panel-title > a {
            color: variables.$white;
            font-size: 14px;
            font-weight: bold!important;
          }
        }

        .package-pro {
          ul {
            padding: 15px;
            margin: 0;

          }

          li {
            padding: 0;
            background-color: #f2f6f7;
            // box-shadow: #949494 0px 1px 3px -1px;
          }
        }
      }

      .arrow,
      .heading-icon,
      .icon-info {
        svg {
          path,
          polygon {
            fill: variables.$white;
          }
        }
      }
    }
  }

  .bottom-section {
    bottom: 0;

    .main-layer {
      background: variables.$brand-accent;
      color: variables.$white;
      height: 50px;
      padding-right: 20px;
      padding-left: 20px;
    }

    .hidden-layer {
      display: none;
      background: variables.$white !important;
      position: absolute;
      bottom: 100%;
      left: 2px;
      right: 2px;
      padding: 18px;
      z-index: 2;
    }

    .total-lbl {
      font-size: variables.$font-size-base;
    }

    .total-value {
      font-size: 16px;
      font-weight: bold;
      vertical-align: middle;
    }

    .icon-info {
      // height: 18px;
      // margin-right: 10px;
      // width: 18px;
      width: 30px;
      height: 30px;

      svg path {
        fill: variables.$white;
      }
    }
  }

  .heading-icon {
    height: 21px;
    margin-right: 14px;
    width: 21px;

    svg {
      path,
      polygon {
        fill: variables.$gray-dark;
      }
    }
  }

  .head-section {
    &.min-length {
      min-height: 60px;
    }
    padding-top: 12px;
    padding-bottom: 12px;

    h1 {
      color: variables.$gray-darker;
      font-size: variables.$font-size-base;
      font-weight: normal;
      margin: 0;
    }

    .icon-button {
      margin-left: 0;
      margin-right: -8px;

      md-icon {
        height: 20px;
        width: 20px;
      }

      &.active md-icon {
        svg path {
          fill: variables.$brand-primary;
        }

        &.icon-info {
          svg path {
            fill: variables.$gray;
          }
        }
      }
    }
  }

  .hidden-layer {
    h2 {
      margin-top: 2px;
      margin-bottom: 18px;
    }

    .icon-button {
      margin-top: -6px;
      margin-right: -8px;

      md-icon {
        height: 16px;
        width: 16px;
      }
    }

    .billing-breakup {
      margin-top: 18px;

      li:not(:last-child) {
        margin-bottom: 18px;
      }
    }
  }

  .icon-button {
    height: auto;
    min-width: auto;
  }

  .total-section {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .catalog-divider {
    margin-left: -16px;
    margin-right: -16px;
  }
}

.product-sidebar-accordion {
  .arrow {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    transform: rotate(-180deg);

    &.open {
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
  }

  .panel-group {
    @include mixins.border-radius(0);
    border: none;
    margin-right: -16px;
    margin-bottom: -1px;
    margin-left: -16px;

    .panel {
      background: transparent;

      &:not(:last-child) {
        border-color: rgba(0,0,0,0.12);
      }

      &,
      &.panel-open {
        .panel-heading {
          background: transparent;
        }
      }
    }

    .panel-title > a {
      font-size: variables.$font-size-small;
    }
  }
}

.catalog-product-list {
  margin: 10px 18px;

  &,
  .product-content-list {
    @extend .no-style;
  }

  md-checkbox {
    .md-container {
      background: variables.$white;
    }
  }

  .icon-arrow {
    height: 11px;
    margin-right: 12px;
    width: 19px;
  }

  .header-section {
    @extend .f3;

    md-checkbox {
      margin-bottom: 12px;
      top: -1px;
      left: 12px;
    }
  }

  .text-content {
    @extend .ellipsis;
  }

  .product-img-container {
    @include mixins.border-radius(50%);
    background: variables.$white;
    border: 1px solid variables.$border-color;
    height: 44px;
    margin-right: 10px;
    min-width: 44px;
    position: relative;
    width: 44px;

    .icon-tick {
      height: 16px;
      margin-right: 0;
      position: absolute;
      top: -2px;
      right: -2px;
      width: 16px;

      .tick-bg {
        fill: variables.$white;
      }
    }

    img {
      height: 20px;
      width: 32px;
    }
  }

  li {
    // &:not(:last-child) {
    .product-detail {
      margin-bottom: 10px;
    }
    // }
  }

  .product-detail {
    line-height: 20px;

    .sub-text {
      color: variables.$gray;
      font-size: 10px;
    }

    .text-content {
      max-width: 110px;
    }
  }

  .catalog-title {
    @extend .f4;
    font-weight: normal;
    margin-top: 10px;
    margin-bottom: 8px;
  }
}

.catalog-product-list.list-view2 {
  li {
    &:not(:first-child) {
      margin-top: 25px;
    }

    .product-content-list li {
      margin-top: 0;
    }
  }

  .inclusive-title {
    margin-right: 30px;
  }

  .line-connector {
    &:not(:last-child) {
      border-left: 1px solid variables.$gray-lighter;
    }
    margin-left: 22px;
    position: relative;

    .title-gap {
      margin-left: 30px;
    }

    .product-img-container {
      position: absolute;
      left: -23px;
      z-index: 1;
    }

    .product-detail {
      margin-left: 30px;
    }
  }
}

.product-content-list {
  li {
    color: variables.$gray-dark;
    font-size: 10px;
  }

  .content-icon-box {
    height: 25px;
    margin-right: 6px;
    width: 25px;

    &.mandatory {
      md-icon svg path:not(.icon-bg) {
        fill: #AA79DB;
      }
    }
  }

  .text-content {
    max-width: 90px;
  }

  .icon-button {
    margin-right: -12px;
    margin-left: 0;

    md-icon {
      height: 16px;
      width: 16px;
    }
  }
}

.status-dropmenu.catalog-dropdown {
  min-width: 170px;
  padding: 12px;
  width: 170px;

  &:before {
    border-width: 14px;
    margin-right: 61px;
  }

  &:after {
    border-width: 13px;
    margin-right: 62px;
  }
}

.item-desc {
  max-width: 200px;
  display: inline-block;
}

.coupon-label-steps {
  overflow: hidden;

  li {
    display: inline-block;
    vertical-align: top;
    border: 1px solid variables.$white-darker;
    background-color: variables.$white-dark;
    font-size: 10px;
    width: 117px;
    //height: 100px;
    margin-right: 70px;
    text-align: center;
    position: relative;
    padding: 10px;

    &:after,
    &:before {
      left: 100%;
      top: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(136, 183, 213, 0);
      border-left-color: variables.$white-dark;
      border-width: 58px;
      margin-top: -58px;
    }

    &:before {
      border-color: rgba(194, 225, 245, 0);
      border-left-color: variables.$white-darker;
      border-width: 60px;
      margin-top: -60px;
    }

    .remain-count-label {
      padding: 5px;
    }

    .remain-count {
      @include mixins.border-radius(50%);
      border: 1px solid variables.$gray;
      padding: 0 6px;
      line-height: 22px;
      margin-right: 5px;
      height: 25px;
    }

    .c-text {
      //height: 55px;
      overflow: hidden;

      .inner-container {
        display: table-cell;
        //height: 62px;
        padding: 5px;
        text-align: center;
        vertical-align: middle;
        width: 1%;
      }
    }

    .ico-selected {
      position: absolute;
      top: 5px;
      left: 5px;
      height: 18px;
      width: 18px;

      .tick-bg {
        fill: transparent;
      }
    }

    .coupon-dot {
      display: inline-block;
      height: 10px;
      width: 10px;
      border-radius: 100%;
      border: 1px solid variables.$white-darker;
      position: absolute;
      right: -11px;
      top: 50%;
      z-index: 5;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }

    &.active {
      border: 1px solid variables.$brand-primary;

      &:before {
        border-left-color: variables.$brand-primary;
      }
    }
  }
}

.historic-serials {
  .serial-search {
    border: 1px solid variables.$gray-light;
    width: 370px;
    height: 60px;

    .search-block {
      width: 350px;

      .input-line {
        border: 0;
      }
    }
  }

}

.inventory-table {
  .data-table {
    td {
      @extend .f3;
    }

    .tot-serial {
      min-width: 45px;
      text-align: center;
      padding: 2px 0 4px;
      font-size: 14px;
      font-weight: bold;
      background-color: variables.$text-navy-blue;
      color: #fff;
      border-radius: 1px;
    }

    .col-100 {
      min-width: 100px;
      max-width: 100px;
    }

    .col-200 {
      min-width: 200px;
      max-width: 200px;
    }

    .col-150 {
      min-width: 150px;
      max-width: 150px;
    }

    .col-120 {
      min-width: 120px;
      max-width: 120px;
    }

    .location-symbol {
      margin-left: 0;
      margin-right: 5px;
    }

    .stock-location {
      display: inline-block;
    }

    .item-qty-cell {
      text-align: center;
      vertical-align: middle;
    }

    .add-type {
      @extend .f3;
      margin-top: 10px;
      text-align: center;
    }
  }
}
/*--Product Page pop pups---*/
.clear-inventory {
  height: 340px;
  width: 510px;

  p {
    max-width: 250px;
    margin-bottom: 25px;
    line-height: 1.8;
  }

  md-backdrop {
    display: block;
  }
  @media only screen and (max-width: 600px) {
    height: 300px;
    width: 600px;
  }
}
/********/
@media only screen and (max-width: 600px) {
  .product-details .detail-overview .gallery {
    float: none;
    margin-bottom: 30px;
  }
}

.custom-flag {
  .flag {
    margin-bottom: 10px;

    md-icon {
      margin-right: 5px;
    }
  }

  .active {
    color: variables.$brand-accent;
  }

}

.bordered.cart-active {
  border-color: variables.$brand-accent;
  color: variables.$brand-accent !important;
}

.cart-active {
  md-icon svg path {
    fill: variables.$brand-accent;
  }
}

.sku-id-col {
  min-width: 130px;
}

.sku-name-col {
  min-width: 345px;
}

.work-order {
  .wo-status {
    border: 1px solid variables.$status-yellow;
    color: variables.$status-yellow;
    border-radius: 20px;
    padding: 3px 10px;
    min-width: 95px;
    text-align: center;

    &:hover {
      color: #fff;
      background-color: variables.$status-yellow;
    }

    &.status-completed {
      border-color: variables.$status-green;
      color: variables.$status-green;

      &:hover {
        color: #fff;
        background-color: variables.$status-green;
      }
    }
  }
}

.wo-status-sidenav {
  .btn-wo-secondary {
    background: #fff;
    border: 1px solid variables.$brand-primary;
    min-height: 36px;
    line-height: 18px;
    color: variables.$brand-primary;
  }

  .wo-status-header {
    background-color: #000;
  }
}
