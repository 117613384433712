@use "../../settings/third-party/mixins";
@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.import-inventory-section {
  position: relative;

  .import-box {
    position: absolute;
    width: 500px;
    max-width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  .inner {
    @include mixins.border-radius(5px);
    background: variables.$white-dark;
    border: 1px dashed variables.$border-color;
    padding: 40px 15px;
    position: relative;
    text-align: center;

    .top-section {
      margin-bottom: 15px;

      .icon-upload {
        height: 37px;
        margin-right: 10px;
        width: 37px;
      }

      h1 {
        color: variables.$gray-dark;
        font-weight: normal;
        margin: 0;
      }
    }

    .bottom-gap {
      margin-bottom: 10px;
    }
  }

  .file-selector {
    @extend .f8;
    @extend .PosR;
    @extend .cursor-point;

    input[type="file"] {
      position: absolute;
      left: -99999px;
    }
  }

  .template-link {
    color: variables.$brand-primary;
    font-size: variables.$font-size-base;
    margin-top: 6px;
  }
}

.importInventory-tabSection {
  padding: 20px 25px;

  .status-displayer {
    margin-top: 0;
    margin-bottom: 20px;

    .main-holder {
      &.active {
        .progress-circular-holder {
          &:after {
            border-color: variables.$border-color;
          }
        }
      }

      &:after,
      &:before {
        top: 34%;
      }
    }

    .displayer-title,
    .progress-circular-holder {
      cursor: pointer;
    }

    .progress-circular-holder {
      height: 72px;
      width: 72px;

      md-progress-circular {
        height: 70px !important;
        width: 70px !important;

        svg {
          height: 72px !important;
          width: 72px !important;
        }

        .md-inner {
          height: 144px;
          width: 144px;

          .md-gap {
            left: 72px;
            right: 72px;
          }

          .md-left,
          .md-right {
            height: 144px;
            width: 71px;

            .md-half-circle {
              width: 144px;
              height: 144px;
            }
          }
        }
      }

      .progress-number {
        color: variables.$gray-dark;
        font-size: 16px;
      }
    }
  }

  md-checkbox .md-icon {
    background-color: variables.$white;
  }

  md-radio-button .md-label {
    @extend .f8;
  }

  .data-table {
    &.standard-table {
      min-width: 985px;
    }

    td {
      @extend .f3;

      md-checkbox {
        margin-top: 4px;
      }
    }

    .stock-location {
      .location-symbol {
        margin-left: 6px;
      }

      .more-link {
        text-align: right !important;
      }
    }

    &.duplicate-sku-table {
      @include mixins.border-radius(4px);
      border: 1px solid #c1cbcd;

      tr:last-child td {
        border-bottom: none;
      }

      td {
        border-bottom: 1px solid #c1cbcd;
        font-size: 10px;
        line-height: 16px;
        vertical-align: middle;
        width: 50%;

        &.highlight-cell {
          background: variables.$brand-table;
        }

        &:last-child {
          border-left: 1px solid #c1cbcd;
        }
      }

      .product-img,
      .product-no-img {
        margin-right: 20px;
      }

      md-radio-button {
        margin-bottom: 0;

        .md-label {
          margin-left: 20px;
        }
      }

      .product-img {
        width: 62px;
        height: 40px;
      }

      .product-no-img {
        background: variables.$white;
        height: 55px;
        width: 65px;

        &.small-pic {
          height: 38px;
        }

        md-icon {
          height: 34px;
          margin-top: 8px;
          width: 34px;
        }
      }

      .product-id {
        font-size: variables.$font-size-base;
        font-weight: 500;
        margin: 0 0 10px;
      }

      .icon-edit {
        height: 12px;
        margin-top: 0;
        margin-left: 8px;
        width: 12px;
      }

      .common-gap {
        min-width: 55px;
        margin-right: 10px;
      }

      .spec-lbl {
        color: variables.$gray;
        margin-right: 8px;
        padding-right: 0;
      }
    }
  }

  .edit-button {
    @include mixins.buttonIcons(27px);
    border: 1px solid variables.$gray-light;
    margin-left: 0;
    padding: 7px;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

.inventory-product-view {
  .right-icon {
    height: 15px;
    width: 15px;
  }

  .old-price {
    text-decoration: line-through;
  }

  .active {
    color: variables.$status-green;
  }

  .section-gap {
    ul {
      width: 100%;
    }
  }
}
@media screen and (max-width: 600px) {
  .import-inventory-section {
    .import-box {
      width: 90%;
    }
  }
}
