@use "../../settings/third-party/mixins";
@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.service-history-section {
  padding: 30px 20px;

  .dark-color {
    color: variables.$gray-darker;
  }

  .no-padding {
    padding: 0;
  }

  .no-margin {
    margin: 0;
  }

  .no-br {
    border-radius: 0;
  }

  .no-top-br {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .no-bottom-br {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .default-card-box {
    margin-bottom: 30px;
  }

  .cost-summary-section,
  .message-section,
  .service-detail-section {
    margin-bottom: 20px;
  }

  .service-quote-detail {
    margin-bottom: 20px;

    .gap-right {
      margin-right: 40px;
    }

    .id-no {
      @extend .dark-color;
      font-size: variables.$font-size-base;
    }
  }

  .data-table {
    .big-label {
      @extend .fm;

      font-size: variables.$font-size-base;
    }

    td {
      @extend .f3-light;
    }

    .total-amt {
      @extend .fm;
      @extend .dark-color;

      font-size: 16px;
      margin-left: 8px;
    }

    .amt-detail {
      @extend .f4;

      margin-left: 4px;
      position: relative;
      top: -1px;
    }

    &.business-detail-table {
      margin-bottom: 20px;

      .bottom-gap {
        margin-bottom: 10px;
      }
    }

    &.service-info-table {
      td {
        width: 50%;
      }

      .service-label {
        margin-bottom: 7px;
      }
    }

    &.charge-details {
      tbody td {
        height: 75px;
      }
    }
  }

  .note {
    @extend .f4;

    letter-spacing: 0.24px;
    line-height: 18px;
    margin-top: 20px;
  }

  .message-section {
    p {
      margin-bottom: 0;
    }
  }

  .cost-summary-section {
    @extend .no-padding;

    .data-table {
      @extend .no-margin;

      .summary-amt {
        text-align: right;
        padding-right: 35px;
      }

      .total-amt {
        margin-left: 0;
      }

      .charge-dates {
        font-style: italic;
        padding-left: 40px;
      }

      .dist-list {
        li {
          font-style: italic;
          margin-top: 10px;
        }

        .gap-left {
          margin-left: 15px;
        }
      }
    }
  }

  .tnc-section {
    .arrow {
      height: 14px;
      width: 14px;
      float: right;
      position: relative;
      top: 2px;
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
      transition: all 0.2s linear;

      svg path {
        fill: variables.$brand-primary;
      }

      &.open {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
      }
    }
  }

  .signature-section {
    h4 {
      @extend .dark-color;

      font-weight: normal;
      margin-top: 0;
      margin-bottom: 10px;
    }

    .signing-panel {
      @include mixins.border-radius(5px);

      border: 1px solid variables.$border-color;
      height: 100px;
      margin-bottom: 10px;
    }

    .date-picker {
      md-datepicker {
        position: relative;

        .md-datepicker-button {
          background: variables.$white;
          height: 24px;
          min-height: 24px;
          padding: 0;
          position: absolute;
          right: 2px;
          top: -5px;
          z-index: 1;
        }
      }

      .md-datepicker-input-container {
        margin-left: 0;
        width: 165px;
      }
    }
  }
}

@media screen and (min-width: 600px) {
  .service-history-section {
    .signature-section .gap {
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: 600px) {
  .service-history-section {
    .service-quote-detail {
      label {
        margin-bottom: 10px;
      }

      .id-no {
        line-height: 14px;
      }
    }

    .signature-section {
      .gap {
        margin-bottom: 20px;
      }
    }
  }
}
