@use "../../settings/third-party/variables";

.sms-message {
  .text-white {
    color: #fff!important;
  }

  .text_orange {
    color: rgb(248, 149, 29);
  }

  .desc {
    display: flex;
  }

  .accepting_cards {
    margin: 0;

    .chat-list {
      min-width: 375px;
      background: #ffffffff;
      padding: 0 20px;
      box-shadow: #949494 0 3px 5px 0;

      .icon_img {
        margin: 0 15px 0 0!important !important;
      }

      .proflie_section {
        h1 {
          color: #395a7a;
          font-size: 20px;
          margin-top: 7px;
        }

        p {
          color: #3d9a83;
          margin-top: 10px;
        }
      }
    }

    .icon-search-container {
      .search-input {
        min-width: 340px;
      }
    }

    .chats:hover {
      background-color: #61c5c338;
    }

    .chats.active {
      border: 1px solid #3d9a83;
      overflow: visible;

      .chat-div {
        .text-label-desc.active {
          color: #3d9a83;
        }
      }

      .bell-icon {
        position: absolute;
        bottom: 78px;
        left: 95%;
        width: 25px;
        z-index: 999999;

        svg {
          g {
            circle {
              fill: #3d9a83;
            }

            path {
              fill: #ffffff;
            }
          }

        }
      }
    }

    .chats {
      width: 100%;
      background-color: #f3f3f3ff;
      padding: 15px 10px;
      border-radius: 10px;
      margin-bottom: 15px !important;

      .icon_img {
        // margin-right: 15px;
        margin: 10px 15px 0 0;
        width: 60px;
        height: 60px;
      }

      .chat-div {
        width: 100%;
        line-height: 1.9;
        //line-height: inherit;
        .text-label-desc {
          font-size: 13px;
          color: #999999;
        }
      }

    }

    .heading_title {
      background: #ffffffff;
    }

    #content {
      display: contents;
    }

    .chat-section {
      background: url("#{variables.$image-path}/Chat-Background.jpg") no-repeat 0 0;
      background-position: 0 0;
      background-size: cover;

      .chats {
        .chat-div {
          .text-label-desc.chat-text-label-desc {
            word-break: break-all;
            width: 265px;
            white-space: wrap;
            color: #000000DE;
            line-height: 1.6;
          }

          .chat-text-label-heading {
            color: #999999;
            cursor: pointer;
          }

          .p-date-tag {
            position: relative;
            top: 7px;
          }
        }

      }

      .input_chat_div:hover {
        md-icon.copy-quote-icon {
          display: block;
        }
      }

      .input_chat_div {
        md-icon.copy-quote-icon {
          display: none;
        }
      }

      .custom-default-box {
        background-color: #395a7aff;
        padding: 5px 15px;
        margin-bottom: 20px;

        .icon_img {
          width: 50px;
          margin: 7px 25px 0 0;
          height: auto;
        }
      }

      .chat-div {
        line-height: inherit;
      }

      .icon_png {
        svg {
          g {
            path {
              fill: #ffffffff!important;
            }
          }
        }
      }

      .search-without-border {
        margin-top: 5px !important;
      }

      .forward-icon {
        width: 15px;
      }

      .icon_png {
        svg {
          path {
            fill: #ffffffff!important;
          }
        }
      }

      .chat-profile-name {
        margin-right: 80px;
        line-height: 0.2;

        .email_title {
          margin-right: 15px;
          margin-top: 10px;
          line-height: 1;
        }

        p {
          margin-bottom: 5px;
          margin-top: 5px;
        }
        @media (max-width:600px) {
          margin-right: 60px;
        }
      }

      .img-grid-list {
        display: flex;
        justify-content: flex-end;
        padding-top: 16px;

        .icon_png {
          margin: 0 10px 0 0;
        }
      }

      .insert-action {
        .img-grid-list {
          md-icon {
            margin-bottom: 10px;
            width: 40px;
            height: 40px;
          }
        }

        .insert-section {
          background-color: #000000;
          color: variables.$white;
          margin-right: 20px;
          padding: 5px 0;

          .insert-div {
            min-width: 400px;
            width: 100%;
            max-width: 600px;
            align-items: center;
            padding-left: 25px;
            @media (max-width:600px) {
              align-items: start;
              grid-gap: 10px;
              padding: 10px 0 10px 5px;

            }

            .add-email {
              margin-right: 25px;
            }
          }

          .cta-div {
            align-items: center;
            display: flex;
          }

          .ico-plus {
            margin-right: 10px;
          }

          .insert_content {
            padding-top: 5px;
          }

          .ico-white {
            svg {
              path {
                fill: variables.$white;
              }
            }
          }

        }
      }
      @media (max-width:600px) {
        overflow-x: hidden;
        margin-bottom: 65px !important;
      }

    }

    .input_chat_section.end {
      display: flex;
      justify-content: flex-end;
      padding-right: 20px;

      .chats {
        background-color: #427675ff;
        border-bottom-right-radius: 0;

      }
      @media (max-width:600px) {
        flex-direction: row;
      }
    }

    .input_chat_section.start {
      display: flex;
      justify-content: flex-start;
      padding-left: 20px;

      .chats {
        border: 2px solid #427675ff;
        background-color: variables.$white;
        border-bottom-left-radius: 0;

      }
      @media (max-width:1200px) {
        flex-wrap: wrap;

      }
      @media (max-width:600px) {
        flex-direction: row;
        padding-left: 0;
        padding-right: 20px;
      }
    }

    .input_chat_section {
      .quote-id {
        .icon_img {
          width: 25px;
          margin-top: 5px;
          height: 25px;
        }

        p {
          color: #ff9900!important;
        }

        .quote-icon {
          margin: 7px 10px 0 0!important !important;
          bottom: 0;
        }
      }
    }

  }

  .no-marg-top {
    margin-top: 0 !important;
  }

  .flex {
    display: flex;
    width: 100%;
  }

  .accepting_cards_content {
    padding: 0;
  }

  .back-btn {
    min-width: 25px;
  }

  .chat-unreads-div {
    margin-bottom: 10px;
  }
}
