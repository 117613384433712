.aliases-template{
    .section-heading{
        .aliases-icon{
            md-icon{
                margin-right: 15px;
            }
        }
        .aliases-heading{
            .title{
                margin: 0px 0px 7px;
                font-size: 18px;
            }
        }
    }
    .quote-alias{
        margin-left: 35px;
    }
    .label-key{
        color: #a6a6a6;
    }
   
}