@use "../../settings/third-party/mixins";
@use "../../settings/third-party/variables";
@use "../../settings/style-guide";
@use "../../settings/custom";

.promotion-section {
  padding: 10px 20px;

  .top-section {
    margin-bottom: 18px;
  }

  .small-lbl {
    color: variables.$gray;
    font-size: 10px;
  }

  .big-lbl {
    color: variables.$gray-dark;
    font-size: 16px;
  }

  .form-base {
    md-input-container {
      margin-bottom: 0;

      md-select {
        width: 145px;
      }
    }
  }

  .coupon-list {
    @extend .no-style;

    li {
      @include mixins.border-radius(5px);
      background: variables.$white-dark;
      display: inline-block;
      margin-bottom: 20px;
      margin-right: 20px;
      padding: 20px 12px;
      position: relative;
      width: 22.5%;
      max-width: 100%;
    }

    .gap-right {
      margin-right: 4px;
    }

    .gap-left {
      margin-left: 15px;
    }

    .bottom-gap-xs {
      margin-bottom: 10px;
    }

    .bottom-gap-sm {
      margin-bottom: 20px;
    }

    .coupon-status,
    .menu-btn {
      position: absolute;
    }

    .menu-btn {
      top: 9px;
      right: 12px;
    }

    .coupon-status {
      top: 12px;
      left: 12px;

      &.active {
        @extend .text-success;
      }

      &.expired {
        @extend .text-danger;
      }
    }

    .icon-coupon {
      height: 34px;
      margin-bottom: 15px;
      width: 34px;
    }

    .title {
      @extend .f3;
      @extend .fm;
      @extend .bottom-gap-sm;
      margin-top: 0;
      @extend .ellipsis;
      width: 100%;
    }

    .coupon-descp {
      margin-top: 6px;

      label {
        @extend .small-lbl;
        margin-bottom: 5px;
      }
    }
  }
}
@media screen and (min-width: 769px) {
  .promotion-section {
    .coupon-list li {
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .promotion-section {
    .coupon-list li {
      width: 30.5%;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .promotion-section {
    .coupon-list li {
      width: 100%;
      margin-right: 0;
    }
  }
}
