@use "../../settings/third-party/variables";
@use "../../settings/style-guide";
@use "../../settings/custom";

.savings-section {
  ul {
    &.col-2-list {
      width: 70%;
    }
  }
}

.savings-sidenav {
  min-width: 0;
  max-width: 300px;
  width: 300px;
  position: fixed;

  .bottom-section {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 85px;
  }

  .leftnav-wrap {
    height: calc(100% - 143px);

    .sidebar-title {
      @extend .form-base;
      margin: 16px 8px 8px;

      .md-block {
        width: 100%;
      }

      h2 {
        margin: 0;
        font-weight: normal;
        @extend .f1;
      }
    }

    ul.item-list {
      padding: 0;
      margin: 0;

      li {
        @extend .cursor-point;
        padding: 10px 8px;

        &.active {
          background-color: variables.$brand-info-light;

          .img-container .verify {
            display: block;
          }
        }

        .img-container {
          height: 42px;
          width: 42px;
          display: inline-block;
          position: relative;
          margin-right: 10px;

          img {
            max-width: 42px;
            border-radius: 50%;
            height: 42px;
          }

          .verify {
            width: 16px;
            position: absolute;
            top: -7px;
            right: 3px;
            display: none;
          }
        }

        .item-detail {
          display: inline-block;
          font-size: variables.$font-size-base - 2;
          color: variables.$gray-base;

          span {
            color: variables.$gray;
          }
        }
      }
    }
  }

  .savings-cost-list {
    md-list-item {
      min-height: auto;
      padding: 8px 16px;

      span {
        @extend .fm;
      }
    }
  }
}
/*-- Edit Saving Page Css--*/
.edit-savings-section {
  .sidebar-toggle-btn {
    position: absolute;
    right: 0;
  }

  .promotion-type {
    margin-bottom: 35px;
  }
  // .section-title {
  //   font-weight: normal;
  //   @extend .f1;
  //   margin-top: 14px;
  //   margin-left: 5px;
  //   margin-bottom: 0;
  // }

  md-divider {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .discount-amt {
    margin-top: 20px;

    label {
      display: inline-block;
      @extend .f4;
      margin-right: 50px;
    }
  }

  .content-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .form-base {
    margin-bottom: 0;

    md-input-container {
      margin-right: 10px;

      input {
        min-width: 245px;
      }
    }

    .md-button {
      text-transform: capitalize;
      min-width: 115px;
    }
  }

  .coupon-label-steps {
    overflow: hidden;

    li {
      @extend .cursor-point;
      display: inline-block;
      vertical-align: top;
      border: 1px solid variables.$white-darker;
      background-color: variables.$white-dark;
      width: 185px;
      height: 120px;
      text-align: center;
      position: relative;
      margin: 0 70px 20px 0;

      &.active {
        .verfication-icon {
          display: block;
        }
      }

      .verfication-icon {
        position: absolute;
        left: 5px;
        top: 5px;
        display: none;
        height: 15px;
        width: 15px;
      }

      .combination-apply {
        position: absolute;
        right: 5px;
        top: 5px;
      }

      &:after,
      &:before {
        left: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }

      &:after {
        border-color: rgba(136, 183, 213, 0);
        border-left-color: variables.$white-dark;
        border-width: 59px;
        margin-top: -59px;
      }

      &:before {
        border-color: rgba(194, 225, 245, 0);
        border-left-color: variables.$white-darker;
        border-width: 60px;
        margin-top: -60px;
      }

      .c-text {
        @extend .content-center;
        width: 185px;

        .pro-percent {
          font-weight: bold;
          font-size: variables.$font-size-medium;
        }

        .pro-for,
        .pro-name {
          font-size: variables.$font-size-base;
        }

        .pro-for {
          color: variables.$gray;
        }

        .remain {
          font-size: variables.$font-size-base;
          margin-top: 5px;
          color: variables.$gray;

          .remain-count {
            position: relative;
            width: 30px;
            height: 30px;
            border: 1px solid variables.$gray;
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            border-radius: 50%;

            .count {
              @extend .content-center;
              font-size: variables.$font-size-small - 2;
            }
          }
        }
      }

      .coupon-dot {
        display: inline-block;
        height: 10px;
        width: 10px;
        border-radius: 100%;
        border: 1px solid variables.$white-darker;
        position: absolute;
        right: -11px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 5;
      }

      span {
        &.status {
          position: absolute;
          bottom: -16px;
          font-size: 12px;
          left: 70px;
        }

        &.top-status {
          position: absolute;
          left: 23px;
          top: 8px;
          font-size: 8px;
        }
      }

      span.status,
      span.top-status {
        &.active {
          color: variables.$brand-success;
        }

        &.not-allow {
          color: variables.$brand-primary;
        }

        &.error-show {
          color: variables.$brand-danger;
        }
      }

      &.active {
        border: 1px solid variables.$brand-success;

        &:before {
          border-left-color: variables.$brand-success;
        }

      }

      &.not-allow {
        border: 1px solid variables.$brand-primary;

        &:before {
          border-left-color: variables.$brand-primary;
        }
      }

      &.error {
        border: 1px solid variables.$brand-danger;

        &:before {
          border-left-color: variables.$brand-danger;
        }
      }
    }
  }
}

.promotion-info {
  @extend .summery-widget;
  // margin-top: 60px;
  max-width: 400px;
  min-width: 260px;

  .pro-info {
    font-size: variables.$font-size-medium;

    span {
      color: variables.$gray;
    }
  }
}
@media only screen and (max-width: 960px) {
  .promotion-info {
    max-width: 100%;
    // margin-top: 0;
  }
}
/* Edit Saving Page edit */
