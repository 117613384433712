@use "../../settings/third-party/variables";

.structure-product-list {
  padding: 18px;

  md-tabs md-content {
    padding-top: 15px;

    .data-table {
      margin-top: 25px;

      td {
        font-size: variables.$font-size-small;
      }
    }
  }

  .select-by-view {
    md-input-container {
      width: 100%;
      max-width: 112px;
      margin: 0;

      md-select md-select-value span {
        padding-left: 10px !important;
      }

    }

  }
}
