@use "../../settings/third-party/mixins";
@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.proposal-container {
  padding: 25px 30px;
  box-sizing: border-box;

  .slide-rating {
    .range-slider {
      display: inline-block;
      vertical-align: top;
      width: 120px;
      margin-left: 35px;
      margin-top: -16px;
    }
  }

  .customer-name {
    .box {
      max-width: none;
      margin-left: 15px;
    }
  }

  .due-date {
    max-width: 180px;
  }

  .attach-file-section {
    margin-top: 25px;
  }

  md-icon.icon-ico-add {
    margin-right: 8px;
  }

  .attachment-chip {
    display: inline-block;
    padding: 6px 10px;
    position: relative;
    text-align: left;
    background: variables.$white-darker;
    min-width: 100px;
    margin-bottom: 15px;
    border-radius: 3px;
    @extend .f3;

    md-icon {
      width: 15px;
      height: 18px;
    }

    .icon-cross {
      display: inline-block;
      border-left: 2px solid variables.$gray-light;
      position: absolute;
      right: 8px;
      bottom: 6px;
      top: 6px;

      md-icon {
        width: 10px;
        height: 10px;
        position: relative;
        top: 4px;
        margin-left: 10px;

        svg {
          path {
            fill: variables.$gray;
          }
        }
      }
    }

    span {
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
    }
  }

  .comment-btn {
    margin: 0;
    min-width: 110px;
    width: 110px;
    line-height: 30px;
    min-height: 30px;
    height: 30px;
    margin-top: -7px;
  }

  .comment-section {
    li {
      margin-top: 15px;
      position: relative;

      &.default-card-box {
        padding: 10px;
        box-shadow: 1px 1px 0 #D2D2D2;
        border-radius: 3px;
      }

      .profile-pic {
        max-width: 40px;
        max-height: 40px;
      }

      .comment-txt {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        padding-bottom: 5px;
        margin-left: 10px;

        p {
          margin-top: 5px;
          margin-bottom: 5px;
        }

        .usr-name {
          font-size: 10px;
          font-weight: normal;
          color: variables.$gray;
        }
      }
    }
  }

  .color-chooser {
    width: auto;
    margin-left: 45px;
    border-bottom: 0;
    padding-bottom: 0;
    vertical-align: top;
    display: inline-block;

    li {
      margin: 0;
      display: block;
      margin-bottom: 5px;

      md-checkbox {
        display: inline-block;
        vertical-align: middle;

        .md-container .md-icon {
          border-radius: variables.$border-radius-small;
        }
      }

      label {
        @extend .f3;

        padding-right: 10px;

        &.active {
          color: variables.$brand-accent;
        }
      }

      .label-box {
        display: inline-block;
        width: 21px;
        height: 21px;
        border-radius: 3px;
        outline: 0;
      }
    }
  }

  .attachment-title {
    margin-bottom: 6px;
    margin-top: 20px;
    @extend .f3;

    font-weight: 500;
  }

  .datepicker-wrapper {
    position: relative;
    margin: 0 0 18px;
    vertical-align: middle;

    .datepicker-custom {
      .md-datepicker-input-container {
        margin-left: 0;
        width: 100%;
        padding-bottom: 0;

        .md-datepicker-input.md-input {
          border-bottom: none;
        }

        input {
          width: 100%;
          height: 36px;
        }
      }

      .md-datepicker-triangle-button.md-button.md-icon-button {
        display: none;
      }
    }

    .padding-right {
      padding-right: 15px;
    }

    .md-datepicker-button {
      position: absolute;
      right: 0;
      margin: 0;
      z-index: 2;
      width: 36px;
      height: 36px;
    }

    .md-datepicker-input-container.md-datepicker-focused {
      border-bottom-color: variables.$brand-primary;
    }
  }

  .format-option {
    position: relative;

    md-input-container {
      textarea {
        min-height: 140px;
        padding-bottom: 32px;
      }
    }
  }

  .comment-options {
    position: absolute;
    right: 0;
    bottom: 28px;

    md-icon {
      width: 25px;
      height: 20px;
      vertical-align: top;
      margin-right: 22px;

      svg {
        path {
          fill: variables.$gray-light;
        }
      }

      &.attach {
        width: 25px;
        height: 25px;
        margin-top: -3px;
      }

      &.active {
        svg {
          path {
            fill: variables.$brand-primary;
          }
        }
      }
    }
  }

  .attach-list {
    @extend .clearfix;

    li {
      border: 1px solid variables.$white-dark;
      @include mixins.border-radius(4px);

      box-shadow: 2px 2px 0 variables.$white-darker;
      float: left;
      height: 90px;
      margin: 0 17px 20px 0;
      position: relative;
      width: 110px;
      background: url("#{variables.$image-path}/icons/set-b/ico-pdf.svg") center no-repeat;
      background-size: 34px;

      md-icon {
        position: absolute;
        height: 10px;
        top: 7px;
        right: 7px;
        width: 10px;
      }
    }
  }
}

.proposal-sidepanel {
  width: 310px;
  max-width: 310px;

  &.large-panel {
    max-width: 400px;
    width: 100%;
  }

  // .color-chooser li md-checkbox .md-container .md-icon {
  //   border-radius: 5px;
  // }
  .likes-history {
    .postHistory-sidebar {
      background-color: #f2f6f8;
    }
  }

  h3 {
    @extend .f3;

    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 12px;
  }

  .bottom-section {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  #label {
    height: 100%;

    .proposal-sidenav {
      position: absolute;
      top: 0;
      bottom: 48px;
      left: 0;
      right: 0;
      overflow-y: auto;

      &.no-footer {
        bottom: 0;
      }
    }
  }

  // md-input-container {
  //   margin-bottom: 0;
  // }

  .top-section {
    margin-bottom: 10px;

    md-icon {
      // width: 25px;
      // height: 25px;
      margin-right: 15px;
      // margin-top: -5px;
    }

    span {
      font-size: 16px;
      font-weight: normal;
    }
  }

  // .active-labels {
  //   &.test {
  //     border: 2px solid black;
  //   }
  // }

  .proposal-sidenav {
    padding: 20px 15px;

    .edit-section {
      margin-top: 10px;

      .color-chooser {
        margin-top: 10px;
      }

      .editlabel-input:not(:first-of-type) {
        margin-top: 15px;
      }
    }

    .create-section {
      .input-with-actions {
        margin: 25px 0 22px;
      }
    }

    // &:first-child {
    //   div {
    //     &:last-child {
    //       .md-button {
    //         margin-top: 10px;
    //         font-weight: bold;
    //
    //         &:first-child {
    //           border: 1px solid $gray;
    //           color: $gray;
    //         }
    //
    //         &:last-child {
    //           background-color: $brand-primary;
    //           color: $white;
    //         }
    //       }
    //     }
    //   }
    // }

    h2 {
      &:first-child {
        margin-top: 0;
      }
    }

    md-divider.space {
      margin: 30px 0 25px;
    }

    ul {
      @extend .clearfix;

      &.color-list {
        li {
          @include mixins.border-radius(4px);

          float: left;
          height: 35px;
          margin: 0 15px 15px 0;
          width: 50px;
        }
      }

      &.active-label-list {
        li {
          @include mixins.border-radius(4px);

          color: variables.$white;
          font-size: variables.$font-size-base;
          margin-bottom: 15px;
          padding: 7px 10px;
          width: 70%;
        }
      }

      &.emoji-list {
        padding: 0 5px;

        li {
          display: inline-block;
          padding-left: 30px;
          padding-bottom: 30px;

          &:nth-child(4n-7) {
            padding-left: 0;
          }

          md-icon {
            height: 42px;
            width: 42px;
          }
        }
      }
    }
  }
}

#emoji {
  h2 {
    padding-left: 5px;
    margin-bottom: 20px;
  }
}

#label .color-chooser {
  // padding-right: 45px;
  // padding-bottom: 0;

  li {
    padding-right: 12px;
    padding-bottom: 12px;
    margin: 0;

    // .no-color-choose {
    //   margin: 0;
    //   width: 42px;
    //   height: 30px;
    //   min-width: 42px;
    //   min-height: 30px;
    //   border: 2px solid $gray-lighter;
    //   border-radius: 5px;
    //   text-align: center;
    //   cursor: pointer;
    //   @extend .f4;
    //
    //   display: table;
    //   position: relative;
    //
    //   span {
    //     display: table-cell;
    //     text-align: center;
    //     width: 100%;
    //     font-size: 10px;
    //     vertical-align: middle;
    //   }
    // }
  }
}
@media screen and (max-width: 375px) {
  .proposal-container {
    .attach-list {
      li {
        background-size: 35px;
        height: 80px;
        width: 100px;
      }
    }
  }
}
@media screen and (max-width: 320px) {
  .proposal-container {
    md-sidenav {
      section {
        ul {
          &.emoji-list {
            li {
              margin-right: 30px;

              md-icon {
                height: 45px;
                width: 45px;
              }
            }
          }
        }
      }
    }
  }
}
