@use "../../settings/third-party/variables";

.segment-container {
  padding: 20px;

  .segment-table {}

  .data-table {
    font-size: 12px;

    th {
      font-weight: 500;
    }
  }

  .status {
    div {
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin-right: 4px;
    }

    .schedule {
      color: variables.$brand-primary;
      position: relative;

      div {
        background: variables.$brand-primary;
        position: absolute;
        top: 3px;
      }

      span {
        margin-left: 20px;
      }
    }

    .undelivered {
      color: #f05858;
      position: relative;

      div {
        background: #f05858;
      }
    }

    .delivered {
      color: #3cea21;
      position: relative;

      div {
        background: #3cea21;
      }
    }

    .removed {
      color: #b1b3b1;
      position: relative;

      div {
        background: #b1b3b1;
      }
    }

  }
}
