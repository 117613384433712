@use "../../settings/third-party/variables";

.nurture-mail {
  .left-panel {
    padding-right: 50px;

    .info {
      margin: 15px 13px 15px 0;

      .header {
        padding: 10px;
        background-color: variables.$brand-primary;
        color: variables.$white;
      }

      .content {
        border: 1px solid variables.$gray-light;
        padding: 10px;
      }

      .footer {
        padding: 10px;
        background-color: variables.$brand-primary;
        color: variables.$white;
        font-weight: 600;

        .socialicon-btn {
          md-icon {
            svg {
              path {
                fill: variables.$white;
              }
            }
          }
        }
      }
    }
  }

  .btn-default {
    margin: 10px;
  }

  .right-panel {
    overflow: scroll;
    border: 1px solid variables.$gray-light;
    padding: 10px;

    .icon-down {
      float: right;
      margin-right: 0;

      svg {
        g {
          fill: variables.$gray;
        }
      }
      height: 18px;
      width: 15px;
      margin: 0 10px;
      // padding: 10px;
    }

    .element {
      margin-top: 15px;
      padding: 30px;
      font-weight: 600;
      font-size: 18px;
    }

    .data {
      margin-top: 5px;
      padding: 10px;

      span {
        color: variables.$proposalBlue;
        font-weight: variables.$alert-link-font-weight;
        float: right;
        margin-right: 0;
      }
    }

    .content {
      font-size: 15px;
      font-weight: 600;
    }

    .right-tab-head {
      width: 100%;
      border: 1px solid variables.$gray-light;

      &.title {
        min-height: 40px;
      }

      &.fun {
        margin: 10px 0;
        padding: 10px;
      }

      div {
        width: 34%;
        border-right: 1px solid variables.$gray-light;
        padding: 15px;
        font-weight: variables.$alert-link-font-weight;
      }

      .active {
        background-color: variables.$proposalBlue;
        color: variables.$white;
      }
    }
  }
}

.send-mail {
  height: auto;

  .f1 {
    color: variables.$gray;
  }
}
