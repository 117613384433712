@use "../../settings/third-party/variables";

.testing-drip {
  width: 800px;

  .ico-tick {
    margin-right: 10px;
  }

  input[type=text] {
    border: 1px solid variables.$gray-light-media;
  }
}

@media (max-width:600px) {
  .popup-cta-row {
    padding: 8px 0;

    button {
      min-width: auto;
    }
  }
}
