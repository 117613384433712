.current-stock {
  padding: 20px;

  .data-table .collapse-header {
    padding-right: 0;
    padding-left: 0;
  }

  .data-table .collapse-header .collapse-color {
    background-color: #D6E1E5;
    margin: 3px 0px;
  }

  .data-table .collapse-header .separation {
    margin-right: 14px;
  }

  .filters-bar {
    .small-btn {
      margin: 6px 30px;
    }
  }
}
