@use "../../settings/third-party/mixins";
@use "../../settings/third-party/variables";

/*--ng-site-visit--*/

md-toolbar {
  &.main-header .form-base {
    .header-menu {
      margin-top: 0;
    }

    &.map-switch .map-switch-btn {
      top: 10px;
      right: 8px;
      margin-left: 15px;
      height: 40px;
    }
  }
}

.guider-section.service-recurring-section {
  padding: 0;
  background-color: #d3e1e54a;

  .enhance-section {
    padding: 30px 10px;
  }

  .bottom-lockbg-filled {
    background-color: #f45150;
    height: 30px;
  }

  .bottom-unlockbg-filled {
    background-color: #64c43aff;
    height: 30px;
  }

  .image-div {
    width: 90px;
    height: 90px;
    background: white;
    border: 1px solid #f45150;
    border-radius: 50%;
    padding: 18px;
    margin-right: 15px;

    .lock-services-img {
      width: 50px;
      height: 50px;
    }
  }

  .add-custom-content {
    .title-heading {
      margin-bottom: 5px;
    }

    .desc-text {
      margin-top: 5px;

      .pending-status {
        color: #f45150;
      }

      .completed-status {
        color: #64c43aff;
      }
    }

    .status {
      color: variables.$white;
      border-radius: 20px;
      padding: 5px 10px;
      min-width: 85px;
      font-weight: 600;
      text-align: center;
      font-size: 14px;

      &.pending-status {
        background: #f45150;
      }

      &.completed-status {
        background: #64c43aff;
      }
    }

    .schedule-time {
      color: #606060;
      background: variables.$white;
      border-radius: 20px;
      padding: 5px 10px;
      min-width: 85px;
      font-weight: 600;
      text-align: center;
    }

    .btn-primary {
      color: rgb(255, 149, 1);
      background-color: rgb(255, 255, 255);
      border: 1px solid rgb(255, 149, 1);
      padding: 5px 10px;
      margin: 20px 0 0;
      width: max-content;
      text-align: left;
      line-height: normal;

      .arrow-icon {
        height: 18px;
        width: 18px;
      }

      .update {
        font-size: 14px;
      }

      .master {
        font-size: 17px;
        margin-top: 2px;
      }
    }

    .btn-primary:hover {
      color: variables.$white;
      background-color: rgb(255, 149, 1);

      .arrow-icon {
        svg {
          path {
            fill: variables.$white;
          }
        }
      }
    }

    .contact-info {
      p span {
        min-width: 135px;
      }
    }
  }

  .recurring-steps {
    background-color: #21374cff;
    padding: 0;
    max-width: 320px;

    @media screen and (min-width: 600px) and (max-width: 960px) {
      margin-left: 105px;
    }

    .recurring-heading-section {
      padding: 15px 20px;
      border-bottom: 1px solid #595959;
    }

    .recurring-steps-section {
      padding: 15px 20px;
    }

    .flag-icon {
      width: 15px;
      margin-right: 15px;

      svg {
        path {
          fill: variables.$white;
        }
      }
    }

    .add-custom-content {
      .title-heading {
        margin: 0;
      }

      .text-desc {
        color: variables.$white;
        font-size: 13px;
        margin-bottom: 0;
      }

    }

    .count-div {
      background-color: #48c5c3ff;
      border: 1px solid #595959;
      padding: 9px 13px;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      margin-right: 15px;

      .count {
        color: variables.$white;
        font-weight: bold;
        font-size: 14px;
      }
    }

    .steps-tab {
      background-color: white;
      border: 1px solid #48c5c3;
      padding: 14px;
      width: 100%;
      position: relative;

      .steps-tab-div {
        font-weight: 600;
        font-size: 13px;
        color: #486480;
      }

      .check-status {
        position: absolute;
        top: -12px;
        right: -12px;

        &.inactive svg .path1 {
          fill: #949494;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .guider-section.service-recurring-section {
    .add-custom-content {
      .title-heading {
        margin-top: 0;
      }

      .contact-info {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        p {
          md-icon {
            margin: 0;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 350px) {
  .guider-section.service-recurring-section {
    .image-div {
      width: 40px;
      height: 40px;
      padding: 9px;

      .lock-services-img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.archived-items {
  margin-top: 25px;

  md-checkbox {
    margin-bottom: 5px;
  }

  .md-subheader-inner {
    @include mixins.border-radius(variables.$border-radius-small);

    text-align: center;
    color: variables.$gray-darker;
    background-color: variables.$white-dark;
    padding: 14px;
    font-size: variables.$font-size-small;
  }

  .md-list-item-inner {
    @include mixins.border-radius(variables.$border-radius-small);

    background-color: #f9f9f9;
    margin-top: 12px;
    color: #a0a0a0;
  }

  md-checkbox.md-checked[disabled] .md-icon {
    background-color: #d7eef4;
    border: 1px solid #b2c6cd;

    @include mixins.border-radius(5px);

    padding: 9px;
    border-radius: 3px;
  }

  md-checkbox.md-checked .md-icon::after {
    border-color: #9bc2cc;
    left: 7px;
    top: 3px;
    width: 5px;
    height: 9px;
  }

  md-list-item .md-list-item-inner > md-checkbox:first-child {
    margin-right: 5px;
    margin-top: 3px;
  }
}

.data-table {
  border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -webkit-border-radius: 5px 5px 0 0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;

  th:first-child {
    border-radius: 5px 0 0 0;
    -moz-border-radius: 5px 0 0 0;
    -webkit-border-radius: 5px 0 0 0;
  }

  th:last-child {
    border-radius: 0 5px 0 0;
    -moz-border-radius: 0 5px 0 0;
    -webkit-border-radius: 0 5px 0 0;
  }

  tbody {
    tr {
      border-top: 1px solid variables.$brand-table;
      border-bottom: 1px solid variables.$brand-table;
      vertical-align: top;
      text-align: left;

      &.item-sublist {
        td {
          border-bottom: 1px solid variables.$white-dark;
        }
      }
    }

    td {
      .ico-btn-edit {
        border: 1px solid variables.$brand-primary;
      }
    }

    .date-text {
      font-size: variables.$font-size-large;
      color: variables.$gray-darker;
    }

    .month-text {
      font-size: variables.$font-size-small;
      font-weight: normal;
      color: #606060;
    }

    .inventory-text {
      font-size: variables.$font-size-base;
      font-weight: 500;
      color: variables.$gray-darker;
    }

    .update-text {
      font-size: 11px;
      color: #606060;
    }
  }

  .arrow-icon {
    height: 15px;
    width: 14px;
    margin-top: 5px;
    cursor: pointer;

    svg path {
      fill: variables.$brand-primary;
    }

    &.cursor-disabled svg path {
      fill: variables.$gray-light;
    }
  }
}

.sub-data-table {
  border-radius: 0 0 5px 5px;
  border: 1px solid variables.$brand-table;
  border-top: 0;
  width: 100%;

  td {
    padding: 20px 0;
    width: 100%;
    text-align: center;
    font-size: variables.$font-size-small;
    font-weight: bold;
    color: variables.$gray-darker;

    .ico-btn-edit {
      border: 1px solid variables.$brand-primary;
    }
  }
}

.site-visit-section {
  overflow-x: auto;

  .data-table {
    min-width: 550px;

    .scheduled {
      color: variables.$status-green;
    }

    .completed {
      color: variables.$status-green;

    }

    .canceled {
      color: variables.$status-gray;

    }

    .rescheduled {
      color: variables.$status-yellow;

    }
  }
}

.add-site-visit {
  cursor: pointer;
}

.plus-icon {
  transform: rotate(45deg);
  border: 1px solid variables.$brand-accent;
  border-radius: 50%;
  padding: 5px;
  width: 6px;
  height: 6px;
  margin-bottom: 3px;
  margin-right: 8px;
  cursor: pointer;
  outline: none;

  svg {
    path {
      fill: rgb(2, 185, 242);
    }
  }
}

.plus-icon-head {
  width: 24px;
  height: 24px;
  margin-bottom: 3px;
  margin-right: 10px;
  cursor: pointer;
  margin-top: 6px;
  outline: none;
}

/*FormBase End*/

.sort-min {
  min-width: 80px;
}

/*site visit edit css start here*/

.sv-sidepanel {
  width: 300px;
  min-width: 0;
  max-width: 300px;

  &[md-component-id="deal-status-change"] {
    max-width: 400px;
    width: 100%;
  }

  .section-title {
    display: block;
    margin-top: 10px;
    padding: 0 8px;
  }
}

.site-visit-edit {
  md-input-container.md-input-focused:not(.md-input-invalid) md-icon {
    color: variables.$brand-danger;
  }

  .datepicker-custom {
    height: 40px;
    display: inline-block;
    position: relative;
    padding: 2px;
    vertical-align: middle;

    .md-datepicker-input-container {
      width: 100%;
      margin-left: 0;

      input {
        border: none;
      }
    }

    .md-datepicker-button {
      position: absolute;
      right: 0;
      margin: 0;
      z-index: 2;
      width: 36px;
      height: 36px;
    }

    .md-datepicker-triangle-button.md-button.md-icon-button {
      display: none;
    }
  }

  .gap-left-hard {
    padding-left: 20px;
  }

  .end-label {
    display: inline-block;
    margin-left: 20px;
  }

  .custom-calender {
    /*  height: 200px; */
    margin-top: 8px;

    .uib-datepicker {
      /* transform: scale(0.7); */
      transform-origin: top left;
    }
  }
}

// @media only screen and (max-width: 767px) {
//     .tablesaw-bar .tablesaw-advance a.tablesaw-nav-btn,
//     .tablesaw-columntoggle-btnwrap.visible .tablesaw-columntoggle-popup {
//         display: inline-block;
//     }
// }
