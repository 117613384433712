@use "../settings/third-party/variables";
@use "../settings/style-guide";

/**Scss Common Popups Used**/
// TODO: remove change image popup

.change-image {
  width: 675px;
  overflow: hidden;

  .main-header {
    .md-toolbar-tools .md-icon-button {
      md-icon {
        height: 18px;
        width: 18px;

        svg {
          path {
            fill: variables.$white;
          }
        }
      }
    }

    .end-btn {
      min-width: 72px;
    }
  }

  md-dialog-content {
    width: 100%;
    height: 100%;
    padding: 20px;
    overflow: hidden;

    .browse-image {
      border-right: 1px solid variables.$white-darker;
      padding: 20px 0;

      .canvas-capture {
        border: 1px solid variables.$white-darker;
        border-radius: 100%;
        margin-right: 35px;
      }

      .profile-avatar {
        margin: 0 35px 0 0;
        width: 96px;
        height: 96px;

        svg {
          border-radius: 100%;
          border: 1px solid variables.$white-darker;
        }
      }

      span {
        @extend .f2;
        padding-top: 6px;
      }

      .md-button {
        margin: 0;
        padding: 0;
        border: 1px solid variables.$white-darker;
        min-width: 0;
        width: 110px;
        height: 36px;
        min-height: 0;
        line-height: 36px;

        span {
          color: variables.$brand-primary;
        }
      }
    }

    .upload-image {
      padding: 20px;

      md-icon {
        height: 45px;
        width: 55px;
        margin: 0;
      }

      span {
        @extend .f2;
        padding-top: 6px;
      }
    }

    .choose-image {
      width: 100%;
      border-top: 1px solid variables.$white-darker;
      margin-top: 18px;
      padding-top: 10px;

      li {
        display: inline-block;
        padding: 20px;

        md-icon {
          height: 50px;
          width: 50px;

          svg {
            border-radius: 100%;
          }
        }
      }
    }

    #capture-section {
      #video {
        width: 100%;
        border: 1px solid variables.$white-darker;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    md-dialog-content .browse-image {
      border: 0;
      border-bottom: 1px solid variables.$white-darker;
    }

    md-dialog-content .choose-image {
      text-align: center;

      li {
        padding: 20px 10px;
      }
    }

    md-dialog-content .browse-image .profile-avatar {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
}
/***End***/
.tableSetting-popup {
  min-height: 500px;
  max-width: 100%;
  width: 650px;

  md-toolbar.main-header {
    background-color: variables.$white;
    border-bottom: 1px solid variables.$white-darker;
  }

  .md-toolbar-tools {
    h2 {
      color: variables.$gray-darker;
      font-size: 16px;
    }

    .md-button:not(.no-color) {
      &.header-btn {
        min-width: 75px;
      }

      &.cancel-btn {
        background: variables.$white-dark;
        color: variables.$gray;
        min-width: 82px;
      }
    }
  }

  .table-settings {
    max-width: 500px;
    margin: 50px auto 0 !important;
    width: 100%;
    padding: 12px;

    .title {
      margin: 0;
      font-weight: normal;
    }

    .column-list {
      margin-top: 20px;

      li {
        display: inline-block;
        min-width: 48%;
        max-width: 50%;
        margin-bottom: 20px;

        md-checkbox {
          margin-bottom: 0;

          &.md-checked {
            &[disabled="disabled"] .md-icon {
              background-color: #D7EEF4;
              border: 1px solid #B2C6CD;

              &:after {
                border-color: #b2c6cd;
              }
            }
          }

          .md-label {
            color: #606060;
          }
        }

        &:nth-child(even) {
          @extend .PosR;
          right: -5px;
        }
      }
    }
  }
}

.image-cropper,
.image-display {
  height: 550px;
  width: 800px;
  // height: 100%;
  // width: 100%;
  // max-width: 100%;
  // max-height: 100%;
}

.image-gallery {
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  background-color: #424853;
}

.image-cropper {
  .croppie-container {
    padding: 25px;
  }

  .title-text {
    color: #a5a5a5;
    font-size: 15px;
    font-weight: 500;
  }

  .icon-back {
    display: none;
  }

  .browse-on {
    md-pagination-wrapper {
      left: 38px;
    }

    .icon-back {
      display: block;
      position: absolute;
      left: 30px;
      top: 24px;
      transform: rotateY(-180deg);
      z-index: 12;

      path {
        fill: variables.$brand-primary;
      }
    }
  }

  md-dialog-content {
    padding: 10px 20px;
    position: relative;
  }

  md-content {
    margin-top: 20px;
  }

  .main-header {
    h2 {
      font-size: 20px;
    }
  }

  .md-sidenav-right {
    &.inside-container {
      bottom: 0;
    }
  }

  .modes {
    margin: 10px;

    li {
      cursor: pointer;
      text-align: center;
      margin: 7px;
      display: inline-block;

      .img-container {
        height: 85px;
        width: 132px;
        border: 1px solid variables.$gray;
      }

      .f8 {
        margin: 10px;
      }
    }
  }

  .repo-categories {
    text-align: center;

    li {
      display: inline-block;
      cursor: pointer;
      margin: 5px 20px;
    }
  }

  .repo-images {
    margin-top: 5px;

    li {
      display: inline-block;
      height: 102px;
      width: 102px;
      border: 1px solid variables.$gray;
      margin: 15px 10px;
    }
  }

  .avatar-list {
    .short-name {
      height: 180px;
      width: 180px;
      border: 1px solid variables.$gray-light;
      margin: 8px 8px 0;
      float: left;
      position: relative;

      .set-name {
        position: absolute;
        bottom: 0;
        text-align: center;
        width: 100%;
      }

      .icon-tick {
        display: none;
        height: 20px;
      }

      &.active {
        .icon-tick {
          display: block;
          position: absolute;
          top: 55px;
          right: 52px;
        }
      }
    }

    ul {
      li {
        display: inline-block;
        padding: 15px;
        border: 1px solid variables.$gray-light;
        margin: 8px;
        position: relative;

        md-icon {
          height: 50px;
          width: 50px;
        }

        .icon-tick {
          display: none;
          height: 20px;
        }

        &.active {
          .icon-tick {
            display: block;
            position: absolute;
            top: 10px;
            right: -4px;
          }
        }
      }
    }
  }

  .img-replacer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 20px 0 0;
    height: 66px;
    width: 66px;
    line-height: 66px;
    background: #47d2fd;
    color: #fff;
    font-size: 24px;
    font-weight: 500;
  }
}

.upload-area {
  height: 415px;

  .image-options {
    li {
      text-align: center;
      // margin-bottom: 20px;

      .f8 {
        margin: 6px auto;
      }

      .delete-image {
        path {
          fill: #a4b0c1;
        }
      }
    }
  }

  .drop-area {
    position: relative;
    text-align: center;
    height: 330px;
    min-height: 330px;
    margin: auto;
    border-radius: 2px;

    &:not(.no-border) {
      border: 2px dashed variables.$gray-light;
    }

    &:not(.image-dropped) {
      background-color: #fafafa;
    }

    .browse-img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      @extend .title-text;
      width: 100%;

      .f8 {
        font-size: 15px;
      }
    }

    input[type=range] {
      -webkit-appearance: none;
      height: 12px;
      border: 1px solid #ddd;
      border-radius: 6px;
      background: #fff;
      margin: 0;
    }

    input[type=range]:focus {
      outline: none;
    }

    input[type=range]::-webkit-slider-runnable-track {
      background: #fff;
    }

    input[type=range]::-webkit-slider-thumb {
      background: variables.$brand-primary;
      cursor: pointer;
      -webkit-appearance: none;
    }

    input[type=range]::-moz-range-track {
      background: #fff;
    }

    input[type=range]::-moz-range-thumb {
      background: variables.$brand-primary;
      cursor: pointer;
    }
  }

  .dragover {
    border-color: variables.$brand-primary;
  }

  .camera-frame {
    background-color: #fafafa;
    border: 1px dashed variables.$gray-light;
    box-sizing: border-box;
    max-width: 502px;
    margin: auto;
    position: relative;
  }

  @media only screen and (min-width: 767px) {
    .camera-frame {
      height: 100%;
      max-height: 377px;
      width: 100%;
    }
  }

  .options {
    bottom: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    z-index: 999;

    li {
      padding: 10px 6px;
      display: inline-block;
    }

    md-icon {
      height: 30px;
      width: 30px;

      path {
        fill: #fff;
      }
    }
  }

  .icon-reply {
    transform: rotateY(-180deg);
  }

  .croppie-container .cr-slider-wrap {
    margin: 30px auto 0;
    width: auto;
  }

  .cr-slider {
    width: 100%;
  }
}

.image-display {
  md-dialog-content {
    padding: 18px;
  }
}
