@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

/*Quotes List Start*/
.cust-filter {
  .permanent-filter {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        @extend .cursor-point;
        display: inline-block;
        padding: 10px;
        min-width: 75px;
        text-align: center;
        // font-size: 16px;
        color: variables.$brand-primary;

        &.active {
          color: variables.$gray-base;
        }
      }
    }
  }

  .secondary-filter {
    .md-icon-button md-icon {
      display: inline-block;
      width: 12px;
      height: 12px;

      svg path {
        fill: variables.$gray-dark !important;
      }
    }

    md-select md-select-value span {
      max-width: 150px;
    }
  }
}

// customer custom filter code starts here
.custom-filters-bar {
  // TODO: remove
  // height: 100vh;

  // TODO: remove
  .custom-filter-header {
    max-width: 1350px;
    width: 100%;
    background-color: #f7fcffff;
    padding: 20px 18px !important;

    .fill_polygon {
      margin-right: 15px;

      svg {
        path {

          color: variables.$gray-base;
          fill: variables.$gray-base;
        }
      }
    }

    md-icon {
      svg {
        path {
          color: #8E8E8E;
          fill: #8E8E8E;
        }
      }
    }
  }

  .custom-filter-body {
    // background-color: #f7fcffff;
    background-color: #f2fffe;
  }

  .customer-detail-tabs {
    md-ink-bar {
      background-color: transparent;
    }
  }

  .tab-header {
    background: #e2f5f4;
    color: variables.$gray;
    padding: 20px 0 0 60px;
    font-size: 14px;
  }

  md-tabs-wrapper {
    background: #e2f5f4;
    padding: 20px 0 30px;
  }

  md-tabs-canvas {
    margin-left: 60px;
  }

  md-tab-item {
    border: 1px solid variables.$brand-primary;
    color: variables.$brand-primary;
    margin-right: 25px;
    border-radius: 25px;

    &.md-active {
      background-color: variables.$brand-primary;
      color: #fff;

      .status_filter_icon {
        filter: sepia(1);
      }
    }

    &:not(.md-active) label {
      cursor: pointer;
    }
  }

  .status_filter_icon {
    width: 20px;
    height: auto;
    vertical-align: middle;
  }

  .status_filter_img {
    width: 45px;
    height: auto;
    vertical-align: middle;
    margin-right: 9px;
  }

  .filter_tab_content_custom {
    padding: 5px 8px;
    background-color: transparent;
    margin-top: 20px;
    max-width: 1350px;
    width: 100%;
  }

  // TODO: remove
  .custom_tab_title {
    font-size: 18px;
  }

  .type-box {
    // &.status-all {
    //   border: 1px solid #ff7373ff;
    //   background: #ff7373ff;
    // }

    &.status-new {
      border: 1px solid #ffce3a;
      background: transparent;

      &.active,
      &:hover {
        background: #ffce3a;
      }

      .type-label {
        color: #6a6a6a;
      }

      .custom_type-value {
        color: #ffce3a;
      }
    }

    &.status-buying {
      border: 1px solid #b4a7d6;
      background: transparent;

      &.active,
      &:hover {
        background: #b4a7d6;
      }

      .type-label {
        color: #6a6a6a;
      }

      .custom_type-value {
        color: #b4a7d6;
      }
    }

    &.status-lead {
      border: 1px solid #5cc8fcff;
      background: transparent;

      &.active,
      &:hover {
        background: #5cc8fcff;
      }

      .type-label {
        color: #6a6a6a;
      }

      .custom_type-value {
        color: #5cc8fcff;
      }
    }

    &.status-buyer {
      border: 1px solid #93c47d;
      background: transparent;

      &.active,
      &:hover {
        background: #93c47d;
      }

      .type-label {
        color: #6a6a6a;
      }

      .custom_type-value {
        color: #93c47d;
      }
    }
  }

  .status-filter-box {
    .status-icon path {
      fill: variables.$gray;
    }

    &.active,
    &:hover {
      .status-icon path {
        fill: variables.$white;
      }
      .filter-label {
        color: variables.$white;
      }
      .custom_type-value,
      .type-label {
        color: variables.$white;
      }
    }
  }

  .type-box.grey-box {
    border: 1px solid #a8b6c2;
    background: transparent;

    .type-label {
      color: #6a6a6a;
    }

    .custom_type-value {
      color: #595959;
    }
  }

  .type-box.grey-box {
    &.active,
    &:hover {
      background: #52758d;

      .custom_svg_icon {
        g,
        path {
          fill: variables.$white;
        }
      }
      .filter-label {
        color: variables.$white;
      }

      .custom_type-value,
      .type-label {
        color: variables.$white;
      }
    }
  }

  .type-box.rectangle_grey_box {
    padding: 12px;
  }

  .rectangle_checkbox {
    margin-top: 6px;
  }

  .type_box_card_section {
    margin-left: 55px;
  }

  .type_box_card_div {
    margin-top: 30px;
  }

  .type-box {
    -moz-border-radius: 7px;
    -webkit-border-radius: 7px;
    border-radius: 7px;
    background-clip: padding-box;
    padding: 12px 16px;
    min-width: 140px;
    max-width: 225px;
    width: 100%;
    box-sizing: border-box;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 20px;
    position: relative;
    cursor: pointer;

    .selected-icon {
      position: absolute;
      right: -8px;
      top: -8px;
    }

    .type-label {
      color: variables.$white;
      font-size: 12px;
      margin-bottom: 5px;
      display: flex;
      align-items: center;

      label {
        cursor: pointer;
      }

      .type-label-custom {
        margin-bottom: 0;
        display: flex;
        align-items: center;
      }
    }

    .custom_type-value {
      color: variables.$white;
      font-size: 25px;
      font-weight: bold;
      margin-left: 25px;
    }

    .custom_svg_icon {
      width: 20px;
      height: 20px;
      vertical-align: middle;
      margin-right: 5px;
    }
  }

  .location-symbol {
    margin-right: 8px;
  }

  .hexa_icon {
    margin-left: 15px;
    margin-right: 15px;
  }

  .empty_hexa_icon {
    svg {
      path {
        color: rgba(0,0,0,0.54);
        fill: rgba(0,0,0,0.54);
      }
    }
  }

  .type_boxcard_des {
    font-size: 18px;
    color: #999999;
    margin-bottom: 35px;
  }

  #label-in-front .md-icon,
  #label-in-front.md-checked .md-icon {
    border-radius: 50px;
  }

  md-icon {
    svg {
      g {
        color: rgba(0,0,0,0.54);
        fill: rgba(0,0,0,0.54);
      }
    }
  }

  md-tabs-wrapper md-next-button,
  md-tabs-wrapper md-prev-button {
    background-color: transparent;
  }
}

.mb-3 {
  margin-bottom: 30px !important;
}

// customer custom filter code ends here
@media (max-width:600px) {
  .cust-filter .permanent-filter ul li {
    display: block;
    text-align: left;
  }

  .filters-bar {
    .custom_filter_header {
      .md-button {
        min-width: 29px;
      }
    }
  }
}

.customers-list {
  padding: 0 20px 20px;

  // .customers-toolbar {
  //   background: transparent;
  //   width: auto;
  //   padding-right: 2px;
  //   min-height: 0;
  //   margin: 15px 20px 5px;
  //
  //   .md-toolbar-tools {
  //     height: auto;
  //     padding: 0;
  //     max-height: none;
  //   }
  //
  //   .ico-setting {
  //     svg {
  //       path {
  //         fill: $brand-accent;
  //       }
  //     }
  //   }
  // }

  // .row-check {
  //     md-checkbox {
  //         display: none;
  //     }
  // }
  .data-table {
    min-width: 700px;

    .inventory-text {
      font-weight: 500;
    }
    // tr table tr {
    //     &:hover {
    //         .row-check {
    //             md-checkbox {
    //                 display: inline-block;
    //             }
    //         }
    //     }
    // }
    // .row-check {
    //     md-checkbox {
    //         &.md-checked {
    //             display: inline-block;
    //         }
    //     }
    // }
    td {
      // vertical-align: middle;
      // padding: 15px;
    }

    .customer-services {
      @extend .f4;

      md-icon {
        width: 24px;
        height: 21px;
      }

      span {
        display: inline-block;
        vertical-align: middle;
        margin-right: 6px;
        font-size: 12px;
      }
    }

    .rating {
      span {
        margin-right: 4px;
      }
      // .small-star {
      //   height: 12px;
      //   width: 12px;
      //   margin-bottom: 3px;
      //   margin-left: 2px;
      //
      //   svg {
      //     path {
      //       fill: $brand-primary;
      //     }
      //   }
      // }
      //
      // a {
      //   font-size: 10px;
      //
      //   &.new-cutomer {
      //     color: $brand-success;
      //   }
      // }
    }

    .updates {
      // @extend .f3-light;
      font-size: 10px;
      line-height: 1.4;
    }

    .table-btn {
      min-width: 0;
      min-height: 0;
      padding: 0;
      margin: 0;
      line-height: 1;
    }
  }

  .table-toolbar-icon {
    padding-top: 5px;

    md-icon {
      height: 30px;
      width: 30px;
      margin: 0 0 0 10px;
    }
  }

  .swipe-wrapper {
    // overflow: hidden;
    min-width: 720px;
  }

  .data-table tbody td:nth-child(1),
  .data-table thead th:nth-child(1) {
    width: 30px;
    padding-right: 0;
  }

  .data-table thead th:nth-child(1) {
    height: 40px;
  }
}

.toolbar-icon {
  height: 30px;
  width: 30px;
  margin-left: 28px;
}

// .svg-base {
//   svg {
//     path {
//       fill: rgb(151, 153, 155);
//     }
//   }
// }

.svg-base-white {
  svg {
    path {
      fill: variables.$white;
    }
  }
}

.search-button {
  display: inline-block;
  border: none;
  top: 0;
  right: 0;
  z-index: 2;
  position: relative;
  margin: 0;
  cursor: pointer;

  svg path {
    fill: #8E8E8E;
  }
}

.customers-list .fixed-header table td.row-check {
  padding: 0 0 0 7px;
  min-width: 30px;
}

.earning-age-area {
  position: absolute;
  top: 10px;
  right: 40px;
}

.filter-area {
  md-input-container {
    padding-right: 10px;
    width: 100%;

    .md-input,
    md-select {
      width: 100%;
      height: 38px;
      margin-top: 0;
    }
  }

  .multi-select-input {
    &.md-input-has-value md-select md-select-value span:first-child {
      text-overflow: ellipsis;
    }
  }
}

.advanced-search {
  @extend .filter-area;

  .PosR {
    .choose-criteria {
      position: absolute;
      left: -17px;
      top: 2px;
    }

    md-input-container {
      margin-left: 25px;
      width: 87%;
    }
  }
}
@media (min-width:767px) {
  // .filter-area {
  //   md-input-container {
  //     width: 192px;
  //   }
  // }

  .advanced-search {
    md-input-container {
      width: 100%;
    }
  }
}
@media (min-width:960px) {
  .uppar-padding {
    padding-top: 32px;
  }
}

// remove css and move it structure in business

.form-base .contact-type-value .edit-text {
  height: 16px;
  border-width: 0;
  font-size: variables.$font-size-small;
}

.check-dropdown {
  .check-top-margin {
    margin-top: 8px;
  }

  md-select {
    width: 80px;
    margin: 0;
    padding: 0;

    md-select-value {
      border: none;
    }
  }
}

.check-dropdown-110 {
  @extend .check-dropdown;

  md-select {
    width: 125px;
  }
}
@media (max-width:767px) {
  .customer-upload-section .duplicate-list .inner-sublist .input-container .contact-type-value.address-fix {
    max-width: 75px;
    margin-left: 35px;
  }
}
@media (max-width:600px) {
  // .check-dropdown md-select {
  //   margin-left: 40px;
  // }

  .customer-upload-section .duplicate-list .inner-sublist .input-container .contact-type-value.address-fix {
    max-width: inherit;
    margin-left: 15px;
  }
}
@media (max-width:960px) {
  .del-icon {
    position: absolute;
    top: 0;
    right: 0;
  }

  .address-margin {
    margin-top: 16px;
  }
}

.dup-correct {
  path {
    fill: #0E9212;
  }
}

.dup-found-exist {
  path {
    fill: variables.$brand-primary;
  }
}

.dup-found-history {
  path {
    fill: #CF0A0A;
  }
}

.add-contact-ai {
  .mat-auto-fix {
    position: absolute;
    top: 10px;
    right: 0;
  }
  .header {
    background: #20394c;
    color: #fff;

    .custom_svg_icon {
      width: 35px;
      height: 35px;      
    }

    .percent_counter {
      background: #486480;
      border: 5px solid #06b7f8;
      border-radius: 55px;
      width: 60px;
      height: 60px;
      line-height: 50px;
    }
  }

  .profile_icon_png {
    width: 30px;
    height: 30px;
  }

  .body-section {
    background-color: #d3e1e5;
    .index-cell {
      background-color: #fff;
      width: 40px;
      height: 40px;
      border-radius: 35px;
      line-height: 40px;
      font-size: 18px;
    }
    .email-input {
      background-color: #fff;
      height: 500px !important;
    }
    .contact-form {
      background-color: #fff;
      border-radius: 10px;
    }
    .save-cta {
      right: 20px;
      top: 20px;
    }
    .arrow-btn-cta {
      width: 18px;
      height: 18px;

      svg path {
        fill: #fff !important;
      }
    }
    .counter-cell {
      background-color: #486480;
      border-radius: 5px;
      padding: 5px 10px;
    }
    .send-icon, .send-icon:hover {
      background: #20394c !important;
      border-radius: 6px;
      svg path {
        fill: #41A5EE !important;
      }
    }

    @media (max-width: 490px) {
      .save-cta {
        flex-direction: column;
      }
    }
  }

  @media (max-width: 780px) {
    .contact-ai-form {
      flex-direction: column;
    }
  }
}
