@use "../../settings/third-party/variables";
@use "../../settings/style-guide";
@use "../../settings/custom";

.assign-task {
  .date-filter {
    position: relative;
  }

  .total-tasks {
    @extend .f1;
    margin: 10px 0 18px;
  }

  .calenderlike-table {
    padding-right: 20px;

    th {
      @extend .f4;
      height: 25px;
      font-weight: normal;
    }

    td {
      &:first-child {
        vertical-align: top;
        padding-top: 5px;
      }
      @extend .f4;
      border-top: 1px solid variables.$gray-light;
      height: 75px;
    }

    td:first-child,
    th:first-child {
      width: 8%;
    }
  }

  .assign-table {
    min-width: 0;
    margin: 0;

    td,
    th {
      padding-right: 0;
    }

    .md-button.btn-primary {
      min-height: 22px;
      min-width: 60px;
      line-height: 27px;
      font-size: variables.$font-size-small;
    }

    .company-name {
      display: inline-block;
      @extend .ellipsis;
      width: 75%;
    }

    .service-on {
      width: 100px;
    }
  }
}
