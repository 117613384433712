@use "../../settings/third-party/variables";
@use "../../settings/style-guide";
/*--assign resources--*/
/* Md-Content Class of main content inventory Control*/
.icon-down-head {
  height: 6px;
  margin-bottom: 2px;

  .st0 {
    fill: #fff;
  }
}

.swipe-wrapper {
  // overflow: hidden;
  min-width: 720px;
  margin-top: 10px;
}

md-toolbar.main-header .dropdown-btn {
  position: relative;

  .ico-btn-edit {
    position: absolute;
    left: -35px;
    top: 5px;
  }

  .md-button {
    line-height: 16px;
    text-align: left;
    text-transform: capitalize;
    vertical-align: middle;
    width: auto;
  }

  .btn-toptxt {
    font-size: 11px;
    font-weight: normal;
    color: #E0E6F0;
  }
}

.inventory-count {
  md-icon {
    outline: 0;

    &.verfication-icon {
      height: 18px;
      margin-left: 8px;
      position: relative;
      top: -3px;
      width: 18px;
    }
  }

  .assign-txt {
    position: relative;
    top: 2px;
    left: 5px;
  }

  .col-2-list {
    width: 80%;

    ul {
      li {
        margin-top: 0;
        line-height: 1.5;
        margin-bottom: 18px;
      }
    }

    li > label {
      padding-right: 5%;
      font-weight: normal;
      @extend .f4;
    }
  }

  .total-time {
    padding-right: 30px;
  }
}

.assign-plus {
  display: inline-block;
  outline: 0;
  cursor: pointer;
}
/* Toggel Side bar*/
.ar-sidepanel {
  .max-list {
    max-height: 300px;
    overflow: auto;
    padding: 15px 18px;
    margin: 0;
    background: #EAEAEA;
  }

  .icon-calendar,
  .icon-tick {
    height: 16px;
  }

  .icon-calendar {
    svg path {
      fill: variables.$brand-primary;
    }

    &.pending-task {
      svg path {
        fill: #BD4545;
      }
    }
  }

  .schedule-date {
    font-size: 10px;
    display: inline-block;
  }

  .task-assign {
    margin-top: 8px;
    padding: 5px 0 5px 12px;
  }

  md-checkbox.sidenav-radio {
    margin: 5px 0 0;

    .md-icon {
      top: -2px;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      border-width: 1px;
      border-color: rgb(173, 173, 173);
    }
  }

  md-checkbox.sidenav-radio.md-checked {
    .md-icon {
      background-color: transparent;
      border-radius: 50%;
      border: 1px solid rgb(173, 173, 173);
    }

    .md-icon:after {
      background: variables.$brand-primary;
      border: 0;
      border-radius: 50%;
      height: 8px;
      width: 8px;
      top: 6px;
      left: 6px;
    }
  }

  &.site-visit-sidebar {
    .task-wrapper {
      top: 125px;
    }
  }

  .task-wrapper {
    position: absolute;
    top: 100px;
    bottom: 50px;
    overflow: auto;
    left: 0;
    right: 0;

    .dedicated-sales {
      color: variables.$white;
      background: variables.$brand-primary;
      padding: 4px 10px;
      display: inline-block;
      border-radius: 5px;
      margin-top: 5px;
      line-height: 1;
    }

    .schedule-list li {
      padding: 10px;

      span {
        padding-right: 12px;
      }
    }
  }

  .task-list {
    padding: 0;
    overflow: auto;
    height: 100%;
    // .schedule-select {
    //   background: $brand-info-light;
    // }
    md-list-item .md-no-style,
    md-list-item.md-no-proxy {
      padding-left: 18px;
      padding-right: 18px;
    }

    .md-avatar {
      margin-right: 10px;
    }
  }

  .bottom-section {
    height: 50px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .top-section {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 100px;
  }

  .sidenav-wrapper {
    position: relative;
    height: 100%;
  }

  .md-button {
    text-transform: capitalize;
  }
}
