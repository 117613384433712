.sms-message {
  .accepting_cards {
    .chat-list {
      min-width: 335px;
      background-color: #d3e1e54a;

      .proflie_section {
        max-width: 235px;
        width: 100%;

        .heading {
          color: #000;
          font-size: 14px;

          margin-bottom: 10px;
        }

        h1 {
          text-align: left;
          color: #000;
          font-size: 21px;
          font-weight: 600;
          line-height: 1.5;

        }

        p {
          text-align: left;
          color: #999999;
          margin-bottom: 25px;
          line-height: 1.3;
        }

        .watch-video {
          margin: 6px 0;
          max-width: 140px;
          background-color: #2196f3ff;

          img {
            width: 25px;
            vertical-align: middle;
            margin-right: 10px;
          }
        }

      }

      .bottom-menu {
        position: fixed;
        bottom: 20px;
        display: flex;
        justify-content: space-between;
        width: 85%;
        border-top: 1px solid #cccccc;
        padding-top: 20px;

        .policy-div {
          display: flex;
          justify-content: space-between;

          a {
            color: #ff9900 !important;
          }
        }
      }
    }

    .custom-default-boxx {
      width: 100%;
      padding: 15px 20px !important;

      .switch-bar {
        md-switch {
          margin-top: 10px;
        }
      }

      .export-criteria-div {
        width: 100%;
        justify-content: space-between;

        h2 {
          font-size: 18px;
        }
      }

      .section-heading {
        md-icon {
          margin-right: 10px;
          margin-left: 5px;
        }
      }

      .setup-content-div {
        padding-left: 50px;
        margin-bottom: 20px;

        .setup-content-heading {
          margin-right: 45px;
        }

        .setup-content-desc {
          margin-top: 10px;
          width: 100%;
        }

        md-input-container {
          margin-left: 0 !important;
        }
      }

      .hr-btns {
        width: 100%;

        // md-radio-group{
        //     justify-content: space-between;
        // }
      }

      .note {
        margin-left: 50px;
        margin-top: 40px;
        margin-bottom: 40px;
      }

      .data-creation-div {
        margin-bottom: 20px;
      }

      .email_title {
        margin-left: 0;
      }

      .capsule-name {
        padding: 0;
        border: 1px solid #eeeeee;
        border-radius: 50px;
        background-color: #eeeeee;
        max-width: 155px;
        width: 100%;
        align-items: center;
        display: flex;
        text-align: left;
        font-weight: 600;
        font-size: 11px;
        margin-right: 20px;

        .icon_img {
          margin-right: 7px;
        }
      }

      .icon-ico-add {
        margin-right: 5px;
      }
    }

    .main-right-section {
      width: 100%;
    }

    .api-chat-list {
      padding: 0 20px 0 30px;
    }

    .right-side-data {
      padding-left: 15px;

      .note,
      md-checkbox.md-auto-horizontal-margin,
      md-input-container.md-block.md-auto-horizontal-margin {
        margin-left: 35px;
      }

      md-checkbox.md-checked.restrict-data .md-ink-ripple {
        margin-top: -10px;
      }

      md-checkbox .md-label {
        line-height: 1.5;
      }

      .item-chips {
        margin-left: 65px;
      }

      .api-tag {
        margin: 40px 0;

      }

      .api-activity {
        line-height: 1.5;
      }

      .tick-status-indicator {
        margin-top: -7px;
      }

      .enable-switch {
        margin-top: 10px !important;
      }

      .active {
        color: #02b406;
      }

      .note {
        margin-top: 35px;
      }
    }

    .website-right-side-data {
      .note,
      md-checkbox.md-auto-horizontal-margin,
      md-input-container.md-block.md-auto-horizontal-margin {
        margin-left: 35px;
        max-width: 400px;
      }

      .note {
        max-width: 660px;
      }

      .business-location-input {
        margin-bottom: 30px;
      }

      .api-information {
        .enable-switch {
          margin-top: 10px !important;
        }
      }

      .enable-switch {
        margin-top: 0 !important;
      }

      .oauth-headig {
        md-checkbox.md-auto-horizontal-margin {
          max-width: 600px;
        }
      }

      .content-management {
        md-checkbox.md-auto-horizontal-margin {
          margin-top: 0;
        }
      }
    }
  }

  .icon_img {
    margin: 0 15px 0 0;
    width: 45px;
  }

  md-radio-button {
    max-width: 340px;
    min-width: 115px;
    width: 100%;
  }

  .text-seablue {
    color: #2196f3!important;
  }

  .height-100 {
    height: 100%;
  }
}

.export-module-popup.submit-popup {
  .form-base {
    padding: 15px 20px;

    .heading {
      margin-bottom: 20px;
    }

    .text-desc {
      color: #5e696c;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 30px;
    }

    .notes {
      font-size: 14px;
    }

    .bottom-section {
      justify-content: center;
      display: flex;
    }
  }

  .icon_img {
    width: 35px;
    height: auto;
    align-items: end;
    display: flex;
    margin-bottom: 80px;
    margin-right: 10px;
  }
}

.view-api {
  .opp-detail-list {
    width: 100%;

    .location-section {
      line-height: 1.8;
      //padding-top: 20px;
    }
    // md-list-item{
    //     font-size: 16px;
    // }
  }

  .api-main-heading {
    margin-bottom: 10px;
  }
}
