@use "../../settings/third-party/variables";

// .on-drag-enter,
// .on-drag-hover {
//   border: 1px dashed $brand-primary;
// }

.product-tag li {
  margin: 8px 8px 0 0;
  border: 2px solid variables.$white-dark;

  &.selected {
    border-color: variables.$brand-primary;
  }

  &.on-drag-enter {
    border: 2px dashed rgba(0, 0, 0, .32);
    transition: all 0.3s ease-in-out;
  }

  &.on-drag-hover {
    border: 2px dashed variables.$brand-primary;
    background: variables.$white;
    transition: all 0.3s ease-in-out;
  }
}

.tag-by-name {
  position: relative;
  border-top: 1px solid variables.$gray-lighter;
  padding: 5px 0;
  margin-top: 15px;

  li {
    &:first-of-type {
      margin-left: 30px;
    }
  }

  .tag-separation {
    position: absolute;
    top: -12px;
    width: 25px;
    height: 25px;
    background: variables.$brand-primary;
    border-radius: 50%;

    .tag-by-name-filter {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.product-tag li {
  margin: 8px 8px 0 0;
}

.full-width {
  width: 100%;
}

.custom-form {
  &#mirrorContainer {
    max-width: 800px;
  }

  .add-option {
    display: inline-block;
  }

  .question {
    margin: 0 0 20px;
    padding: 20px 15px;

    .card-input-padding {
      padding: 20px 20px 0;

      .dots-icon {
        width: 20px;
        height: 20px;
      }
    }

    .card-answer-padding {
      padding: 10px 20px 10px 55px;
      background-color: rgba(211, 225, 229, 0.25);

      .item-chips {
        li {
          background: #a8b6c2;
          color: #fff;
          border-radius: 0;

          md-icon {
            path {
              fill: #fff;
            }
          }
        }
      }
    }

    .custom-form-section-header {
      background: #21374c;
      border-bottom: 10px solid #5cc5c3;
      color: #5cc5c3;
      font-size: 20px;

      .custom-form-section-icon {
        position: absolute;
        width: 60px;
        height: 60px;
        top: -30px;
        right: 10px;
      }
    }

    &.card-border {
      border-top: 7px solid #374151;
      border-radius: 0;
    }

    &.question-section-card {
      margin-top: 60px;

      &.section-card-border {
        border-top: 1px solid #D3D3D3;
      }
    }

    &.choices,
    .choices {
      md-icon {
        margin-right: 10px;
        height: 18px;
        width: 18px;
      }
    }

    .fields-container {
      md-icon {
        height: 15px;
        width: 15px;
      }

      .md-button {
        margin: 0;
        min-height: 32px;

        &.md-icon-button {
          height: 32px;
          width: 32px;
        }
      }
    }

    .option-actions {
      line-height: 38px;
    }

    md-radio-group md-radio-button {
      margin-bottom: 0;
    }

    md-input-container.option input[type="text"] {
      border-color: transparent;
    }

    .options {
      md-checkbox {
        margin-bottom: 0;
      }

      md-input-container {
        margin-bottom: 0;
      }
    }

    .others {
      .index {
        color: rgba(0, 0, 0, .38);
      }
    }

    .icon-ico-add path {
      fill: variables.$brand-primary;
    }

    .bin-icon path {
      fill: variables.$gray-light-media;
    }

    .mark-default {
      margin-right: 10px;
    }

    .default {
      color: variables.$brand-accent;

      path {
        fill: variables.$brand-accent;
      }
    }
  }

  .section-end {
    border: 1px solid variables.$brand-primary;
  }

  .section-end:hover {
    box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .container-border {
    border: 1px solid #D3D3D3;
  }

  .container-border:hover {
    border: 1px solid variables.$brand-primary;
    box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .card-footer {
    background: #374151;
    padding: 10px 20px 10px 55px;

    &.required-optional {
      border-top: 5px solid rgb(255 206 10);
    }

    &.required-mandatory {
      border-top: 5px solid #ef4135;
    }

    .delete {
      svg path {
        fill: #fff;
      }
    }
  }

  .copy-delete {
    li {
      display: inline-block;
      cursor: pointer;

      &:not(.mandatory-switch) {
        margin: 12px 12px 0 0;
      }

      &.mandatory-text {
        margin-right: 4px;
      }

      &.mandatory-switch {
        height: 10px;
        margin-right: 7px;
      }

      .brand-color {
        color: variables.$brand-primary;
      }
    }
  }

  @media (max-width: 600px) {
    .options-margin {
      margin-left: 35px;
    }

    .options-margin-list {
      margin-top: 20px;
    }
  }
}

@media (max-width: 600px) {
  .question {
    .option-actions {
      margin-left: 24px;
    }

    .custom-form-option-actions {
      margin-left: 45px;
    }
  }
}

.applied-forms {
  .section-navigation {
    section-navigation {
      min-width: 320px;
    }
  }

  .custom-form-section {
    background: rgba(211 225 229 / 20%);
  }

  .question {
    margin: 20px 0 56px;
  }

  .section-index {
    display: inline-block;
    padding: 5px;
    text-align: center;
    color: #677b8a;
    border: 1px solid #96acba;
    border-radius: 15px;
    background: rgba(211, 225, 229, .25);
    min-width: 100px;
  }

  .card-answer-padding {
    padding: 10px 20px 10px 10px !important;
  }

  .section-heading-icon {
    position: absolute;
    top: -40px;

    &.section-icon {
      width: 70px;
      height: 70px;
    }

  }

  .accordion-section-heading {
    background: #21374c;
    border-bottom: 10px solid #5cc5c3;
    padding: 38px 10px 20px;

    .section-number {
      max-width: 430px;
      width: 100%;
      background: #fff;
      color: #374151;
      padding: 5px 0;
      font-size: 15px;
      border-radius: 35px;
    }

    h1 {
      color: #5cc5c3;
    }

    .section-info {
      color: #5cc5c3;
      font-size: 14px;
    }
  }

  .card-border {
    border: 1px solid #D3D3D3;
    border-top: 2px solid #374151 !important;
    box-shadow: rgba(159, 159, 159, 0.1) 0 4px 4px 4px;
  }

  .card-border:hover {
    box-shadow: rgba(76, 76, 76, 0.1) 0 4px 4px 4px;
  }

  .section-questions {
    .question-number {
      background: #21374c;
      border-radius: 4px;
      padding: 4px 15px;
      color: #5cc5c3;
      font-size: 14px;
    }
  }

  .optional-form-field {
    border-bottom: 3px solid rgb(255 206 10);
  }

  .mandatory-form-field {
    border-bottom: 3px solid #ef4135;
  }

  .condition-required {
    border-bottom: 3px solid #6aa84f;
  }

  .field-info {
    &.freedom-form-question {
      font-size: 15px;
    }
  }

  .answer-icon {
    width: 20px;
    height: 20px;
  }

  .freedom-form-text-area {
    min-height: 40px;
  }

  .freedom-form-radio-button {
    md-radio-button {
      .md-label {
        margin-left: 35px !important;
      }
    }
  }

  .freedom-form-checkbox {
    md-checkbox {
      min-height: 0;

      .md-label {
        margin-top: 0 !important;
      }
    }
  }

  @media (max-width: 440px) {
    .freedom-form-radio-button {
      margin-top: 10px !important;
    }
  }
}

.custom-form-section {
  .form-complete {
    border-radius: 10px;
    border: 1px solid white;
    background-color: rgb(1, 2, 21);
  }

  .md-card-image {
    height: 44px;
    width: 44px;
  }

  .complete-btn {
    background-color: #ea8538;
    border-radius: 2px;
    min-width: 250px;
    padding: 5px;
    min-height: 50px;
  }

  .pending-btn {
    @extend.complete-btn;
    background-color: white;
    border: 1px solid #ea8538;
    color: #ea8538;
  }
}

// .section-navigation {
@media (max-width: 959px) {
  md-menu-content[width="5"] {
    &.navigation-menu {
      min-width: 300px;
    }
  }
}

.navigation-menu {
  padding-bottom: 50px;

  .total-field {
    color: variables.$brand-primary;
  }

  .time-lapse-header {
    gap: 80px;
  }

  .bottom-section {
    background-color: #f1efef;
  }

  .close-btn-cta {
    border: 1px solid variables.$brand-primary;
    border-radius: 50px;
    max-height: 30px;
    min-height: 30px;
    line-height: 1;
    background-color: white;
    padding: 0 30px;
  }

  .form-section-info {
    .jump-to-cta {
      border-radius: 50px;
      border: 1px solid variables.$brand-primary;
      padding: 2px 10px;
      box-shadow: 0 0 3px 3px #e6e1e1;
      color: variables.$brand-primary;
    }

    .jump-to-cta:hover {
      background-color: #f0ecec;
    }

    .section-title {
      font-size: 15px;
    }

    // background: rgba(211, 225, 229, .25);
    background: rgb(177 215 226 / 25%);

    .data-filled,
    .mandatory-data,
    .remaining-fields {
      font-size: 12px;
      // border-radius: 15px;
      padding: 3px 5px;
      // min-width: 60px;
      // width: 100%;
    }

    .data-filled {
      background-color: rgba(106, 168, 79, 0.3);
      color: #6aa84f;
      border-bottom: 2px solid #6aa84f;
    }

    .remaining-fields {
      background-color: rgba(72, 100, 128, 0.3);
      color: #486480;
      border-bottom: 2px solid #486480;

    }

    .mandatory-data {
      background-color: rgba(224, 102, 102, 0.3);
      color: #e06666;
      border-bottom: 2px solid #e06666;

    }

    .sort-icon {
      svg {
        path {
          fill: variables.$brand-primary;
        }
      }
    }
  }

  .form-section-info:hover {
    .jump-to-cta {
      border-radius: 50px;
      border: 1px solid variables.$brand-primary;
      padding: 2px 10px;
      box-shadow: 0 0 3px 3px #e6e1e1;
      color: white;
      background-color: variables.$brand-primary;
    }

    .sort-icon {
      svg {
        path {
          fill: white;
        }
      }
    }

    background: rgba(117, 153, 163, 0.25);
  }

  // }
}

.section-heading {
  .section-navigation {
    .navigate-button {
      top: -54px;
      min-width: 60px;
      width: 60px;
      visibility: hidden;
    }
  }
}

.section-heading:hover {
  .section-navigation {
    .navigate-button {
      visibility: visible;
    }
  }
}

.custom-form-landing-page {
  .custom-form-header {
    padding: 50px 20px 70px;
    background: #52758d;
    color: #fff;

    h1 {
      font-size: 25px;
    }

    .section-info {
      font-size: 16px;
    }

    .apply-freedom-forms {
      background: #fffcf5bf;
      width: 45px;
      height: 30px;
    }
  }

  .form-category-cards {
    position: absolute;
    bottom: -80px;

    // top: 218px;
    // padding: 20px;
    .form-category-card {
      h1 {
        font-size: 18px;
        color: #000;
      }

      // padding: 5px 10px 10px 10px;
      max-width: 260px;
      width: 100%;
      background: #fff;
      border: 2px solid rgba(148, 148, 148, .1);
      border-bottom: 4px solid rgba(148, 148, 148, .1);
      box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.1);

      .form-category-desc {
        font-size: 10px;
        color: #666666;
      }

      .card-count {
        border-radius: 2px;
        padding: 6px 15px;
        color: #fff;
        background: #21374c;
      }

      .form-category-card-description {
        background: rgba(211, 225, 229, .25);
      }
    }

    .view-button {
      border: 1px solid variables.$brand-primary;
      color: variables.$brand-primary;
      font-size: 10px;
      min-width: 50px;
      min-height: 17px;
      border-radius: 70px;
      line-height: 8px;
    }

    .view-button:hover {
      color: #fff;
      background: variables.$brand-primary;
    }

    .filter-active,
    .form-category-card:hover {
      border: 2px solid variables.$brand-primary;
      border-bottom: 4px solid variables.$brand-primary;

      .form-category-card-description {
        background: #fff;
      }
    }

    .filter-active {
      .view-button {
        display: none;
      }
    }
  }

  .custorm-forms-container {
    background: rgba(211, 225, 229, .25);
    margin-top: 125px;
  }

  @media (max-width: 960px) {
    .form-category-cards {
      position: relative;
      bottom: -22px;
    }

    .custorm-forms-container {
      background: rgba(211, 225, 229, .25);
      margin-top: 85px;
    }
  }

  .form-header {
    font-size: 25px;

    span {
      color: #06b7f8;
    }
  }

  .form-header-info {
    color: #666666;
  }

  .forms-grid {
    .forms-grid-header {
      padding: 4px 15px 2px;
      background-color: #374151;
      color: #fff;

      .polygon {
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }

    .form-grid-body {
      background-color: rgba(211, 225, 229, 0.5);

      .freedom-form {
        padding: 2px 15px;
        color: #52758d;
        border: 1px solid #52758d;
        border-radius: 15px;
        background: #fff;
        max-width: 112px;
      }
    }

    .form-grid-footer {
      padding: 10px 20px;
      background: rgba(211, 225, 229, .25);
    }
  }
}

// Freedom Form Pop up

.freedom-form-popup {
  // height: 700px;
  max-width: 900px;
  width: 100%;

  .chat-list {
    background-color: rgba(211, 225, 229, .5);
  }

  .icon-folder {
    height: 40px;
    width: 40px;
  }

  .freedom-form-inputs {
    padding: 30px 10px;
  }

  .freedom-form-note {
    md-icon {
      width: 50px;
      height: 25px;
    }
  }

  .freedom-form-footer {
    line-height: 17px;
    min-height: 40px;
  }
}