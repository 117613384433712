@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

/*Customer History List Start*/
.customer-history-section {
  .default-box {
    padding: 10px 20px;
  }

  .table-sort {
    margin: 8px 0;
  }

  .data-list-move {
    td:last-child {
      width: 8%;
    }
  }

  .table-responsive {
    padding: 0;

    .data-table {
      min-width: 600px;
      margin-top: 10px;

      th {
        @extend .f3;
        font-weight: normal;

        .sub-label {
          position: relative;

          .label-txt {
            font-size: 7px;
            position: absolute;
            right: -3px;
            color: variables.$brand-primary;
            bottom: -4px;
          }
        }
      }

      td {
        @extend .f3-light;

        .ico-touch-arrow {
          svg {
            path {
              fill: variables.$brand-accent;
            }
          }
        }
      }

      .ico-table {
        width: 20px;
        height: 20px;
        margin-right: 5px;

        svg {
          path {
            fill: variables.$gray;
          }
        }

        &.small {
          width: 15px;
          height: 15px;
          // svg {
          //   path {
          //     fill: $gray;
          //   }
          // }
        }

        &.active {
          svg {
            path {
              fill: variables.$brand-accent;
            }
          }
        }
      }
    }
  }

  .update-status {
    margin-top: 17px;

    label {
      padding-right: 6px;
    }
  }
}
/*Customer History End*/
.customer-history {
  .customer-history-detail {
    border-bottom: 1px solid variables.$border-color;

    td {
      // @extend .f3-light;
      font-size: 12px;
      // border: none;
    }
  }

  .revenue-summary {
    border: 1px solid variables.$border-color;
    margin-top: 20px;

    h1 {
      margin-left: 15px;
      display: inline-block;
    }

    .separator {
      padding: 15px;

      &:not(:last-child) {
        border-right: 1px solid variables.$border-color;
      }

      span {
        color: variables.$gray-dark;
      }
    }
  }

  .top-gap {
    margin: 12px 0;
  }
}
@media(max-width:600px) {
  .customer-history .separator {
    border-right: none;

    &:not(:last-child) {
      border-bottom: 1px solid variables.$border-color;
    }
  }
}
