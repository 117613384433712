@use "../settings/third-party/variables";

.terms-popup {
    max-width: 700px;
    width: 100%;
    margin: 0px 50px;
    min-height: auto !important;

    .img-space-bottom {
        width: 70px;
    }

    .heart_symbol {
        color: variables.$code-color;
        font-size: 28px;
    }

    .accepting_cards {
        max-width: 700px;
        width: 100%;
        padding-left: 25px;
        height: auto;
        overflow-y: scroll;
        border: 1px solid #f9f6f6;
        border-left-color: variables.$brand-accent;
        border-left-width: 4px;
        margin-bottom: 20px;

        md-card-title {
            max-width: 700px;
            width: 100%;
            padding-left: 0px;

            .img-space-bottom {
                margin-right: 15px;
            }
        }
    }

    .accepting_tag_line {
        font-size: 19px;
        font-weight: 600;
    }

    .security_img {
        width: 50px;
        margin-right: 5px;
        height: 45px;
        margin-top: 6px;
        margin-left: 10px;
    }

    .accepting_hr {
        // color: #d9d9d9;
        margin-left: 11px;
        background: #ddd none repeat scroll 0 0;
        border: 0 none;
        height: 1px;
        position: relative;
        top: 3px;
    }
    .terms-link{
        text-decoration: underline;
        color: #af4345;
    }
    .accepting_card_title_text {
        min-width: 400px;
        max-width: 400px;
        width: 100%;

        h1 {
            font-size: 26px;
            color: variables.$brand-accent;
            font-weight: 600;
            margin-bottom: 0px;
        }
    }

    .terms-heading {
        p {
            color: #999999;
            font-weight: 500;
            font-size: 16px;
        }
    }

    .accepting_cards_content {
        padding-left: 0px;

        p {
            color: #999999;
            font-weight: 500;
            font-size: 16px;
        }
    }

    ::-webkit-scrollbar {
        width: 5px;
        min-height: 5px;
        max-height: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #888;
    }

    .btn-primary {
        border-radius: 0px;
    }

    .bottom-section_cta {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0px 10px;
        margin-bottom: 15px;
    }

    .btn-secondary {
        border: 1px solid variables.$brand-primary;
        color: variables.$brand-primary !important;
        background-color: transparent !important;
    }
}