@use "../../settings/third-party/variables";

.campaigns-grid {
  padding: 20px;

  .boards {
    li.active {
      background: variables.$brand-primary-light;
    }
  }
}
