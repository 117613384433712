@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.service-policyList-section {
  padding: 20px;

  .filters-bar {
    margin: 0 0 16px;
  }

  .data-table {
    td {
      @extend .f3;
      vertical-align: top;

      .policy-draft {
        color: variables.$brand-accent;
      }

      &.policy-update-status {
        font-size: 10px;
        line-height: 14px;
      }
    }

    .active {
      color: variables.$status-green;
    }

    .new {
      }

    .disabled {
      color: variables.$status-gray;

    }

    .archived {
      color: variables.$status-yellow;
    }

    .deleted {
      }

    .draft {
      color: variables.$status-gray;
    }

    .expired {
      color: variables.$status-red;
    }
  }
}
