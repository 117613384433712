@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.application-section {
  .btn-adding {
    padding-left: 0;
    margin-left: -5px;
  }

  .removefullWarrant {
    margin: 5px 0 0 10px;

  }

  .last-update-msg {
    top: 14px;
    right: 8px;
  }

  .custom-tabs {
    md-content {
      padding-top: 15px;
    }
  }

  .timer-cb {
    color: variables.$gray-dark;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .input-title {
    color: variables.$gray-dark;
    font-size: variables.$font-size-base;
    font-weight: 500;
    margin-bottom: 18px;
  }

  .timer-tab-widget {
    @extend .f3;

    line-height: 18px;
    text-align: center;

    .input-title {
      margin-bottom: 0;
    }
  }

  .bottom-gap {
    margin-bottom: 12px;
  }

  .calendar-format {
    margin: 4px 0 20px;

    label {
      @extend .f3;

      font-weight: 500;
      padding-right: 15px;
    }

    md-radio-button {
      @extend .f3;
    }
  }

  .webhook-switch {
    margin: 25px 0 20px;

    label {
      padding-right: 10px;
    }

    .enable-policy {
      margin-left: 20px;
    }
  }

  .webhook-info {
    &:not(:last-child) {
      margin-bottom: 30px;
    }

    label {
      @extend .f4;

      display: inline-block;
      margin-bottom: 10px;
    }

    .info-value {
      @extend .break-word;

      color: variables.$gray-darker;
      font-size: variables.$font-size-small;
    }
  }

  .module-list {
    margin-top: 10px;

    md-checkbox {
      margin-bottom: 20px;
    }
  }
}

@media screen and (min-width: 600px) {
  .timer-tab-widget {
    box-sizing: border-box;
    position: absolute;
    top: 15px;
    right: 0;
    width: 250px;
  }

  .module-list {
    max-width: 60%;

    li {
      display: inline-block;
      margin-right: 10px;
      width: 110px;
    }
  }
}

.apply-hr {
  margin: 0 0 17px 20px;
}

.add-webconversion {
  .setup-tab {
    margin-top: 20px;
  }

  .get-structure {
    margin: 0;
  }

  .status-active {
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-clip: padding-box;
    display: inline-block;
    height: 11px;
    margin-right: 5px;
    top: 1px;
    width: 11px;
  }

  .status-active.active {
    background: variables.$active;
  }

  .status-active.not-working {
    background: variables.$submit;
  }

  .status-active.disabled {
    background: variables.$disable;
  }

  .webhook-info .module-list {
    max-width: 100%;

    li {
      width: 150px;
    }
  }

}

.new_web_conversion {
  padding-left: 20px;

  .blue_title {
    color: #06b7f8;
    margin-bottom: 0;
  }

  .title {
    margin-top: 5px;
  }

  .description {
    color: #666666;
  }

  .type-box {
    margin: 0 20px 20px 0;
    padding: 15px 0;

    .md-headline {
      font-size: 14px;
      font-weight: 600;
      line-height: 1;
    }

    .md-description {
      color: #999999;
      font-size: 12px;
    }

    img.md-card-image {
      width: 50px;
      margin: 10px auto 0;
      // border-radius: 50px;
    }

    img.md-card-unique-image {
      width: 80px;
      margin: 10px auto 0;
      // border-radius: 50px;
      // margin-top: 10px;
    }

    md-card-title {
      flex: initial;
    }

    .btn-secondary {
      background-color: transparent;
      color: #06b7f8;
      border: 1px solid #06b7f8;
      max-width: 130px;
      width: 100%;
    }

    .btn-secondary:hover {
      background-color: #06b7f8;
      color: variables.$white;
    }

  }

  .flex {
    display: flex;
  }
}
