.automate-task{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f1f6f7; 

    .a-icon{
       width: 22px;
       height: 22px;
       margin-right: 8px; 
    }
    
    .personalize-data-icon, .verificar-icon , .quick-guide, .black-info-icon, .clip-icon{
        width: 25px; 
        height: 25px;
    }
    .play-icon{
        width: 35px;
        height: 35px;
    }

    .margin-right-8{
        margin-right: 8px;
    }
    .margin-right-10{
        margin-right: 10px;
    }
    .margin-right-15{
        margin-right: 15px;
    }
    .margin-right-20{
        margin-right: 20px;
    }
    .padding-left-30{
        padding-left: 30px;
    }
    .margin-left-30{
        margin-left: 30px;
    }
    .text-blue{
        color: #06b7f8;
    }
    .text-blue2{
        color:#5cc5c3;
    }
    .text-dark-gray-3{
        color: #6a6a6a;
    }
    .text-black{
        color: black;
    }

    .quote-description{
        font-size: 17px;
        color: #a6a6a6; 
        margin: 6px 0 0 30px;
    } 
    
    .title{
        font-size: 27px;
        margin: 30px 0 0 0; 
        letter-spacing: 1px;
    }
    .subtitle{
        font-size: 25px;
        margin: 0;
        letter-spacing: 1px;
        color: black;
    }

     .info-section{
        padding: 25px 30px 0 30px;
        height: 100%;
       
     
        .header{
             padding-top:15px;
             padding-bottom: 25px ;
             border-bottom: 1px solid #a2a1a1;

             .review-cta{
                width: 220px;
                padding: 10px;
                background-color: #fff;
                color: #fe9603;
                box-shadow: -1px 1px 8px rgba(0, 0, 0, 0.3);
                transition: background-color 250ms, box-shadow 250ms;

                &:hover{
                    color: white;
                    background-color: #fe9603;
                    box-shadow: -2px 2px 16px rgba(0, 0, 0, 0.6);
                }
              }
              .plus-img-icon{
                width: 25px; 
                height: 25px; 
                margin-right: 10px;
              }
              .service-type{
                font-size: 18px;
              }
        
        }

     }

     .main{
        padding: 40px 0;
        height: 70%;

        .reasons-list{
            font-size: 16px;
            color: #a2a1a1;
            padding-left: 0;
        }
            
        .reasons-list li{
            margin-left: 30px;
            padding-left: 20px;
        }

        .watch-video-btn{
            width: 200px;
            padding: 15px 8px;
            margin-top: 40px;
            color: white;
            background-color: #06b7f8;
            box-shadow: -1px 1px 8px rgba(0, 0, 0, 0.3);
            cursor: pointer;
            border: none;
            font-size: 14px; 
            font-weight: 600;
            transition: background-color 250ms, box-shadow 250ms;

            &:hover{
                background-color: #038abb;
                box-shadow: -2px 2px 16px rgba(0, 0, 0, 0.6);
            }

        }

        .img-section{
            width: 50%;
            height:350px;
        }

     }

     .footer{
          padding: 15px 0;
          border-top: 1px solid #a2a1a1;
      }

}



.qoute-status{

  .checklist-icon{
      width: 25px; 
      height: 18px; 
      margin-right: 10px; 
      margin-bottom: 10px;;
  }
  .circle-tick{
      width: 20px; 
      height: 20px;  
      margin-bottom: 10px;  
  }
  .right-tick{
      width: 14px; 
      height: 14px; 
  }

  .qoute-status-section{
       width: 100%;

        .header{
            padding:25px;
            box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.2);
            position: relative;

            .header-service-type{
                color: #2b3e50; 
                font-size: 14px; 
                cursor: pointer;
            }
            
        }
  }

  .main-status-list{
        padding: 30px 40px;
  }

  .qoute-status-list{
        gap: 50px; 
        padding: 30px; 
        background-color: #f1f6f7;

        .status-container{
                width: 170px;
                height: 110px;
                padding: 10px;
                background-color: white;
                cursor: pointer;

                
        }
        .active{
                background-color: #21374c;
                color: white;
        }
        .quote-process-description{
                max-width: 400px;
                font-size:14px;
                color: black;
        }

  }


     .section-2{
        padding: 30px 40px;

        .active-task-count-description{
                font-size: 15px; 
                color: #595959; 
                margin: 6px 0 0 15px;
        }
        .btn-section{
                padding: 14px 65px;
                border: 1px solid #a6a6a6;
                border-radius: 30px;
                cursor: pointer;
                font-size: 16px;
        }
        .btn-active{
                background: #fe9603;
                border-color: #fe9603;
                color: white;
        }

        .reminder-box{
                border: 1px solid #a2a1a1;
                padding: 0 10px;
                background:white;
                font-size: 14px;
        }
        .reminder-box-active{
                background-color: #06b7f8;
                color: white;
        }
        

    }

    .toggle-icon {
        position: absolute;
        top: 65px;
        right: 50%;
        padding: 2px 6px;
    
        md-icon {
          margin: 0;
          width: 15px;
          height: 15px;
          transform: rotate(-90deg);
          transition: 0.5s all;
    
          &.opened {
            transform: rotate(90deg);
          }
    
          svg path {
            fill: #52748d;
          }
        }
      }

}


@media (max-width: 600px) {
    .automate-task{

        .title{
            font-size: 20px;
        }
     
        .subtitle{
            font-size: 18px;
        }

        .header{
   
           .review-cta{
               margin-top:20px;
           }
           .review-txt{
               font-size: 10px;
           }
           .service-type{
               font-size: 14px;
           }
        }
        
       .main{
           height: auto;
   
           .reasons-list li{
              font-size: 12px;
           }
               
       }
   
       .footer{
   
           .related-auto-list{
               padding-top: 20px;
               display: flex;
               gap: 25px;
               margin-left: 30px;

               
           }
       }

   }
           
   
}