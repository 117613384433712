@use "../../settings/third-party/variables";

/* rces side panel Toggel Side bar */
.ar-sidepanel {
  width: 305px;
  // max-width: 400px;
  // width: 100%;

  .assign-type {
    margin: 3px 15px;

    md-radio-button {
      font-size: variables.$font-size-small-plus;
    }
  }

  .top-section {
    height: 130px;

    span {
      font-size: variables.$font-size-small-plus;
    }
  }

  .top-section .sidenav-searchicon {
    height: 16px;
    float: right;
    cursor: pointer;
    margin: 0;
    padding-right: 6px;
    outline: 0;
    z-index: 10;
    position: relative;

    svg {
      path {
        fill: variables.$gray-base;
      }
    }
  }

  .sidenav-title {
    margin-top: 15px;
    margin-bottom: 0;

    h2 {
      display: inline-block;
      font-weight: 500;
      margin-left: 9px;
    }
    /*Search Animation*/
    .search-box {
      height: 25px;
      font-size: variables.$font-size-small;
      position: absolute;
      margin: 0;
      top: 4px;
      right: 13px;
      padding-right: 22px;
    }

    .search-box:focus {
      width: 145px;
      z-index: 1;
      border: 1px solid #fff;
      background: #fff;
    }

    .search-button {
      top: 8px;
      z-index: 2;
      // top: 14px;
      // z-index: 2;
      // left: 68px;
    }
    /*Search AnimAtion End*/
    .sidenav-search {
      width: 115px;
      position: absolute;
      right: 23px;
      top: 17px;
      margin: 0;
      padding: 5px;
      border: 0;
      border-radius: 5px;
    }
  }

  .select-wrapper {
    margin-top: 3px;
    margin-bottom: 2px;
  }

  .md-avatar {
    border-radius: 50%;
    border: 1px solid #ccc;
  }

  .task-wrapper {
    position: absolute;
    top: 115px;
    bottom: 50px;
    overflow: auto;
    left: 0;
    right: 0;
  }

  .task-list {
    padding: 0;
    overflow: auto;
    height: 100%;

    .pending-task {
      .ico-calender-st0 {
        fill: none;
        stroke: #BD4545;
      }
    }

    md-list-item .md-no-style,
    md-list-item.md-no-proxy {
      padding-left: 18px;
      padding-right: 18px;
    }

    .md-avatar {
      margin-right: 10px;
    }
  }

  .bottom-section {
    height: 50px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .top-section {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 100px;
  }

  .sidenav-wrapper {
    position: relative;
    height: 100%;
  }
}
/*Search Animation Csss*/
.search-wrap {
  display: inline-block;
  padding: 0;
  position: relative;
  top: 10px;
}

.search-box {
  height: 36px;
  font-size: variables.$font-size-base;
  display: inline-block;
  outline: none;
  border: none;
  color: #555;
  cursor: pointer;
  padding: 3px 60px 3px 10px;
  width: 0;
  position: absolute;
  top: -4px;
  right: 116px;
  border-radius: 5px;
  background: none;
  z-index: 3;
  transition: width 0.4s cubic-bezier(0, 0.795, 0, 1);
}

.search-box:focus {
  width: 250px;
  z-index: 1;
  border: 1px solid #fff;
  background: #fff;
}

.search-button {
  display: inline-block;
  border: none;
  top: 0;
  right: 0;
  z-index: 2;
  position: relative;
  margin: 0;
  cursor: pointer;

  svg path {
    fill: #8E8E8E;
  }
}

.ar-sidepanel .task-wrapper {
  overflow: hidden;

  .contact-title {
    text-align: right;
    padding: 10px 16px 5px;

    span {
      font-size: 10px;
    }
  }

  .task-list.heightAdjust {
    height: 93%;
  }
}

// Labels sidebar starts
.input-with-actions {
  md-icon {
    top: 16px;
    left: inherit;
    height: 10px;
    width: 15px;

    &.tick {
      right: 30px;
    }

    &.active-icon,
    &.delete {
      right: 8px !important;
    }

    path {
      fill: variables.$gray;
    }

    &.active-icon path {
      fill: variables.$white;
    }
  }
}

.editlabel-input {
  position: relative;
  width: 100%;
  margin-bottom: 0 !important;

  &:not(.view-only) {
    max-width: calc(100% - 30px);
  }

  input[type="text"] {
    background-color: variables.$white;
    border-radius: 3px;
    border: 1px solid variables.$brand-primary;
    color: variables.$white;
    height: variables.$color-chooser-size;
    min-height: variables.$color-chooser-size;
    text-indent: 10px;
    transition: all linear 0.3s;
    width: 100%;

    &.pink {
      background-color: variables.$color-palatte-pink;
      border: variables.$color-chooser-input-size solid variables.$color-palatte-pink;
    }

    &.blue {
      background-color: variables.$color-palatte-blue;
      border: variables.$color-chooser-input-size solid variables.$color-palatte-blue;
    }

    &.red {
      background-color: variables.$color-palatte-red;
      border: variables.$color-chooser-input-size solid variables.$color-palatte-red;
    }

    &.orange {
      background-color: variables.$color-palatte-orange;
      border: variables.$color-chooser-input-size solid variables.$color-palatte-orange;
    }

    &.sky-blue {
      background-color: variables.$color-palatte-sky-blue;
      border: variables.$color-chooser-input-size solid variables.$color-palatte-sky-blue;
    }

    &.green {
      background-color: variables.$color-palatte-green;
      border: variables.$color-chooser-input-size solid variables.$color-palatte-green;
    }

    &.light-green {
      background-color: variables.$color-palatte-light-green;
      border: variables.$color-chooser-input-size solid variables.$color-palatte-light-green;
    }

    &.skin {
      background-color: variables.$color-palatte-skin;
      border: variables.$color-chooser-input-size solid variables.$color-palatte-skin;
    }

    // &.active {
    //   border: $color-chooser-input-size solid $gray-base;
    // }

    &.edit-mode {
      background-color: variables.$white;
      color: variables.$gray-dark;
    }

  }

  md-icon {
    top: 50%;
    transform: translateY(-50%);
    // left: inherit;

    &.icon-action {
      right: -24px !important;
      height: 14px;
      width: 14px;

      path {
        fill: variables.$gray;
      }
    }
  }
}

.color-chooser {
  // width: 100%;
  // border-bottom: none;
  // padding-bottom: 10px;

  li {
    margin-right: 10px;
    margin-top: 5px;
    display: inline-block;
  }

  md-checkbox {
    margin: 0;
    width: variables.$color-chooser-size;
    height: variables.$color-chooser-size;
    min-width: variables.$color-chooser-size;
    min-height: variables.$color-chooser-size;

    .md-label {
      display: none;
    }

    .md-container {
      width: variables.$color-chooser-size;
      height: variables.$color-chooser-size;
      min-width: variables.$color-chooser-size;
      min-height: variables.$color-chooser-size;

      .md-icon {
        width: variables.$color-chooser-size;
        height: variables.$color-chooser-size;

        &:after {
          border-color: variables.$white;
          left: 14px;
          top: 9px;
        }
      }

      // .md-ripple-container {
      //   left: -8px;
      //   top: -9px;
      //   right: -8px;
      //   bottom: -12px;
      // }
    }

    &.md-checked .md-icon:after {
      border-width: 3px;
      width: 10px;
      height: 16px;
    }

    &.pink .md-icon {
      background-color: #f266ba;
      border: 1px solid #f266ba;
    }

    &.blue .md-icon {
      background-color: #005bc3;
      border: 1px solid #005bc3;
    }

    &.red .md-icon {
      background-color: #ff2e4e;
      border: 1px solid #ff2e4e;
    }

    &.green .md-icon {
      background-color: #a8d900;
      border: 1px solid #a8d900;
    }

    &.orange .md-icon {
      background-color: #ff6f38;
      border: 1px solid #ff6f38;
    }

    &.sky-blue .md-icon {
      background-color: #5b9fff;
      border: 1px solid #5b9fff;
    }

    &.light-green .md-icon {
      background-color: #d4ff85;
      border: 1px solid #d4ff85;
    }

    &.skin .md-icon {
      background-color: #ffd285;
      border: 1px solid #ffd285;
    }
  }
}
// Labels sidebar ends
