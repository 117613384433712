@use "../../settings/third-party/variables";
@use "../../settings/style-guide";
@use "../../settings/custom";

.payment {
  textarea {
    max-height: 20px !important;
  }

  h2 {
    font-size: 16px;
    margin: 0;
    font-weight: normal;
  }

  .regular-recurring {
    margin: 10px 0;
  }

  .adding-cards {
    padding-top: 8px;
    margin-left: 3px;
  }

  .payment-credit {
    font-size: 16px;
    font-weight: 800;
  }

  .full-partial {
    margin: 15px 0;
  }

  .card-credit {
    margin: 5px 0 !important;
    font-size: 16px !important;
    font-weight: 800 !important;
  }

  .services-list {
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 16px;
  }

  .add-card {
    margin: 8px 15px;
    font-size: 11px;
    cursor: pointer;
  }

  // .card-details {
  //   padding: 20px 0;
  // }

  .payment-status {
    @extend .border-default;
    padding: 5px 15px;
    margin-bottom: 16px;

    .payment-info-row {
      // padding: 12px 15px;

      .payment-info-box {
        margin: 10px 0;
      }
    }

    .label {
      @extend .f4;
    }

    strong {
      @extend .f3;
    }

    &.header-card {
      border: none;

      .payment-header-card {
        background: #20394c;
        border-radius: 5px;
        padding: 15px 0 0;

        .payment-card {
          padding-right: 30px;
        }

        md-icon {
          width: 15px;
          height: 15px;
        }

        .link-active {
          md-icon {
            width: 15px;
            height: 15px;

            path {
              fill: variables.$brand-primary;
            }
          }
        }

        .payment-header-card-footer {
          background: #000;

          .function-status {
            min-width: 100px;
            max-width: 100px;
            border-radius: 15px;
            background: #fff;

            // &.expired, &.pending {
            //   border: 1px solid #e06666;
            // }

            // &.active {
            //   border: 1px solid #6aa84f;
            // }
          }
        }
      }
    }
    // .total-cost {
    //   label {
    //     @extend .f2;
    //   }
    //
    //   strong {
    //     @extend .f1;
    //   }
    // }
  }

  .partial-payment {
    md-input-container {
      margin-bottom: 0;
    }

    .right .radio-group {
      margin-top: 0;
    }
  }

  .autocharge-card {
    display: inline-block;
    vertical-align: top;
    margin-top: 10px;

    label {
      @extend .f4;
      display: inline-block;
      margin-right: 8px;
      vertical-align: top;
      margin-bottom: 12px;
    }

    md-input-container {
      margin-top: -13px;
      margin-right: 15px;
      margin-left: 5px;
    }

    md-switch {
      display: inline-block;
      margin-right: 10px;
      vertical-align: top;
    }
  }

  .payment-method {
    h2 {
      margin: 22px 0 26px;
    }

    .radio-group {
      margin-top: 0;
      margin-bottom: 15px;
    }

    .default-box {
      border-radius: 5px;
      margin: 0;
      border-color: variables.$gray-lighter;

      .amoun-percent {
        border: 1px solid variables.$gray-lighter;
        border-radius: 4px;
        max-width: 125px;
        height: 34px;
        padding-left: 10px;
        margin-right: 10px;
      }
    }
  }

  .form-base {
    // margin: 0 12px 0 14px;

    .radio-group {
      margin-top: 10px;

      md-radio-button {
        @extend .f3;
        margin-bottom: 0;
      }
    }
  }

  .payment-method-list {
    @extend .border-default;
    margin: 18px 0;
    padding: 28px 5px 13px;

    ul {
      li {
        display: inline-block;
        width: 15%;
        color: variables.$gray-light;
        vertical-align: top;
        outline: none;
        cursor: pointer;
        text-align: center;

        md-icon {
          height: auto;
          width: auto;

          svg {
            height: 28px;
            width: 42px;

            path {
              fill: variables.$gray-light;
            }
          }
        }

        .tab-caption {
          padding: 0;
          display: block;
          margin: 17px 0 13px;
          cursor: pointer;
        }
      }

      li.active {
        color: variables.$gray;

        &:after {
          content: "";
          display: block;
          height: 10px;
          width: 10px;
          background: variables.$brand-accent;
          margin: 5px auto 0;
          border-radius: 50%;
        }

        md-icon {
          svg {
            path {
              fill: variables.$gray;
            }
          }
        }
      }
    }
  }

  .apply-pay-tab,
  .cash-tab,
  .local-card-machine-tab {
    p {
      text-align: center;
      margin-top: 72px;
      @extend .f2;
      line-height: 14px;
    }
  }

  .payee-form {
    margin-left: 2.812px;

    section {
      .md-button:first-child {
        margin-left: 0;
      }
    }

    input {
      height: 40px;
    }
  }

  .add-payee {
    border-bottom: 1px solid variables.$white-dark;
    padding: 0 0 15px;
    color: variables.$brand-primary;
    margin: 0 0 20px 2.812px;
    outline: none;
    cursor: pointer;
  }

  .accept-card {
    color: variables.$gray;

    span {
      color: variables.$gray-base;
    }
  }

  .tab {
    margin-bottom: 30px;
  }

  .card-dtl {
    margin-top: 15px;
  }

  .payment-return {
    @extend .border-default;
    padding: 15px 18px;

    md-input-container {
      margin: 0 15px;

      md-select {
        label:not(.md-no-float),
        md-input-container .md-placeholder {
          display: none;
        }

        &:not([disabled]):focus md-select-value {
          padding: 0;
        }
      }

      &.md-input-has-value {
        label {
          display: none;
        }
      }

      label {
        padding: 0 7px;
      }
    }

    input,
    md-select,
    md-select-value {
      height: 25px;
      line-height: 25px;
      min-height: 25px;
      background: variables.$white;
      border-radius: 3px;
      padding: 0 10px;
      border: 1px solid variables.$white-dark;
      font-size: variables.$font-size-small;

      span {
        font-size: variables.$font-size-small;
        margin-top: -5px;
      }
    }

    input,
    md-select,
    md-select-value {
      margin: 0;
    }

    md-select-value {
      border: none;
      padding: 0;
    }

    .or-divider {
      margin: 4px 24px 0 7px;
      @extend .f4;
    }
  }
  /*--- Comman Card or Cheque detail Start ---*/
  .card-cheque-detail {
    padding: 40px 15px 0;
    background: variables.$white-dark;
    // margin: 30px 0 10px;
    // @extend .border-default;

    .md-input-focused {
      label {
        display: none;
      }

      .md-input {
        border: none;
      }

      .md-input-focused {
        input {
          border: 1px solid variables.$brand-accent;
          color: variables.$brand-accent;
        }
      }
      // .md-input.active {
      //   border: 1px solid $brand-accent;
      //   color: $brand-accent;
      // }
    }

    // .md-input.active {
    //   border: 1px solid $brand-accent;
    //   color: $brand-accent;
    // }

    .card-no-input:after {
      position: relative;
    }

    .card-type {
      display: block;
      content: "";
      position: absolute;
      right: 1px;
      top: 1px;
      height: 38px;
      width: 45px;
      background: #ffffff url("#{variables.$image-path}/set-c/visa.png") 6px no-repeat;
      z-index: 1;
      border-radius: 0 5px 5px 0;
    }

    label {
      padding: 0 6px;

      line-height: 14px;
    }

    md-input-container {
      margin: 16px 6px 14px;

      md-select {
        label:not(.md-no-float),
        md-input-container .md-placeholder {
          display: none;
        }
      }

      &.md-input-has-value {
        label {
          display: none;
        }
      }

      label {
        padding: 0 7px;
      }
    }

    input,
    md-select,
    md-select-value {
      height: 40px;
      line-height: 40px;
      min-height: 40px;
      background: variables.$white;
      border-radius: 3px;
      // padding: 0 10px;
      text-indent: 7px;
      border: none;
      font-size: variables.$font-size-base;
    }

    md-select-value {
      border: none;
      padding: 0;
    }

    .card-no {
      padding-right: 45px;
    }

    // .card-no-label {
    //   color: $brand-accent;
    // }
  }

  .card-cheque-detail {
    // margin-top: 12px;
    max-width: 455px;

    &.adding-cart {
      max-width: 100%;
    }
  }

  .fixed-wrapper,
  .transaction-wrapper {
    label {
      @extend .f4;
      vertical-align: middle;
    }
  }

  .function-status,
  .service-payment-status {
    &.expired,
    &.pending {
      border: 1px solid variables.$status-red;
    }

    &.canceled,
    &.void {
      border: 1px solid variables.$status-gray;
    }

    &.partially.paid {
      border: 1px solid variables.$status-yellow;
    }

    &.active,
    &.fully.paid {
      border: 1px solid variables.$status-green;
    }
  }
}

.payment-section {
  padding: 20px;

  .section-heading {
    .header-img {
      width: 34px;
      height: 34px;
    }

    .section-info.section-info-margin {
      margin-left: 44px;
    }
  }

  .payment-method-types {
    padding: 20px 20px 40px 42px;

    .payment-method-type {
      border-radius: 5px;
      max-width: 100px;
      min-width: 100px;
      min-height: 96px;
      max-height: 96px;

      .payment-method-icon {
        width: 60px;
        height: 60px;
      }

      .icon-tick {
        position: absolute;
        top: -10px;
        right: -10px;
      }

      &.cash {
        border: 1px solid #38761d;
        color: #38761d;
        background: #b6d7a8;

        .payment-method-icon {
          transform: rotate(45deg);
        }
      }

      &.cheque {
        border: 1px solid #783f04;
        color: #783f04;
        background: #f6b26b;
      }

      &.credit-card {
        border: 1px solid #415160;
        color: #415160;
        background: #acd2f1;
      }

      .others-img {
        width: 54px;
        height: 57px;
      }

      &.others-mode {
        border: 1px solid #505154;
        color: #505154;
        background: #d9dce1;
      }

      &.active-payment-type {
        border: 1px solid variables.$brand-primary;
      }
    }

  }

  .payment-service-type {
    background: #d3e1e54a;

    .payment-steps {
      background: #fff;
      color: #999999;
      padding: 5px 10px;
      border: 1px solid #999999;
      border-radius: 15px;
      min-width: 120px;
    }

    @media (max-width: 600px) {
      .payment-steps {
        margin-left: 32px;
      }
    }

    .payment-method-tabs {
      background: #d3e1e54a;
    }
  }

  .polygon-blue {
    svg {
      path {
        fill: #2f80ed;
      }
    }
  }

  .selectCard-card {
    position: absolute;
    left: 15px;
    top: 15px;
    z-index: 1;
  }

  // .sectionTitle {
  //   margin-left: 37px !important;
  // }

  .creditDetails {
    .md-errors-spacer {
      display: none !important;
    }
  }

  .card-numbers {
    display: block !important;
  }

  .md-input-invalid {
    .card-no-input {
      .card-numbers {
        width: 100%;
      }
    }

    .selectmonth-validate {
      width: 100%;
      display: block;
      // .month-validate {
      //   display: block !important;
      //     color: red;
      // }
    }

    .selectyear-validate {
      width: 100%;
      display: block;
      // .year {
      //   display: block !important;
      //     color: red;
      // }
    }

    .card-numbers {
      display: block;

      .card-validate {
        display: block !important;
        color: red;
      }
    }
  }

  .card-validate {
    display: none !important;
  }

  .full-payment-title {
    color: #486480;
    font-size: 14px;
    font-weight: bold;
  }

  .count-value {
    color: variables.$brand-accent !important;
  }

  .card-cheque-detail {
    position: relative;
    margin: 30px 0 0;
    padding: 40px 15px 0;
  }

  .pick-service {
    .all-service {
      // padding: 20px;
      border: 1px solid #ccc;
      // margin-right: 40px;
      max-height: 340px;
      min-height: 340px;
      overflow: auto;
      margin-top: 15px;
      background: #fff;
      border-radius: 5px;

      .select-radio {
        border-bottom: 1px solid #ccc;

        .md-container {
          margin-left: 10px;
          margin-top: 5px;
        }
      }

      // .select-radio:hover {
      //   background: #d3e1e54a;
      // }
    }

    .service-payment-status {
      background: #fff;
      padding: 5px;
      min-width: 100px;
      max-width: 100px;
      max-height: 27px;
      border: 1px solid #ccc;
      border-radius: 15px;
    }
  }

  .set-charge {
    .charge-type {
      // padding: 20px;
      border: 1px solid #ccc;
      margin-top: 15px;
      max-height: 340px;
      min-height: 340px;
      overflow: auto;
      background: #f0f8ff;
      border-radius: 5px;

      .payment-type {
        margin-left: 15px;
      }

      md-input-container {
        .md-errors-spacer {
          display: none;
        }
      }
    }

    .payment-details {
      margin: 8px 5px 5px;
      border: 1px solid #ccc;
      background: #fff;
      border-radius: 5px;
      min-height: 225px;

      .count-value {
        font-size: 16px;
      }
    }
  }

  .select-radio:first-child {
    margin-top: 0;
  }

  .select-radio {
    display: block;
    width: 100%;
    margin-bottom: 30px !important;
    margin-top: 15px;

    .md-label {
      display: block;
    }

    .md-container {
      top: 13%;
    }
  }

  .payment-service {
    margin-bottom: 10px;
    font-size: 14px;

    &.payment-service-btns {
      background: #20394c;
    }

    &.mr-0 {
      margin-right: 0;
    }

    .payment-service-type-btn,
    .payment-service-type-btn-custom {
      min-height: 25px;
      min-width: 120px;
      max-width: 120px;
      line-height: 25px;
      border: 1px solid #20394c;
      background: #fff;
      border-radius: 35px;
    }

    .payment-service-type-btn:hover {
      border: 1px solid variables.$brand-primary;
      color: variables.$brand-primary;
      background: #fff;
    }

    .active-payment-service-type,
    .active-payment-service-type:hover {
      background: variables.$brand-primary;
      color: #fff;
    }
  }

  .payment-type {
    margin-left: 15px;
  }

  .full-partial-percentage {
    margin-bottom: 25px;
  }

  .credit-radio {
    .md-container {
      top: 10%;
    }
  }

  .save-card-checkbox {
    // margin-left: 32px;
    margin-top: -40px;
    margin-left: 53px;
  }

  .creditDetails {
    position: relative;

    // .section-title {
    //   margin-left: 30px;
    // }
  }

  .selecting-card {
    position: absolute;
    left: 20px;
    top: 20px;
  }

  .card-list {
    position: relative;
  }

  // .adding-cart {
  //   margin-left: 31px;
  // }

  .selecting-radioCard {
    .md-container {
      top: 23px;
    }
  }

  .auto-charge-schedule {
    // padding-left: 15px;

    .auto-cycle-icon {
      width: 100%;
      height: 262px;
      // margin-top: 30px;
    }
  }

  .service-auto-schedule {
    border: 1px solid #ccc;
    margin-top: 10px;
    max-height: 292px;
    overflow: auto;

    .service-name {
      padding: 15px 10px;
    }
  }

}
@media only screen and (max-width: 600px) {
  .payment-section {
    .auto-charge-schedule {
      margin: 10px 0;

      .auto-cycle-icon {
        width: 262px;
      }
    }

    .set-charge {
      margin-top: 15px;
    }
  }

  // .recurring-pay-section {
  //   .card-details {
  //     .card-view {
  //       margin: 0 32px !important;
  //       display: block !important;
  //       width: 94% !important;
  //     }
  //   }
  // }

  .service-dates {
    margin-bottom: 15px;
  }

  .payment-service {
    margin-top: 10px;
  }

  .payment-section .pick-service .all-service {
    padding-top: 10px;
    margin-right: 0;

  }
}
@media (min-width: 600px) and (max-width: 902px) {
  .charge-type {
    .radio-percentage {
      margin-top: 16px;
    }
  }
}

/* Media Queries */
@media only screen and (max-width: 960px) {
  .payment {
    .card-cheque-detail {
      margin-right: 0;
    }

    .fixed-wrapper,
    .transaction-wrapper {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .or-divider {
      display: none;
    }
  }
}
@media only screen and (max-width: 767px) {
  .payment {
    .credit-card-tab {
      .payee-block {
        margin-top: 15px;
      }
    }

    .payment-method {
      .radio-group {
        md-radio-button {
          margin: 5px 20px 12px 0;
        }
      }
    }

    .payment-method-list {
      ul {
        li {
          // margin-top: 15px;
          width: 25%;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .payment {
    .payment-return {
      padding: 10px 20px;

      md-input-container {
        margin: 10px 0;
      }
    }

    .payee-form {
      section {
        .md-button {
          margin: 6px 0;
        }
      }
    }
  }

  .payment {
    .payment-method {
      .default-box {
        margin-top: 10px;
      }

      .amoun-percent {
        margin-top: 10px;
      }
    }

  }
}

@media only screen and (min-width: 600px) {
  .payment-section {
    .pick-service {
      margin-right: 20px;
    }

    .creditDetails:not(.auto-charge-schedule) {
      margin-right: 20px;
    }
  }
}

@media only screen and (min-width: 480px) {
  .payment {
    .payment-status .payment-info-box {
      .label {
        display: inline-block;
        margin-right: 15px;
      }
    }
  }
}
