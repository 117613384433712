@use "../../settings/third-party/variables";

.platform_setup_checklist{
    .customer-dashboard{
        .task_number_status{
            color: #999999;
            font-size: 16px;
            margin-top: 5px;
            margin-left: 90px;
        }
        .grid{
            display: grid;
        }  
        .icon_png{
            width: 50px;
            margin-right: 20px;
        }
        h1{
            color: variables.$color-blue;
        }
        .description{
            margin-left: 70px;
        }    
    }

    .custom-default-box{
        margin: 25px 20px;
        .first_cell{
            max-width: 600px;
            width: 100%;
        }
        .description{
            h3{
                color: #999999;
                margin-bottom: 5px;
                font-size: 18px;
            }
            p{
                color: #999999;
                font-size: 16px;
                margin-top: 5px;
            }

        }
        .second_cell{
            .percent_counter{
                display: inline-block;
                background: #43a4e5ff;
                border: 4px solid #06b7f8;
                color: #fff !important;
                font-weight: 600;
                font-size: 28px;
                text-align: center;
                line-height: 3.2;
                border-radius: 50px;
                padding: 0 3px !important;
                min-width: 90px;
                height: 90px;
                margin-bottom: 20px;
                margin-left: 40px;
                box-sizing: content-box;
            }
            .percent_des_label{
                display: flex;
                justify-content: center;
                background: #eb4843;
                color: #fff !important;
                font-weight: 600;
                font-size: 14px;
                text-align: center;
                line-height: 2.9;
                border-radius: 20px;
                padding: 0 3px !important;
                max-width: 175px;
                width: 100%;
                height: 40px;
                text-transform: capitalize;
                box-sizing: content-box;
            }
        } 
    }

    // code for tab section starts here

    .platform-setup-tab{
        md-tabs-wrapper {
            border-bottom: none;
        }
        .md-tab {
            text-align: left;
            padding: 0px;  
            .first_md_tab-label{
                padding-left: 35px;
            }
        }
        .md-default-theme{ 
            md-ink-bar{
                color: transparent;
                background: transparent;
            }
        }
        md-ink-bar {
            color: transparent;
            background: transparent;
        }
        md-tabs-canvas {
            display: flex;
            height: 160px;
            md-pagination-wrapper {
                height: 160px;
            }
        }
        md-tab-item.md-active {
            .type-box.warning-box {
                border: 2px solid #ff9900;
                background: #fffaf5ff;
            }
        }
        md-tab-item:hover {
            .type-box.warning-box {
                border: 2px solid #ff9900;
                background: transparent;
            }
        }

        .type_box_card_section{
            margin-left: 60px;
        }
        .type_box_card_div{
            margin-top: 30px;
        }
        .type-box {
            -moz-border-radius: 7px;
            -webkit-border-radius: 7px;
            border-radius: 7px;
            background-clip: padding-box;
            padding: 12px 16px;
            min-width: 230px;
            max-width: 250px;
            width: 100%;
            line-height: 21px;
            box-sizing: border-box;
            display: inline-block;
            margin-right: 10px;
            margin-bottom: 20px;
            border: 1px solid #a8b6c2;
            background-color: transparent;
            .type-label {
              color: variables.$white;
              font-size: 12px;
              margin-bottom: 5px;
              display: grid;
              justify-content: flex-start;
              
              .type-label.type-label-custom{
                margin-bottom: 0px;
                label{
                  padding-top: 9px;
                }
              }
            }
            
            label{
                padding-top: 4px;
                font-weight: 600;
                font-size: 18px;
                text-transform: capitalize;
                color: variables.$color-blue;
              }
        }
        .status-viewer {
            -moz-border-radius: 5px;
            -webkit-border-radius: 5px;
            border-radius: 5px;
            background-clip: padding-box;
            background: #efefefff;
            border: 1px solid #efefefff;
            height: 8px;
            margin-top: 2px;
            margin-bottom: 2px;
            overflow: hidden;
            .progress-bar {
                display: inline-block;
                height: 100%;
            }
            .progress-bar.red {
                background: #ec4843ff;
                border-radius: 50px;
            }
            .progress-bar.yellow {
                background:#ffce3a;
                border-radius: 50px;
            }
            .progress-bar.green {
                background:#6fbd6aff;
                border-radius: 50px;
            }
        }
        .type-box.warning-box{
            .type-label {
              color: #6a6a6a;
              
            }
            .custom_type_value.red {
              color: #ec4843ff;
            
            }
            .custom_type_value.yellow {
                color: #ffce3a;
              
            }
            .custom_type_value.green {
                color: #6fbd6aff;
              
            }
        }
        #label-in-front.md-checked .md-icon, #label-in-front .md-icon {
            border-radius: 50px;
        }
        .table-responsive .data-table {
            min-width: 1240px;
            max-width: 100%;
            border-left: none;
            border-right: none;
            padding-left: 0px;
            .check_box_td{
                padding-left: 95px;
                width: 10%;
                padding-right: 20px;
            }
            .watch-tooltip{
                margin-right: 0px;
                padding-left: 0px;
                margin: 0px;
                height: auto;
                min-width: auto;
                line-height: initial;
                padding: 0;
                width: auto;
                border-radius: initial;
            }
            .description_td{
                width: 70%;
            }
            td {
                padding: 30px 15px;
            }
            tr:hover {
                background-color: #e6effcff;
                .btn-secondary{
                    background-color: #43a4e5;
                    color: variables.$white;
                }
            }
            
            .playicon_png{
                width: 40px;
                margin-top: 5px;
            }
            .heading{
                font-size: 20px;
                font-weight: 600;
                color: #43a4e5;
                
            }
            .sub_heading{
                font-size: 16px;
                color: #6a6a6a;
                font-weight: 500;
            }
            .btn-secondary{
                border: 1px solid #43a4e5;
                color: #43a4e5;
                background-color: transparent;
                max-width: 140px;
                width: 100%;
            }
            .btn_label{
                margin-left: 25px;
                color: #6a6a6a;
                font-size: 14px;
            }
        }
        .check_box_td{
            md-checkbox{ 
                .md-container {
                    height: 40px;
                }
                .md-icon {
                    width: 47px;
                    height: 47px;
                    
                }
                .md-icon:after{
                    border-color: #5CC5C3;
                    position: absolute;
                    left: 18px;
                    top: 7px;
                }
            }
            md-checkbox.md-checked{
                .md-icon {
                    width: 47px;
                    height: 47px;
                    background-color: #33c22c;
                }
                // .md-icon:before {
                //     background-color:black;
                // }
                .md-icon:after {
                    border-color: variables.$white;
                    position: absolute;
                    left: 17px;
                    top: 7px;
                    width: 10px;
                    height: 24px;
                    border-width: 3px;
                }
                .btn-secondary{
                    background-color: #43a4e5;
                    color: variables.$white;
                }
            }
        }
        .faq_section{
            margin: 20px 25px;
            .faq_heading{
                margin-top: 20px;
                margin-bottom: 35px;
            }
            .description{
                h4{
                    margin-top: 0px;
                    margin-bottom: 0px;
                }
            }
            .faq_content{
                float: right;
                position: absolute;
                left: 45px;
            }
            .custom_arrow{
                left: 16px;
                transform: rotate(180deg);
                svg{
                    path{
                      fill: #ff9900;  
                    }
                }
            }
            .panel {
                margin-bottom: 25px;
            }
            hr{
                background: #dddddd none repeat scroll 0 0;
                border: 0 none;
                height: 1px;
                position: relative;
                top: 3px;
                margin-right: 20px;
                margin-left: 20px;
            }
            .faq_show_more{
                font-size: 20px;
                font-weight: 600;
                color: #ff9900;
                margin-left: 70px;
                margin-top: 20px;
                .custom_arrow{
                    transform: rotate(-90deg);
                }
            }
            .faq_footer_content{
                margin-left: 70px;
                margin-top: 20px;
                font-size: 15px;
                font-weight: 500;
                color: #6a6a6a;
                display: block;
                .faq_a_tag{
                    margin:0px 5px;
                }
            }
            
        }
        
        
        
    }

    // code for tab section ends here
    
    .font-bold{
        font-weight: 600;
    }
}
@media (max-width:600px) {
    .platform_setup_checklist{
        .md-toolbar-tools{
            .back-btn{
                min-width: 20px;
                width: 100%;
            }
        }
        .customer-dashboard{
            .second_cell{
                max-width: 800px;
                width: 100%;
                text-align: center;
                .percent_des_label {
                    margin: 0px auto;
                }
            }
           
            .type_box_card_div {
                margin-right: 20px;
            }
            .type-box {
                min-width: 200px;
            }
            .platform-setup-tab{
                .type_box_card_section.first_md_tab-label {
                    margin-left: 0px;

                }
            } 
            
            .faq_section{
                .faq_show_more,.faq_footer_content{
                    margin-left: 0px;
                }
                .description {
                    margin-left: 0px;
                 }
            }
        }
        .platform-setup-tab{
            .table-responsive{
                .data-table{
                    .check_box_td {
                        padding-left: 10px;
                        width: 5%;
                    }
                }
            } 
        }
    } 
}
@media (max-width:960px) {
    .platform_setup_checklist{
        .customer-dashboard{
            padding: 0px 25px;
            .faq_section{
                margin: 20px 25px 40px;
            }
            .platform-setup-tab{
                .type_box_card_section.first_md_tab-label {
                    padding-left: 0px;
                }
            } 
        }
        .custom-default-box{
            .second_cell {
                .percent_counter {
                    margin-left: 22px;
                }
            }
        }
    } 
}

