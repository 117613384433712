@use "../../settings/third-party/mixins";
@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

/*--Customer DashBoard--*/

.customer-dashboard {
  .default-box {
    margin: 25px 20px;
    position: relative;
    //text-align: center;
    // img {
    //     max-width: 100%;
    //     margin: 0 auto;
    //     border-radius: 12px;
    // }
    .title {
      color: variables.$gray-darker;
      margin: 0 8px 0 0;
      font-size: variables.$font-size-base;
      font-weight: normal;
    }

    .type-box {
      padding: 12px 16px;
      min-width: 140px;
      line-height: 21px;
      box-sizing: border-box;

      .type-label {
        color: #7f7f7f;
        font-size: variables.$font-size-small;
      }

      .type-value {
        color: #494949;
        font-size: variables.$font-size-base;
      }
    }

    .earning-type-list {
      margin-top: 20px;
      max-width: 350px;

      li {
        margin-bottom: 15px;

        &.selected .type-box {
          @include mixins.border-radius(7px);

          border: 1px solid #c8e0ba;
          background: #e4f9d9;
        }
      }
    }
  }

  .dashboard-details {
    margin: 12px 20px;
    border-top: 1px solid variables.$gray-lighter;
    padding-top: 20px;

    h2 {
      font-weight: normal;
      margin: 0 0 8px;
      font-size: variables.$font-size-base;
      color: variables.$gray-darker;
    }

    .dashboard-list {
      border-right: 1px solid variables.$gray-lighter;
      margin-right: 20px;

      ul {
        li {
          padding: 15px 20px 15px 0;
          @extend .clearfix;

          md-icon {
            float: left;
            margin-right: 10px;
          }

          label {
            font-size: variables.$font-size-base;
            color: variables.$gray-dark;
            float: left;
            padding-top: 5px;
          }

          span {
            @extend .f4;
            float: right;
            padding-top: 8px;
          }
        }
      }
    }

    .recent-list {
      margin-right: 0;

      ul {
        li {
          padding: 16px 20px 16px 0;
        }
      }
    }

    .recent-contacts {
      h2 {
        padding-left: 20px;
      }

      ul {
        li {
          padding-top: 8px;
          padding-bottom: 9px;
          padding-left: 10px;
          margin-left: 10px;
          border: 1px solid variables.$white;

          &:hover {
            border-color: variables.$white-darker;
            border-radius: 3px;
          }

          md-icon {
            margin: 0;
          }

          .user-icon {
            height: 35px;
            width: 35px;
            margin-right: 8px;

            svg {
              border-radius: 100%;
              border: 1px solid variables.$gray-lighter;
            }
          }

          .user-sub-icon {
            height: 14px;
            width: 18px;
            margin-top: 5px;
          }

          label {
            font-size: variables.$font-size-base;
            color: variables.$gray-dark;
          }
        }
      }
    }

    .list-all-link {
      display: inline-block;
      margin-top: 15px;
      margin-bottom: 15px;
      margin-left: 5px;
      font-size: variables.$font-size-base;
    }
  }
}

@media only screen and (min-width: 600px) {
  .customer-dashboard {
    .default-box {
      padding-top: 30px;

      &:before {
        content: '';
        border-right: 1px solid variables.$gray-lighter;
        display: inline-block;
        height: 80%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 1px;
      }

      .title {
        &.gap-left {
          margin-left: 15px;
        }
      }

      .inner-cell {
        &:first-child {
          padding-right: 20px;
        }

        &:last-child {
          padding-left: 20px;
        }
      }

      .head-section {
        margin-top: -10px;
      }

      .earning-type-list {
        li {
          display: inline-block;
          margin-right: 15px;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .customer-dashboard .dashboard-details .dashboard-list {
    border-right: 0;
    margin-right: 0;
  }

  .customer-dashboard .dashboard-details h2 {
    margin-top: 10px;
  }

  .customer-dashboard .dashboard-details .recent-contacts h2 {
    padding-left: 0;
  }

  .customer-dashboard .dashboard-details .recent-contacts ul li {
    padding-left: 0;
    margin-left: 0;
  }

  .customer-dashboard {
    .default-box {
      padding: 20px;

      .inner-cell {
        &:not(:last-child) {
          margin-bottom: 25px;
        }
      }

      .title {
        margin-bottom: 8px;
      }
    }
  }
}

/*Customer DashBoard End*/
