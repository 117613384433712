/*ellipsis classes*/
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.ellipsis-110,
.ellipsis-100,
.ellipsis-150,
.ellipsis-200,
.ellipsis-250,
.ellipsis-300,
.ellipsis-320,
.ellipsis-125 {
  @extend .ellipsis;
}

.ellipsis-110 {
  max-width: 110px;
}
.ellipsis-125 {
  max-width: 125px;
}
.ellipsis-100 {
  max-width: 100px;
}

.ellipsis-150 {
  max-width: 150px;
}

.ellipsis-200 {
  max-width: 200px;
}

.ellipsis-250 {
  max-width: 250px;
}

.ellipsis-300 {
  max-width: 300px;
}
.ellipsis-320{
  max-width: 320px;
}

.ellipsis-line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &.line-2 {
    -webkit-line-clamp: 2;
  }
}
