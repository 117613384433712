@use "../../settings/third-party/variables";

md-menu-content.schedu md-menu-item {
  height: 150px;
}

.filter-input {
  width: 200px;
  padding: 0 30px 0 0;
}

// .additional-pop {
//   min-width: 70%;
//   max-width: 90%;
// }
.procurement-auto-purchase-popup {
  min-width: 50%;
  max-width: 90%;

  .icon-tick {
    margin: 8px 8px 0 0;
  }

  .procurement {
    padding: 0;
  }

  .form-base {
    margin-bottom: 0;

  }

  .choose-supplier-section {
    padding: 0 20px;

    .radio-button-grp {
      padding: 10px 0;
    }
  }

  .rl-gap {
    padding: 0 18px;

  }

  .data-table {
    border: none;
  }

  .purchase-footer {
    background-color: variables.$brand-table;
    height: 50px;

    .btn-primary {
      float: right;
    }
  }

  .f2 {
    color: variables.$white;
  }

  thead {
    background-color: variables.$white;

    th {
      border-bottom: 1px solid variables.$brand-table;
    }
  }
}
