@use "../../settings/third-party/variables";

.guider-sidebar {
  .product {
    .task-wrapper {
      top: 104px !important;
    }

    .search-list li.gap {
      padding: 10px 15px;
    }
  }

  .opportunities {
    .task-wrapper {
      top: 178px !important;
    }
  }

  .customer {
    .task-wrapper {
      top: 104px !important;
    }
  }

  .promotion {
    .task-wrapper {
      top: 104px !important;
    }

    // .res-alloc-parent {
    //   margin-top: -18px;
    // }
  }

  .action-list {
    .assign-type {
      margin: 10px 15px;
    }

    .task-wrapper {
      top: 143px !important;

      &.acc {
        top: 145px;
      }
    }

    // .resource-allocation {
    //   &.icon {
    //     padding: 3px;
    //   }
    // }

    .resource-organisation {
      &.primary {
        margin-top: -2px;
      }
    }

    // .resource-data {
    //   padding: 0 12px 0 0;
    // }
  }

  .cal-icon {
    // margin-top: -4px;
    height: 14px;
    z-index: 4;
    // position: absolute;
    /*margin-top: 12px;*/
    svg {
      path {
        fill: variables.$brand-primary;
      }
    }
  }

  // .accordionN {
  //   .cal-icon {
  //     margin-top: 0;
  //   }
  // }

  .header {
    padding: 8px 0;
    margin: 0 8px;
  }

  .md-select-value {
    .location-symbol {
      font-size: 10px;
      text-align: center;
      line-height: 1.9;
      margin-right: 10px;
    }
  }

  .select-wrapper {
    margin: 0;

    .sidenav-select {
      margin-top: 3px !important;
    }
  }

  .md-icon-button {
    width: 100px;
    padding: 0;
    border-radius: 0;
  }

  .item {
    z-index: 1;
    position: relative;
  }

  .sidenav-title {
    margin-top: 0;

    h2 {
      margin-left: 0;
    }
  }

  .ar-sidepanel {
    md-input-container {
      .sidenav-select {
        width: 92%;
        margin: -5px 13px 0 !important;

        md-select {
          md-select-value {
            span {
              .location-symbol {
                font-weight: 500;
                font-size: 10px;
                display: inline-block;
                margin-right: 8px;
              }
            }
          }
        }
      }
    }

    .sidenav-title {
      // .search-button {
      //   top: 3px;
      //   // top: 0;
      //   z-index: 2;
      // }
      //
      // .search-box {
      //   // top: 8px;
      //   // right: 5px;
      //   top: 12px;
      //   right: 5px;
      // }
      //
      // .search-box:focus {
      //   width: 100px;
      //   height: 20px;
      // }

      h2 {
        margin-left: 5px;
      }
    }

    .task-list {
      .custom-data {
        width: 100%;
        padding: 5px 0;
      }

      .accordionN-section-content {
        md-list-item {
          .md-no-proxy {
            padding-left: 40px;
            padding-right: 18px;
          }
        }

        md-list {
          padding: 0;
        }

        .icon-side-tick {
          left: 46px;
        }
      }
    }

    .task-wrapper {
      position: absolute;
      top: 135px;
      bottom: 50px;
      overflow: auto;
      left: 0;
      right: 0;

      .active {
        color: variables.$brand-success; //green:
      }

      .draft {
        color: variables.$brand-primary; //brand-primary:
      }

      .danger {
        color: variables.$brand-danger;
      }

      .schedule-list {
        li {
          padding-left: 50px;
        }
      }

      .custom-data {
        span {
          margin: 2px 0;
        }
      }
    }

    .function {
      .task-list {
        md-list-item {
          .md-no-proxy {
            padding-left: 12px;
            padding-right: 12px;
          }
        }
      }
    }
  }

  .schedule-list {
    span {
      color: variables.$gray-base;
    }
  }

  .resource-allocation {
    .statistics {
      padding-right: 15px;
      margin-top: 5px;
    }
  }

  .top-section {
    .sidenav-title {
      .resource-icon {
        width: 34px;
        height: 34px;
        margin: 0;
        cursor: default;

        svg {
          path {
            fill: #fab963;
          }
        }
      }

      .search-symbol {
        width: 20px;
        height: 20px;
        margin: 0;
      }
    }
  }

  .task-list {
    .schedule-select {
      background-color: variables.$brand-info-light;
    }
  }

  .acc-cal-head {
    color: variables.$gray-base;
    font-size: variables.$font-size-small;
  }

  // .schedule-list p {
  //   color: $brand-primary;
  //   text-overflow: ellipsis;
  //   padding: 0 !important;
  //   display: inline !important;
  //   vertical-align: middle;
  // }

  .resource-assign {
    .resource-organisation {
      margin: 0;
      font-size: 11px;
    }
  }
}

// .assign-type {
//   md-radio-group md-radio-button {
//     margin-right: 50px;
//   }
// }
/*.support-accordion.accordionN .accordionN-section-content md-list md-list-item {
  padding-left: 0;
}*/
// .icon-side-tick {
//   position: absolute;
//   height: 15px;
//   width: 15px;
//   left: 40px;
//   top: 10px;
// }

// .resource-assign {
//   width: 100%;
// }

// .md-select-menu-container {
//   left: 1138px !important;
//   min-width: 290px !important;
//
//   .location-symbol {
//     margin-right: 8px;
//   }
// }

// .md-product {
//   width: 40px;
//   height: 40px;
//   box-sizing: content-box;
//   margin: 8px 10px 8px 0;
// }
