@use "../../settings/third-party/variables";

.bulk-scan-serials-template {
    &.sms-message {
        .accepting_cards {
            .chat-list-border {
                border-left: 5px solid variables.$brand-primary;
            }
            .chat-list{
                min-width: 320px;
            }
            .proflie_section {
                .bulk-scan-serials-list {
                    padding: 0 0 0 10px;
                }
                .font-color-gray {
                    font-size: 13px;
                    color: #989898;
                }
            }
            .icon-play {
                svg, path {
                    fill: #fff;
                    stroke: #2196f3;
                }
            }
        }
    }
    .chat-unreads-div {
        .h1-font-size {
            font-size: variables.$font-size-h3 !important;
            font-weight: bold !important;
        }
        .img-container{
            .img{
                height: 45px;
                width: 45px;
                border-radius: 50%;
            }
        }
    }

    .stock-details {
        min-width: 120px;
        border: 2px solid variables.$gray-light;
        padding: 4px 15px 4px 10px;
        font-size: 10px;
        color: #606060;
    }

    .scan-results {
        border: 0;
        background-color: rgba(211, 225, 229, 0.71);
        padding: 5px 10px;
        font-size: 10px;
    }

    .result-font {
        font-size: 18px;
        color: #000;
    }

    .copy-label {
        padding: 0 5px;
        background-color: #5e696c;
        font-size: 10px;
    }

    .copy-label:hover {
        background-color: #5e696c;
    }

    .select-all {
        .md-container {
            border: 1px dotted #606060;
            border-radius: 2px;
            width: 24px;
            height: 25px;
            .md-icon {
                top: 1px;
                left: 1px;
            }
        }
    }

    .content_div {
        &.bulk-scan-results {
            padding-bottom: 0;
        }
    }

        .loader-gif {
            height: 21px;
        }
        input {
            font-size: 12px;
        }

    .icon-background {
        border-radius: 30px;
        background-color: #fff;
        .icon-inventory{
            height:40px;
            width:40px;
            background-color: white;
            border-radius: 50%;
        }
    }
    .icon-scan{
        height: 45px;
        width: 45px;
    }
    .serials{
        width: 300px;
        margin-right: 0;
        height: 8px;
    }
    .data-table{
        border: none;
        tr{
            border: none;
            td{
                border: none;
            }
        }
    }
    .sync{
        color: variables.$brand-primary;
    }
}

md-dialog {
    &.bulk-scan-result-template {
        max-width: 900px;
        width: 100%;
        .md-sidenav-right {
            .list-data{
                .export-cta-div {
                    .data-setup-cta {
                      background-color: variables.$brand-primary;
                      color: variables.$white;
                    //   border: 1px solid $brand-primary;
                      border-radius: 50px;
                      min-width: 120px;
                      max-height: 15px;
                      .download{
                        color: variables.$white;
                        font-size: variables.$font-size-small;
                      }
                    }
                    .download-cta {
                        a {
                            color:variables.$white;
                            font-size: variables.$font-size-small;
                          }
                    }
                    .na-cta {
                        background-color: variables.$brand-primary;
                        color:#ffffff;
                      //   border: 1px solid #cccccc;
                      }
                }
        
            }
        }
        .icon-flag {
            height: 30px;
        }
    }
  
}
