@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.bundle-product-section {
  &.package-section {
    .attached-files-section .md-icon-button.add-btn {
      margin-left: 0;
      margin-right: 8px;
    }

    .summery-widget {
      .widget-title {
        @extend .f1;

        font-weight: 500;
        margin-top: 0;
        margin-bottom: 15px;
      }

      .md-button {
        margin-left: 0;
      }

      .test-result {
        color: variables.$gray-dark;
        line-height: 20px;
        margin-top: 10px;
      }
    }

    // .md-button.md-icon-button.add-btn {
    // 	margin-bottom: 10px;
    // }
  }

  .form-base {
    .section-title {
      @extend .fm;

      margin-top: 0;
    }

    md-radio-button {
      font-size: variables.$font-size-small;
      margin-bottom: 16px;
    }
  }

  .bundle-setup-section {
    margin-top: 18px;

    .assemble-price {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  .product-img-list {
    .image-type {
      margin-top: 0;
      margin-bottom: 25px;
    }
  }
}

.bundleProduct-sidebar {
  .leftnav-wrap {
    position: relative;

    .bottom-section,
    .task-wrapper {
      position: absolute;
      right: 0;
      left: 0;
    }
  }

  .task-wrapper {
    top: 0;
    bottom: 50px;
    overflow-y: auto;
    padding-top: 20px;
  }

  .bottom-section {
    bottom: 1px;
  }

  .sidebar-title {
    font-weight: 500;
    font-size: variables.$font-size-base;
  }

  // .head-section {
  // 	border-bottom: 1px solid $white-darker;
  // 	font-weight	: 500;
  // 	padding-left: 18px;
  // 	padding-right: 18px;
  // }

  .sidebar-title,
  md-input-container {
    margin-right: 18px;
    margin-left: 18px;
  }

  .search-list {
    li {
      padding-right: 18px;
      padding-left: 18px;
    }
  }
}

// .ui-accordion.bundle-list {
// 	.arrow svg path {
// 		fill: $white;
// 	}

// 	.panel-group {
// 		border: none;
// 		margin-bottom: 0;

// 		.panel {
// 			border: none;
// 		}

// 		.panel,
// 		.panel-open	{
// 			.panel-heading {
// 				background: $white-darker;
// 			}
// 		}
// 	}

// 	.panel-title > a {
// 		box-sizing: border-box;
// 	}

// }

.bundleProduct-viewSection {
  .inner-tabs {
    margin-top: 12px;
    margin-bottom: 30px;

    label {
      color: variables.$gray-darker;
      cursor: pointer;
      font-weight: 500;
      padding-right: 20px;

      &.active {
        color: variables.$brand-primary;
        font-weight: normal;
      }
    }
  }

  .table-title {
    color: variables.$gray-dark;
    margin-bottom: 18px;
  }

  .catalog-cb-section {
    position: absolute;
    top: 20px;
    right: 0;
  }
}

@media only screen and (min-width: 600px) {
  .bundle-product-section {
    &.package-section {
      .detail-right-section {
        width: 25%;
      }
    }

    .bundle-setup-section {
      float: left;
      width: 35%;
    }

    .summery-widget {
      float: right;
    }
  }
}
