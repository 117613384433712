.quote-frame .estimate-section .sidebar-toggle-btn {
  margin-top: 20px;
  margin-right: 20px;
  text-align: right;
}

.quote-frame .estimate-section .no-data-found {
  padding: 60px 0 19px;
  margin: 0 20px;
  border-radius: 5px;
  text-align: center;
  font-size: 20px;
  color: #606060;
  height: 200px;
}
