@use "../settings/third-party/variables";

.activity-board {
  .notification-card {
    margin-top: 20px;
  }

  .highlight-lane {
    min-width: 180px;
    padding: 0 18px 0 2px;

    .header-text {
      margin: 30px 0 15px;
      padding: 0 15px;
    }

    .total-stats {
      padding: 0 35px 0 25px;
    }

    .single-stat {
      padding: 0 19px 0 9px;

      &:not(:last-of-type) {
        margin-bottom: 25px;
      }
    }
  }

  .profile-lane {
    margin-top: 140px;

    md-card-content {
      padding: 11px 10px;
    }

    .add-function-icon {
      margin-bottom: 10px;
    }

    .img-container {
      margin-right: 0;

      &:not(:last-of-type) {
        margin-bottom: 15px;
      }
    }

  }

  .img-replacer {
    height: 24px;
    width: 24px;
    line-height: 24px;
    font-size: 10px;

    &.md-avatar {
      line-height: 30px;
      font-size: 12px;
    }
  }

  .days-filter {
    margin: 15px 0 20px;

    .btn-primary:not(.selected) {
      color: variables.$gray-base !important;
      background: #d4d4d4;
    }
  }

  .activity-container {
    padding: 2px;

    md-list {
      padding-top: 0;
      max-height: 817px;
      overflow-y: scroll;
    }

    .table-section md-card {
      margin-bottom: 30px;

      h2 {
        margin-top: 0;
      }

      @media screen and (min-width: 960px) {
        &:last-of-type {
          margin: 0;
        }
      }
    }
  }

  .activities-list {
    md-card-title {
      background: variables.$gray-dark;
      padding: 8px;
    }

    md-list-item {
      padding: 16px 16px 8px;
      min-height: auto;

      &:hover {
        background: variables.$gray-lighter;
      }

      &:before {
        min-height: auto;
      }

      md-icon {
        flex: none;
      }

      .md-avatar {
        margin: -7px 10px 8px 0;
        width: 30px;
        height: 30px;
        overflow: hidden;
      }

      .md-list-item-text {
        margin: 0;
      }
    }

    .profile-name {
      font-size: 14px;
      color: variables.$gray-dark;
    }

    .activity-description {
      margin: 5px 0;
    }

    .created-time {
      margin-right: 15px;
    }

    .activity-type {
      color: variables.$white;
      padding: 0 6px;
      border-radius: 2px;
      text-transform: capitalize;
      margin-right: 10px;

      &.general {
        background: rgb(101, 198, 241);
      }

      &.pricing {
        background: #556080;
      }

      &.payment {
        background: variables.$brand-primary;
      }
    }
  }

}
