.quote-header md-toolbar.main-header .md-toolbar-tools .md-icon-button md-icon {
  margin-left: 5px;
}

.quote-header {
  md-toolbar.main-header .dropdown-btn .md-button {
    text-align : center;
    font-weight: 500;
  }
}

md-toolbar.main-header .md-toolbar-tools .md-icon-button md-icon.icon-menu {
  height: 21px;
  width : 21px;
}
