
@use '../../business/inventory/import-inventory';
@use "../../settings/third-party/variables";

.upload-setting-section {
  .inner {
    background: #FAFBFC;
    box-sizing: border-box;
    height: 160px;
    padding-top: 34px;
    padding-bottom: 34px;

    &.uploading {
      .progress-btn {
        md-icon svg path {
          fill: variables.$brand-primary;
        }
      }

      md-progress-linear .md-bar {
        background-color: variables.$brand-accent;
      }
    }

    &.completed {
      .progress-btn {
        md-icon svg path {
          fill: variables.$brand-success-dark;
        }
      }

      md-progress-linear .md-bar {
        background-color: variables.$brand-success-dark;
      }
    }

    &.failed {
      background: #EFA8A8;
      border-color: #F9E1E1;

      .progress-btn {
        md-icon svg path {
          fill: variables.$brand-danger-dark;
        }
      }

      md-progress-linear {
        .md-bar,
        .md-container {
          background-color: variables.$brand-danger-dark;
        }
      }
    }
  }

  .btn-setting {
    position: absolute;
    top: 0;
    right: -6px;

    md-icon {
      height: 20px;
      width: 20px;

      svg path {
        fill: #a19f9f;
      }
    }
  }

  .icon-button {
    height: auto;
    min-height: auto;
    width: auto;
  }

  .record-section {
    margin-bottom: 18px;

    .icon-records {
      height: 68px;
      margin-bottom: 8px;
      width: 72px;
    }
  }

  .file-selector {
    font-size: variables.$font-size-base;
  }

  .download-link {
    display: inline-block;
    margin-top: 8px;
  }

  .upload-progress-section {
    .icon-file {
      height: 35px;
      margin-left: 10px;
      margin-right: 18px;
      width: 26px;
    }

    .progress-btn {
      margin-right: -8px;

      md-icon {
        height: 15px;
        width: 15px;
      }
    }
  }

  .refresh-btn {
    margin-left: 0;

    md-icon {
      height: 18px;
      width: 18px;

      svg path {
        fill: #a19f9f;
      }
    }
  }

  .pwd-strength-checker {
    md-progress-linear {
      &,
      .md-container {
        &,
        .md-bar {
          height: 2px;
        }
      }

      .md-container {
        background-color: variables.$white-dark;
      }
    }
  }
}

.upload-setting-popup {
  height: 540px;
  width: 800px;

  md-dialog-content {
    padding: 28px 18px 18px;
    line-height: 20px;
  }

  .main-header {
    h2 {
      font-size: 16px;
    }
  }

  .fields-section {
    margin-top: 25px;

    .field-list {
      margin-top: 16px;
    }
  }
}

@media screen and (min-width: 600px) {
  .upload-setting-popup {
    md-dialog-content {
      padding-left: 55px;
    }

    .field-list li {
      display: inline-block;
      margin-right: 16px;
    }
  }
}
