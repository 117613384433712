@use "third-party/variables";

// @use url(https://fonts.googleapis.com/css?family=Roboto:400,300italic,300,700,500);

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  position: relative;
  font-size: variables.$font-size-small;
  font-family: Roboto,"Helvetica Neue",sans-serif;
}

// fix for br tag standard in firefox
.break-space {
  padding-bottom: 14px;
}

input,
textarea {
  outline-style: none;
}
/*-for image link-*/

img {
  padding: 0;
  margin: 0;
  border: 0;
}

a img {
  /*filter: alpha(opacity=100); opacity:1; -moz-opacity:1; -webkit-opacity:1;*/
  border: 0;
}

a:hover img {
  /*filter: alpha(opacity=90); opacity:.9; -moz-opacity:.9; -webkit-opacity:.9;*/
  border: 0;
}
/*Heading Definitions*/

h1,
h2,
h3,
h4,
h5,
h6 {}

a {
  outline: 0;
  text-decoration: none;
  color: variables.$brand-primary;
  cursor: pointer;

  &[disabled] {
    color: variables.$gray;
    cursor: not-allowed;
  }
}

a:not([disabled]):active,
a:not([disabled]):hover {
  outline: 0;
  text-decoration: none;
  color: variables.$brand-primary;
  cursor: pointer;
}
/* Anchors a img { border: 0px; text-decoration: none; opacity:0.9; }
a:link, a:visited {  }

a:focus {outline-style: none;}
strong, b { font-weight:800; }
p b, p strong { font-weight:600; }
p { font-family: 'Roboto-Light', sans-serif;  }
.alert strong { font-weight:700; }
p a{text-decoration:underline; }
h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover { text-decoration:none; }
*/
/*--Commonly Used Classes works according to name-*/
/*---Style Guide CSS---*/

h1 {
  font-size: 20px;
}

h2 {
  font-size: variables.$font-size-base;
  outline: none;
}

// pre {
//   white-space: normal;
// }

.f1 {
  font-size: variables.$font-size-base;
  color: variables.$gray-dark;
}
/*-- F1: 14px #333333--*/

.f2 {
  font-size: variables.$font-size-base;
  color: variables.$gray;
}
/*-- F2: 14px #A0A0A0--*/

.f2-smallest {
  font-size: 10px;
  color: variables.$gray;
}

.f2-small {
  font-size: variables.$font-size-small;
  color: variables.$gray;
}

/*-- F2-small: 12px #A0A0A0--*/

.f3 {
  font-size: variables.$font-size-small;
  color: variables.$gray-dark;
}
/*-- F3: 12px #333333--*/

.f3-light {
  font-size: variables.$font-size-small;
  color: #606060;
}
/*-- F3-light: 12px #606060--*/

.f4 {
  font-size: variables.$font-size-small;
  color: variables.$gray;
}
/*-- F4: 12px #A0A0A0--*/

.f5 {
  font-size: variables.$font-size-small-plus;
  color: variables.$gray-dark;
}
/*-- F5: 13px #333333--*/

.f6 {
  font-size: variables.$font-size-base;
  color: variables.$white;
}
/*-- F6: 14px #ffffff--*/

.f7 {
  font-size: variables.$font-size-small;
  color: variables.$white;
}
/*-- F7: 12px #ffffff--*/

.f8 {
  font-size: variables.$font-size-small;
  color: variables.$brand-primary;
}

/* new F8: 12px   #FAB963--*/

.f9 {
  color: variables.$gray;
  font-size: variables.$font-size-base;
}

.fm {
  font-weight: 500;
}

.hide {
  display: none;
}

.mt-0 {
  margin-top: 0!important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.no-flex {
  flex: none;
}

.no-border {
  border: none !important;
}

.z1 {
  z-index: 1;
}

.cursor-point {
  cursor: pointer;
}

.no-cursor {
  cursor: default !important;
}

.cursor-disabled {
  cursor: not-allowed !important;
}

.left {
  float: left;
}

.right {
  float: right;
}

.clear {
  clear: both;
}

.scroll-x {
  overflow-x: auto;
  overflow-y: hidden;
}

.scroll-y {
  overflow-x: hidden;
  overflow-y: auto;
}

.scroll-hide {
  overflow: hidden !important;
}

.scroll-auto {
  overflow: auto;
}

.clearfix:after,
.clearfix:before {
  clear: both;
  content: "";
  display: block;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.v-align-mid {
  vertical-align: middle;
}

.no-style {
  margin: 0;
  padding: 0;
  list-style: none;
  outline: none;
}

.center-block {
  text-align: center;
  display: block;
}

.PosR {
  position: relative !important;
}

.PosA {
  position: absolute !important;
}

:focus {
  outline: 0;
}

.override-max-height {
  max-height: none !important;
}

.camelcase {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}
/*---Buttons Setting---*/

.btn {
  font-size: variables.$font-size-base;
  font-weight: 500;
  cursor: pointer;
  padding: 7px 10px;
}

.btn-default {
  &.bordered {
    border: 1px solid #d6d7d8;
    transition: border-color 1s box-shadow 1s;

    &:not(.disabled):hover {
      background-color: white;
      box-shadow: 0 0 8.37px 0.63px rgba(82, 117, 141, 0.35);
      border-color: variables.$brand-primary;
    }
  }
}

.btn-primary {
  &.bordered {
    box-shadow: 0 0 8.37px 0.63px rgba(82, 117, 141, 0.35);
    border: 1px solid variables.$brand-primary;
  }
}

.btn-primary,
.btn-primary.md-button:not([disabled]).md-focused {
  background-color: variables.$brand-primary;
  color: variables.$white;
}

.btn-primary:hover,
.md-button.btn-primary:hover {
  background-color: variables.$brand-primary-dark;
  color: variables.$white;
}

.btn-primary:disabled,
.md-button.btn-primary[disabled]:hover {
  background-color: variables.$brand-primary-light!important;
  color: #e2c5a6!important;
  cursor: not-allowed;
}

.btn-secondary:not(.btn-primary) {
  background-color: variables.$white-dark;
  color: #8e8e93;
}

.btn-secondary:not(.btn-primary):hover {
  background-color: #c7c7c9;
  color: #8e8e93;
}

.btn-secondary:disabled,
.md-button.btn-secondary[disabled]:hover {
  background-color: variables.$white-dark;
  color: #7e7e7e;
  cursor: not-allowed;
}

.btn-selected,
md-toolbar:not(.md-menu-toolbar) .btn-selected:not(.md-raised) {
  border: 1px solid variables.$brand-primary;
  color: variables.$gray-dark;
}

.md-button {
  text-transform: capitalize;
}

.btn-danger.btn-primary.md-button,
.btn-danger.btn-primary.md-button:not([disabled]).md-focused {
  background-color: variables.$brand-danger-dark;
  color: variables.$white;
}

.btn-danger.btn-primary.md-button:hover {
  background-color: variables.$brand-danger;
}

.btn-bordered {
  border: 1px solid variables.$brand-primary;
  color: variables.$brand-primary;
  box-shadow: 0 0 8.37px 0.63px rgba(82, 117, 141, 0.35);
}

.btn-success {
  background-color: #6aa84f;
  color: #fff;
}

md-radio-button[disabled],
md-radio-button[disabled] .md-container {
  opacity: 0.5;
}
/*--Text Color Styles Start--*/

.text-success {
  color: variables.$brand-success-dark !important;
}

.text-danger {
  color: variables.$brand-danger-dark !important;
}

.text-warning {
  color: variables.$brand-warning !important;
}

.text-accent {
  color: variables.$brand-accent !important;
}

.text-white {
  color: variables.$white !important;
}
/*--Text Color Styles End--*/
/*--All Type of Alerts--*/

.alert {
  display: inline-block;
  padding: 8px 15px;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #fff;
  font-weight: 700;
  font-size: variables.$font-size-base;

  &.alert-success {
    background-color: variables.$brand-success;
    border-color: variables.$brand-success;
  }

  &.alert-danger {
    background-color: variables.$brand-danger;
    border-color: variables.$brand-danger;
  }

  &.alert-warning {
    background-color: variables.$brand-warning;
    border-color: variables.$brand-warning;
  }
}

.note {
  font-size: 12px;
  color: variables.$gray-darker;
  margin-bottom: 10px;
  line-height: 1.5;
  padding: 10px;
  // background: $white-dark;
  border: 1px dashed variables.$brand-accent;
  border-left: 5px solid variables.$brand-accent;

  .note-label {
    margin-right: 10px;
    color: variables.$brand-accent;

    md-icon {
      margin-right: 5px;
    }
  }

  &.warning {
    border-color: variables.$brand-warning;

    .note-label {
      color: variables.$brand-warning;

      md-icon path {
        fill: variables.$brand-warning;
      }
    }
  }

  &.danger {
    border-color: variables.$brand-danger;

    .note-label {
      color: variables.$brand-danger;

      md-icon path {
        fill: variables.$brand-danger;
      }
    }
  }
}

.alert-wrapper,
.note-wrapper {
  margin-bottom: 25px;
}

.archive-note {
  padding: 10px;
  background: variables.$brand-warning;
  font-size: 16px;

  md-icon path {
    fill: variables.$white;
  }

  .view-active-cta {
    color: variables.$white;
  }

  .close-note {
    height: 15px;
  }
}

.new-records-note {
  background: #d9ead3;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #008000;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.full-width {
  width: 100%;
}

.width-size-300 {
  width: 300px;
}

.font-italic {
  font-style: italic;
}

.font-bold {
  font-weight: bold;
}

.text-normal {
  font-weight: normal !important;
}

.mr-10 {
  margin-right: 10px;
}

.abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bold-text {
  font-weight: bold;
  font-size: 14px;
  color: variables.$brand-primary !important;
}

.capitalize {
  text-transform: capitalize;
}

.icon-15 {
  height: 15px;
  width: 15px;
}

.icon-18 {
  height: 18px;
  width: 18px;
}

.icon-35 {
  height: 35px;
  width: 35px;
}

@for $i from 0 through 10 {
  .spacer-#{$i * 5},
  .spacer-bot-#{$i * 5} {
    margin-bottom: $i * 5px;
  }

  .spacer-#{$i * 5},
  .spacer-top-#{$i * 5} {
    margin-top: $i * 5px;
  }

  .spacer-left-#{$i * 5} {
    margin-left: $i * 5px;
  }
}

@for $i from 0 through 6 {
  .padding-#{$i * 5} {
    padding: $i * 5px;
  }

  .spacer-right-#{$i * 5} {
    margin-right: $i * 5px;
  }
}

@for $i from 1 through 10 {
  .gap-#{$i * 5} {
    gap: $i * 5px;
  }
}

@for $i from 1 through 3 {
  .line-clamp-#{$i} {
    display: -webkit-box;
    -webkit-line-clamp: $i;
    line-clamp: $i;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.no-transform {
  text-transform: none !important;
}

#driver-popover-item .driver-popover-footer .driver-close-btn {
  display: none;
}

.left-nav {
  .top-section {
    margin: 10px;
  }

  .middle-section {
    padding-left: 10px;
    padding-right: 10px;
    height: 602px;
    overflow: scroll;

    &.no-height {
      height: auto;
    }
  }

  .btn-bottom-section {
    text-align: center;

    &.bg-color {
      background-color: #eaeaea;
    }
  }
}

.break-word {
  word-break: break-word;
}

.nowrap {
  white-space: nowrap;
}

.mw-100 {
  max-width: 100px;
}

.mw-150 {
  max-width: 150px;
}

.mw-200 {
  max-width: 200px;
}

.new-record {
  background: #64c43a;
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  text-orientation: upright;
  writing-mode: vertical-lr;
  text-align: center;
  font-size: 10px;
}

.f-16 {
  font-size: 16px;
  color: variables.$brand-primary;
}

.f-13 {
  font-size: 13px;
}
