@use "../../settings/third-party/mixins";
@use "../../settings/third-party/variables";
@use "../../settings/custom";

.ex-over {
  background-color: #f2f2f2;
}

.gu-transit {
  opacity: 0.3;
}

.ex-moved {
  transition: opacity 0.5s ease-in-out;
}

.gu-mirror {
  transform: rotate(5deg);
}

.board-list {
  .card-list {
    border-color: #D6E1E5;
    border-width: 0 1px 0 1px;

    .board-list-title {
      padding: 16px 11px;
      border-bottom: 1px solid #dbdbdb;
    }
  }
}

.refresh-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all linear 0.2s;
}

.refresh-overlay.active {
  z-index: 10;
  opacity: 0.7;
  background: #fff;
}

.card-labels {
  min-height: 5px;
  margin: 0 0 10px;

  md-divider {
    position: relative;
    top: 4px;
    z-index: 0;
  }

  .label-indicators {
    @include mixins.border-radius(5px);

    height: 7px;
    float: left;
    margin-right: 10px;
    position: relative;
    width: 70px; //70
    z-index: 1;
  }

  .label-indicators-hover {
    @extend .label-indicators;
    @include mixins.border-radius(10px);
    height: auto;
    padding: 0 5px;
    width: 50px;
    text-align: center;
    font-size: 11px;
  }
}

.dpd-menu {
  width: 90%;
  visibility: hidden;
}

.dpd-content {
  position: absolute;
  right: 0;
}

.optimeline-cal {
  .md-fab.scroll-btn {
    top: 47px;
  }

  .scroll-left md-icon {
    transform: rotate(180deg);
  }

  .scroll-btn.scroll-left {
    right: 42px;
  }

  .scroll-btn.scroll-right {
    right: 0;
  }

  md-tab-data {
    z-index: 1;
    min-width: 130px;
    left: auto;
  }

  .date-time-detail {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &.win-chance-section {
      .cal-inner {
        background-color: variables.$white;
        border: 1px solid variables.$border-color;
        height: 185px;
        padding: 0;
        width: 325px;
      }

      .title {
        font-size: 16px;
      }

      .inner-gap {
        padding: 20px;
      }

      .cal-head {
        border-bottom: 1px solid variables.$border-color;
      }

      .content-section {
        position: absolute;
        top: 65px;
        left: 0;
        right: 0;
        bottom: 0;
      }

      .range-slider {
        margin-left: 17px;
        width: 170px;
      }
    }
  }

  .show-cal {
    cursor: pointer;
  }

  .cal-inner {
    padding: 10px 30px 30px;
    background-color: #F3FCFF;
    border: 1px solid #C0E0F6;
    border-radius: 3px;
    position: relative;
    z-index: 2;
  }

  .cal-head {
    margin-bottom: 15px;

    md-icon {
      &.round {
        border: 1px solid variables.$gray;
        padding: 5px;
        border-radius: 50%;
        cursor: pointer;

        &.right {
          margin-right: 0;
        }

        &.left {
          margin-left: 0;
        }
      }

      &.active {
        border-color: variables.$brand-primary;

        svg path {
          fill: variables.$brand-primary;
        }
      }
    }
  }

  .custom-calender {
    border: 1px solid #C0E0F6;
    border-radius: 3px;
  }
}

.board-list {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  // background-color: rgba($white-dark, 0.5);

  .card-list {
    border-color: variables.$white-dark transparent transparent variables.$border-color;
    border-width: 1px;
    border-style: solid;
    display: inline-table;
    max-width: 350px;
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 0 11px;
    margin-left: -1px;
    white-space: normal;
    transition: all 0.3s ease-in-out;

    &:last-child {
      border-right: 1px solid variables.$border-color;
    }

    &:first-child {
      margin-left: 0;
    }

    .board-list-title {
      margin: 0 -11px;
      padding: 13px 11px;
      background-color: variables.$white-dark;
      font-size: variables.$font-size-small;
      font-weight: 500;
      border-bottom: 1px solid variables.$white;

      .title-name {
        margin-top: 3px;
      }
    }

    .buck-status {
      min-height: 40px;

      .price-old {
        padding-top: 15px;
        font-size: variables.$font-size-base;
        font-weight: 500;
      }

      .price-new {
        padding-top: 15px;
        font-size: variables.$font-size-small;
        font-weight: 500;

        &.add {
          color: variables.$brand-success-dark;
        }

        &.less {
          color: variables.$brand-danger-dark;
          margin-left: 2px;
        }
      }
    }

    &.selected {
      border-color: variables.$brand-accent;
      background-color: rgba(variables.$brand-accent, 0.05);
      position: relative;
      z-index: 1;

      .board-list-title {
        background-color: rgba(variables.$brand-accent, 0.15);
      }
    }
  }
}

.notify {
  @extend .location-symbol;
}

.cards-container {
  max-height: 89%;
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll-space {
  padding-right: 11px;
  margin-right: -11px;
}

// .card-container {
//   cursor: move;
//   cursor: grab;
//   cursor: -moz-grab;
//   cursor: -webkit-grab;
// }

.card {
  background-color: variables.$white;
  // box-shadow: 2px 2px 0 $border-color;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 3px, rgba(0, 0, 0, 0.24) 0 1px 2px;
  min-height: 94px;
  margin: 0 2px 4px;
  // padding: 10px 7px 5px;
  position: relative;
  transition: 0.3s linear all;

  &:hover {
    background-color: #fff4e7;
    // box-shadow: 4px 4px 0 $border-color;
    box-shadow: rgba(50, 50, 93, 0.25) 0 6px 12px -2px, rgba(0, 0, 0, 0.3) 0 3px 7px -3px;
  }

  h2 {
    margin: 0;
    font-weight: 500;
  }

  .card-title {
    padding-right: 20px;
    font-weight: 500;
    line-height: 20px;
  }

  .card-header {
    padding: 6px 10px 10px;
  }

  .icon-drag-handle {
    height: 18px;
    width: 18px;
    position: absolute;
    cursor: move;
    right: 9px;
    top: 25px;
  }

  .complete-checkbox {
    position: absolute;
    right: 7px;
    top: 38px;

    md-checkbox {
      min-height: 21px;
    }
  }

  .card-body {
    padding: 12px 10px 10px;
    position: relative;

    .icon-container {
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    .icon-drag-handle {
      top: -9px;
    }

    .focus-on-hover {
      transition: transform 0.3s ease;
    }

    .focus-on-hover:hover {
      color: variables.$brand-primary;
      transform: scale(1.1);
    }

    .icon-fullscreen {
      top: -8px;
      position: absolute;
      height: 16px;
      width: 16px;
      right: 55px;
    }
  }

  .card-body:hover {
    .icon-container {
      opacity: 1;
    }

    .icon-drag-handle,
    .icon-fullscreen {
      path {
        fill: variables.$brand-primary;
      }
    }
  }
  @media screen and (max-width: 480px) {
    .card-body {
      .icon-container {
        opacity: 1;
      }
    }

    .card-body:hover {
      .icon-drag-handle,
      .icon-fullscreen {
        path {
          fill: rgba(0, 0, 0, 0.54);
        }
      }
    }
  }

  &.action {
    border-top: 2px solid;
    background-color: #f7f7f8;

    h2:not(.card-title) {
      font-weight: 600;
      color: #434343;
      margin: 3px 0 20px;
    }

    .card-title {
      max-width: calc(100% - 28px);
      min-height: 65px;

      &,
      textarea {
        border: 1px solid transparent;
        font-weight: 600;
        cursor: auto;
        width: 100%;
        max-height: 200px;
        font-size: variables.$font-size-small;
      }

      textarea {
        padding: 0 5px;
        min-height: 63px;
        max-width: -webkit-fill-available;
        min-width: -webkit-fill-available;
      }

      &:not(.editing-title) {
        padding: 0 5px;
      }

      &.editing-title {
        padding: 0;
        overflow-y: auto;
      }

      &.editing-title,
      &:hover {
        // outline: thin solid $brand-primary;
        color: variables.$brand-primary;
        border-color: variables.$brand-primary;

        .edit-icon-holder {
          display: inline-block;
        }
      }
    }

    .edit-icon-holder {
      position: absolute;
      background: #fff;
      // border-radius: 2px;
      display: none;
      padding: 3px 5px 5px;
      right: 0;
      top: 0;

      md-icon {
        height: 15px;
        width: 18px;
      }
    }

    .card-header {
      background: #fff;
      min-height: 85px;
      padding-bottom: 20px;
    }

    .card-labels {
      position: absolute;
      top: -3px;
    }

    .focused svg path {
      fill: variables.$brand-primary;
    }

    .function-chip {
      background: #2b3e50;
      color: #fff;
      padding: 3px 7px;
      display: inline-block;
      border-radius: 15px;
    }

    .timer {
      height: 18px;
      width: 18px;
    }
  }

  .card-details {
    color: variables.$gray;
    list-style-type: disc;
    padding-left: 50px;

    li {
      line-height: 25px;
    }
  }

  .menu-btn:before {
    font-size: 10px;
    color: variables.$gray;
    padding: 6px;
  }

  &.selected {
    border-color: variables.$brand-accent;
    background-color: variables.$white;
  }

  .contact-img .img-replacer {
    height: 30px;
    width: 30px;
    font-size: 12px;
    line-height: 30px;
  }

  .user-contact {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 6px 0 0;

    .avatar,
    .contact-img {
      width: 30px !important;
      height: 30px !important;
      min-width: 30px !important;
      max-width: 30px !important;
      min-height: 30px !important;
      max-height: 30px !important;
    }

    .contact-img {
      border-radius: 50%;
      overflow: hidden;
    }
  }

  .card-stats {
    .visual-sm-icon {
      margin-right: 6px;
    }

    .stat:not(:last-of-type) {
      margin-bottom: 15px;
    }
  }

  .card-activity {
    // display: none;
    margin-top: 18px;
    text-transform: capitalize;
  }

  &.won {
    background-color: rgba(variables.$brand-success, .2);

    &:hover {
      background-color: rgba(variables.$brand-success, .4);
    }

    // .card-activity {
    //   color: $brand-success-dark;
    // }
  }

  &.lost {
    background-color: rgba(variables.$brand-danger, .2);

    &:hover {
      background-color: rgba(variables.$brand-danger, .4);
    }

    // .card-activity {
    //   color: $brand-danger-dark;
    // }
  }

  &.postponed {
    background-color: rgba(#fff2cd, .8);

    &:hover {
      background-color: #fff2cd;
    }
  }

  &.disabled {
    background-color: rgba(#bababa, .8);

    &:hover {
      background-color: #bababa;
    }
  }

  &.pending {
    background-color: rgba(#9347ff, 0.2);

    &:hover {
      background-color: rgba(#9347ff, .4);
    }
  }

  &.quotes {
    border-color: #A07CBC;
    background-color: rgba(#A07CBC, .1);

    &:hover {
      background-color: rgba(#A07CBC, .2);
    }
  }

  &.jobs {
    border-color: #EF4135;
    background-color: rgba(#EF4135, .1);

    &:hover {
      background-color: rgba(#EF4135, .2);
    }
  }

  &.campaigns,
  &.customers,
  &.opportunities {
    border-color: #62C060;
    background-color: rgba(#62C060, .1);

    &:hover {
      background-color: rgba(#62C060, .2);
    }
  }

  .card-progress {
    height: 5px;
    margin: 5px 0;
    z-index: 1;
    position: relative;
    // overflow: hidden;

    .progress-bar,
    .progress-track {
      position: relative;
    }

    .progress-track {
      background: variables.$gray-light;
      height: 1px;
      margin: 3px -5px -4px;
      top: -1px;
    }

    .progress-bar {
      height: 100%;
      background-color: variables.$header-bgcolor;
      border-radius: 15px;
      // z-index: 1;
    }
  }

  .card-footer {
    font-size: 12px;
    margin-top: 6px;

    .new-record {
      left: -10px;
      top: -12px;
      bottom: -10px;
      border-top: 1px solid #ccc;
    }

    span:not(.img-replacer) {
      color: variables.$gray;
    }

    md-icon.rating-star {
      height: 20px;
      width: 20px;
    }

    .user-contact {
      height: 24px;
      width: 24px;

      .avatar,
      .contact-img {
        width: 24px !important;
        height: 24px !important;
        min-width: 24px !important;
        max-width: 24px !important;
        min-height: 24px !important;
        max-height: 24px !important;
      }
    }

    .img-replacer {
      height: 24px;
      width: 24px;
      font-size: 10px;
      line-height: 24px;
    }
  }
}

.card-options {
  color: variables.$white;
  font-size: 12px;
  opacity: 0;
  padding: 15px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  transition: all 0.2s linear;
  z-index: 2;

  &.active {
    background: rgba(0,0,0,0.8);
    opacity: 1;
  }

  .action {
    padding: 5px;
    margin: 8px 0;
    cursor: pointer;

    &.doc-action-cta {
      padding: 10px 18px;
      border: 2px solid #fff;
      font-size: 14px;
      
      .cta-icon {
        width: 20px;
        height: 20px;
      }

      div {
        line-height: 1.5;
      }
    }
    .view-cta {
      width: 24px;
      height: 24px;
    }
  }
  .action:hover {
    div {
      text-decoration: underline;
      text-decoration-color: variables.$brand-primary;
    }
  }
  md-icon {
    height: 13px;
    margin-right: 5px;
    width: 13px;
    vertical-align: top;

    svg path {
      fill: variables.$white;
    }

    &.icon-primary {
      svg path {
        fill: variables.$brand-primary;
      }
    }
  }
}

.card-outer-footer {
  font-size: 12px;
  color: variables.$gray;
  margin-bottom: 15px;
  margin-left: 2px;

  &.ellipsis {
    max-width: 260px;
  }
}
