@use "../../settings/third-party/variables";

.view-campaign {
  padding: 20px;

  .highlights-container {
    @media(min-width: 600px) {
      padding: 10px 35px 0;
    }

    .cheader-name {
      color: #949494;
      font-size: 14px;
      margin-right: 15px;
    }

    .bold-content {
      font-size: 15px;
    }

    .content_cube,
    .content_list {
      max-width: 495px;
      width: 100%;
      margin-bottom: 20px;
      border-radius: 10px;
    }

    .content_list {
      padding: 35px 20px 40px;
      background-color: #e2f4f4;
      display: flex;
    }

    .content_cube {
      background-color: #f3f3f3;
      padding: 15px 0 0 15px;
    }

    .campaign-stat {
      width: 140px;
      padding: 17px;
      margin: 0 15px 15px 0;
      border-radius: 10px;
      background-color: variables.$white;
    }

    .cstat-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    @media(max-width: 600px) {
      .campaign-stat {
        width: 115px;
        margin-right: 15px;
      }
    }
  }

  .stat-row {
    padding: 0 20px;

    .count {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    span {
      font-size: 14px;
    }
  }

  .humberg-right {
    position: absolute;
    top: 0;
    right: -15px;
  }

  .drip-border {
    border: 1px solid variables.$gray-light-media;
  }

  .drip {
    .subject-text {
      margin: 10px;
      font-size: 14px;
      font-weight: 500;
    }

    .drip-container {
      margin-left: 25px;
    }
  }

  .drip {
    position: relative;

    .calendar-icon {
      width: 28px;
      height: 28px;
      border: 1px solid variables.$brand-primary;
      border-radius: 50%;

      svg {
        padding: 5px;
      }
    }

    .timeline {
      margin-left: 14px;
      padding-bottom: 40px;
    }

    &:not(:last-child) {
      .timeline {
        border-left: 1px dashed variables.$brand-primary;
      }
    }
  }

  .drip-content {
    padding: 10px;
    background: variables.$btn-default-color;
    color: variables.$btn-primary-color;

    .drip-type,
    .rate-type,
    md-icon {
      margin-right: 10px;
    }
  }

  .drip-footer .status {
    margin-right: 10px;
  }

  .completed-status {
    background: variables.$brand-accent;
    border-radius: 12px;
    padding: 4px;
    color: variables.$white;
    margin-right: 10px;

    .badge {
      background: variables.$white;
      color: variables.$btn-default-color;
    }
  }

  .pending-status {
    padding: 4px;

    .badge {
      background: variables.$btn-default-color;
    }
  }

  .new_drip-footer {
    @media(min-width: 600px) {
      margin-left: 50px;
      margin-right: 50px;
    }

    .row {
      margin-bottom: 15px;
    }

    .last_email {
      color: #6a6a6a;
    }
  }

}

.edit-campaigns {
  height: 540px;
  width: 800px;

  .edit-container {
    padding: 18px;

    .form-right-section {
      width: 100%;
    }

    .datepicker-custom {
      position: relative;
      margin-bottom: 20px;
    }

    ol {
      padding-left: 12px;

      li {
        padding: 5px 0;
      }
    }
    @media screen and (min-width: 600px) {
      .campaign-dates .datepicker-custom:first-of-type {
        margin-right: 20px;
      }
    }
  }
  @media(max-width:500px) {
    .item-chips li {
      margin-bottom: 15px;
    }
  }

  .enable-campaing {
    .enable-title {
      margin-top: 0;
      margin-left: 15px;
    }
  }

  .datepicker-custom {
    .md-datepicker-input {
      width: 100%;
    }
  }
}

.campaign-details {
  .campaign-control {
    background-color: #f7fcff;
    // padding:20px;

    .gap-10 {
      gap: 10px;
    }

    .text-white {
      color: white;
    }

    .text-dark-gray-3 {
      color: #6a6a6a;
    }

    .header {
      border-bottom: 1px solid #a2a1a1;
      gap: 20px;
      padding: 20px;

      .header-box {
        width: 215px;
        padding: 15px 10px;
        background-color: #fff;
        box-shadow: -1px 1px 8px rgba(0, 0, 0, 0.3);
      }

      .margin-left-auto {
        margin-left: auto;
      }

      .margin-left-20 {
        margin-left: 20px;
      }

      .edit-campaign-cta {
        background-color: #63d1dd;
        font-size: 17px;
        color: white;
        width: 235px;
        padding: 11px 10px;
        box-shadow: -1px 1px 8px rgba(0, 0, 0, 0.3);

        md-icon path {
          fill: variables.$white;
        }
      }
    }

    .main {
      padding: 20px;

      .detail-label {
        font-size: 14px;
        color: #6a6a6a;
      }

      .campaign-name {
        font-size: 24px;
        font-weight: 600;
      }
    }

    .card-sec {
      border-radius: 15px;
      padding: 0;
      box-shadow: -1px 1px 8px rgba(0, 0, 0, 0.3);
      overflow: hidden;
      min-height: 250px;
      width: 315px;

      .owner-name {
        font-size: 18px;
        font-weight: bold;
      }

      .date-section {
        margin: 20px;
        font-size: 17px;
      }

      .date {
        margin-top: 5px;
        color: #63d1dd;
        font-size: 15px;
      }

    }

    .card-footer-sec {
      margin-top: auto;
      padding: 10px;
      background-color: #2b3e50;
      text-align: center;
      color: white;
      overflow: hidden;
      // border-radius: 15px;
    }

  }

  .first-section {
    background: #eeeeee;

    .font-size-16 {
      font-size: 16px;
    }

    .spacer-left-35 {
      margin-left: 35px;
    }

    .gap-30 {
      gap: 30px;
    }

    .starter-content-new {
      padding: 15px;

      .header {
        // background-color: red;
        padding: 5px 10px 20px 0;
        border-bottom: 1px solid #a2a1a1;

        // margin: 15px;

        .next-action-txt {
          color: #5cc8fc;
          font-weight: 600;
          margin-top: 5px;
        }

        .header-box {
          width: 240px;
          padding: 15px 10px;
          background-color: #fff;
          box-shadow: -1px 1px 8px rgba(0, 0, 0, 0.3);
          transition: background-color 250ms, box-shadow 250ms;
          color: #fe9603;
          cursor: pointer;

          &:not(.disabled):hover {
            color: white;
            background-color: #fe9603;
            box-shadow: -2px 2px 16px rgba(0, 0, 0, 0.6);

            md-icon path {
              fill: variables.$white;
            }
          }

          &.disabled {
            cursor: not-allowed;
            color: variables.$gray;

            md-icon {
              cursor: not-allowed;

              path {
                fill: variables.$gray;
              }
            }
          }
        }

        .margin-left-auto {
          margin-left: auto;
        }

        .margin-left-20 {
          margin-left: 20px;
        }
      }

      .main {
        padding: 30px 0;

        .img-section {
          width: 50%;
          // height:300px;
          // display: flex;
          align-items: center;
        }

        .title {
          font-size: 30px;
          margin: 20px 0 0;
          letter-spacing: 1px;
        }

        .subtitle {
          font-size: 28px;
          margin: 0;
          letter-spacing: 1px;
        }

        .para-list {
          font-size: 16px;
          color: #a2a1a1;
          list-style-type: decimal;
          padding-left: 0;
        }

        .para-list li {
          margin-left: 40px;
        }

        .watch-video-btn {
          width: 190px;
          padding: 15px;
          margin-top: 50px;
          color: white;
          background-color: #06b7f8;
          box-shadow: -1px 1px 8px rgba(0, 0, 0, 0.3);
          font-size: 15px;
        }
      }

      .footer-sec {
        .black-info-icon,
        .clip-icon,
        .personalize-data-icon,
        .quick-guide,
        .verificar-icon {
          width: 25px;
          height: 25px;
          margin-right: 10px;
        }
        padding: 20px 0 5px;
        border-top: 1px solid #a2a1a1;

        .related-auto-cta {
          line-height: 20px;
          margin-left: 25px;
        }
      }

    }

  }
}

.clone-design-template {
  .template-color {
    border: 1px solid #595959;
    max-width: 30px;
    min-width: 30px;
    min-height: 30px;
    border-radius: 40px;
  }

  .template-section-type {
    border: 1px solid #595959;
    min-width: 200px;
    background: #eeeeee;
  }
}
