@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.payment-poppups {
  min-height: 520px;
  width: 650px;

  .register-payment {
    font-size: 16px;
  }

  .confirmpop-icons {
    svg {
      height: 28px;
      width: 42px;
    }

  }

  md-toolbar {
    &.main-header {
      // background: $white;

      h3 {
        text-align: center;
        font-size: variables.$font-size-base;
        // color: $gray-dark;
        font-weight: normal;
      }

      .md-icon-button {
        .icon-close {
          height: 18px;
          width: 18px;
        }
      }
    }
  }

  .popup-cancel {
    position: absolute;
    top: 16px;
    left: 20px;
    height: 16px;
    width: 16px;
  }

  .pay-btn {
    position: absolute;
    top: 10px;
    right: 20px;
    margin: 0;
    padding: 0;
    @extend .btn-primary;
    background-color: variables.$brand-primary;
  }

  md-dialog-content {
    padding: 20px;

    h2 {
      padding-top: 7px;
      font-weight: normal;
      text-align: center;
      font-size: 16px;
      color: variables.$gray-dark;
      margin-bottom: 15px;
    }

    p {
      line-height: 1.6;
    }

    md-input-container {
      md-icon {
        left: 10px;
        top: 7px;
      }

      input {
        padding-left: 45px;
        line-height: 1;
        width: 280px;
        color: variables.$gray;
      }

      &:last-child {
        margin-bottom: 12px;
      }
    }

    .payment-loader {
      height: 45px;
      width: 45px;
      margin-top: 40px;
      margin-bottom: 35px;
    }

    .border-box {
      border: 1px solid variables.$gray-lighter;
      border-radius: variables.$border-radius-small;
      width: 100%;
      max-width: 610px;
    }

    .discount-box {
      .btn-discount {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        line-height: 1.5;
        border-radius: 5px;
        width: 80px;
        min-width: 80px;
        height: 60px;
        min-height: 60px;
        border: 1px solid variables.$gray-lighter;
        color: variables.$brand-accent;
        font-size: variables.$font-size-base;
        padding: 5px;
        margin: 15px 17px 20px 0;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .btn-tip {
      margin: 0;
      width: 100%;
      max-width: 280px;
      min-height: 45px;
      border: 1px solid variables.$gray-lighter;
      border-radius: 5px;
      color: variables.$brand-accent;
      margin-bottom: 18px;
    }

    .tip-amount {
      margin-top: 16px;

      a {
        display: inline-block;
        margin-left: 8px;
        margin-top: 3px;
      }
    }

    .signature-box {
      outline: 0;
      position: relative;

      .menu-btn {
        position: absolute;
        top: 9px;
        right: 12px;
      }

      canvas {
        cursor: pointer;
        min-height: 120px;
        margin-bottom: 18px;
      }

      .canvas-placeholder {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, .75);
        z-index: 1;

        .text-info {
          text-align: center;
          @extend .f2;
          position: absolute;
          color: variables.$white;
          top: 50%;
          width: 100%;
          transform: translateY(-50%);
        }
      }
    }

    .img-thanks {
      width: 100%;
      max-width: 225px;
      height: 100%;
      max-height: 100px;
    }

    .price-block {
      line-height: 1.9;
      margin-bottom: 45px;
    }

    .img-nfc-notready {
      margin-bottom: 50px;
    }

    .img-space-bottom {
      margin-bottom: 15px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .payment-poppups md-dialog-content .discount-box {
    .btn-discount {
      margin: 8px 8px 15px 0;
    }
  }

  .payment-poppups md-dialog-content .border-box {
    padding: 8px;
  }

  .payment-poppups md-dialog-content md-input-container input {
    padding-left: 45px;
    line-height: 1;
    width: auto;
  }
}
