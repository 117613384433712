@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.inventory-catalog-sidebar {
  .custom-tabs {
    md-tabs-wrapper md-tabs-canvas md-pagination-wrapper md-tab-item.md-active:after {
      border-top-color: variables.$white-dark;
    }

    md-content {
      padding-top: 25px;
    }
  }

  .gen-lbl {
    @extend .f4;

    @extend .fm;

    margin-top: 0;
    margin-bottom: 10px;
  }

  .message {
    color: variables.$gray-darker;
    margin-top: 0;
    margin-bottom: 25px;
  }

  .attribute-list {
    li {
      margin-bottom: 20px;

      .message {
        margin-bottom: 0;
      }
    }

    &.attached-service-list {
      .col-title {
        margin-top: 0;
      }

      md-checkbox .md-icon {
        background: variables.$white;
      }
    }
  }

  .sidebar-title {
    margin-top: 0;
    margin-bottom: 15px;
    font-weight: 500;
  }
}
