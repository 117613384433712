@use "../../settings/third-party/variables";

/*--Customer Edit--*/
.customer-edit {
  .md-subhead {
    // padding-left: 15px;
    // padding-top: 18px;
    margin: 0;
  }

  .contact-desc {
    padding-left: 4px;
  }

  .no-padding {
    padding: 0;
  }

  .secondary-contact {
    margin-bottom: 35px;
  }

  .billing-space {
    margin-bottom: 0;
  }

  .btn-min-space {
    min-width: 38px;
  }

  .form-base {
    textarea {
      height: 100px;
    }

    .avtar {
      width: 110px;
      max-width: 110px;
      text-align: center;

      .avtar-img {
        height: 100px;
        width: 100px;
        padding: 5px 3px 0;
        border: 1px solid variables.$gray-light;
        border-radius: 100%;
        margin-bottom: 15px;
        overflow: hidden;
      }

      .avtar-change {
        margin-top: 10px;
        color: variables.$brand-primary;
        cursor: pointer;
      }
    }

    .add-email {
      padding: 10px;
      margin-bottom: 18px;

      .icon-round {
        margin-left: -20px;
      }
    }

    .add-field {
      position: absolute;
      top: 12px;
      right: -32px;

      @media screen and (max-width: 599px) {
        position: relative;
        right: 10px;
      }
    }

    .v-address {
      @extend .add-field;

      right: -102px;

      @media screen and (max-width: 599px) {
        position: static;
        margin: -5px 0 10px;
        text-align: right;
      }
    }

    .add-contact {
      margin: 10px 0 15px;
      outline: 0;

      .icon-text {
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
      }

      .ico-plus {
        height: 25px;
        width: 25px;
      }
    }

    .email-section {
      @media screen and (min-width: 600px) {
        flex: 1 1 66.7%;
        max-width: 66.7%;
      }
    }
  }

  .form-base {
    md-input-container input[type=number] {
      height: 38px;
    }
  }

  .md-subhead.profile-head {
    padding: 0 9px 3px !important;
  }

}

/*--Customer Edit  End--*/

@media screen and (max-width: 599px) {
  .close-btn .icon-cross {
    margin: 0;
  }
}

/*Contact-Edit End*/
/*FormBase End*/

@media only screen and (max-width: 767px) {
  .default-box {
    padding: 8px;
  }

  .contact-user-list .user-contact {
    display: inline-block;
    margin-right: 25px;
  }
}

@media only screen and (max-width: 439px) {
  .contact-user-list {
    .icon-b {
      top: 0;
    }
  }
}
