@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.recurring-pay-section {
  .service-select.md-input-has-value .md-select-value > span:not(.md-select-icon) {
    transform: translate3d(0, 1px, 0);
    max-width: 80% !important;
  }

  .credit-card-tab {
    &.tab {
      margin-bottom: 15px;
    }

    .card-cheque-detail {
      margin-top: 0;
    }
  }

  .pay-details {
    margin-bottom: 15px;
  }

  .gap-right-hard {
    margin-right: 20px;
  }

  .data-table {
    border: 0;

    td {
      border: 0;
    }
  }

  md-list-item:before {
    content: "";
    min-height: 0;
  }

  .edit-card-option {
    margin: 0;
  }

  .card-details {
    h4 {
      font-weight: normal;
      margin-top: 12px;
      margin-bottom: 8px;
      @extend .f3;

      &.no-top-space {
        margin-top: 0;
      }
    }

    .card-view {
      border: 1px solid variables.$gray-lighter;
      padding: 0;
      margin-bottom: 20px;
      border-radius: variables.$border-radius-small;
      cursor: pointer;
      max-width: 400px;
      display: inline-block;
      min-width: 300px;

      &:not(:last-of-type) {
        margin-right: 20px;
      }

      &.cardSelected {
        border: 1px solid #fab963;
      }

      &.active {
        border-color: variables.$gray;
        outline: 0;
      }

      .md-subheader {
        background: variables.$white-darker;
        border-radius: 5px 5px 0 0;
        font-weight: normal;
        padding-left: 30px;

        a {
          display: inline-block;
          font-size: variables.$font-size-small;
          padding-top: 3px;
          padding-left: 16px;

          &:first-child {
            padding-left: 0;
          }
        }

        .form-base {
          margin: 0;
          padding: 0;

          .md-label {
            display: inline-block;
            vertical-align: top;
            @extend .f2;
            font-weight: normal;
          }
        }
      }

      // md-radio-group {
      //   md-radio-button {
      //     &:after {
      //       display: inline-block;
      //       content: "";
      //       position: relative;
      //       top: 2px;
      //       left: 10px;
      //       background: url("#{$image-path}/icons/set-c/ico-wrong-small.svg") no-repeat 0 0;
      //       background-size: 15px;
      //       height: 15px;
      //       width: 15px;
      //     }
      //
      //     &.md-checked:after {
      //       position: relative;
      //       top: 2px;
      //       left: 10px;
      //       display: inline-block;
      //       content: "";
      //       background: url("#{$image-path}/icons/set-c/ico-right-small.svg") no-repeat 0 0;
      //       background-size: 15px;
      //       height: 15px;
      //       width: 15px;
      //     }
      //   }
      // }

      md-radio-button {
        display: inline-block;
        margin: 0;
      }

      md-list-item {
        padding: 18px;

        .card-desc {
          margin-bottom: 10px;
          margin-top: 15px;
          width: 100%;

          img {
            margin-right: 14px;
            margin-left: -5px;
            margin-top: -10px;
          }

          label {
            padding-right: 10px;
          }

          span {
            display: inline-block;
            vertical-align: top;
          }
        }

        label {
          padding-right: 10px;
        }
      }
    }

    .payment-detail-list {
      .success {
        color: variables.$status-green;
      }

      .pending {
        color: variables.$status-yellow;
      }
    }

    .payment-detail-list {
      border: 1px solid variables.$gray-light;
      border-radius: variables.$border-radius-small;
      padding: 16px;

      li {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        div {
          display: inline-block;
          vertical-align: top;
          width: 49%;

          label {
            display: inline-block;
            @extend .f4;
            padding-bottom: 3px;
          }

          span {
            display: block;
            @extend .f3;
          }
        }
      }
    }

    .card-alert {
      display: inline-block;
      color: variables.$brand-danger;
      font-size: 10px;
      margin: 0 0 12px;
    }

    .section-title {
      margin: 10px 0 22px;
      padding: 10px 0;

      h3 {
        margin: 0;
        @extend .f1;
        font-weight: normal;
        margin-right: 12px;
      }

      a {
        padding-top: 2px;
      }
    }
  }

  // .payment .payment-status {
  //   .txt-status {
  //     padding-left: 20px;
  //   }
  // }

  .edit-card-option {
    label {
      display: inline-block;
      vertical-align: top;
      width: 140px;
      margin-right: 20px;
    }

    .desc {
      margin-bottom: 22px;

      md-radio-button {
        .md-label {
          position: relative;
          top: 0;
          @extend .f3;
        }
      }
    }
  }

  .card-detail {
    margin: 0;
  }

  .payment {
    .card-cheque-detail {
      margin-top: 4px;
    }

    .card-block {
      width: 65%;
    }

    .tab {
      margin-bottom: 10px;
    }
  }
  // .payment .card-cheque-detail .card-no-wrap:after {
  //     top: 3px;
  //     bottom: 3px;
  //     right: 3px;
  //     height: 38px;
  // }
  .payment .card-cheque-detail md-select {
    padding: 0;

    .md-select-value {
      // span {
      //   // display: inline-block;
      //   // max-width: 60px;
      //   // margin-left: 10px;
      // }

      .md-select-icon {
        margin: 0;
        position: absolute;
        right: 0;
        top: 10px;
        line-height: 1;

        &:after {
          right: 3px;
        }
      }
    }

    &:not([disabled]):focus .md-select-value {
      border: 0;
    }
  }

  &.edit-card {
    .section-title {
      margin-bottom: 15px;
    }

    .card-block {
      margin-bottom: 5px;
    }
  }
}

.capitalize {
  text-transform: capitalize;
}
// .payment-refund {
//       min-height: 64%;
// }

.transaction-payment {
  padding: 20px;
}

.refund-payment {
  padding: 20px;

  .cancle-close {
    text-align: center;
  }

  .refund-details {
    margin-bottom: 50px;
    min-height: 76px;
  }
}

.addingp-card {
  .md-errors-spacer {
    display: block;
  }
}

md-option {
  .md-text {
    width: 100%;
  }
}
// .recurring-pay-section {
//
// .canceled {
//   color: $brand-danger !important;
// }
//
// .pending {
//   color: $brand-danger;
// }
//
// .partially-paid {
//   color: $brand-success;
// }
// }

.form-base {
  .recurring-pay-section {
    md-input-container {
      margin-top: 9px;

      .md-input-has-value {
        md-select {
          md-select-value {
            span {
              &.canceled {
                color: variables.$brand-danger;
              }

              &.pending {
                color: variables.$brand-primary;
              }

              &.partially-paid {
                color: variables.$brand-success;
              }

              &.f3-light {
                color: variables.$gray-light;
              }
            }
          }
        }
      }
    }
  }

  md-select {
    &.custom_select {
      height: 70px;
    }
  }
}
@media only screen and (max-width: 600px) {
  .recurring-pay-section .payment .card-block {
    width: 100%;
  }

  // .recurring-pay-section .card-details .card-view {
  //   // margin-right: 0;
  //   margin-top: 15px;
  //   // width: 100%;
  // }

  .recurring-pay-section .payment span {
    text-align: left;
  }

  .form-base md-radio-group md-radio-button {
    margin-bottom: 15px;

    // &:last-child {
    //   margin-bottom: 0;
    // }
  }
}
@media only screen and (max-width: 400px) {
  .recurring-pay-section .card-details .card-view .md-subheader {
    md-radio-group {
      float: none;
    }

    .form-base {
      div.right {
        float: none;
        margin-top: 8px;
        text-align: right;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .recurring-pay-section .gap-right-hard {
    margin-right: 0;
  }

  .payment .card-cheque-detail {
    max-width: none;
  }
}
