@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.status-dropmenu.table-menu {
  &:after,
  &:before {
    right: 0;
    left: 50%;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
  }

  md-menu-item {
    height: auto;
    min-height: 35px;
    padding-top: 7px;
    padding-bottom: 7px;

    & > * {
      margin: 0;
    }
  }

  .globe-ico {
    margin: 0 15px 0 7px;
  }

  .location-list {
    @extend .no-style;
    margin-top: 3px;

    li {
      margin-right: 5px;
      margin-bottom: 7px;
    }
  }
}

md-toolbar.main-header {
  .location-marker svg path.marker {
    fill: variables.$brand-primary;
  }
}

.resource-list-section {
  .filters-bar {
    padding-top: 20px;

    .filter-label {
      margin-top: 0;
      vertical-align: middle;
    }

    .clear-filter {
      top: 25px;
    }
  }

  .multiple-loc {
    display: inline-block;
    padding-left: 30px;

    a {
      font-size: 10px;
    }
    text-align: left;
  }

  .utillization {
    display: inline-block;
    width: 54px;
    overflow: hidden;

    .utilization-container {
      background: #eee;
      border-radius: 20px;
      line-height: 0;
      margin-right: 5px;
    }

    .utill-cover {
      width: 100%;
      height: 11px;
      // display: inline-block;
      overflow: hidden;
      transition: 2s all ease-in-out;

      img {
        vertical-align: top;
      }
    }
  }

  .table-responsive {
    .data-table {
      min-width: 815px;

      td {
        // padding: 10px 0 10px 10px;
        .table-btn {
          .small-btn {
            height: 10px;
            width: 12px;
          }
        }

        .globe-ico {
          height: 18px;
          width: 18px;
        }

        &:first-child {
          width: auto;
          // padding: 0 0 0 10px;
          .title {
            display: inline-block;
            padding-top: 25px;
            padding-bottom: 25px;
            width: 75%;
          }
        }

        &.f-icon {
          width: 35px;
        }

        .user-status {
          margin-bottom: 3px;
          min-height: 30px;

          md-checkbox {
            display: none;
            position: relative;
            right: -4px;
            top: 5px;
          }

          .ico-user-icon md-icon {
            border-radius: 50%;
            overflow: hidden;
          }

          &:hover {
            .ico-user-icon {
              display: none;
            }

            md-checkbox {
              display: inline-block;
            }
          }

          &.selected {
            md-checkbox {
              display: inline-block;
            }

            .ico-user-icon {
              display: none;
            }
          }
        }
      }

      th:first-child {
        width: auto;
        padding-left: 10px;
      }

      .table-options {
        text-align: center;

        a {
          display: inline-block;
          font-size: variables.$font-size-small;
          margin-right: 15px;
        }

        .chat-num {
          background: url("#{variables.$image-path}/icons/set-h/chat-bubble.svg") no-repeat;
          display: inline-block;
          height: 22px;
          width: 23px;
          background-size: 23px;
          font-size: 10px;
          text-align: center;
          padding-top: 1px;
        }

        .user-num {
          background: url("#{variables.$image-path}/icons/set-h/ico-tickuser.svg") no-repeat;
          display: inline-block;
          height: 24px;
          width: 18px;
          background-size: 17px;
          text-align: center;

          span {
            display: inline-block;
            font-size: 9px;
            color: variables.$gray;
            margin-top: -4px;
          }
        }

        md-icon {
          height: 22px;
          width: 22px;
          margin-right: 15px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
      // .table-options {
      //     a {
      //         visibility: hidden;
      //     }
      // }
    }
  }
}

@media (max-width: 959px) {
  md-dialog.bulk-email-send.md-dialog-fullscreen {
    height: 90vh;
    min-height: 90%;
    min-width: 90%;
  }
}

.bulk-email-send {
  // &.md-dialog-full-screen {
  //   min-height: 90%;
  //   min-width: 90%;
  //   border-radius: 0;
  // }
  height: 90vh;
  min-height: 90%;
  min-width: 90%;

  .bulk-email-card {
    overflow: hidden;
  }

  .bulk-send-email-form-base {
    padding: 15px !important;
  }

  .bulk-email-send-card {
    overflow-y: auto;
    min-height: 560px;
    max-height: 560px;
  }

  .view-sample-template {
    img {
      width: 30px;
      height: 30px;
    }
    line-height: 14px;
    padding: 8px 10px;
    margin: 6px 0;
    max-width: 140px;
    background-color: #2196f3;
  }

  .view-sample-template:hover {
    background-color: #2196f3;
  }

  .bulk-scan-serials-template {
    .chat-list {
      overflow: hidden;
      background-color: #f2f6f7;
      border-left: 5px solid variables.$brand-primary;
    }

    .icon-background {
      border-radius: 30px;
      background-color: #fff;
      padding: 7px;

      .icon_image,
      img {
        width: 45px;
      }
    }

    .proflie_section {
      max-width: 300px !important;

      .chat-unreads-div {
        h1 {
          color: #000 !important;
        }
      }

    }
  }

  .md-sidenav-left {
    display: inline;
  }

  .bulk-send-email-icon {
    width: 35px;
    height: 35px;
  }

  .proflie_section {
    height: 100%;
  }

}
