@use "../../settings/style-guide";
@use "../../settings/custom";

md-toolbar.main-header .dropdown-btn {
  .btn-toptxt {
    position: relative;
    top: 10px;
  }

  md-input-container {
    margin-bottom: 0;

    md-select md-select-value {
      padding: 0;
      border-bottom-color: transparent;
      padding-bottom: 0;

      .md-select-icon:after {
        content: '\2304';
        top: -1px;
        color: #949494;
        font-size: 28px;
        max-height: 16px;
        line-height: 16px;
        width: 0;
        margin-top: 4px;
      }

      span {
        color: white;
      }
    }
  }
}

md-toolbar.main-header .dropdown-btn {
  .md-button {
    width: 40px;
  }
}

.visit-detail {
  md-toolbar.main-header .dropdown-btn {
    .md-button {
      width: auto;
    }
  }

  .guider-section {
    border: none;
    padding: 0;
  }

  .md-chips {
    padding: 0 0 8px;
  }
}

.site-visit-edit {
  .form-base .address-type h4 {
    padding-right: 20px;
    font-weight: normal;
    font-size: 12px;
    margin-top: 10px;
  }

  .form-base md-switch.md-block {
    margin: 15px 0 0 11px;
  }
}

.site-visit-edit,
.visit-detail {
  .resource-name {
    @extend .ellipsis-150;
    max-height: 50px;
  }

  .resource-label {
    margin-top: 5px;
  }
}

.visit-detail {
  md-icon {
    outline: 0;

    &.verfication-icon {
      height: 18px;
      margin-left: 8px;
      position: relative;
      top: -3px;
      width: 18px;
    }
  }

  .assign-txt {
    position: relative;
    top: 2px;
    left: 5px;
  }

  .col-2-list {
    width: 80%;

    ul {
      li {
        margin-top: 0;
        line-height: 1.5;
        margin-bottom: 0;
      }
    }

    li > label {
      padding-right: 1%;
      font-weight: normal;
      @extend .f4;
    }
  }

  .total-time {
    padding-right: 30px;
  }
}

.add-visit {
  .switch-h3 {
    h3 {
      font-size: 12px;
      font-weight: normal;
      margin-left: 15px;
      line-height: 2;
    }
  }
}
@media screen and (max-width: 960px) {
  .form-base .v-address {
    position: static;
    margin: -5px 0 10px;
    text-align: right;
  }
}
