@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.procurement-add-notes-popup {
  min-width: 50%;
  width: 800px;

  .notes {
    margin: 25px 0;
  }

  .icon-ico-notes {
    margin: 5px 20px 5px 0;
  }
}

.gap {
  padding: 0;
}

.filter-doc-input {
  width: 350px;
}

.filter-notes-input {
  width: 350px;
}

.po-order-right-nav {
  .nav-head {
    // margin:0px 0px 8px 0px;
    // border-bottom: 1px solid $brand-table;
    .block-title {
      font-size: 16px;
      font-weight: 400;
      margin: 8px;
      min-width: 150px;
    }
  }
}

.purchase-order {
  .file-input-rows {
    margin-bottom: 30px;

    .select {
      padding: 20px;
    }
  }

  .inventory-addProduct-section .datepicker-custom {
    width: 100%;
  }

  .item-inputs {
    md-input-container {
      display: block;
      max-width: 65px;
      margin: auto;

      input {
        //border:1px solid #D6E1E5;
        border-radius: 3px;
        text-align: center;
      }
    }
  }

  .inventory-table {
    md-input-container {
      display: inline-block;
    }
  }

  .copy-link {
    display: inline-block;
    margin-top: 10px;
  }

  .custom-tabs md-content.files-tab-sec {
    padding-top: 30px;
    padding-left: 10px;
  }

  .files-tab-sec {
    .add-contact {
      margin-left: 0;
      margin-bottom: 24px;
      margin-top: 11px;
    }

    md-checkbox {
      margin-bottom: 0 !important;
    }
  }

  .applyall-link {
    position: absolute;
    bottom: -15px;
    left: 15px;
    right: 0;
    font-size: 11px;
    color: variables.$brand-accent;
  }

  md-radio-group md-radio-button {
    font-size: 14px !important;
    margin-bottom: 14px;
  }

  .package-section .datepicker-custom {
    margin-bottom: 20px;
  }

  .billing-space.add-title {
    margin: 0;
  }

  .package-section .policy-left-division {
    width: 100%;
  }

  .order-desc-sec {
    width: 70%;
    float: left;
  }

  .order-info-sec {
    width: 30%;
    float: right;

    .summery-widget {
      line-height: 1.5;

      .sm-text {
        font-size: 11px;
      }

      .widget-space {
        margin-top: 15px;
      }
    }
  }

  .po-items {
    .add-contact {
      margin: 10px 0;
    }
  }

  .add-contact.add-sec {
    margin: 12px 0 20px;

    md-icon {
      margin-right: 6px;
    }
  }

  .policy-right-division {
    md-checkbox.policy-cb {
      margin-bottom: 20px;
    }

    md-input-container {
      margin-bottom: 20px;
    }

    .manual-id-link {
      margin-top: 16px;
      margin-left: 10px;
    }
  }

  .files-type-sec {
    @extend .no-style;

    li {
      margin-bottom: 30px;
      margin-top: 10px;
    }

    .file-type-img {
      margin-bottom: 10px;
      width: 50px;
      height: 50px;
    }

    .files-desc-wrap {
      h2 {
        margin-bottom: 20px;
        margin-top: 0;
      }

      md-divider {
        margin-bottom: 15px;
        margin-top: 10px;
      }
    }
  }

  .payment-tab-sec {
    .po-payment-sec {
      .assign-type {
        margin-bottom: 15px;
        margin-top: 20px;
      }

      .add-contact {
        margin-top: 0;

        &.no-space-l {
          margin-left: 0;
        }
      }
    }

    .data-table {
      td {
        md-input-container {
          margin-bottom: 10px !important;
        }
      }
    }

    .billing-space.add-title {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .add-contact {
      margin-left: 15px;
      margin-top: -5px;
    }
  }

  .add-contact {
    margin: 20px 0;

    .icon-ico-add {
      margin-right: 5px;
    }
  }

  .order-menu-title {
    margin-bottom: 20px !important;
  }

  .item-list {
    .search-result-list {
      .search-list {
        .img-container {
          box-sizing: border-box;
          text-align: left;

          .icon-tick {
            top: -4px;
            right: inherit;
            left: -3px;
          }
        }
      }

      a {
        display: inline-block;
        font-size: 11px;
        margin-top: 0;
        margin-left: 9px;
      }
    }

    .font-sml {
      font-size: 11px;
      line-height: 1.4;
    }
  }

  .invoice-setting {
    padding: 20px;

    .default-business-logo {
      max-width: 260px;
      padding: 25px 20px;
    }
  }
}

/*===========================================
=            PopUp For Inventory            =
===========================================*/
.additional-po-popup {
  height: 540px;
  width: 800px;

  md-dialog-content {
    padding: 20px;

    .add-contact {
      margin-left: 10px;
      margin-top: 15px;

      md-icon {
        margin-right: 10px;
      }
    }

    .additional-po-table {
      .data-table {
        border: 0;

        .delete-icon {
          svg path {
            fill: variables.$gray;
          }
        }

        thead {
          background: transparent;
        }

        td {
          border: 0;
          vertical-align: middle;
          padding: 0 10px;

          md-input-container.md-input-focused label:not(.md-no-float),
          md-input-container.md-input-has-placeholder label:not(.md-no-float),
          md-input-container.md-input-has-value label:not(.md-no-float) {
            display: none;
          }
        }

        md-input-container {
          display: block;
          margin: 10px 0;

          &.cost-input {
            max-width: 100px;
          }

          &.tax-input {
            max-width: 150px;
          }

          input,
          md-select-value {
            font-size: 13px;
          }
        }

        .apply-link {
          font-size: 12px;
          color: variables.$brand-accent;
        }
      }
    }
  }
}

/*=====  End of PopUp For Inventory  ======*/

/*Mobile and Tab ----------- */

@media (max-width: 768px) {
  .purchase-order .order-desc-sec,
  .purchase-order .order-info-sec {
    width: 100%;
    float: left;

    .summery-widget {
      float: none;
    }
  }
}
