@use "../../settings/third-party/variables";

.bill {
  .heading_title {
    background-color: #ddecf3;
    padding: 15px;

    span {
      color: black;
      font-size: 25px;
      font-weight: 600;

      @media screen and (max-width: 600px) {
        font-size: 16px;
      }
    }

    .icon_png {
      width: 45px;
      margin-right: 15px;
    }

    .icon-next-arrow {
      rotate: 90deg;
      transition: 0.3s linear rotate;

      &.opened {
        rotate: -90deg;
      }
    }
  }

  .bill-content {
    margin-bottom: 30px;
    padding: 15px 0 0 30px;

    .clients-table {
      min-width: 500px;
    }

    .form-right-section {
      padding-left: 0;
    }

    h3 {
      font-size: 18px;
    }
    // .capsule-name-section{
    //     display: flex;
    //     justify-content: space-between;
    //     width: 100%;
    // }
    .capsule-name {
      padding: 5px 10px;
      border: 1px solid #595959;
      border-radius: 50px;
      background-color: #eeeeee;
      max-width: 150px;
      width: 100%;
      // margin-bottom: 15px;
    }

    .details {
      margin-bottom: 30px;

      p {
        margin: 6px auto;
      }
    }

    .btn-primary {
      display: grid;
      color: variables.$brand-primary;
      background-color: variables.$white;
      border: 1px solid variables.$brand-primary;
      padding: 10px;
      margin: 20px 0 0;
      width: max-content;

      span {
        text-align: left;
        line-height: 1.2;

      }
    }

    .type-box {
      md-icon {
        margin-right: 15px !important;
      }
    }

  }

  .project-content {
    .capsule-name-section {
      p {
        margin-top: 0;
      }
    }

    .radio-btn-section {
      padding-left: 2px;
    }
  }

  .center {
    text-align: center;
  }

}

.added-custom-tag {
  background: #d3e1e54a;
  padding: 35px 30px 40px;

  .icon-next-arrow {
    margin: 0 15px 0 0;
    height: 36px;
    width: 36px;
  }

  .crm-icon {
    height: 65px;
    width: 65px;
  }

  .add-custom-content {
    width: 80%;

    h2 {
      margin-top: 10px;
      font-size: 18px;
    }

    .btn-primary {
      color: variables.$brand-primary;
      background-color: transparent;
      border: 1px solid variables.$brand-primary;
    }

    p {
      margin-top: 0;
      color: #999999;
    }

    .item-chips {
      li {
        //background-color: #a8b6c2ff;
        background-color: #596773;
        color: #ffffff;
        margin-bottom: 15px;
        font-size: 14px;
      }
    }
  }
}
