@use "../../settings/third-party/variables";

.start-sms-message{
    .accepting_cards{
        .chat-list{
            padding: 0px;
            .start-header{
                background-color: #46676cff;
                padding: 15px;
                .icon-next-arrow{
                    rotate: -180deg;
                    margin:0px 20px 0px 0px;
                    svg{
                        path{
                            fill: variables.$brand-primary;
                        }
                    }
                }
                .first_cell_content{
                    max-width: 260px;
                    min-width: 260px;
                    width: 100%;
                }
                
            }
            .proflie_section{
                h1 {
                    font-size: 18px;
                }
            } 
            .chat-list-div{
                padding: 0px 20px;
                .top-all-contacts{
                    margin: 10px auto;
                    h1{
                        font-size: 16px;
                        margin-top: 15px;
                    }
                    .cta-div{
                        .btn-secondary{
                            background-color: #fff;
                            color: variables.$brand-primary;
                            border: 1px solid variables.$brand-primary;
                            border-radius: 10px;
                            font-size: 12px;
                            margin-right: 0px;
                            .ico-plus{
                                width: 15px;
                                padding-bottom: 3px;
                                margin-right: 5px;
                            }
                        }
                    }
                }
                
            }
        }
        .chats {
            .icon_img {
                width: 45px;
                height: 45px;
                margin-top: 5px;
            }
        }
        .chat-section {
            .custom-default-box {
                .icon_img {
                    width: 35px;
                }
            }
            .img-grid-list {
                
                padding-top: 10px;
            }
            .chat-profile-name {
                .email_title {
                    padding-top: 5px;
                }
            }
        }
        .input_chat_section{
            justify-content: center;
            margin: 0px auto;
            .chats {
                background-color: transparent;
                justify-content: center;
                .icon_img {
                    margin-top: 0px;
                    width: 65px;
                    height: auto;
                }
                .profile-address{
                    line-height: 1.7;
                }
            }
        }
        .send-hi{
            margin-bottom: 80px;
            .chats{
                background-color: #fff;
                padding: 30px;
                .icon_img {
                    margin:0px auto 10px;
                    width: 35px;
                }
                .md-button {
                    font-size: 14px;
                    line-height: 30px;
                    min-height: 25px;
                    margin: 10px auto 0px;
                    justify-content: center;
                    display: flex;
                }
                .text-label{

                }
            }
        }
        .form-base {
            md-input-container {
                label{
                    text-align: left;
                }
            }
            .number-text{
                text-align: left;
            }
        }
    }
    
}