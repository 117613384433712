@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

/* summeryPage Start */

.summery-section {
  .accordion {
    list-style: none;
    padding: 0;
    margin: 10px 12px;
    border: 1px solid variables.$white-darker;
    border-radius: 4px;
  }

  .col-2-list {
    .col-list-item {
      padding: 10px 4px;
      font-weight: normal;

      .summery-status {
        text-align: right;

        label {
          font-weight: 300;
          margin-left: 10px;
        }
      }
    }

    md-divider {
      margin: 10px 5px;
    }
  }

  .summery-list {
    h2 {
      font-weight: normal;
      margin: 0;
      padding: 16px 100px 16px 16px;
      border-bottom: 1px solid variables.$white-dark;

      label,
      span,
      strong {
        display: inline-block;
        vertical-align: middle;
      }
    }

    > li {
      border-bottom: 1px solid #fff;
      outline: 0;
      position: relative;
      background: variables.$footer-bgcolor;

      &.open {
        background: #fff;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.toggle-list {
  margin-left: 55px;
  padding-top: 15px;

  md-list-item {
    padding: 0;
    min-height: auto;

    label {
      @extend .f4;
      display: inline-block;
      min-width: 150px;
      margin-right: 30px;

      &.label-medium {
        @extend .f3;
      }
    }
    padding-bottom: 18px;
  }
}

/* summeryPage End */

@media only screen and (max-width: 600px) {
  .summery-section .col-2-list .col-list-item .summery-status {
    text-align: left;
  }

  .summery-section .summery-list > li .toggle-list {
    margin-left: 16px;
  }

  .summery-section .summery-list h2 {
    label,
    span,
    strong {
      margin-bottom: 5px;
    }
  }

  .summery-section .summery-list > li .toggle-list md-list-item label {
    min-width: 100px;
    margin-right: 20px;
  }
}
