@use "../../settings/third-party/variables";

.campaign-category md-icon {
  width: 15px;
  margin-right: 5px;
}

.campaign-stat {
  // margin: 0;
  width: 150px;
  padding: 10px 0;
  border: 1px solid variables.$gray-light-media;
  text-align: center;
  text-transform: uppercase;

  .count {
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: bold;
    color: variables.$brand-primary;
  }
}

.campaign-grid {
  .notification-card {
    margin: 20px 0;
  }

  .subhead {
    font-size: 16px;
  }

  .menu-btn {
    top: 12px;

    &:before {
      transform: rotate(90deg);
    }
  }

  .status-dropmenu {
    width: 100%;
  }

  // TODO: check
  .filters-bar md-input-container md-select {
    width: auto;
  }

  .segment-detail {
    > div {
      padding: 10px 16px;
    }

    .customer-segment {
      background: variables.$brand-accent-darker;

      .f6 {
        margin-right: 5px;
      }
    }
  }

  .active-status md-icon {
    height: 15px;
    width: 15px;
    margin-right: 5px;
  }

  .owner-info {
    .img-replacer {
      width: 25px;
      height: 25px;
      line-height: 25px;
      font-size: 10px;
    }

    .right-space {
      margin-right: 5px;
    }

    .owner-status {
      width: 15px;
      margin-right: 5px;
    }
  }

  .campaign-drip {
    // border: 1px solid $gray-light-media;
    border-radius: 5px;
    border: 1px solid #d0e4f2;
    transition: box-shadow 1s, border-color 1s;
    overflow: hidden;

    &:hover {
      border-color: #52758d;
      box-shadow: 0 0 8.37px 0.63px rgba(82, 117, 141, 0.35);

    }
  }

  .campaign-header {
    border-bottom: 1px solid variables.$gray-light-media;
    padding: 10px;
    background: #eff6fb;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    &.active {
      background-color: #eff6fb;
    }

    md-icon {
      margin: 0 5px 0 0;
    }
  }

  .opportunity-grid .boards li {
    border: none;
  }
}
