@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.envelop-history-section {
  .top-section {
    margin-bottom: 20px;
  }

  .titles {
    @extend .fm;
    color: variables.$gray-darker;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .envelop-list {
    li {
      line-height: 16px;
      margin-bottom: 20px;
    }

    .receipient-list li {
      margin-bottom: 0;
    }
  }

  .table-responsive {
    margin-bottom: 20px;

    .titles {
      margin: 0;
    }
  }
}

@media screen and (min-width: 600px) {
  .envelop-history-section {
    .section-right-gap {
      margin-right: 15px;
    }
  }
}
