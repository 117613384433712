@use "../../settings/third-party/variables";
@use "../../settings/style-guide";
@use "../../settings/custom";

.md-menu {
  &.itemAssociate {
    padding: 0;
  }
}

.update-status {
  color: variables.$gray-dark;
  position: absolute;
  top: 14px;
  right: 10px;

  &,
  .f4 {
    font-size: 10px;
  }
}
/* In View pages start */
.field-view-list {
  li {
    color: variables.$gray-dark;
    margin-bottom: 18px;
  }

  .list-lbl {
    color: variables.$gray;
    padding-right: 15px;
  }
}

.detail-view-section {
  line-height: 18px;
  // margin-top: 12px;
  // .img-container {
  //   border: 1px solid $gray-light;
  //   border-radius: 5px;
  //   margin-right: 20px;
  //   overflow: hidden;
  //   width: 140px;
  //   height: 140px;
  //   position: relative;
  //
  //   &.no-img-container {
  //     height: 70px;
  //     width: 100px;
  //
  //     .item-img.no-img {
  //       height: 30px;
  //       width: 30px;
  //     }
  //   }
  //
  //   .item-img {
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     right: 0;
  //     bottom: 0;
  //     max-width: 100%;
  //     max-height: 100%;
  //     margin: auto;
  //   }
  // }
  // .visibility-section {
  //   .f4 {
  //     padding-right: 15px;
  //   }
  //
  //   .f3 {
  //     margin-right: 35px;
  //   }
  // }

  md-divider {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .doc-list {
    .chipbox {
      &:before {
        content: '';
        display: inline-block;
        background: url("#{variables.$image-path}/icons/set-b/ico-pdf.svg") no-repeat 0 0;
        background-position: 0 0;
        background-size: 15px;
        height: 17px;
        margin-right: 6px;
        width: 15px;
        vertical-align: middle;
      }
    }
  }

  // .product-status {
  //   font-size: 12px;
  //   margin-left: 7px;
  //
  //   &.draft {
  //     color: $brand-accent;
  //   }
  // }

  .bottom-gap {
    margin-bottom: 12px !important;
  }

  .title {
    @extend .f1;
    @extend .ellipsis;
    max-width: 250px;
    margin: 0;
  }

  .inner-left-sections {
    padding-right: 20px;
  }

  .inner-right-sections {
    padding-left: 20px;
  }

  .product-img-list {
    .image-type {
      margin-top: 0;
      margin-bottom: 10px;
      text-align: left;
    }

    li {
      vertical-align: bottom;
    }
  }

  .section-title {
    color: variables.$gray;
    margin-top: 0;
    margin-bottom: 10px;
    font-size: variables.$font-size-base;
    font-weight: normal;
  }

  .ico-favourite {
    height: 10px;
    margin-left: 7px;
    width: 10px;
  }
}
/* In View pages end */
.inventory-addProduct-section {
  .order-seq {
    li {
      position: relative;
      padding-left: 40px;
    }

    .list-no {
      position: absolute;
      left: 4px;
      top: 8px;
      border: 1px solid #c0e0f6;
      display: inline-block;
      height: 22px;
      line-height: 22px;
      width: 22px;
      background-color: #EBF2F7;
      font-size: 14px;
      text-align: center;
    }
  }

  .bottom-gap {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  // md-chips {
  //   .md-chips {
  //     border-bottom: 1px solid $border-color;
  //   }
  // }
  .add-btn-section {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .stock-cb-section {
    margin-bottom: 15px;
  }

  .md-button.md-icon-button {
    &.add-btn {
      margin: 0 8px 0 0;
      height: auto;
      min-height: auto;
      padding: 0;
      width: auto;

      &.ship-btn {
        margin-top: 15px;
        margin-bottom: 15px;
      }
      // &.manu-btn {
      //   margin-top: 5px;
      // }
      md-icon {
        height: 21px;
        width: 21px;

        svg path {
          fill: variables.$brand-primary;
        }
      }
    }
  }

  .shipping-rb-section {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .form-base {
    .product-input-list {
      md-input-container input {
        width: 100%;
      }
    }

    .shipping-rb-section {
      md-radio-button {
        font-size: variables.$font-size-small;
      }
    }
  }

  .custom-tabs {
    md-tabs-canvas md-tab-item {
      padding-left: 20px;
      padding-right: 20px;
    }

    md-content {
      padding-top: 15px;
    }
  }

  .status-section {
    .section-lbl {
      margin: 0 8px 20px 20px;
    }

    .stats-options {
      margin-bottom: 0;

      md-select {
        height: 32px;
        position: relative;
        top: -10px;
      }

      md-select-value {
        border-bottom: none;

        .md-text {
          @extend .f3;
        }

        .md-select-icon:after {
          top: 1px;
        }
      }
    }
  }

  .datepicker-custom {
    height: 40px;
    display: inline-block;
    margin-bottom: 18px;
    position: relative;
    padding: 2px;
    vertical-align: middle;

    .md-datepicker-input-container {
      width: 100%;
      margin-left: 0;

      input {
        border: none;
        width: 90%;
      }
    }

    .md-datepicker-button {
      position: absolute;
      right: 0;
      margin: 0;
      z-index: 2;
      width: 36px;
      height: 36px;
    }

    .md-datepicker-triangle-button.md-button.md-icon-button {
      display: none;
    }
  }

  .summery-widget {
    md-checkbox {
      margin-bottom: 0;

      .md-icon {
        background: variables.$white;
      }
    }
  }

  .input-list-section {
    .tab-title {
      @extend .f1;
      font-weight: normal;
      margin-top: 0;
      margin-bottom: 20px;

      .location-symbol {
        margin-left: 15px;
      }
    }

    .customized-inputs {
      md-input-container {
        input {
          width: 100%;
        }
      }
    }

    .pricingPromotionalSection {
      margin-top: 35px;
      margin-bottom: 15px;

      h3 {
        margin-top: 0;
        font-weight: 500;
      }

      .md-button.md-icon-button.add-btn {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    .extra-options {
      // .md-button.md-icon-button.add-btn {
      //   margin-bottom: 20px;
      // }
      md-checkbox {
        margin-bottom: 8px;
      }
    }
  }

  .product-input-list {
    @extend .no-style;
    margin-top: 10px;

    .instruction-msg {
      @extend .f3;
      @extend .fm;
      margin-bottom: 18px;
    }

    .switch-bar {
      margin-top: 30px;
      margin-bottom: 8px;

      label {
        padding-right: 0;
        margin-right: 12px;
        width: 115px;
        margin-left: 15px;
      }
    }
  }

  .product-tags-section {
    margin-bottom: 20px;
  }

  .data-table {
    min-width: 790px;

    td {
      @extend .f3;
    }
  }

  &.product-label-section {
    .custom-tabs {
      md-content {
        padding-top: 20px;
      }
    }

    .form-base {
      md-input-container {
        input,
        textarea {
          width: 100%;
        }
      }
    }

    .md-button.md-icon-button.add-btn {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    &.product-instruction-section {
      .img-container {
        border: 1px solid variables.$gray-light;
        padding: 10px 17px;
        height: 60px;
        width: 90px;
        margin-bottom: 5px;

        .default-img {
          margin: auto;
        }
      }

      .cursor-point {
        margin-left: 15px;
      }

      .center-align {
        margin: 0 auto;
      }

      .switch-bar {
        margin-top: 8px;

        &.summery-widget {
          background: transparent;
          padding: 8px 0 0;
          margin-bottom: 10px;
        }

        label {
          margin-right: 12px;
        }

        md-switch {
          margin-top: 0;
        }
      }

      .summery-widget {
        margin-top: 0;

        .sm-text {
          font-size: 10px;
        }

        md-icon {
          height: 21px;
          margin-right: 8px;
          width: 21px;

          svg path:first-child {
            fill: transparent;
          }
        }

        &.price-check-section {
          .section-title {
            margin-top: 0;
          }

          .bottom-gap {
            margin-bottom: 8px;
          }

          .btn-primary {
            margin-left: 0;
            margin-bottom: 15px;
          }
        }
      }

      .note {
        margin-bottom: 20px;
      }
    }

    .custom-switch {
      margin: 15px 0;
    }

    .summery-widget {
      label {
        padding-right: 0;
      }
    }

    .top-gap {
      margin-top: 10px;
    }
    // .img-container {
    //   margin-right: 20px;
    //
    //   .f8 {
    //     font-size: 10px;
    //   }
    //
    //   &.hasImage {
    //     .cursor-point,
    //     .guid-lbl,
    //     md-icon {
    //       display: none;
    //     }
    //   }
    //
    //   .default-img {
    //     @include border-radius(3px);
    //     border       : 1px solid $border-color;
    //     height       : 70px;
    //     margin-bottom: 10px;
    //     width        : 100px;
    //
    //     .guid-lbl {
    //       color    : $gray-dark;
    //       font-size: 11px;
    //     }
    //
    //     img {
    //       height    : 100%;
    //       max-height: 100%;
    //       max-width : 100%;
    //       width     : 100%;
    //     }
    //
    //     md-icon {
    //       height       : 24px;
    //       margin-top   : 13px;
    //       margin-bottom: 13px;
    //       width        : 28px;
    //     }
    //   }
    // }
    .data-table {
      margin-top: 15px;
      min-width: 790px;

      td {
        @extend .f3;
      }
    }

    .switch-bar {
      md-switch {
        width: 65px;
      }
    }

    .set-fav {
      position: relative;
      top: -15px;

      md-checkbox {
        margin-bottom: 0;
      }

      md-icon {
        height: 10px;
        width: 10px;
        margin-right: 5px;
      }
    }

    .input-sections {
      margin-top: 18px;
    }

    md-radio-group {
      &.radio-btns {
        display: block;
        margin-top: 20px;
        margin-bottom: 12px;

        .md-label {
          @extend .f3;
        }
      }
    }

    .item-chips {
      li {
        margin-right: 10px;
        margin-bottom: 15px;

        .icon-close {
          margin-left: 3px;
        }
      }
    }
  }

  &.label-view-section {
    .product-specification {
      margin-bottom: 10px;
    }

    .section-bottom-gap {
      margin-bottom: 30px;
    }

    .gap-xs {
      margin-right: 8px;
    }

    .gap-sm {
      margin-right: 15px;
    }

    .product-detail {
      line-height: 18px;

      .product-name {
        margin-right: 15px;
      }
    }

    .set-fav {
      position: static;
    }
  }

  .input-sublabel {
    margin-top: -10px;
  }

  .add-sup-btn {
    margin-top: 15px;
    margin-bottom: 20px;
  }

  .suppliers-list {
    @extend .no-style;
    margin-bottom: 20px;

    li {
      @extend .f4;
      line-height: variables.$line-height-base;
      margin-bottom: 10px;
    }
  }

  .custom-value-section {
    .md-button.md-icon-button.add-btn {
      margin-top: 6px;
    }
  }

  h3.imageTab-title {
    color: variables.$gray-dark;
    font-size: variables.$font-size-small;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .item-chips {
    &.bottom-gap {
      margin-bottom: 20px;
    }
  }
  // .product-image-list {
  //   @extend .no-style;
  //
  //   li {
  //     display: inline-block;
  //     margin-right: 18px;
  //     margin-bottom: 18px;
  //     vertical-align: top;
  //   }
  //
  //   .img-container {
  //     border: 1px solid $border-color;
  //     border-radius: 4px;
  //     box-sizing: border-box;
  //     height: 100px;
  //     margin-bottom: 8px;
  //     padding: 10px;
  //     width: 130px;
  //
  //     &.default {
  //       width: 150px;
  //     }
  //
  //     .default-icon {
  //       height: 38px;
  //       width: 38px;
  //       margin: 12px auto;
  //       display: block;
  //     }
  //
  //     .product-img {
  //       max-width: 100%;
  //       height: auto;
  //     }
  //   }
  //
  //   .image-lbl {
  //     @extend .f8;
  //     cursor: pointer;
  //     text-align: center;
  //   }
  //
  //   // .set-default {
  //   //   margin-top: 20px;
  //   // }
  // }
}

.custom-value-section {
  .title {
    margin-top: 9px;
    margin-bottom: 8px;
  }

  .input-section {
    margin-top: 10px;
  }

  .section-btn {
    margin-top: -16px;

    .md-button {
      height: 30px;
      min-height: 30px;
      width: 30px;
    }

    md-icon {
      height: 15px;
      width: 15px;
    }
  }
}

.sku-view-section {
  &.inventory-addProduct-section {
    .sku-title {
      @extend .fm;
      @extend .f3;
      margin-bottom: 15px;
    }

    .custom-tabs {
      md-content {
        padding-top: 25px;
      }
    }

    .imageTab-title {
      margin-top: 0;
      margin-bottom: 12px;
    }

    .shipping-tabTitle {
      font-size: 16px;
      font-weight: 500;
      margin-top: 0;
      margin-bottom: 30px;
    }
  }

  .ico-tick {
    height: 21px;
    margin: 0 8px;
    width: 21px;
  }

  .input-list {
    @extend .no-style;

    li {
      margin-bottom: 20px;
    }

    &.order-seq {
      li {
        padding-top: 12px;
      }
    }
  }

  .item-chips {
    li {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }
}

.sku-generator {
  .switch-bar {
    margin-top: 15px;

    label {
      padding-left: 30px;
    }
  }

  .data-table {
    td {
      border: 1px solid #d6e1e5;
    }
  }
}

#supplier {
  .title {
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 20px;
  }

  .suppliers {
    @extend .no-style;

    li {
      background: variables.$white;
      line-height: variables.$line-height-base;
      padding: 10px;

      &:not(:last-child) {
        margin-bottom: 7px;
      }

      &.selected {
        .check-icon-tick {
          display: inline-block;
        }
      }
    }

    .archive-img-container {
      margin-right: 10px;
      position: relative;

      .check-icon-tick {
        display: none;
        position: absolute;
        top: -1px;
        right: -3px;
        height: 12px;
      }
    }

    .stock {
      .product-input-list {
        .instruction-msg {
          font-size: 15px !important;
        }
      }

      .location-symbol {
        margin: 0 26px;
      }
    }

    .manufacturer {
      .files-desc-wrap {
        md-input-container {
          margin-top: 15px;
        }
      }
    }

    md-checkbox {
      margin-right: 7px;
      margin-bottom: 0;
    }
  }
}

.manufacturer-view {
  .file-input-rows {
    .files-desc-wrap {
      margin: 10px 0;
    }

    .file-type-img {
      margin: 10px 0;
    }
  }
}
// @media screen and (min-width: 769px) {
//   .product-label-section.label-view-section {
//     .detail-view-section {
//       max-width: 65%;
//     }
//   }
// }
// @media screen and (max-width: 768px) {
//   .product-label-section.label-view-section {
//     .summery-widget {
//       float: none;
//     }
//   }
// }
@media screen and (min-width: 645px) {
  .inventory-addProduct-section {
    .input-holder-section {
      float: left;
      max-width: 450px;
    }

    &.product-instruction-section {
      .summery-widget {
        float: none;
      }
    }

    // .summery-widget {
    //   float: right;
    // }
  }
}
@media screen and (min-width:600px) {
  .sku-generator {
    .update-info {
      top: 20px;
      position: relative;
    }
  }
}
@media screen and (min-width: 600px) {
  .inventory-addProduct-section {
    .form-base {
      .custom-section {
        md-input-container {
          margin-right: 12px;

          &:first-child {
            input {
              width: 145px;
            }
          }

          &:nth-child(2) {
            input {
              width: 130px;
            }
          }

          &:last-child {
            input {
              width: 175px;
            }
          }
        }
      }

      .input-sm {
        input {
          width: 158px;
        }
      }
    }

    &.sku-view-section {
      .summery-widget {
        margin-top: 5px;
      }
    }

    .stock-cb-section {
      md-input-container {
        margin-left: 18px;
      }
    }

    .add-btn-section {
      position: relative;
      top: -4px;
    }

    .gap-right {
      margin-right: 20px;
    }

    .datepicker-custom {
      width: 158px;
    }

    .summery-widget {
      margin-top: 15px;
      width: 265px;
    }

    .input-list-section {
      .customized-inputs {
        .gap-right {
          margin-right: 12px;
        }
      }
    }

    .product-input-list {
      //margin-bottom: 25px;
      max-width: 50%;

      md-input-container {
        //margin-bottom: 0;
      }
    }

    &.product-label-section {
      .summery-widget {
        margin-top: 0;
        width: 220px;
      }
    }
  }

  .custom-value-section {
    .gap-right {
      margin-right: 15px;
    }
  }

  // .detail-view-section {
  //   max-width: 75%;
  // }
}
