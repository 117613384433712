@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.service-popups {
  width: 800px;
  min-height: 500px;
  overflow: hidden;

  &.disable-promotion-popup {
    min-height: 260px;
  }

  md-toolbar.main-header .md-toolbar-tools {
    .md-icon-button md-icon {
      height: 18px;
      width: 18px;
    }

    h2 {
      font-size: 16px;
      line-height: 32px;
    }

    md-icon {
      height: 18px;
      width: 18px;

      svg path {
        fill: variables.$white;
      }
    }

    .create-plan-btn {
      min-width: 110px;
    }
  }

  .form-base {
    md-input-container {
      input,
      label,
      md-select md-select-value span {
        font-size: variables.$font-size-small;
      }
    }

    .input-sm {
      input,
      md-select {
        width: 160px;
      }
    }

    .input-md {
      input {
        width: 180px;
      }
    }

    .input-lg {
      input {
        width: 220px;
      }
    }
  }
}

.reason-dependancy {
  font-size: variables.$font-size-medium;

  .form-base md-input-container {
    input,
    label,
    md-select md-select-value span {
      font-size: variables.$font-size-medium;
    }
  }

  .change-state {
    margin-bottom: 15px;

    md-input-container {
      margin: 0;
    }
  }

  img {
    height: 90px;
    width: 90px;
    margin-right: 15px;
    -webkit-filter: invert(65%);
    filter: invert(65%);
  }

  ul {
    margin-left: 15px;

    li:before {
      content: '';
      display: inline-block;
      background: url("#{variables.$image-path}/icons/set-i/arrow_right.svg") no-repeat;
      height: 12px;
      width: 12px;
      margin-right: 15px;
    }
  }
}

/* Charge policy section starts here */

.charge-policy-section {
  .top-section {
    margin-bottom: 20px;
  }

  .section-titles {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .note-section {
    line-height: 20px;
    margin-top: 45px;
  }

  .rules-list {
    li {
      md-checkbox {
        @extend .f3;

        .md-label {
          margin-left: 37px;
        }
      }
    }
  }
}

/* Charge policy section ends here */

/* Job Charge policy section starts here */

.job-charge-section {
  .auto-charge-cb {
    margin-top: 40px;

    .md-label {
      margin-left: 37px;
    }
  }
}

/* Job Charge policy section ends here */

/* Recurring payment section starts here */

.recurring-payment-section {
  padding: 25px 16px 16px 55px;
  position: relative;

  .learn-more-section {
    position: absolute;
    right: 20px;
    top: 30px;
  }

  .form-base {
    md-select .md-text {
      color: variables.$gray;
    }
  }

  .input-control-list {
    li {
      margin-bottom: 15px;

      .input-label {
        @extend .f3;

        margin-top: 3px;
      }

      md-input-container {
        margin-bottom: 0;
      }

      md-switch {
        position: relative;
        top: 2px;
      }
    }
  }

  .adv-config-section {
    margin-top: 40px;
  }
}

/* Recurring payment section ends here */

.add-service-section {
  padding: 25px;

  .switch-bar {
    margin-top: 30px;

    label {
      margin-right: 20px;
    }
  }
}

// .disablePromotion-section {
//     line-height: 21px;
//     padding: 45px 20px;
//
//     .msg-section {
//         margin-bottom: 20px;
//     }
//
//     .confirm-que {
//         margin-bottom: 30px;
//     }
//
//     .md-button {
//         min-width: 52px;
//     }
// }
@media screen and (min-width: 600px) {
  .charge-policy-section {
    .rules-list {
      li {
        md-checkbox,
        md-input-container {
          margin-right: 18px;
          margin-bottom: 0;
        }

        md-input-container.md-input-has-value label:not(.md-no-float) {
          display: none;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .service-popups {
    md-toolbar.main-header .md-toolbar-tools {
      .md-button.btn-primary.tick-btn {
        min-width: 45px;
      }

      .long-titles {
        font-size: 15px;
        line-height: 20px !important;
        text-align: center;
      }
    }
  }

  .charge-policy-section {
    .rules-list {
      li {
        margin-bottom: 20px;
      }
    }
  }

  .recurring-payment-section {
    padding-left: 16px;

    .input-control-list {
      li {
        .input-label {
          margin-bottom: 15px;
        }
      }
    }
  }
}
