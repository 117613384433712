@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.customer-segment {
  .add-product {
    padding: 15px;
  }

  .match {
    .label-key {
      padding-top: 20px;
      color: variables.$gray;
    }

    .label-val {
      padding-top: 20px;
    }
  }

  .active {
    color: variables.$status-green;
  }

  .inactive {
    color: variables.$status-gray;
  }

  .primary {
    color: variables.$brand-primary;
  }

  .product-img-box {
    padding: 10px;
  }

  .sub-container {
    padding-top: 24px;
  }

  .segment-info-sec {
    margin-bottom: 35px;

    .summery-widget {
      padding: 5px 20px;

      & > div {
        margin: 15px 0;
      }
    }

    .num {
      color: variables.$brand-primary;
    }
  }

  .right-nav-open {
    float: right;
    margin: 10px;
  }

  .automation-div {
    md-list-item.md-no-proxy {
      padding: 0;
    }

    .lead-creation-div {
      .export-criteria-div {
        display: flex;
        width: 100%;
        justify-content: space-between;

        md-switch {
          margin-top: 15px;
          margin-right: 20px;
        }
      }

      .icon_img {
        width: 30px;
        margin-right: 15px;
      }
    }

    .lead-card {
      display: flex;
      width: 100%;
      //justify-content: space-between;
      md-input-container {
        margin-right: 15px;
      }
    }

    .section-heading {
      md-icon {
        margin: 0 5px 0 4px;
      }
    }

    .lead-card-content {
      margin-left: 41px;
      margin-bottom: 30px;

      .sales-agent-btns {
        margin: 0 0 20px;
      }
    }

    .view-lead-card-content {
      md-radio-button {
        pointer-events: none;
      }

      md-switch.md-block {
        pointer-events: none;
      }

      md-checkbox {
        pointer-events: none;
      }

      md-input-container {
        pointer-events: none;
      }
    }

    .setup-content-div {
      //padding-left: 50px;
      .setup-content-heading {
        margin-right: 45px;
      }

      .setup-content-desc {
        margin-top: 10px;
        width: 100%;
      }

      md-input-container {
        margin-left: 0 !important;
      }

      .rb-btns {
        margin-top: 10px;
      }
    }

    .toggle-btn-section {
      md-switch.md-block {

        margin-right: 20px;
        margin-top: 15px;
      }
    }

    .capsule-name {
      padding: 0;
      border: 1px solid #eeeeee;
      border-radius: 50px;
      background-color: #eeeeee;
      max-width: 155px;
      width: 100%;
      align-items: center;
      display: flex;
      text-align: left;
      font-weight: 600;
      font-size: 11px;
      margin-right: 20px;

      .icon_img {
        margin: 0 7px 0 0;
        width: 35px;
        height: auto;
      }
    }

    .summery-widget {
      padding: 0;

      .title {
        border-bottom: 1px solid #b7b7b7;
        padding: 15px 20px;
        margin: 0 !important;
        font-weight: bold;
      }

      .description {
        padding: 5px 20px;

        .sm-text {
          margin-bottom: 10px;
        }

        .num {
          color: #333333;
        }
      }
    }

    .add-contact {
      margin-bottom: 15px;

      .icon-ico-add {
        margin-right: 10px;
      }
    }
  }

  .text-seablue {
    color: #2196f3 !important;
  }

  .gap {
    padding-left: 18px;
    padding-right: 18px;
  }

  .tag-title {
    margin-bottom: 20px;
  }

  ul {
    .nav-tag {
      background-color: #596773;
    }
  }

  .item-chips {
    li {
      margin-top: 10px;
    }
  }

  .top-section {
    top: 0;
    bottom: 50px;
    overflow-y: scroll;
    position: absolute;
    right: 0;
    left: 0;
  }

  .bottom-section {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    height: 50px;
  }

  .function-data {
    max-width: 400px;
    margin-left: 15px;
  }

  .item-list {
    li {
      padding: 10px;
      line-height: 20px;
    }
  }

  .rb-btns {
    display: block;
    margin-top: 20px;
    margin-bottom: 8px;
  }

  h3.rb-btns {
    @extend .f1;
  }

  .form-base {
    .toggle {
      margin-top: 12px;
      padding: 0 12px;
    }
  }
}

.view-screen {
  md-list-item {
    .label-key {
      color: variables.$gray;
    }
  }
}

.white-user {
  margin: 8px 0;
}

.restricted-table {
  .data-table {
    border: none;

    td {
      border: none;
      vertical-align: middle;
    }
  }
}

.custom-quick-navigation {
  .nav-title {
    background: #000;

    h2 {
      color: variables.$white;
    }
  }
}
