.recur-status {
  .rrp-simple {
    margin-top: 30px;
  }

  label {
    margin-left: 10px;
  }
}

.recurring-policy {
  padding: 18px 20px;

  .hrspace {
    margin: 15px 0 35px;
  }
}
