@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.bottom-18 {
  margin-bottom: 18px;
}

.guider-section {
  border: 1px solid lightgrey;
  padding: 10px;

  .contact-user-list {
    &.data {
      margin: 14px 0 0;
    }

    .contact-img {
      height: 70px;
      width: 70px;
      border-radius: 50%;
      // border: solid 1px #cccccc;
      overflow: hidden;

      md-icon {
        width: 70px;
        height: 70px;
      }
    }

    .data-card {
      &.prob {
        margin-top: 16px;
      }
    }
  }

  .guider-checklist .md-list-item-inner {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }

  .status-list {
    @media only screen and (max-width: 600px) {
      ul {
        margin: 0;
      }
    }
  }

  li,
  ul {
    list-style: none;
    padding: 0;
  }

  .block-title {
    font-size: 16px !important;
    font-weight: 400;
    margin: 8px;
    min-width: 150px;
  }

  .section-info {
    @extend .f4;
  }

  .section-data {
    @extend .f3;

    &.rating {
      margin: 7px 0;

      md-icon {
        height: 20px;
        width: 18px;
      }
    }
  }

  .more {
    cursor: pointer;
    color: variables.$brand-primary;
  }

  .panel-container {
    // max-height: 400px;
    width: 100%;

    .panel-content {
      // min-height: 350px;
      // max-height: 350px;
      overflow: unset;
    }

    md-list {
      md-list-item {
        &.form-base:first-child {
          min-height: 80px;
        }

        .radio-section {
          width: 100%;
        }
      }

      .star {
        margin: 5px 10px 5px 5px;
        cursor: pointer;
        // md-icon {
        //   color: $white-darker;
        // }
      }

      .timer {
        margin: 5px 10px 5px 5px;
        cursor: pointer;

        md-icon {
          color: variables.$white-darker;

          &.active {
            color: variables.$brand-accent;
          }
        }
      }

      .radio-list-btn {
        margin-top: 14px;
        margin-bottom: 16px;
        margin-left: 16px;
        line-height: 15px;
        @extend .f3;
      }
    }

    .panel-btn {
      width: 100%;
      height: 40px;
      margin: 15px 0;
      background: variables.$white-darker;
      padding-top: 2px;
      border-radius: 3px;
      font-size: variables.$font-size-base;
      text-transform: none;

      .badge {
        @extend .location-symbol;
        min-width: 14px;
        background-color: variables.$gray-light;
        margin-left: 5px;
      }
    }

    .archived-items {
      margin: 0;

      md-list {
        padding-top: 0;

        md-list-item:first-child .md-list-item-inner {
          margin-top: 0;
        }
      }
    }

    md-input-container {
      padding-right: 20px;
      margin-bottom: 15px;
      margin-right: 20px;
      margin-left: 20px;

      .guider-cancel {
        background: variables.$gray-lighter;
      }
    }

    md-checkbox {
      .md-label {
        width: 97%;
        margin-left: 25;

        span {
          width: 80%;
          height: 30px;
          display: table-cell;
          vertical-align: middle;
        }
      }

      md-icon {
        margin: 0 4px 0 0;
        vertical-align: top;
        height: 22px;
        width: 20px;
        float: left;
      }
    }

    .calander {
      .full-cal,
      .time {
        padding: 10px;
      }
    }
  }

  .location {
    .location-symbol {
      margin-left: 10px;
      display: inline-block;
    }
  }

  .filter-star {
    cursor: pointer;
    margin-left: 5px;
  }

  .date-time-detail {
    background: variables.$white-dark;

    .custom-calender {
      min-height: 300px;
      max-height: 310px;

      table {
        width: 100%;
      }

      .uib-datepicker {
        transform: scale(1);
        transform-origin: left top 0;
        @media only screen and (max-width: 1270px) and (min-width: 960px) {
          transform: scale(1);
        }
        @media only screen and (max-width: 524px) and (min-width: 320px) {
          transform: scale(0.8);
        }

        .btn {
          min-width: 40px !important;
          min-height: 40px;
        }
      }
    }

    .panel-date-time {
      margin-top: 0;
    }
  }
}

.table-menu.guider-menu .quotes-menu-list .folder md-icon:first-child {
  margin-bottom: 13px;
}

.right-gap {
  margin-right: 27px;
}

.star-rating {
  margin: 0;
  padding: 0;
  display: inline-block;

  .star {
    padding: 1px;
    color: #ddd;
    font-size: 20px;
    text-shadow: 0.05em 0.05em #aaa;
    list-style-type: none;
    display: inline-block;
    cursor: pointer;

    &.filled {
      color: #fd0;
    }
  }

  &.readonly .star.filled {
    color: #666;
  }
}
/*Sidebar Settings Start*/
.guider-sidebar {
  // .ar-sidepanel {
  //   .task-list {
  //     height: 92%;
  //   }
  // }
  .leftnav-wrap {
    height: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.guider-section {
  .accent-font {
    color: variables.$brand-primary;
    font-weight: bold;
  }

  .guider-detail {
    margin-left: 10px;

    .post {
      margin-top: 4px;
    }
  }

  md-progress-linear {
    padding-right: 15px;
    margin-top: 20px;
    height: 8px !important;
    border-radius: 20px;
  }

  md-progress-linear .md-bar {
    background-color: rgb(107, 191, 102);
    height: 8px !important;
    border-radius: 20px;
  }

  md-progress-linear .md-container {
    background-color: rgb(214, 225, 229);
    border-radius: 20px;
    height: 8px !important;
  }
}

.action-section {
  .panel-heading {
    // margin-left: 25px;
    color: variables.$brand-accent;
    font-weight: 500;
    font-size: 16px;
  }

  .panel-container .panel-head .md-toolbar-tools {
    background-color: #eaf0f6;
    // border-top: 1px solid $gray-lighter;
    border-bottom: 1px solid variables.$gray-lighter;
  }

  .input-toolbar {
    &.bg-hightlight {
      background-color: #eaf0f6 !important;
    }
    border-top: 1px solid variables.$gray-lighter;
    border-bottom: 1px solid variables.$gray-lighter;
    font-size: 14px;

    .action-inline {
      li {
        color: variables.$white;
      }
    }
  }

  .teams-function {
    background-color: #5cc5c3;
  }

  .small-size {
    width: 18px;
  }

  .action-icon {
    height: 38px;
    width: 38px;
    position: absolute;
    top: -18px;
    left: -17px;
  }

  .add-action-header {
    background-color: #20394c !important;
    color: white;
  }

  .icon-ico-cross {
    width: 12px;
  }

  .more-options-section {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: variables.$white;
    height: 0;
    overflow: hidden;
    // transition: height 0.5s ease-out;
    &.open-more {
      height: auto;
      opacity: 1;
    }
  }

  .option-tab {
    position: absolute;
    left: 5px;
  }

  .list-inline {
    display: flex;
    flex-direction: row;

    .action-status {
      display: flex;
      // border: 1px solid white;
      background: transparent;

      &.active {
        border: 1px solid white;
      }
      // display: flex;
      // flex-direction: row;
    }

    .action-status:hover {
      background-color: #5cc5c3;

      .badge {
        background-color: white;
        color: #5cc5c3;
      }
    }
  }

  .action-complete-badge:hover {
    background-color: rgb(184, 225, 184);

    .complete {
      color: rgb(0, 94, 0);
    }

    .badge {
      background-color: white;
      color: #5cc5c3;
    }
  }

  .head-action:hover {
    .descript {
      color: variables.$brand-primary;
    }
  }
  // .icon-folder-border{
  //   // border: 1px solid $white;
  //   .icon-color:hover{
  //     path{
  //       fill: $brand-primary;
  //     }
  //   }
  // }
  .add-action-section {
    margin-bottom: 50px;
  }

  .add-action-line {
    border: 1px solid variables.$brand-primary;
  }

  .action-list-line {
    border-width: 2px;
    border-style: solid;
  }

  .icon-action {
    width: 18px;
    // height: 18px;
    padding-bottom: 3px;
    flex: none;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .icon-action:hover {
    transform: scale(1.1);
  }

  .icon-border {
    // border: 2px solid  #eaeaea;
    border-radius: 5px;
    padding: 2px;
    background-color: white;
    align-items: center;
    width: 32px;
    height: 28px;
  }

  .small-size {
    width: 15px;
  }

  .resource-image {
    height: 28px;
    width: 28px;
  }

  .expand-detail {
    .expand-details-icon {
      // position: absolute;
      // margin: 0;
      width: 17px;
      height: 17px;
      // left: 20px;
      transform: rotate(-90deg);
      transition: 0.5s all;

      svg path {
        fill: #52748d;
      }

      &.open {
        transform: rotate(90deg);
        transition: 0.5s all;
      }
    }

    .option-toggle-icon {
      @extend .expand-details-icon;
      // background-color: transparent;
      svg {
        path {
          fill: variables.$white;
        }
      }
      left: 0;
    }
  }

  .icon-ico-cross:hover {
    path {
      fill: variables.$brand-primary;
    }
    transform: scale(1.0);
  }

  .hidden-icons {
    display: flex;
    // gap: 5px;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease-in-out;
  }

  .hidden-icons.show {
    transform: scaleX(1);
    transition: transform 0.3s ease-in-out;
  }

  .icon-border {
    transition: transform 0.3s ease-in-out;
  }

  .clear-all-cta {
    cursor: pointer;
    color: variables.$brand-primary;
    font-size: variables.$font-size-base;
  }

  .cleared-cta {
    pointer-events: none;
    font-size: variables.$font-size-base;
    color: #333;
  }

  .task-description {
    width: 100%;
    min-height: 65px;
  }

  .add-action-block {
    border: 1px solid variables.$brand-primary;
    // width: 620px;
    min-height: 60px;
    background-color: variables.$white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

    .action-block {
      // width: 620px;
      .input-line {
        border: 0;
      }
    }
  }

  .md-button {
    // margin: 10px 8px;
  }

  .folder {
    cursor: auto;
  }

  .showSidenav {
    // margin-left: 26px;
    a {
      outline: 0;
      text-decoration: none;
      color: variables.$brand-primary;
      cursor: pointer;
      padding: 0 35px;
      margin: 0;
    }
  }

  .support-accordion {
    .accordionN {
      .content {
        .icon-side-tick {
          left: 20px;
          top: 8px;
        }
      }
    }
  }

  .accordionN {
    a {
      padding: 9px 0;
      margin: 0;
      border-radius: 0;
    }

    md-list-item {
      border-top: 1px solid #cccccc;
    }

    .md-list-item-inner {
      min-height: 70px;

      .archive-img-container {
        margin: 7px;
      }
    }
  }

  .active-menu {
    background-color: variables.$brand-accent;
    color: white;
  }

  .active-menu:hover {
    color: white;
  }

  .md-no-style {
    // margin: 0px 16px;
    padding: 0 16px;
  }

  .sidenav-wrapper {
    font-size: 14px;

    md-divider {
      margin: 15px 0;
    }

    .input-row {
      margin: 20px 0;
    }

    .btn-border {
      border: 2px solid variables.$brand-primary;
      color: variables.$brand-primary;
    }

    .selectedRes {
      border: 2px solid variables.$gray-light;
      padding: 10px;
      margin: 5px;
      width: fit-content;
      position: relative;

      .cross {
        position: absolute;
        top: -11px;
        right: -12px;
      }
    }

    .empty {
      height: 200px;
      width: 200px;
    }

    .icon {
      font-size: 35px;
      color: variables.$brand-primary;
      margin: 5px 10px 0 0;
    }
  }

  .empty-action-div {
    text-align: center;
    position: relative;
  }

  .empty-action-icon {
    height: 250px;
    width: 250px;
  }

  .multi-line-input {
    &.callout {
      background-color: #ee5e99;
      border-radius: 5px;
      min-width: 230px;
      position: absolute;
      top: -100px;
      font-size: 16px;
      left: 50%;
      transform: translateX(-50%);

      md-icon {
        height: 16px;

        svg {
          path {
            fill: #fff;
          }
        }
      }

      .md-button {
        border: #fff 2px solid;
        border-radius: 10px;
      }
    }

  }

  .action-list-sidebar {
    .edit-sidebar-label {
      width: 120px;
    }
  }
}
