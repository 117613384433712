@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.create-event-popup {
  width: 395px;
  height: 330px;
  padding: 5px 15px;

  md-toolbar.main-header {
    background-color: #FFFFFF;
    border-bottom: 1px solid variables.$white-darker;

    .md-toolbar-tools {
      padding: 0;
    }

    .md-icon-button.close-popup {
      svg path {
        fill: variables.$gray-lighter;
      }
    }
  }

  &.form-base h3 {
    font-size: variables.$font-size-base;
  }

  md-toolbar.main-header .md-toolbar-tools .md-icon-button.close-popup md-icon svg path {
    fill: variables.$gray-dark;
  }

  .create-event-field {
    @extend .no-style !optional;

    padding: 8px 20px;

    li {
      position: relative;
      padding: 10px 0;
      height: 26px;

      label {
        @extend .f4 !optional;

        display: inline-block;
        min-width: 105px;
        margin-right: 5px;
      }

      &.date-picker-custom {
        padding: 0;

        md-datepicker .md-datepicker-input-container input {
          min-width: 104px;
          max-width: 104px;
          margin: 0;
          height: 22px;
        }
      }

      .date-picker {
        display: inline-block;

        .md-datepicker-input-container {
          border-bottom: 1px solid rgb(224, 224, 224);
          height: 22px;
        }

        input::-webkit-input-placeholder {
          font-size: variables.$font-size-small;
        }

        input:-moz-placeholder {
          /* Firefox 18- */
          font-size: variables.$font-size-small;
        }

        input::-moz-placeholder {
          /* Firefox 19+ */
          font-size: variables.$font-size-small;
        }

        input:-ms-input-placeholder {
          font-size: variables.$font-size-small;
        }
      }

      md-input-container {
        margin: 0;
        height: 28px;

        md-select {
          height: 31px;
          width: 80px;
          margin-right: 5px;

          md-select-value {
            height: 28px;
            min-width: 0;
            min-height: 28px;
          }
        }

        label {
          height: 28px;
          line-height: 28px;
        }

        label,
        span {
          font-size: variables.$font-size-small;
          color: variables.$gray-light;
        }

        input {
          height: 28px;
        }
      }
    }
  }
}
