.error-import {
  .total-error {
    margin-top: 18px;
  }

  .reset-margin{
    margin-bottom: 0;
  }

  .count-errors {
    margin-left: 15px;
    position: relative;
    position: relative;

    .icon-tick {
      margin-top: -10px;
    }
  }

  .warning:before {

    content: '';
    display: inline-block;
    height: 25px;
    width: 25px;
    // background-image: url('#{$image-path}/media-icons/ico-waring.svg');
    background-size: 24px;
    background-repeat: no-repeat;
    margin-right: 5px;
    margin-top: 4PX;
    position: absolute;
    top: 45px;

  }

  .done:before {
    top: 45px;

  }

  .sucess-tick {
    width: 25px;
  }

}
