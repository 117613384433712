@use "../../settings/third-party/variables";

.guider-section
  .panel-container
  .panel-content
  .list-item
  .task-list
  .task-des
  .task-detail
  .list-inline
  li {
  padding: 8px 0 0;
}

.guider-section {
  md-toolbar.main-header .dropdown-btn .md-button {
    width: 40px;
  }

  .timer {
    margin: 5px 8px 3px 0;
    height: 25px;
    width: 25px;
  }

  .task-detail {
    margin-top: -12px;
    width: 100%;
    // .list-inline li{
    //   padding: 8px 0 0;
    // }
  }

  .task-des {
    margin-left: 50px !important;
    width: 100%;
    min-height: 65px;
  }

  .table-menu.guider-menu .quotes-menu-list {
    min-height: 0;
    height: auto;

    &:after {
      background: url("#{variables.$image-path}/icons/set-d/ico-small-right-arrow.svg")
        no-repeat;
      background-size: 9px;
      right: 0;
    }

    .folder md-icon:first-child {
      margin-bottom: 13px;
    }
  }

  .icon-orange {
    svg path {
      fill: variables.$brand-primary !important;
    }
  }

  .bell {
    // margin: 15px;
    cursor: auto;
  }

  .action-title {
    font-size: variables.$font-size-base;
    font-weight: 500;
    color: variables.$gray-darker;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 12px !important;

    &:hover {
      color: variables.$brand-primary;
    }
  }

  .star {
    // margin: 0 !important;

    .set-priority {
      margin-left: 8px;
    }
  }

  .text-disable {
    color: gray;
  }

  .icon-ico-add {
    top: -5px !important;
  }

  .panel-container {
    .panel-btn {
      span.bar-spinner {
        position: absolute;
        top: 20px;
      }

      span.title-bar {
        margin-right: 15px;
      }
      text-align: left;
      font-weight: 400;
      padding-left: 12px;

      .badge {
        position: absolute;
        right: 10px;
        top: 12px;
        background-color: variables.$brand-accent;
      }
    }

    .panel-head.archive-action-list {
      margin-top: 15px;
      min-height: 40px;
      height: 40px;
      cursor: pointer;
      background: variables.$white-darker;

      .md-toolbar-tools {
        border-radius: 3px;
      }
    }
  }

  // md-chips .md-chips {
  //   width: fit-content;
  //   display: inline-block;
  //   box-shadow: 0 0 rgba(0,0,0,0.12);
  // }
  //
  // .md-chips md-chip {
  //   height: 22px;
  //   border-radius: 50px !important;
  //   padding-left: 0;
  //   line-height: 20px;
  //   box-shadow: none;
  // }

  // .img-replacer {
  //   height: 22px;
  //   width: 22px;
  //   line-height: 22px;
  //   font-size: 12px;
  // }

  .resource-gap .img-replacer {
    height: 22px;
    width: 22px;
    line-height: 22px;
    font-size: 12px;
  }

  .task-list-header {
    margin-bottom: 0 !important;
    width: 90%;
  }

  .list-item {
    padding: 0 12px 0 12px !important;
    &:hover {
      background-color: rgba(222, 222, 222, 0.2);
    }
    &.active {
      background-color: rgba(222, 222, 222, 0.2);
    }
  }
}

.guider-sidebar {
  .resource-name {
    padding-right: 16px;
  }
}
