@use "../settings/third-party/variables";

.dynamic-dashborad {
  .add-block {
    padding: 29px 30px;

    .block-icon {
      height: 250px;
      width: 250px;
      width: 100%;
    }

    .names-camp {
      width: 100%;
    }

    .welcome-blocks {
      font-size: 16px;
      position: relative;
      top: 10%;
    }
  }

  .filter-right {
    height: 20px;
  }

  .add-report {
    font-size: 20px;
    margin: 15px;
  }

  .add-filtersChart {
    margin-right: 15px !important;
  }

  .blocks-zone {
    padding: 10px;
  }

  .adding-block {
    margin: 40px 17px;

    // padding-top: 16px;/
    border-top: 1px solid variables.$gray-light;
    padding-bottom: 0;

    // border-bottom: 1px solid #ccc;
    .block-icon {
      height: 250px;
      width: 250px;
      width: 100%;
    }

    .welcome-blocks {
      font-size: 16px;
      position: relative;
      top: 10%;
    }

    .blocks-exits {
      font-size: 14px;
    }

    .added {
      background-color: #efefef !important;
      color: #000000 !important;

    }

  }

  .block-settings {
    background: variables.$gray-light;
  }

  .block-desc {
    font-size: 14px;
    padding: 12px 12px 12px 0;
    border-bottom: 1px solid #969495;
    margin: 10px;
  }

  .desc {
    font-size: 13px;
    padding: 10px;
  }

  .top-filter {
    padding: 15px;

    .category md-icon {
      margin: 0 5px 0 0;

    }

    .md-block {
      width: 100%;
      max-width: 200px;
    }
  }

  @media screen and (max-width: 600px) {
    .dynamic-dashborad .gap-right-hard {
      margin-right: 0;
    }

    .top-filter .md-block {
      max-width: 100%;
    }

    .emptydash-centre {
      text-align: center;
      width: 100%;
      display: inline-block;
    }

    .empty-header {
      text-align: center;
    }
  }
}
