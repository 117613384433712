@use "../../settings/third-party/variables";
@use "../../settings/style-guide";
@use "../../settings/custom";

/*Quotes List Start*/
.scheduling-list {
  .viewby-select {
    margin: 0;
    width: 100px;
    margin-bottom: 9px;
    margin-right: 10px;

    md-select {
      span {
        font-size: variables.$font-size-base;
        color: variables.$gray-dark;
      }
    }
  }

  .right-list-section {
    @extend .f4;

    md-icon {
      height: 15px;
      width: 15px;
      margin-left: 10px;
      margin-right: 5px;

      &.big-icon {
        width: 20px;
        height: 20px;
        margin-left: 20px;
        margin-right: 0;
      }

      &.small-icon {
        width: 11px;
        height: 11px;
      }
    }
  }

  .table-responsive {
    // margin: 15px 20px 20px;
    // overflow-x : auto;
    // padding-top: 0;
  }

  .row-check {
    md-checkbox {
      display: none;
    }
  }

  .data-table {
    min-width: 700px;

    a {
      font-size: variables.$font-size-small;
      @extend .ellipsis;
      width: 125px;
      display: inline-block;
    }

    .inventory-text {
      font-weight: 500;
    }

    .customer-services {
      @extend .f3;

      md-icon {
        width: 20px;
        height: 14px;
      }

      span {
        display: inline-block;
        vertical-align: middle;
        margin-right: 6px;
        font-size: 12;
      }
    }

    tr table tr {
      &:hover {
        .row-check {
          md-checkbox {
            display: inline-block;
          }
        }
      }
    }

    .row-check {
      md-checkbox {
        &.md-checked {
          display: inline-block;
        }
      }
    }

    td {
      vertical-align: middle;
      padding: 15px;
    }

    .ico-touch-arrow {
      width: 15px;
      height: 15px;

      svg {
        path {
          fill: variables.$brand-accent;
        }
      }
    }

    .action-services {
      @extend .f4;

      md-icon {
        width: 20px;
        height: 14px;
      }

      span {
        display: inline-block;
        vertical-align: middle;
        margin-right: 6px;
        font-size: 12;
      }
    }

    .rating {
      .small-star {
        height: 12px;
        width: 12px;
        margin-bottom: 3px;
        margin-left: 2px;

        svg {
          path {
            fill: variables.$brand-primary;
          }
        }
      }

      a {
        font-size: 10px;

        &.new-cutomer {
          color: variables.$brand-success;
        }
      }
    }

    .updates {
      // @extend .f3-light;
      font-size: 10px;
      line-height: 1.4;
    }

    .table-btn {
      min-width: 0;
      min-height: 0;
      padding: 0;
      margin: 0;
    }
  }

  .table-toolbar-icon {
    padding-top: 5px;

    md-icon {
      height: 30px;
      width: 30px;
      margin: 0 0 0 10px;
    }
  }

  .swipe-wrapper {
    overflow: hidden;
    min-width: 720px;
  }

  // .data-table thead th:nth-child(1) {
  //   text-align: center;
  // }

  .data-table tbody td:nth-child(1),
  .data-table thead th:nth-child(1) {
    width: 60px;
    // padding-right: 0;
  }
}

.toolbar-icon {
  height: 30px;
  width: 30px;
  margin-left: 28px;
}

// .svg-base {
//   svg {
//     path {
//       fill: rgb(151, 153, 155);
//     }
//   }
// }

.svg-base-white {
  svg {
    path {
      fill: variables.$white;
    }
  }
}

.search-button {
  display: inline-block;
  border: none;
  top: 0;
  right: 0;
  z-index: 2;
  position: relative;
  margin: 0;
  cursor: pointer;

  svg path {
    fill: #8E8E8E;
  }
}

.scheduling-list .fixed-header table td.row-check {
  padding: 0 0 0 7px;
  min-width: 30px;
}
