@use "../../settings/style-guide";

.notification-details {
  padding: 20px;

  .col-2-list {
    li {
      margin: 10px 0 20px;
    }

    label {
      @extend .f4;

      display: inline-block;
      max-width: 160px;
      margin-right: 10px;
      margin-bottom: 5px;
    }

    span {
      @extend .f3;
    }
  }
}
