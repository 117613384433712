@use "../settings/third-party/variables";


/*for common css of all customer screens*/
.table-responsive {
  padding: 15px 0 0;

  .data-table {
    min-width: 600px;

    // th {
    //   @extend .f3;
    //
    //   font-weight: normal;
    // }

    .ico-table {
      width: 20px;
      height: 20px;
      margin-right: 5px;

      &.small {
        width: 15px;
        height: 15px;

        svg {
          path {
            fill: variables.$gray;
          }
        }
      }

      &.active {
        svg {
          path {
            fill: variables.$brand-accent;
          }
        }
      }
    }
  }
}

.cust-details{
  .default-card-box {
    margin-bottom: 0;
  }

  .small-star {
    height: 12px !important;
    width: 12px !important;
    margin-bottom: 3px;
    margin-left: 2px;
  }

  .tip-statement {
    display: none;
  }

  .website:not(:last-of-type) {
    margin-bottom: 14px;
  }

  .md-menu-backdrop {
    background: rgba(0, 0, 0, .4);
  }

  .status-dropmenu.child-list-dropmenu:before,
  .status-dropmenu.child-list-dropmenu:after {
    right: 15px;
  }

  /* [vg-vimeo] iframe {
    height: 100% !important;
    margin-top: 0 !important;
  } */
  .status-box {
    border: 2px solid #a8b6c2;
    text-align: center;
    /* padding: 10px; */
    border-radius: 20px;
    width: 200px;
    height: 35px;
    background-color: #fff;
    &.open-activity{
      border: 1px solid variables.$brand-primary;
      background-color:variables.$brand-primary;
      width: 150px;
      &.active{
        background-color: transparent;
        color: #000000;
      }
    }
  }

  .status-box .active {
    color: #6aa84f;
  }

  .status-box .inactive {
    color: #e06666;
  }

  .contact-status-box {
    border-color: red;
  }

  .min-height-35 {
    min-height: 35px;
  }

  .height-100 {
    height: 100%;
  }

  .same-as-mailing {
    font-size: 15px;
    margin: 5px 0;
  }

  .gap-8 {
    gap: 8px;
  }
}

.last-update-msg {
  top: 10px;
  right: 8px;
}

.customer-common-bar {
  .customers-toolbar {
    background: transparent;
    width: auto;
    padding-right: 2px;
    min-height: 0;
    margin-top: 15px;
    margin-bottom: 15px;

    .md-toolbar-tools {
      height: auto;
      padding: 0;
      max-height: none;
    }
  }

  .table-toolbar-icon {
    padding-top: 5px;

    md-icon {
      height: 30px;
      width: 30px;
      margin: 0 0 0 10px;
    }
  }

  .md-button.md-icon-button.table-ico {
    height: 30px;
    border-radius: 0;
    margin: 0;

    md-icon {
      margin: 0;
    }
  }
}

.default-card-box {
  md-tabs-wrapper md-tabs-canvas md-pagination-wrapper md-tab-item.md-active:after {
    border-top-color: variables.$navbar-default-bg;
  }
}

.contact-user-list {
  li:not(:last-of-type) {
    margin-bottom: 20px;
  }

  .desc {
    h3 {
      margin: 0;
      display: inline-block;
      font-size: 12px;
      &.supplier-detail-name{
        font-size: 14px;
        font-weight: bold;
      }
    }

    p {
      display: block;
      position: relative;
      padding-left: 20px;
      margin: 14px 30px 5px 0;
      text-align: left;
    }

    .icon-a,
    .icon-b {
      position: absolute;
      left: 0;
    }

    .icon-a {
      top: -3px;
    }

    .icon-b {
      top: -5px;
    }

    .icon {
      color: variables.$gray-light;
      vertical-align: bottom;
      font-size: variables.$font-size-large;
    }

    .icon-ico-phone {
      width: 15px;
      height: 20px;
    }

    // .status {
    //   margin: 0 10px 0 5px;
    //   color: $gray-light;
    //
    //   &.active {
    //     color: $brand-success;
    //   }
    // }

    .place {
      display: block;
      color: variables.$gray;
      margin-bottom: 5px;
    }

    .time-info {
      color: variables.$gray;
      font-size: 12px;
    }

    .contact-info {
      min-width: 250px;
      display: inline-block;

      // &.num {
      //   min-width: 0;
      // }

      .ico-landline {
        width: 18px;
        height: 20px;
        position: relative;
        right: 2px;
      }
    }
  }

  .social-info {
    // margin-left: 0;

    md-icon.last-updated {
      width: 18px;
      height: 18px;
    }

    span {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .social-icon-wrapper {
    md-icon {
      margin: 0 2px;
      vertical-align: top;
    }

    .md-button {
      margin: 0;
      height: auto;
      min-width: 0;
      padding: 0;
      width: auto;
      vertical-align: top;
      min-height: 0;
    }
  }

  .user-contact {
    display: inline-block;
    margin-right: 28px;
    margin-top: 4px;
    max-width: 55px;
    color: variables.$gray;

    md-icon {
      width: 55px;
      height: 55px;
    }
  }
}

.customer-rating {
  margin-top: 8px;
  margin-bottom: 25px;

  span {
    display: inline-block;
    vertical-align: top;
    margin-right: 15px;
  }

  md-icon {
    height: 12px;
    width: 12px;
  }

  p {
    margin: 0;
  }
}

.s2 {
  .ar-sidepanel {
    .task-wrapper {
      top: 45px;

      .md-block {
        margin-bottom: 15px;
      }

      md-input-container {
        margin-top: 10px;
        margin-left: 17px;
      }
    }

    md-icon.radio-icon {
      margin-right: 5px;
      width: 20px;
      height: 18px;
    }

    md-radio-button {
      .md-container {
        .md-off {
          background: variables.$white;
        }
      }
    }

    .task-list {
      md-list {
        padding: 0;
      }

      md-list-item.md-no-proxy,
      md-list-item.md-no-style {
        padding: 0;
      }
    }

    .support-sidebar-radios {
      padding: 22px 18px;

      &.active {
        background: variables.$brand-info-light;
      }
    }
  }

  md-radio-group {
    md-radio-button {
      margin-bottom: 0;
    }

    .margin-rt-10 {
      margin-right: 10px;
    }
  }
}

.kb-posting {
  .list-class {
    margin-bottom: 10px;
    list-style: none;
    position: relative;
    line-height: 1.5;
  }

  .ul-list {
    padding-left: 32px;
    padding-top: 12px;
  }
}

.kb-categories {
  .line-category {
    margin-top: 6px;
  }

  .view-all {
    margin-left: 23px;
  }

  .categories-header {
    padding: 0 10px;
  }

  .summerywidget {
    margin-bottom: 10px;
    padding: 0;
  }

  .left-box {
    margin-left: 15px;
  }

  .space-bottom {
    border-bottom: 1px solid #ccc;
    padding: 8px;
  }

  .new-blue {
    color: #1a73e8;
  }

  .user-icon {
    height: 30px;
    width: 30px;

  }
}

.knoewledge-base {
  .right-section {
    position: absolute;
    right: 0;
  }

  .categories-header {
    padding: 0 10px;
  }

  .summerywidget {
    margin-bottom: 10px;
    padding: 0;
  }

  .left-box {
    margin-left: 15px;
  }

  .space-bottom {
    border-bottom: 1px solid #ccc;
    padding: 8px;
  }

  .leftbox {
    /* border: 1px solid #ccc; */
    padding: 18px;
    margin-right: 32px;
    /* border-radius: 9px; */
  }

  .searchIcon {
    top: 11px;
  }

  .new-blue {
    color: #1a73e8;
  }

  .updated {
    margin-left: 15px;
  }

  .spacebottom {
    border-radius: 0;
    border-bottom: 1px solid #ccc;

  }

  .spacebottom:last-of-type {
    border-radius: 0;
    border-bottom: 0 solid #ccc;

  }

  .boxcontainer {
    border: 1px solid #ccc;
    border-radius: 21px;
  }

  .right-section {
    padding: 30px;
  }

  .user-icon {
    height: 30px;
    width: 30px;

  }

  .question {
    font-size: 13px;
  }

  .replies {
    margin-top: 10px;
  }

  .replies-count {
    margin-top: 17px;
  }
}

.kb-catalog {
  .view-post {
    display: none;
  }
}

@media (min-width: 300px) and (max-width: 630px) {
  .knoewledge-base {
    .replies-count {
      margin-top: 28px;
    }
  }
}

@media (min-width: 300px) and (max-width: 1140px) {
  .kb-content {
    width: 80%;
    margin: 0 30px;
  }

  .kb-maincontainer {
    display: block;
    width: 100%;

    .right-section {
      max-width: 100%;
    }
  }

  .knoewledge-base {
    .right-section {
      position: relative;
      right: 0;
    }

    .question-contact {
      max-width: 100% !important;
    }
  }

  .kb-catalog {
    .view-post {
      display: none;
    }

    .view-allpost {
      display: block;
    }
  }
}

@media (min-width: 1045px) and (max-width: 1050px) {
  .kb-catalog {
    .view-post {
      display: block;
    }

    .view-allpost {
      display: none;
    }
  }
}

@media (min-width: 1150px) {
  .kb-catalog {
    .view-post {
      display: none;
    }

    .view-allpost {
      display: block;
    }
  }
}

@media (min-width: 600px) {
  .contact-user-list {
    .social-info {
      text-align: right;
    }

    // .customer-since {
    //   display: inline-block;
    // }
  }
}

@media (max-width: 600px) {
  .contact-user-list {
    .social-info {
      margin-top: 10px;
    }

    // .customer-since {
    //   margin: 5px 0 10px;
    // }
  }
}
