.customers-list .data-table.archive-img-table .row-check {
  height: 40px;
  td md-chechbox.selected {
    display: inline-block;
  }
  th md-checkbox {
    display: inline-block;
    position: relative;
    bottom: 5px;
  }
}

.customers-list {
  .c-filter {
    position: absolute;
    right: 20px;
    top: 18px;
  }
  .filter-label {
    margin-top: 20px;
  }
  .filters-bar {
    md-input-container.filter-input {
      margin-top: 5px;
      .md-input,
      md-select {
        margin-top: 8px;
      }
    }
  }
}
