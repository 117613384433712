@use "third-party/variables";

/*--Custom Open Calender Css --*/
.custom-calender {
  table {
    max-width: 100%;
    width: 100%;
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;
  }

  th {
    text-align: center;
  }

  td,
  th {
    padding: 0;
    background: #fff;
  }

  .btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px;
    font-size: variables.$font-size-base;
    line-height: 1.42857143;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .btn-default:not([disabled]) {
    color: #333;
    background-color: #fff;
  }

  .btn-sm {
    padding: 4px 10px;
    font-size: variables.$font-size-small;
    line-height: 1.5;
    border-radius: 3px;
    background: #fff;
  }

  .small,
  small {
    font-size: 85%;
    color: variables.$brand-accent;
    padding: 10px 0 1px;
    display: inline-block;
  }

  .btn-default.active,
  .btn-default:active {
    background-color: #e6e6e6;
    border-color: #e6e6e6;
  }

  .ico-arrow {
    height: 20px;
    width: 20px;

    svg {
      path {
        fill: #4d4d4d;
      }
    }

    &.right-direction {
      transform: rotate(180deg);
    }
  }

  .uib-daypicker {
    .btn {
      height: 38px;
      width: 36px;
    }

    .text-muted {
      color: #a0a0a0;
    }

    .text-info {
      color: #fff;
      font-weight: bold;
      position: relative;

      .c-num {
        position: relative;
        z-index: 2;
      }

      &:before {
        position: absolute;
        top: -10px;
        bottom: -10px;
        left: -12px;
        right: -12px;
        content: "";
        background: variables.$brand-accent;
        z-index: 1;
        border-radius: 100%;
      }
    }

    .btn-default.active,
    .btn-default:active {
      border-radius: 100%;
    }
  }

  .uib-monthpicker {
    .btn {
      width: 80px !important;
      height: 40px;
      margin: 3px;
    }
  }

  .uib-yearpicker {
    .btn {
      height: 45px;
      margin: 3px;
    }
  }
}
