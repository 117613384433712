@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.opportunity-pipeline-section {
  .opportunity-pipeline {
    padding: 20px;

    .table-responsive {
      .data-table {
        td {
          &:last-child {
            width: auto;
          }

          @extend .f3-light;
        }
      }
    }
  }

  .map-switch .map-switch-btn {
    position: relative;
    top: 10px;
    right: 4px;
    margin-left: 8px;

    @media only screen and (max-width: 600px) {
      right: -5px;
    }
  }

  // used in manage lanes sidebar
  .active-lanes {
    .lane {
      margin-bottom: 25px;
    }

    md-input-container:first-of-type {
      margin-right: 10px;
    }

    md-icon path {
      fill: variables.$brand-primary;
    }

    .add-lane {
      md-icon {
        margin-right: 5px;
      }
      margin-bottom: 30px;
    }

    .visual-icon {
      height: 30px;
      width: 30px;
      margin-right: 5px;

      .visual {
        transform: translate(-50%, -50%) scale(0.47); // ~30px
      }
    }
  }

  .associate-right-nav {
    min-width: 415px;
    width: 100%;

    .form-base {
      margin-right: 8px;
    }

    .close-filter {
      background-color: transparent;
      margin: 0;
      padding: 0;
      min-width: 0;
    }

    .close-filter:hover {
      background-color: transparent;
    }

    md-checkbox .md-container {
      top: 50%;
      left: 0;
      width: 20px;
      height: 20px;
      margin: 0;
    }

    md-checkbox .md-container:before {
      background-color: transparent;
      border-radius: 50%;
      bottom: 0;
      box-sizing: border-box;
      content: "";
      display: block;
      height: auto;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: all 0.5s;
      width: auto;
    }

    md-checkbox .md-container:after {
      bottom: -10px;
      box-sizing: border-box;
      content: "";
      left: -10px;
      position: absolute;
      right: -10px;
      top: -10px;
    }
  }

  .pipeline-right-nav {
    min-width: 415px;
    width: 100%;

    .opportunity-pipeline-sidebar {
      padding: 0;

      .close-div {
        background-color: black;
        padding: 18px;

        md-icon {
          margin-right: 10px !important;

          svg {
            max-width: 50px;
            height: 14px;
            width: 100%;
          }
        }

        p {
          margin: 0 0 5px;
          color: variables.$white;
        }

        h2 {
          margin: 0;
          color: variables.$white;
          font-size: 18px;
        }
      }

      .profile-detail-section {
        ul {
          padding-left: 15px;
          width: 100%;

          .owner-detail {
            .profile-customer-name {
              width: 100%;
              display: flex;
              justify-content: space-between;

              .forward-icon {
                margin-top: 0;
              }
            }

            .age-section {
              .profile-pic.forward-img {
                max-width: 20px;
                height: 20px;
                margin-right: 5px;
              }

              .age-div {
                margin-right: 5px;
              }
            }

            .social-icon-wrapper {
              .insta-icon {
                margin: 0;
                padding: 0;
                width: 25px;
              }

              md-icon {
                svg {
                  path {
                    fill: #dbdbdb;
                  }
                }
              }
            }

            .profile-pic {
              max-width: 50px;
              width: 100%;
              height: 50px;
              margin-right: 15px;
            }

            h3 {
              margin: 0;
              font-size: 18px;
              font-weight: 500;
            }

            p {
              color: #999999;
            }
          }

          .primary_email {
            a {
              font-weight: 600;
              margin-bottom: 8px;

              span {
                margin-right: 20px;
              }
            }

            p {
              margin-top: 0;
            }
          }

          .mb-30 {
            margin-bottom: 30px !important;
          }
        }
      }

      .status-deal-section {
        padding: 0;

        .status-deal-div {
          background-color: #000000;
          padding: 0 10px;

          md-icon {
            margin-right: 15px;

            svg {
              path {
                fill: variables.$white;
              }
            }
          }

          h2 {
            color: variables.$white;
            font-size: 18px;
          }
        }

        ul {
          width: 100%;
          padding: 0;

          li {
            margin-bottom: 10px;
            padding: 15px 0 15px 10px;
          }

          .association-section {
            .owner-detail {
              .profile-pic {
                max-width: 40px;
                height: 40px;
              }

              .add-icon {
                max-width: 30px;
                min-height: 30px;
                width: 100%;

                svg {
                  width: 25px;
                  height: 25px;
                }
              }
            }
          }

          .association-section:hover {
            background-color: #d3e1e54a;

            h3 {
              color: #fe9603;
            }
          }

          .bg-neon {
            background-color: #eaf0d6!important;
          }

          .bg-yellow {
            background-color: #fff2cd!important;
          }

          .bg-yellow.active {
            border: 1px solid #fe9603!important;
          }

          .bg-red {
            background-color: #fae1dc!important;
          }

          .bg-grey {
            background-color: #f6f6f6!important;
          }

          .bg-dark-grey {
            background-color: #d9d9d9!important;
          }

          .background-circel {
            background-color: #fff;
            border-radius: 50px;
            height: 45px;
            width: 47px;
            margin-right: 15px;
            position: relative;

            .icon_png_img {
              width: 30px;
              height: 30px;
              position: relative;
              vertical-align: middle;
              align-items: center;
              display: flex;
              top: 8px;
              left: 6px;
            }
          }

          .owner-detail {
            .profile-pic {
              max-width: 50px;
              width: 100%;
              height: 50px;
              margin-right: 15px;
            }

            h3 {
              margin: 0;
              font-size: 18px;
              font-weight: 500;

              span {
                font-size: 11px;
              }
            }

            p {
              color: #999999;
            }

            .arrow-icon {
              margin-top: 0;

              svg {
                width: 15px;
                height: 15px;

                path {
                  fill: variables.$brand-primary;
                }
              }
            }
          }
        }
      }

      .notify-custom {
        border-radius: 5px;
        margin-right: 8px;
      }
    }
  }

  .text-green {
    color: #6aa84f!important;
  }
}

.opps-pl-sidebar {
  // CHANGE: added class
  .opportunities-header {
    font-size: 15px;
    color: variables.$brand-primary;
  }

  .view-associates-icon {
    md-icon {
      width: 18px;
      height: 18px;
    }
  }

  .associates-quotes-card {
    padding: 20px 15px;
  }

  .quotes-card {
    background-color: variables.$table-bg-accent;
  }

  .quotes-drips-card {
    background-color: #f2f6f8;
  }

  // quotes-card-font class removed
  .quotes-notify {
    min-width: 70px;
    text-align: center;
    padding: 5px 0 4px;
    font-size: 14px;
    font-weight: bold;
    background-color: variables.$text-navy-blue;
    color: #fff;
    border-radius: 2px;

    &.quotes-header {
      background-color: #fff;
      color: variables.$gray-base;
    }

    // Change: color code
    &.drips-sent {
      background: variables.$status-green;
    }

    &.drips-pending {
      background-color: #6fa8dc;
    }
  }

  // Padding removed
  .quotes-id {
    font-size: 16px;
    color: variables.$text-navy-blue;
  }

  .quotes-id-border {
    border-right: 2px solid variables.$gray;

    &.expired {
      border-left: 2px solid variables.$gray;
    }

    // Padding changed
    &.status {
      padding: 0 15px;
    }
  }

  .quotes-details-label {
    min-width: 110px;
  }

  .opportunities-details-label {
    min-width: 120px;
  }

  .line-height-3 {
    line-height: 3;
  }

  .line-height-1 {
    line-height: 1;
  }

  .line-height-2 {
    line-height: 2;
  }

  .line-height-1-half {
    line-height: 1.5;
  }

  .line-height-2-half {
    line-height: 2.5;
  }

  .line-height-px {
    line-height: 15px;
  }

  .notify,
  .sales-status,
  .segment-status {
    &.disabled {
      background-color: #eaeaea;
    }

    &.active,
    &.enabled,
    &.sent,
    &.won {
      background-color: #eaf0d6;
    }

    &.draft,
    &.expired,
    &.inactive,
    &.lost,
    &.pending {
      background-color: #fae1dc;
    }

    &.postponed {
      background-color: #fff2cd;
    }
  }

  // CHANGE: class added
  md-select .md-select-placeholder {
    font-weight: bold;
    color: black;
    font-size: 16px;
  }

  .notify {
    &.counter-drip-status {
      padding: 0 10px !important;
      border-radius: 20px !important;
    }
  }

  // CHANGE: padding changed
  .md-select-wrapper {
    padding: 25px 20px 10px 30px;
  }

  // CHANGE: font-color
  .font-color-gray {
    color: #adada7;
  }

  .quotes-text {
    color: #000;
  }

  .associates-row {
    color: #000;
    background-color: #fff;
    border: 1px dashed transparent;

    &.active,
    &:hover {
      border-color: variables.$brand-primary;
      background: #FFE9CE;
    }
  }
}

.fulfillment-pipeline-view {
  .navigation-btn {
    padding: 3px;
    border: 1px solid variables.$brand-primary;
    border-radius: 30px;
    color: #fff;
    width: 110px;

    &.pending {
      background-color: variables.$status-red;
    }

    &.fully {
      background-color: #6aa84f;
    }

    &.partial {
      background-color: rgb(180,95,6);
    }
  }

  .service-flags {
    &.sidebar-arrow-icon {
      height: 20px;
      width: 20px;
      top: -6.5px;
    }
  }

  .label-indicators-open {
    height: auto !important;
    width: auto !important;
    border-radius: 45px !important;
    padding: 3px 10px;
    position: relative !important;
    top: -9px;
  }

  .service-title {
    color: #000;
    font-size: 16px;
  }

  .shipping-details {
    border-radius: 4px;
    background: #fff;
  }

  .clone-icon {
    width: 25px;
    height: 25px;

    path {
      fill: variables.$brand-primary;
    }
  }

  .status.pending {
    background-color: rgba(224, 102, 102, .1) !important;
  }

  .status.partial {
    background-color: rgba(230, 140, 56, .1) !important;
  }

  .status.fully {
    background-color: rgba(106, 168, 79, .1) !important;
  }

  .order-status .status-viewer {
    border-radius: 12px;
  }
}

.invalid-email-container {
  border-bottom: 5px solid rgb(20,53,76);
  background-color: rgb(242,246,248);

  .invalid-email-action-icon {
    width: 290px;
    height: 320px;
  }

  @media (min-width: 600px) {
    .invalid-email-action-icon {
      position: absolute;
      top: -184px;
      bottom: 0;
    }
  }
}

.cust-cmn-sidenav {
  .panel-header {
    background-color: #f2fffe !important;
  }

  .bold-text {
    color: #000 !important;

    &:hover {
      color: variables.$brand-primary !important;
    }
  }

  .cust-stats {
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 6px;
  }

  .associates-row {
    border: 0;

    &.active,
    &:hover {
      border: 0;
      background: #EEF3F6;
    }
  }

  // .view-associates-icon {
  //   .icon-active {
  //     path {
  //       fill: $brand-warning;
  //     }
  //   }
  // }

  .customer-desc {
    background-color: #081F2F;
    color: #fff;

    h1 {
      color: variables.$brand-primary;

      .company-details {
        color: #fff;
        font-weight: 500;
      }
    }

    .contact-ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .tab-content {
    background-color: #F2FFFE;
  }

  md-tabs-wrapper {
    md-next-button,
    md-prev-button {
      background: rgb(238, 243, 246);
    }
  }

}
