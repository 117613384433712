@use "../../settings/third-party/mixins";
@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.f3-darker {
  color: variables.$gray-darker;
}

.inventory-detail-list-section {
  .primary-text {
    color: variables.$brand-primary;
    cursor: pointer;
  }

  .small-text {
    font-size: 10px;
    color: variables.$gray;
  }

  .location-symbol {
    margin-bottom: 5px;
    margin-left: 5px;
  }

  .order-status {
    @include mixins.border-radius(14px);
    background: variables.$white-darker;
    color: variables.$gray-dark;
    display: inline-block;
    font-size: 10px;
    min-width: 70px;
    padding-left: 6px;
    padding-right: 6px;
    text-align: center;
  }

  .mini-icons {
    height: 14px;
    width: 14px;
  }

  .filters-bar {
    margin: 0 0 16px;
  }

  .inventory-detail {
    margin-bottom: 20px;

    .assign-card {
      box-sizing: border-box;
      line-height: 20px;
      margin-bottom: 10px;
      //            .product-img {
      //                max-width: 40px;
      //                min-width: 40px;
      //            }
      .product-no-img {
        height: 30px !important;
        min-width: 45px;
      }

      .edit-card-btn {
        margin: 0;
        padding: 0;
        height: 18px;
        min-height: 18px;
        width: 18px;

        md-icon {
          height: 12px;
          width: 12px;
        }
      }

      .catalog-no {
        margin-bottom: 10px;
      }

      .cat-lbl {
        @extend .f4;
        margin-right: 10px;
      }

      .mini-icons {
        margin-right: 8px;
      }
    }

    .status {
      &.active {
        color: variables.$brand-success-dark;
      }
    }
  }

  .inventory-table {
    .data-table {
      tr {
        &.additional-menu {
          td {
            background: variables.$white-dark;
            font-size: 10px;
            padding-top: 7px;
            padding-bottom: 7px;
          }

          .mini-icons {
            margin-right: 6px;

            svg path {
              fill: variables.$gray;
            }
          }
        }
      }

      td {
        @extend .f3;
        border-bottom: none;
      }

      .location-symbol {
        margin-left: 0;
        margin-right: 5px;
        min-width: 30px;
      }

      .stock-location {
        display: inline-block;
      }
    }
  }
}

.inventory-stock-order {
  h2 {
    font-size: variables.$font-size-large;
    margin-bottom: 0;
    font-weight: normal;
  }

  .data-table {
    min-width: 760px;

    td {
      @extend .f3;
      padding: 20px 15px;

      img {
        margin-bottom: 6px;
        min-width: 40px;
        max-width: 55px;
      }
    }
  }
}

.in-stock-status {
  padding: 12px;

  .location-list {
    height: 20px;
    min-height: 20px;
    margin-bottom: 7px;

    &:last-child {
      margin: 0;
    }
  }

  &:after {
    border-width: 13px;
    margin-right: 146.5px;
  }

  &:before {
    border-width: 14px;
    margin-right: 145px;
  }
}

.inventory-detail-sidebar {
  .gap {
    padding-right: 18px;
    padding-left: 18px;
  }

  &.form-base {
    md-input-container {
      margin-bottom: 10px;
    }
  }

  .bottom-section,
  .top-section {
    position: absolute;
    right: 0;
    left: 0;
  }

  .top-section {
    top: 0;
    bottom: 50px;
    overflow-y: scroll;

    h2 {
      color: variables.$gray-darker;
      font-weight: 400;
      margin-top: 20px;
      margin-bottom: 6px;
    }

    .search-result {
      @extend .fm;
      @extend .f3-darker;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }

  .bottom-section {
    bottom: 0;
    height: 50px;

    .md-button.btn-default {
      color: variables.$gray;
    }
  }

  md-divider {
    margin-right: -18px;
    margin-left: -18px;
  }

  .item-list {
    li {
      line-height: 18px;
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .md-button.md-icon-button {
      margin-top: -12px;
      margin-right: -12px;

      md-icon {
        height: 18px;
        width: 18px;

        svg path {
          fill: variables.$gray;
        }
      }
    }

    .sm-text {
      font-size: 10px;
    }

    h3 {
      color: variables.$gray-dark;
      line-height: normal;
      margin-top: 0;
    }
  }
}

.filters-bar {
  &.inventory {
    .show-border {
      border: 1px solid variables.$brand-primary;
    }

    .inner-inputs {
      margin-top: 15px;
    }

    .right-aligned {
      float: right;
    }

    .right-divider {
      padding-right: 15px;
      border-right: 1px solid lightgray;
    }

    .small-btn {
      min-height: 20px !important;
      line-height: 20px !important;
      height: 20px !important;
      font-size: 12px !important;
      margin: 6px 80px;
    }

    .date-picker {
      margin-bottom: 15px;
    }

    .section-gap {
      margin-top: -22px;
    }

    .sec-ul {
      li {
        margin-bottom: 15px;
      }
    }

    .showmore {
      font-size: 14px;
      margin-top: 10px;

      md-icon {
        height: 12px;
        width: 12px;

        svg {
          g {
            fill: variables.$brand-primary;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1160px) {
  .filters-bar {
    &.inventory {
      .showmore {
        margin-top: 40px;
      }
    }
  }
}
@media screen and (max-width: 1146px) {
  .filters-bar {
    &.inventory {
      .right-divider {
        border-right: none;
      }

      .showmore {
        margin-top: 40px;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .filters-bar {
    &.inventory {
      li:not(.filter-btns) {
        width: 100%;

        md-select {
          width: 100%;
        }
      }

      .showmore {
        margin-top: 90px;
      }
    }
  }
}
@media screen and (min-width: 600px) {
  .filters-bar {
    &.inventory {
      .top-space {
        margin-top: 35px;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .filters-bar {
    &.inventory {
      .filter-label {
        margin-bottom: 10px;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .inventory-detail-list-section {
    .inventory-detail {
      .inner-section {
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }

      .assign-card {
        .catalog-no {
          margin-right: 15px;
          margin-bottom: 0;
        }
      }
    }
  }
}
