.email-inbox {
  md-checkbox {
    min-height: 25px;
    margin-left: 5px;
  }
  .read-email {
      background-color: white !important;
  }

  .no-background {
      background-color: #e2f4f4;
  }
  .inventory-addproduct-section {
    // overflow: hidden;
    padding-left: 0;
    @media (max-width:600px) {
      padding-right: 0;
    }
  }

  .segment-info-sec {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;

    .inbox-heading {
      font-size: 32px;
      font-weight: 500;
      color: #20394c;
    }

    .icon-next-arrow {
      rotate: 90deg;
      font-weight: bold;
      width: 35px;
      position: relative;
      bottom: 4px;

      svg {
        path {
          fill: #64c43aff;
        }
      }
    }

    .icon-search-container {
      border: 1px solid #cccccc;
      padding: 5px;
      max-width: 400px;
      min-width: 300px;
      width: 100%;
      .search-input {
        border: none;
        max-width: 355px;
        min-width: 250px;
        width: 100%;
      }
    }
  }

  .icon-grid-list-newtab:hover {
    svg {
      path {
        fill: #cc0000!important;
      }
    }
  }

  .inbox-body-content-section {
    border-top: 1px solid #dbdbdb;
    margin-top: 12px;
    padding: 0px;
    // padding: 10px 10px 10px 0;

    .inbox-body-content {
      .icons-grid {
        max-width: 210px;
        width: 100%;
        margin-top: 10px;

        .md-mini {
          min-height: 38px;
          min-width: 42px;
          margin: 0;

        }

        .md-mini:hover {
          background-color: transparent;
        }

        .icon-grid-list {
          width: 35px;
          height: 35px;
          margin-right: 0;
        }

        .icon-grid-list:hover {
          svg {
            path {
              fill: #cc0000!important;
            }
          }
        }

        .icon-grid-list-flag {
          border: 1px solid;
          border-radius: 50px;
          padding: 7px;
        }

        .icon-grid-list-flag:hover {
          border: 1px solid #cc0000!important;
        }

      }

    }
    @media (max-width:600px) {
      padding-right: 0;
    }

    .inbox-body-content-text {
      margin-top: 30px;
      // overflow-y: auto;
      // height: 600px;

      .mail-text-content {
        display: flex;
      }

      md-datepicker {
        .md-datepicker-calendar-icon {
          svg {
            path {
              fill: rgb(151, 153, 155);
            }
          }
        }
      }

      .dot_icon_png {
        top: 5px !important;
        margin-right: 2px;
      }

      .icon-grid-list-newtab {

        top: -2px !important;
      }

      .icon_png {
        width: 22px;
        height: 16px;
        margin: 0 2px 0 0;

      }

      // ul {
      //   padding-top: 10px !important;
      // }

      li {
        background-color: #e2f4f4;
        padding: 0 10px;
        display: flex;
        //height: 55px;
        box-shadow: #9cc8c87a 0 0px 2px 0px;
        &.read-email{
          .user-name {
            font-weight: normal!important;
          }
        }
        &.small-chip{
          box-shadow: none;
          padding-left: 5px;
          align-items: center;
          background-color: transparent;
          .user-name {
            line-height: 35px;
            padding: 0 0px 0 10px;
            max-width: 135px;
            font-weight: bold;
            @media (max-width:600px) {
              line-height: 35px;
              padding: 0 0px 0 8px;
              max-width: 130px;
            }
          }
        }
        .hover-icon{
          display: none;
          
        }
        .hover-date{
          display: block;

        }
      }
      li.small-chip .img-replacer,
      li.small-chip .form-base .avtar .i-holder .quote-image-replacer-edit-contact,
      .form-base .avtar .i-holder .custom-chips li.small-chip .quote-image-replacer-edit-contact, 
      .custom-chips li.small-chip img, .custom-chips li.small-chip md-icon {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
      }

      li:hover {
        box-shadow: rgb(148, 148, 148) 0 3px 18px -1px;
        border: none;
        z-index: 1;
        position: relative;
        &.small-chip{
          box-shadow: none;
        }
        .date-time-section{
          display: flex;
          justify-content: end;
          .hover-icon{
            display: block;
            
          }
        }
        
        .hover-date{
          display: none;
        }
        .icon-grid-list-newtab {
          svg {
            path {
              fill: #cc0000!important;
            }
          }
        }
      }

      md-icon.star-icon-filled:hover {
        svg {
          path {
            fill: rgb(251, 207, 21);
            height: 24px;
            width: 24px;
          }
        }
      }

      .no-background {
        background-color: transparent;

        .ico-name {
          margin: 0 5px 0 0;
        }
      }

      .no-background:hover {
        box-shadow: #949494 0 3px 18px -1px;
        border: none;
        z-index: 1;
        position: relative;
      }

      .text-with-dot {
        max-width: 245px;
        width: 100%;
        margin-right: 25px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        
        .circle-dot {
          width: 10px;
          height: 10px;
          padding: 5px;
          background-color: #6a6a6aff;
          border-radius: 50px;
          display: inline-block;
          @media (max-width:600px) {
            margin-right: 15px!important;
          }
        }
        .circle-dot.green{
          background-color: #00ff00ff;
        } 
        .circle-dot.blue{
          background-color: #06b7f8ff;
        } 
        .circle-dot.red{
          background-color: #f45150ff;
        } 

        .description-bold {
          font-weight: bold;
        }
        .description-bold , .description{

          &.title-desc{
            @media (max-width:1000px) {
              white-space: nowrap;
              max-width: 198px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: block;
              width: 100%;
            }
            @media (max-width:600px) {
              white-space: nowrap;
              max-width: 198px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: block;
              width: 100%;
            }
          }
        }

        .description {
          font-weight: normal;
          
        }
        @media (max-width:1435px) {
          max-width: 200px;
          width: 100%;
        }
        
        @media (max-width:1250px) {
          padding-bottom: 0px;
          padding-bottom: 10px;
          display: flex;
          padding-top: 5px;
          align-items: center;
          max-width: 600px;
          width: 100%;
          .circle-dot {
            margin-right: 5px;
          }
        }
       
        @media (max-width:600px) {
          padding-bottom: 10px;
          display: flex;
          padding-top: 0px;
        }
      }

      .preview {
        color: #666666;
        max-width: 540px;
        width: 100%;
        margin-right: 25px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width:1435px) {
          max-width: 400px;
          width: 100%;
          // margin-bottom: 15px;
        }
        @media (max-width:600px) {
          // margin-bottom: 15px;
          margin-left: 25px;
          padding-bottom: 5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 198px;
        }
      }

      .time {
        max-width: 200px;
        width: 100%;
        text-align: end;
        display:flex;
        justify-content: end;
        align-items: center;
        
        .icon-div {
          float: left;
          width: 50%;
          margin-right: 20px;
          text-align: start;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          &.icon-div-mobile{
            display: none;
          }
          .star-icon-filled {
            position: relative;
            // bottom: 5px;
            margin: 0;
          }
          @media (max-width:600px) {
            justify-content: space-between;
          }
          @media (max-width:1250px) {
            &.icon-div-mobile{
              display: flex;
            }
            &.icon-div-web{
              display: none;
            }
          }
        }

        .time-div {
          float: none;
          margin-right: 10px;
          @media (max-width:600px) {
            margin-bottom: 10px;
            float: left;
          }
        }

        .ico-table {
          margin: 0;
          position: relative;
          /* bottom: 10px; */
          width: inherit;
          @media (max-width:600px) {
            width: auto;
            padding: 0;

            .md-button.md-icon-button {
              padding: 0;
              margin: 0;
            }

            .md-datepicker-input-container {
              display: none;

            }
          }
        }
        @media (max-width:1250px) {
          flex-direction: column;
          align-items: end;
          max-width: 115px;
          padding-top: 15px !important;
          gap: 10px;
          .icon-div {
            width: 85%;
            margin-right: 0;
            margin-bottom: 0px;
          }
        }
        
        @media (max-width:600px) {
          align-items: end;
          max-width: 115px;
          padding-top: 15px !important;
          .date-time-div{
            align-items: center;
          }
          .icon-div {
            width: 85%;
            margin-right: 0;
            margin-bottom: 20px;
          }
        }
      }
      .data-table {
        border: none;
        
      }
      @media (max-width:1250px) {
        .data-table.archive-img-table{
            min-width: 325px;
            .row-check{
              height: auto;
            }
        } 
      }
      .archive-img-container {
        width: auto;
        line-height: 45px;
        height: 45px;
      }
      .icon-section {
        max-width: 50px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 15px 0px;
        align-items: center;
        gap: 5px;
        // display: inline-block;
        //padding-top: 15px;
        @media (max-width:600px) {
          padding-top: 15px;
          max-width: 30px;
          display: grid;
          gap: 0px;
          display: none;
        }

        .md-checkbox {
          min-height: 30px;
          margin-right: 0px;
          
        }
        .star-icon-filled{
          margin: 0px;
        }
      }

      .icon-name-section {
        display: flex;
        width: 100%;
        max-width: 185px;
        &.icon-name-div{
          justify-content: flex-start;
          padding: 0px;
          border: none;
          @media (max-width:1000px) {
            align-items: center;
            display: flex;
          }
          @media (max-width:600px) {
            padding-top: 0px;
          }
        }
        .avatar_icon_png{
          width: 25px;
          height: 26px;
          border-radius: 50px;
          margin-top: 7px!important;
          @media (max-width:600px) {
            margin-right: 3px;
          }
        }
        .ico-name {
          margin: -3px 5px 0 0;
          min-width: 26px;

          svg {
            width: 25px;
            height: 25px;
          }
        }

        .ico-name:hover {
          background-color: transparent;
        }
        @media (max-width:600px) {
          padding-left: 10px;

          .ico-name {
            margin-right: 5px;
            position: relative;
            bottom: 0px;
            padding: 0;
            margin: 0px 2px 0 0;
          }
        }
        
      }

      .text-label {
        max-width: 130px;
        width: 100%;
        padding: 22px 15px 0 0;
        display: inline-block;
        @media (max-width:1000px) {
          padding-top: 0px;
        }
      }
      @media (max-width:600px) {
        .text-label {
          padding: 0px 0 0;

        }
      }
      
      .mail-text-div {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-left: 1px solid #c1c1c1;
        align-items: center;
        padding: 20px 0 20px 10px;
        @media (max-width:1250px) {
          display: grid;
          gap: 5px;
        }
        @media (max-width:600px) {
          margin-bottom: 0px;
          justify-content: flex-start;
          border-left: none;
          align-items: center;
          padding: 0px 0 10px 15px;
        }
      }
      .mail-desc-div{
        @media (max-width:600px) {
          display: grid;
          align-items: center;
          padding: 0px 0 0 17px;
          margin-bottom: 10px;
        }
      }

      // .time {
      //   padding-top: 20px;

      // }

      .right {
        float: right;
      }

      .text-end {
        text-align: end;
      }
    }
  }

  .pl-0 {
    padding-left: 0;
  }

}

.description-bold {
  font-weight: bold;
}
.first-md-checkbox .md-label {
  margin-top: 28px;
}

// md-checkbox {
//   min-height: auto!important;
// }
//
// md-toolbar.footer {
//   border-top: 1px solid #dbdbdb;
//   background-color: #ffffff;
//   z-index: 60;
//   bottom: 0;
//   position: fixed;
// }
//
// @media (max-width:600px) {}
