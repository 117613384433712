@use "../../settings/third-party/variables";

.campaign-charts {
  .campaign-stat-container {
    .campaign-stat {
      margin: 15px 15px 15px 0;
    }
  }

  .activity-updated-by {
    display: flex;

    .cust-name {
      margin: 14px 0 0 10px;
    }
  }

  .activiting-heading md-icon {
    margin-right: 10px;
  }

  .image-container {
    p {
      display: inline-block;
      vertical-align: text-bottom;
      margin-left: 10px;
    }
  }

  .data-table thead tr {
    background: variables.$brand-table;
  }
}