@use "../../settings/third-party/variables";

.active-emails {
  padding: 20px;
}

.template-container {
  .grid-margin {
    margin-top: 30px;
  }

  li {
    &:not(.single) {
      margin-right: 20px;
    }
    min-width: 260px;
    max-width: 280px;
    width: 100%;
    margin-bottom: 20px;
  }

  .temp-widget {

    background: variables.$white;
    border: 1px solid variables.$gray-lighter;

    &.temp-active {
      border-color: variables.$brand-primary;
    }

    .template-heading {
      position: relative;
      background: variables.$gray-light-media;
      border-bottom: 1px solid variables.$gray-lighter;
      padding: 1px 0;
      overflow: hidden;

      h2 {
        padding: 0 15px;
      }

      .ico-tick {
        position: absolute;
        top: 0;
        right: 0;
        height: 18px;
        width: 18px;
      }

      .location-symbol {
        position: absolute;
        top: -11px;
        right: -18px;
        transform: rotate(45deg);
        z-index: 1;
        padding: 12px 10px 0 10px !important;
        border-radius: 0;
      }
    }

    .template-image {
      // padding: 20px;
      overflow: hidden;
      position: relative;

      &:hover {
        .template-overlay {
          display: flex;
        }
      }
    }

    .template-preview {
      margin: 20px;
      overflow: hidden;
      height: 250px;
    }

    .preview-container {
      transform: scale(.41) translate(-72%, -72%);
    }

    .template-overlay {
      background: rgba(0, 0, 0, .6);
      color: variables.$white;
      display: none;
      padding: 20px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .template-options {
      width: 100%;

      md-icon {
        height: 20px;
        width: 20px;
        margin-bottom: 10px;

        path {
          fill: variables.$white;
        }
      }
    }
  }

  md-icon.edit {
    height: 20px;
    width: 20px;

    path {}
  }

  .stat {
    margin: 0;
    display: inline-block;
  }

  .stat-info {
    text-transform: uppercase;
  }

  .location-symbol {
    margin: 3px 0;
  }

  .md-button {
    border: 2px solid;
  }

  .btn-primary {
    padding: 0 15px;
    margin-right: 10px;
    border: 1px solid variables.$brand-primary;
  }

  .btn-default {
    border: 1px solid variables.$gray-lighter;
  }

  .space-bottom {
    margin-bottom: 24px;
  }

  .new-board {
    color: variables.$gray;

    md-icon {
      height: 60px;
      width: 60px;

      path {
        fill: variables.$gray-light;
      }
    }
  }

  .leftnav-wrap {
    .assign-category {
      padding: 20px;

      .icon-close {
        float: right;

        md-icon {
          width: 16px;
        }
      }
    }

    .search-list {
      height: 70%;

      li {
        h2 {
          display: inline-block;
          margin-left: 30px;
        }

        .icon-tick {
          position: relative;
        }
      }

      li.selected {
        .icon-tick {
          display: inline-block;
          margin: 0;
        }
      }

    }
  }
}
