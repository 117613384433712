@use "../settings/third-party/variables";

.signup-parent {
  flex: 1 0 auto;

  md-content {
    padding: 0 30px;
    background-color: black;
  }

  .wizard-section {
    //border: 1px solid #999;
    max-width: 900px;
    width: 100%;
    border-radius: 10px;
    .disabled{
      background-color: #ffe9cf !important;
      color: #e2c5a6 !important;
      cursor: not-allowed;
      border: 1px solid #ffe9cf!important;
    }
    .arrow-icon {
      width: 24px;
      height: 10px;
      margin-right: 0;

      svg {
        path {
          fill: variables.$white;
        }
      }
    }

    .icon-grid-list-newtab {
      height: 18px;

      svg g path {
        fill: #52758d;
      }
    }

    a:hover {
      .icon-grid-list-newtab svg g path {
        fill: variables.$brand-primary;
      }
    }

    .section-right {
      background: url("../../resources/images/signup-sidenav-bg.png") center / cover no-repeat;
      padding: 20px 10px 15px;
      width: 40%;
      height: 600px;
      border-radius: 0 10px 10px 0;

      .count-main {
        width: 100%;
      }

      span.number-round {
        border-radius: 50%;
        padding: 7px 11px;
        font-size: 11px;
        color: variables.$white;
        background-color: #fab963ff;
      }

      span.step-label {
        color: variables.$white;
        margin-right: 5px;
      }

      span.number-round-dot {
        padding: 5px;
        border-radius: 50px;
        margin-right: 8px;
        background-color: variables.$white;

        &.active {
          background-color:#fab963ff;
        }
      }

      .percent_number {
        font-size: 40px;
        margin-bottom: 0;
        color: #fab963ff;
        font-weight: bold;
      }

      .percent_tagline {
        color: variables.$white;
        font-size: 18px;
        margin-bottom: 70px;
      }
    }

    .subs {
      padding: 30px;
      width: 60%;
      height: 600px;
      position: relative;
      background-color: white;
      border-radius: 10px 0 0 10px;
      .form-content-custom{
        width: 360px;
        //width: 100%;
      }
      hr{
        border: 1px solid #cccccc;
      }
      .map-icon-img {
        width: 19px;
        height: 19px;
        margin-top: 10px;
        cursor: pointer;
      }

      .auto-location-div {
        margin-right: 10px;
      }
    }

    .inventory_radio {
      .md-label {
        font-size: 12px;
      }
    }

    .socket {
      width: 200px;
      height: 200px;
      position: absolute;
      left: 50%;
      margin-left: -100px;
      top: 52%;
      margin-top: -100px;
    }

    .hex-brick {
      background: #4dbcab;
      width: 30px;
      height: 17px;
      position: absolute;
      top: 5px;
      animation-name: fade00;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      -webkit-animation-name: fade00;
      -webkit-animation-duration: 2s;
      -webkit-animation-iteration-count: infinite;
    }

    .h2 {
      transform: rotate(60deg);
      -webkit-transform: rotate(60deg);
    }

    .h3 {
      transform: rotate(-60deg);
      -webkit-transform: rotate(-60deg);
    }

    .gel {
      height: 30px;
      width: 30px;
      transition: all 0.3s;
      -webkit-transition: all 0.3s;
      position: absolute;
      top: 50%;
      left: 50%;
    }

    .center-gel {
      margin-left: -15px;
      margin-top: -15px;
      animation-name: pulse00;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      -webkit-animation-name: pulse00;
      -webkit-animation-duration: 2s;
      -webkit-animation-iteration-count: infinite;
    }

    .c1 {
      margin-left: -47px;
      margin-top: -15px;
    }

    .c2 {
      margin-left: -31px;
      margin-top: -43px;
    }

    .c3 {
      margin-left: 1px;
      margin-top: -43px;
    }

    .c4 {
      margin-left: 17px;
      margin-top: -15px;
    }

    .c5 {
      margin-left: -31px;
      margin-top: 13px;
    }

    .c6 {
      margin-left: 1px;
      margin-top: 13px;
    }

    .c7 {
      margin-left: -63px;
      margin-top: -43px;
    }

    .c8 {
      margin-left: 33px;
      margin-top: -43px;
    }

    .c9 {
      margin-left: -15px;
      margin-top: 41px;
    }

    .c10 {
      margin-left: -63px;
      margin-top: 13px;
    }

    .c11 {
      margin-left: 33px;
      margin-top: 13px;
    }

    .c12 {
      margin-left: -15px;
      margin-top: -71px;
    }

    .c13 {
      margin-left: -47px;
      margin-top: -71px;
    }

    .c14 {
      margin-left: 17px;
      margin-top: -71px;
    }

    .c15 {
      margin-left: -47px;
      margin-top: 41px;
    }

    .c16 {
      margin-left: 17px;
      margin-top: 41px;
    }

    .c17 {
      margin-left: -79px;
      margin-top: -15px;
    }

    .c18 {
      margin-left: 49px;
      margin-top: -15px;
    }

    .r1 {
      animation-name: pulse00;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-delay: 0.2s;
      -webkit-animation-name: pulse00;
      -webkit-animation-duration: 2s;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-delay: 0.2s;
    }

    .r2 {
      animation-name: pulse00;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-delay: 0.4s;
      -webkit-animation-name: pulse00;
      -webkit-animation-duration: 2s;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-delay: 0.4s;
    }

    .r3 {
      animation-name: pulse00;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-delay: 0.6s;
      -webkit-animation-name: pulse00;
      -webkit-animation-duration: 2s;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-delay: 0.6s;
    }

    .r1>.hex-brick {
      animation-name: fade00;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-delay: 0.2s;
      -webkit-animation-name: fade00;
      -webkit-animation-duration: 2s;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-delay: 0.2s;
    }

    .r2>.hex-brick {
      animation-name: fade00;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-delay: 0.4s;
      -webkit-animation-name: fade00;
      -webkit-animation-duration: 2s;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-delay: 0.4s;
    }

    .r3>.hex-brick {
      animation-name: fade00;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-delay: 0.6s;
      -webkit-animation-name: fade00;
      -webkit-animation-duration: 2s;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-delay: 0.6s;
    }

    @keyframes pulse00 {
      0% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }

      50% {
        -webkit-transform: scale(0.01);
        transform: scale(0.01);
      }

      100% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }

    @keyframes fade00 {
      0% {
        background: #7fcdbf;
      }

      50% {
        background: #4dbcab;
      }

      100% {
        background: #c6e9e3;
      }
    }

  }

  .plan-type {
    margin-bottom: 50px;

    .type {
      label {
        display: inline-block;
        padding: 10px 40px;
        border: 2px solid #5cc5c3ff;
        color: #5cc5c3ff;
        border-radius: 50px;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }

  .location-info{
    margin-bottom: 20px;
  }
  .plans {
    padding: 35px 20px 20px;
    background: variables.$white;
    font-size: 14px;
    font-weight: bold;
    margin: 10px 15px 18px 0;
    width: 118px;
    height: 110px;
    cursor: pointer;
    border: 2px solid #e8e6e6;
    text-align: center;
    position: relative;

    &.selected,
    &:hover {
      border: 2px solid #5cc5c3ff;
      background-color: variables.$white;
      color: #5cc5c3ff;
    }

    .most-pack-picked {
      background-color: red;
      width: 118px;
      position: absolute;
      top: -19px;
      right: -2px;
      color: variables.$white;
      border-radius: 10px 10px 0 0;
    }
  }

  .plan-type .type label:hover,
  .plan-type .type.active label {
    border: 2px solid #5cc5c3ff;
    background-color: #5cc5c3ff;
    color: variables.$white;
  }

  .pricing-link {
    max-width: 90%;

    a {
      color: #5cc5c3ff;
    }
    a:hover {
      color: variables.$brand-primary;
    }

    p {
      margin-top: 5px;
    }

  }

  .form-base {
    .form-title {
      margin-bottom: 20px;

      .login-text {

        // left: -80px;
        .login-md-icon {
          margin-right: 15px;
        }

        h2 {
          font-size: 24px;
          margin-top: 10px;
        }
      }
    }

  }

  .auto-location-section {
    margin-top: 25px;
  }

  .btn-primary {
    border-radius: 0;
  }

  .security_img {
    width: 50px;
    height: 40px;
    margin: 6px 5px 0 0;

    //margin-left: 21px;
    &.jt-logo-hex {
      width: auto;
    }
  }

  .business-info-notes {
    background-color: #eaf0f6ff;
    border: 1px solid #5cc5c3;
    padding: 5px 10px;
    margin-bottom: 30px;

    p {
      color: #666666;

      .note-text {
        color: #000000;
        font-weight: bold;
        font-size: 11px;
      }
    }
  }

  .jt_logo_img {
    margin-left: 0;
  }

  .bottom-section_cta {
    padding: 0 8px;

    button.btn-primary {
      padding: 0 0 0 43px;

      .arrow-icon {
        width: 24px;
        height: 10px;
        svg path{
          fill: #fff;
        }
      }
    }
    

    // button.btn-primary:hover {
    //   .arrow-icon {
    //     display: block;
    //   }
    // }

    .btn-secondary {
      border: 1px solid variables.$brand-primary;
      color: variables.$brand-primary;
      background-color: transparent;

      &:hover {
        background-color: variables.$brand-primary;
        color: #fff;
      }
    }

  }

  .finishing-section {
    position: absolute;
    top: 73%;
    width: 74%;
    line-height: 2;

    &.jt-text-label {
      font-size: 10px;
      display: flex; 
      justify-content: center; 
      color: white; 

    }
    span.first_text::before { 
      content: "Finishing Up"; 
      animation: change infinite 7s; 
      padding-left: 0px; 
    } 
    span.second_text::before { 
          content: "JACKTRADE SETUP"; 
          animation: animate infinite 7s; 
          padding-left: 0px; 
    } 

    @keyframes change { 

        0% { 
            content: "Setting Up"; 
        } 

        50% { 
            content: "Almost Done"; 
        } 

        75% { 
            content: "Finishing Up"; 
        } 
    } 
    @keyframes animate { 

      0% { 
          content: "YOUR ACCOUNT"; 
      } 

      50% { 
          content: "JACKTRADE SETUP"; 
      } 

      75% { 
          content: "JACKTRADE SETUP"; 
      } 
  } 
  }


  .btn-primary,
  .btn-secondary {
    width: 100%;
    max-width: 115px;
  }

  .timezone_section {
    line-height: 1.8;

    label {
      font-size: 14px;
    }

    a {
      text-align: left;
    }
  }

  .md-off,
  md-radio-button:not([disabled]).md-checked.md-primary .md-off {
    border-color: variables.$brand-primary !important;
  }

  .form-base md-radio-group md-radio-button:not([disabled]).md-primary .md-on {
    background-color: variables.$brand-primary !important;
  }

  .login-text {
    height: 85%;

    &.signup-form-section {
      height: 466px !important;
      overflow: auto;
    }

    .form-content {
      width: 100%;
      padding-right: 9px;
    }
  }

  label {
    font-size: 16px;
  }

   md-input-container.business-name-input {
    margin: 20px 0 30px;
    width: 100%;

    &.md-input-invalid {
      margin: 10px 0 0px;
    }
    &.md-input-focused, &.md-input-has-value {
      margin: 25px 0px 15px;
    }

    &.business-name-loc {
      margin: 20px 0 0;
    }
  }
  md-input-container.street-number{
    &.md-input-focused, &.md-input-has-value {
      margin: 20px 0px 15px;
    }
  }
  .brand-ocean-blue {
    color: #52758d;
  }

  .f-16 {
    font-size: 16px;
  }

}


.signup-popup {
  max-width: 100%;
  width: 100%;
  min-height: 100%;
  height: 100%;
  margin: 0px;

  .form-base {
    width: 100%;
    height: 100%;

    .signup-parent {
      width: 100%;
    }
  }
}

@media screen and (max-width: 960px) {
  .signup-parent {
    #step4 {
      .plan-type {
        margin-bottom: 20px;
      }

      #allPlan {
        margin-bottom: 0;
      }
    }

    .login-text {
      height: 87%;
    }

    .bottom-section_cta {
      bottom: 20px;
    }

    .security_img.left-security-image {
      display: none;
    }
  }

}
@media screen and (max-width: 750px) {
  .signup-parent .finishing-section {
    width: 100%;
  }
}
@media screen and (max-width: 700px) {
  .signup-parent {
    .wizard-section {
      height: 100%;

      .section-right {
        display: none;
      }

      .subs {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }

      section {
        height: 100%;
      }

      .login-text {
        height: 80%;
        margin-bottom: 40px;
      }

      .bottom-section_cta {
        bottom: 10px;
      }

    }

    #step5 {
      .finishing-section {
        top: 75%;
        width: 100%;
      }

      .socket {
        height: 100%;
        top: 25%;
      }

      .form-content {
        height: 100%;
        position: relative;
        min-height: 300px;
      }
    }

  }
}

@media screen and (max-width: 600px) {
  .signup-parent {
    .wizard-section {
      .bottom-section_cta {
        bottom: 5px;
      }

      .inventory_radio {
        margin-bottom: 5px;
      }
    }



    #step2,
    #step3 {
      .login-text {
        height: 83%;
      }
    }

    #step4 {
      .login-text {
        height: 85%;
      }

      .plan-type {
        margin-bottom: 30px;
        display: grid;
      }

      .subPlans {
        height: 100%;
      }
    }
  }

  .location-info {
    display: grid;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 410px) {
  .signup-parent {
    .wizard-section {
      .subs {
        padding: 5px 25px;
      }

      .inventory_radio {
        margin-top: 20px;
      }

      .login-text {
        height: 78%;
        margin-bottom: 50px;
      }

      .bottom-section_cta {
        bottom: 5px;
        width: 93%;
      }

      .pricing-link {
        margin-bottom: 20px;
        align-items: flex-start;
      }

      // TODO: check responsive problem
      .plan-type {
        margin-bottom: 0;

        .type {
          margin-bottom: 15px;
        }
      }

      .auto-location-div {
        width: 100%;
      }
    }

    #step4 {
      .login-text {
        height: 82%;
      }
    }

    #step5 {
      .login-text {
        height: 69%;
      }

      .finishing-section {
        right: 9%;
      }

      .socket {
        left: 40%;
      }
    }

    .btn-primary,
    .btn-secondary {
      padding: 0 8px !important;
      justify-content: center;
    }

    .bottom-section_cta {
      bottom: 5px;
      left: 12px;
    }

    .security_img {
      height: 33px;
      margin-top: 10px;
    }

    .timezone_section {
      a {
        text-align: left;
      }
    }

    .multiple_input_section {
      flex-direction: column;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      md-input-container {
        width: 100%;
        max-width: 100%;
        margin-left: 0 !important;
      }
    }
  }
}

@media screen and (max-width: 385px) {
  .signup-parent {
    .wizard-section {
      .business-info-notes {
        margin-bottom: 12px;
      }

      .timezone_section {
        margin-bottom: 25px;
      }
    }

    #step4 {
      .login-text {
        height: 84%;
      }
    }

    #step2,
    #step3 {
      .login-text {
        height: 83%;
        margin-bottom: 40px;
      }
    }
  }
}