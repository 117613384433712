@use "../../settings/third-party/variables";

.uploadinginventory-file {
  padding: 20px;

  .inventoryimport-section {
    max-width: 700px;
    margin: 0 auto;
  }

  .media-img {
    height: 68px;
    margin-bottom: 8px;
    width: 72px;
  }

  .norecords-available {
    margin: 0 auto;
    text-align: center;
    padding: 20px;

    img {
      width: 50px;
    }

    md-radio-group {
      margin-top: 12px;
    }

  }

  .file-type {
    margin: 15px 0;
  }

  .products-materials {
    margin-top: 12px;

  }

  .product-attribute {
    padding: 20px;
  }

  .select-attribute {
    margin-top: 15px;
  }

  .regenerate-product {
    font-size: 14px;
  }

  .upload-inventoryfile {
    border: 1px dashed #D6E1E5;
    border-radius: 10px;
    background: #FAFBFC;
    position: relative;
    text-align: center;
  }

  .drag-drop {
    font-size: 14px;
    margin-top: 15px;
  }

  .import-utilities {
    font-size: variables.$font-size-medium;
    font-weight: variables.$headings-font-weight;
  }

  .setting-upload {
    position: absolute;
    right: 9px;
    top: 9px;
    height: 30px;
  }

  .upload-conent {
    margin-top: 60px;
    text-align: center;

    h1 {
      display: inline-block;

    }
  }

  .upload-img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    height: 56px;
    margin-bottom: 8px;
    width: 50px;
  }

}

// .processing-data {
//   text-align: center;
//
//   h1 {
//     font-size: 26px;
//   }
//
//   img {
//     width: 70px;
//   }
//
//   .no-records {
//
//     margin-top: 12px;
//     font-size: 13px;
//   }
//
//   .we-processing {
//     font-size: 14px;
//     padding-top: 30px;
//   }
// }
