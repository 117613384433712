@use "../../settings/third-party/variables";
@use "../../settings/style-guide";
@use "../../settings/custom";

.opportunity-building {
  md-content {
    padding: 20px;
  }

  .toolbar-sec {
    h2 {
      float: left;
      font-size: 36px;
      margin: 10px 0 0;
      font-weight: 500;
      color: variables.$gray-base;
    }

    .tools-list {
      @extend .no-style;

      display: inline-block;
      float: right;
      border-radius: 5px;
      background: variables.$white-darker;

      li {
        display: inline-block;
        padding: 8px 12px;
        text-align: center;
        vertical-align: top;
        min-width: 50px;
        height: 75px;
        min-height: 55px;
        cursor: pointer;
        position: relative;
        border-bottom: 5px solid transparent;

        .selection-counter {
          position: absolute;
          top: 6px;
          right: 14px;
          background: variables.$brand-accent;
          color: #fff !important;
          font-weight: 500;
          font-size: 10px;
          text-align: center;
          line-height: 1.9;
          border-radius: 20px;
          padding: 0 3px !important;
          min-width: 14px;
          height: 18px;
          box-sizing: content-box;
        }

        .tool-name {
          font-size: 10px;
        }

        md-icon {
          margin-bottom: 5px;
          height: 22px;
          width: 22px;

          svg path {
            fill: variables.$gray;
          }
        }

        &.active {
          border-bottom: 5px solid variables.$brand-accent;
          background: variables.$gray-darker;
          color: variables.$white;

          md-icon svg path {
            fill: white;
          }

          &:hover {
            background: variables.$gray-darker;
          }
        }

        &:hover {
          background: variables.$gray-light;

          md-icon svg path {
            fill: white;
          }
        }
      }
    }
  }

  .op-main-sec {
    position: relative;

    h3 {
      font-size: 27px;
      font-weight: 300;
    }

    .doc-image {
      float: right;
      margin-top: -60px;
      margin-left: 30px;
    }

    .text-section {
      margin-top: 20px;

      p {
        font-size: 13px;
        color: variables.$gray;
        margin-bottom: 0;
        line-height: 1.4;
        padding: 10px;
      }
    }

    .half-sec {
      max-width: 100%;
      width: calc(100% - 500px);
    }
  }

  .editable-sec {
    transition: all 0.5s ease-in-out;
    position: relative;

    img,
    p {
      border: 1px solid transparent;
    }

    &:hover {
      img,
      p {
        border: 1px dotted variables.$gray-light;
      }
    }

    .editable-part {
      position: absolute;
      right: 0;
      z-index: 10;
      top: -12px;

      md-icon {
        width: 15px;
        height: 15px;
        margin-right: 15px;
        background: variables.$white-darker;
        border-radius: 100%;
        padding: 5px;

        svg path {
          fill: variables.$gray;
        }
      }
    }
  }

  .tool-arrow {
    @extend .status-dropmenu;

    border-radius: 0;
    padding: 0;
    width: auto;
    border: 0;

    &:before {
      border-bottom-color: variables.$brand-accent;
      border-width: 11px;
      bottom: -30px;
      right: 25px;
    }

    &:after {
      display: none;
    }
  }

  .all-toolbars {
    md-toolbar {
      margin-top: 25px;
      background: variables.$white-darker;
      position: relative;
      border-top: 1px solid variables.$gray;

      .icon-close {
        position: absolute;
        top: 10px;
        right: 15px;
        width: 13px;
        height: 13px;
      }

      .tools-btn-list {
        @extend .no-style;

        padding: 0 20px 20px;

        md-input-container.md-input-has-value label:not(.md-no-float) {
          display: none;
        }

        li {
          display: inline-block;
          vertical-align: top;
          text-align: center;
          padding: 10px 4px 20px;
          margin-top: 10px;
          cursor: pointer;
          padding-bottom: 0;

          &:hover {
            background: variables.$white;
          }

          .toolname {
            @extend .f3;

            font-size: 10px;
          }

          .md-button {
            md-icon {
              max-width: 18px;
            }
          }

          &.btn-separator {
            position: relative;
            margin-right: 20px;
            padding-right: 20px;

            &:after {
              content: "";
              position: absolute;
              right: 0;
              top: 18px;
              bottom: 2px;
              border-right: 1px solid variables.$gray;
            }
          }

          .color-pick-tool {
            margin-top: 10px;

            label {
              display: inline-block;
              vertical-align: middle;
              height: 17px;
              width: 17px;
              background: variables.$brand-primary;
              margin-right: 5px;
            }

            input {
              background: #fff;
              border: 1px solid variables.$gray;
              font-size: variables.$font-size-base;
              color: variables.$gray;
              max-width: 75px;
              height: 30px;
              padding: 0 5px;
            }
          }

          .page-label {
            display: inline-block;
            border: 1px solid variables.$gray;
            color: variables.$brand-accent;
            position: relative;
            font-size: 11px;
            width: 22px;
            height: 22px;
            border-radius: 2px;
            line-height: 22px;
            margin-top: 15px;
            text-align: center;

            &:after {
              content: '\25BC';
              position: absolute;
              right: -14px;
              top: 0;
              color: variables.$gray;
            }
          }
        }
      }
    }

    .format-toolbar {
      .tools-btn-list {
        li {
          padding-top: 30px;
          position: relative;
          margin-right: 20px;
          padding-right: 0;
          text-align: left;

          &:hover {
            background: inherit;
          }

          .toolname {
            position: absolute;
            top: 18px;
            left: 0;
            right: 0;
          }

          md-slider {
            min-width: 80px;
          }

          md-input-container {
            margin-bottom: 0;
          }

          .md-icon-button {
            font-size: 16px;
            color: variables.$gray;
            margin: 0;
          }
        }
      }
    }

    .attach-quote-toolbar {
      .tools-btn-list {
        li {
          text-align: left;
          padding: 15px 12px;

          &.tool-heading {
            @extend .f1;

            font-size: variables.$font-size-large;
            margin-top: 18px;
          }

          &.btn-separator {
            margin-right: 13px;
            padding-right: 30px;
          }

          .toolname {
            @extend .f1;

            margin-top: 10px;
            font-weight: 500;
          }

          .tooldesc {
            @extend .f4;

            margin-top: 6px;
          }
        }
      }
    }

    .hyperlink-toolbar {
      .tools-btn-list {
        li {
          md-input-container {
            vertical-align: middle;
            margin-bottom: 0;
          }

          &:hover {
            background: inherit;
          }
        }
      }

      md-radio-group md-radio-button {
        margin-right: 0;
      }

      .tooldesc {
        @extend .f2;

        display: inline-block;
        font-size: 16px;
        margin-right: 10px;
      }

      .btn-secondary {
        color: variables.$gray;
      }
    }

    .dynamicdata-toolbar {
      .tools-btn-list {
        padding: 20px 10px;

        li {
          vertical-align: middle;
          padding: 10px 15px;

          &.tool-heading {
            @extend .f1;

            font-size: variables.$font-size-large;
          }

          &.choose-company {
            margin: 0;

            &:hover {
              background: transparent;
            }

            md-input-container {
              min-width: 180px;
              border: 0;
              margin: 0;

              span {
                font-size: variables.$font-size-large;
                color: variables.$gray-dark;
              }

              md-select {
                border: 0;

                md-select-value {
                  border: 0;
                }
              }
            }
          }

          .toolname {
            @extend .f3;

            font-size: 13px;
          }
        }
      }
    }
  }

  .table-of-content {
    margin-top: 20px;

    .t-content {
      display: inline-block;
      vertical-align: top;
      width: 60%;
      float: left;
    }

    .img-content {
      display: inline-block;
      vertical-align: top;
      width: 40%;
      float: right;

      img {
        max-width: 100%;
        min-width: 200px;
      }
    }

    .table-content-list {
      @extend .no-style;

      padding-right: 50px;
      padding-left: 14px;
      counter-reset: li;

      li {
        margin-bottom: 25px;
        position: relative;
        counter-increment: li;

        &:before {
          content: counter(li) ".";
          color: variables.$brand-accent;
          display: inline-block;
          width: 1em;
          margin-left: -16px;
        }

        .page-name {
          @extend .f1;

          font-size: 16px;
        }

        p {
          @extend .f4;

          font-size: variables.$font-size-small;
          margin-top: 2px;
        }

        .page-index {
          position: absolute;
          right: 3px;
          top: 3px;

          @extend .f3;

          margin-top: 0;
        }
      }
    }
  }

  .content-title {
    @extend .f1;

    font-size: variables.$font-size-large;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 30px;
  }

  .toolbox {
    @extend .no-style;

    background: variables.$white;
    margin-right: 10px;
    border: 1px solid variables.$gray;
    display: inline-block;
    vertical-align: top;
  }

  .master-template {
    margin-top: 20px;

    .content-title {
      margin: 0;
    }

    .tools-detail {
      margin-top: 15px;
      margin-bottom: 15px;
      display: table;
      background-color: variables.$white-darker;
      border: 1px solid variables.$gray;
      padding-top: 3px;

      .tools-detail-list {
        display: table-cell;

        @extend .toolbox;

        li {
          padding: 6px 5px;
          min-width: 200px;
          cursor: pointer;

          &:first-child {
            border-bottom: 1px solid variables.$gray;
            margin-bottom: 5px;
          }

          &.active {
            background: variables.$brand-accent;
          }
        }
      }

      .tools-detail-desc {
        display: table-cell;
        margin-right: 0;

        @extend .toolbox;

        img {
          max-width: 100%;
        }
      }
    }
  }
}

/*================================================
=            Edit DropDown MenuCustom            =
================================================*/
.edit-dropbtn {
  display: inline-block;
  width: 25px;
  height: 10px;
  line-height: 1;
  border-radius: 0;
  border: 0;
  position: relative;
  color: variables.$gray;
  font-size: 16px;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "\2022 \2022 \2022";
    cursor: pointer;
    display: block;
    font-size: 14px;
    padding: 5px;
    color: #cccccc;
  }
}

.edit-dropdown {
  position: absolute;
  top: 6px;
  right: 10px;
  display: inline-block;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    background: #000;
  }
}

.edit-dropdown-content {
  @extend .status-dropmenu;

  display: none;
  position: absolute;
  top: 20px;
  right: 0;
  background-color: #f9f9f9;
  min-width: 0;
  width: auto;
  border-radius: 5px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 10;

  @extend .no-style;

  > li {
    display: block;
    border: 1px solid variables.$gray-lighter;
    float: none !important;
    border-top: 0;
    text-align: center;
    padding: 7px 10px !important;
    background: variables.$white !important;

    &:hover {
      background: rgba(158, 158, 158, 0.2) !important;
    }

    &:first-child {
      border-top: 1px solid variables.$gray-lighter;
    }

    md-icon {
      width: 18px;
      height: 18px;

      svg path {
        fill: variables.$gray;
      }
    }
  }
}

.edit-dropdown-content a:hover {
  background-color: #f1f1f1;
}

.edit-dropdown:hover .edit-dropdown-content {
  display: block;
}

/*=====  End of Edit DropDown MenuCustom  ======*/

/*========================================
=            Comment Menu Css            =
========================================*/
.comment-link-btn {
  background: #a5d9f2;
  width: 25px;
  min-width: 25px;
  height: 25px;
  min-height: 25px;
  line-height: 25px;
  border-radius: 0;
  position: absolute;
  left: 10%;

  &:hover {
    background: #a5d9f2;
  }
}

.comment-menu {
  background: variables.$white-darker;
  border-radius: 0;

  .icon-close {
    position: absolute;
    top: 10px;
    right: 15px;
    width: 10px;
    height: 10px;
  }

  .comments-listsec {
    margin-bottom: 10px;

    @extend .no-style;

    padding: 15px;
    margin-top: 10px;

    .menu-btn {
      position: absolute;
      right: 10px;
      top: 0;
    }

    > li {
      padding: 10px;
      position: relative;
      margin-bottom: 10px;

      &.reciver {
        background: variables.$white;
        margin-left: 30px;
      }

      .img-holder {
        display: inline-block;
        float: left;
        width: 20%;

        md-icon {
          width: 42px;
          height: 42px;
        }
      }

      .comm-desc {
        display: inline-block;
        float: left;
        width: 80%;

        .user-name {
          color: variables.$brand-accent;
          font-size: 11px;

          > span {
            display: inline-block;
            margin-left: 10px;

            @extend .f4;

            font-size: 9px;
          }
        }

        p {
          font-size: 11px;
          color: variables.$gray-dark;
        }

        a {
          font-size: 9px;
        }
      }
    }
  }

  &:before {
    left: 18px;
    right: inherit;
    border-bottom-color: variables.$white-darker;
    border-width: 12px;
  }

  &:after {
    display: none;
  }
}

/*=====  End of Comment Menu Css  ======*/
