@use "../settings/third-party/variables";
@use "../settings/style-guide";

/*Notification Side bar Css*/
.notification-section {
  background: variables.$white;

  md-select md-select-value {
    min-height: 38px;
  }

  .notification-title {
    padding: 12px;

    h2 {
      display: inline-block;
      margin: 0 5px 0 0;
      font-weight: 500;
    }

    .notify-badge {
      position: static;
    }
  }

  // .md-select-placeholder {
  //   padding-left: 18px;
  // }

  .notification-list {
    .md-subheader {
      @extend .f3;
      background-color: variables.$white-darker;

      .md-subheader-inner {
        padding: 0 20px;
        height: 30px;
        line-height: 30px;
      }
    }

    li {
      padding: 20px 20px 20px 5px;
      border-bottom: 1px solid variables.$gray-lighter;
      font-weight: bold;

      &:hover {
        box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
      }

      md-icon {
        height: 20px;
        width: 20px;
      }

      &.read {
        background: variables.$white-dark;
        font-weight: normal;
      }

      .notify-detail {
        .notify-id {
          display: inline-block;
          width: 48%;

          span {
            display: inline-block;
          }
        }

        a {
          display: inline-block;
          margin-right: 4px;
        }
      }

      p {
        margin: 12px 0 0 24px;
      }

      // &:last-child {
      // border: 0;
      // }
    }
  }
}
