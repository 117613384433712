@use "../../settings/third-party/variables";

.submit-section {
  .PosR .delete-node {
    content: '';
    display: inline-block;
    position: absolute;
    top: 9px;
    right: 7px;
    height: 12px;
    width: 12px;
    background: url("#{variables.$image-path}/icons/set-d/ico-cross.svg") no-repeat center;
    background-size: 12px;
    cursor: pointer;
  }
}

.submit-popup {
  .currency-conv-top {
    padding: 20px;
  }

  .currency-container {
    margin-left: 0;
    background-color: #cccccc;
    margin-bottom: 20px;

    .inner-box-title {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 30px;
    }

    .info-box-label {
      font-size: 12px;
      font-weight: 300;
      margin-bottom: 10px;

      span {
        font-weight: 500;
      }
    }
  }
}
