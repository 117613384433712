@use "../../settings/third-party/variables";
@use "../../settings/style-guide";
@use "../../settings/custom";

.quotes-list {
  md-content {
    padding: 20px;
  }

  .data-table .table-btn {
    min-width: 0;
    min-height: 0;
    padding: 0 3px;
    margin: 0;
  }

  .active-quote-row {
    background-color: #f4f5f6;
  }

  .active-quote-row .quick-actions-row,
  tr:hover .quick-actions-row {
    left: 0;
  }

  .quick-actions-row {
    position: absolute;
    left: -50px;
    top: 0;
    bottom: 0;
    background: variables.$text-navy-blue;
    transition: all linear 0.3s;
    z-index: 1;
    width: 34px;
    overflow: hidden;

    .md-button {
      line-height: 20px;
      font-size: 10px;
      min-width: 24px;
    }

    md-icon {
      height: 18px;
      width: 18px;
      margin: 0;
    }

    &:hover {
      width: 250px;
    }

    .actions-divider {
      position: absolute;
      height: 100%;
      width: 1px;
      background: #394854;
      left: 35px;
    }
  }

  .order-stage:hover {
    color: variables.$brand-primary;
  }

  // overflow: auto;
  .quotes-toolbar {
    background: transparent;
    width: auto;
    padding-right: 2px;
    min-height: 0;

    // margin: 1px 2% 1px 2%;
    // margin: 15px 20px 8px;
    .md-toolbar-tools {
      height: auto;
      padding: 0;
      max-height: none;
    }
  }

  .decision-btn {
    .action-btn {
      width: 8px;

      path {
        fill: variables.$white;
      }
    }
  }

  .decision-col-width-250 {
    min-width: 250px;
    max-width: 250px;
  }

  .quoteid-symbol {
    span {
      @extend .f4;
      display: inline-block;
      vertical-align: middle;
      font-size: 11px;
    }

    md-icon {
      width: 16px;
      height: 16px;
      margin-right: 1px;

      svg {
        path {
          fill: variables.$brand-accent;
        }
      }
    }
  }

  // .table-sort {
  //   margin-right: 20px;
  // }

  .table-toolbar-icon {
    padding-top: 5px;

    md-icon {
      height: 30px;
      width: 30px;
      margin: 0;
    }
  }

  .action-name {
    word-wrap: break-word;
  }

  .flag-section {
    md-icon {
      height: 20px;
      width: 18px;

      svg path {
        fill: variables.$brand-accent;
      }
    }

    .text-danger {
      svg path {
        fill: variables.$brand-danger-dark;
      }
    }

    .text-warning {
      svg path {
        fill: variables.$brand-warning;
      }
    }
  }

  .archive-img-table {
    tbody tr:hover md-checkbox {
      margin-top: 5px;
    }
  }
}

.action-list-sidebar {
  .grey-hover {
    color: grey;
  }

  .grey-hover:hover {
    .comments {
      color: grey !important;
    }
  }
}

.toolbar-icon {
  height: 30px;
  width: 30px;
  margin-left: 28px;
}

// .svg-base {
//   svg {
//     path {
//       fill: rgb(151, 153, 155);
//     }
//   }
// }

.svg-base-white {
  svg {
    path {
      fill: variables.$white;
    }
  }
}

/*Search Animation Csss*/

.search-wrap {
  display: inline-block;
  padding: 0;
  position: relative;
  top: 10px;
}

.search-box {
  height: 36px;
  font-size: variables.$font-size-base;
  display: inline-block;
  outline: none;
  border: none;
  color: #555;
  cursor: pointer;
  padding: 3px 60px 3px 10px;
  width: 0;
  position: absolute;
  top: -4px;
  right: 116px;
  border-radius: 5px;
  background: none;
  z-index: 3;
  transition: width 0.4s cubic-bezier(0, 0.795, 0, 1);
}

.search-box:focus {
  width: 250px;
  z-index: 1;
  border: 1px solid #fff;
  background: #fff;
}

.search-button {
  display: inline-block;
  border: none;
  top: 0;
  right: 0;
  z-index: 2;
  position: relative;
  margin: 0;
  cursor: pointer;

  svg path {
    fill: #8E8E8E;
  }
}

md-icon {
  cursor: pointer;
}

// @media screen and (max-width: 768px) {
//   .quotes-list {
//     .filters-bar {
//       .inner-inputs {
//         li {
//           display: block;
//         }
//       }
//     }
//   }
//
//   .date-picker,
//   .quotes-list .filters-bar md-input-container {
//     width: 90%;
//
//     md-datepicker {
//       .md-input {
//         width: 100%;
//       }
//     }
//
//   }
//
//   .quotes-list .filters-bar .filter-text {
//     padding-left: 2px;
//   }
//
//   .quotes-list .filters-bar {
//     padding-bottom: 10px;
//   }
// }
//
// @media screen and (max-width: 790px) {
//   .quotes-list .filters-bar .inner-inputs {
//     width: 90%;
//   }
// }

@media screen and (max-width: 600px) {
  .search-box {
    right: 50px;
    top: 5px;
  }

  .search-wrap {
    top: 8px;
    right: 0;
  }

  .search-button {
    top: -2px;
  }
}

.autocomplete-custom-template li {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

/* quotes sidebar */
.action-list-sidebar {
  .action-item-accordian {
    .accordion-group,
    .panel-group {
      border-radius: 0;
    }
  }
}

.services-sidebar {
  .service-row {
    background-color: #f2f6f8;

    &.action-row {
      padding: 15px;
    }
  }

  .hex-white {
    path {
      fill: variables.$white;
    }
  }

  .action-list {
    .md-list-item-inner {
      padding: 0;
    }

    .service-row {
      height: 60px;
      padding: 5px;
    }

    .img-replacer {
      height: 35px;
      width: 35px;
      line-height: 35px;
      font-size: variables.$font-size-small;
    }

    .arrow-icon {
      height: 20px;
      width: 20px;
    }

    .action-name {
      font-size: variables.$font-size-medium;
      max-width: 300px;
    }
  }

  .service-status {
    &.disabled {
      pointer-events: none;
      color: rgb(186, 183, 183);
    }
  }

  .quotes-notify {
    min-width: 35px;
    text-align: center;
    padding: 5px 0 4px;
    font-size: 14px;
    font-weight: bold;
    background-color: variables.$text-navy-blue;
    color: #fff;
    border-radius: 2px;

  }

  // .fs-label{
  //   padding: 4px 0;
  //   width: 100px;
  //   border-radius: 15px;
  //   font-size: 12px;
  // }

  .start-add {
    background-color: variables.$gray-base;
    width: 40px;
    height: 20px;
    line-height: 2;
    text-align: center;
    font-size: 10px;
    color: variables.$white;
    border-radius: 5px;
  }

  .fill-white {
    width: 30px;
    height: 30px;
  }

  .expedited {
    border-radius: 5px;
    min-width: 70px;
    padding: 6px 18px 6px 14px;
    font-weight: 500;
    background-color: #674ea7;

    md-icon {
      height: 20px;
      width: 20px;

      svg {
        path {
          fill: variables.$white;
        }
      }
    }
  }

  .rush {
    @extend .expedited;
    background-color: #4374ba;
    min-width: 130px;
  }

  .action-completed {
    background-color: variables.$brand-success-dark;
    border-radius: 18px;
    color: white;
    padding: 4px 18px 4px 14px;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  }

  .to-complete {
    @extend .action-completed;
    background-color: variables.$white;
    color: variables.$brand-primary;
  }

  .expand-all-cta {
    border-radius: 18px;
    color: variables.$brand-primary;
    padding: 4px 18px 4px 14px;
    border: 1px solid variables.$brand-primary;
  }

  .expand-all-cta:hover {
    background-color: #dbd4cb;
  }

  .accordion-img {
    position: absolute;
    top: -3px;
    left: -10px;
  }

  .delete-default {
    background-color: rgb(196 197 203);
  }

  .more-option {
    @extend .action-completed;
    padding: 6px 15px;
    background-color: variables.$brand-primary;
  }

  .action-items-add {
    min-width: 160px;
  }

  .focus-icons {
    width: 18px;
    height: 18px;
    transition: transform 0.3s ease;
  }

  .icon-border:hover {
    border: 2px solid variables.$brand-primary;
  }

  .action-items-add:hover {
    .focus-label {
      color: variables.$brand-primary;
    }

    .icon-border {
      border: 2px solid variables.$brand-primary;
    }

    .focus-icons {
      path {
        fill: variables.$brand-primary;
      }
    }
  }

  .expand-select:hover {
    color: #5cc5c3;

    .expand-select-icon path {
      fill: #5cc5c3;
    }
  }

  .teams-function {
    background-color: #5cc5c3;
  }

  .action-completed:hover,
  .to-complete:hover {
    transform: scale(1.0);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    opacity: 0.9;
  }

  .more-option:hover {
    background-color: #c47618;
  }

  .due-date:hover {
    color: variables.$brand-primary;
  }

  .click-effect {
    opacity: 1;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: background-color 0.5s ease-out, color 0.5s ease-out, transform 0.5s ease-out, opacity 0.5s ease-out, box-shadow 0.5s ease-out;
  }

  .download-icon {
    height: 18px;
    width: 18px;
  }

  .job-action {
    border: 3px solid #cc0000;
  }

  .quote-action {
    border: 3px solid #674ea7;
  }

  .quote-action-item {
    background-color: #674ea7;
  }

  .customer-action-item {
    background-color: #4ec41c;
  }

  .job-action-item {
    background-color: #cc0000;
  }

  .resource-action-tem {
    background-color: variables.$brand-accent;
  }

  .action-items-list {
    width: 100%;
    // min-height: 48px;
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: background-color 0.5s ease-out, height 0.5s ease-out, opacity 0.5s ease-out;

    &.open-lists {
      height: auto;
      opacity: 1;
      transition: background-color 0.8s ease-out, height 0.8s ease-out, opacity 0.8s ease-out;
    }
  }

  .action-list-outline {
    border-width: 1px;
    border-style: solid;
  }

  .label-rectangle {
    display: flex;
    height: 15px;
    width: 40px;
  }

  .cust-action {
    border: 3px solid rgb(35, 158, 35);
  }

  .res-action {
    border: 3px solid variables.$brand-accent;
  }

  .new-action-icon {
    position: absolute;
    right: 40px;
    top: 10px;
  }

  /* Main Priority Container */
  .priority-container {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .priority-item {
    display: flex;
    align-items: center;
    padding: 5px 10px;
  }

  .action-full-screen {
    padding: 20px 0;
  }

  .download-all {
    &.disabled {
      pointer-events: none;
      filter: grayscale(1);
    }
  }

  .acc-bg {
    .panel-title {
      background-color: #20394c;
    }
  }

  .disable-option {
    pointer-events: none;
  }

  .addAction-header {
    background-color: #20394c;
    color: white;
    padding: 6px 25px;
  }

  .hexa-icon {
    position: absolute;

    .ico-table {
      position: relative;
      right: 55px;
      width: 60px;
      height: 40px;
      bottom: 25px;
    }
  }

  .product-img-list {
    .product-box {
      @extend .product-img-box;
      height: 120px;
      width: 120px;
    }
  }

  .action-line {
    border: 2px solid #20394c;
  }

  .custom-input-box {
    border: 1px solid #ccc;
    height: 40px;
  }

  .drag-drop-section {
    background-color: #f2f6f8;
  }

  .inside-organize {
    background-color: variables.$white;
  }

  .add-customer-ns {
    .type-box {
      border: 1px solid variables.$brand-primary;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
  }

  .service-details {
    background-color: variables.$white;

    .cycle-chip {
      border: 1px solid variables.$brand-accent;
      border-radius: 25px;
      min-width: 75px;
      line-height: 1.4;
      color: variables.$brand-accent;
      text-align: center;
    }

    .master-chip {
      @extend .cycle-chip;
      background-color: variables.$brand-accent;
      color: variables.$white;
    }
  }

  .tot-service {
    background-color: #20394c;
  }

  .quote-id {
    font-size: variables.$font-size-large;
    font-weight: 600;
  }

  // .bold-text {
  //   font-size: $font-size-small-plus + 3;
  //   font-weight: 700;
  // }
  // .custom-chips {
  //   display: flex;
  //   flex-wrap: wrap;
  //   list-style: none;
  //   padding: 0;
  //   margin: 0;
  // }

  .service-add {
    svg {
      path {
        fill: variables.$brand-primary;
      }
    }
  }

  .rs-row {
    font-size: variables.$font-size-small;
    font-weight: 400;
    color: variables.$gray-base;
  }

  .accordion-column {
    .panel-group {
      display: flex;
    }

    .accordion-column1,
    .flex {
      width: 70%;
      flex: 1;
    }

    .accordion-column2 {
      width: 30%;
    }
  }

  .remove-cursor {
    pointer-events: none;
  }

  .action-menu {
    background-color: #e9f0f3;

    .action-flag {
      svg {
        path {
          fill: variables.$brand-accent;
        }
      }
    }

    .action-title {
      color: #06b7f8;
    }

    .flag-img {
      height: 22px;
      width: 22px;
    }

    .arrow-icon {
      svg {
        path {
          fill: variables.$brand-primary;
        }
      }
    }

  }

  .action-edit-side {
    width: 43vw;
    max-width: 43vw;
  }
  @media (max-width: 720px) {
    .action-edit-side {
      width: 70vw;
      max-width: 70vw;
    }
  }

  @media (max-width: 500px) {
    .action-edit-side {
      width: 90vw;
      max-width: 90vw;
    }
  }
}

.quote-action-list-sidenav {
  .action-label {
    width: 110px;
    margin-right: 5px;
    font-size: 13px;
  }
}

.custom-date-popup {
  .close-icon {
    height: 20px;
    width: 30px;
  }

  .date-options {
    background-color: #dce0e2;
  }
  // .date-options .md-list-item .button-class:hover { /* Hover state for the button */
  //   background-color: rgba(255, 255, 255, 0.8);
  //   // color: black;
  // }
  .due {
    font-weight: bold;
  }

  .drag-drop-section {
    background-color: #f2f6f8;
  }

  .remove-cursor {
    pointer-events: none;
  }
  // .hover-date-option:hover{
  //   .option{
  //     color: $brand-primary;
  //   }
  // }
}
@media (max-width: 1050px) {
  .action-full-screen {
    .accordion-container {
      flex-direction: column;
    }

    .panel-group {
      display: flex;
    }

    .accordion-column1,
    .accordion-column2,
    .flex {
      width: 100%;
      flex: 1;
    }
  }

  .action-edit-side {
    width: 60vw;
    max-width: 60vw;
  }
}
