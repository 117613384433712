@use "../../settings/third-party/variables";
@use "../../settings/style-guide";

.publish-inventory-section {
  padding: 20px;

  .purchase-order-div {
    .status-viewer {
      text-align: left;
      margin-top: 15px;
    }
    background-color: variables.$brand-table;
    padding: 15px 0 0;
    margin-top: 25px;

    .label {
      font-weight: 400;
      line-height: 30px;
    }

    .self-info {
      margin: 0 20px;
    }
  }

  .data-table {
    min-width: 100%;

    tbody {
      background-color: variables.$white;
    }

    .location-symbol {
      margin-bottom: 5px;
    }

    // .expanding-icon {
    // 	background: $white-dark;
    // 	padding: 4px;
    // 	position: absolute;
    // 	top: -10px;
    // 	left: 50%;
    // 	transform: translate(-50%, 0);
    // 	-webkit-transform: translate(-50%, 0);
    // 	-moz-transform: translate(-50%, 0);
    //     height: 11px;
    //     width: 11px;
    //
    // 	svg path {
    // 		fill: $brand-primary;
    // 	}
    // }

    td {
      @extend .f3;

      // &.highlight {
      // 	background: $white-dark;
      // }
    }

    md-checkbox .md-container {
      background: variables.$white;
    }

    .small-txt {
      color: variables.$gray;
      font-size: 10px;
    }
  }

  .item-info {
    thead {
      background-color: variables.$white;

      th {
        border-bottom: 1px solid variables.$brand-table;
      }
    }
  }

}

.procurement-payment-status-popup {
  .stat-desc {
    padding: 5px 15px;
    margin: 0;

  }

  .stat-info {
    padding: 15px 100px 15px 5px;
  }
}

@media screen and (max-width: 1024px) {
  .publish-inventory-section {
    .table-responsive {
      max-width: 800px;
    }
  }
}

@media screen and (min-width: 600px) {
  .publish-inventory-section {
    .top-section {
      md-input-container {
        margin-right: 20px;
      }
    }
  }
}
