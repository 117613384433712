@use "../settings/third-party/variables";

.get-support {
  padding: 20px 20px 0;

  .online-resources {
    .resource-container {
      @media screen and (min-width: 600px) {
        padding-right: 20px;
      }

      @media screen and (min-width: 960px) {
        &:nth-child(3n) {
          padding: 0;
        }
      }

      @media screen and (max-width: 960px) {
        &:nth-child(even) {
          padding: 0;
        }
      }
    }

    .online-resource {
      margin: 0 0 20px;
      padding: 10px;

      .space-bottom {
        margin-bottom: 10px;
      }

      .resource-img {
        height: auto;
        width: 80px;
      }

      .image-title {
        font-size: 16px;
        font-weight: 500;
      }

      .btn-action {
        background: variables.$brand-accent;
        color: variables.$white;
      }
    }
  }

  .request-support {
    .support-image {
      width: 20px;
      margin-right: 18px;
    }
  }
}
