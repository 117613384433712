@use "../settings/third-party/variables";

.custom-role {
  padding: 20px;

  .role-name {
    font-size: variables.$font-size-base;
  }

  .ui-accordion section {
    padding: 20px;
  }

  .access-rbtns md-radio-button {
    margin-right: 0;
  }

  @media screen and (max-width: 959px) {
    .upgrade-now {
      margin-bottom: 15px;
    }
  }

  // @media screen and (max-width: 599px) {
  //   .license {
  //     margin-top: 5px;
  //   }
  // }
}
