@use "../../settings/third-party/variables";

.campaign_control {
  .section1 {
    .package_offer_section {
      md-icon path {
        fill: black;
      }
    }

    .first_cell {
      display: flex;

      .description {
        margin-left: 0;

        h1 {
          margin-top: 0;
          color: #20394c;
        }
      }

      .task_number_status {
        margin-left: 0;
        font-size: 10px;
      }
    }

    .top-right-section {
      label {
        .btn-primary {
          background-color: #008001;
          color: variables.$white;
          padding: 7px 25px;
        }
      }
    }

    .second_cell {
      display: grid;
      justify-content: end;

      .cta_div {
        display: flex;
        padding: 8px 20px;
        background-color: #63d1ddff;
        max-width: 180px;
        width: 100%;

        .edit_cta {
          display: grid;

          a {
            color: variables.$white;
          }
        }

        md-icon {
          svg {
            path {
              fill: variables.$white;
            }
          }
        }
      }

      .task_number_status {
        margin-left: 0;
        margin-bottom: 0;
        font-size: 11px;
      }
    }

    .opp-detail-list {
      padding-top: 0;
      margin-top: 65px;

      h1 {
        color: #20394c;
      }
    }

    .customer_segment {
      font-size: 18px;
    }

    .custom-default-box {
      margin-left: 55px;

      .set-up {
        width: 100%;

        .date_div {
          display: grid;
        }

        .summery-widget {
          background-color: transparent;
          padding: 0;
          border-radius: 20px;

          .card_div {
            padding: 25px 35px;
          }

          .card_cta.footer_section {
            background-color: #20394c;
            margin: 0;
            padding: 10px;
            background-color: #20394c;
            margin: 0;
            padding: 10px;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;

            h3 {
              color: variables.$white;
            }
          }
        }
      }

    }

  }

  .step_indicator_div {
    padding: 10px 25px;
    height: auto;
    background-color: #21374cff;

    .section-heading {
      h1 {
        color: variables.$white;
      }

      md-icon path {
        fill: variables.$white;
      }
    }

    .step_indicator {
      width: 100%;

      md-divider {
        min-width: 210px;
        max-width: 210px;
        width: 100%;
        background-color: white;
        margin-right: 15px;
      }

      span {
        color: variables.$white;
        font-size: 16px;
      }
    }

    .percent_counter {
      background: #5cc4c3;
      border: 2px solid #5cc4c3;
      font-size: 20px;
      font-weight: normal;
      margin-left: 8px;
    }
  }

  .type-box {
    -moz-border-radius: 7px;
    -webkit-border-radius: 7px;
    border-radius: 7px;
    background-clip: padding-box;
    padding: 12px 16px 0;
    display: flex;
    // color: #6a6a6a;
    // width: 100%;
    // cursor: pointer;
    min-width: 200px;
    max-width: 200px;
    border: 1px solid variables.$brand-primary;
    background: transparent;
    cursor: pointer;

    &:not(:last-of-type) {
      margin-right: 115px !important;
    }

    &.disabled {
      pointer-events: none;
      filter: grayscale(1);
    }

    .control_value_percent {
      display: grid;

      label {
        padding: 0;
      }

      .first_content {
        color: variables.$white;
        margin-bottom: 5px;
      }

      .second_content {
        position: relative;
        bottom: 7px;
        color: variables.$brand-primary;
        margin-bottom: 0;
        font-size: 18px;
      }
    }

    img.icon_png_img {
      width: 20px !important;
      height: 20px;
      position: inherit;
      left: 0;
    }
  }
}

@media (max-width: 1200px) {
  .campaign_control {
    .control_type_card_section {
      width: 100%;

      .type_box_card_div {
        margin-left: 31px !important;
      }
    }
  }
}

@media (max-width:960px) {
  .section1 {
    margin: 0;

    .custom-default-box {
      margin-left: 8px;
    }

    .set-up {
      .segment-desc-sec {
        margin-bottom: 45px;
      }

      .summery-widget {
        min-width: 300px;
        max-width: 300px;
        margin: 0 auto 0 0;
        box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px 2px rgba(0,0,0,.12), 0 2px 1px 1px rgba(0,0,0,.12);
      }
    }
  }

  .step_indicator {
    md-divider {
      width: 100%;
      background-color: white;
      margin: 30px 0;
    }
  }

  .type_box_card_section.control_type_card_section {
    .type_box_card_div {
      width: 100%!important;
      display: grid!important;
      gap: 20px;
    }
  }

  .opp-detail-list {
    md-list-item.steps_list {
      margin-bottom: 20px;
    }
  }
}
@media (max-width:600px) {
  .campaign_control {
    .section1 {
      .set-up {
        .summery-widget {
          min-width: 260px;
          max-width: 260px;
        }
      }

      .top-right-section {
        margin-top: 0;

      }

      .opp-detail-list {
        margin-top: 0;
      }

      .custom-default-box {
        margin-left: 0;
        padding: 0 35px;
      }

      .first_cell {
        .description {
          h1 {
            font-size: 16px;
          }
        }
      }

      .second_cell {
        display: grid;
        justify-content: start;
        margin-left: 45px;
        margin-top: 10px;
      }
    }

    .step_indicator_div {
      padding: 25px;

      .percent_counter {
        width: 35px;
        height: 35px;
      }
    }

    .opp-detail-list {
      md-list-item.steps_list {
        margin-bottom: 0;

        .label-key {
          margin-bottom: 15px;
        }
      }
    }
  }
}
