@use "third-party/variables";

// sidenav in app starts

// sidenav with no parent frame eg. calendar
.frame-sidenav-container .md-sidenav-right.inside-container {
  bottom: 65px;
  top: 0;
  // z-index: 1000;
}

// sidenav with parent frame eg. business
ui-view {
  position: relative;

  .md-sidenav-right.inside-container {
    top: -64px;
    bottom: 0;
  }
}

// sidenav in dialog
md-dialog .md-sidenav-right.inside-container {
  top: 64px;
  bottom: 0;
}

.sidenav-wrapper md-backdrop {
  top: -100000px;
  left: -100000px;
  z-index: 60;
}

.sidenav-close {
  line-height: 0;
}

md-sidenav md-icon.search-symbol path {
  fill: #97999b;
}
// sidenav in app ends

// Toolbar overlay on default one
md-toolbar.top {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
}

.quick-panel-opened md-toolbar.top {
  width: auto;
  right: 62px;
}

// Blur effect for tables
.blur-effect {
  opacity: 0.3;
  transition: all linear 0.2s;
}

// Search symbol active color in sidebars
.search-symbol.active path {
  fill: variables.$brand-accent !important;
}

/*ckeditor css start*/
// Smileys aligned middle
.cke_editable img {
  vertical-align: middle;
}

// Hide Toolbar buttons in full screen for ckeditor
md-toolbar {
  z-index: auto;
}
/*ckeditor css end*/

// full width date picker
.md-block .md-datepicker-input {
  max-width: 100%;
}

.md-button.md-fab.scroll-btn {
  opacity: 0.6;
  height: 36px;
  width: 36px;
  z-index: 100;
  position: absolute;
  transition: all 0.2s;
  -webkit-transition: -webkit-all 0.2s;
  transition: -webkit-all 0.2s;
  transition: all 0.2s, -webkit-all 0.2s;

  &.scroll-left,
  &.scroll-right {
    z-index: 1;
  }

  &:hover {
    opacity: 1;
  }

  md-icon {
    height: 15px;
    width: 15px;

    path {
      fill: variables.$white;
    }
  }
}

// scroll to top css starts
.md-button.md-fab.scroll-top {
  transform: scale(0);
  -webkit-transform: scale(0);
  // bottom: 80px;
  top: -125px;
  right: 5px;

  &.chat-hidden {
    top: -65px;
  }

  &.active {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  // &.sidenav {
  //   bottom: 130px;
  // }

  md-icon {
    transform: rotate(-90deg);
  }
}

// @media (max-width: 768px) {
//   .md-button.md-fab.scroll-top {
//     // bottom: 55px;
//
//     &.sidenav {
//       bottom: 110px;
//     }
//   }
// }
// scroll to top css ends

// Error Fields css starts
.panel-heading .error {
  top: 4px;
  right: -20px;
}

.accordion-name {
  padding-right: 20px;
  font-weight: 600;
}

.md-tab span {
  vertical-align: middle;
  font-weight: bold;
}

md-icon.error {
  height: 12px;
  width: 12px;
  position: absolute;
  right: 4px;
  top: 7px;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  opacity: 0;

  path {
    fill: #dd2c00 !important;
  }

  &.is-error {
    opacity: 1;
  }
}

// Error Fields css ends

.map-redirect-icon {
  height: 18px;
  width: 18px;
}

// highlight spam word
.spam-word {
  color: #ad0505;
  background: #ffa2a2;
}

.quote-frame .ar-sidepanel {
  .assign-type {
    margin: 3px 15px;
  }

  .task-wrapper {
    position: absolute;
    top: 115px;
    bottom: 50px;
    overflow: auto;
    left: 0;
    right: 0;
    overflow: hidden;
  }

  .contact-title {
    text-align: right;
    padding: 10px 16px 5px;
  }

  .select-wrapper {
    margin-top: 3px;
    margin-bottom: 2px;
  }

  .sidenav-select {
    margin: 0;
    width: 95%;
  }

  .sidenav-title .search-box {
    top: 10px;
  }
}

.td-min-width-140 {
  min-width: 140px;
}

.td-min-width-185 {
  min-width: 185px;
}

.td-min-width-200 {
  min-width: 200px;
}

.td-min-width-300 {
  min-width: 300px;
}

.list-view {
  .td-min-width-170 {
    min-width: 170px;
  }

  .td-min-width-120 {
    min-width: 120px;
  }

  .td-min-width-100 {
    min-width: 100px;
  }
}

// Hide increase decrease button that comes for number fields in some browsers
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

// Quill editor css
ng-quill-editor {
  &[disabled] {
    .ql-toolbar {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &.ng-touched.ng-invalid {
    .ql-container,
    .ql-toolbar {
      &.ql-snow {
        border-color: variables.$brand-danger;
      }
    }
  }
}

.ql-container {
  &.ql-disabled {
    opacity: 0.5;
  }

  .ql-editor {
    min-height: 200px;
    max-height: 400px;
  }
}

// Quill editor display errors
md-input-container {
  &:not(.md-input-invalid) {
    ng-quill-editor + .md-auto-hide {
      .md-input-message-animation {
        opacity: inherit;
        margin-top: inherit;
      }
    }
  }
}

.ql-snow .ql-picker {
  &.ql-header {
    .ql-picker-item,
    .ql-picker-label {
      &:not([data-value])::before {
        content: 'Paragraph';
      }
    }
  }

  &.ql-size {
    .ql-picker-item,
    .ql-picker-label {
      &[data-value]::before {
        content: attr(data-value);
      }
    }
  }
}

[ng-bind-html],
[ng-html-compile] {
  img {
    max-width: 100%;
  }
}

// timezone css start
.timezone-panel {
  padding: 0 15px;
}

.gmt-timezone {
  margin-bottom: 10px;
}

.configure-timezone {
  margin-bottom: 15px;
}

.gmt-timezone-cal {
  background: variables.$white;
  line-height: 1.5;
  margin-top: 20px;
  padding: 10px;
}

// image marker js
md-dialog.image-marker {
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;

  .image-view {
    z-index: -1;

    .center-image {
      top: 20px;
      height: calc(100% - 40px);
      width: 100%;
    }
  }
}

// facebook like content holder infinite loader css
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

// Quill js editor emoji bg img override
.ap {
  background-image: url("https://github.com/contentco/quill-emoji/raw/master/dist/1e7b63404cd2fb8e6525b2fd4ee4d286.png") !important;
}
