@use "../../settings/third-party/mixins";
@use "../../settings/third-party/variables";

.dialog-popover {
  min-height: 500px;
  width: 100%;

  .right-space {
    margin-right: 15px;
  }

  &.custom-size {
    max-width: 902px;
  }

  &:not(.custom-size) {
    max-width: 650px;
  }

  .estimate-templates {
    .estimate-details-header {
      h1 {
        font-size: 25px;
      }

      img.logo {
        height: 50px;
        padding: 8px;
      }
    }

    .go-to-estimate {
      md-icon svg path {
        fill: #f8b289;
      }

      .edit {
        height: 60px;
        width: 60px;

        margin-bottom: 30px;
        margin-top: 20px;



      }


      .estimate-detail-footerE {
        background-color: white;
        padding: 3px 10px;
        color: #2b3e50;
        font-size: 10px;
        border: 2px solid variables.$gray-light;
        border-top-width: 0;
      }

      .add-icon-estimate {
        height: 30px;
        width: 30px;

      }

      .btn-primary {
        border: 1px solid #b7b7b7;
        background-color: transparent;
        color: #fb9e54;
      }

      .grid-product {
        background-color: #20394cff;
        border: none;
      }
    }

    .estimate-detail {
      width: 100%;
      max-width: 295px;
      padding: 15px;

      .estimate-detail-background {
        background-color: #f2fffe;

        h1 {
          overflow: hidden;
          word-break: break-all;
        }
      }

      .margin-left {
        margin-left: 10px;
      }

      .grid-product {
        padding: 10px;
        border: 2px solid variables.$gray-light;
        // border-radius: 3px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        position: relative;
        height: 240px;
        width: 100%;

        &.disabled {
          border: 2px solid variables.$table-border-color;
        }

        &.active {
          border-color: variables.$brand-primary;
        }

        .price-detail {
          font-size: 18px;
        }

        .status-gap {
          margin: 10px 0;
        }

        .overlay {
          border-radius: 3px;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          color: variables.$white;
          left: 0;
          background: variables.$gray-dark;
          opacity: 0.9;

          .action-btns {
            .remove-template {
              background: variables.$gray-light;
            }

            p {
              padding: 8px;
            }
          }
        }
      }

      .estimate-elipses {
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;

        display: -webkit-box;
      }



      .estimate-details-notify {
        display: inline-block;
        font-size: 10px;
        padding: 4px 10px;
        border-radius: 20px;

        &.usable {
          background-color: #eaf0d6;
        }

        &.unusable {
          background-color: #fae1dc;
        }
      }

      .estimate-detail-footer {
        background: variables.$brand-accent-darker;
        padding: 3px 10px;
        font-size: 10px;
        border: 2px solid variables.$gray-light;
        border-top-width: 0;
      }
    }
  }

  .add-template {
    display: inherit;
  }

  md-input-container {
    &.title-inputbox textarea {
      height: 40px !important;
    }
  }

  &.unarchive-confirm-section {
    md-dialog-content {
      padding: 20px;
    }

    .date-picker {
      margin-bottom: 24px;
      width: 60%;

      md-datepicker {
        position: relative;
        padding-right: 0;
      }

      .md-datepicker-input-container {
        margin-left: 0;
        max-height: 36px;
        width: 100%;

        &,
        &:focus {
          border-bottom: 1px solid variables.$white-dark;
        }
      }

      .md-datepicker-button.md-icon-button {
        position: absolute;
        top: -6px;
        right: 2px;
        margin: 0;
        padding: 0;
        height: 24px;
        min-height: 24px;
        width: 24px;
        background: variables.$white;
        z-index: 1;
      }

      .md-datepicker-triangle-button {
        display: none;
      }
    }
  }

  .main-header {

    &,
    .md-toolbar-tools {
      min-height: 72px;
      height: 72px;
    }

    .md-toolbar-tools {
      padding-left: 4px;
      padding-right: 18px;
    }
  }

  .close-popup {
    margin-left: 10px !important;

    * {
      color: variables.$white;
      fill: currentColor;
    }

    md-icon {
      height: 16px !important;
      width: 16px !important;
    }
  }

  h2 {
    font-size: 16px;
    font-weight: normal;
  }

  .question {
    font-size: variables.$font-size-medium;
    font-weight: 500;
  }

  .radio-grp {
    padding: 20px;
  }

  .format-editor {
    margin: 0 5px;
  }

  .field-label {
    margin-bottom: 29px;
  }

  .field-caption {
    margin-bottom: 15px;
    margin-left: 12px;
  }

  .popup-start-field {
    margin-top: 30px;
  }

  .total-container {
    background-color: rgba(variables.$brand-accent, .05);
    padding: 7px;
    min-height: 80px;
    box-sizing: content-box;
    // margin-bottom: 20px;
    @include mixins.border-radius(4px);

    .inner-box {
      margin-left: 40px;
    }

    .inner-box-peak {
      margin-left: 20px;
    }

    .total-cost {
      color: variables.$brand-accent;
      font-size: 18px;
      font-weight: 700;
      // span {
      //   font-weight: 400;
      //   color: rgba($brand-accent,.7);
      // }
      // .tax-value {
      //   color: #949494;
      //   font-size: 14px;
      // }
    }
  }

  .saprator-vert {
    border: 1px solid rgba(51, 51, 51, 0.1);
    min-height: 80px;
    margin: 0 5px;
    display: inline-block;
  }
}

.simple_estimate {
  margin-top: 25px;
}

.component {
  margin-left: 15px !important;
}

.percinput {
  border: 1px inset #ccc;
}

.percinput input {
  border: 0;
}

@media screen and (max-width: 600px) {
  .dialog-popover {
    .right-space {
      margin-right: 0;
    }

    md-switch.md-block {
      margin-bottom: 0;
    }

    .saprator-vert {
      display: none;
    }

    .get-up-margin {
      margin-bottom: 95px;
    }

    &.simple {
      .component {
        margin-left: 0 !important;
      }
    }
  }
}

// cover letter popoup

.cover-letter {
  height: 540px;
  width: 800px;

  .file-download {
    color: variables.$brand-primary;
  }
}

// Estimate popup

.overview-estimate {
  .cancel-btn {
    background: variables.$white-dark;
  }
}

.estimates-resources-popup {
  max-height: 100%;
  max-width: 100%;

  .popup-sidenav {
    background: #52758D;
    width: 230px;
  }

  .resources-popup-sidenav-btn {
    background-color: #fff;
    border: 1px solid variables.$brand-warning;
    border-radius: 5px;
    color: variables.$brand-warning;
    line-height: 20px;

    &:hover {
      background-color: variables.$brand-warning;
      color: #fff;
    }
  }

  .popup-footer {
    border: 2px dashed #d6e1e5;
    max-width: 500px;
    min-width: 200px;
  }

  .dialog-popover {
    min-height: 100%;
    max-width: 100%;
  }

  .data-table {
    border-top: 10px solid #21374C;
    td {
      border-bottom: 0px;
    }
  }

  .select-resource-btn:hover {
    background-color: rgba(0, 0, 0, 0);
  }

  .icon-sidenav {
    width: 35px;
    height: 35px;
  }

  .sidenav-header.f6 {
    font-size: 18px;
  }

  .form-base {
    md-radio-group md-radio-button, md-input-container label, input {
      font-size: 14px !important;
    }
  }
}