@use "../settings/third-party/variables";

.customer-card {
  // padding: 16px;

  .customer-contact {
    .customer-name {
      font-weight: 500;
      font-size: 18px;
      margin: 0 0 8px;
    }
  }

  .customer-contact-info {
    .contact-line {
      padding: 7px 0;

      md-icon {
        height: 20px;
        width: 20px;
        margin: 0 15px;
      }
    }
  }

  .notify {
    border-radius: 5px;
  }
}

.cta-row {
  background-color: variables.$white;

  &.selected {
    background-color: variables.$brand-info-light;
  }

  &:hover {
    &:not(.selected) {
      background-color: #f2f2f2;
    }

    h3 {
      color: variables.$text-orange;
    }
  }

  .contact-op {
    height: 35px;
    width: 35px;
  }

  .row-ele {
    padding: 16px;
  }

  .arrow-icon {
    width: 15px;
    height: 15px;

    svg {
      path {
        fill: variables.$brand-primary;
      }
    }
  }

  .plus-icon {
    @extend .arrow-icon;
    width: 23px;
    height: 23px;
  }

  .insta {
    width: 35px;
    height: 35px;
  }

  .cta-header {
    font-weight: 600;
    font-size: 15px;
    margin: 20px 0 5px;
  }

  .notify {
    border-radius: 2px;

  }

  .align-icon {
    line-height: 1;
  }
}

.set-time {
  .circle-cross {
    height: 20px;
    width: 20px;

    svg {
      path {
        fill: variables.$gray-dark;
      }
    }
  }
}

.action-item-content {
  .star-icon {
    height: 18px;
    width: 18px;
  }

  .aic-row {
    margin: 15px 0 !important;
  }

  md-switch.md-block {
    height: 18px;
  }

  .campaign-chips {
    padding: 4px 4px 4px 14px;
    line-height: 16px;
  }
}

.add-associate {
  .associate-row {
    text-indent: 35px;
  }

  .relation {
    margin: 0 0 20px 9px !important;
  }

  .country-code {
    bottom: 10px;
  }
}

.priority {
  .priority-row {
    font-size: variables.$font-size-large;
    font-weight: 500;
  }

  .light-grey-star {
    svg {
      path {
        fill: variables.$gray-lighter;
      }
    }
  }

  .grey-star {
    svg {
      path {
        fill: variables.$gray-dark;
      }
    }
  }

  .light-red-star {
    svg {
      path {
        fill: rgb(196, 51, 51);
      }
    }
  }

  .red-star {
    svg {
      path {
        fill: rgb(245, 39, 39);
      }
    }
  }
}

.cai-row {
  .create-action {
    margin: 10px 15px 10px 8px;

    .ac-item {
      font-size: variables.$font-size-small - 2;
    }
  }
}

.cai {
  .cta-header {
    font-weight: 600;
  }

  .cai-text {
    font-size: variables.$font-size-small + 1;
  }

  .cai-icon {
    height: 20px;
    width: 20px;
  }
}
