@use '../settings/third-party/variables';

.time-entry {
  .user-clock-management {
    .btn-secondary {
      padding: 2px 25px;
      font-weight: bold;
      color: #333;
      margin: 0 20px 0 0;
    }

    .custom-button {
      padding: 2px 25px;
      font-weight: bold;
      color: #333;
      background-color: #a7cf9d;
    }

    .stats-list.first-list {
      margin-right: 20px;
    }

    .stats-section {
      padding: 20px;
      background: #ecf3f9ff;
      // border: 1px solid #79a9bb;

      li {
        background-color: #fff;
        padding: 8px 10px;
        margin: 5px 10px 5px 0;
        min-width: 86px;
        text-align: center;
        display: inline-block;

        &.last-stat {
          margin-right: 0;
        }
      }

      h3 {
        margin: 0 0 10px;
      }

      p {
        margin: 0;
      }

    }

    .travel-summary {
      padding: 0 0 20px 30px;
      // position: relative;
      // border-left: 2px solid white;

      // &:not(.stop) {
      //   border-color: $brand-primary;
      // }

      label {
        position: initial;
      }

      // .circle {
      //   background-color: #93c47d;
      //   width: 10px;
      //   height: 10px;
      //   position: absolute;
      //   top: 0;
      //   border-radius: 50%;
      //   left: -6px;
      // }
    }

    // .current.second-last {
    //   border-left: 2px dashed $brand-accent;
    // }

    // .current .circle {
    //   background-color: #FFAB19;
    // }

    .sms-conversation {
      border: 1px solid #cccccc;
      position: relative;
      padding: 5px;
      /* width: 100%;
      max-width: 500px; */

      &::before {
        top: 11px;
        left: -16px;
        border: 8px solid;
        border-color: transparent #dbdbdb transparent transparent;
        content: "";
        position: absolute;
      }

    }

    .address-status {
      background: green;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      position: absolute;
      left: 6px;

      &::before {
        content: "";
        border-left: 1px solid #5CC5C3;
        height: 100%;
        position: absolute;
        padding-left: 15px;
      }
    }

    .my-calendar {
      border: 1px solid #cccccc;
    }

    .time-row {
      border: 1px solid #cccccc;
    }

    .address-detail {
      padding-left: 30px;
    }

  }

  .clock-in-card {
    // padding-right: 5px;
    border-radius: 5px;
    border: 1px solid #d0e4f2;
    transition: box-shadow 1s, border-color 1s;
    overflow: hidden;
  }

  .card-section {
    display: flex;
    justify-content: space-around;
  }

  .stop-icon {
    md-icon {
      margin-right: 10px !important;
    }
  }

  .service-details {
    display: flex;

    md-icon {
      margin: auto 0;
    }

    h3 {
      margin: 0;
    }

    // .resource-info {
    //   margin-left: 20px;
    // }
  }

  .summary-tab {
    .title {
      width: 100%;
      text-align: center;
      background: #333;
      color: #fff;
      margin-top: 0;
      padding: 10px;
      font-weight: normal;
    }
  }

  .campaign-detail {
    overflow: auto;
    display: block;
    width: 100%;

    .data-box {
      min-width: 120px;

      &:not(:last-of-type) {
        margin-right: 15px;
      }
    }

    .md-padding {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      width: 100%;
    }
  }

  .regular {
    background: #93c47dff;
    text-align: center;
    padding: 12px 10px;
    // margin-right: 15px;
    font-size: 20px !important;
    color: #fff !important;
  }

  .overtime {
    @extend .regular;
    background: #e06666ff;
  }

  .break {
    @extend .regular;
    background: #999999ff;
  }

  .clock-in-card {
    h1 {
      font-weight: normal;
      font-size: 18px;
    }
  }

  .clock-list {
    .inner-wrapper {
      padding: 10px;
      margin-left: 15px;
      border-radius: 3px;
      position: relative;
      background-color: #f9f9f9;
      border: 1px solid #dbdbdb;

      &::before {
        top: 11px;
        left: -16px;
        border: 8px solid;
        border-color: transparent #dbdbdb transparent transparent;
        width: 0;
        height: 0;
        content: "";
        position: absolute;
      }
    }

    .post-title {

      font-size: 16px;
      margin-bottom: 8px;
      padding-right: 50px;
      color: #bababa;

    }
  }

  .clock-status {
    font-size: 14px;
    background: #000;
    color: #fff;
    padding: 5px 0;
    border-radius: 20px;
    margin-left: 20px;
    min-width: 120px;
    display: inline-block;
    text-align: center;

    &.incomplete {
      //background-color: variables.$gray;
    }

    &.cancelled,
    &.na,
    &.void {
      background-color: #666666;
    }

    &.partial {
      background-color: #e69138;
    }

    &.pending {
      background-color: #e06666;
    }

    &.full,
    &.over {
      background-color: #6aa84f;
    }
  }

  .progress {
    margin-left: 0;
    text-align: center;
    width: 100%;
    max-width: 90px;
    font-weight: normal;
    font-size: 12px;
    margin-top: 5px;
    //color: variables.$white;

    &.cancelled,
    &.na,
    &.void {
      background-color: #666666;
    }

    &.partial {
      background-color: #e69138;
    }

    &.pending {
      background-color: #e06666;
    }

    &.full,
    &.over {
      background-color: #6aa84f;
    }
  }

  // .paid {
  //   @extend .pending;
  //   background-color: #a7cf9dff;
  // }
  //
  // .void {
  //   @extend .pending;
  //   background-color: #999999ff;
  // }
  //
  // .complete {
  //   @extend .pending;
  //   background-color: #000;
  // }

  // .form-base md-switch.md-block {
  //   top: 7px !important;
  //   width: 42px !important;
  // }
  //
  // .form-base md-switch.md-block .md-container .md-bar {
  //   height: 15px !important;
  //   width: 35px !important;
  // }
  //
  // .form-base md-switch.md-block .md-container .md-thumb-container .md-thumb {
  //   height: 11px !important;
  //   width: 13px !important;
  // }

  .clocl-left {
    margin-left: 35px;
  }

  @media(max-width:500px) {
    .clocl-left {
      margin-left: 0;
    }

    // .stats-list {
    //   overflow: scroll;
    // }
  }

  .total-clock {
    // background: red;
    width: 100%;
    max-width: 150px;
    min-width: 150px;
    /* padding: 5px; */
    margin-bottom: 3px;
    display: flex;
    font-size: 10px;

    .light-green {
      background: #6aa84fff;
      padding-left: 4px;
      font-size: 10px;
    }

    .green {
      background: #a7cf9dff;
      padding-right: 4px;
      font-size: 10px;
    }

    .light-red {
      background: #cc0000ff;
      margin-right: 0;
      padding-left: 4px;
      font-size: 10px;
    }

    .red {
      background: #e06666ff;
      padding-right: 4px;
      font-size: 10px;
    }

    .light-gray {
      background: #666666ff;
      margin-right: 0;
      padding-left: 4px;
      font-size: 10px;
    }

    .gray {
      background: #999999ff;
      padding-right: 4px;
      font-size: 10px;
    }
  }

  .clockin-img img {
    width: 45px;
    margin-right: 10px;
    height: 45px;
  }

  .pay-status {
    .pay-label {
      margin-left: 35px;
    }
  }

  .overpaid {
    padding-left: 10px;
  }

  .initiate-hour {
    margin-left: 35px;
  }

  .total-approved {
    background-color: #ecf3f9ff;
  }

  .total-reg {
    display: inline-block;
    width: 20%;
  }

  .total-hr {
    display: inline-block;
    width: 80%;
    text-align: right;
  }
}
