
@charset "utf-8";
/*CSS Document*/
/*Don't use undescore(_)*/
/*Don't define font family in body*/
/*UseProper name for css*/
/*--DEFAULT TAGS DEfinition END--*/

@use "features/ellipsis";
@use "features/status";
@use "third-party/mixins";
@use "third-party/variables";
@use "style-guide";

body > .wrapper {
  opacity: 0;
}

.hamburger-dot {
  &:before {
    content: "\2022 \00a0 \2022 \00a0 \2022";
    display: block;
    font-size: variables.$font-size-base;
    color: variables.$white;
    transform: rotate(90deg);
    margin-left: -3px;
  }

  &.gray:before {
    color: variables.$gray;
  }
}

/*--End Backdrop--*/

/*--Side Menu Wrap--*/
.leftnav-wrap {
  height: 100%;
  overflow: auto;
}

.rightnav-wrap {
  height: 100%;
  overflow: auto;
}

/*--End Wrap--*/

.wrapper {
  overflow-x: hidden;
  position: relative;
}

.svg-grey {
  svg {
    path {
      fill: variables.$gray;
    }
  }
}

.space-right {
  margin-right: 15px !important;
}

/*--Drop down menu Css*/
.md-open-menu-container {
  .md-button:not([disabled]).md-focused {
    background: transparent;

    &:hover {
      background-color: rgba(158, 158, 158, 0.2);
    }
  }

  .btn-primary.md-button:not([disabled]).md-focused {
    background-color: variables.$brand-primary;
    color: variables.$white;

    &:hover {
      background-color: variables.$brand-primary-dark;
    }
  }

  .btn-secondary.md-button:not([disabled]).md-focused {
    background-color: variables.$white-dark;
    color: #8e8e93;

    &:hover {
      background-color: #c7c7c9;
      color: #8e8e93;
    }
  }
}

// accent color for md-select

md-select-menu md-option[selected] {
  color: variables.$brand-accent !important;
}

.md-select-value {
  margin-bottom: 0;
}

.md-checkbox-enabled.md-default-theme[selected] .md-icon,
.md-checkbox-enabled[selected] .md-icon {
  background-color: variables.$brand-accent;
  border-color: rgba(0, 0, 0, 0.54);
}

.md-checkbox-enabled.md-default-theme[disabled] .md-icon,
.md-checkbox-enabled[disabled] .md-icon {
  background-color: variables.$gray;
}

.status-dropmenu {
  background: #fff;
  border: 1px solid #CFDADE;
  border-top: 0;
  border-radius: 5px;
  padding: 0;
  width: 120px;

  &.serial-dropmenu {
    width: 300px;
    min-height: 290px;
    position: absolute;
    right: -120px;
    top: 85px;
    padding: 15px;
    background-color: #f4f7f9;

    .type-box {
      color: #000000;
      background-color: #ffffff;
      border: 1px solid #fe9603;
      padding: 10px;

      .second_content {
        font-size: 14px;
      }

      md-icon {
        width: 20px;
        height: 18px;
      }
    }
  }

  md-menu-divider {
    margin: 0;
    background: transparent;
    border-top: 1px dotted #B0A6A6;
  }
}

.status-dropmenu:after,
.status-dropmenu:before {
  bottom: 100%;
  right: 8px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.status-dropmenu:after {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #fff;
  border-width: 4px;
  margin-right: 0.5px;
}

.status-dropmenu:before {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: #CFDADE;
  border-width: 6px;
  margin-right: -2px;
}

.input-dropdown {
  &:after {
    margin-right: 43px;
  }

  &:before {
    margin-right: 41px;
  }

  md-menu-item {
    padding: 10px;

    input {
      border: 1px solid variables.$gray-lighter;
      height: 36px;
      min-width: 0;
      border-radius: 3px;
      padding-right: 32px;
    }

    .md-button {
      border-radius: 3px;
      height: 35px;
      margin-left: 5px;
      max-width: 100px;
      text-align: center;
      min-width: 0;
      position: absolute;
      top: 15px;
      right: 3px;
    }
  }
}

.head-menu {
  padding: 0;

  md-menu-item {
    border-bottom: 1px solid variables.$gray-lighter;
    min-height: 38px;
    height: 38px;

    &:nth-child {
      border-bottom: 0;
    }
  }

  &:after {
    border-width: 8px;
    margin-right: 16.5px;
  }

  &:before {
    border-width: 8px;
    margin-right: 17px;
  }
}

/*--End8--*/

/*--Custom Scroll View--*/
.custom-scroll {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 5px;
  }

  &::-webkit-scrollbar:horizontal {
    width: 6px;
  }

  &::-webkit-scrollbar-track:horizontal {
    background: rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb:horizontal {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 5px;
  }
}

/*--Custom Scroll View End--*/

/***Menu Close button***/
.md-sidenav-right {
  overflow: visible;

  .sidenav-close {
    background: #f2f2f2;
    border-radius: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    position: absolute;
    left: -25px;
    top: 30px;
    z-index: 0;
    margin: 0;
    padding: 7px;
    min-width: 0;
    width: auto;
    min-height: 0;
    height: 30px;
    cursor: pointer;

    &:not([disabled]).md-icon-button:hover,
    &:not([disabled]):hover {
      background: variables.$white-dark;
    }

    md-icon {
      height: 12px;
      width: 12px;
    }
  }
}

/****END****/

.muted-btns {
  margin: 15px 0;
  display: inline-block;

  .md-button {
    @extend .btn-secondary;
    @extend .f4;
    font-weight: normal;
    margin: 0 10px 5px 0;
    height: 30px;
    min-height: 30px;
    line-height: 30px;
    min-width: auto;
    background: variables.$white-dark;
    width: auto;
    padding: 0 10px;
  }
}

/*--Text start from left--*/
.text-start-left {
  display: inline-block;
  text-align: left;
}

.text-start-right {
  display: inline-block;
  text-align: right;
}

.text-start-center {
  text-align: center;
}

.ico-cross {
  transform: rotate(45deg);
  margin-left: 10px;
  height: 18px;
  width: 18px;
  display: inline-block;
  vertical-align: bottom;

  svg {
    path {
      fill: variables.$brand-primary;
    }
  }
}

/*-----------------*/

/* Item Quantity Box*/
.item-quantity-box {
  input {
    width: 45px;
    height: 30px;
    padding: 2px 10px;
    border: 1px solid variables.$gray-lighter;
    border-radius: 3px;
    text-align: center;
    margin: 0 7px;
    color: variables.$gray;
  }
}

/*End */

/*--ng-home--*/
.btn-outline {
  border: variables.$white solid 1px;
}

.ico-btn-edit {
  width: 25px;
  height: 25px;
  border: variables.$white solid 1px;
  border-radius: 100%;
  margin-right: 15px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.ico-btn-edit .icon {
  height: 25px;
  width: 25px;
  position: absolute;
  left: -2px;
  top: -1px;
}

.default-box {
  border: 1px solid variables.$gray-light;
  border-radius: variables.$border-radius-base;
  margin-left: 12px;
  margin-top: 6px;
  padding: 20px;
  box-shadow: 0 3px 6px -1px variables.$gray;
  background: variables.$white;
}

.col-2-list {
  li {
    margin: 10px 0 24px;
  }

  label {
    color: variables.$gray;
  }
}

/* Error Message Hide Start*/
.md-errors-spacer {
  display: none;
}

.md-input-invalid .md-errors-spacer {
  display: block;
}

/* Error Message Hide End*/
.booked-row {
  td {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 1px;
      bottom: 1px;
      left: 0;
      right: 0;
      background: variables.$white-dark;
      opacity: 0.5;
    }
  }
}

.archive-img-container {
  display: inline-block;
  //  height: 30px;
  height: 46px;
  //  line-height: 30px;
  line-height: 35px;
  text-align: center;
  //  width: 30px;
  width: 46px;

  .user-img-holder {
    @include mixins.border-radius(50%);
    display: inline-block;
    overflow: hidden;

    //    .name-initials,
    &,
    img {
      height: 100%;
      width: 100%;
    }

    //    &.no-img {
    //      img {
    //        display: none;
    //      }
    //      .name-initials {
    //        display: block;
    //      }
    //    }
  }

  //  .name-initials {
  //    background: $brand-primary-light;
  //    display: none;
  //    font-size: $font-size-small;
  //    color: $brand-primary;
  //  }
}

.data-table {

  // .touch-arrow,
  &.valign-middle {
    td,
    tr {
      vertical-align: middle;
    }
  }

  .ico-touch-arrow {
    width: 15px;
    height: 15px;
  }

  &.archive-img-table {
    // md-checkbox {
    //   margin-left: 8px;
    // }

    thead {
      .archive-img-container {
        height: auto;
      }

      md-checkbox {
        margin-left: 18px;
      }
    }

    .row-check {
      height: 58px;

      md-checkbox {
        display: none;

        &.selected {
          display: inline-block;
        }
      }

      &:hover {
        .user-img-holder {
          display: none;
        }

        md-checkbox {
          display: inline-block;
        }
      }
    }
  }

  .sub-label {
    position: relative;

    .label-txt {
      font-size: 7px;
      position: absolute;
      right: -3px;
      color: variables.$brand-primary;
      bottom: -4px;
    }
  }

  .row-check {
    // padding-bottom: 0;
    // padding-right: 0;
    padding: 15px 0 0 25px;
    min-width: 30px;

    md-checkbox {
      //            visibility: hidden;
      margin-bottom: 0;
    }

    &:hover {
      md-checkbox {
        //              visibility: visible;
      }
    }
  }

  .inventory-text {
    font-weight: 500;
  }
}

/*global class for location symbol*/
.location-symbol {
  display: inline-block;
  background: variables.$brand-accent;
  color: variables.$white !important;
  font-weight: 500;
  font-size: 10px;
  text-align: center;
  line-height: 1.9;
  border-radius: 20px;
  padding: 0 3px !important;
  // min-width: 14px;
  min-width: 30px;
  height: 18px;
  box-sizing: content-box;
}

.md-select-value .font-italic .location-symbol:not(.filter),
md-option[disabled] .location-symbol {
  background: variables.$gray;
}

/*FormBase Start*/
.form-base {
  .v-address {
    .ico-tick {
      margin-right: 10px;
    }
  }

  md-input-container {
    margin: 20px 0;
  }

  md-input-container.md-input-focused label:not(.md-no-float),
  md-input-container.md-input-has-placeholder label:not(.md-no-float),
  md-input-container.md-input-has-value label:not(.md-no-float) {
    -webkit-transform: translate3d(0, 8px, 0) scale(.92);
    transform: translate3d(0, 8px, 0) scale(.92);
    padding-left: 0;
  }

  md-input-container {
    &.md-no-float {
      &.md-input-focused,
      &.md-input-has-value {
        label {
          display: none;
        }
      }
    }
  }

  md-radio-group {
    &.md-default-theme.md-focused:not(:empty) .md-checked .md-container:before,
    &.md-focused:not(:empty) .md-checked .md-container:before {
      background-color: rgba(variables.$brand-accent, 0.26);
    }

    md-radio-button {
      margin: 0;
      display: inline-block;
      outline: 0;
      margin-right: 10px;
      margin-bottom: 10px;
      @extend .f1;

      .md-container {
        margin-right: 5px;
        width: 22px;
        height: 22px;
      }

      .md-off {
        top: 0;
        width: 22px;
        height: 22px;
        border-width: 1px;
        border-color: variables.$gray-light;
        margin-right: 5px;
      }

      &:not([disabled]).md-primary .md-on {
        background-color: variables.$brand-accent;
      }

      .md-on {
        top: 1px;
        left: 1px;
        background-color: variables.$brand-accent;
      }
    }

    &[disabled] .md-container .md-off {
      background: #f3f3f3;
    }
  }

  md-radio-button.md-checked .md-off,
  md-radio-button.md-default-theme.md-checked .md-off {
    border-color: variables.$gray-light;
  }

  md-radio-button.md-checked .md-ink-ripple,
  md-radio-button.md-default-theme.md-checked .md-ink-ripple {
    color: variables.$brand-accent;
  }

  /*******MD Chips In Form Base*******/
  md-chips {
    margin-bottom: 20px;
    width: 100%;

    &.custom-md-tags {
      md-chip {
        background-color: #596773;
        margin-bottom: 15px;
      }

      .md-chip-content {
        span {
          color: #fff;
          font-size: 14px;
        }
      }

      button.md-chip-remove md-icon {
        fill: variables.$white;
      }

    }

    &.update-custom-tag {
      md-chip {
        margin-bottom: 0;
      }
    }

    input {
      font-size: variables.$font-size-base;
    }

    .md-chips.md-focused {
      box-shadow: 0 2px variables.$brand-primary;
    }

    md-chip {
      border-radius: 3px;

      //      background: $white-darker;
      .md-chip-content {
        span {
          @extend .f3;
        }
      }

      md-chip-template {
        @extend .f3;
        font-weight: normal;

        .md-chip-remove-container {
          width: 28px;
        }
      }

      .md-chip-input-container md-autocomplete,
      .md-chips .md-chip-input-container md-autocomplete-wrap {
        height: 20px;
      }
    }
  }

  /****End******/
  h3 {
    font-size: variables.$font-size-small;
    font-weight: normal;
  }

  input[type="email"],
  input[type="number"],
  input[type="text"],
  input[type="url"],
  input[type="password"],
  md-select,
  textarea {
    border-color: variables.$border-color;
    height: 38px;
    font-size: variables.$font-size-base;
  }

  textarea {
    margin-top: 5px;
  }

  .form-right-section {
    padding: 8px;
  }

  .gap-right-hard {
    margin-right: 20px;
  }

  md-input-container {
    .filter-label {
      color: #353434 !important;
      font-size: variables.$font-size-base !important;
    }
  }

  md-input-container {
    padding: 0;
    margin-top: 0;

    label {
      color: variables.$gray;
      font-size: variables.$font-size-base;
    }

    [ng-messages] {
      // min-height: 0;
      // height: 0;
      overflow: hidden;
    }

    &.md-input-invalid {
      transition: all ease-in-out 1s;

      .md-input {
        border-color: variables.$brand-danger;
      }

      label {
        color: variables.$brand-danger;
      }

      .msg-balloon {
        min-height: 24px;
        height: 24px;
        transition: all ease-in-out 1s;

        div {
          transition: all ease-in-out 1s;
          color: variables.$brand-danger;
        }
      }
    }

    &:not(.md-input-invalid).md-input-focused {
      label {
        color: variables.$brand-primary;
      }

      .md-input {
        border-color: variables.$brand-primary;
      }
    }

    //    @media screen and (max-width: 600px) {
    //      &.gap-right-hard.mob-right {
    //        margin-right: 0;
    //      }
    //    }
    .filter-select {
      .md-select-value.md-select-placeholder {
        color: #353434 !important;
      }
    }

    md-select {
      .md-select-value.md-select-placeholder {
        color: variables.$gray;
      }

      md-select-value {
        padding: 0 2px 2px;

        span {
          font-size: variables.$font-size-base;
        }

        .location-symbol {
          font-size: 10px;
          margin-right: 5px;
        }
      }

      &:not([disabled]) {
        &:focus md-select-value {
          padding: 0 2px 2px;
        }
      }

      md-option:focus[selected] {
        color: variables.$brand-primary;
      }

      &:not([disabled]):focus .md-select-value {
        border-color: variables.$brand-primary;
      }
    }

    &.md-input-has-value {
      md-select md-select-value {
        span:not(.no-color) {
          color: variables.$gray-darker;
        }
      }

      [ng-messages] {
        transition: all ease-in-out 0.5s;
        min-height: 0;
        height: 0;
        //        overflow: hidden;
      }

      &.md-input-invalid [ng-messages] {
        //        transition: all ease-in-out 0.5s;
        min-height: 24px;
        height: auto;
      }
    }

    @media screen and (max-width: 599px) {
      .gap-right-sm {
        margin-right: 20px;
      }
    }

    /*@media screen and (min-width: 768px){
            .msg-balloon{
                background-color: $brand-danger;
                border-radius: 5px;
                padding: 7px 10px;
                overflow: visible;
                position:absolute;
                top: 0;
                right: -168px;
                z-index: 2;
                &:before{
                    content: "";
                    border-style: solid;
                    border-color: transparent  $brand-danger transparent transparent;
                    border-width: 10px;
                    position: absolute;
                    left: -16px;
                    top: 10px;
                }
                div{
                    color: $white;
                }
            }
            }*/
  }

  md-switch.md-block {
    flex: none;
    margin: 0;
    position: relative;
    width: 50px;
    display: block;
    top: -5px;
    left: 0;

    &:not([disabled]) {
      .md-bar {
        background: variables.$brand-primary-light;
      }
    }

    .md-container {
      width: 47px;

      .md-bar {
        border-radius: 50px;
        height: 25px;
        width: 56px;
        top: 0;
        //background: $brand-primary-light;
      }

      .md-thumb-container {
        width: 33px;

        .md-thumb {
          height: 22px;
          top: -1px;
          width: 22px;
        }
      }
    }

    .md-label {
      position: relative;
      right: -19px;
      top: -27px;
      padding: 2px 5px;
      color: variables.$brand-primary-dark;

      &:after {
        // content:"OFF";
      }
    }
  }

  md-switch.md-block.md-checked {
    .md-label {
      left: 0;
      color: variables.$white;
      padding-left: 9px;
    }

    &:not([disabled]) {
      .md-bar {
        background: variables.$brand-primary;
      }
    }

    //.md-bar {
    //  background: $brand-primary;
    //}

    .md-thumb {
      background: variables.$white;
    }
  }

  md-radio-button {
    display: inline-block;
  }
}

/* Map Switch BTn */
.map-switch {
  .map-switch-btn {
    position: relative;
    top: 11px;
  }

  &.form-base {
    md-switch.md-block {
      top: 0;

      .md-container {
        .md-bar {
          height: 14px;
          width: 45px;
          top: 5px;
          background-color: white;
        }

        .md-thumb-container .md-thumb {
          background: variables.$brand-primary url("#{variables.$image-path}/icons/set-g/google-map.svg") no-repeat center (center / 8px);
          color: variables.$brand-primary;
        }
      }

      &.md-checked {
        .md-container {
          .md-bar {
            background-color: variables.$white;
          }

          .md-thumb-container .md-thumb {
            background: variables.$white url("#{variables.$image-path}/icons/set-g/google-map.svg") no-repeat center (center / 8px);
            color: variables.$white;

            &:before {
              background-color: rgba(variables.$white, 0.26);
            }
          }
        }
      }

      .md-thumb .md-ripple-container {
        color: variables.$brand-primary;
      }
    }

    md-menu {

      //      margin-left: 10px;
      //      margin-top: -3px;
      .icon-down-head {
        width: 15px;
        margin-left: 5px;

        .st0 {
          fill: currentColor;
        }
      }
    }
  }

  &.with-btn {
    .map-switch-btn {
      top: 4px;
      right: 12px;
    }
  }
}

/* End */

.date-filter {
  cursor: pointer;
  position: absolute;
  right: 0;
  z-index: 20;
  background: variables.$white-dark;

  md-icon {
    width: 16px;
  }

  .arrow-right {
    transform: rotate(180deg);
  }
}

/******Md Checkbox customization***************/

md-checkbox {
  // min-height: 20px;

  .md-label {
    display: inline-block;
    margin-top: 16px;
  }

  .md-container {
    top: 50%;
    left: 0;
    width: 20px;
    height: 20px;
    margin: 0;
    // margin-top: 0;
  }

  &.md-checked .md-icon,
  .md-icon {
    width: 21px;
    height: 21px;
    background-color: transparent;
    border: 1px solid variables.$gray-light;

    &:after {
      border-color: variables.$brand-accent;
      position: absolute;
      left: 6.67px;
      top: 2.22px;
    }
  }

  &.md-checked .md-ink-ripple,
  &.md-default-theme.md-checked .md-ink-ripple {
    color: variables.$brand-accent;
  }

  &.md-default-theme:not(.md-checked) .md-icon,
  &:not(.md-checked) .md-icon {
    border: 1px solid variables.$gray-light;
  }
}

md-slider {
  .md-track-ticks {
    canvas {
      display: none;
    }
  }

  .md-track.md-track-fill {
    background-color: variables.$brand-primary;
  }

  .md-thumb-container {
    .md-thumb {
      &:after {
        border-color: variables.$brand-primary;
        background-color: variables.$brand-primary;
      }
    }

    .md-sign {
      background-color: variables.$brand-primary;

      &:after {
        border-top-color: variables.$brand-primary;
      }
    }
  }
}

/******Md Checkbox customization End***************/

/*************MD DATEPICKER Customization**********/
.md-datepicker-open .md-datepicker-calendar-icon {
  fill: variables.$brand-accent;
}

.md-calendar-date.md-calendar-selected-date .md-calendar-date-selection-indicator,
.md-calendar-date.md-focus.md-calendar-selected-date .md-calendar-date-selection-indicator {
  background: variables.$brand-accent;
}

.md-calendar-date.md-calendar-date-today .md-calendar-date-selection-indicator {
  border-color: variables.$brand-accent;
}

.md-calendar-day-header,
.md-default-theme .md-calendar-day-header {
  background: #fff;
  color: variables.$brand-accent;
  border-top: 1px solid variables.$gray-lighter;
}

.md-datepicker-calendar * {
  box-shadow: none;
}

.md-datepicker-open .md-datepicker-input {
  padding-top: 2px !important;
}

/*custom datepicker*/
.datepicker-custom {
  label {
    font-size: variables.$font-size-base;
    position: absolute;
    top: -8px;
  }

  .md-datepicker-input-container {
    width: 100%;
    margin-left: 0;
    padding-bottom: 0;
    height: 38px;

    input {
      border: none;
      padding-top: 11px;
    }
  }

  .md-datepicker-input-container.md-datepicker-focused,
  .md-datepicker-input-container.md-datepicker-focused {
    border-color: variables.$brand-primary;
  }

  .md-datepicker-button {
    position: absolute;
    right: 0;
    margin: 0;
    z-index: 2;
    width: 36px;
    height: 36px;
    top: 2px;
    text-align: right;
  }

  .md-datepicker-triangle-button.md-button.md-icon-button {
    display: none;
  }

  md-datepicker,
  md-datepicker.md-default-theme {
    background: transparent;
  }
}

.datepicker-custom-new {
  .md-datepicker-input-container {
    width: 100%;
    margin-left: 0;
    padding-bottom: 0;
    height: 38px;

    input {
      height: 100%;
      padding-top: 11px;
    }
  }

  .md-datepicker-input-container.md-datepicker-focused,
  .md-datepicker-input-container.md-datepicker-focused {
    border-color: variables.$brand-primary;
  }

  .md-datepicker-button {
    position: absolute;
    right: 0;
    margin: 0;
    z-index: 2;
    width: 36px;
    height: 36px;
    top: 2px;
    text-align: right;
  }

  .md-datepicker-triangle-button.md-button.md-icon-button {
    display: none;
  }

  md-datepicker,
  md-datepicker.md-default-theme {
    background: transparent;
  }

  .md-input[disabled] {
    border-bottom-color: rgba(0, 0, 0, 0.12);
  }
}

.datepicker-custom-new {
  &._md-datepicker-floating-label._md-datepicker-has-calendar-icon {
    > label:not(.md-no-float):not(.md-container-ignore) {
      right: auto;
      width: auto;
    }

    .md-input-message-animation {
      margin-left: 0;
    }
  }
}

md-datepicker[disabled] {
  .md-datepicker-input-container {
    border-bottom-color: #E0E0E0;
  }

  .md-datepicker-button {
    .md-datepicker-calendar-icon {
      cursor: default;

      svg path {
        fill: variables.$white-darker;
      }
    }
  }
}

md-select[disabled] {
  border-bottom: 1px solid #E0E0E0;

  .md-select-value {
    background-size: 0;

    span {
      div {
        color: rgba(0, 0, 0, 0.38);
      }
    }

    .md-select-icon:after {
      color: variables.$white-darker;
    }
  }
}

/********************END*****************************/
/*Schedule list start */
.schedule-list {
  ul {
    padding: 0;

    li {
      display: inline-block;
    }
  }
}

/*Schedule list start */
/*form base end*/
.add-field {
  position: absolute;
}

.icon-round {
  border: 1px solid variables.$brand-primary;
  color: variables.$brand-primary;
  cursor: pointer;
  padding: 3px;
  height: 15px;
  width: 15px;
  display: inline-block;
  @include mixins.border-radius(100%);
  position: relative;
  text-align: center;
  position: relative;
  text-align: center;

  span {
    line-height: 16px;
  }
}

.close-btn {
  margin-left: -6px;

  .icon-cross {
    height: 15px;
    width: 15px;
    margin-right: 6px;
    margin-top: -3px;
  }
}

/*FormBase End*/
/*Panels Basic Stying Start*/
.panel-container {
  border-radius: 3px;
  border: 1px solid variables.$white-dark;

  .panel-head {
    height: 48px;
    min-height: 48px;

    .md-toolbar-tools {
      background-color: variables.$white-dark;
      color: variables.$gray-darker;
      font-size: variables.$font-size-base;
    }

    .md-button.counter-rd-box {
      background-color: variables.$brand-accent;
      color: variables.$white;
      width: 20px;
      height: 20px;
      min-height: 20px;
      line-height: 20px;
      font-size: 9px;
      font-weight: 600;
      box-shadow: none;
      position: relative;

      span {
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        margin-top: 1px;
      }

      &:not([disabled]):hover {
        @include mixins.background-opacity(variables.$brand-accent, 0.7);
      }

      &:not([disabled]).md-focused {
        @include mixins.background-opacity(variables.$brand-accent, 0.9);
      }
    }
  }

  /*Form BAse End*/
  .panel-content {
    .md-list-item-text {
      h3 {
        @extend .f3;
        line-height: 25px;
      }

      p {
        @extend .f4;
      }
    }

    md-divider {
      border-color: variables.$white-dark;

      &:not(.hide-last):last-child {
        display: block;
      }
    }
  }
}

//**Header Search **//

.small-btn {
  @media only screen and (max-width: 600px) {
    min-width: 0;
    width: auto;
  }
}

.search-wrapper {
  display: inline-block;
  vertical-align: top;
  position: relative;

  // height: 38px;
  &.responsive {
    @media only screen and (max-width: 600px) {
      top: 4px;
      right: 5px;
    }
  }

  &.gap-right {
    margin-right: 15px;
  }

  .icon-search-container {
    float: right;
    position: absolute;
    right: 0;
    height: 32px;
    width: 32px;
    transition: width 0.3s ease-out;
    backface-visibility: hidden;
    overflow: hidden;
    margin: 6px 10px 0 0;

    @media only screen and (max-width: 600px) {
      margin: 0;
    }

    &.no-marg-top {
      margin-top: 0;
    }

    &.single-btn {
      top: -20px;
    }

    &.active {
      width: 245px;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      background: #fff;
      z-index: 5;

      .search-close {
        display: inline-block;
      }

      .search-input {
        width: 182px;
        font-size: variables.$font-size-small;
        height: 32px;
        padding: 0 5px;
        transition: font-size 0.1s cubic-bezier(0.18, 0.89, 0.32, 1.28);
      }

      .search-symbol {
        top: 6px;
        height: 18px;
        width: 18px;
        margin-left: 8px;
      }
    }

    .search-symbol {
      position: absolute;
      top: 0;
      margin-left: 2px;
      height: 30px;
      width: 30px;
      cursor: pointer;
    }

    .search-close {
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 50%;
      height: 16px;
      width: 16px;
      padding: 4px;
      margin-right: 10px;
      background: variables.$gray-light;

      &:after {
        content: "";
        position: absolute;
        right: -10px;
        top: -7px;
        height: 30px;
        width: 10px;
        background: #fff;
      }

      svg {
        path {
          fill: variables.$white;
        }
      }
    }

    .search-input {
      position: absolute;
      cursor: default;
      left: 30px;
      top: 0;
      bottom: 0;
      right: 20px;
      width: 0;
      padding: 5px;
      border: none;
      outline: none;
      font-size: 0;
      line-height: 20px;
      background-color: rgba(255, 255, 255, 0);
      transition: width 0.4s ease-out;
    }
  }
}

//******************************************//
/** Table Menu DropDown popup**/
.table-menu {
  overflow: hidden;
  border: 0;

  &:after {
    margin-right: 104.5px;
    border-width: 10px;
  }

  &:before {
    margin-right: 102.8px;
    border-width: 11px;
    border-bottom-color: rgba(207, 218, 222, 0.5);
  }

  .custom-scroll {
    overflow-y: auto;
  }

  md-menu-item > * {
    padding: 0;
  }

  .menu-header {
    padding: 3px 10px;

    md-icon {
      height: 18px;
      width: 18px;
      margin-top: -3px;
    }

    strong {
      padding-left: 3px;
    }

    .md-button {
      min-width: 60px;
      height: 30px;
      line-height: 30px;
      min-height: 30px;
      margin: 0;

      span {
        font-size: variables.$font-size-small;
      }

      @extend .btn-primary;

      span {
        color: #fff;
      }
    }

    .md-button:not([disabled]):hover {
      background-color: variables.$brand-primary;
    }
  }

  .quotes-menu-list {
    padding: 10px;
    min-height: 38px;
    height: 38px;

    // &:after {
    //   content: "";
    //   background: url("#{$image-path}/icons/set-d/ico-small-right-arrow.svg") no-repeat;
    //   height: 15px;
    //   width: 30px;
    //   background-size: 15px;
    //   position: relative;
    //   top: 2px;
    //   right: -10px;
    //   cursor: pointer;
    // }

    .folder {
      display: inline-block;
      vertical-align: top;
      width: 10%;
      margin: 0;

      md-icon {
        height: 12px;
        width: 15px;

        &.active {
          width: 13px;
        }
      }
    }

    .quote-txt {
      display: inline-block;
      margin: 0;
      padding-left: 4px;

      .quote-number {
        @extend .f1;
        display: inline-block;
        margin-bottom: 8px;
      }
    }
  }
}

//***Table menu Drop Down End**
//***Default card box ***//

.default-card-box {
  padding: 10px 15px;
  background-color: variables.$navbar-default-bg;
  -webkit-box-shadow: 2px 2px 0 #D2D2D2;
  /*Saf3-4, Chrome, iOS 4.0.2-4.2, Android 2.3+*/
  box-shadow: 2px 2px 0 #D2D2D2;
  /* FF3.5+, Opera 9+, Saf1+, Chrome, IE10 */
}

//***Default card box  End***//

#content {
  md-toolbar {
    .btn-primary,
    .btn-secondary,
    .btn-selected {
      text-transform: capitalize;
      // height: 32px;
      // line-height: 30px;
      // min-height: 32px;
      margin-left: 0;
    }
  }
}

//****Edit Drop Down Menu PopUp****//

.menu-btn {
  line-height: 1;
  min-height: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 5px;
  top: 1px;

  &:before {
    content: "\2022 \2022 \2022";
    cursor: pointer;
    display: block;
    font-size: variables.$font-size-base;
    padding: 5px;
    // color: #cccccc;
  }
}

.edit-action {
  width: 42px;
  min-width: 42px;
  background: variables.$white;
  border: 1px solid variables.$gray-light;
  border-bottom: 0;
  border-radius: 0;

  &:before {
    border-bottom-color: variables.$gray-light;
    border-width: 5px;
    margin-right: -1px;
  }

  &:after {
    border-bottom-color: variables.$white-dark;
    top: -7;
  }

  md-menu-item {
    border-bottom: 1px solid variables.$gray-light;
    height: 40px;
    min-height: 40px;
    cursor: pointer;

    .edit-button {
      min-height: 0;
      min-width: 0;
    }

    .md-button {
      padding-left: 12px;
    }

    md-icon {
      height: 18px;
      width: 18px;
      padding: 0;
      margin: 0;

      svg {
        path {
          fill: variables.$gray;
        }
      }
    }
  }

  @media screen and (max-width: 960px) {
    width: 42px;
    min-width: 42px;
  }
}

//****Edit Drop Down Menu PopUp****//
//******Social Down******//

.social-dropdown {
  min-height: 78px;
  overflow: hidden;
  border: 0;

  &:before {
    border-width: 10px;
    margin-right: 25px;
  }

  &:after {
    border-width: 9px;
    margin-right: 26.5px;
  }

  md-menu-item {
    height: auto;
    padding: 0;

    .input-box {
      position: relative;
      padding: 10px;

      span {
        @extend .f1;
        display: inline-block;
        margin-bottom: 2px;
        width: 100%;
      }

      input {
        width: 69%;
        margin-top: 5px;
        border: 1px solid #dbdbdb;
        height: 38px;
        min-width: 0;
        border-radius: 3px;
        padding-left: 10px;

        @media screen and (max-width: 959px) {
          width: 67%;
        }
      }

      .md-button {
        position: relative;
        min-width: 85px;
        margin: 0 0 0 10px;
        background: variables.$brand-primary;

        span {
          width: 100%;
          color: #fff;
        }
      }
    }

    .v-url {
      margin-top: 7px;
      font-style: italic;
    }
  }

  @media only screen and (max-width: 600px) {
    &:after,
    &:before {
      display: none;
    }
  }
}

//*****END******//
/* Global Table Css For data Table*/
.table-sort {
  // text-align: right;
  // height: 12px;
  margin-top: 10px;
  color: variables.$gray;
}

.table-responsive {
  overflow-x: auto; //  @media screen and (max-width: 1024px) {
  //    max-width: 815px;
  //  }
  //  @media screen and (max-width: 768px) {
  //    max-width: 740px;
  //  }
}

md-toolbar .md-button {
  &.bordered {
    border: 1px solid variables.$gray-light;
  }

  &.md-icon-button.table-ico {
    padding: 0;

    md-icon {
      height: 30px;
      width: 30px; // svg{path{fill:$gray-lighter;}}
    }
  }
}

.table-dropdown {
  overflow: hidden;

  &[width="3"] {
    min-width: 155px;
  }

  md-menu-item {
    padding: 8px 16px 10px;
    min-height: 0;
    height: auto;

    md-checkbox {
      line-height: 1;
      padding: 0;

      .md-label {
        padding-top: 6px;
      }
    }
  }
}

.user-status {
  display: inline-block;
  position: relative;
  margin-right: 5px;

  // md-icon {
  //   height: 30px !important;
  //   width: 30px !important;
  // }

  .status-light {
    display: inline-block;
    position: absolute;
    top: 1px;
    right: 0;
    height: 7px;
    width: 7px;
    border-radius: 100%;
  }

  &.available {
    .status-light {
      background: variables.$brand-success-dark;
    }
  }

  &.busy {
    .status-light {
      background: variables.$brand-danger-dark;
    }
  }

  &.away {
    .status-light {
      background: variables.$proposalOrange;
    }
  }

  &.not-available {
    .status-light {
      background: variables.$gray-light;
    }
  }
}

.data-table {
  // table-layout: fixed;
  // margin: 1px 2% 1px 0%;
  min-width: 500px;
  border: 1px solid variables.$brand-table;
  border-bottom: 0;
  text-align: left;
  width: 100%;
  margin-top: 8px;
  border-radius: 5px;
  border-collapse: inherit;
  overflow: hidden;

  md-checkbox {
    margin-bottom: 0;
  }

  &.archived {
    opacity: 0.6;
  }

  .archive-cell {
    width: 30px;
    padding-right: 0;
  }

  thead {
    background: variables.$brand-table;
    border: none;
    border-spacing: 0;
    font-size: variables.$font-size-base;

    th {
      padding: 0 15px;
      font-size: variables.$font-size-small-plus;
      color: variables.$gray-dark;
      font-weight: bold;
      height: 40px;
      white-space: nowrap;
    }
  }

  td {
    border-bottom: 1px solid variables.$brand-table;
    padding: 15px;
    vertical-align: top;
    line-height: 20px;
    text-align: left;

    .updates {
      @extend .f3-light;
    }
  }

  .sort-icon {
    height: 12px;
    width: 12px;
    margin-left: 4px;
    cursor: pointer;

    svg {
      path {
        fill: variables.$brand-primary;
      }
    }
  }

  //  .selected-row {
  //    background-color: $brand-primary-light;
  //  }
  //  .draft-row {
  //    @extend .selected-row;
  //    td:last-child {
  //      position: relative;
  //    }
  //  }
}

/*Schedule box /List*/
.schedule-list {
  background: variables.$white;
  line-height: 1;
  font-size: 11px;
  margin-top: 8px;
  min-width: 60%;

  li {
    padding: 6px 4px;
    font-size: 10px;

    a {
      padding-left: 0;
      display: inline-block;
      vertical-align: middle;
      @extend .ellipsis-150;
    }
  }

  &.show-5 {
    max-height: 155px !important;
    overflow-y: auto;

    &:last-child {
      a {
        max-width: 48px;
      }
    }
  }

  hr {
    border-color: #FFF;
    opacity: 0.2;
    color: #fff;
    margin: 0;
  }
}

.schedule-list > li {
  border-bottom: 1px solid #E2E2E2;

  &:last-child {
    border-bottom: 0;
  }
}

/*Panels Basic Stying Start*/
/* Right Side Bar */
// .md-sidenav-right,
// .md-sidenav-right md-content {
//   background-color: $white-dark;
// }

.md-sidenav-right .bottom-section {
  background-color: variables.$white-darker;
  text-align: center;
  z-index: 1;
}

.md-sidenav-right .btn-default {
  background-color: variables.$white-dark;
}

.md-sidenav-right.inside-container {
  top: 64px;
  bottom: 65px;
  // @media screen and (max-width: 768px) {
  //   bottom: 46px;
  // }
}

/*Swipe Start*/
.basic-swipe {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  padding: 11px;
  display: flex;
  align-items: center;
}

.swipe-right {
  @extend .basic-swipe;
  left: -200px;
  max-width: 155px;
  background: variables.$brand-success;
  transition: all ease-in-out 0.5s;

  md-icon {
    margin: 0 5px auto 20px;
    height: 42px;
    width: 20px;
  }

  span {
    color: variables.$white;
    font-size: variables.$font-size-small;
  }

  &.right-open {
    left: -16px;
  }
}

.swipe-left {
  @extend .basic-swipe;
  right: -200px;
  max-width: 110px;
  background: variables.$brand-danger;
  transition: all ease-in-out 0.5s;

  md-icon {
    margin: 0 0 auto;
    height: 25px;
    width: 25px;
  }

  span {
    color: variables.$white;
    font-size: variables.$font-size-small;
  }

  &.left-open {
    right: -16px;
  }

  &.left-open-td {
    right: 0;
    max-width: 200px;
    width: 120px;

    md-icon {
      margin: auto 5px;
    }
  }
}

/*Swipe End*/
/*tick Mark start*/
.tick-mark {
  &:after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 5px;
    height: 2px;
    border-left: 1px solid variables.$white;
    border-bottom: 1px solid variables.$white;
    left: 5px;
    transform: rotate(-45deg);
    top: 6px;
  }

  &:before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: variables.$brand-primary;
    left: 2px;
    top: 2px;
    border: 1px solid variables.$brand-primary;
  }

  &.white-tick {
    width: 20px;
    height: 19px;
    display: inline-block;
    margin-left: 15px;

    &:after {
      width: 9px;
      height: 4px;
      border-width: 2px;
      border-color: variables.$brand-primary;
      top: 12px;
      left: 7px;
    }

    &:before {
      width: 19px;
      height: 19px;
      top: 5px;
      border: 1px solid variables.$brand-primary;
      background-color: variables.$white;
    }
  }
}

/*tick mark end*/

/*Tabs Start*/
md-tabs-wrapper {
  md-next-button,
  md-prev-button {
    background: #fff;
  }

  md-next-button:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: -10px;
    background: #fff;
    width: 16px;
  }
}

md-tabs .md-tab.md-focused {
  background: transparent;
}

.common-tabs:not(.no-style) {
  margin-top: 33px;

  md-pagination-wrapper {
    white-space: nowrap;
    border-radius: 5px;
    background-color: variables.$brand-primary;
    border: 1px solid variables.$brand-primary;
    overflow: hidden;
    overflow-x: auto;
    height: 31px;
  }

  .md-tab {
    background-color: variables.$brand-primary;
    padding: 3px 20px;
    font-size: variables.$font-size-small;
    color: variables.$white;
    border-left: 1px solid variables.$white;
    text-transform: capitalize;
    margin-left: -1px;

    &:first-child {
      border-left: none;
    }

    &.md-active {
      background-color: variables.$white;
      color: variables.$gray-darker;
    }
  }
}

.image-tabs-wrapper {
  background-color: variables.$white-dark;
  border-top: 1px solid variables.$gray-lighter;

  &.bottom-bar:after {
    content: "";
    background-color: variables.$white-darker;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 13px;
    width: 100%;
    z-index: 0;
    border-top: 1px solid variables.$gray-lighter;
  }

  &.white {
    &:after,
    .extra-fill {
      background-color: variables.$white;
    }
  }
}

.image-tabs {
  &::-webkit-scrollbar {
    display: none;
  }

  list-style: none;
  margin: 0 auto;
  padding: 0;
  position: relative;
  z-index: 1;
  -webkit-appearance: none;
  white-space: nowrap;
  overflow-x: auto;
  overflow: -moz-scrollbars-none;
  overflow-y: hidden;

  &.child {
    white-space: normal;
    padding: 15px 0;
  }

  .bottom-border {
    border-bottom: 1px solid variables.$gray-lighter;
  }

  li {
    display: inline-block;
    text-align: center;
    padding: 10px 20px;
    min-width: 100px;
    cursor: pointer;
    vertical-align: middle;

    .img-tab-container {
      background-color: variables.$white;
      border: 1px solid variables.$gray-lighter;
      border-radius: 4px;
      width: 98px;
      height: 90px;
      margin-bottom: 10px;
      position: relative;

      md-icon {
        min-height: 60px;
        width: 100%;
        padding: 14px 0;
      }

      .selection-counter {
        @extend .location-symbol;
        min-width: 14px;
        position: absolute;
        top: 4px;
        left: 4px;
        display: none;
      }
    }

    &.change-make {
      .img-tab-container {
        border-color: variables.$gray;

        .selection-counter {
          background-color: variables.$gray;
          display: inline-block;
        }
      }
    }
  }

  &.border-between li {
    border-left: 1px solid variables.$gray-lighter;

    &:last-child {
      border-right: 1px solid variables.$gray-lighter;
    }
  }

  &.outside li {
    padding: 10px;

    &.selection .img-tab-container {
      border-color: variables.$brand-primary;
    }
  }
}

.extra-fill {
  height: 13px;
  background-color: variables.$white-darker;
}

.product-tab-content {
  opacity: 0;
  left: -9999px;
  height: 0;
  position: relative;
  overflow: hidden;

  .item-quantity-box {
    md-icon {
      width: 20px;
      height: 20px;
    }
  }

  &.active {
    opacity: 1;
    height: auto;
    left: 0;
  }
}

/*Tabs End*/

/*Page edit Default-style*/
.svg-primary {
  svg {
    path {
      fill: variables.$brand-primary;
    }
  }
}

.edit-page {
  .page-edit-plus {
    margin-right: 10px;
  }

  .page-edit-options {
    // @media screen and (min-width: 1300px) {
    //   &:not(.no-move) {
    //     margin-right: -45px;
    //   }
    // }

    .svg-base {
      margin: 0;

      &.page-edit-btn {
        * {
          color: variables.$brand-success;
          fill: currentcolor;
        }
      }

      &.page-edit-remove {
        * {
          color: variables.$brand-danger;
          fill: currentcolor;
        }
      }
    }
  }
}

/* Assign Card Csutom Css */
.assign-card {
  @extend .default-card-box;
  padding: 18px;
  width: 100%;

  li {
    .card-name {
      min-width: 150px;
      display: inline-block;
      margin-top: 5px;
      @extend .f1;
    }
  }

  .card-actions {
    text-align: right;

    md-icon {
      margin-right: 18px;
      height: 20px;
      width: 20px;

      &:last-child {
        margin-right: 10px;
      }
    }
  }
}

/*End*/

/****Custom Select*********/
.custom-select {
  padding: 5px 8px;
  position: relative;
  width: 100%;
  border: none;
  box-shadow: none;
  background-color: transparent;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  height: 30px;
  padding-bottom: 0;
  padding-left: 3px;
  padding-right: 15px;
  color: variables.$gray;

  &:focus {
    outline: none;
    border-color: variables.$brand-primary;
    border-width: 2px;
  }
}

/* ************************************************** */

/* Summery-widget css */
.summery-widget {
  padding: 20px;
  margin-bottom: 10px;
  background: variables.$white-dark;

  // div {
  //   margin: 0;
  // }

  .gap-right-hard {
    margin-right: 8px;
  }

  .num {
    color: variables.$brand-primary;
    font-size: variables.$font-size-base;
  }

  .custom-select {
    border-bottom: 0;
    padding: 0;
    font-size: variables.$font-size-base;
    height: 22px;
  }

  h3 {
    @extend .f1;
    font-weight: normal;
    margin: 0;
    display: inline-block;
  }

  .data-list {
    @extend .no-style;

    li {
      border-bottom: 1px solid variables.$gray-light;
      padding: 10px 0 15px;

      .stats-text {
        color: variables.$brand-accent;
        font-size: variables.$font-size-large;
        margin-bottom: 5px;
        font-weight: bold;
        margin-top: 5px;
      }

      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}

/* End */

/* Comman Border Start*/
.border-default {
  border: 1px solid variables.$white-dark;
  border-radius: 5px;
}

/* Comman Border End*/

/* Customized md tabs structure start */

.custom-tabs {
  md-content {
    &.md-padding {
      padding-bottom: 0;
    }

    label {
      padding-right: 35px;
    }

    > h3 {
      margin-bottom: 8px;
      font-weight: normal;
      @extend .f1;
    }
  }

  md-ink-bar {
    display: none;
  }

  .md-tab {
    color: variables.$brand-primary;
    font-weight: 500;
  }

  .md-tab.md-active {
    color: variables.$brand-accent-darker;
  }

  &[md-border-bottom] md-tabs-wrapper {
    z-index: 11;
    border-color: variables.$brand-accent-darker;
  }

  md-tab-item {
    padding-top: 0;
    padding-bottom: 0;
    text-transform: capitalize;
    font-weight: normal;
    font-size: variables.$font-size-base;
  }

  md-pagination-wrapper,
  md-tabs-canvas {
    height: 27px;

    md-tab-item {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  md-tabs-wrapper {
    md-tabs-canvas {
      md-pagination-wrapper {
        md-tab-item {
          overflow: visible;

          &.md-active {
            position: relative;

            &:after,
            &:before {
              top: 27px;
              left: 50%;
              border: solid transparent;
              content: " ";
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
            }

            &:after {
              border-color: rgba(255, 255, 255, 0);
              border-top-color: #FFFFFF;
              border-width: 7px;
              margin-left: -6px;
            }

            &:before {
              border-color: rgba(204, 204, 204, 0);
              border-top-color: variables.$brand-accent-darker;
              border-width: 8px;
              margin-left: -7px;
            }
          }
        }
      }
    }
  }

  md-tabs-content-wrapper {
    md-tab-content {
      transition: -webkit-transform 0.8s cubic-bezier(0.35, 0, 0.25, 1);
      transition: transform 0.8s cubic-bezier(0.35, 0, 0.25, 1);
    }
  }
}

.custom-tabsN {
  md-tabs-wrapper {
    background: rgb(238, 243, 246);
  }

  .md-tab {
    position: relative;
    color: variables.$brand-primary;
    font-weight: bold;

    &.md-active {
      color: rgba(0, 0, 0, 1);
    }

    &:hover {
      color: rgba(0, 0, 0, 1);
      background-color: rgb(232, 238, 241);
    }

    &:hover::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 3px;
      margin: 0 4px;
      width: 100%;
      background: variables.$brand-primary;
      transition: width 0.3s ease;
    }
  }

  md-ink-bar {
    height: 3px;
    background: variables.$brand-primary;
  }
}

// Pradeep change start
md-tabs-canvas {
  overflow: visible;

  md-tab-item {
    overflow: visible;
  }
}

md-tabs-canvas.md-center-tabs {
  display: inline-block;
}

.common-tabs > md-tabs-wrapper {
  @extend .text-center;
}

// Pradeep Change End

.serial-label-status {
  font-size: 16px;
  padding: 15px 12px;
  border: 1px solid #999;
  margin-right: 15px;
  text-align: center;

  .status-name {
    margin-left: 10px;
    display: inline-block;
  }
}

/* Customized md tabs structure end */
/*Accordion Default Style Start*/
.accordion {
  .item .content {
    padding: 0;
    margin: 0;
    transition: all 0.5s ease;
    overflow: hidden;
  }

  li {
    &.active {
      h2 {
        md-icon.arrow {
          transform: rotate(90deg);
          transition: 0.5s all;
        }
      }
    }
  }
}

.accordionN {
  max-width: 560px;
  margin: 0 auto 100px;
  border-top: 1px solid #d9e5e8;

  li {
    border-bottom: 1px solid #d9e5e8;
    position: relative;

    .accordionN-section-content {
      display: none;
      padding: 10px 25px 30px;
      color: #6b97a4;
    }
  }

  a {
    width: 100%;
    display: block;
    cursor: pointer;
    font-weight: 600;
    line-height: 3;
    font-size: variables.$font-size-base;
    font-size: 0.875rem;
    text-indent: 15px;
    user-select: none;
  }

  a:after {
    width: 8px;
    height: 8px;
    border-right: 1px solid #4a6e78;
    border-bottom: 1px solid #4a6e78;
    position: absolute;
    right: 10px;
    content: " ";
    top: 22px;
    transform: rotate(-45deg);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  a.active:after {
    transform: rotate(45deg);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  .accordionN-section-content {
    font-size: variables.$font-size-small-plus;
    font-size: 0.8125rem;
    line-height: 2;
    padding: 10px;
  }
}

.ui-accordion {
  .arrow {
    position: absolute;
    margin: 0;
    width: 15px;
    height: 15px;
    top: 20px;
    right: 16px;
    transform: rotate(-90deg);
    transition: 0.5s all;

    svg path {
      fill: #52748d;
    }

    &.open {
      transform: rotate(90deg);
      transition: 0.5s all;
    }
  }

  .panel-group {
    @include mixins.border-radius(5px);
    overflow: hidden;
    margin-bottom: 0;

    .panel {
      border: 1px solid #96acba;
      margin-bottom: 10px;
      border-radius: 5px;
      -webkit-box-shadow: none;
      box-shadow: none;
      overflow: hidden;
    }

    .panel-heading {
      background: variables.$brand-accent-light;
      padding: 0;

      +.panel-collapse > .panel-body {
        border-top-color: #96acba;
      }
    }

    .panel-title {
      margin: 0;

      & > a {
        display: block;
        padding: 16px;
        outline: none;
        min-height: 50px;
        text-decoration: none;
        font-size: variables.$font-size-base;
        font-weight: 500;
        position: relative;
        color: variables.$gray-dark;
      }

      .accordion-name md-icon {
        margin-right: 5px;
      }
    }

    .panel-body {
      padding: 0;
    }
  }
}

/*Accordion Default Style End*/
/*search button start*/
.search {
  .md-icon-button {
    margin-right: 7px;
  }
}

/*search button end*/
/*Radio Button Style Start*/
md-radio-button:not([disabled]) {
  &.md-checked {
    &.md-primary {
      .md-off {
        border-color: variables.$gray-lighter;
      }
    }

    .md-on {
      background-color: variables.$brand-accent;
    }
  }
}

/*****Sales Agent Box  Style ****/
.customer-name {
  margin-left: 50px;
  margin-bottom: 10px;

  md-input-container {
    width: 100%;
  }
}

.active-labels {
  position: relative;
  //  width: 90%;
  max-width: 365px;
  //  height: 30px;
  line-height: 30px;
  //  border-radius: 3px;
  margin-top: 10px;
  font-size: variables.$font-size-base;

  //  color: $white;
  //  font-weight: normal;
  //  padding-left: 10px;
  &.active {
    border: 2px solid black;
    line-height: 26px;
    padding-left: 8px;

    md-icon {
      top: 6px;

      &.ico-edit {
        right: 8px;
      }
    }
  }

  //  &.no-color {
  //    border: 1px solid $gray-light;
  //
  //    md-icon {
  //      display: none;
  //    }
  //    text-align: center;
  //    font-size: 10px;
  //    color: $gray;
  //    cursor: pointer;
  //  }
  md-icon {
    height: 14px;
    width: 14px;
    position: absolute;
    top: 8px;

    svg {
      path {
        fill: variables.$white;
      }
    }

    &.ico-edit {
      right: 10px;
    }

    &.ico-delete {
      right: -24px;
    }
  }
}

/**Color Chooser Md-checkbox***/
/*Chips start*/
.attachment-list {
  li {
    @include mixins.border-radius(4px);
    padding: 8px 12px 8px 32px;
    background: variables.$white-dark url("#{variables.$image-path}/icons/set-b/ico-pdf.svg") center left 8px / 15px no-repeat;
    margin: 0 15px 10px 0 !important;

    &:last-child {
      margin: 0 15px 0 0 !important;
    }
  }
}

/*Chips End*/
.notify-badge {
  position: absolute;
  top: 11px;
  right: 10px;
  display: inline-block;
  background: variables.$brand-accent;
  color: #fff;
  font-weight: 500;
  font-size: 10px;
  text-align: center;
  padding: 0 5px;
  line-height: 15px;
  border-radius: 20px;
  min-width: 14px;
  height: 15px;
}

/* End */
/*Radio Button Style End*/
.sidebar-add-service {
  margin-left: 3px;
  margin-top: 7px;
  padding: 0 5px;
  height: 25px;
  min-height: 25px;
  line-height: 25px;

  md-icon {
    height: 17px;
    width: 17px;
    margin-right: 5px;
  }

  span {
    @extend .f3;
  }
}

/* Status displayer support page start */
.status-displayer {
  .main-holder {
    position: relative;

    &:after,
    &:before {
      content: '';
      border: 2px solid variables.$white-dark;
      display: inline-block;
      position: absolute;
      top: 35%;
    }

    &:before {
      right: 50%;
      left: 0;
    }

    &:after {
      left: 50%;
      right: 0;
    }

    &:first-child {
      &:before {
        content: none;
      }
    }

    &:last-child {
      &:after {
        content: none;
      }
    }

    .displayer-title {
      cursor: pointer;
      color: #424853;
      font-size: variables.$font-size-base;
      margin-top: 15px;
    }

    &.active {
      .displayer-title,
      .progress-circular-holder .progress-number {
        color: variables.$brand-accent;
      }

      .progress-circular-holder {
        &:after {
          border: 1px solid #5a5a5a;
        }
      }

      .displayer-title {
        @extend .fm;
      }
    }
  }

  .progress-circular-holder {
    cursor: pointer;
    height: 65px;
    width: 65px;
    position: relative;

    &.overall {
      &:after {
        background: variables.$white-dark;
      }
    }

    md-progress-circular {
      height: 63px !important;
      width: 63px !important;

      svg {
        height: 66px !important;
        width: 66px !important;
      }

      .md-inner {
        height: 128px;
        width: 128px;

        .md-gap {
          left: 64px;
          right: 64px;
          border-top-width: 4px;
          top: 0.8px;
        }

        .md-left,
        .md-right {
          height: 128px;
          width: 63px;

          .md-half-circle {
            border-width: 4px;
            width: 128px;
            height: 128px;
          }
        }
      }
    }

    .progress-number {
      @extend .fm;
      color: variables.$gray-dark;
      font-size: 20px;
      @extend .abs-center;
      z-index: 10;
    }

    &:after {
      content: "";
      position: absolute;
      top: 1px;
      left: 1px;
      bottom: 1px;
      right: 1px;
      border: 1px solid #dbdbdb;
      background: #fff;
      z-index: 1;
      @include mixins.border-radius(100%);
    }
  }
}

/* mixin for color platellete in status displayer start */
@mixin status-displayer-platelet($br-side-color) {
  md-progress-circular {
    .md-inner {
      .md-left,
      .md-right {
        .md-half-circle {
          border-top-color: $br-side-color;
        }
      }

      .md-left {
        .md-half-circle {
          border-left-color: $br-side-color;
        }
      }

      .md-gap {
        border-top-color: $br-side-color;
        border-bottom-color: $br-side-color;
      }

      .md-right {
        .md-half-circle {
          border-right-color: $br-side-color;
        }
      }
    }
  }
}

/* mixin for color platellete in status displayer start */
.status-displayer {
  margin-top: 22px;
  margin-bottom: 35px;

  .main-holder {
    &:after,
    &:before {
      @media screen and (max-width: 460px) {
        top: 30%;
      }
    }
  }

  .progress-circular-holder {
    &.platelet1 {
      @include status-displayer-platelet(variables.$brand-danger-dark);
    }

    &.platelet2 {
      @include status-displayer-platelet(variables.$proposalOrange);
    }

    &.platelet3 {
      @include status-displayer-platelet(variables.$brand-success);
    }

    &.platelet4 {
      @include status-displayer-platelet(variables.$gray);
    }

    &.platelet5 {
      @include status-displayer-platelet(variables.$brand-accent);
    }

    &.no-platelet {
      @include status-displayer-platelet(transparent);
    }
  }
}

/* Status displayer support page end */
/* Customer logo start */
.auth-head {
  .ico-default {
    display: inline-block;
    height: 16px;
    width: 16px;
    border-radius: 100%;
    position: relative;

    &:after {
      content: "";
      height: 16px;
      width: 16px;
      position: absolute;
      bottom: -26px;
      right: 16px;
      border-radius: 100%;
    }

    &:before {
      content: "";
      height: 16px;
      width: 16px;
      position: absolute;
      bottom: -26px;
      left: 16px;
      border-radius: 100%;
    }
  }
}

/* Customer logo end */
/* Attribute-definition section start */
.attribute-section {
  .title {
    @extend .fm;
    margin-top: 0;
    color: variables.$gray;
  }

  .attribute-list {
    @extend .no-style;

    li {
      .attr-serno {
        @include mixins.border-radius(4px);
        border: 1px solid variables.$border-color;
        color: variables.$gray-darker;
        display: inline-block;
        height: 21px;
        line-height: 21px;
        margin-right: 10px;
        text-align: center;
        width: 21px;
      }

      .col-title {
        @extend .f4;
        margin-top: 4px;
        margin-bottom: 10px;
        font-weight: normal;
      }
    }
  }
}

/* Attribute-definition section end */
/* Item chips section start */
.item-chips {
  @extend .no-style;

  &.custom-user-tags {
    li {
      background-color: #596773;
      color: #fff;
      margin-bottom: 15px;
      font-size: 14px;
    }
  }

  li {
    @include mixins.border-radius(4px);
    background: variables.$white-dark;
    color: #fff;
    display: inline-block;
    padding: 8px 10px;
    margin-right: 10px;

    .icon-close {
      padding: 0;
      margin: 0;
      height: 13px;
      min-height: 13px;
      width: 13px;

      md-icon {
        height: 11px;
        width: 11px;
      }
    }
  }
}

.item-chipset {
  @extend .no-style;

  li {
    margin-bottom: 10px;
  }

  &.custom-user-tags {
    li {
      .chipbox {
        background-color: #596773;
        color: #fff;
        margin-bottom: 15px;
        font-size: 14px;
      }
    }

  }

  .chipbox {
    @extend .ellipsis-200;
    @include mixins.border-radius(4px);
    background: variables.$white-dark;
    color: variables.$gray;
    display: inline-block;
    padding: 8px 20px 8px 10px;
    @extend .PosR
  }

  .icon-close {
    padding: 0;
    margin: 0;
    height: 13px;
    min-height: 13px;
    width: 13px;

    position: absolute;
    right: 4px;

    md-icon {
      height: 11px;
      width: 11px;
    }
  }
}

/* Item chips section end */
/* Styling for ticked disabled checkbox */
md-checkbox.cb-checkDisabled {
  &,
  &[disabled] {
    .md-label {
      color: variables.$gray-dark;
    }

    &:hover {
      cursor: not-allowed;
    }
  }

  &,
  &[disabled].md-checked {
    .md-icon {
      border: 1px solid variables.$gray-light;
      background-color: #eee;

      &:after {
        border-color: variables.$gray-light;
      }
    }
  }
}

//image, avatar and image replace text

.avatar,
.img-circle {
  width: 46px !important;
  height: 46px !important;
  min-width: 46px !important;
  max-width: 46px !important;
  min-height: 46px !important;
  max-height: 46px !important;
}

.img-circle {
  border-radius: 50%;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.img-replacer {
  height: 46px;
  width: 46px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  @extend .text-center;
  font-size: variables.$font-size-base;
  color: variables.$brand-primary;
  //  padding: 15px 0 0;
  box-sizing: border-box;
  line-height: 46px;
  //  margin-top: 4px;
  text-transform: uppercase;

  &:not(md-icon) {
    background: variables.$brand-primary-light;
  }
}

/* Styling for ticked disabled checkbox */
.task-list {
  .icon-side-tick {
    display: none;
    height: 15px;
    position: absolute;
    left: 46px;
    top: 4px;
    width: 15px;
  }

  .schedule-select {
    background: variables.$brand-info-light;

    &.no-bg {
      background: none !important;
    }

    .icon-side-tick {
      display: inline-block;
    }
  }

  .disabled {
    background-color: rgba(158, 158, 158, 0.3);
    font-style: italic;
    color: variables.$gray;
    cursor: not-allowed;
  }
}

/*Utilization bar section starts*/
.utilization-bar {
  cursor: pointer;
  height: 17px;
  width: 52px;

  span {
    background-image: url("#{variables.$image-path}/icons/set-h/utilization-bar.png");
    background-position: 0 1px;
    background-repeat: no-repeat;
    display: inline-block;
    height: 100%;

    &.inactive {
      background-image: url("#{variables.$image-path}/icons/set-h/utilization-bar-inactive.png");
      background-position: -4px 1px;
    }
  }
}

/*Utilization bar section ends*/
md-input-container {
  &.header-select-input {
    margin-bottom: 0;

    md-select {
      &:not([disbaled]):focus .md-select-value {
        border-bottom: none;
      }
    }

    md-select-value {
      @extend .fm;
      border-bottom: none;

      .md-text {
        @extend .f2;
      }

      .md-select-icon {
        width: 16px;

        &:after {
          content: '\3e';
          color: variables.$brand-primary;
          top: -4px;
          right: -4px;
          -webkit-transform: scaleY(1.2) scaleX(2.1) rotate(90deg);
          -moz-transform: scaleY(1.2) scaleX(2.1) rotate(90deg);
          -o-transform: scaleY(1.2) scaleX(2.1) rotate(90deg);
          transform: scaleY(1.2) scaleX(2.1) rotate(90deg);
        }
      }
    }
  }
}

.icon-active svg path {
  fill: variables.$brand-primary;
}

/*Social icons color paths starts */
.instagram svg path {
  fill: #FF0069;
}

.facebook svg path {
  fill: #1877F2;
}

.twitter svg path {
  fill: #1DA1F2;
}

.linkedin svg path {
  fill: #5BCEE8;
}

.telegram svg path {
  fill: #0088cc;
}

.others svg path {
  fill: variables.$brand-primary;
}

.icon-grey svg path {
  fill: rgb(198, 198, 198);
}

/*Social icons color paths ends */

.assign-resource {
  height: 24px !important;
  width: 42px !important;

  &:hover {
    svg path:first-of-type {
      fill: variables.$brand-success-dark;
    }
  }
}

.user-assign {
  background: variables.$brand-primary;
  border-radius: 20px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  min-width: 42px;
  padding: 2px 10px;
  text-align: left;

  md-icon {
    height: 12px !important;
    margin-right: 2px !important;
    width: 12px !important;

    svg path {
      fill: variables.$white;
    }
  }

  span {
    color: variables.$white;
    font-size: 11px;
  }
}

.save-option {
  position: absolute;
  top: 2px;
  right: 15px;

  md-icon {
    height: 13px;
    width: 13px;

    &.active {
      height: 15px;
      margin-right: 8px;
      width: 15px;

      svg path {
        fill: variables.$brand-primary;
      }
    }
  }
}

.edit-content {
  md-input-container {
    margin: 0;

    input,
    textarea {
      background-color: variables.$white;
      height: 35px !important;
    }
  }
}

md-datepicker {
  .md-datepicker-calendar-icon svg path {
    fill: variables.$brand-primary;
  }
}

/* Product img section start */
.product-img-box {
  .image-cont {
    overflow: hidden;
  }

  // display: inline-block;
  text-align: center;

  // TODO: remove later if not used
  &.flex-box {
    display: flex;

    img {
      @extend .img-responsive;
      border-radius: 3px;
      position: relative;
      // top: 1px;
    }
  }

  .product-img {
    max-width: 60px;
    min-width: 50px;

    &.img-circle {
      @extend .img-circle;
    }
  }

  .product-no-img {
    height: 36px !important;
    min-width: 60px;
  }

  .product-lbl {
    margin-top: 9px;
  }

  .work-order-media-marker.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .6);
    color: #fff;
    display: none;

    .overlay-btn {
      border: 1px solid #fff;
    }
  }

  &:hover {
    .work-order-media-marker.image-overlay {
      display: flex;
    }
  }
}

.product-img-list {
  @extend .no-style;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  li {
    &:not(.single-img) {
      margin-bottom: 25px;
    }

    display: inline-block;
    margin-right: 28px;
    text-align: center;
    vertical-align: top;
  }

  .product-img-box {
    @include mixins.border-radius(4px);
    border: 1px solid variables.$border-color;
    box-sizing: border-box;
    height: 150px;
    //  width: 100%;
    width: 150px;
    position: relative;
    //  width: 95%;
  }

  .holder {
    @extend .abs-center;

    img {
      @extend .img-responsive;
    }
  }

  .product-no-img {
    height: 45px;
  }

  .product-img {
    max-width: 85px;
    min-width: 75px;
  }

  .product-lbl {
    color: variables.$gray-dark;
    cursor: pointer;
    font-size: 10px;
    margin-top: 8px;

    &.add-lbl {
      @extend .f8;
    }
  }

  .img-options {
    position: absolute;
    top: -15px;
    right: -14px;

    .md-icon-button {
      border: 1px solid variables.$gray-light;
      padding: 0;
      height: 28px;
      margin-right: 0;
      min-height: 28px;
      width: 28px;

      &,
      &:hover {
        background: variables.$white;
      }
    }

    md-icon {
      height: 12px;
      width: 12px;
    }
  }
}

/* Product img section end */
.product-selection-list.active {
  border-radius: 5px;
  position: relative;
  top: -1px;
  padding: 10px;

  ul {
    padding: 0;
    list-style: none;
    margin: 0;
    @extend .text-left;

    li {
      display: inline-block;
      vertical-align: top;
    }
  }

  .product-title {
    @extend .ellipsis;
    max-width: 135px;
    font-size: 11px;
    color: variables.$gray-dark;
    line-height: 15px;
    text-align: left;

    &.small-title {
      max-width: 100px;
    }
  }

  .product-price {
    @extend .f4 !optional;
    text-align: left;
    // min-width: 50px;
    // max-width: 67px;
    word-wrap: break-word;
  }

  .product-image {
    width: 74px;

    img {
      max-height: 46px;
      max-width: 46px;
      border-radius: 50%;
    }
  }

  .product-detail {
    width: 100%;
  }

  .product-content {
    width: 100%;
    // max-width: 90px;
    padding-left: 10px;
  }

  md-card {
    box-shadow: none;
    border: 1px solid variables.$gray-light;
    // border-radius: 5px;
    margin: 0;
    cursor: pointer;
    width: 166px;
    min-height: 74px;
    padding: 8px 10px 8px 4px;
  }

  md-checkbox.product-radio {
    padding: 0;
    margin: 10px;
  }

  md-checkbox.product-radio {
    .md-icon {
      top: -2px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border-width: 1px;
      border-color: variables.$gray-lighter;
    }

    .md-label {
      margin: 0;
    }

    .md-container {
      top: 1px;
      left: -11px;
      z-index: 5;
      height: 18px;
      width: 20px;
      background: #FFF;

      .md-icon {
        transition: none;
      }
    }
  }

  md-checkbox.product-radio.md-checked {
    .md-icon {
      background-color: transparent;
      border-radius: 50%;
      border: 1px solid variables.$gray-lighter;

      &:after {
        background: variables.$brand-primary;
        border: 0;
        border-radius: 50%;
        height: 8px;
        width: 8px;
        top: 5px;
        left: 5px;
      }
    }

    md-card {
      border: 1px solid variables.$brand-primary;
    }
  }

  .item-quantity-box {
    opacity: 0;
    overflow: hidden;
    height: 0;
    transition: 0.5s all ease-in-out;
    margin: 0;

    .ico-round-btn {
      svg {
        path {
          fill: #dbdbdb;
        }
      }
    }
  }

  md-checkbox.product-radio.md-checked+.item-quantity-box {
    margin: 9px 5px 10px;
    transition: 0.5s all ease-in-out;
    overflow: visible;
    opacity: 1;
    height: auto;
  }

  md-icon.icon-like {
    height: 10px;
    width: 10px;
  }
}

.img-tab-container {
  // max-height: 90px;
  // height: 100%;
}

.days {
  @extend .no-style;
  margin-top: 10px;

  li {
    &:not(:last-child) {
      margin-right: 10px;
    }

    display: inline-block;
    margin-bottom: 10px;
    text-align: center;
  }

  .day-text {
    @extend .f4;
    border: 1px solid variables.$border-color;
    border-radius: 50%;
    height: 34px;
    line-height: 34px;
    text-transform: uppercase;
    width: 34px;

    &.dayOffWorking,
    &.selected {
      border: 1px solid variables.$brand-accent;
      color: variables.$brand-accent;
    }

    &.dayOff {
      border: 1px solid variables.$gray-light;
      background: variables.$gray-light;
      color: variables.$white;
    }

    &.dayOffWorking {
      background: variables.$white-dark;
    }
  }
}

.search-list {
  @extend .no-style;

  .small-text {
    font-size: 10px;
  }

  li {
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    transition: all 0.15s linear;

    &:not(.static) {
      cursor: pointer;

      &:hover {
        background-color: rgba(158, 158, 158, 0.2);
      }
    }

    .product-id {
      color: variables.$gray-dark;
      margin-top: 0;
      margin-bottom: 10px;
    }

    .gap-xs {
      margin-right: 8px;
    }

    .icon-tick {
      display: none;
      height: 16px;
      position: absolute;
      top: -6px;
      right: -5px;
      z-index: 10;

      &.show-tick-grey {
        display: inline-block;
      }
    }

    &.selected {
      background-color: variables.$brand-info-light;

      &:hover {
        background-color: #c1e2b4;
      }

      .icon-tick {
        display: inline-block;
      }
    }

    &.disabled {
      background-color: rgba(158, 158, 158, 0.3);
      font-style: italic;
      color: variables.$gray;
      cursor: not-allowed;
    }
  }

  .avatar,
  .img-container {
    height: 42px !important;
    width: 42px !important;
    min-width: 42px !important;
    min-height: 42px !important;
  }

  .img-container {
    //    background: $white;
    background: variables.$brand-primary-light;
    //    min-width: 42px;
    //    max-width: 42px;
    border-radius: 50%;
    //    border: 1px solid $border-color;
    margin-right: 12px;
    //    padding: 10px 5px;
    position: relative;
    text-align: center;

    img {
      height: 42px;
      width: 42px;
      border-radius: 50%;
      // TODO: remove after updating HTMLs
      @extend .abs-center;
    }

    .icon-tick {
      position: absolute;
      height: 16px;
      right: 0;
      top: -5px;
      z-index: 1;
    }
  }

  .short-name {
    color: variables.$brand-primary;
    font-size: variables.$font-size-base;
    line-height: 42px;
    text-transform: uppercase;
  }

  md-list-item._md-button-wrap > div.md-button {
    padding: 8px 16px;
    line-height: 1;
  }
}

.last-update-msg {
  color: variables.$gray;
  position: absolute;
  font-size: 10px;
}

.default-business-logo {
  .ico-default {
    margin-top: -20px;
    margin-right: 30px;
    margin-left: 15px;

    &,
    &:after,
    &:before {
      background: variables.$brand-accent;
    }
  }

  .logo-title {
    font-size: 24px;
    font-weight: 300;
  }
}

.break-word {
  word-break: break-all;
}

@media only screen and (min-width: 600px) {
  .hide-backdrop {
    md-backdrop {
      display: none;
    }
  }

  .hidden-backdrop {
    md-backdrop {
      background: transparent;
    }
  }

  //  .product-img-list {
  //    li {
  //      display: inline-block;
  //      margin-right: 28px;
  //    }
  //    .product-img-box {
  //      width: 150px;
  //    }
  //  }
}

@media (max-width: 959px) {
  md-menu-content[width="4"] {
    min-width: 256px;
  }
}

@media only screen and (max-width: 600px) {
  .md-dialog-container {
    md-toolbar.main-header .md-button.btn-primary {
      md-icon svg path {
        fill: variables.$white;
      }
    }
  }
}

@media only screen and (max-width: 321px) {
  md-menu-content[width="6"] {
    min-width: 287px;
  }

  .social-dropdown md-menu-item .input-box input {
    width: 62%;
  }

  .map-switch .map-switch-btn {
    top: 9px;
    right: 38px;
  }
}

/* Toast style */
md-toast {
  &.md-success-toast-theme .md-toast-content {
    text-shadow: 0 1px rgba(variables.$gray-base, 0.5);
    background-color: variables.$brand-success;
  }

  &.md-danger-toast-theme .md-toast-content {
    text-shadow: 0 1px rgba(variables.$gray-base, 0.5);
    background-color: variables.$brand-danger;
  }

  &.md-warning-toast-theme .md-toast-content {
    text-shadow: 0 1px rgba(variables.$gray-base, 0.5);
    background-color: variables.$brand-warning;
  }
}

md-select-menu md-option {
  &.option-selected,
  &.option-selected-edited {
    color: variables.$brand-danger-dark;
  }
}

.center-tabs md-pagination-wrapper {
  position: relative;
  left: 50%;
  transform: translateX(-50%) !important;
}

// input.number-type[type=number] {
//   height: 100%;
// }

// md-input-container .md-input[disabled]:not(textarea) {
//   background-image: none;
// }

md-input-container .md-input.black-font[disabled] {
  color: black;
}

.last-col-cursor tbody tr td:last-child {
  cursor: pointer;
}

md-icon {
  cursor: pointer;
  min-height: 0;
  min-width: 0;
  outline: 0;

  &.circle-svg {
    svg {
      border-radius: 50%;
    }
  }
}

md-autocomplete {
  md-input-container {
    .md-input {
      font-size: variables.$font-size-base;
    }
  }
}

md-autocomplete {
  margin-bottom: 20px;

  md-autocomplete-wrap {
    md-input-container {
      margin-bottom: 0 !important;

      .md-input {
        height: 38px;
      }
    }

    md-progress-linear.md-inline {
      bottom: -3px;
      right: 0;
      left: 0;
      width: auto;
    }
  }

  &[md-floating-label] .md-show-clear-button button {
    right: 4px;
    top: 8px;
  }
}

// confirm dialog box
.confirm-dialog {
  width: 500px;

  .dialog-content {
    padding: 30px;
  }

  .description {
    line-height: 24px;
  }

  .question {
    font-size: variables.$font-size-medium;
  }
}

// textarea of different sizes

md-input-container textarea {
  &.textarea-ht-lg,
  &.textarea-ht-md {
    max-height: 160px;
    overflow: auto;
  }

  &.textarea-ht-md {
    min-height: 45px;
  }

  &.textarea-ht-lg {
    min-height: 60px;
  }
}

.country-code md-select-value .sprite {
  max-width: none;
  flex: none;
  margin-right: 5px;
}

.country-code md-select-value .country-name {
  display: none;
}

.bottom-margin {
  margin-bottom: 24px;
}

md-dialog-content .layout-background {
  position: fixed;
  top: 64px;
}

.badge {
  &.badge-zero {
    background: variables.$input-color-placeholder;
  }

  background: variables.$brand-primary;
  color: white;
  text-align: center;
  border-radius: 10px;
  padding: 0 10px;
  max-width: 70px;

  &.active {
    background: variables.$status-green;
  }

  &.archived {
    background: variables.$status-yellow;
  }
}

//_guider.scss code in Custom.css

.guider-section {
  .resource {
    border: 1px solid variables.$white-dark;

    .resource-personal {
      .res-img {
        width: 80px;
        height: 80px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .res-other {
        margin-left: 15px;

        .location,
        .profession,
        h2 {
          @extend .gap;
        }

        h2 {
          margin-top: 0;
        }

        .profession {
          color: variables.$brand-primary;
        }

        .location {
          color: variables.$gray;
        }
      }
    }

    .gap {
      margin-bottom: 6px;
    }

    .resource-other {
      @extend .text-right;

      .active {
        @extend .gap;
        color: variables.$brand-success-dark;

        div {
          background: variables.$brand-success-dark;
          padding: 6px;
          display: inline-block;
          vertical-align: middle;
          border-radius: 50%;
          margin-right: 4px;
        }
      }

      .bdate,
      .email,
      .mobile {
        @extend .gap;
        color: variables.$gray;
      }
    }

    @media screen and (max-width: 600px) {
      .resource-other {
        margin-left: 100px;
        text-align: left;
      }
    }
  }

  .guider-checklist .md-list-item-inner {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }

  .panel-container {
    // height: 100%;
    width: 100%;

    .empty-action-div {
      @extend .text-center;

      md-icon {
        height: 250px;
        width: 250px;
      }

      .no-action {
        color: variables.$brand-accent;
        font-size: 18px;
      }

      .start-add {
        color: gray;
        font-size: variables.$font-size-base;
      }
    }

    .panel-head {
      .md-toolbar-tools {
        background-color: variables.$white;
      }

      md-icon.small-size {
        width: 15px;
      }

      .panel-heading {
        color: variables.$brand-accent;
        font-weight: 500;
        font-size: 16px;

        .folder {
          margin-right: 5px;
          margin-top: -3px;

          svg path {
            fill: variables.$brand-accent;
          }
        }

        .edit {
          width: 12px;
          margin-left: 5px;
          margin-top: -3px;
        }
      }
    }

    .input-toolbar {
      background-color: #fff !important;
      // background-color: #20394c !important;
      border-top: 1px solid variables.$white-dark;
      border-bottom: 1px solid variables.$white-dark;
      font-size: variables.$font-size-base;

      .top {
        margin-top: 20px;
      }

      .md-input-custom {
        padding-right: 20px;
        margin-bottom: 15px;
        margin-left: 20px;

        .fav-icon {
          position: absolute;
          right: -7px;
          top: 9px;
          fill: unset;
        }

        .guider-cancel {
          background: variables.$gray-lighter;
        }
      }
      @mixin active-menu-styles {
        background-color: variables.$brand-accent;
        color: variables.$white;

        .badge {
          background: variables.$white;
          color: variables.$brand-accent;
        }
      }

      .list-inline li {
        @extend .cursor-point;
        color: rgba(0, 0, 0, 0.38);
        padding: 5px;
        margin: 5px 0;
        border-radius: 15px;
        font-size: variables.$font-size-small;

        &.archived {
          margin-bottom: 0;
          margin-top: 2px;
        }

        &.active-menu {
          @include active-menu-styles;

          &:hover {
            background-color: #3cc1bf;
          }
        }

        &.active-menu-complete {
          @include active-menu-styles;
          background-color: rgb(184, 225, 184);

          .complete {
            color: rgb(0, 94, 0);
          }
        }

      }

      .badge {
        background: variables.$gray;
      }

      .custom-filter {
        md-input-container {
          margin: 0;

          .md-select-value {
            border: none;
          }

          md-select:not([disabled]):focus .md-select-value {
            border: none;
          }
        }
      }
    }

    .panel-content {
      background-color: variables.$white;

      // .action-radio-list {
      //   border-bottom: 1px solid $white-dark;
      // }
      md-list {
        padding: 0;

        md-list-item {
          &.form-base:first-child {
            min-height: 80px;
          }

          .radio-section {
            width: 100%;
          }
        }

        .resource-gap {
          margin-right: 3px;
        }

        .notification {
          md-icon svg g > * {
            fill: variables.$gray-light;
          }
        }

        .bottom-gap {
          margin-bottom: 10px;
        }

        .bell,
        .notification,
        .star {
          margin-top: 12px;

          md-icon {
            width: 18px;
            height: 18px;
          }
        }

        .star {
          margin-left: 10px;
          cursor: pointer;

          // md-icon {
          //   color: $white-darker;
          // }
        }

        md-icon.timer {
          margin: 0 8px 0 0;
          cursor: pointer;

          md-icon {
            color: variables.$white-darker;

            &.active {
              color: variables.$brand-accent;
            }
          }
        }

        .radio-list-btn {
          margin-top: 14px;
          margin-bottom: 16px;
          margin-left: 16px;
          line-height: 15px;
          @extend .f3;
        }
      }
    }

    .list-tab {
      height: 70px;
    }

    .nf-items {
      margin-top: -20px;
    }

    .panel-content {
      .list-item {

        // padding: 15px;
        // height: 63px !important;
        &:not(:last-child) {
          border-bottom: 1px solid variables.$white-dark;
        }

        .task-list {
          .select {
            position: absolute;
            left: 20px;
            margin-top: 12px;
          }

          .task-des {
            margin-left: 35px;

            .task-list-header {
              margin-bottom: 8px;

              md-checkbox {
                margin-bottom: 0;
              }

              .head {
                margin-top: 2px;

                span {
                  font-size: 15px;
                  font-weight: bold;
                }
              }
            }

            .task-detail {
              .list-inline li {
                padding: 8px 0;
              }

              color: variables.$gray;

              label {
                color: variables.$brand-accent;
              }

              .date {
                margin-right: 12px;
              }

              .time {
                margin-right: 12px;

                md-icon {
                  width: 15px;
                  height: 15px;
                  margin-right: 5px;
                }
              }

              .user {
                margin-right: 12px;

                .user-icon {
                  width: 15px;
                  height: 15px;
                  border-radius: 50%;
                  background: variables.$gray;
                  display: inline-block;
                  color: variables.$gray-dark;
                  border: 1px solid variables.$gray;
                  vertical-align: middle;

                  .user-name {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 8px;
                  }
                }
              }

              .quote {
                margin-right: 12px;

                md-icon {
                  width: 15px;
                  height: 15px;
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.directory-create {
  min-height: 200px;
  max-width: 100%;
  width: 480px;

  md-dialog-content {
    padding: 20px 10px;
  }
}

.btn-border {
  background-color: transparent;
  color: variables.$gray;
  border: 2px solid;

  &.folder {
    color: variables.$brand-primary;
  }

  &.active {
    border: 2px solid variables.$brand-primary;
    color: variables.$white;
    background-color: variables.$brand-primary;
  }
}

.list-inline,
.list-inline li {
  padding: 0;
  list-style: none;
  display: inline-block;
  vertical-align: middle;
}

.directory {
  padding: 20px 0;
  background-color: variables.$white;

  .btn-border:first-child {
    margin-left: 20px;
  }
}

.polygon-filled {
  svg {
    path {
      fill: #fff !important;
    }
  }
}

.action-list-sidebar {
  &.action-list-sidebar-width {
    width: 375px;
  }

  .action-icon {
    width: 20px;
  }

  .action-list-sidebar-header {
    background: #000;

    .action-icon {
      width: 30px;
      height: 30px;
    }

    .action-item-button {
      background: #fff;
      border: 1px solid variables.$brand-primary;
      min-width: 30px;
      max-width: 30px;
      min-height: 30px;
      border-radius: 50px;
      line-height: 15px;

      .action-item-icon {
        width: 18px;
        height: 18px;
      }
    }

    .block-title {
      min-width: 0;
    }
  }

  .action-item-accordian {
    .ui-accordion .panel-group .panel {
      border: 0 !important;
    }

    .panel {
      border: 0 !important;
    }

    .panel-group {
      margin-bottom: 0;
    }

    .panel-group .panel-heading {
      background: #52758d;
      border-bottom: 5px solid #374151;
    }
  }

  .accordion-section {
    border-bottom: 5px solid #374151;

    md-list-item:hover {
      h2 {
        color: variables.$brand-primary;
      }
    }

    md-list-item.list-item::before {
      min-height: 0;
    }

    md-list-item.md-no-proxy {
      padding: 5px;
    }

    .f1-span {
      color: #949494;
    }

    .accordion-section-icon {
      width: 40px;
      height: 40px;
    }

    .right-arrow {
      width: 15px;
      height: 15px;

      svg {
        path {
          fill: variables.$brand-primary;
        }
      }
    }

    .product-img-list .product-img-box {
      height: 100px;
      width: 100px;
    }
  }

  .action-item-image-button {
    border: 1px solid variables.$brand-primary !important;
  }

  .user-info {
    background: #000;
    padding: 10px 15px;
  }

  .bottom-section {
    position: absolute;
    width: 100%;
    bottom: 0;

    &.action-list {
      .btn-default {
        border: 1px solid variables.$brand-primary;
        color: variables.$brand-primary;
        background: #fff;
      }

      .btn-default:hover {
        background-color: rgba(158, 158, 158, 0.2);
      }
    }
  }

  .botton-gap {
    margin-bottom: 10px;
  }

  .space-left {
    margin-left: 20px;
  }

  .short-des {
    max-height: 120px;
    overflow: auto;
  }

  .file-attach {
    border: 1px solid;
    margin-left: 0;
    margin-bottom: 2px;
  }

  .reminder {
    margin-top: -13px;
    margin-left: 10px;
  }

  .actions md-icon {
    width: 20px;
    height: 20px;
  }

  .action-list {
    // padding: 8px;

    .data-section {
      top: 68px;
      bottom: 50px;
      overflow-y: auto;
      overflow-x: hidden;
      position: absolute;
      right: 0;
      left: 0;
      padding: 10px;
    }

    .custom-input-row {
      @extend .PosR;
      margin-top: 15px;

      // md-icon {
      //   position: absolute;
      // }
      .md-block {
        width: 100%;

        .date {
          margin: 10px 0;
          right: auto;
        }

        md-datepicker {
          .md-datepicker-button {
            // display: none;
          }

          .md-datepicker-input-container {
            width: 100%;
            margin-left: 0;
          }
        }
      }
    }
  }

  .custom-user-tags {
    .file-attachment {
      background: #eeeeee;
      border: 1px solid #eeeeee;
      color: #000;
    }
  }
}

.quick-update-accordion {
  .panel-group {
    margin-bottom: 10px;
  }

  .panel-group .panel-heading {
    background: #52758d;
    border-bottom: 5px solid #374151;
  }
}

.calendar-accordion {
  background-color: rgb(227, 245, 244);
  border-radius: 4px;
}

.disabled-right-nav {
  input {
    border-bottom: 1px solid variables.$brand-table !important;
  }
}

//Resources select side nav

.top-section .lefthead-icon {
  height: 18px;
  width: 18px;
  margin: 8px 5px;
}

.sidenav-title {
  margin-top: 0 !important;
}

.res-sidebar-nav .search-button {
  top: 15px;
}

.schedule-select {
  background: variables.$brand-info-light;
}

.block-title {
  font-size: 16px !important;
  // font-weight: 400;
  margin: 8px;
  min-width: 150px;
}

@media only screen and (max-width: 600px) {
  .guider-section {
    .panel-container {
      .list-tab {
        height: 100px;

        .custom-filter {
          margin: 25px 8px;
        }
      }
    }
  }
}

// empty state ends here

// START :  CUST0M FORM GENERATOR CSS
.custom-form-bottom-gap {
  margin-bottom: 15px;
}

.custom-lbl .label,
.custom-lbl label {
  color: variables.$gray;
  font-size: variables.$font-size-base;
}

.custom-lbl .label.radio {
  margin-bottom: 10px;
}

.custom-lbl md-checkbox,
.custom-lbl md-input-container {
  margin-top: 10px;
}

// END :  CUST0M FORM GENERATOR CSS

.ar-sidepanel .task-wrapper {
  .active {
    color: variables.$brand-success; //green:
  }

  .draft {
    color: variables.$brand-primary; //brand-primary:
  }

  .danger {
    color: variables.$brand-danger; //brand-danger:
  }

  // .f3-light {
  //   font-size: 11px;
  // }
}

.summery-widget {
  &.info-widget {
    width: 100%;
    min-width: 260px;
  }

  .f1 {
    font-weight: 600;
  }

  .status {
    font-size: variables.$font-size-base;
    font-weight: 600;
  }

  .new {
    color: variables.$brand-success;

    md-icon & {
      svg {
        .path2 {
          fill: variables.$brand-success;
        }

        .path3 {
          fill: variables.$brand-success;
        }
      }
    }
  }

  .active {
    color: variables.$brand-success;

    md-icon & {
      svg {
        .path2 {
          fill: variables.$brand-success;
        }

        .path3 {
          fill: variables.$brand-success;
        }
      }
    }
  }

  .scheduled {
    color: variables.$brand-success;

    md-icon & {
      svg {
        .path2 {
          fill: variables.$brand-success;
        }

        .path3 {
          fill: variables.$brand-success;
        }
      }
    }
  }

  .accepted {
    color: variables.$brand-success;

    md-icon & {
      svg {
        .path2 {
          fill: variables.$brand-success;
        }

        .path3 {
          fill: variables.$brand-success;
        }
      }
    }
  }

  .completed {
    color: variables.$brand-success;

    md-icon & {
      svg {
        .path2 {
          fill: variables.$brand-success;
        }

        .path3 {
          fill: variables.$brand-success;
        }
      }
    }
  }

  .rescheduled {
    color: variables.$brand-success;

    md-icon & {
      svg {
        .path2 {
          fill: variables.$brand-success;
        }

        .path3 {
          fill: variables.$brand-success;
        }
      }
    }
  }

  .canceled {
    color: variables.$gray;

    md-icon & {
      svg {
        path {
          fill: variables.$gray;
        }
      }
    }
  }

  .incompleted {
    color: variables.$gray;

    md-icon & {
      svg {
        path {
          fill: variables.$gray;
        }
      }
    }
  }

  .void {
    color: variables.$gray;

    md-icon & {
      svg {
        path {
          fill: variables.$gray;
        }
      }
    }
  }

  .deleted {
    color: variables.$gray;

    md-icon & {
      svg {
        path {
          fill: variables.$gray;
        }
      }
    }
  }

  .draft {
    color: variables.$gray;

    md-icon & {
      svg {
        path {
          fill: variables.$gray;
        }
      }
    }
  }

  .disabled {
    color: variables.$gray;

    md-icon & {
      svg {
        path {
          fill: variables.$gray;
        }
      }
    }
  }

  .archived {
    color: variables.$gray;

    md-icon & {
      svg {
        path {
          fill: variables.$gray;
        }
      }
    }
  }

  .expired {
    color: variables.$brand-danger;

    md-icon & {
      svg {
        path {
          fill: variables.$brand-danger;
        }
      }
    }
  }

  .info-div {
    margin: 10px -20px 0;
    padding: 5px 0;

  }

  .val {
    font-size: variables.$font-size-base;
  }

}

.location-select {
  md-select {
    min-width: 185px;

    .location-symbol {
      margin-right: 5px;
    }
  }
}

.primary {
  color: variables.$brand-primary; //brand-danger:
}

.clear-date {
  position: absolute;
  right: 0;
  bottom: 0;
}

.data-table {
  .text-left {
    text-align: left !important;
  }

  .text-center {
    text-align: center !important;
  }

  .text-right {
    text-align: right !important;
  }
}

@media (max-width:400px) {
  .image-cropper .upload-area .drop-area {
    width: 100% !important;
  }

  .image-cropper .main-header h2 {
    font-size: 14px;
  }

  .image-cropper .repo-categories {
    text-align: left;
  }

  .media-upload h3 {

    font-size: 16px !important;
  }

  .media-upload .f2 {
    font-size: 10px !important;
  }

  .supplier-right {
    margin-top: 125px;
    margin-left: 11px;
  }

  .po-overflow {
    max-width: 98px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.custom-chips {
  line-height: 1;

  li {
    &:not(.no-bg) {
      background: rgb(224, 224, 224);
    }

    border-radius: 35px;
    line-height: 10px;
    margin: 10px 10px 10px 0;

    .img-replacer,
    img,
    md-icon {
      width: 35px;
      height: 35px;
      line-height: 35px;
      border-radius: 50%;
    }

    .user-name {
      line-height: 35px;
      padding: 0 10px;
      color: variables.$gray-darker;
    }

    // Smal-chip for table
    &.small-chip {
      .img-replacer,
      img,
      md-icon {
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 10px;
      }

      .user-name {
        line-height: 20px;
        padding: 0 5px;
      }
    }
  }
}

.md-panel.md-tooltip {
  height: auto;
  line-height: 1.6;
  white-space: pre-line;
  background: #000;
  font-size: 12px;
  max-width: 300px;
  color: variables.$white;
  padding: 5px 10px;
  text-align: justify;
}

.business-setup-timezone {
  position: relative;
}

.tooltip-custom {
  //max-width: 400px;
  min-width: 250px;
  width: 100%;
  border: 2px solid #3869d4;
  background-color: #ffffff!important;
  color: #666666!important;
  line-height: 1!important;
  //box-shadow: 0 0 18px rgb(0 0 0 / 14%)!important;

  &.privacy-tooltip {
    min-width: 235px;
    max-width: 350px;
    width: 100%;
    line-height: 1!important;
    padding: 5px 10px;
    text-align: center;
    position: relative;
    right: 40%;
  }

  &.valid-tooltip {
    right: 0;
    padding: 5px 10px 10px;
  }

  &.timezone-tooltip {
    right: 110%;
    bottom: 0;
    //top: 80%;
    position: relative;
    padding: 10px 5px;
    margin-top: 40%;
  }

  .check-section {
    margin-bottom: 0;
    margin-top: 0;
    vertical-align: middle;

    span {
      margin-top: 0;

    }
  }

  .info-icon-img {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    margin-top: 2px;
  }
}

.reset-pass {
  .password-checker-section {
    margin-bottom: 15px;
  }

  .info-icon {
    width: 15px;
    height: 15px;
  }

  .info-icon-img {
    width: 15px;
    height: 15px;
  }

  .indicator {
    height: 10px;
    width: 165px;
    margin: 3px 0 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      position: relative;
      height: 100%;
      width: 100%;
      background: lightgrey;

      &.active {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

      }

      &.weak {
        background-color: #ff4757;
      }

      &.medium {
        background-color: orange;
      }

      &.strong {
        background-color: #23ad5c;
      }
    }

    // span:nth-child(2){
    //     margin: 0 3px;
    // }
  }

  .password-strength {
    .password-strength-status {
      color: #6aa84f;
      margin: 0 10px 5px 0;

      &.approved {
        color: #6aa84f;

      }

      &.weak {
        color: #e06666ff;

      }

      &.medium {
        color: #f1c232ff;

      }
    }

    .info-icon-img {
      margin-right: 10px;
    }

    .cross-circle-img {
      margin-top: 0;

      svg path {
        fill: #f55e61;
      }

    }

  }

  .password-status {
    .cross-circle-img {
      margin-top: 0;
      width: 16px;
      height: 16px;
      margin-right: 15px;

      svg path {
        fill: #f55e61;
      }
    }

    .incorrect {
      margin-top: 0;
      color: #f55e61;
    }
  }
}

// @media screen and (max-width: 1250px) {
//   .tooltip {
//     position: absolute !important;
//     right: 20% !important;
//   }
// }
// @media screen and (max-width: 410px) {
//   .tooltip {
//     position: absolute !important;
//     right: 20% !important;
//     min-width: 200px;
//     width: 100%;
//   }
// }

.btn-cancel {
  border: 1px solid variables.$gray-lighter;
}

.star-icon {
  width: 18px;
  margin-right: 10px;
}

.list-icon {
  width: 20px;
  margin-right: 10px;
}

.order-status {
  display: inline-block;
  width: 70px;

  .status {
    text-transform: uppercase;
  }

  .status-viewer {
    @include mixins.border-radius(5px);

    background: variables.$gray-lighter;
    border: 1px solid variables.$gray-light;
    height: 18px;
    margin-top: 2px;
    margin-bottom: 2px;
    overflow: hidden;

    .progress-bar {
      display: inline-block;
      height: 100%;

      &.open {
        background: variables.$proposalBlue;
      }

      &.closed {
        background: variables.$brand-info;
      }

      &.pending {
        background: variables.$brand-danger-dark;
      }
    }
  }
}

.country-flag {
  width: 25px;
}

.common-history {
  .history-filter {
    margin: 20px;

    .history-filter-type {
      // display: inline-block;
      // text-align: center;
      margin-right: 100px;

      .filter-circle {
        margin-bottom: 5px;
        cursor: pointer;
        transition: border-color 1s;
        border: 1px solid rgb(242, 242, 242);
        border-radius: 50%;
        width: 60px;
        height: 60px;

        &.active {
          border-color: variables.$brand-primary;
        }

        .icon-center {
          width: 60px;
          height: 60px;
        }
      }
    }
  }
}

table tr:hover {
  background-color: rgba(222, 222, 222, 0.2);
}

.dtp .dtp-actual-meridien a.selected,
.dtp .dtp-hand.on,
.dtp .dtp-picker-time > a.dtp-select-hour.selected,
.dtp div.dtp-date,
.dtp div.dtp-time,
.dtp table.dtp-picker-days tr > td > a.selected,
.dtp table.dtp-picker-days tr > td > a.selected.hilite {
  background: variables.$brand-accent !important;
}

.dtp div.dtp-actual-time.p60 span.selected,
.dtp table.dtp-picker-days tr > td > a.hilite:not(.selected) {
  color: variables.$brand-accent !important;
}

.dtp div.dtp-actual-maxtime,
.dtp div.dtp-actual-year,
.dtp div.dtp-year-btn {
  color: variables.$brand-accent !important;
}

.dtp > .dtp-content > .dtp-date-view > header.dtp-header {
  background: variables.$brand-accent !important;
}

md-menu-content.dtp-month-list {
  background-color: variables.$brand-accent !important;
}

md-menu-content.dtp-year-list {
  background-color: variables.$brand-accent !important;
}

md-backdrop.md-dialog-backdrop {
  z-index: 79;
  opacity: 0.8;
}

.welcome-order-import {
  .customer-detail-tabs {
    md-tabs-content-wrapper {
      height: 710px;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}

.signup-dialog-box {
  max-height: 600px;
  position: relative;
  overflow: initial;
  border-radius: 10px;

  md-dialog-content {
    position: relative;
    overflow: initial;
  }

  md-autocomplete {
    //margin-bottom: 20px;
    max-width: 425px;
    margin-top: 10px;
  }

  .inventory_radio {
    md-radio-button {
      margin: 0;
    }
  }

  .multiple_input_section {
    md-input-container {
      width: 100%;
    }
  }
}

.terms-popup {
  md-dialog-content {
    overflow: initial;
  }
}

.order-import-popup {
  min-width: 800px;
  max-width: 100%;
}

md-input-container .md-input.custom-map-input {
  width: 94%;
}

// @media screen and (max-width: 1045px) {
//   md-input-container .md-input.custom-map-input {
//     width: 94%;
//   }
// }

@media screen and (max-width: 912px) {
  md-input-container .md-input.custom-map-input {
    width: 93%;
  }
}

@media screen and (max-width: 912px) {
  md-input-container .md-input.custom-map-input {
    width: 93%;
  }
}

@media screen and (max-width: 832px) {
  md-input-container .md-input.custom-map-input {
    width: 92%;
  }
}

@media screen and (max-width: 775px) {
  md-input-container .md-input.custom-map-input {
    width: 91%;
  }
}

@media screen and (max-width: 775px) {
  .signup-dialog-box .inventory_radio md-radio-button {
    margin-bottom: 15px;
  }

  .signup-dialog-box {
    min-width: 300px;
  }
}

@media screen and (max-width: 700px) {
  .signup-dialog-box {
    min-width: 300px;

    #step5 {
      min-width: 320px;

      .signup-parent {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 434px) {
  md-input-container .md-input.custom-map-input {
    width: 90%;
  }
}

@media screen and (max-width: 393px) {
  md-input-container .md-input.custom-map-input {
    width: 88%;
  }
}

.action-full-screen {
  // background: #d3e1e5;
  &.md-dialog-full-screen {
    max-width: 100%;
    min-width: 100%;
    max-height: 100%;
    min-width: 100%;
  }

  .action-full-screen-form {
    padding: 15px 10px;
    background: #fff;
  }

  .header-section {
    md-icon {
      width: 40px;
      height: 40px;
    }
  }

  .drag-drop {
    padding: 110px;
  }

  .navigate-button {
    line-height: 16px;
  }

  .action-list-sidebar .accordion-section {
    border: 0;
  }
}

.drag-drop {
  padding: 110px;
}

.navigate-button {
  line-height: 16px;
}

.action-list-sidebar .accordion-section {
  border: 0;
}

md-dialog.md-dialog-fullscreen {
  min-height: 100%;
  min-width: 100%;
  border-radius: 0;
}

.filter-options-tab md-option .md-text {
  display: flex!important;
  align-items: center!important;
}

.ql-snow .ql-tooltip {
  left: 3px !important;
}
// .bar-chart {
//   height: auto !important;
//   min-height: 450px !important;
// }
// .bar-chart svg {
//   height: auto !important;
//   width: 100%;
//   min-height: 420px !important;
//   overflow-y: visible !important;
// }
