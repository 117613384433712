.fullorder {
  padding: 20px;

  .data-table {
    min-width: 1200px !important;

    .shipping-address {
      max-width: 150px;
      display: block;
    }
  }

  .status-icon {
    height: 14px;
    width: 14px;
  }

  .locationsymbo-bar {
    margin-bottom: 18px;
  }

  .order-status {
    max-width: 80px;

    .status-viewer {
      -webkit-border-radius: 5px;
      border-radius: 5px;
      background-clip: padding-box;
      background: #dbdbdb;
      border: 1px solid #cccccc;
      height: 16px;
      margin-top: 2px;
      margin-bottom: 2px;
      overflow: hidden;

      .progress-bar {
        display: inline-block;
        height: 100%;

        &.open {
          background: #6C9AFF;
        }

        &.closed {
          background: #b9eaa7;
        }
      }
    }
  }
}

.orderfulfilment-title {
  margin: 0 auto;
}
